import styled from 'styled-components';

export const ActionButtonContainer = styled.div`
  padding: 8px;
  cursor: pointer;

  &:hover {
    border-radius: 4px;
    background-color: #e6e6e6;
  }
`;

export const ActionButtonLabel = styled.span`
  font-size: 16px;
  margin-left: 8px;
  color: var(--primary-color);
`;
