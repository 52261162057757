export const states = [
  { id: 1, name: 'ACRE', UF: 'AC' },
  { id: 2, name: 'ALAGOAS', UF: 'AL' },
  { id: 3, name: 'AMAPA', UF: 'AP' },
  { id: 4, name: 'AMAZONAS', UF: 'AM' },
  { id: 5, name: 'BAHIA', UF: 'BA' },
  { id: 6, name: 'CEARA', UF: 'CE' },
  { id: 7, name: 'DISTRITO FEDERAL', UF: 'DF' },
  { id: 8, name: 'ESPIRITO SANTO', UF: 'ES' },
  { id: 9, name: 'RORAIMA', UF: 'RR' },
  { id: 10, name: 'GOIAS', UF: 'GO' },
  { id: 11, name: 'MARANHAO', UF: 'MA' },
  { id: 12, name: 'MATO GROSSO', UF: 'MT' },
  { id: 13, name: 'MATO GROSSO DO SUL', UF: 'MS' },
  { id: 14, name: 'MINAS GERAIS', UF: 'MG' },
  { id: 15, name: 'PARA', UF: 'PA' },
  { id: 16, name: 'PARAIBA', UF: 'PB' },
  { id: 17, name: 'PARANA', UF: 'PR' },
  { id: 18, name: 'PERNAMBUCO', UF: 'PE' },
  { id: 19, name: 'PIAUI', UF: 'PI' },
  { id: 20, name: 'RIO DE JANEIRO', UF: 'RJ' },
  { id: 21, name: 'RIO GRANDE DO NORTE', UF: 'RN' },
  { id: 22, name: 'RIO GRANDE DO SUL', UF: 'RS' },
  { id: 23, name: 'RONDONIA', UF: 'RO' },
  { id: 24, name: 'TOCANTINS', UF: 'TO' },
  { id: 25, name: 'SANTA CATARINA', UF: 'SC' },
  { id: 26, name: 'SAO PAULO', UF: 'SP' },
];

export interface City {
  cityName: string;
  stateCode: number;
  stateName: string;
}

export interface State {
  stateName: string;
  stateCode: number;
}

export interface Cep {
  cep: string;
}
