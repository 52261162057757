import React, { useState } from 'react';
import { Col, Drawer, Row, Tabs } from 'antd';

import ProfileTab from '../ProfileTab';
import CustomersTab from '../CustomersTab';
import PermissionFilters from '../PermissionFilters';

import './styles.css';
import SalesRegionsTab from '../SalesRegionsTab';

const RepresentativeProfileContent: React.FC = () => {
  const [drawerPermissionIsVisible, setDrawerPermissionIsVisible] = useState(false);

  const showDrawerPermission = () => {
    setDrawerPermissionIsVisible(true);
  };

  const closeDrawerPermission = () => {
    setDrawerPermissionIsVisible(false);
  };

  const permission = (
    <strong style={{ cursor: 'pointer' }} onClick={showDrawerPermission}>
      Permissões
    </strong>
  );
  return (
    <>
      <Row className="mt-4">
        <Col span={24}>
          <Tabs tabBarExtraContent={permission}>
            <Tabs.TabPane tab="Perfil" key="1">
              <ProfileTab />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Lojistas" key="2">
              <CustomersTab />
            </Tabs.TabPane>
            {/* TODO */}
            <Tabs.TabPane tab="Região de Venda" key="3">
              <SalesRegionsTab />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>

      <Drawer placement="left" onClose={closeDrawerPermission} visible={drawerPermissionIsVisible} closable={false}>
        <PermissionFilters />
      </Drawer>
    </>
  );
};

export default RepresentativeProfileContent;
