import React, { useEffect, useState } from 'react';

import Address from '../Address';
import TablePane from '../TablePane';
import ProfileInformation from '../ProfileInformation';
import { Row, Switch, Table, Typography, Col, Button, Modal } from 'antd';
import { useCustomer } from '@hooks/CustomerContext';
import { useBrand } from '@hooks/BrandContext';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { BrandIntegration } from '@models/BrandIntegrations';
import { isEmpty } from '@utils/is-empty';

const { Title } = Typography;

const CustomerProfileContent: React.FC = () => {
  const { currentCustomer, acceptCustomerInBrand, loadCustomerByCode } = useCustomer();
  const { brands } = useBrand();

  const [selectedBrands, setSelectedBrands] = useState<any[]>([]);
  const [pastSelectedBrands, setPastSelectedBrands] = useState<any[]>([]);

  useEffect(() => {
    if (!isEmpty(currentCustomer)) {
      setSelectedBrands(
        currentCustomer.brandIntegrations.flatMap((integration: BrandIntegration) => {
          return integration.brand.brandId;
        }),
      );
      setPastSelectedBrands(
        currentCustomer.brandIntegrations.flatMap((integration: BrandIntegration) => {
          return integration.brand.brandId;
        }),
      );
    }
  }, [currentCustomer]);

  const emailColumns = [
    {
      dataIndex: 'email',
      title: 'Endereço de Email',
    },
    {
      dataIndex: 'isDefault',
      title: 'Padrão',
      render: (value: boolean) => <Switch disabled checked={value} />,
    },
  ];

  const contactColumns = [
    {
      dataIndex: 'name',
      title: 'Nome',
    },
    {
      dataIndex: 'isDefault',
      title: 'Padrão',
      render: (value: boolean) => <Switch disabled checked={value} />,
    },
    {
      dataIndex: 'phoneNumber',
      title: 'Telefone',
    },
    {
      dataIndex: 'cellNumber',
      title: 'Celular',
    },
    {
      dataIndex: 'email',
      title: 'Email',
    },
    {
      dataIndex: 'typeCode',
      title: 'Tipo',
    },
  ];

  const referenceColumns = [
    {
      dataIndex: 'type',
      title: 'Tipo',
    },
    {
      dataIndex: 'description',
      title: 'Nome',
    },
    {
      dataIndex: 'phoneNumber',
      title: 'Telefone',
    },
  ];

  const phoneColumns = [
    {
      dataIndex: 'typeName',
      title: 'Tipo',
    },
    {
      dataIndex: 'number',
      title: 'Telefone',
    },
    {
      dataIndex: 'branchLine',
      title: 'Ramal',
    },
  ];

  const socialNetworkColumns = [
    {
      dataIndex: 'typeName',
      title: 'Rede',
    },
    {
      dataIndex: 'address',
      title: 'Perfil',
    },
  ];

  const brandColumns = [
    {
      dataIndex: 'brandId',
      title: 'ID',
    },
    {
      dataIndex: 'brandName',
      title: 'Nome',
    },
  ];

  const onChangeRowSelection = async (selectedBrands: any[]) => {
    setSelectedBrands([...selectedBrands]);
  };

  const rowSelection = {
    columnTitle: 'Vinculado',
    selectedRowKeys: selectedBrands,
    onChange: onChangeRowSelection,
  };

  const handleAccept = () => {
    Modal.confirm({
      title: 'Tem certeza que deseja vincular esse lojista?',
      icon: <QuestionCircleOutlined />,
      onOk: async () => {
        const brandPayload = selectedBrands.flatMap((brand: number) => {
          return [{ brandId: brand }];
        });
        const success = await acceptCustomerInBrand(brandPayload, currentCustomer.code);
        setPastSelectedBrands([...selectedBrands]);
        loadCustomerByCode(success.data);
      },
      onCancel() {},
    });
  };

  return (
    <Row gutter={[16, 16]}>
      <ProfileInformation />

      {/* <TablePane title="Guias" columns={[]} data={[]} xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} /> */}
      {/* <TablePane title="Representantes" columns={[]} data={[]} xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} /> */}
      {/* <TablePane title="Vendedores" columns={[]} data={[]} xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} /> */}
      {/* <TablePane title="Pedidos" columns={[]} data={[]} span={24} /> */}
      {/* <TablePane title="Marcas" columns={[]} data={[]} span={24} /> */}
      <Address />
      <TablePane title="Telefones" columns={phoneColumns} data={currentCustomer.phones} span={24} />
      <TablePane
        title="Emails"
        columns={emailColumns}
        data={currentCustomer.emails}
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        xxl={12}
      />
      <TablePane
        title="Contatos"
        columns={contactColumns}
        data={currentCustomer.contacts}
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        xxl={12}
      />
      <TablePane
        title="Redes Sociais"
        columns={socialNetworkColumns}
        data={currentCustomer.socialNetworks}
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        xxl={12}
      />
      <TablePane
        title="Referências"
        columns={referenceColumns}
        data={currentCustomer.references}
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        xxl={12}
      />
      <Col style={{ width: '100vw' }}>
        <Title level={4} style={{ color: '#808080' }}>
          Marcas
          <Button
            className="ml-5"
            style={{ borderRadius: '8px' }}
            type="primary"
            size="large"
            onClick={handleAccept}
            disabled={!selectedBrands.length && !pastSelectedBrands.length}
          >
            Salvar
          </Button>
        </Title>
        <Table
          columns={brandColumns}
          dataSource={brands}
          rowSelection={rowSelection}
          rowKey={(record) => record.brandId}
        />
      </Col>
    </Row>
  );
};

export default CustomerProfileContent;
