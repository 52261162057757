import { CheckOutlined, EyeOutlined } from '@ant-design/icons';
import { useDevolution } from '@hooks/DevolutionsContext';
import { Devolution } from '@models/Devolution';
import { DevolutionStatusEnum } from '@models/enum/DevolutionStatusEnum';
import { FullScreenModal } from '@styles/globals';
import { Col, Row } from 'antd';
import React from 'react';
import gavel from '../../../../../assets/svg/gavel.svg';
import DevolutionForm from '../../DevolutionForm';

interface ActionsStatusColumnProps {
  devolution: Devolution;
}

const ActionsStatusColumn: React.FC<ActionsStatusColumnProps> = ({ devolution }) => {
  const { finishDevolutionRequest } = useDevolution();
  const [DevolutionModalIsVisible, setDevolutionModalIsVisible] = React.useState(false);

  const onCloseDevolutionModal = () => setDevolutionModalIsVisible(false);

  const onShowDevolutionModal = () => {
    setDevolutionModalIsVisible(true);
  };

  const finishDevolution = () => {
    if (devolution?.status === DevolutionStatusEnum.IN_DEVOLUTION) {
      finishDevolutionRequest(devolution?.sequence);
    }
  };

  return (
    <>
      <FullScreenModal
        destroyOnClose={true}
        visible={DevolutionModalIsVisible}
        onOk={onShowDevolutionModal}
        onCancel={onCloseDevolutionModal}
        footer={null}
        forceRender={false}
      >
        <Row align="middle" justify="center">
          <Col span={24} className="d-flex" style={{ width: '1100px' }}>
            <DevolutionForm devolution={devolution} onCloseModal={onCloseDevolutionModal} />
          </Col>
        </Row>
      </FullScreenModal>

      <div style={{ width: '150px', cursor: 'pointer', fontSize: '16px', color: '#267AFA', textAlign: 'center' }}>
        {(devolution?.status === DevolutionStatusEnum.PRIMARY_ANALYZIS ||
          devolution?.status === DevolutionStatusEnum.SECONDARY_ANALYSIS) && (
          <p
            style={{
              color: '#267AFA',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 0,
            }}
            onClick={onShowDevolutionModal}
          >
            Avaliar
            <img style={{ paddingLeft: '6px' }} src={gavel} alt="gavel icon" />
          </p>
        )}

        {devolution?.status === DevolutionStatusEnum.IN_DEVOLUTION && (
          <p
            style={{
              color: '#267AFA',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 0,
            }}
            onClick={finishDevolution}
          >
            Concluir
            <CheckOutlined style={{ paddingLeft: '6px' }} />
          </p>
        )}

        {devolution?.status === DevolutionStatusEnum.FINISHED && (
          <p
            style={{
              color: '#267AFA',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 0,
            }}
            onClick={onShowDevolutionModal}
          >
            Visualizar
            <EyeOutlined style={{ paddingLeft: '6px' }} />
          </p>
        )}
      </div>
    </>
  );
};

export default ActionsStatusColumn;
