import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: white;
  padding: 5%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ScrollContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  padding: 4px 0;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: #f5f5f5;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-appearance: none;
    background: var(--primary-color);
    border-radius: 8px;
  }
`;

export const GridContainer = styled.div`
  padding: 4px 8px 12px 8px;
  display: grid;
`;

export const InputContainer = styled.div`
  padding: 2px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ColorNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  margin-right: 8px;

  &:first-child {
    justify-content: end;
    padding-bottom: 6px;
  }
`;
