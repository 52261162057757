import React, { useCallback, useState } from 'react';
import { Col, Drawer, Row, Typography } from 'antd';
import { FilterFilled, PlusCircleFilled } from '@ant-design/icons';
import { useHistory } from 'react-router';

import * as S from './styles';
import HeaderButton from '@components/HeaderButton';
import FiltersForm from '@components/FiltersForm';
import OfficialStoreFiltersForm from '../OfficialStoreFiltersForm';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
import FiltersList from '@components/FiltersList';

const { Title } = Typography;

interface OfficialStoreHeaderProps {
  baseUrl: string;
}

const filtersNameMap: any = {
  name: 'Descrição',
};

const OfficialStoreHeader: React.FC<OfficialStoreHeaderProps> = ({ baseUrl }) => {
  const history = useHistory();
  const [filtersDrawerIsVisible, setfiltersDrawerIsVisible] = useState<boolean>(false);
  const { loadOfficialStores, officialStoreFilters } = useOfficialStore();

  const handleCloseFiltersDrawer = useCallback(() => {
    setfiltersDrawerIsVisible(false);
  }, [setfiltersDrawerIsVisible]);

  const handleShowFiltersDrawer = useCallback(() => {
    setfiltersDrawerIsVisible(true);
  }, [setfiltersDrawerIsVisible]);

  const onSubmitFiltersForm = useCallback(
    (values: any) => {
      loadOfficialStores(0, values.name);
    },
    [loadOfficialStores],
  );

  const onClearFiltersForm = useCallback(() => {
    loadOfficialStores(0);
  }, [loadOfficialStores]);

  const onRemoveFilter = () => {
    loadOfficialStores(0);
  };

  return (
    <Row className="mt-8" justify="space-between">
      <Col>
        <Title level={2}>Lojas Oficiais</Title>
      </Col>
      <Col>
        <Row>
          <Col>
            <FiltersList columns={[{title: 'Nome', key: 'name'}]} dataSource={officialStoreFilters} onRemove={onRemoveFilter}/>
          </Col>
          <Col>
            <HeaderButton
              text="Filtros"
              onClick={handleShowFiltersDrawer}
              Icon={<FilterFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
            />
          </Col>
          <Col>
            <S.ActionButtonContainer onClick={() => history.push(`${baseUrl}/nova-loja`)}>
              <PlusCircleFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />
              <S.ActionButtonLabel>Adicionar a Loja Oficial</S.ActionButtonLabel>
            </S.ActionButtonContainer>
          </Col>
        </Row>
      </Col>

      <Drawer
        title="Filtros"
        placement="left"
        onClose={handleCloseFiltersDrawer}
        visible={filtersDrawerIsVisible}
        width={400}
      >
        <FiltersForm
          onCloseDrawer={handleCloseFiltersDrawer}
          onSubmit={onSubmitFiltersForm}
          onClearFilters={onClearFiltersForm}
          filtersForm={<OfficialStoreFiltersForm />}
        />
      </Drawer>
    </Row>
  );
};

export default OfficialStoreHeader;
