import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

import * as OfficialStoreSellerService from '@services/officialStoreSeller';
import { message } from 'antd';

interface OfficialStoreSellerState {
  loadingSellers: boolean;
  loadingBrandSellers: boolean;
  officialStoreSellers: any[];
  brandSellers: any[];

  loadOfficialStoreSellers: Function;
  loadOfficialStoreBrandSellers: Function;
  updateOfficialStoreSeller: Function;
  removeOfficialStoreBrandSeller: Function;
}

interface OfficialStoreSellerProviderProps {
  children: ReactNode;
}

export const OfficialStoreSellerContext = createContext<OfficialStoreSellerState>({} as OfficialStoreSellerState);

const OfficialStoreSellerProvider = ({ children }: OfficialStoreSellerProviderProps) => {
  const [loadingSellers, setLoadingSellers] = useState<boolean>(false);
  const [loadingBrandSellers, setLoadingBrandSellers] = useState<boolean>(false);
  const [officialStoreSellers, setOfficialStoreSellers] = useState<any[]>([]);
  const [brandSellers, setBrandSellers] = useState<any[]>([]);

  const loadOfficialStoreSellers = useCallback(
    async (officialStoreId: number) => {
      setLoadingSellers(true);

      try {
        const { data: _officialStoreSeller } = await OfficialStoreSellerService.getSellersByOfficialStoreId(
          officialStoreId,
        );

        if (_officialStoreSeller.data) {
          setOfficialStoreSellers(_officialStoreSeller.data);
        }
      } catch {
        message.error('Erro ao buscar vendedores');
      } finally {
        setLoadingSellers(false);
      }
    },
    [setOfficialStoreSellers, setLoadingSellers],
  );

  const loadOfficialStoreBrandSellers = useCallback(
    async (officialStoreId: number, brandId: number) => {
      setLoadingBrandSellers(true);

      try {
        const { data: _brandSellers } = await OfficialStoreSellerService.getSellersByBrandId(officialStoreId, brandId);

        if (_brandSellers) {
          setBrandSellers(_brandSellers);
        }
      } catch {
        message.error('Erro ao buscar vendedores');
      } finally {
        setLoadingBrandSellers(false);
      }
    },
    [setLoadingBrandSellers, setBrandSellers],
  );

  const updateOfficialStoreSeller = useCallback(
    async (officialStoreId: number, brandId: number, sellerCodes: number[]) => {
      setLoadingSellers(true);

      try {
        const body = sellerCodes.map((_code) => ({
          sellerCode: _code,
          brandId,
          isActive: true,
        }));

        const { data: _officialStoreSellers } = await OfficialStoreSellerService.updateSellersById(
          officialStoreId,
          body,
        );

        if (_officialStoreSellers) {
          message.success('Vendedores vinculados com sucesso!');

          setOfficialStoreSellers(_officialStoreSellers);

          await loadOfficialStoreBrandSellers(officialStoreId, brandId);
        }
      } catch (e) {
        message.error('Erro ao mudar situação dos vendedores!');
      } finally {
        setLoadingSellers(false);
      }
    },
    [setLoadingSellers, setOfficialStoreSellers, loadOfficialStoreBrandSellers],
  );

  const removeOfficialStoreBrandSeller = async (officialStoreId: number, brandId: number, sellerCode: number) => {
    setLoadingBrandSellers(true);

    try {
      const index = brandSellers.findIndex((seller) => seller.sellerCode === sellerCode);
      const { data: _deleted } = await OfficialStoreSellerService.removeSellerById(
        officialStoreId,
        brandId,
        sellerCode,
      );

      if (index !== -1) {
        message.success('Vendedor desvinculado com sucesso!');
        brandSellers.splice(index, 1);
        setBrandSellers([...brandSellers]);
      }
    } catch (e) {
      message.error('Erro ao mudar situação do vendedor!');
    } finally {
      setLoadingBrandSellers(false);
    }
  };

  return (
    <OfficialStoreSellerContext.Provider
      value={{
        loadingSellers,
        officialStoreSellers,
        loadingBrandSellers,
        brandSellers,
        loadOfficialStoreSellers,
        loadOfficialStoreBrandSellers,
        updateOfficialStoreSeller,
        removeOfficialStoreBrandSeller,
      }}
    >
      {children}
    </OfficialStoreSellerContext.Provider>
  );
};

const useOfficialStoreSeller = () => {
  return useContext(OfficialStoreSellerContext);
};

export { OfficialStoreSellerProvider, useOfficialStoreSeller };
