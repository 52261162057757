import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

import * as OfficialStoreRepresentativesService from '@services/officialStoreRepresentative';
import { OfficialStoreRepresentative } from '@models/OfficialStoreRepresentative';
import { message } from 'antd';
import { OfficialStoreBrandRepresentative } from '@models/OfficialStoreBrandRepresentative';

interface OfficialStoreRepresentativesState {
  loadingRepresentatives: boolean;
  loadingBrandRepresentatives: boolean;
  officialStoreRepresentatives: OfficialStoreRepresentative[];
  brandRepresentatives: OfficialStoreBrandRepresentative[];
  loadOfficialStoreRepresentatives: Function;
  updateOfficialStoreRepresentatives: Function;
  loadOfficialStoreBrandRepresentatives: Function;
  removeOfficialStoreBrandRepresentative: Function;
}

interface OfficialStoreRepresentativesProviderProps {
  children: ReactNode;
}

export const OfficialStoreRepresentativesContext = createContext<OfficialStoreRepresentativesState>(
  {} as OfficialStoreRepresentativesState,
);

const OfficialStoreRepresentativesProvider: React.FC<OfficialStoreRepresentativesProviderProps> = ({ children }) => {
  const [officialStoreRepresentatives, setOfficialStoreRepresentatives] = useState<any[]>([]);
  const [brandRepresentatives, setBrandRepresentatives] = useState<any[]>([]);
  const [loadingRepresentatives, setLoadingRepresentatives] = useState<boolean>(false);
  const [loadingBrandRepresentatives, setLoadingBrandRepresentatives] = useState<boolean>(false);

  const loadOfficialStoreRepresentatives = useCallback(
    async (officialStoreId: number) => {
      setLoadingRepresentatives(true);

      try {
        const { data: _officialStoreRepresentatives } =
          await OfficialStoreRepresentativesService.getRepresentativesById(officialStoreId);

        if (_officialStoreRepresentatives) {
          setOfficialStoreRepresentatives(_officialStoreRepresentatives);
          console.log(_officialStoreRepresentatives)
        }
      } catch {
        message.error('Erro ao buscar representantes');
      } finally {
        setLoadingRepresentatives(false);
      }
    },
    [setLoadingRepresentatives, setOfficialStoreRepresentatives],
  );

  const loadOfficialStoreBrandRepresentatives = useCallback(
    async (officialStoreId: number, brandId: number) => {
      setLoadingBrandRepresentatives(true);

      try {
        const { data: _brandRepresentatives } = await OfficialStoreRepresentativesService.getRepresentativesByBrandId(
          officialStoreId,
          brandId,
        );

        if (_brandRepresentatives) {
          setBrandRepresentatives(_brandRepresentatives);
        }
      } catch {
        message.error('Erro ao buscar representantes');
      } finally {
        setLoadingBrandRepresentatives(false);
      }
    },
    [setLoadingBrandRepresentatives, setBrandRepresentatives],
  );

  const updateOfficialStoreRepresentatives = useCallback(
    async (officialStoreId: number, brandId: number, representativesCodes: number[]) => {
      setLoadingRepresentatives(true);

      try {
        const body = representativesCodes.map((_code) => ({
          representativeCode: _code,
          brandId,
          isActive: true,
        }));

        const { data: _officialStoreRepresentatives } =
          await OfficialStoreRepresentativesService.updateRepresentativesById(officialStoreId, body);

        if (_officialStoreRepresentatives) {
          message.success('Representantes vinculados com sucesso!');

          setOfficialStoreRepresentatives(_officialStoreRepresentatives);

          await loadOfficialStoreBrandRepresentatives(officialStoreId, brandId);
        }
      } catch (e) {
        message.error('Erro ao mudar situação dos representantes!');
      } finally {
        setLoadingRepresentatives(false);
      }
    },
    [setLoadingRepresentatives, setOfficialStoreRepresentatives, loadOfficialStoreBrandRepresentatives],
  );

  const removeOfficialStoreBrandRepresentative = async (
    officialStoreId: number,
    brandId: number,
    representativeCode: number,
  ) => {
    setLoadingBrandRepresentatives(true);

    try {
      const index = brandRepresentatives.findIndex((representative) => representative.code === representativeCode);
      const { data: _deleted } = await OfficialStoreRepresentativesService.removeRepresentativesById(
        officialStoreId,
        brandId,
        representativeCode,
      );

      if (index !== -1) {
        message.success('Representante removido com sucesso!');
        brandRepresentatives.splice(index, 1);
        setBrandRepresentatives([...brandRepresentatives]);
      }
    } catch (e) {
      message.error('Erro ao mudar situação do representante!');
    } finally {
      setLoadingBrandRepresentatives(false);
    }
  };

  return (
    <OfficialStoreRepresentativesContext.Provider
      value={{
        loadingRepresentatives,
        loadingBrandRepresentatives,
        brandRepresentatives,
        officialStoreRepresentatives,
        loadOfficialStoreRepresentatives,
        loadOfficialStoreBrandRepresentatives,
        updateOfficialStoreRepresentatives,
        removeOfficialStoreBrandRepresentative,
      }}
    >
      {children}
    </OfficialStoreRepresentativesContext.Provider>
  );
};

const useOfficialStoreRepresentatives = () => {
  return useContext(OfficialStoreRepresentativesContext);
};

export { OfficialStoreRepresentativesProvider, useOfficialStoreRepresentatives };
