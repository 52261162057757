import { PriceTableOfficialStore } from '@models/PriceTableOfficialStore';
import { Avatar, Table, Typography } from 'antd';

const { Text } = Typography;

interface TabOfficialStoreProps {
  priceTableOfficialStores: PriceTableOfficialStore[];
}

const TabOfficialStore: React.FC<TabOfficialStoreProps> = ({ priceTableOfficialStores }) => {
  const columns = [
    {
      title: 'Código',
      dataIndex: 'officialStore',
      render: (officialStore: any) => <Text>#{officialStore.officialStoreId}</Text>,
    },
    {
      title: 'Loja Oficial',
      dataIndex: 'officialStore',
      render: (officialStore: any) => (
        <>
          {officialStore.logoUrl ? (
            <Avatar src={officialStore.logoUrl} />
          ) : (
            <Avatar style={{ backgroundColor: 'var(--primary-color)', verticalAlign: 'middle' }}>
              {officialStore.description[0]}
            </Avatar>
          )}
          <Text className="ml-2">{officialStore.description}</Text>
        </>
      ),
    },
    {
      title: 'Estados',
      dataIndex: 'states',
      render: (values: any) =>
        values.map((_state: string, i: number) => (i === 0 ? <Text>{_state}</Text> : <Text> | {_state}</Text>)),
    },
  ];

  return (
    <Table
    scroll={{ x: 800 }}
      columns={columns}
      dataSource={priceTableOfficialStores}
      pagination={{ pageSize: 4, hideOnSinglePage: true }}
    />
  );
};

export default TabOfficialStore;
