import React from 'react';

import { Table } from 'antd';
import { OfficialStoreQuestionOption } from '@models/OfficialStoreQuestionOption';

interface ClassificationsTableProps {
  options: OfficialStoreQuestionOption[];
}

const ClassificationsTable: React.FC<ClassificationsTableProps> = ({ options }) => {
  const columns = [
    {
      title: 'Tipo de Classificação',
      dataIndex: 'typeCode',
      align: 'right' as 'right',
      render: (value: any) => <strong>Tipo de Classificação: {value}</strong>,
    },
    {
      title: 'Classificação',
      dataIndex: 'code',
      width: '180px',
      align: 'right' as 'right',
      render: (value: any) => <strong>Classificação: {value}</strong>,
    },
  ];

  return <Table columns={columns} dataSource={options} pagination={false} showHeader={false}
  scroll={{ x: 800 }}
  />;
};

export default ClassificationsTable;
