import React, { useCallback, useState } from 'react';
import { Col, Drawer, Row, Typography } from 'antd';
import { CloseOutlined, FilterFilled, LeftOutlined, PlusCircleFilled } from '@ant-design/icons';

import { useHistory } from 'react-router-dom';
import HeaderButton from '@components/HeaderButton';
import FiltersForm from '@components/FiltersForm';
import { GoalFilters, useGoal } from '@hooks/GoalContext';
import GoalFiltersForm from '../GoalFiltersForm';
import FiltersList, { ListFilterType } from '@components/FiltersList';
import { FullScreenModal } from '@styles/globals';
import CreateGoal from '@components/CreateGoal';

const { Title, Text } = Typography;

interface DetailsHeaderProps {
  goalId: number;
}

const DetailsHeader: React.FC<DetailsHeaderProps> = ({ goalId }) => {
  const history = useHistory();
  const {
    filterGoalItems,
    clearFilterGoalItems,
    filtersObjectGoalItems,
    deleteGoalItems,
    uploadGoalFileSheet,
    currentGoal,
  } = useGoal();
  const [filtersDrawerIsVisible, setfiltersDrawerIsVisible] = useState<boolean>(false);
  const [modalCreateGoalIsVisible, setModalCreateGoalIsVisible] = useState<boolean>(false);

  const onCloseModalCreateGoal = useCallback(() => {
    setModalCreateGoalIsVisible(false);
  }, [setModalCreateGoalIsVisible]);

  const onShowModalCreateGoal = useCallback(() => {
    setModalCreateGoalIsVisible(true);
  }, [setModalCreateGoalIsVisible]);

  const onBack = useCallback(() => {
    history.go(-1);
  }, [history]);

  const handleCloseFiltersDrawer = useCallback(() => {
    setfiltersDrawerIsVisible(false);
  }, [setfiltersDrawerIsVisible]);

  const handleShowFiltersDrawer = useCallback(() => {
    setfiltersDrawerIsVisible(true);
  }, [setfiltersDrawerIsVisible]);

  const onSubmitFiltersForm = useCallback(
    (filters: GoalFilters) => {
      filterGoalItems(filters);
    },
    [filterGoalItems],
  );

  const onClearFiltersForm = useCallback(() => {
    clearFilterGoalItems();
  }, [clearFilterGoalItems]);

  const onRemoveFilter = ({ key }: ListFilterType) => {
    const newFilters = { ...filtersObjectGoalItems, [key]: undefined };
    filterGoalItems(newFilters);
  };

  const handleDeleteGoalItems = useCallback(async () => {
    if (goalId) {
      await deleteGoalItems(goalId);
      onShowModalCreateGoal();
    }
  }, [deleteGoalItems, onShowModalCreateGoal, goalId]);

  const handleCreateGoalItems = useCallback(async () => {
    onShowModalCreateGoal();
  }, [onShowModalCreateGoal]);

  const onSubmitFileSheet = async (file: File) => {
    await uploadGoalFileSheet(file);
    onCloseModalCreateGoal();
    onBack();
  };

  const filterColumns = [
    { title: 'REF.', key: 'reference' },
    { title: 'LOJISTA', key: 'customer' },
    { title: 'LOJA OFI.', key: 'officialStore' },
    { title: 'UF', key: 'uf' },
    { title: 'COLEÇÃO', key: 'collection' },
    { title: 'TIPO CLASS. PED.', key: 'orderClassificationTypeCode' },
    { title: 'TIPO CLASS. PROD.', key: 'productClassificationTypeCode' },
    { title: 'CLASS. PED.', key: 'orderClassificationCode' },
    { title: 'CLASS. PROD.', key: 'productClassificationCode' },
    { title: 'INT. INICIAL(%)', key: 'initialPercentInterval' },
    { title: 'INT. FINAL(%)', key: 'finalPercentInterval' },
  ];

  return (
    <>
      <Row className="mt-6" justify="space-between" gutter={[32, 32]}>
        <Col className="d-flex align-center justify-center gutter-row">
          <LeftOutlined style={{ fontSize: '16px' }} onClick={onBack} />
          <Title level={3} className="mb-0 ml-8">
            {currentGoal?.description?.toUpperCase()}
          </Title>
        </Col>
        <Col className="gutter-row">
          <Row className="gutter-row" gutter={[16, 16]}>
            <Col className="gutter-row">
              <FiltersList columns={filterColumns} dataSource={filtersObjectGoalItems} onRemove={onRemoveFilter} />
            </Col>
            <Col className="gutter-row">
              <HeaderButton
                text="Filtros"
                onClick={handleShowFiltersDrawer}
                Icon={<FilterFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
              />
            </Col>
            <Col className="gutter-row">
              <HeaderButton
                text="Excluir e carregar metas"
                Icon={<CloseOutlined style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
                onClick={handleDeleteGoalItems}
              />
            </Col>
            <Col className="gutter-row">
              <HeaderButton
                text="Criar novas metas"
                Icon={<PlusCircleFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
                onClick={handleCreateGoalItems}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Drawer
        title="Filtros"
        placement="left"
        onClose={handleCloseFiltersDrawer}
        visible={filtersDrawerIsVisible}
        width={400}
      >
        <FiltersForm
          onCloseDrawer={handleCloseFiltersDrawer}
          onSubmit={onSubmitFiltersForm}
          onClearFilters={onClearFiltersForm}
          filtersForm={<GoalFiltersForm />}
          initialValues={filtersObjectGoalItems}
        />
      </Drawer>

      <FullScreenModal
        destroyOnClose={true}
        footer={null}
        forceRender={false}
        visible={modalCreateGoalIsVisible}
        onOk={onCloseModalCreateGoal}
        onCancel={onCloseModalCreateGoal}
        bodyStyle={{ width: '100%' }}
      >
        <CreateGoal
          onSubmitGoal={() => {
            return;
          }}
          onSubmitFileSheet={onSubmitFileSheet}
          newGoalId={goalId}
          initialPage={1}
        />
      </FullScreenModal>
    </>
  );
};

export default DetailsHeader;
