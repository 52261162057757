import React from 'react';

import Customers from '../Customers';
import ProfileConnectionsHeader from '../ProfileConnectionsHeader';

interface ProfileConnectionsProps {}

const ProfileConnections: React.FC<ProfileConnectionsProps> = () => {
  return (
    <>
      <ProfileConnectionsHeader />
      <Customers />
      {/* <Orders /> */}
      {/* <Addresses /> */}
    </>
  );
};

export default ProfileConnections;
