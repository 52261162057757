import { CheckOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { Col, notification, Row, Spin, Typography, Upload } from 'antd';
import { useCallback } from 'react';
import { useVideos } from '@hooks/VideosContext';
import HeaderButton from '@components/HeaderButton';
import { getBase64 } from '@utils/file-to-base64';

const { Title } = Typography;

const UploadVideosHeader: React.FC = () => {
  const { fileList, updateFileList, clearVideos, uploadVideos, loading } = useVideos();

  const emptyFunction = useCallback(() => {
    return;
  }, []);

  const beforeUpload = useCallback(
    async (file: File) => {
      const allowedMimeTypes = ['video/webm', 'video/mp4', 'video/flv', 'video/mpeg', 'video/mkv', 'video/ogv'];
      const isMimeValid = allowedMimeTypes.includes(file.type);

      if (!isMimeValid) {
        return notification.error({
          message: 'Formato inválido',
          description: `Apenas vídeos WEBM / MP4 / FLV / MPEG / MKV são aceitos! Verifique o arquivo: ${file.name}.`,
        });
      }

      if (file.size == 0) {
        return notification.error({
          message: 'Arquivo vazio',
          description: `O video está vazio! Verifique o arquivo: ${file.name}`,
        });
      }
      const videoSizeMaxInMB = Number(process.env.REACT_APP_VIDEO_MAX_SIZE_IN_MB) || 50;
      const isLt2M = file.size / 1024 / 1024 < videoSizeMaxInMB;
      if (!isLt2M) {
        return notification.error({
          message: 'Tamanho inválido',
          description: `Vídeo ultrapassa o limite de ${videoSizeMaxInMB}MB! Verifique o arquivo: ${file.name}`,
        });
      }

      const url = await getBase64(file);
      const splitedName = file.name.split('.');

      const reference = splitedName[0];

      const index = fileList.findIndex((_file) => _file.reference === reference);

      if (index !== -1)
        return notification.error({
          message: 'Ação inválida',
          description: `Vídeo já foi selecionado! Verifique o arquivo: ${file.name}`,
        });

      updateFileList({ file, url, reference });

      return true;
    },
    [fileList, updateFileList],
  );

  const getHeaderButtons = () => {
    if (loading)
      return (
        <div className="d-flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
          <Spin className="mr-4" />
          <strong style={{ width: '260px' }}>Enviando vídeos...</strong>
        </div>
      );

    if (!fileList || fileList.length === 0)
      return (
        <Upload
          showUploadList={false}
          beforeUpload={beforeUpload}
          listType="picture"
          multiple
          customRequest={emptyFunction}
        >
          <HeaderButton
            text="Carregar Videos"
            onClick={emptyFunction}
            Icon={<UploadOutlined style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
          />
        </Upload>
      );

    return (
      <Row>
        <Col>
          <HeaderButton
            text="Cancelar"
            onClick={clearVideos}
            Icon={<CloseOutlined style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
          />
        </Col>
        <Col>
          <HeaderButton
            text="Confirmar"
            onClick={uploadVideos}
            Icon={<CheckOutlined style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
          />
        </Col>
      </Row>
    );
  };

  return (
    <Row className="mt-8" justify="space-between">
      <Col>
        <Title level={2}>Cadastrar Videos</Title>
      </Col>
      <Col>{getHeaderButtons()}</Col>
    </Row>
  );
};

export default UploadVideosHeader;
