import React, { useEffect } from 'react';
import { Table, Form, InputNumber, Modal, message, Button } from 'antd';

import * as S from '../../styles';
import { useForm } from 'antd/lib/form/Form';
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { OfficialStore } from '@models/OfficialStore';
import { useOfficialStore } from '@hooks/OfficialStoreContext';

interface PanelPaymentMinimumValueProps {
  officialStore: OfficialStore;
}

const PanelPaymentMinimumValue: React.FC<PanelPaymentMinimumValueProps> = ({ officialStore }) => {
  const [form] = useForm();
  const { updateOfficialStoreMinimumValue } = useOfficialStore();

  useEffect(() => {
    if (!officialStore) return;

    form.setFieldsValue({ minimumCifValue: officialStore.minimumCifValue });
  }, [officialStore]);

  const onUpdateMinimunValue = (value: any) => {
    if (!value || !value.minimumCifValue) return;

    console.log(value);

    Modal.confirm({
      title: 'Atualizar valor mínimo para frete grátis',
      icon: <ExclamationCircleOutlined />,
      content: 'Deseja mesmo prosseguir?',
      okText: 'Sim',
      cancelText: 'Não',
      onOk: async () => {
        if (officialStore) {
          const updated = await updateOfficialStoreMinimumValue(officialStore.officialStoreId, value.minimumCifValue);

          if (updated) message.success('Valor atualizado com sucesso!');
          else message.error('Erro ao atualizar valor!');
        }
      },
    });
  };

  const columnsPaymentMinimumValue = [
    {
      title: 'Valor Mínimo para Frete Grátis',
      render: () => (
        <Form
          className="d-flex"
          style={{ alignItems: 'center', justifyContent: 'center' }}
          onFinish={onUpdateMinimunValue}
          form={form}
        >
          <Form.Item name="minimumCifValue">
            <InputNumber prefix="R$" style={{ width: 'min(100%, 200px)', height: '40px' }} min={0} type="number" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ height: '40px' }}>
              <CheckOutlined />
            </Button>
          </Form.Item>
        </Form>
      ),
    },
  ];

  return (
    <S.Panel>
      <Table columns={columnsPaymentMinimumValue} dataSource={[{}]} pagination={false} />
    </S.Panel>
  );
};

export default PanelPaymentMinimumValue;
