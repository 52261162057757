import React, { useCallback, useEffect } from 'react';

import { Checkbox, Col, Form, Input, message, Row, Typography } from 'antd';
import { CustomButton } from '@styles/globals';

const { Title } = Typography;

interface QuestionDescriptionStepProps {
  handleAddQuestionField: Function;
  nextStep: Function;
  editingQuestion?: any;
}

const QuestionDescriptionStep: React.FC<QuestionDescriptionStepProps> = ({
  handleAddQuestionField,
  nextStep,
  editingQuestion,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editingQuestion) {
      form.setFieldsValue({
        description: editingQuestion.description,
        isRepresentativeApp: !!editingQuestion.isRepresentativeApp,
        isShopkeeperApp: !!editingQuestion.isShopkeeperApp,
        isSellerApp: !!editingQuestion.isSellerApp,
      });
    }
  }, []);

  const handleSubmit = useCallback((values: any) => {
    values.isRepresentativeApp = !!values.isRepresentativeApp;
    values.isShopkeeperApp = !!values.isShopkeeperApp;
    values.isSellerApp = !!values.isSellerApp;

    if (!values.description) return message.warning('Preencha a descrição para prosseguir!');

    if (!values.isRepresentativeApp && !values.isShopkeeperApp && !values.isSellerApp)
      return message.warning('Selecione ao menos um aplicativo!');

    handleAddQuestionField(values);
    nextStep();
  }, []);

  return (
    <Form
      layout="vertical"
      className="d-flex"
      style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
      form={form}
      onFinish={(values: any) => handleSubmit(values)}
    >
      <Row className="d-flex">
        <Col
          className="d-flex"
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          style={{ flexDirection: 'column', background: 'white', borderRadius: '6px', padding: '20px' }}
        >
          <Title level={4}>Descrição</Title>

          <Form.Item name="description">
            <Input.TextArea placeholder="Digite sua pergunta" rows={5} style={{ width: '400px' }} />
          </Form.Item>

          <Row className="d=flex" justify="center" align="middle">
            <Form.Item name="isRepresentativeApp" valuePropName="checked">
              <Checkbox>App. Representante</Checkbox>
            </Form.Item>

            <Form.Item className="ml-2" name="isSellerApp" valuePropName="checked">
              <Checkbox>App. Vendedor</Checkbox>
            </Form.Item>

            <Form.Item className="ml-2" name="isShopkeeperApp" valuePropName="checked">
              <Checkbox>App. Lojista</Checkbox>
            </Form.Item>
          </Row>
        </Col>
      </Row>

      <CustomButton type="primary" htmlType="submit">
        Continuar
      </CustomButton>
    </Form>
  );
};

export default QuestionDescriptionStep;
