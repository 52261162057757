import { useCallback } from 'react';
import { CheckOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { Col, notification, Row, Spin, Typography, Upload } from 'antd';

import HeaderButton from '@components/HeaderButton';
import { useImages } from '@hooks/ImagesContext';
import { getBase64 } from '@utils/file-to-base64';

const { Title } = Typography;

const UploadImagesHeader: React.FC = () => {
  const { fileList, updateFileList, clearImages, uploadImages, loading } = useImages();

  const emptyFunction = useCallback(() => {
    return;
  }, []);

  const beforeUpload = useCallback(
    async (file: File) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

      if (!isJpgOrPng)
        return notification.error({
          message: 'Formato inválido',
          description: `Apenas imagens JPG/PNG são aceitas! Verifique o arquivo: ${file.name}.`,
        });

      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M)
        return notification.error({
          message: 'Tamanho inválido',
          description: `Imagem ultrapassa o limite de 10MB! Verifique o arquivo: ${file.name}`,
        });

      const url = await getBase64(file);
      const splitedName = file.name.split('#');

      if (splitedName.length !== 3)
        return notification.error({
          message: 'Nome inválido',
          description: `Nome da imagem fora dos padrões especificados! Verifique o arquivo: ${file.name}`,
        });

      const reference = splitedName[0];
      const color = splitedName[1];
      const sequence = splitedName[2].split('.')[0];

      const index = fileList.findIndex(
        (_file) => _file.reference === reference && _file.color === color && _file.sequence === sequence,
      );

      if (index !== -1)
        return notification.error({
          message: 'Ação inválida',
          description: `Imagem já foi selecionada! Verifique o arquivo: ${file.name}`,
        });

      updateFileList({ file, url, reference, color, sequence });

      return isJpgOrPng && isLt2M;
    },
    [notification, fileList, updateFileList],
  );

  const getHeaderButtons = () => {
    if (loading)
      return (
        <div className="d-flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
          <Spin className='mr-4'/>
          <strong style={{ width: '260px' }}>Enviando imagens...</strong>
        </div>
      );

    if (!fileList || fileList.length === 0)
      return (
        <Upload
          showUploadList={false}
          beforeUpload={beforeUpload}
          listType="picture"
          multiple
          customRequest={emptyFunction}
        >
          <HeaderButton
            text="Carregar Imagens"
            onClick={emptyFunction}
            Icon={<UploadOutlined style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
          />
        </Upload>
      );

    return (
      <Row>
        <Col>
          <HeaderButton
            text="Cancelar"
            onClick={clearImages}
            Icon={<CloseOutlined style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
          />
        </Col>
        <Col>
          <HeaderButton
            text="Confirmar"
            onClick={uploadImages}
            Icon={<CheckOutlined style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
          />
        </Col>
      </Row>
    );
  };

  return (
    <Row className="mt-8" justify="space-between">
      <Col>
        <Title level={2}>Cadastrar Fotos</Title>
      </Col>
      <Col>{getHeaderButtons()}</Col>
    </Row>
  );
};

export default UploadImagesHeader;
