import React, { useEffect } from 'react';

import { useManufacturer } from '@hooks/ManufacturerContext';
import Loading from '@components/Loading';
import ManufacturerContent from './components/ManufacturerContent';
import ManufacturerHeader from './components/ManufacturerHeader';

const ManufacturerPage: React.FC = () => {
  const { loadRequestManufacturer, loading } = useManufacturer();

  useEffect(() => {
    loadRequestManufacturer();
  }, []);


  if (loading) return <Loading />;

  return (
    <>
      <ManufacturerHeader />
      <ManufacturerContent />
    </>
  );
};

export default ManufacturerPage;
