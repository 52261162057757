import Loading from '@components/Loading';
import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';

import { useOfficialStore } from '@hooks/OfficialStoreContext';
import OfficialStorePaymentHeader from './components/OfficialStorePaymentHeader';
import OfficialStorePaymentContent from './components/OfficialStorePaymentContent';
import { usePaymentConditions } from '@hooks/PaymentConditionsContext';

interface MatchParams {
  id: string;
}

const OfficialStorePaymentPage: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();
  const { actualOfficialStore, findOfficialStoreById, loading } = useOfficialStore();
  const { loadRequestPaymentConditions, loadingPaymentConditions, paymentConditions } = usePaymentConditions();

  useEffect(() => {
    findOfficialStoreById(parseInt(params.id));
    loadRequestPaymentConditions(0, { isActive: true });
  }, []);

  if (loading || loadingPaymentConditions) return <Loading />;

  return (
    <>
      <OfficialStorePaymentHeader officialStore={actualOfficialStore} />
      <OfficialStorePaymentContent officialStore={actualOfficialStore} paymentConditions={paymentConditions} />
    </>
  );
};

export default OfficialStorePaymentPage;
