import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { states } from '@components/SalesRegionForm/models/sales-order-form-models';
import { useDevolution } from '@hooks/DevolutionsContext';
import { Devolution, DevolutionInvoice, DevolutionObservation } from '@models/Devolution';
import { InvoiceItemObservation } from '@models/InvoiceItem';
import { DevolutionItemObservationStatusEnum, DevolutionStatusEnum } from '@models/enum/DevolutionStatusEnum';
import { CustomTextArea } from '@pages/CollectionDetailsPage/components/CustomerNotification/styles';
import DevolutionStatusColumn from '@pages/DevolutionPage/components/DevolutionColumns/DevolutionStatusColumn';
import { CustomButton } from '@styles/globals';
import { formatCep } from '@utils/cep-mask';
import { Col, Form, Modal, Row, Select, Switch, Table, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CustomInput } from '../../styles';
import './styles.css';
const { Title } = Typography;

interface DevolutionInvoiceDataFormProps {
  formValues?: { devolution: Devolution; updateObj?: any };
  handleSubmit: Function;
}

const DevolutionInvoiceDataForm: React.FC<DevolutionInvoiceDataFormProps> = ({ formValues, handleSubmit }) => {
  const { loadingDevolutions, currentDevolution, loadByCodeAndCnpj } = useDevolution();

  const [devolutionForm] = Form.useForm();
  const [devolutionOriginAddressForm] = Form.useForm();

  const [products, setProducts] = React.useState<any[]>([]);
  const [history, setHistory] = React.useState<DevolutionObservation[]>([]);
  const [invoices, setInvoices] = React.useState<DevolutionInvoice[]>([]);
  const [sendPac, setSendPac] = React.useState<boolean>(false);
  const [disableAddressFields, setDisableAddressFields] = React.useState<boolean>(false);

  React.useEffect(() => {
    devolutionForm.setFields([
      { name: 'cnpj', value: null },
      { name: 'invoiceCode', value: null },
      { name: 'observation', value: null },
      { name: 'responsibleName', value: null },
      { name: 'addressPublicPlace', value: null },
      { name: 'addressName', value: null },
      { name: 'addressNumber', value: null },
      { name: 'addressComplement', value: null },
      { name: 'addressNeighborhood', value: null },
      { name: 'addressCityName', value: null },
      { name: 'addressUf', value: null },
      { name: 'addressCep', value: null },
      { name: 'addressBcbCountryCode', value: null },
      { name: 'addressCountryName', value: null },
      { name: 'addressPostOfficeBox', value: null },
      { name: 'addressReference', value: null },
      { name: 'pacCode', value: null },
    ]);

    devolutionOriginAddressForm.setFields([
      { name: 'responsibleName', value: null },
      { name: 'addressPublicPlace', value: null },
      { name: 'addressName', value: null },
      { name: 'addressNumber', value: null },
      { name: 'addressComplement', value: null },
      { name: 'addressNeighborhood', value: null },
      { name: 'addressCityName', value: null },
      { name: 'addressUf', value: null },
      { name: 'addressCep', value: null },
      { name: 'addressBcbCountryCode', value: null },
      { name: 'addressCountryName', value: null },
      { name: 'addressPostOfficeBox', value: null },
      { name: 'addressReference', value: null },
    ]);
  }, []);

  React.useEffect(() => {
    if (formValues) {
      if (formValues?.updateObj) {
        devolutionForm.setFieldsValue({
          invoiceCode: formValues?.devolution?.invoiceCode,
          responsibleName: formValues?.updateObj?.devolutionDestinationAddress?.responsibleName,
          addressPublicPlace: formValues?.updateObj?.devolutionDestinationAddress?.addressPublicPlace,
          addressName: formValues?.updateObj?.devolutionDestinationAddress?.addressName,
          addressNumber: formValues?.updateObj?.devolutionDestinationAddress?.addressNumber,
          addressComplement: formValues?.updateObj?.devolutionDestinationAddress?.addressComplement,
          addressNeighborhood: formValues?.updateObj?.devolutionDestinationAddress?.addressNeighborhood,
          addressCityName: formValues?.updateObj?.devolutionDestinationAddress?.addressCityName,
          addressUf: formValues?.updateObj?.devolutionDestinationAddress?.addressUf,
          addressCep: formatCep(formValues?.updateObj?.devolutionDestinationAddress?.addressCep),
          addressBcbCountryCode: formValues?.updateObj?.devolutionDestinationAddress?.addressBcbCountryCode,
          addressCountryName: formValues?.updateObj?.devolutionDestinationAddress?.addressCountryName,
          addressPostOfficeBox: formValues?.updateObj?.devolutionDestinationAddress?.addressPostOfficeBox,
          addressReference: formValues?.updateObj?.devolutionDestinationAddress?.addressReference,
          observation: formValues?.updateObj?.observation,
        });
      } else {
        devolutionForm.setFieldsValue({
          invoiceCode: formValues?.devolution?.invoiceCode,
        });

        const loadCurrentDevolution = async () => {
          await loadByCodeAndCnpj(formValues?.devolution?.invoiceCode, formValues?.devolution?.cnpj);
        };

        loadCurrentDevolution();

        const cnpj: string = formValues?.devolution?.cnpj.toString();
        formatCnpj(cnpj);
      }
    }
  }, [formValues]);

  React.useEffect(() => {
    if (currentDevolution) {
      const devolutionProducts: any = [];

      currentDevolution?.invoice?.items.forEach((product, index) => {
        devolutionProducts.push({
          name: product?.name,
          quantity: product?.quantity,
          ...currentDevolution?.invoiceItemObservations[index],
        });
      });

      setProducts(
        devolutionProducts.filter((product: InvoiceItemObservation) => {
          if (Number(product?.itemDevolutionQuantity) > 0) {
            return product;
          }
        }) || [],
      );
      setHistory(
        currentDevolution?.observations?.map((obser: any) => {
          return {
            ...obser,
            ...obser?.collaborator,
            collaboratorName: (obser?.collaborator?.firstName || '') + ' ' + (obser?.collaborator?.lastName || ''),
          };
        }) || [],
      );

      if (
        currentDevolution.status === DevolutionStatusEnum.IN_DEVOLUTION ||
        currentDevolution.status === DevolutionStatusEnum.FINISHED
      ) {
        setDisableAddressFields(true);
      } else {
        setDisableAddressFields(false);
      }

      if (currentDevolution?.devolutionDestinationAddress) {
        devolutionForm.setFieldsValue({
          responsibleName: currentDevolution?.devolutionDestinationAddress?.responsibleName,
          addressPublicPlace: currentDevolution?.devolutionDestinationAddress?.addressPublicPlace,
          addressName: currentDevolution?.devolutionDestinationAddress?.addressName,
          addressNumber: currentDevolution?.devolutionDestinationAddress?.addressNumber,
          addressComplement: currentDevolution?.devolutionDestinationAddress?.addressComplement,
          addressNeighborhood: currentDevolution?.devolutionDestinationAddress?.addressNeighborhood,
          addressCityName: currentDevolution?.devolutionDestinationAddress?.addressCityName,
          addressUf: currentDevolution?.devolutionDestinationAddress?.addressUf,
          addressCep: formatCep(currentDevolution?.devolutionDestinationAddress?.addressCep),
          addressBcbCountryCode: currentDevolution?.devolutionDestinationAddress?.addressBcbCountryCode,
          addressCountryName: currentDevolution?.devolutionDestinationAddress?.addressCountryName,
          addressPostOfficeBox: currentDevolution?.devolutionDestinationAddress?.addressPostOfficeBox,
          addressReference: currentDevolution?.devolutionDestinationAddress?.addressReference,
        });
      }

      if (currentDevolution?.devolutionOriginAddress) {
        devolutionOriginAddressForm.setFieldsValue({
          responsibleName: currentDevolution?.devolutionOriginAddress?.responsibleName,
          addressPublicPlace: currentDevolution?.devolutionOriginAddress?.addressPublicPlace,
          addressName: currentDevolution?.devolutionOriginAddress?.addressName,
          addressNumber: currentDevolution?.devolutionOriginAddress?.addressNumber,
          addressComplement: currentDevolution?.devolutionOriginAddress?.addressComplement,
          addressNeighborhood: currentDevolution?.devolutionOriginAddress?.addressNeighborhood,
          addressCityName: currentDevolution?.devolutionOriginAddress?.addressCityName,
          addressUf: currentDevolution?.devolutionOriginAddress?.addressUf,
          addressCep: formatCep(currentDevolution?.devolutionOriginAddress?.addressCep),
          addressBcbCountryCode: currentDevolution?.devolutionOriginAddress?.addressBcbCountryCode,
          addressCountryName: currentDevolution?.devolutionOriginAddress?.addressCountryName,
          addressPostOfficeBox: currentDevolution?.devolutionOriginAddress?.addressPostOfficeBox,
          addressReference: currentDevolution?.devolutionOriginAddress?.addressReference,
        });
      }

      if (currentDevolution?.devolutionInvoices) {
        setInvoices(currentDevolution?.devolutionInvoices);
      }
    }
  }, [currentDevolution]);

  const submit = async (status: string) => {
    const values = await devolutionForm.validateFields();

    const updataObj = {
      sequence: formValues?.devolution?.sequence,
      statusObservation: status,
      observation: values?.observation,
      devolutionDestinationAddress: {
        responsibleName: values?.responsibleName,
        addressPublicPlace: values?.addressPublicPlace,
        addressName: values?.addressName,
        addressNumber: values?.addressNumber,
        addressComplement: values?.addressComplement,
        addressNeighborhood: values?.addressNeighborhood,
        addressCityName: values?.addressCityName,
        addressUf: values?.addressUf,
        addressCep: values?.addressCep?.replace(/\D/g, ''),
        addressBcbCountryCode: values?.addressBcbCountryCode,
        addressCountryName: values?.addressCountryName,
        addressPostOfficeBox: values?.addressPostOfficeBox,
        addressReference: values?.addressReference,
      },
      ...(sendPac && { pacCode: values?.pacCode }),
    };

    handleSubmit(updataObj);
  };

  const showModalConfirmation = (status: string) => {
    Modal.confirm({
      title: `${status === 'APPROVED' ? 'Aprovar' : 'Reprovar'} solicitação de devolução`,
      icon: <ExclamationCircleOutlined />,
      content: `Tem certeza que deseja ${status === 'APPROVED' ? 'aprovar' : 'reprovar'} a solicitação de devolução?`,
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk() {
        submit(status);
      },
    });
  };

  const showSeeMoreModal = (value: string) => {
    Modal.info({
      content: value,
      icon: null,
      cancelText: 'Fechar',
    });
  };

  const formatCnpj = (value: string) => {
    if (value) {
      const cnpjRegex = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
      const numericValue = value.replace(/\D/g, '');

      if (numericValue.length === 14) {
        devolutionForm.setFieldsValue({
          cnpj: value.replace(cnpjRegex, '$1.$2.$3/$4-$5'),
        });
      } else {
        devolutionForm.setFieldsValue({
          cnpj: numericValue,
        });
      }
    }
  };

  const buildStatusTag = (status: string) => {
    const style = {
      borderRadius: 5,
      padding: '5px 15px',
      margin: 0,
      width: 120,
      color: '#973D20',
      backgroundColor: '#FFF0EB',
      border: '1px solid #973D20',
    };

    if (status === DevolutionItemObservationStatusEnum.APPROVED) {
      return (
        <p
          style={{
            ...style,
            color: '#2f7324',
            backgroundColor: '#dcfad7',
            border: '1px solid #2f7324',
          }}
        >
          Aprovado
        </p>
      );
    }

    if (status === DevolutionItemObservationStatusEnum.REJECTED) {
      return (
        <p
          style={{
            ...style,
            color: '#973D20',
            backgroundColor: '#FFF0EB',
            border: '1px solid #973D20',
          }}
        >
          Reprovado
        </p>
      );
    }
  };

  const buildUserType = (type?: string) => {
    if (type === 'COMMERCIAL') return <span>Comercial</span>;
    if (type === 'ACCOUNTING') return <span>Contabilidade</span>;
    if (type === 'ADMIN') return <span>Administração</span>;
    if (type === 'USER') return <span>Usuário</span>;
  };

  if (loadingDevolutions) {
    return (
      <div
        style={{ width: '1200px', height: '1000px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <LoadingOutlined
          type="play-circle-o"
          style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
        />
      </div>
    );
  }

  const downloadFile = (url: string) => {
    const fileName = url?.split('/')?.[url.split('/').length - 1];
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <Form
        form={devolutionForm}
        className="d-flex interleaved-table-style"
        style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}
      >
        <Row
          className="d-flex"
          style={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'bottom',
            borderBottom: '1px solid #d9d9d9',
            marginBottom: '30px',
            paddingBottom: '15px',
          }}
        >
          <div style={{ paddingRight: 15, display: 'flex', fontSize: 18, alignItems: 'center' }}>
            <strong style={{ display: 'flex', margin: 0 }}>Código da devolução:</strong>
            <p style={{ margin: 0, marginLeft: '10px' }}>{currentDevolution?.sequence}</p>
          </div>

          <DevolutionStatusColumn devolution={currentDevolution} />
        </Row>

        <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Col span={12} style={{ paddingRight: 15 }}>
            <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
              CNPJ <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
            </Title>
            <Form.Item name="cnpj">
              <CustomInput
                disabled={true}
                maxLength={18}
                style={{ width: '100%' }}
                placeholder="CNPJ"
                onChange={(e) => {
                  formatCnpj(e.target.value);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={12} style={{ paddingLeft: 15 }}>
            <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
              Número da nota fiscal <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
            </Title>
            <Form.Item name="invoiceCode">
              <CustomInput disabled={true} style={{ width: '100%' }} placeholder="Número da nota fiscal" />
            </Form.Item>
          </Col>
        </Row>

        <>
          <Row className="d-flex" style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'start' }}>
            <Col span={24}>
              <Row style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 style={{ fontWeight: 'bold', paddingBottom: '10px' }}>Itens</h2>
              </Row>

              <Table
                size="middle"
                showHeader={true}
                pagination={false}
                dataSource={products}
                loading={loadingDevolutions}
                rowKey={() => uuidv4()}
                scroll={{ y: 400 }}
                columns={[
                  {
                    title: 'Descrição',
                    dataIndex: 'name',
                  },
                  {
                    title: 'Qtde.',
                    dataIndex: 'quantity',
                    align: 'center',
                  },
                  {
                    title: 'qtde. de devolução',
                    align: 'center',
                    width: '100px',
                    render: (item: any) => <span>{item?.itemDevolutionQuantity}</span>,
                  },
                  {
                    title: 'Observações',
                    dataIndex: 'observation',
                    align: 'center',
                    render: (observation: any) => <span>{observation}</span>,
                  },
                ]}
              />
            </Col>
          </Row>
          {history?.length > 0 && (
            <Row
              className="d-flex"
              style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'start', marginTop: '30px' }}
            >
              <Row style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 style={{ fontWeight: 'bold', paddingBottom: '10px' }}>Histórico de observações</h2>
              </Row>
              <Col span={24}>
                <Table
                  size="middle"
                  showHeader={true}
                  pagination={false}
                  dataSource={history}
                  loading={loadingDevolutions}
                  rowKey={() => uuidv4()}
                  scroll={{ y: 400 }}
                  columns={[
                    {
                      title: 'Departamento',
                      render: (obs: DevolutionObservation | any) => {
                        return <>{buildUserType(obs?.collaboratorId ? obs?.type : obs?.userType)}</>;
                      },
                    },
                    {
                      title: 'Responsável',
                      align: 'center',
                      render: (obs: DevolutionObservation | any) => {
                        return <> {obs?.collaboratorId ? obs?.collaboratorName : obs?.userName} </>;
                      },
                    },
                    {
                      title: 'Status',
                      dataIndex: 'status',
                      align: 'center',
                      render: (status: string) => {
                        return (
                          <div
                            style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                          >
                            {buildStatusTag(status)}
                          </div>
                        );
                      },
                    },
                    {
                      title: 'Observação',
                      dataIndex: 'observation',
                      width: '350px',
                      align: 'left',
                      render: (value: string) => {
                        return (
                          <>
                            {value?.length > 80 ? (
                              <div>
                                <span>{value.substring(0, 80)}...</span>
                                <span
                                  style={{
                                    color: '#267AFA',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    marginLeft: '5px',
                                  }}
                                  onClick={() => showSeeMoreModal(value)}
                                >
                                  Ver tudo
                                </span>
                              </div>
                            ) : (
                              <span>{value}</span>
                            )}
                          </>
                        );
                      },
                    },
                    {
                      title: 'Data',
                      dataIndex: 'createdAt',
                      align: 'left',
                      render: (createdAt: string) => <span>{moment.utc(createdAt).format('DD/MM/YYYY')}</span>,
                    },
                  ]}
                />
              </Col>
            </Row>
          )}
          {invoices?.length > 0 && (
            <Row
              className="d-flex"
              style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'start', marginTop: '30px' }}
            >
              <Row style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 style={{ fontWeight: 'bold', paddingBottom: '10px' }}>Nota fiscal</h2>
              </Row>
              <Col span={24}>
                <Table
                  size="middle"
                  showHeader={true}
                  pagination={false}
                  dataSource={invoices}
                  loading={loadingDevolutions}
                  rowKey={() => uuidv4()}
                  scroll={{ y: 400 }}
                  columns={[
                    {
                      title: 'ID',
                      dataIndex: 'sequence',
                      align: 'left',
                    },
                    {
                      title: 'Data',
                      dataIndex: 'createdAt',
                      align: 'left',
                      render: (createdAt: string) => <span>{moment.utc(createdAt).format('DD/MM/YYYY')}</span>,
                    },
                    {
                      title: 'Anexo',
                      dataIndex: 'url',
                      align: 'left',
                      render: (url: string) => (
                        <span
                          onClick={() => downloadFile(url)}
                          style={{ color: '#512874', textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          Download
                        </span>
                      ),
                    },
                  ]}
                />
              </Col>
            </Row>
          )}

          {currentDevolution?.devolutionOriginAddress && (
            <Form
              form={devolutionOriginAddressForm}
              className="d-flex"
              style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}
            >
              <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', marginTop: '30px' }}>
                <Col span={24}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={4}>
                    Endereço de origem
                  </Title>
                </Col>
              </Row>

              <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', marginTop: '20px' }}>
                <Col span={24}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Nome do Responsável
                    <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                  </Title>
                  <Form.Item
                    name="responsibleName"
                    rules={[{ required: true, message: 'Informe o nome do responsável' }]}
                  >
                    <CustomInput disabled={true} style={{ width: '100%' }} placeholder="Informe o responsável" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col span={12} style={{ paddingRight: '12px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Nome do País
                    <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                  </Title>
                  <Form.Item name="addressCountryName" rules={[{ required: true, message: 'Informe o nome do país' }]}>
                    <CustomInput disabled={true} style={{ width: '100%' }} placeholder="Informe o nome do país" />
                  </Form.Item>
                </Col>

                <Col span={12} style={{ paddingLeft: '12px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Código do País (BCB)
                  </Title>
                  <Form.Item name="addressBcbCountryCode">
                    <CustomInput
                      disabled={true}
                      style={{ width: '100%' }}
                      placeholder="Informe o código do país (BCB)"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col span={12} style={{ paddingRight: '12px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    UF
                    <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                  </Title>
                  <Form.Item name="addressUf" rules={[{ required: true, message: 'Informe o a UF' }]}>
                    <Select
                      disabled={true}
                      style={{ height: '48px', borderRadius: '8px' }}
                      placeholder="Selecione a UF"
                      size="large"
                      showSearch
                      allowClear
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {states?.length &&
                        states.map((state: { id: number; name: string; UF: string }) => (
                          <Select.Option key={state.UF} value={state.UF}>
                            {state.UF}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12} style={{ paddingLeft: '12px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    CEP
                    <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                  </Title>
                  <Form.Item name="addressCep" rules={[{ required: true, message: 'Informe o CEP' }]}>
                    <CustomInput disabled={true} maxLength={9} style={{ width: '100%' }} placeholder="Informe o CEP" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col span={12} style={{ paddingRight: '12px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Cidade
                    <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                  </Title>
                  <Form.Item name="addressCityName" rules={[{ required: true, message: 'Informe a cidade' }]}>
                    <CustomInput disabled={true} style={{ width: '100%' }} placeholder="Informe a cidade" />
                  </Form.Item>
                </Col>

                <Col span={12} style={{ paddingLeft: '12px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Bairro
                    <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                  </Title>
                  <Form.Item name="addressNeighborhood" rules={[{ required: true, message: 'Informe o bairro' }]}>
                    <CustomInput disabled={true} style={{ width: '100%' }} placeholder="Informe o bairro" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col span={12} style={{ paddingRight: '12px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Logradouro
                    <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                  </Title>
                  <Form.Item name="addressPublicPlace" rules={[{ required: true, message: 'Informe o logradouro' }]}>
                    <CustomInput disabled={true} style={{ width: '100%' }} placeholder="Informe o logradouro" />
                  </Form.Item>
                </Col>

                <Col span={12} style={{ paddingLeft: '12px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Nome do Endereço
                    <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                  </Title>
                  <Form.Item name="addressName" rules={[{ required: true, message: 'Informe o nome do endereço' }]}>
                    <CustomInput disabled={true} style={{ width: '100%' }} placeholder="Informe o nome do endereço" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col span={12} style={{ paddingRight: '12px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Número <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                  </Title>
                  <Form.Item name="addressNumber" rules={[{ required: true, message: 'Informe o número' }]}>
                    <CustomInput
                      disabled={true}
                      style={{ width: '100%' }}
                      type="number"
                      placeholder="Informe o número"
                    />
                  </Form.Item>
                </Col>

                <Col span={12} style={{ paddingLeft: '12px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Complemento
                  </Title>
                  <Form.Item name="addressComplement">
                    <CustomInput disabled={true} style={{ width: '100%' }} placeholder="Informe o complemento" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col span={12} style={{ paddingRight: '12px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Caixa Postal
                  </Title>
                  <Form.Item name="addressPostOfficeBox">
                    <CustomInput disabled={true} style={{ width: '100%' }} placeholder="Informe a caixa postal" />
                  </Form.Item>
                </Col>

                <Col span={12} style={{ paddingLeft: '12px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Referência
                  </Title>
                  <Form.Item name="addressReference">
                    <CustomInput disabled={true} style={{ width: '100%' }} placeholder="Informe a referência" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', marginTop: '30px' }}>
            <Col span={24}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={4}>
                Endereço de destino
              </Title>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', marginTop: '20px' }}>
            <Col span={24}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Nome do Responsável
                <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item name="responsibleName" rules={[{ required: true, message: 'Informe o nome do responsável' }]}>
                <CustomInput
                  disabled={disableAddressFields}
                  style={{ width: '100%' }}
                  placeholder="Informe o responsável"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
            <Col span={12} style={{ paddingRight: '12px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Nome do País
                <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item name="addressCountryName" rules={[{ required: true, message: 'Informe o nome do país' }]}>
                <CustomInput
                  disabled={disableAddressFields}
                  style={{ width: '100%' }}
                  placeholder="Informe o nome do país"
                />
              </Form.Item>
            </Col>

            <Col span={12} style={{ paddingLeft: '12px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Código do País (BCB)
              </Title>
              <Form.Item name="addressBcbCountryCode">
                <CustomInput
                  disabled={disableAddressFields}
                  style={{ width: '100%' }}
                  placeholder="Informe o código do país (BCB)"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
            <Col span={12} style={{ paddingRight: '12px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                UF
                <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item name="addressUf" rules={[{ required: true, message: 'Informe o a UF' }]}>
                <Select
                  disabled={disableAddressFields}
                  style={{ height: '48px', borderRadius: '8px' }}
                  placeholder="Selecione a UF"
                  size="large"
                  showSearch
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {states?.length &&
                    states.map((state: { id: number; name: string; UF: string }) => (
                      <Select.Option key={state.UF} value={state.UF}>
                        {state.UF}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12} style={{ paddingLeft: '12px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                CEP
                <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item name="addressCep" rules={[{ required: true, message: 'Informe o CEP' }]}>
                <CustomInput
                  onChange={(e) => {
                    if (e?.target?.value?.length === 8) {
                      devolutionForm.setFieldsValue({
                        addressCep: formatCep(e.target.value),
                      });
                    } else {
                      devolutionForm.setFieldsValue({
                        addressCep: e.target.value,
                      });
                    }
                  }}
                  disabled={disableAddressFields}
                  maxLength={9}
                  style={{ width: '100%' }}
                  placeholder="Informe o CEP"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
            <Col span={12} style={{ paddingRight: '12px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Cidade
                <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item name="addressCityName" rules={[{ required: true, message: 'Informe a cidade' }]}>
                <CustomInput disabled={disableAddressFields} style={{ width: '100%' }} placeholder="Informe a cidade" />
              </Form.Item>
            </Col>

            <Col span={12} style={{ paddingLeft: '12px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Bairro
                <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item name="addressNeighborhood" rules={[{ required: true, message: 'Informe o bairro' }]}>
                <CustomInput disabled={disableAddressFields} style={{ width: '100%' }} placeholder="Informe o bairro" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
            <Col span={12} style={{ paddingRight: '12px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Logradouro
                <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item name="addressPublicPlace" rules={[{ required: true, message: 'Informe o logradouro' }]}>
                <CustomInput
                  disabled={disableAddressFields}
                  style={{ width: '100%' }}
                  placeholder="Informe o logradouro"
                />
              </Form.Item>
            </Col>

            <Col span={12} style={{ paddingLeft: '12px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Nome do Endereço
                <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item name="addressName" rules={[{ required: true, message: 'Informe o nome do endereço' }]}>
                <CustomInput
                  disabled={disableAddressFields}
                  style={{ width: '100%' }}
                  placeholder="Informe o nome do endereço"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
            <Col span={12} style={{ paddingRight: '12px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Número
                <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item name="addressNumber" rules={[{ required: true, message: 'Informe o número' }]}>
                <CustomInput
                  disabled={disableAddressFields}
                  style={{ width: '100%' }}
                  type="number"
                  placeholder="Informe o número"
                />
              </Form.Item>
            </Col>

            <Col span={12} style={{ paddingLeft: '12px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Complemento
              </Title>
              <Form.Item name="addressComplement">
                <CustomInput
                  disabled={disableAddressFields}
                  style={{ width: '100%' }}
                  placeholder="Informe o complemento"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
            <Col span={12} style={{ paddingRight: '12px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Caixa Postal
              </Title>
              <Form.Item name="addressPostOfficeBox">
                <CustomInput
                  disabled={disableAddressFields}
                  style={{ width: '100%' }}
                  placeholder="Informe a caixa postal"
                />
              </Form.Item>
            </Col>

            <Col span={12} style={{ paddingLeft: '12px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Referência
              </Title>
              <Form.Item name="addressReference">
                <CustomInput
                  disabled={disableAddressFields}
                  style={{ width: '100%' }}
                  placeholder="Informe a referência"
                />
              </Form.Item>
            </Col>
          </Row>
        </>

        {currentDevolution?.status !== DevolutionStatusEnum.FINISHED && (
          <>
            <Row style={{ width: '100%' }}>
              <div
                className="d-flex"
                style={{
                  width: '100%',
                  marginTop: '10px',
                  justifyContent: 'start',
                  alignItems: 'center',
                  marginBottom: '30px',
                }}
              >
                <Switch
                  checked={sendPac}
                  onChange={(value: boolean) => {
                    setSendPac(value);
                  }}
                />
                <p style={{ margin: 0, marginLeft: 10 }}>Deseja enviar o PAC reverso manualmente?</p>
              </div>
            </Row>

            {sendPac && (
              <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <Col span={24} style={{ paddingRight: 15 }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Código do PAC <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                  </Title>
                  <Form.Item name="pacCode" rules={[{ required: true, message: 'Informe o código do PAC' }]}>
                    <CustomInput style={{ width: '100%' }} placeholder="Código do PAC" />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
              <Col span={24} style={{ paddingRight: 15 }}>
                <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                  Observação da aprovação ou rejeição <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                </Title>
                <Form.Item name="observation" rules={[{ required: true, message: 'Informe a observação' }]}>
                  <CustomTextArea
                    style={{ width: '100%', height: '200px' }}
                    placeholder="Observação da aprovação ou rejeição *"
                  />
                </Form.Item>
              </Col>
            </Row>

            <CustomButton
              type="primary"
              style={{ marginTop: 40, width: '100%' }}
              onClick={() => showModalConfirmation(DevolutionItemObservationStatusEnum.APPROVED)}
            >
              Aprovar
            </CustomButton>

            <CustomButton
              style={{ width: '100%' }}
              onClick={() => showModalConfirmation(DevolutionItemObservationStatusEnum.REJECTED)}
            >
              Rejeitar
            </CustomButton>
          </>
        )}
      </Form>
    </>
  );
};

export default DevolutionInvoiceDataForm;
