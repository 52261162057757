import LayoutHeader from '@components/LayoutHeader';
import { useManufacturer } from '@hooks/ManufacturerContext';
import { EntityWithLabel } from '@models/EntityWithLabel';
import { CustomButton } from '@styles/globals';
import { Form, Select } from 'antd';
import React, { useEffect } from 'react';

const IntegrationPage: React.FC = () => {
  const { loadEntities, entities, loading, loadingForceImport, forceImport } = useManufacturer();

  useEffect(() => {
    loadEntities();
  }, []);

  const handleSubmit = ({ entity }: any) => {
    forceImport(entity);
  };

  return (
    <>
      <LayoutHeader title="Integração" />
      <div className="d-flex justify-center">
        <Form style={{ width: '50%' }} className="mt-8" layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            className="mb-2"
            label="Entidade para importação"
            name="entity"
            rules={[{ required: true, message: 'Informe a entidade para importação.' }]}
          >
            <Select
              placeholder="Entidade para importação"
              loading={loading || loadingForceImport}
              disabled={loading || loadingForceImport}
            >
              {entities.map(({ label, entity }: EntityWithLabel) => {
                return (
                  <Select.Option key={entity} value={entity}>
                    {label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <CustomButton htmlType="submit" type="primary" className="w-100" loading={loadingForceImport}>
            Importar
          </CustomButton>
        </Form>
      </div>
    </>
  );
};

export default IntegrationPage;
