import React, { useCallback, useState } from 'react';
import { Dropdown, Menu, Table, Typography } from 'antd';
import { EllipsisOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useGoal } from '@hooks/GoalContext';
import TableFilters from '@components/TableFilters';
import { GOAL_PAGINATION_SIZE } from '@utils/constants';
import { Goal } from '@models/Goal';
import './styles.css';
import { FullScreenModal } from '@styles/globals';
import CreateGoal from '@components/CreateGoal';

const { Text } = Typography;

const GoalsContent: React.FC = () => {
  const [modalCreateGoalIsVisible, setModalCreateGoalIsVisible] = useState<boolean>(false);

  const onCloseModalCreateGoal = useCallback(() => {
    setModalCreateGoalIsVisible(false);
  }, [setModalCreateGoalIsVisible]);

  const onShowModalCreateGoal = useCallback(() => {
    setModalCreateGoalIsVisible(true);
  }, [setModalCreateGoalIsVisible]);

  const history = useHistory();
  const { url } = useRouteMatch();
  const [selectedGoal, setSelectedGoal] = useState<Goal>();
  const {
    loadingGoals,
    goals,
    goalsTotal,
    goalsCurrentPage,
    filtersObjectGoalItems,
    loadGoals,
    updateGoalStatus,
    loadingDownloadGoalResultsFile,
    downloadGoalResultsFile,
    deleteGoalItems,
    loadingDeleteGoalItems,
    uploadGoalFileSheet,
    loadingUpdateGoalStatus,
  } = useGoal();

  const handleDeleteGoalItems = useCallback(async () => {
    if (selectedGoal) {
      await deleteGoalItems(selectedGoal.goalId);
      onShowModalCreateGoal();
    }
  }, [deleteGoalItems, selectedGoal, onShowModalCreateGoal]);

  const handleDownloadGoalResults = useCallback(
    (goalId) => {
      downloadGoalResultsFile(goalId);
    },
    [downloadGoalResultsFile, selectedGoal],
  );

  const menuItems = (
    <Menu>
      <Menu.Item key="delete" onClick={handleDeleteGoalItems}>
        Excluir e carregar meta
      </Menu.Item>
      <Menu.Item key="download">Baixar resultado alcançado</Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'ID',
      dataIndex: 'goalId',
      width: '100px',
      align: 'center' as 'center',
      render: (id: string) => <Text>#{id}</Text>,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pelo ID'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.goalId ? record.goalId.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
      sorter: true,
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      render: (goalDesc: any) => <strong style={{ cursor: 'pointer' }}>{goalDesc}</strong>,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pela descrição'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.description ? record.description.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
      sorter: true,
    },
    {
      title: 'Data de Criação',
      dataIndex: 'createdAt',
      render: (date: Date) => <Text>{moment(date).format('DD/MM/YYYY HH:mm')}</Text>,
      sorter: true,
    },
    {
      render: (_goal: Goal) => (
        <Dropdown overlay={menuItems} trigger={['click']}>
          <EllipsisOutlined
            style={{ cursor: 'pointer', fontSize: '24px', fontWeight: 'bolder', color: 'var(--primary-color)' }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Dropdown>
      ),
      onCell: (_goal: Goal) => {
        return {
          onClick: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
            setSelectedGoal(_goal);
            handleDownloadGoalResults(_goal.goalId);
            e.stopPropagation();
          },
        };
      },

      align: 'right' as 'right',
    },
  ];

  const onChangeRowSelection = useCallback(
    (goal: Goal) => {
      updateGoalStatus(goal.goalId, !goal.isActive);
    },
    [updateGoalStatus],
  );

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.hasOwnProperty('column')) {
      filtersObjectGoalItems.field = `${sorter.field}`;
      filtersObjectGoalItems.order = `${sorter.order}`;
    }

    loadGoals(pagination.current, filtersObjectGoalItems);
  };

  const onSubmitFileSheet = async (file: File) => {
    await uploadGoalFileSheet(file);
    onCloseModalCreateGoal();
  };

  const rowSelection = {
    columnTitle: 'Ativa',
    selectedRowKeys: goals?.filter((goal) => goal.isActive).map((goal) => goal.goalId),
    onSelect: onChangeRowSelection,
  };

  return (
    <>
      <Table
        className="mt-8 table-goals"
        loading={loadingGoals || loadingDownloadGoalResultsFile || loadingDeleteGoalItems || loadingUpdateGoalStatus}
        columns={columns}
        dataSource={goals}
        rowKey={(goal) => goal.goalId}
        scroll={{ x: 600 }}
        rowSelection={rowSelection}
        pagination={{
          total: goalsTotal,
          current: goalsCurrentPage,
          pageSize: 10,
          showSizeChanger: false,
        }}
        onRow={(record: Goal) => {
          return {
            onClick: () => {
              history.push(`${url}/${record.goalId}`);
            },
          };
        }}
        onChange={handleTableChange}
      />
      <FullScreenModal
        destroyOnClose={true}
        footer={null}
        forceRender={false}
        visible={modalCreateGoalIsVisible}
        onOk={onCloseModalCreateGoal}
        onCancel={onCloseModalCreateGoal}
        bodyStyle={{ width: '100%' }}
      >
        <CreateGoal
          onSubmitGoal={() => {
            return;
          }}
          onSubmitFileSheet={onSubmitFileSheet}
          newGoalId={selectedGoal?.goalId}
          initialPage={1}
        />
      </FullScreenModal>
    </>
  );
};

export default GoalsContent;
