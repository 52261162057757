import styled from 'styled-components';

export const ButtonContainer = styled.div`
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-radius: 4px;
    background-color: #e6e6e6;
  }

  > * {
    color: var(--primary-color);
    font-size: 16px;
  }
`;
