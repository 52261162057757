import { CloseOutlined } from '@ant-design/icons';
import React from 'react';

import moment from 'moment';
import * as S from './styles';

export type ListFilterType = {
  title: string;
  key: string;
  isDate?: boolean;
};

interface FiltersListProps {
  columns: ListFilterType[];
  dataSource: any;
  onRemove: Function;
}
const FiltersList: React.FC<FiltersListProps> = ({ columns, onRemove, dataSource }) => {
  if (!Object.keys(dataSource)?.length) return <div />;
  return (
    <S.ScrollContainer>
      <S.ListContainer className="d-flex">
        {Object.keys(dataSource).map((objKey, index) => {
          let found = columns.find((col) => col.key === objKey);
          if (found && dataSource[objKey]) {
            if (objKey === 'isActive') {
              return (
                <S.FilterContainer key={index}>
                  <strong>{found.title}:&ensp;</strong>
                  <span>{dataSource[objKey] === 'true' ? 'Ativo' : 'Inativo'}</span>
                  <CloseOutlined
                    className="ml-2"
                    onClick={() => onRemove({ key: objKey, value: dataSource[objKey] })}
                  />
                </S.FilterContainer>
              );
            } else {
              return (
                <S.FilterContainer key={index}>
                  <strong>{found.title}:&ensp;</strong>
                  <span>{found.isDate ? moment(dataSource[objKey]).format('DD/MM/YYYY') : dataSource[objKey]}</span>
                  <CloseOutlined
                    className="ml-2"
                    onClick={() => onRemove({ key: objKey, value: dataSource[objKey] })}
                  />
                </S.FilterContainer>
              );
            }
          }
        })}
      </S.ListContainer>
    </S.ScrollContainer>
  );
};

export default FiltersList;
