import styled from 'styled-components';

export const Container = styled.div`
  width: 320px;
  min-height: 100px;
  background: white;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  cursor: pointer;
`;
