import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Col, Row, Switch, Typography } from 'antd';

import * as S from '../../styles';

const { Title } = Typography;

interface SalesTypePanelProps {}

const SalesTypePanel: React.FC<SalesTypePanelProps> = () => {
  return (
    <Col xs={24} sm={24} lg={8} xxl={6} className="d-flex">
      <S.Panel>
        <Title level={4} style={{ color: '#555' }}>
          Tipo de Venda
        </Title>

        <Row>
          <Col>
            <S.CustomFormItem name="isDeliveryImmediately" label="Pronta-Entrega" valuePropName="checked">
              <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
            </S.CustomFormItem>

            <S.CustomFormItem name="isMakeToOrder" label="Sob-Encomenda" valuePropName="checked">
              <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
            </S.CustomFormItem>
          </Col>
        </Row>
      </S.Panel>
    </Col>
  );
};

export default SalesTypePanel;
