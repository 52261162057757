import React from 'react';
import { Col, Row, Pagination } from 'antd';
import { useHistory } from 'react-router-dom';

import { useSeller } from '@hooks/SellerContext';
import LoadingComponents from '@components/LoadingComponents';
import Card from '../Card';
import { SELLER_PAGINATION_SIZE } from '@utils/constants';

interface SellersContentProps {
  redirectBaseUrl: string;
}

const SellersContent: React.FC<SellersContentProps> = ({ redirectBaseUrl }) => {
  const history = useHistory();
  const { sellers, currentPage, totalPages, loadSellers, loadingSellers, sellerFilters } = useSeller();

  const handlePaginationChange = (page: number) => {
    loadSellers(page - 1, sellerFilters);
  }

  if (loadingSellers) return <LoadingComponents />;

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
        {sellers
          .map((seller: any) => (
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={6}
              xxl={4}
              onClick={() => history.push(`${redirectBaseUrl}/perfil/${seller.sellerCode}`)}
              key={seller.sellerCode}
            >
              <Card name={seller.sellerName} code={seller.sellerCode} imageUrl={seller.photoUrl} />
            </Col>
          ))}
      </Row>
      <Row className="mt-4 mb-8 mr-4" justify="end">
        <Pagination
          defaultCurrent={1}
          total={totalPages}
          current={currentPage + 1}
          pageSize={SELLER_PAGINATION_SIZE}
          showSizeChanger={false}
          onChange={handlePaginationChange}
        />
      </Row>
    </>
  );
};

export default SellersContent;
