import React from 'react';

import { Col, Row, Typography } from 'antd';

const { Title } = Typography;

interface ProfileConnectionsHeaderProps {}

const ProfileConnectionsHeader: React.FC<ProfileConnectionsHeaderProps> = () => {
  return (
    <Row className="mt-8" justify="space-between">
      <Col className="d-flex" style={{ alignItems: 'center', flexDirection: 'column', marginLeft: '12px' }}>
        <Title level={3} style={{ margin: '0' }}>
          Conexões
        </Title>
      </Col>
    </Row>
  );
};

export default ProfileConnectionsHeader;
