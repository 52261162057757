import axios from 'axios';

export interface ZipImagesUrl {
  integration_id: string;
  order_code: string;
  email: string;
}
export async function sendEmailWithZipPhotosOfOrder(body: ZipImagesUrl) {
  return axios.post(process.env.REACT_APP_ZIP_IMAGES_URL || 'http://zip_images_url_is_not_defined', body);
}
