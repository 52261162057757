import { LiquidityBufferAppEnum } from '@models/enum/LiquidityBufferApp';
import { Table } from 'antd';
import React from 'react';
import OfficialStoreStock from '../OfficialStoreStock';

interface OfficialStoreStockContentProps {}

const OfficialStoreStockContent: React.FC<OfficialStoreStockContentProps> = () => {
  const columns = [
    {
      dataIndex: 'description',
      render: (description: string) => <strong>{description.toUpperCase()}</strong>,
    },
  ];
  const liquidityBufferAppEnum = [
    {
      description: 'REPRESENTANTE',
      component: <OfficialStoreStock appType={LiquidityBufferAppEnum.APP_REPRESENTATIVE} />,
    },
    {
      description: 'VENDEDOR',
      component: <OfficialStoreStock appType={LiquidityBufferAppEnum.APP_SELLER} />,
    },
    {
      description: 'LOJISTA',
      component: <OfficialStoreStock appType={LiquidityBufferAppEnum.APP_SHOPKEEPER} />,
    },
  ];
  return (
    <>
      <Table
        className="mt-4 table-antd"
        columns={columns}
        showHeader={false}
        dataSource={liquidityBufferAppEnum}
        rowClassName="row-dark"
        scroll={{ x: 800 }}
        expandable={{
          expandedRowRender: (record) => record.component,
        }}
        rowKey={(record) => record.description}
      />
    </>
  );
};

export default OfficialStoreStockContent;
