import { SharedData } from '@components/ProductForm/models/product-form-models';
import AddRepresentativeEmailFormModal from '@components/RepresentativeForm/components/AddRepresentativeEmailFormModal';
import { RepresentativeEmail } from '@components/RepresentativeForm/models/representative-form-models';
import { Container } from '@pages/MainPage/styles';
import { CustomButton, FullScreenModal } from '@styles/globals';
import { Col, Row, Table, notification } from 'antd';
import { registerRenderer, textRenderer } from 'handsontable/renderers';
import React from 'react';

registerRenderer('customStylesRenderer', (hotInstance, TD, ...rest) => {
  textRenderer(hotInstance, TD, ...rest);
  TD.style.fontSize = '18px';
});

interface RepresentativeFormEmailStepProps {
  sharedData?: SharedData;
  changeSharedData?: Function;
  formValues: RepresentativeEmail[];
  setFormValues: Function;
  goToNextStep: Function;
  goToBackStep: Function;
  isEditing: boolean;
  render: boolean;
}

const RepresentativeFormEmailStep: React.FC<RepresentativeFormEmailStepProps> = ({
  formValues,
  setFormValues,
  goToNextStep,
  goToBackStep,
  isEditing = false,
  render = true,
}) => {
  const [addRepresentativeEmailModalIsVisible, setAddRepresentativeEmailModalIsVisible] = React.useState(false);

  const [selectedEmails, setSelectedEmails] = React.useState<RepresentativeEmail[]>([]);

  const onCloseAddRepresentativeEmailModal = () => setAddRepresentativeEmailModalIsVisible(false);

  const onShowAddRepresentativeEmailModal = () => {
    setAddRepresentativeEmailModalIsVisible(true);
  };

  React.useEffect(() => {
    if (!isEditing && formValues?.length) {
      setSelectedEmails(formValues);
    }
  }, []);

  React.useEffect(() => {
    if (isEditing && formValues?.length) {
      setSelectedEmails(formValues);
    }
  }, [formValues]);

  React.useEffect(() => {
    const values = selectedEmails;
    setFormValues(values);
  }, [selectedEmails]);

  const finishStep = async () => {
    if (selectedEmails?.length > 0) {
      const values = selectedEmails;
      setFormValues(values);
      goToNextStep();
    } else {
      notification.warning({
        message: 'Aviso',
        description: `Adicione pelo menos um e-mail para o representante.`,
      });
    }
  };

  const returnStep = async () => {
    const values = selectedEmails;
    setFormValues(values);
    goToBackStep();
  };

  const removeEmail = (key: any) => {
    const newList = selectedEmails.filter((email: RepresentativeEmail) => email?.key !== key);
    setSelectedEmails(newList);
  };

  const addEmail = (newEmail: RepresentativeEmail) => {
    if (newEmail.isDefault) {
      let emailsCopy = [...selectedEmails, newEmail];
      emailsCopy = emailsCopy.map((e) => {
        if (e.key === newEmail.key) {
          return {
            ...e,
          };
        } else {
          return {
            ...e,
            isDefault: false,
          };
        }
      });
      setSelectedEmails(emailsCopy);
    } else {
      setSelectedEmails((prev) => [...prev, newEmail]);
    }
  };

  const handleSetIsDefault = (email: RepresentativeEmail) => {
    let emailsCopy = [...selectedEmails];
    emailsCopy = emailsCopy.map((e) => {
      if (e.key === email.key) {
        return {
          ...e,
          isDefault: !e.isDefault,
        };
      } else {
        return {
          ...e,
          isDefault: false,
        };
      }
    });

    setSelectedEmails(emailsCopy);
  };

  return (
    <>
      {render && (
        <>
          <div className="d-flex" style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}>
            <Row className="d-flex" style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Col span={5}>
                <CustomButton type="primary" style={{ marginBottom: 10 }} onClick={onShowAddRepresentativeEmailModal}>
                  Adicionar e-mail
                </CustomButton>
              </Col>
            </Row>

            <Row className="d-flex" style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Col span={24}>
                <h2 style={{ margin: '20px 0 30px 0' }}>E-mails adicionados</h2>
              </Col>
            </Row>

            <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <Col span={24}>
                {selectedEmails?.length === 0 && (
                  <Row style={{ width: '100%' }}>
                    <Table
                      bordered={true}
                      showHeader={false}
                      pagination={false}
                      style={{ width: '100%' }}
                      className="table-antd"
                      dataSource={[]}
                    />
                  </Row>
                )}

                {selectedEmails?.length > 0 &&
                  selectedEmails?.map((email: RepresentativeEmail) => {
                    return (
                      <Row
                        key={email.key}
                        style={{
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          margin: '10px 0',
                        }}
                      >
                        <div
                          style={{
                            width: '15%',
                            display: 'flex',
                          }}
                        >
                          <span style={{ marginRight: 5 }}>E-mail padrão:</span>

                          <div className="checkbox">
                            <input
                              checked={email?.isDefault}
                              onChange={() => handleSetIsDefault(email)}
                              type="checkbox"
                              className="checkbox"
                              id={`checkbox-${email.key}`}
                              name={`checkbox-${email.key}`}
                            />
                            <label htmlFor={`checkbox-${email.key}`} style={{ cursor: 'pointer' }}></label>
                          </div>
                        </div>

                        <Row
                          style={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: '1px solid #E5E5E5',
                            padding: '10px',
                            borderRadius: '8px',
                            width: '84%',
                          }}
                        >
                          <span style={{ width: '25%', textTransform: 'capitalize' }}>
                            {email.typeName.toLowerCase()}
                          </span>
                          <span style={{ width: '45%' }}>{email?.email}</span>
                          <p
                            style={{
                              color: 'red',
                              textDecoration: 'underline',
                              margin: 0,
                              padding: 0,
                              cursor: 'pointer',
                              maxWidth: 'fit-content',
                              width: '30%',
                            }}
                            onClick={() => removeEmail(email.key)}
                          >
                            Excluir
                          </p>
                        </Row>
                      </Row>
                    );
                  })}
              </Col>
            </Row>

            <CustomButton type="primary" style={{ marginTop: 40, width: '100%' }} onClick={finishStep}>
              Continuar
            </CustomButton>

            <CustomButton type="default" style={{ marginTop: 20, width: '100%' }} onClick={returnStep}>
              Voltar
            </CustomButton>
          </div>

          <FullScreenModal
            destroyOnClose={true}
            visible={addRepresentativeEmailModalIsVisible}
            onOk={onCloseAddRepresentativeEmailModal}
            onCancel={onCloseAddRepresentativeEmailModal}
            footer={null}
            forceRender={false}
          >
            <Row align="middle" justify="center">
              <Col span={24} className="d-flex" style={{ width: '900px' }}>
                <div className="steps-action d-flex" style={{ width: '100%' }}>
                  <Container
                    style={{
                      background: '#fff',
                      padding: '60px',
                      borderRadius: '8px',
                      width: '100%',
                      height: 'auto',
                    }}
                  >
                    <AddRepresentativeEmailFormModal
                      onCloseModal={onCloseAddRepresentativeEmailModal}
                      addEmail={addEmail}
                    />
                  </Container>
                </div>
              </Col>
            </Row>
          </FullScreenModal>
        </>
      )}
    </>
  );
};

export default RepresentativeFormEmailStep;
