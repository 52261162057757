import React from 'react';
import { Router } from 'react-router-dom';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import history from './services/history';
import GlobalStyle from './styles/globals';
import { keycloak, keycloakProviderInitConfig } from './utils/keycloack-auth';

import { BrandProvider } from '@hooks/BrandContext';
import { CategoryProvider } from '@hooks/CategoryContext';
import { ClassificationProvider } from '@hooks/ClassificationContext';
import { CollaboratorProvider } from '@hooks/CollaboratorContext';
import { CollectionProvider } from '@hooks/CollectionContext';
import { ColorProvider } from '@hooks/ColorContext';
import { CustomerProvider } from '@hooks/CustomerContext';
import { DevolutionProvider } from '@hooks/DevolutionsContext';
import { GoalProvider } from '@hooks/GoalContext';
import { ImagesProvider } from '@hooks/ImagesContext';
import { LinkProvider } from '@hooks/LinkContext';
import { ManufacturerProvider } from '@hooks/ManufacturerContext';
import { OfficialStoreProvider } from '@hooks/OfficialStoreContext';
import { OfficialStoreOrderClassificationProvider } from '@hooks/OfficialStoreOrderClassificationContext';
import { OfficialStoreQuestionsProvider } from '@hooks/OfficialStoreQuestionsContext';
import { OfficialStoreRepresentativesProvider } from '@hooks/OfficialStoreRepresentativesContext';
import { OfficialStoreSellerProvider } from '@hooks/OfficialStoreSellerContext';
import { OfficialStoreStockProvider } from '@hooks/OfficialStoreStockContext';
import { OrderProvider } from '@hooks/OrdersContext';
import { ParametersProvider } from '@hooks/ParametersContext';
import { PaymentConditionsProvider } from '@hooks/PaymentConditionsContext';
import { PriceTableProvider } from '@hooks/PriceTableContext';
import { ProductProvider } from '@hooks/ProductContext';
import { ReferenceProvider } from '@hooks/ReferenceContext';
import { RepresentativeProvider } from '@hooks/RepresentativeContext';
import { SalesRegionProvider } from '@hooks/SalesRegionContext';
import { SellerProvider } from '@hooks/SellerContext';
import { ShoppingBagProvider } from '@hooks/ShoppingBagContext';
import { VideosProvider } from '@hooks/VideosContext';
import { VisitsProvider } from '@hooks/VisitsContext';
import Routes from './routes';
import { GridProvider } from '@hooks/GridContext';
import 'handsontable/dist/handsontable.full.min.css';
import { registerAllModules } from 'handsontable/registry';
import { IntegrationProvider } from '@hooks/IntegrationContext';

registerAllModules();

const App: React.FC = () => {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakProviderInitConfig}
      onTokens={({ token }: any) => {
        localStorage.setItem('token', token);
      }}
    >
      <IntegrationProvider>
        <GridProvider>
          <DevolutionProvider>
            <ShoppingBagProvider>
              <GoalProvider>
                <SalesRegionProvider>
                  <ImagesProvider>
                    <ReferenceProvider>
                      <VisitsProvider>
                        <BrandProvider>
                          <CustomerProvider>
                            <ParametersProvider>
                              <SellerProvider>
                                <PriceTableProvider>
                                  <RepresentativeProvider>
                                    <OfficialStoreProvider>
                                      <OfficialStoreOrderClassificationProvider>
                                        <OfficialStoreRepresentativesProvider>
                                          <OfficialStoreSellerProvider>
                                            <OfficialStoreQuestionsProvider>
                                              <ColorProvider>
                                                <ClassificationProvider>
                                                  <ProductProvider>
                                                    <CollectionProvider>
                                                      <OrderProvider>
                                                        <ManufacturerProvider>
                                                          <CategoryProvider>
                                                            <PaymentConditionsProvider>
                                                              <CollaboratorProvider>
                                                                <LinkProvider>
                                                                  <VideosProvider>
                                                                    <OfficialStoreStockProvider>
                                                                      <Router history={history}>
                                                                        <Routes />
                                                                      </Router>
                                                                    </OfficialStoreStockProvider>
                                                                  </VideosProvider>
                                                                </LinkProvider>
                                                              </CollaboratorProvider>
                                                            </PaymentConditionsProvider>
                                                            <GlobalStyle />
                                                          </CategoryProvider>
                                                        </ManufacturerProvider>
                                                      </OrderProvider>
                                                    </CollectionProvider>
                                                  </ProductProvider>
                                                </ClassificationProvider>
                                              </ColorProvider>
                                            </OfficialStoreQuestionsProvider>
                                          </OfficialStoreSellerProvider>
                                        </OfficialStoreRepresentativesProvider>
                                      </OfficialStoreOrderClassificationProvider>
                                    </OfficialStoreProvider>
                                  </RepresentativeProvider>
                                </PriceTableProvider>
                              </SellerProvider>
                            </ParametersProvider>
                          </CustomerProvider>
                        </BrandProvider>
                      </VisitsProvider>
                    </ReferenceProvider>
                  </ImagesProvider>
                </SalesRegionProvider>
              </GoalProvider>
            </ShoppingBagProvider>
          </DevolutionProvider>
        </GridProvider>
      </IntegrationProvider>
    </ReactKeycloakProvider>
  );
};

export default App;
