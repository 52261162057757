import { Col, Form, Modal, Row, Select } from 'antd';
import { useEffect, useState } from 'react';

import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useOrder } from '@hooks/OrdersContext';
import { FailedOrder } from '@models/FailedOrder';
import { Order } from '@models/Order';
import { StatusOrderTypeEnumArray } from '@models/enum/StatusOrder';
import { CustomButton } from '@styles/globals';
import Title from 'antd/lib/typography/Title';

interface OrderActionsTabProps {
  order?: Order;
  orderFailed?: FailedOrder;
}

const OrderActionsTab: React.FC<OrderActionsTabProps> = ({ order, orderFailed }) => {
  const { updateOrderUsingMerp, generateAndDownlodPDF, loadingPDF } = useOrder();
  const [orderForm] = Form.useForm();

  const [actualOrder, setActualOrder] = useState<Order>({} as Order);
  const statusOrder = StatusOrderTypeEnumArray;

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    if (order?.statusOrder) {
      orderForm.setFields([{ name: 'orderStatus', value: null }]);

      if (order?.statusOrder) {
        orderForm.setFieldsValue({ orderStatus: order?.statusOrder });
      }

      setActualOrder(order);
      setLoading(false);
    }
  }, [order]);

  const updateOrder = () => {
    Modal.confirm({
      title: 'Tem certeza que deseja atualizar o status do pedido?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Atualizar',
      cancelText: 'Cancelar',
      onOk() {
        updateOrderUsingMerp({ ...actualOrder, statusOrder: orderForm.getFieldValue('orderStatus') });
      },
    });
  };

  const generatePDF = () => {
    generateAndDownlodPDF(actualOrder);
  };

  return (
    <>
      {loading ? (
        <div style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LoadingOutlined
            type="play-circle-o"
            style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
          />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Form
            form={orderForm}
            className="d-flex"
            style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}
          >
            <Row style={{ padding: 20, width: '100%' }}>
              <Col span={8}>
                <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                  Status do pedido
                </Title>
                <Form.Item name="orderStatus">
                  <Select
                    style={{ height: '48px', borderRadius: '8px' }}
                    placeholder="Status do pedido"
                    size="large"
                    showSearch
                  >
                    {statusOrder?.length &&
                      statusOrder.map((status: any) => (
                        <Select.Option key={status?.statusValue} value={status?.statusValue}>
                          {status?.statusDescription}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <CustomButton type="primary" style={{ marginTop: 40 }} onClick={updateOrder}>
              Salvar
            </CustomButton>
          </Form>
          <CustomButton type="primary" style={{ marginTop: 20 }} onClick={generatePDF}>
            {loadingPDF ? (
              <LoadingOutlined
                type="play-circle-o"
                style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
              />
            ) : (
              'Gerar PDF'
            )}
          </CustomButton>
        </div>
      )}
    </>
  );
};

export default OrderActionsTab;
