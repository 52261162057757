import React, { useState } from 'react';
import { Button, DatePicker, Input, Row, Select } from 'antd';
import moment, { Moment } from 'moment';
import CustomTagSelect from '@components/CustomTagSelect';

interface TableFiltersProps {
  inputPlaceholder: string;
  inputValue: any;
  onChangeInputValue: Function;
  onFilter: Function;
  onClearFilters: Function;
  type?: 'text' | 'datePickerRange' | 'multipleSelect';
  optionsToSelect?: { loading: boolean; maxTagCount: number | 'responsive'; options: { label: string; value: any }[] };
}

const TableFilters: React.FC<TableFiltersProps> = ({
  inputValue,
  onChangeInputValue,
  onFilter,
  onClearFilters,
  inputPlaceholder,
  type = 'text',
  optionsToSelect,
}) => {
  const [inputValueDatePicker, setInputValueDatePicker] = useState<[Moment, Moment]>();
  const [inputValueSelect, setInputValueSelect] = useState<string[]>([]);
  const style: React.CSSProperties =
    type === 'text' ? { width: '200px', padding: '20px' } : { width: '250px', padding: '15px' };

  const handleClearButton = () => {
    onClearFilters();
    setInputValueDatePicker(undefined);
    setInputValueSelect([]);
  };

  const handleComponentToShow = () => {
    switch (type) {
      case 'datePickerRange':
        return (
          <DatePicker.RangePicker
            className="w-100"
            format="DD/MM/YYYY"
            onCalendarChange={(_, [initialDate, finalDate]) => {
              onChangeInputValue([initialDate, finalDate]);
              setInputValueDatePicker([moment(initialDate, 'DD/MM/YYYY'), moment(finalDate, 'DD/MM/YYYY')]);
            }}
            value={inputValueDatePicker}
            autoFocus
          />
        );

      case 'multipleSelect':
        const tagRender = (props: any): JSX.Element => <CustomTagSelect data={props} />;
        return (
          <Select
            value={inputValueSelect}
            showSearch
            mode="multiple"
            style={{ width: '100%' }}
            className="input"
            placeholder={inputPlaceholder}
            options={optionsToSelect?.options}
            loading={optionsToSelect?.loading}
            maxTagCount={optionsToSelect?.maxTagCount}
            onChange={(value) => {
              onChangeInputValue(value);
              setInputValueSelect(value);
            }}
            tagRender={tagRender}
            showArrow
            allowClear
          ></Select>
        );
      default:
        return (
          <Input
            autoFocus
            placeholder={inputPlaceholder}
            value={inputValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeInputValue(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => onFilter()}
          />
        );
    }
  };

  return (
    <Row className="d-flex" align="middle" justify="center" style={style}>
      {handleComponentToShow()}
      <Button className="mt-2 mr-1" onClick={() => handleClearButton()}>
        Limpar
      </Button>

      <Button className="mt-2 ml-1" type="primary" onClick={() => onFilter()}>
        Filtrar
      </Button>
    </Row>
  );
};

export default TableFilters;
