import { Col, Row, Table } from 'antd';
import React from 'react';

import { EditFilled } from '@ant-design/icons';
import SalesRegionForm from '@components/SalesRegionForm';
import { useIntegration } from '@hooks/IntegrationContext';
import { useRepresentative } from '@hooks/RepresentativeContext';
import { RepresentativeOperationalArea } from '@models/SalesRegionRepresentative';
import { FullScreenModal } from '@styles/globals';
import { useRouteMatch } from 'react-router-dom';
import './styles.css';

interface SalesRegionsTabProps {}

interface MatchParams {
  id: string;
}

const SalesRegionsTab: React.FC<SalesRegionsTabProps> = () => {
  const { hasMerpIntegration } = useIntegration();
  const { actualRepresentative } = useRepresentative();

  const [modalNewSalesRegionVisible, setModalNewSalesRegionVisible] = React.useState(false);

  const [currentSalesRegion, setCurrentSalesRegion] = React.useState<RepresentativeOperationalArea | undefined>(
    undefined,
  );

  const { params } = useRouteMatch<MatchParams>();

  const { loading, loadRepresentativeById } = useRepresentative();

  const showEditSalesRegionModal = (operationalArea: RepresentativeOperationalArea) => {
    setCurrentSalesRegion(operationalArea);
    setModalNewSalesRegionVisible(true);
  };

  const onCloseNewSalesRegionModal = () => {
    loadRepresentativeById(parseInt(params.id));
    setModalNewSalesRegionVisible(false);
    setCurrentSalesRegion(undefined);
  };

  const columns = [
    {
      dataIndex: 'salesRegionCode',
      render: (text: any) => <strong>{text}</strong>,
      width: '80px',
    },
    {
      dataIndex: 'salesRegionName',
      render: (text: any) => <strong>{text}</strong>,
    },
    {
      title: hasMerpIntegration ? 'Ações' : '',
      align: 'center' as 'center',
      width: hasMerpIntegration ? '180px' : '0',
      render: (sales: any, operationalArea: RepresentativeOperationalArea): JSX.Element => {
        return (
          <>
            {hasMerpIntegration && (
              <EditFilled
                onClick={() => showEditSalesRegionModal(operationalArea)}
                style={{ fontSize: '24px', color: '#fff', paddingLeft: 15 }}
              />
            )}
          </>
        );
      },
    },
  ];

  const columnssub = [
    {
      dataIndex: 'cep',
      width: '100px',
    },
    {
      dataIndex: 'addressName',
    },
  ];

  return (
    <>
      <FullScreenModal
        destroyOnClose={true}
        visible={modalNewSalesRegionVisible}
        onOk={onCloseNewSalesRegionModal}
        onCancel={onCloseNewSalesRegionModal}
        footer={null}
        forceRender={false}
      >
        <Row align="middle" justify="center">
          <Col span={24} className="d-flex" style={{ width: '1000px' }}>
            <SalesRegionForm
              onCloseModal={onCloseNewSalesRegionModal}
              isEditing={true}
              salesOrderToEdit={currentSalesRegion}
            />
          </Col>
        </Row>
      </FullScreenModal>

      <Table
        columns={columns}
        dataSource={actualRepresentative.salesRegions}
        showHeader={false}
        rowKey={(record) => record.salesRegionName}
        className="table-antd"
        rowClassName="row-dark"
        scroll={{ x: 800 }}
        loading={loading}
        expandable={{
          expandedRowRender: () => (
            <Table columns={columnssub} dataSource={[]} showHeader={false} pagination={{ hideOnSinglePage: true }} />
          ),
        }}
      />
    </>
  );
};

export default SalesRegionsTab;
