import { CloseOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { CollectionDto } from '@models/Collection';
import { CustomButton } from '@styles/globals';
import { Col, Form, Modal, Radio, Row, Space, Steps, Table, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useGrid } from '@hooks/GridContext';
import { Grid } from '@models/Grid';
import { Container, CustomInput } from './styles';

const { Title, Text } = Typography;
const { Step } = Steps;

const steps = [
  {
    title: 'Descrição',
    content: () => null,
  },
  {
    title: 'Disponibilidade',
    content: () => null,
  },
  {
    title: 'Produtos',
    content: () => null,
  },
];

interface GridFormProps {
  onCloseModal: Function;
  page?: number;
}

const initialCollectionData: CollectionDto = {
  description: null,
  dateCostumerStart: null,
  dateCostumerEnd: null,
  dateCostumerPremiumStart: null,
  dateCostumerPremiumEnd: null,
  dateRepresentativeStart: null,
  dateRepresentativeEnd: null,
  dateRepresentativePremiumStart: null,
  dateRepresentativePremiumEnd: null,
  dateSellerStart: null,
  dateSellerEnd: null,
  dateSellerPremiumStart: null,
  dateSellerPremiumEnd: null,
  classifications: [],
};

const GridForm: React.FC<GridFormProps> = ({ onCloseModal, page }) => {
  const { createGrid } = useGrid();
  const [form] = Form.useForm();
  const [sizes, setSizes] = useState<{ size: string; key: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [size, setSize] = useState<string>('');

  useEffect(() => {
    form.setFields([
      { name: 'gradeName', value: '' },
      { name: 'isActive', value: true },
      { name: 'sizes', value: '' },
    ]);
  }, []);

  const addSize = (size: string) => {
    if (size) {
      const key = uuidv4();
      setSizes((prev) => [...prev, { size, key }]);
      setSize('');
    }
  };

  const removeSize = (key: string) => {
    const filteredSizes = sizes.filter((size) => size.key !== key);
    setSizes(filteredSizes);
  };

  const handleSubmit = async (values: Grid) => {
    setLoading(true);

    const newGrid = await createGrid({
      ...values,
      integrationId: localStorage.getItem('@modavest/integration_id'),
      sizes: sizes.map((size) => {
        return {
          size: size.size,
        };
      }),
    });

    if (newGrid) {
      message.success(`Grade cadastrada com sucesso!`);
      onCloseModal();
    } else {
      message.error(`Não foi possível cadastrar a grade.`);
    }

    setLoading(false);
  };

  const showModalConfirmation = async () => {
    const values = await form.validateFields();

    Modal.confirm({
      title: 'Tem certeza que deseja cadastrar a grade?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Cadastrar',
      okType: 'primary',
      onOk() {
        handleSubmit(values as Grid);
      },
    });
  };

  return (
    <Form form={form} className="d-flex" style={{ flexDirection: 'column', alignItems: 'left' }}>
      <div className="steps-action d-flex" style={{ alignItems: 'left', justifyContent: 'left' }}>
        {loading ? (
          <div
            style={{
              width: '1000px',
              height: '500px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LoadingOutlined
              type="play-circle-o"
              style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
            />
          </div>
        ) : (
          <Container
            style={{
              width: '800px',
              background: '#fff',
              padding: '50px 60px',
              borderRadius: '8px',
              overflow: 'auto',
            }}
          >
            <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <Col span={24}>
                <Form.Item name="isActive" rules={[{ message: 'Selecione o status da grade', required: true }]}>
                  <Radio.Group defaultValue={true}>
                    <Title level={5}>Status da Grade</Title>
                    <Space direction="horizontal">
                      <Radio value={true}>Ativa</Radio>
                      <Radio value={false}>Inativa</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <Col span={24}>
                <Title level={5}>Nome da Grade</Title>
                <Form.Item
                  name="gradeName"
                  rules={[
                    {
                      required: true,
                      message: 'Informe o nome da grade',
                    },
                  ]}
                >
                  <CustomInput placeholder="Digite o nome da grade" />
                </Form.Item>
              </Col>
            </Row>

            <Row
              className="d-flex"
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Col span={24}>
                <Title level={5}>Adicionar tamanhos</Title>
                <Form.Item
                  name="sizes"
                  rules={[
                    {
                      required: sizes.length > 0 ? false : true,
                      message: 'Adicione ao menos um tamanho na tabela',
                    },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <Space direction="horizontal">
                    <CustomInput
                      placeholder="Adicione um tamanho"
                      onChange={(event) => setSize(event?.target?.value)}
                      value={size || ''}
                      onSubmit={() => addSize(size)}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          addSize(size);
                        }
                      }}
                    />
                    <CustomButton style={{ marginTop: 0 }} onClick={() => addSize(size)}>
                      Adicionar
                    </CustomButton>
                  </Space>
                </Form.Item>

                <Table
                  columns={
                    [
                      {
                        title: 'Tamanhos',
                        dataIndex: 'size',
                        key: 'size',
                        render: (text: string, size: { size: string; key: string }): JSX.Element => {
                          return <p style={{ marginBottom: 0 }}>{size.size}</p>;
                        },
                      },
                      {
                        title: 'Ações',
                        dataIndex: 'actions',
                        key: 'actions',
                        width: 80,
                        fixed: 'center',
                        render: (text: string, size: { size: string; key: string }): JSX.Element => {
                          return (
                            <CloseOutlined
                              style={{ width: '100%', cursor: 'pointer', color: 'red' }}
                              onClick={() => removeSize(size.key)}
                            />
                          );
                        },
                      },
                    ] as any
                  }
                  dataSource={sizes}
                  rowKey={(size) => size.key}
                  style={{
                    width: '100%',
                    marginTop: '16px',
                    marginBottom: '26px',
                    maxHeight: '250px',
                    overflow: 'auto',
                  }}
                  scroll={{ x: 200 }}
                  pagination={false}
                />
              </Col>
            </Row>

            <CustomButton type="primary" onClick={() => showModalConfirmation()}>
              Salvar
            </CustomButton>
          </Container>
        )}
      </div>
    </Form>
  );
};

export default GridForm;
