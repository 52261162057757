import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Typography, Table, Switch, Row, Button, message } from 'antd';

import PaymentFilters from '../PaymentFilters';
import { PaymentConditions } from '@models/PaymentConditions';

import * as S from '../../styles';
import { OfficialStorePaymentCondition } from '@models/OfficialStorePaymentCondition';
import { useOfficialStore } from '@hooks/OfficialStoreContext';

import './styles.css';

const { Title } = Typography;

interface PanelPaymentTermProps {
  officialStoreId: number;
  paymentConditions: PaymentConditions[];
  officialStorePaymentConditions: OfficialStorePaymentCondition[];
}

const PanelPaymentTerm: React.FC<PanelPaymentTermProps> = ({
  paymentConditions,
  officialStorePaymentConditions,
  officialStoreId,
}) => {
  const [officialStoreConditions, setOfficialStorePaymentConditions] = useState<any[]>([]);
  const { updateOfficialStorePaymentConditions } = useOfficialStore();

  useEffect(() => {
    if (!officialStorePaymentConditions) return;

    setOfficialStorePaymentConditions(officialStorePaymentConditions);
  }, [officialStorePaymentConditions]);

  const onChangeSwitchIsStandard = async (record: any, checked: boolean) => {
    const paymentsFalsyIsStandard = await Promise.all(
      officialStoreConditions.map((_payment) => {
        _payment.isStandard = false;
        return _payment;
      }),
    );

    const paymentIndex = paymentsFalsyIsStandard.findIndex((_payment) => _payment.paymentConditionCode === record.code);

    if (paymentIndex === -1 || !paymentsFalsyIsStandard[paymentIndex].isActive) return;

    paymentsFalsyIsStandard[paymentIndex].isStandard = checked;

    setOfficialStorePaymentConditions(paymentsFalsyIsStandard);
  };

  const columnsPaymentTerm = [
    {
      title: 'Padrão',
      render: (record: any) => (
        <Switch
          onChange={(value: any) => onChangeSwitchIsStandard(record, value)}
          checked={
            record.code === officialStoreConditions.find((payment) => payment.isStandard)?.paymentConditionCode
              ? true
              : false
          }
          defaultChecked={
            record.code === officialStoreConditions.find((payment) => payment.isStandard)?.paymentConditionCode
              ? true
              : false
          }
        />
      ),
    },
    {
      title: 'Código',
      dataIndex: 'code',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <PaymentFilters
          inputPlaceholder={'Filtrar pelo Nome'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.name ? record.name.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
    },
    {
      title: 'Prazo Médio',
      render: (value: any) => {
        const avg =
          value.installmentDays.map(Number).reduce(function (a: number, b: number) {
            return a + b;
          }) / value.installmentDays.length || 0;
        return <strong>{avg} dias</strong>;
      },
    },
  ];

  const onChangeRowSelection = async (selectedRowKeys: any[]) => {
    const standard = officialStoreConditions.find((_condition) => _condition.isStandard);

    const newConditions: any[] = [];

    await Promise.all(
      selectedRowKeys.map((key) => {
        const payment = paymentConditions.find((_payment) => _payment.code === key);

        if (!payment) return;

        newConditions.push({
          paymentConditionCode: key,
          isActive: true,
          isStandard: standard && standard.paymentConditionCode === key ? true : false,
        });
      }),
    );

    setOfficialStorePaymentConditions(newConditions);
  };

  const rowSelection = {
    columnTitle: 'Ativo',
    selectedRowKeys: officialStoreConditions.map((payment) => {
      return payment.isActive ? payment.paymentConditionCode : 0;
    }),
    onChange: onChangeRowSelection,
  };

  const onSavePaymentConditions = async () => {
    const updated = await updateOfficialStorePaymentConditions(officialStoreId, officialStoreConditions);

    if (updated) message.success('Condições de Pagamento atualizadas com sucesso!');
    else message.error('Erro ao atualizar as condições de pagamento!');
  };

  return (
    <S.Panel>
      <Row className="d-flex" justify="space-between">
        <Title level={5} style={{ color: '#555' }}>
          Prazo de Pagamento
        </Title>

        <Button type="primary" onClick={onSavePaymentConditions}>
          Salvar
        </Button>
      </Row>

      <Table
        className="mt-2 table-payment-term"
        columns={columnsPaymentTerm}
        dataSource={paymentConditions}
        rowKey={(record) => record.code}
        pagination={{ pageSize: 4 }}
        rowSelection={rowSelection}
      />
    </S.Panel>
  );
};

export default PanelPaymentTerm;
