import { Devolution } from '@models/Devolution';
import * as api from './api';

export function get(params: any): Promise<any> {
  return api.get('devolution/search', params);
}

export function getByCodeAndCnpj(code: string, CNPJ: string): Promise<any> {
  return api.get('devolution', { code, CNPJ });
}

export function createDevolutionRequest(devolution: Devolution): Promise<any> {
  return api.post('devolution', { ...devolution });
}

export function sendDevolutionInvoice(params: any): Promise<any> {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  const formData = new FormData();
  formData.append('devolutionInvoice', params?.devolutionInvoice);
  formData.append('invoiceCode', params?.invoiceCode);
  formData.append('addressOriginDevolution', JSON.stringify(params?.addressOriginDevolution));

  return api.postWithConfig('devolution/devolution-invoice', formData, config);
}

export function updateDevolutionRequest(params: any): Promise<any> {
  return api.put(`devolution/${params?.sequence}`, {
    statusObservation: params?.statusObservation,
    observation: params?.observation,
    devolutionDestinationAddress: params?.devolutionDestinationAddress,
    ...params,
  });
}

export function finishDevolutionRequest(sequence: number) {
  return api.put(`devolution/finish/${sequence}`, {});
}
