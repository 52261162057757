import { Form, Input } from 'antd';

import { CustomButton } from '@styles/globals';
import { usePriceTable } from '@hooks/PriceTableContext';
import { PriceTable } from '@models/PriceTable';
import { useEffect } from 'react';

interface TabObservationProps {
  priceTable: PriceTable;
}

const TabObservation: React.FC<TabObservationProps> = ({ priceTable }) => {
  const { updatePriceTableObservation } = usePriceTable();
  const [form] = Form.useForm();

  useEffect(() => {
    if (priceTable && priceTable.observation) form.setFieldsValue({ observation: priceTable.observation });
  }, [priceTable]);

  const handleSubmitObservation = (value: any) => {
    if (value && value.observation) updatePriceTableObservation(priceTable.code, value.observation);
  };

  return (
    <Form layout="vertical" onFinish={handleSubmitObservation} form={form}>
      <Form.Item name="observation" rules={[{ required: true, message: 'Informe a observação!' }]}>
        <Input.TextArea rows={6} placeholder="Digite aqui suas observações..." />
      </Form.Item>

      <CustomButton className="w-100 mt-2" type="primary" htmlType="submit">
        ATUALIZAR
      </CustomButton>
    </Form>
  );
};

export default TabObservation;
