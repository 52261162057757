interface CircleIconProps {
  color: string;
}

const CircleIcon = ({ color }: CircleIconProps) => {
  return (
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" width={12} height={12}>
      <circle cx="50" cy="50" r="50" color={color} fill="currentcolor" />
    </svg>
  );
};

export default CircleIcon;
