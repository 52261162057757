import { FilterFilled } from '@ant-design/icons';
import FiltersForm from '@components/FiltersForm';
import HeaderButton from '@components/HeaderButton';
import FiltersList, { ListFilterType } from '@components/FiltersList';
import VisitsFilterForm from '@components/VisitsFiltersForm';
import { useVisits } from '@hooks/VisitsContext';
import { VisitsFilter } from '@models/VisitsFilter';
import { Col, Drawer, Row, Typography } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

const { Title } = Typography;

const VisitsHeader: React.FC = () => {
  const { visits, loadVisits } = useVisits();

  const [filters, setFilters] = useState<VisitsFilter>({} as VisitsFilter);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const handleCloseDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, [setIsDrawerOpen]);

  const handleOpenDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, [setIsDrawerOpen]);

  const onSubmitFiltersForm = useCallback((value: VisitsFilter) => {
    let filterList = value;
    if (value?.dates?.length)
      filterList = {
        ...filterList,
        initialDate: moment(value.dates[0]).format('YYYY-MM-DD').toString(),
        finalDate: moment(value.dates[1]).format('YYYY-MM-DD').toString(),
      };

    loadVisits(0, filterList);
    handleCloseDrawer();
    setFilters(filterList);
  }, []);

  const onRemoveFilter = ({ key }: ListFilterType) => {
    const newFilters: any = filters;

    if (key === 'initialDate' || key === 'finalDate') {
      delete newFilters['initialDate'];
      delete newFilters['finalDate'];
      delete newFilters['dates'];
    } else {
      delete newFilters[key];
    }

    setFilters(newFilters);
    loadVisits(0, newFilters);
  };

  const onClearFiltersForm = useCallback(() => {
    loadVisits(0);
    handleCloseDrawer();
  }, []);

  const filterColumns = [
    {
      title: 'Representate',
      key: 'representative',
    },
    {
      title: 'Lojista',
      key: 'legalPerson',
    },
    {
      title: 'Marca',
      key: 'brand',
    },
    {
      title: 'Data inicial',
      key: 'initialDate',
      isDate: true,
    },
    {
      title: 'Data final',
      key: 'finalDate',
      isDate: true,
    },
    {
      title: 'CEP',
      key: 'addressCep',
    },
    {
      title: 'Estado',
      key: 'addressState',
    },
    {
      title: 'Cidade',
      key: 'addressCity',
    },
  ];

  return (
    <Row className="mt-8" justify="space-between">
      <Col>
        <Title level={2}>Visitação do Representante</Title>
      </Col>

      <Col>
        <Row>
          <Col>
            <FiltersList columns={filterColumns} dataSource={filters} onRemove={onRemoveFilter} />
          </Col>
          <Col>
            <HeaderButton
              text="Filtros"
              onClick={handleOpenDrawer}
              Icon={<FilterFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
            />
          </Col>
          <Drawer title="Filtros" placement="left" onClose={handleCloseDrawer} visible={isDrawerOpen} width={400}>
            <FiltersForm
              onCloseDrawer={handleCloseDrawer}
              onSubmit={onSubmitFiltersForm}
              onClearFilters={onClearFiltersForm}
              filtersForm={<VisitsFilterForm visits={visits} />}
            />
          </Drawer>
        </Row>
      </Col>
    </Row>
  );
};

export default VisitsHeader;
