import { Parameter } from '@models/Parameter';
import { CustomButton } from '@styles/globals';
import { Collapse, Form, Input } from 'antd';
import React from 'react';

interface ParametersFormProps {
  onAddParameter: Function;
  parameter: Partial<Parameter>;
}
const ParametersForm: React.FC<ParametersFormProps> = ({ onAddParameter, parameter }) => {
  return (
    <Form layout="vertical" onFinish={(values: any) => onAddParameter(values)}>
      <Collapse className="mt-4" ghost>
        <Collapse.Panel header="Exibir informações" key="1" style={{ fontWeight: 'bold' }}>
          <Form.Item className="mb-2" label="Desc. Parâmetro">
            <Input.TextArea style={{ cursor: 'not-allowed' }} readOnly value={parameter.description} />
          </Form.Item>

          <Form.Item className="mb-2" label="Desc. Utilidade">
            <Input.TextArea style={{ cursor: 'not-allowed' }} readOnly value={parameter.utility} />
          </Form.Item>

          <Form.Item className="mb-2" label="Desc. Funcionalidade">
            <Input.TextArea style={{ cursor: 'not-allowed' }} readOnly value={parameter.functionality} />
          </Form.Item>

          <Form.Item className="mb-2" label="Desc. Variante">
            <Input.TextArea style={{ cursor: 'not-allowed' }} readOnly value={parameter.variant} />
          </Form.Item>

          <Form.Item className="mb-2" label="Desc. Valor">
            <Input.TextArea style={{ cursor: 'not-allowed' }} readOnly value={parameter.value} />
          </Form.Item>
        </Collapse.Panel>
      </Collapse>

      <Form.Item className="mb-2" label="Variante" name="variant">
        <Input placeholder="Variante" />
      </Form.Item>

      <Form.Item className="mb-2" label="Valor" name="value" rules={[{ required: true, message: 'Informe o valor.' }]}>
        <Input placeholder="Valor" />
      </Form.Item>

      <CustomButton htmlType="submit" type="primary" className="w-100">
        Adicionar
      </CustomButton>
    </Form>
  );
};

export default ParametersForm;
