import React from 'react';
import { Popconfirm, Table } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';

import { OfficialStore } from '@models/OfficialStore';
import TableFilters from '../../../../components/TableFilters';

import './styles.css';
import { useHistory } from 'react-router';
import { useOfficialStore } from '@hooks/OfficialStoreContext';

interface OfficialStoreCollectionsContentProps {
  officialStore: OfficialStore;
}

const OfficialStoreCollectionsContent: React.FC<OfficialStoreCollectionsContentProps> = ({ officialStore }) => {
  const history = useHistory();
  const { updateOfficialStoreCollections } = useOfficialStore();

  const onDeleteOfficialStoreCollection = async (collectionId: number) => {
    const filtered = officialStore.collections?.filter(({ collectionId: id }: any) => id !== collectionId);

    updateOfficialStoreCollections(officialStore.officialStoreId, filtered);
  };

  const columns = [
    {
      title: 'Código',
      dataIndex: 'collectionId',
      key: 'collectionId',
      align: 'center' as 'center',
      width: '100px',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pelo Código'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.collectionId ? record.collectionId.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
    },
    {
      title: 'Nome da Coleção',
      dataIndex: 'collection',
      key: 'description',
      render: (value: any) => {
        return (
          <strong
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(`/painel/coleções/detalhes/${value.collectionId}`)}
          >
            {value.description}
          </strong>
        );
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pelo Nome'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) =>
        record.collection.description
          ? record.collection.description.toString().toLowerCase().includes(value.toLowerCase())
          : '',
    },
    {
      title: '',
      dataIndex: 'collectionId',
      align: 'right' as 'right',
      render: (value: any) => (
        <Popconfirm
          title={`Tem certeza que deseja remover essa coleção?`}
          onConfirm={() => onDeleteOfficialStoreCollection(value)}
          okText="Sim"
          cancelText="Não"
        >
          <CloseOutlined style={{ cursor: 'pointer' }} onClick={() => null} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <Table
      className="mt-4 official-store-collections-table"
      columns={columns}
      dataSource={officialStore.collections}
      scroll={{ x: 800 }}
      rowKey={(record) => record.collectionId}
    />
  );
};

export default OfficialStoreCollectionsContent;
