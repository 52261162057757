import React from 'react';

import OrderHeader from './components/OrderHeader';
import OrderContent from './components/OrderContent';

const OrderPage: React.FC = () => {
  return (
    <>
      <OrderHeader />
      <OrderContent />
    </>
  );
};

export default OrderPage;
