import { Tabs } from 'antd';
import React, { useCallback } from 'react';

import { useReference } from '@hooks/ReferenceContext';
import ProductsTable from '../ProductsTable';

const ProductsContent: React.FC = () => {
  const { loadReferences } = useReference();

  const handleTabChange = useCallback((key) => {
    loadReferences(0, key === "isActive");
  }, []);

  return (
    <Tabs className="mt-4" onChange={handleTabChange}>
      <Tabs.TabPane tab="Ativos" key="isActive">
        <ProductsTable />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Inativos" key="isInactive">
        <ProductsTable onlyInactives={true} />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default ProductsContent;
