import { Steps } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 24px;
  cursor: pointer;
  background: white;

  .uppercase {
    text-transform: uppercase;
  }

  *::placeholder {
    text-transform: none;
  }
`;

export const UploadContainer = styled.div`
  > .upload {
    width: 100%;
    height: 80px;
    border: 1px dashed #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.h2`
  font-weight: bold;
`;

export const Subtitle = styled.h3`
  color: #b0b0b0;
  font-weight: 400;
`;

export const StepsContainer = styled(Steps)`
  width: min(400px, 100%);
  margin-bottom: 40px;

  .ant-steps-item-finish .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: var(--primary-color) !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background-color: var(--primary-color) !important;
  }

  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    border: 2px solid var(--primary-color) !important;
    background-color: #f2f2f2 !important;
  }
`;
