import React from 'react';
import { Col, Row, Typography } from 'antd';
import { GoalItem } from '@models/GoalItem';
import { ScrollContainer } from '../DetailsContent/styles';
import { GoalTypeEnumObject } from '@models/enum/GoalType';
import '../styles.css';

const { Title, Text } = Typography;

interface SellerCardContentProps {
  goalItem: GoalItem;
}
const SellerCardContent: React.FC<SellerCardContentProps> = ({ goalItem }) => {
  const makeUfs = (ufRule: string) => {
    const ufsList = ufRule.split(/[#&]/).filter((uf) => uf != '#' && uf != '&' && uf);

    if (!ufsList?.length) return 'Sem UFs';

    return ufsList.map((uf, idx) => (idx == 0 ? uf : ` | ${uf}`));
  };

  return (
    <Row gutter={[16, 16]}>
      <Col
        className="gutter-row d-flex card-scroll-details"
        style={{ flexDirection: 'column' }}
        xs={24}
        sm={16}
        md={9}
        lg={8}
        xl={5}
        xxl={3}
      >
        <Title level={5}>Lojas Oficiais</Title>
        <ScrollContainer>
          {goalItem?.officialStores?.map((officialStore) => (
            <Text className="w-100" ellipsis>
              {officialStore?.description}
            </Text>
          ))}
          {!goalItem?.officialStores?.length && <Text className="w-100">Não há lojas oficiais</Text>}
        </ScrollContainer>
      </Col>

      <Col
        className="gutter-row d-flex card-scroll-details"
        style={{ flexDirection: 'column' }}
        xs={24}
        sm={16}
        md={9}
        lg={8}
        xl={5}
        xxl={3}
      >
        <Title level={5}>Coleção</Title>
        <ScrollContainer>
          {goalItem?.collections?.map((collection) => (
            <Text className="w-100" ellipsis>
              {collection?.description}
            </Text>
          ))}
          {!goalItem?.collections?.length && <Text className="w-100">Não há coleções</Text>}
        </ScrollContainer>
      </Col>

      <Col
        className="gutter-row d-flex card-scroll-details"
        style={{ flexDirection: 'column' }}
        xs={24}
        sm={16}
        md={9}
        lg={8}
        xl={5}
        xxl={3}
      >
        <Title level={5}>Tipo de Meta</Title>
        <Text className="w-100">{goalItem?.goalType ? GoalTypeEnumObject[goalItem.goalType] : 'Sem informação'}</Text>
      </Col>

      <Col
        className="gutter-row d-flex card-scroll-details"
        style={{ flexDirection: 'column' }}
        xs={24}
        sm={16}
        md={9}
        lg={8}
        xl={5}
        xxl={3}
      >
        <Title level={5}>UF</Title>
        <Text className="w-100">{makeUfs(goalItem?.ufRule)}</Text>
      </Col>

      <Col
        className="gutter-row d-flex card-scroll-details"
        style={{ flexDirection: 'column' }}
        xs={24}
        sm={16}
        md={9}
        lg={8}
        xl={5}
        xxl={3}
      >
        <Title level={5}>Tipo/Classificação de Produto</Title>
        <ScrollContainer>
          {goalItem?.productClassifications?.map((productClassification) => (
            <Text className="w-100" ellipsis>
              Tipo: {productClassification?.typeCode} - Classificação: {productClassification?.code}
            </Text>
          ))}
          {!goalItem?.productClassifications?.length && <Text className="w-100">Não há classificações</Text>}
        </ScrollContainer>
      </Col>

      <Col
        className="gutter-row d-flex card-scroll-details"
        style={{ flexDirection: 'column' }}
        xs={24}
        sm={16}
        md={9}
        lg={8}
        xl={5}
        xxl={3}
      >
        <Title level={5}>Tipo/Classificação de Pedido</Title>
        <ScrollContainer>
          {goalItem?.orderClassifications?.map((orderClassification) => (
            <Text className="w-100" ellipsis>
              Tipo: {orderClassification?.typeCode} - Classificação: {orderClassification?.code}
            </Text>
          ))}
          {!goalItem?.orderClassifications?.length && <Text className="w-100">Não há classificações</Text>}
        </ScrollContainer>
      </Col>

      <Col
        className="gutter-row d-flex card-scroll-details"
        style={{ flexDirection: 'column' }}
        xs={24}
        sm={16}
        md={9}
        lg={8}
        xl={5}
        xxl={3}
      >
        <Title level={5}>Lojista</Title>
        <ScrollContainer>
          {goalItem?.legalPersons?.map((legalPerson) => (
            <Text className="w-100" ellipsis>
              {legalPerson?.fantasyName}
            </Text>
          ))}
          {!goalItem?.legalPersons?.length && <Text className="w-100">Não há lojistas</Text>}
        </ScrollContainer>
      </Col>

      <Col
        className="gutter-row d-flex card-scroll-details"
        style={{ flexDirection: 'column' }}
        xs={24}
        sm={16}
        md={9}
        lg={8}
        xl={5}
        xxl={3}
      >
        <Title level={5}>Referências</Title>
        <ScrollContainer>
          {goalItem?.references?.map((reference) => (
            <Text className="w-100" ellipsis>
              {reference}
            </Text>
          ))}
          {!goalItem?.references?.length && <Text className="w-100">Não há referências</Text>}
        </ScrollContainer>
      </Col>
    </Row>
  );
};

export default SellerCardContent;
