import React from 'react';

import RepresentativeCardHeader from '../RepresentativeCardHeader';
import RepresentativeCardContent from '../RepresentativeCardContent';

import * as S from './styles';
import Card from '../Card';
import { GoalItem } from '@models/GoalItem';
import { Typography } from 'antd';

const { Text } = Typography;

interface RepresentativeCardProps {
  goalItem: GoalItem;
}
const RepresentativeCard: React.FC<RepresentativeCardProps> = ({ goalItem }) => {
  return (
    <Card>
      <RepresentativeCardHeader goalItem={goalItem} />
      <S.Divider className="mt-4 mb-4" />
      <Text>
        <strong>Descrição: </strong> {goalItem?.description || 'Sem descrição'}
      </Text>
      <S.Divider className="mt-4 mb-4" />
      <RepresentativeCardContent goalItem={goalItem} />
    </Card>
  );
};

export default RepresentativeCard;
