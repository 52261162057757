import { EllipsisOutlined, ExclamationCircleOutlined, PlusCircleFilled } from '@ant-design/icons';
import ClassificationForm from '@components/ClassificationForm';
import DualForm from '@components/DualForm';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
import { OfficialStore } from '@models/OfficialStore';
import { OfficialStoreMenu } from '@models/OfficialStoreMenu';
import { ButtonContainer, CustomDropdownMenu } from '@pages/OfficialStoreMenuPage/styles';
import { FullScreenModal } from '@styles/globals';
import { Col, Dropdown, message, Row, Table, Menu, Modal } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import OfficialStoreSubMenuClassificationsTable from '../OfficialStoreSubMenuClassificationsTable';
import OfficialStoreSubMenuForm from '../OfficialStoreSubMenuForm';

interface OfficialStoreSubMenuProps {
  officialStoreMenu: OfficialStoreMenu;
  officialStore: OfficialStore;
}
const OfficialStoreSubMenu: React.FC<OfficialStoreSubMenuProps> = ({ officialStoreMenu, officialStore }) => {
  const [modalSubMenuVisible, setModalSubMenuVisible] = useState(false);
  const [modalEditSubMenuVisible, setModalEditSubMenuVisible] = useState(false);
  const [modalSubMenuAddClassificationIsVisible, setModalSubMenuAddClassificationIsVisible] = useState(false);
  const [actualSubMenuItem, setActualSubMenuItem] = useState<any>();

  const {
    menusOfficialStore,
    createOfficialStoreSubMenu,
    deleteOfficialStoreSubMenu,
    updateOfficialStoreSubMenu,
    addClassificationOfficialStoreSubMenu,
    deleteOfficialStoreSubMenuClassification,
  } = useOfficialStore();

  const onCloseModalSubMenu = useCallback(() => {
    setModalSubMenuVisible(false);
  }, [setModalSubMenuVisible]);

  const onCloseEditModalSubMenu = useCallback(() => {
    setModalEditSubMenuVisible(false);
  }, [setModalEditSubMenuVisible]);

  const onCloseModalAddClassification = useCallback(() => {
    setModalSubMenuAddClassificationIsVisible(false);
  }, [setModalSubMenuAddClassificationIsVisible]);

  const showModalSubMenu = useCallback(() => {
    setModalSubMenuVisible(true);
  }, [setModalSubMenuVisible]);

  const showModalEditSubMenu = useCallback(() => {
    setModalEditSubMenuVisible(true);
  }, [setModalEditSubMenuVisible]);

  const showModalAddClassification = useCallback(() => {
    setModalSubMenuAddClassificationIsVisible(true);
  }, [setModalSubMenuAddClassificationIsVisible]);

  const columns = [
    {
      dataIndex: 'description',
      render: (description: string) => <span>{description}</span>,
    },
    {
      align: 'right' as 'right',
      render: () => (
        <Dropdown overlay={menu} trigger={['click']}>
          <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px', fontWeight: 'bolder' }} />
        </Dropdown>
      ),
    },
  ];

  const onCreateSubMenu = useCallback(
    async (values: any) => {
      if (values && officialStoreMenu.menuId) {
        await createOfficialStoreSubMenu(officialStore.officialStoreId, officialStoreMenu.menuId, values.description);
      }
    },
    [officialStoreMenu],
  );

  const onUpdateSubMenu = useCallback(
    async (values: any) => {
      if (officialStore && officialStoreMenu && values && actualSubMenuItem?.subMenuId)
        await updateOfficialStoreSubMenu(
          officialStore.officialStoreId,
          officialStoreMenu.menuId,
          actualSubMenuItem?.subMenuId,
          values.description,
        );
    },
    [officialStore, officialStoreMenu, actualSubMenuItem?.subMenuId],
  );

  const onDeleteMenu = async () => {
    Modal.confirm({
      title: 'Deletar SubMenu de Loja Oficial',
      icon: <ExclamationCircleOutlined />,
      content: 'Deseja mesmo prosseguir?',
      okText: 'Sim',
      cancelText: 'Não',
      onOk: async () => {
        if (officialStore && officialStoreMenu && actualSubMenuItem?.subMenuId) {
          const deleted = await deleteOfficialStoreSubMenu(
            officialStore.officialStoreId,
            officialStoreMenu.menuId,
            actualSubMenuItem?.subMenuId,
          );
          if (deleted) message.success('SubMenu excluído com sucesso!');
          else message.error('Erro ao excluir submenu!');
        }
      },
    });
  };

  const onAddNewClassification = useCallback(
    async (values: any) => {
      if (officialStore.officialStoreId && officialStoreMenu.menuId && actualSubMenuItem?.subMenuId && values)
        await addClassificationOfficialStoreSubMenu(
          officialStore.officialStoreId,
          officialStoreMenu.menuId,
          actualSubMenuItem?.subMenuId,
          values,
        );
    },
    [officialStore, officialStoreMenu, actualSubMenuItem?.subMenuId],
  );

  const onDeleteClassification = useCallback(
    async (typeCode: string, code: string) => {
      await deleteOfficialStoreSubMenuClassification(
        officialStore.officialStoreId,
        officialStoreMenu.menuId,
        actualSubMenuItem?.subMenuId,
        {
          code,
          typeCode,
        },
      );
    },
    [officialStore, officialStoreMenu, actualSubMenuItem?.subMenuId],
  );

  useEffect(() => {
    adaptClassificationsItemsList();
  }, [officialStoreMenu, menusOfficialStore, actualSubMenuItem?.subMenuId]);

  const [currentClassificationItems, setCurrentClassificationItems] = useState<any[]>([]);

  const adaptClassificationsItemsList = () => {
    const subMenu = officialStoreMenu.subMenus.find((_subMenu) => _subMenu.subMenuId === actualSubMenuItem?.subMenuId);

    const list = subMenu?.classifications?.map((item: any) => ({
      label1: 'Tipo de Classificação',
      item1: item.typeCode,
      label2: 'Classificação',
      item2: item.code,
    }));

    if (list) setCurrentClassificationItems(list);
  };

  const menu = (
    <CustomDropdownMenu>
      <Menu.Item key="add" onClick={showModalAddClassification}>
        Classes
      </Menu.Item>
      <Menu.Item onClick={showModalEditSubMenu} key="edit">
        Editar
      </Menu.Item>
      <Menu.Item key="delete" onClick={onDeleteMenu}>
        Excluir
      </Menu.Item>
    </CustomDropdownMenu>
  );

  return (
    <>
      <Table
        columns={columns}
        dataSource={officialStoreMenu.subMenus}
        rowKey={(record) => record.subMenuId}
        showHeader={false}
        pagination={false}
        scroll={{ x: 800 }}
        expandable={{
          expandedRowRender: (record) => (
            <OfficialStoreSubMenuClassificationsTable submenuClassifications={record.classifications} />
          ),
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => setActualSubMenuItem(record),
          };
        }}
      />

      <Row>
        <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '16px 0' }}>
          <ButtonContainer onClick={showModalSubMenu}>
            <PlusCircleFilled twoToneColor="#510c76" style={{ fontSize: '20px' }} />
            <span style={{ marginLeft: '4px' }}>Adicionar SubMenu</span>
          </ButtonContainer>
        </Col>
      </Row>

      <FullScreenModal
        destroyOnClose={true}
        visible={modalSubMenuVisible}
        onOk={onCloseModalSubMenu}
        onCancel={onCloseModalSubMenu}
        footer={null}
        forceRender={false}
      >
        <OfficialStoreSubMenuForm onSubmit={onCreateSubMenu} />
      </FullScreenModal>

      <FullScreenModal
        destroyOnClose={true}
        visible={modalEditSubMenuVisible}
        onOk={onCloseEditModalSubMenu}
        onCancel={onCloseEditModalSubMenu}
        footer={null}
        forceRender={false}
      >
        <OfficialStoreSubMenuForm onSubmit={onUpdateSubMenu} updating description={actualSubMenuItem?.description} />
      </FullScreenModal>

      <FullScreenModal
        destroyOnClose={true}
        footer={null}
        forceRender={false}
        visible={modalSubMenuAddClassificationIsVisible}
        onOk={onCloseModalAddClassification}
        onCancel={onCloseModalAddClassification}
        bodyStyle={{ width: '100%' }}
      >
        <DualForm
          title="Classificações"
          loading={false}
          form={<ClassificationForm onAdd={onAddNewClassification} />}
          list={currentClassificationItems}
          onDelete={onDeleteClassification}
        />
      </FullScreenModal>
    </>
  );
};

export default OfficialStoreSubMenu;
