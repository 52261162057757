import { useCategory } from '@hooks/CategoryContext';
import { Category } from '@models/Category';
import { Collection } from '@models/Collection';
import { Color } from '@models/Color';
import { Form, Input, Select } from 'antd';
import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface ProductsFiltersFormProps {
  colors: Color[];
  collections?: Collection[];
  categories: Category[];
}
const ProductsFiltersForm: React.FC<ProductsFiltersFormProps> = ({ colors, collections, categories }) => {
  const { loadRequestSubcategory } = useCategory();
  const [currentCategory, setCurrentCategory] = useState<number>();

  const handleCategoryChange = useCallback(
    (categoryId: number) => {
      loadRequestSubcategory([categoryId.toString()]);
      setCurrentCategory(categoryId);
    },
    [loadRequestSubcategory],
  );

  return (
    <>
      <Form.Item className="mb-3" label="Descrição" name="name">
        <Input placeholder="Descrição do Produto..." />
      </Form.Item>

      <Form.Item className="mb-3" label="Referência" name="reference">
        <Input placeholder="Referência do Produto..." />
      </Form.Item>

      {collections && (
        <Form.Item className="mb-3" label="Coleção" name="collectionId">
          <Select placeholder="Coleção do Produto...">
            {collections.map((collection) => (
              <Select.Option key={collection.collectionId} value={collection.collectionId}>
                {collection.description}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item className="mb-3" label="Categoria">
        <Select placeholder="Categoria do Produto..." onChange={handleCategoryChange}>
          {categories.map((category) => (
            <Select.Option key={category.categoryId} value={category.categoryId}>
              {category.description}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item className="mb-3" label="SubCategoria" name="subCategoryId">
        <Select placeholder="SubCategoria do Produto...">
          {categories
            .find((category) => category.categoryId == currentCategory)
            ?.subcategories?.map((subCategory) => (
              <Select.Option key={subCategory.subCategoryId} value={subCategory.subCategoryId}>
                {subCategory.description}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item className="mb-3" label="Cor" name="colorCode">
        <Select placeholder="Cor do Produto...">
          {colors.map((color) => (
            <Select.Option key={color.code} value={uuidv4()}>
              {color.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item className="mb-8" label="Tamanho" name="size">
        <Input placeholder="Tamanho do Produto..." />
      </Form.Item>
    </>
  );
};

export default ProductsFiltersForm;
