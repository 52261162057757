import styled, { css } from 'styled-components';
import { Button, Form, Menu } from 'antd';

export const Panel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 6px;
  padding: 20px;
`;

export const UploadBody = styled.div`
  width: 200px;
  height: 160px;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  > h5 {
    font-size: 16px;
    margin-top: 8px;
  }
`;
export const CustomFormItem = styled(Form.Item)`
  margin: 8px 0 0 0;

  > .ant-form-item-label {
    padding: 0;
  }
`;

export const LoadingContainer = styled.div`
  width: 200px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
`;

export const FormContainer = styled(Form)`
  background-color: white;
  padding: 12px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CustomMenu = styled.div`
  height: 200px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::-webkit-scrollbar {
    width: 8px;
    background: #f5f5f5;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-appearance: none;
    background: var(--primary-color);
    border-radius: 8px;
  }

  > * {
    margin: 0 8px;
  }
`;

interface CustomButtonProps {
  noMargin?: boolean;
}
const no_margin = css`
  margin: 0;
`;

export const CustomButton = styled(Button)<CustomButtonProps>`
  background-color: var(--primary-color);
  border-radius: 8px;
  margin-top: 16px;

  ${(props) => props.noMargin && no_margin}

  > * {
    color: white;
    font-size: 12px !important;
    font-weight: 500;
  }
`;

export const ButtonContainer = styled.div`
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-radius: 4px;
    background-color: #e6e6e6;
  }

  > * {
    color: var(--primary-color);
    font-size: 16px;
  }
`;

export const CustomDropdownMenu = styled(Menu)`
  padding: 12px;
`;