export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).format(value);
};

export const calculateEquivalentPercentage = (total: number, value: number): string => {
  return `${((100 * value) / total).toFixed(2)}%`;
};

export const calculateEquivalentPercentageNumber = (total: number = 0, value: number = 0): number => {
  if (!total || !value) return 0;
  const result = (100 * value) / total ?? 0;
  return +result.toFixed(2);
};
