import { EyeFilled } from '@ant-design/icons';
import CircleIcon from '@components/CircleIcon';
import LoadingComponents from '@components/LoadingComponents';
import { useVisits } from '@hooks/VisitsContext';
import { Brand } from '@models/Brand';
import { RepresentativeCustomer } from '@models/RepresentativeCustomer';
import { VisitAddress } from '@models/VisitAddress';
import { Visits } from '@models/Visits';
import { FullScreenModal } from '@styles/globals';
import { formatCnpj } from '@utils/cnpj-mask';
import { formatDate } from '@utils/date-format';
import { Table, Tooltip } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import VisitModal from '../VisitModal';

const VisitsContent: React.FC = () => {
  const { loadVisits, loadingVisits, visits, loadVisitsDetails, visitsFilters, totalPages, currentPage } = useVisits();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const showVisitModal = useCallback(
    (visit) => {
      loadVisitsDetails(visit);
      setIsModalVisible(true);
    },
    [setIsModalVisible],
  );

  const hideVisitModal = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  useEffect(() => {
    loadVisits(0);
  }, []);

  const columns = [
    {
      title: 'Data da Visita',
      dataIndex: 'visitDate',
      render: (date: string) => {
        return <p>{formatDate(date)}</p>;
      },
      sorter: true,
    },
    {
      title: 'Lojista',
      dataIndex: 'representativeCustomer',
      render: (rc: RepresentativeCustomer) => {
        return (
          <Tooltip placement="topLeft" title={'Lojista'}>
            {rc.name}
            <br />
            {formatCnpj(rc.cpfCnpj)}
          </Tooltip>
        );
      },
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Marca',
      dataIndex: 'brand',
      render: (brand: Brand) => {
        return <p>{brand.brandName}</p>;
      },
      sorter: true,
    },
    {
      title: 'Representante',
      dataIndex: ['representativeCustomer', 'representative'],
      render: (rc: any) => {
        return (
          <Tooltip placement="topLeft" title={'Representante'}>
            {rc.name}
            <br />
            {formatCnpj(rc.cpfCnpj)}
          </Tooltip>
        );
      },
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Endereço Visita',
      dataIndex: 'address',
      render: (address: VisitAddress) => {
        return (
          <Tooltip placement="topLeft" title={'Endereço'}>
            {`${address.publicPlace?.length ? `${address.publicPlace},` : ''} 
          ${address.number ? `${address.number},` : ''}
          ${address.neighborhood?.length ? `${address.neighborhood}` : ''}`}
          </Tooltip>
        );
      },
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Cidade Visita',
      dataIndex: ['address', 'cityName'],
      sorter: true,
    },
    {
      title: 'Estado Visita',
      dataIndex: ['address', 'stateAbbreviation'],
      sorter: true,
    },
    {
      title: 'Observação',
      dataIndex: 'observation',
      ellipsis: true,
      sorter: true,
    },
    {
      title: 'Validação',
      dataIndex: 'valid',
      render: (valid: boolean) => {
        return valid ? <CircleIcon color="#6CA11E" /> : <CircleIcon color="#EDC363" />;
      },
      align: 'center' as 'center',
      sorter: true,
    },
    {
      title: 'Ações',
      align: 'center' as 'center',
      render: (visit: Visits) => {
        return (
          <EyeFilled
            onClick={() => {
              showVisitModal(visit.visitId);
            }}
            style={{ fontSize: '24px', color: '#510c76' }}
          />
        );
      },
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.hasOwnProperty('column')) {
      visitsFilters.field = `${sorter.field}`;
      visitsFilters.order = `${sorter.order}`;
    }

    loadVisits(pagination.current, visitsFilters);
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={visits}
        scroll={{ x: 800 }}
        rowKey={(row) => row.visitId}
        pagination={{
          total: totalPages,
          current: currentPage,
          pageSize: 10,
          showSizeChanger: false,
        }}
        onChange={handleTableChange}
        loading={loadingVisits}
      />

      <FullScreenModal
        destroyOnClose={true}
        footer={null}
        forceRender={false}
        visible={isModalVisible}
        onCancel={hideVisitModal}
        onOk={hideVisitModal}
        bodyStyle={{ width: '100%', overflowY: 'scroll' }}
      >
        <VisitModal />
      </FullScreenModal>
    </>
  );
};

export default VisitsContent;
