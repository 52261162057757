import styled from 'styled-components';

export const ScrollContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  padding: 4px 0;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: #f5f5f5;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-appearance: none;
    background: var(--primary-color);
    border-radius: 8px;
  }
`;

export const ListContainer = styled.div`
  padding: 8px;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 8px;
  width: auto;
  border: 2px solid var(--primary-color);
  border-radius: 16px;
  margin-right: 4px;

  > * {
    cursor: pointer;
    color: var(--primary-color);
  }
`;
