import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect } from 'react';

import * as S from './styles';

interface OfficialStoreSubMenuFormProps {
  onSubmit: (value: any) => void;
  updating?: boolean;
  description?: string;
}

const OfficialStoreSubMenuForm: React.FC<OfficialStoreSubMenuFormProps> = ({ onSubmit, updating, description }) => {
  const [form] = useForm();

  useEffect(() => {
    if (description) form.setFieldsValue({ description });
  }, [description]);

  return (
    <S.CustomForm form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="description"
        label="Nome do SubMenu"
        rules={[{ required: true, message: 'Informe o nome do submenu!' }]}
      >
        <S.CustomInput placeholder="Ex: Camiseta Masculina" />
      </Form.Item>

      <Form.Item>
        <S.CustomButton type="primary" htmlType="submit">
          {updating ? 'Atualizar SubMenu' : 'Adicionar SubMenu'}
        </S.CustomButton>
      </Form.Item>
    </S.CustomForm>
  );
};

export default OfficialStoreSubMenuForm;
