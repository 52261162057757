import React, { useCallback, useState } from 'react';
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Table, Row, Typography, Menu, Dropdown, Modal } from 'antd';
import moment from 'moment';

import ClassificationsTable from '../ClassificationsTable';
import { useOfficialStoreQuestions } from '@hooks/OfficialStoreQuestionsContext';
import LoadingComponents from '@components/LoadingComponents';
import { formatDate } from '@utils/date-format';
import { FIELD_NAME_BILLING_FORECAST_DATE } from '@models/OrderDefinedFieldValues';

const { Title, Text } = Typography;

interface OfficialStoreQuestionsContentProps {
  showModalNewQuestion: Function;
  setEditingQuestion: Function;
}
const OfficialStoreQuestionsContent: React.FC<OfficialStoreQuestionsContentProps> = ({
  showModalNewQuestion,
  setEditingQuestion,
}) => {
  const [currentQuestion, setCurrentQuestion] = useState<any>();

  const { officialStoreQuestions, deleteOfficialStoreQuestion, loadingDeleteOfficialStoreQuestion } =
    useOfficialStoreQuestions();

  const handleRemove = (officalStoreId: number, questionId: number) => {
    Modal.confirm({
      title: 'Deseja realmente excluir?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Excluir',
      okType: 'danger',
      onOk() {
        deleteQuestion(officalStoreId, questionId);
      },
    });
  };

  const deleteQuestion = useCallback(
    (officialStoreId: number, questionId: number) => {
      if (officialStoreId && questionId)
        deleteOfficialStoreQuestion(officialStoreId, questionId, officialStoreQuestions);
    },
    [officialStoreQuestions],
  );

  const editQuestion = useCallback(() => {
    setEditingQuestion(currentQuestion);
    showModalNewQuestion();
  }, [currentQuestion, setEditingQuestion]);

  const menu = (
    <Menu>
      <Menu.Item key="edit" onClick={() => editQuestion()}>
        Editar
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => handleRemove(currentQuestion.officialStoreId, currentQuestion.questionId)}>
        Deletar
      </Menu.Item>
    </Menu>
  );

  const formatDefinedFieldValue = useCallback(
    (definedField: string, definedFieldValue: string, index: number) => {
      let value = '';

      if (definedField === FIELD_NAME_BILLING_FORECAST_DATE) {
        value = moment(definedFieldValue).format('DD/MM/YYYY');
      } else {
        value = definedFieldValue;
      }

      if (index === 0) return value;

      return ' | ' + value;
    },
    [formatDate],
  );

  const columns = [
    {
      title: 'Código',
      dataIndex: 'questionId',
      width: '10%',
      align: 'center' as 'center',
      render: (text: any) => <Text>#{text}</Text>,
      sorter: (questionA: any, questionB: any) => questionA.questionId - questionB.questionId,
    },
    {
      title: 'Tipo',
      dataIndex: 'questionType',
      filters: [
        {
          text: 'TEXTO',
          value: 'TEXTO',
        },
        {
          text: 'CAIXA DE SELEÇÃO',
          value: 'CAIXA DE SELEÇÃO',
        },
        {
          text: 'LISTA DE SELEÇÃO',
          value: 'LISTA DE SELEÇÃO',
        },
        {
          text: 'TEXTO REPRESENTANTE',
          value: 'TEXTO REPRESENTANTE',
        },
      ],
      onFilter: (value: any, record: any) => record.questionType.indexOf(value) === 0,
      sorter: (questionA: any, questionB: any) => questionA.questionType.localeCompare(questionB.questionType),
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      sorter: (questionA: any, questionB: any) => questionA.description.localeCompare(questionB.description),
    },
    {
      title: 'Alternativas',
      render: (record: any) => {
        return (
          record.options?.length > 0 && (
            <Text>
              {record.options.map((_option: any, i: number) =>
                formatDefinedFieldValue(_option.definedField, _option.definedFieldValue, i),
              )}
            </Text>
          )
        );
      },
    },
    {
      align: 'right' as 'right',
      render: (value: any) => (
        <Dropdown overlay={menu} trigger={['click']}>
          <EllipsisOutlined
            style={{ cursor: 'pointer', fontSize: '24px', fontWeight: 'bolder' }}
            onClick={() => setCurrentQuestion(value)}
          />
        </Dropdown>
      ),
    },
  ];

  if (loadingDeleteOfficialStoreQuestion) return <LoadingComponents />;

  return (
    <>
      <Row className="mt-4">
        <Col span={24}>
          <Title level={5} className="ml-2">
            Perguntas Parametrizadas
          </Title>
        </Col>

        <Col span={24}>
          <Table
            className="mt-2"
            columns={columns}
            dataSource={officialStoreQuestions}
            expandable={{
              expandedRowRender: (record) => <ClassificationsTable options={record.options} />,
            }}
            rowKey={(record) => record.questionId}
            scroll={{ x: 800 }}
          />
        </Col>
      </Row>
    </>
  );
};

export default OfficialStoreQuestionsContent;
