import { Button } from 'antd';
import styled from 'styled-components';

export const CustomButton = styled(Button)`
  width: min(80px, 100%);
  height: 36px;
  border-radius: 8px;

  > span {
    color: var(--support_color);
    font-size: 12px;
    font-weight: 500;
  }
`;
