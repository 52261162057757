import React, { useState } from 'react';
import { Form, Typography, Menu, Drawer } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import ConnectionCustomersFilters from '../ConnectionCustomersFilters';
import ConnectionOrdersFilters from '../ConnectionOrdersFilters';
import ConnectionPaymentsFilters from '../ConnectionPaymentsFilters';

const { Title } = Typography;
interface ConnectionsFiltersProps {}

const ConnectionsFilters: React.FC<ConnectionsFiltersProps> = () => {
  const [drawerCustomerIsVisible, setDrawerCustomerIsVisible] = useState(false);
  const [drawerOrderIsVisible, setDrawerOrderIsVisible] = useState(false);
  const [drawerPaymentIsVisible, setDrawerPaymentIsVisible] = useState(false);

  const showCustomerDrawer = () => {
    setDrawerCustomerIsVisible(true);
  };

  const closeCustomerDrawer = () => {
    setDrawerCustomerIsVisible(false);
  };

  const showOrderDrawer = () => {
    setDrawerOrderIsVisible(true);
  };

  const closeOrderDrawer = () => {
    setDrawerOrderIsVisible(false);
  };

  const showPaymentDrawer = () => {
    setDrawerPaymentIsVisible(true);
  };

  const closePaymentDrawer = () => {
    setDrawerPaymentIsVisible(false);
  };

  return (
    <Form>
      <Title level={5}>Permissões</Title>

      <Menu className="w-100" mode="inline" style={{ border: 'none' }}>
        <Menu.Item key="1" onClick={showCustomerDrawer}>
          <div className="d-flex" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <span>Lojista</span>
            <RightOutlined />
          </div>
        </Menu.Item>

        <Menu.Item key="2" onClick={showOrderDrawer}>
          <div className="d-flex" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <span>Pedido</span>
            <RightOutlined />
          </div>
        </Menu.Item>

        <Menu.Item key="3" onClick={showPaymentDrawer}>
          <div className="d-flex" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <span>Pagamento</span>
            <RightOutlined />
          </div>
        </Menu.Item>
      </Menu>

      <Drawer placement="right" onClose={closeCustomerDrawer} visible={drawerCustomerIsVisible} closable={false}>
        <ConnectionCustomersFilters />
      </Drawer>

      <Drawer placement="right" onClose={closeOrderDrawer} visible={drawerOrderIsVisible} closable={false}>
        <ConnectionOrdersFilters />
      </Drawer>

      <Drawer placement="right" onClose={closePaymentDrawer} visible={drawerPaymentIsVisible} closable={false}>
        <ConnectionPaymentsFilters />
      </Drawer>
    </Form>
  );
};

export default ConnectionsFilters;
