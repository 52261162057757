import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import * as ColorService from '@services/color';
import { Color } from '@models/Color';

interface ColorState {
  colors: Color[];
  loadColorsGroupedByName: Function;
  loading: boolean;
}

interface ColorProviderProps {
  children: ReactNode;
}

export const ColorContext = createContext<ColorState | any>({});

const ColorProvider: React.FC<ColorProviderProps> = ({ children }) => {
  const [colors, setColors] = useState<Color[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadColorsGroupedByName = useCallback(async () => {
    setLoading(true);

    const { data: _colors } = await ColorService.getColorsGroupedByName();

    setColors(_colors);
    setLoading(false);
  }, [setLoading, setColors]);

  return (
    <ColorContext.Provider
      value={{
        colors,
        loading,
        loadColorsGroupedByName,
      }}
    >
      {children}
    </ColorContext.Provider>
  );
};

const useColor = () => {
  return useContext(ColorContext);
};

export { ColorProvider, useColor };
