import styled from "styled-components";

export const Container = styled.div`
width: 100%;
padding: 4px;
overflow-x: scroll;

&::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #f5f5f5;
  border-radius: 8px;
}

&::-webkit-scrollbar-thumb {
  -webkit-appearance: none;
  background: var(--primary-color);
  border-radius: 8px;
}
`;
