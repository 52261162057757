import React, { useEffect } from 'react';
import { DatePicker, Form, Select } from 'antd';
import { useCollection } from '@hooks/CollectionContext';
import { Collection } from '@models/Collection';
import { CustomButton } from '@styles/globals';
import moment from 'moment';

const { Option } = Select;

interface CollectionFiltersProps {
  onCloseDrawer: Function;
}

const CollectionFilters: React.FC<CollectionFiltersProps> = ({ onCloseDrawer }) => {
  const { allCollections, filterCollections, loadCollections, loadAllCollections } = useCollection();

  useEffect(() => {
    loadAllCollections(0, { listAll: true });
  }, []);

  const onSearchFiltersDrawer = (values: any) => {
    let filters = values;

    if (values?.dates?.length)
      filters = {
        ...filters,
        initialDate: moment(values.dates[0]).format('YYYY-MM-DD').toString(),
        finalDate: moment(values.dates[1]).format('YYYY-MM-DD').toString(),
      };

    filterCollections(filters);
    onCloseDrawer();
  };

  const onResetFilters = () => {
    loadCollections(0);
    onCloseDrawer();
  };

  return (
    <Form layout="vertical" onFinish={(values: any) => onSearchFiltersDrawer(values)}>
      <Form.Item className="mb-0" name="description" label="Descrição">
        <Select
          style={{ display: 'block', marginBottom: '16px' }}
          size="large"
          showSearch
          allowClear
          placeholder="Pesquisar por Coleção"
        >
          {allCollections.map((collection: Collection) => (
            <Option key={collection.collectionId} value={collection.description}>
              {allCollections && collection.description}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item className="mb-0" name="dates" label="Período de Inclusão">
        <DatePicker.RangePicker size="large" className="w-100" />
      </Form.Item>

      <CustomButton className="mt-8 w-100" type="primary" size="large" block htmlType="submit">
        Buscar
      </CustomButton>

      <CustomButton outlined="true" className="mt-2 w-100" onClick={onResetFilters} type="ghost" size="large" block>
        Reiniciar Filtros
      </CustomButton>
    </Form>
  );
};

export default CollectionFilters;
