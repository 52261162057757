import React, { useEffect, useState } from 'react';
import { ColProps, Typography } from 'antd';

import Card from '../Card';
import { formatCnpj } from '@utils/cnpj-mask';
import { useManufacturer } from '@hooks/ManufacturerContext';
import LoadingComponents from '@components/LoadingComponents';

const { Title, Text } = Typography;

const CardCnpj: React.FC<ColProps> = ({ ...rest }) => {
  const { manufacturers, loading } = useManufacturer();
  const [currentManufacturer, setCurrentManufacturer] = useState<any>();

  useEffect(() => {
    if (manufacturers?.length) setCurrentManufacturer(manufacturers[0]);
  }, [manufacturers]);

  if (loading) return <LoadingComponents />;

  return (
    <Card {...rest}>
      <div>
        <Title level={5} className="mb-0 mt-3">
          CNPJ
        </Title>
        <Text>{formatCnpj(currentManufacturer?.manufacturerCnpj)}</Text>
        <Title level={5} className="mb-0 mt-3">
          Inscrição Estadual
        </Title>
        <Text>{currentManufacturer?.manufacturerNumberStateRegistration}</Text>
        <Title level={5} className="mb-0 mt-3">
          Estado/UF da Inscrição Estadual
        </Title>
        <Text>{currentManufacturer?.manufacturerUf}</Text>
      </div>
    </Card>
  );
};

export default CardCnpj;
