import * as api from './api';

export function get(filters?: any) {
  return api.get(`products`, filters);
}

export function getDetails(referenceCode: string, productCode: number) {
  return api.get(`products/${referenceCode}/${productCode}`);
}

export function getReferenceInfo(referenceCode: string) {
  return api.get(`references/${referenceCode}`);
}

export function findProductsByCollectionId(collectionId: number, body?: any) {
  return api.post(`products/collections/${collectionId}`, body);
}

export function getSizes() {
  return api.get(`products/sizes`);
}

export function updateStatus(referenceCode: string, colorCode: string, productCode: number, status: boolean) {
  return api.patch(`products/${referenceCode}/${colorCode}/${productCode}`, { property: 'isActive', value: status });
}

export function updateReferenceObservation(referenceCode: string, observation: any) {
  return api.patch(`references/${referenceCode}`, { property: 'observations', value: observation });
}
