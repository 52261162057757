import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useOfficialStore } from '@hooks/OfficialStoreContext';

import Loading from '@components/Loading';
import OfficialStoreMenuHeader from './components/OfficialStoreMenuHeader';
import OfficialStoreMenuContent from './components/OfficialStoreMenuContent';

interface MatchParams {
  id: string;
}

const OfficialStoreMenuPage: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();
  const { actualOfficialStore, findOfficialStoreById, findOfficialStoreMenusById, loading } = useOfficialStore();

  useEffect(() => {
    findOfficialStoreById(parseInt(params.id));
    findOfficialStoreMenusById(parseInt(params.id));
  }, []);

  if (loading) return <Loading />;

  return (
    <>
      <OfficialStoreMenuHeader officialStore={actualOfficialStore} />
      <OfficialStoreMenuContent officialStore={actualOfficialStore} />
    </>
  );
};

export default OfficialStoreMenuPage;
