import React, { useState } from 'react';

import { Col, Drawer, Row, Typography } from 'antd';

import ConnectionsFilters from '../ConnectionsFilters';

const { Title } = Typography;

interface ProfileConnectionsHeaderProps {}

const ProfileConnectionsHeader: React.FC<ProfileConnectionsHeaderProps> = () => {
  const [drawerConnectionFiltersIsVisible, setDrawerConnectionFiltersIsVisible] = useState(false);

  const closeConnectionFiltersDrawer = () => {
    setDrawerConnectionFiltersIsVisible(false);
  };

  return (
    <>
      <Row className="mt-8" justify="space-between">
        <Col className="d-flex" style={{ alignItems: 'center', flexDirection: 'column', marginLeft: '12px' }}>
          <Title level={3} style={{ margin: '0' }}>
            Conexões
          </Title>
        </Col>

        <Col>
          {/* TODO */}
          {/* <S.ButtonContainer onClick={showConnectionFiltersDrawer}>
            <FilterFilled twoToneColor="#510c76" style={{ fontSize: '20px' }} />
            <span style={{ marginLeft: '4px' }}>Filtros</span>
          </S.ButtonContainer> */}
        </Col>
      </Row>

      <Drawer
        placement="right"
        onClose={closeConnectionFiltersDrawer}
        visible={drawerConnectionFiltersIsVisible}
        closable={false}
      >
        <ConnectionsFilters />
      </Drawer>
    </>
  );
};

export default ProfileConnectionsHeader;
