import VisitsContent from './components/VisitsContent';
import VisitsHeader from './components/VisitsHeader';

const VisitsPage: React.FC = () => {
  return (
    <>
      <VisitsHeader />
      <VisitsContent />
    </>
  );
};

export default VisitsPage;
