import { AxiosResponse } from 'axios';

import * as api from './api';

export function get(params?: any) {
  return api.get('visits/complete', params);
}

export function getOne(id: number, params?: any) {
  return api.get(`visits/${id}`, params);
}
