import styled, { keyframes } from 'styled-components';

import { Modal } from 'antd';

export const FullScreenModal = styled(Modal)`
  animation: fade 0.3s ease-in-out forwards;
  min-width: 100%;
  min-height: 100vh;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;

  > .ant-modal,
  .ant-modal-content {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5 !important;
  }
`;

const fade = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    } 
`;
