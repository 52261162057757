import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { LeftOutlined, MenuOutlined, PlusCircleFilled } from '@ant-design/icons';

import { Col, Drawer, Image, message, Row, Typography } from 'antd';

import Navigation from '../../../../components/OfficialStoreNavigation';
import { OfficialStore } from '@models/OfficialStore';
import { FullScreenModal } from '@styles/globals';
import DualForm from '@components/DualForm';
import HeaderButton from '@components/HeaderButton';
import CollectionsForm from '../CollectionsForm';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
import { Collection } from '@models/Collection';

const { Text, Title } = Typography;

interface OfficialStoreCollectionsHeaderProps {
  officialStore: OfficialStore;
}

const OfficialStoreCollectionsHeader: React.FC<OfficialStoreCollectionsHeaderProps> = ({ officialStore }) => {
  const history = useHistory();
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [collectionsModalIsVisible, setCollectionsModalIsVisible] = useState(false);
  const [collections, setCollections] = useState<Partial<Collection>[]>([]);
  const [currentCollectionItems, setCurrentCollectionItems] = useState<any[]>([]);
  const { updateOfficialStoreCollections } = useOfficialStore();

  useEffect(() => {
    adaptCollectionItemsList();
  }, [collections]);

  useEffect(() => {
    if (officialStore?.collections)
      setCollections(
        officialStore.collections.map((_collection) => ({
          description: _collection.collection.description,
          collectionId: _collection.collection.collectionId,
        })),
      );
  }, [officialStore]);

  const adaptCollectionItemsList = () => {
    const list = collections?.map((collection) => ({
      label1: 'Código',
      item1: collection.collectionId,
      label2: 'Descrição',
      item2: collection.description,
    }));

    if (list) setCurrentCollectionItems(list);
  };

  const backUrl = useCallback(() => {
    history.go(-1);
  }, [history]);

  const onCloseDrawer = useCallback(() => {
    setDrawerIsVisible(false);
  }, [setDrawerIsVisible]);

  const showDrawer = useCallback(() => {
    setDrawerIsVisible(true);
  }, [setDrawerIsVisible]);

  const onCloseCollectionsModal = useCallback(() => {
    setCollectionsModalIsVisible(false);
  }, [setCollectionsModalIsVisible]);

  const showCollectionsModal = useCallback(() => {
    setCollectionsModalIsVisible(true);
  }, [setCollectionsModalIsVisible]);

  const onAddCollection = (collectionId: number, description: string) => {
    setCollections((current) => {
      if (current.find((collection) => collection.collectionId === collectionId)) {
        message.warning('Coleção já foi vinculada anteriormente!');
        return [...current];
      }
      return [...current, { collectionId, description }];
    });
  };

  const onDeleteCollections = useCallback(
    (collectionId: number) => {
      const index = collections.findIndex((collection) => collection.collectionId === collectionId);

      if (index !== -1) {
        collections.splice(index, 1);
        setCollections([...collections]);
        message.success('Coleção deletada com sucesso!');
      }
    },
    [collections, setCollections],
  );

  const onUpdateCollections = useCallback(() => {
    updateOfficialStoreCollections(officialStore.officialStoreId, collections);
  }, [collections, officialStore]);

  return (
    <>
      <Row className="mt-3" justify="space-between">
        <Col className="d-flex" style={{ alignItems: 'center' }}>
          <LeftOutlined onClick={() => backUrl()} style={{ fontSize: '20px', marginRight: '12px' }} />

          <Image
            src={officialStore.logoUrl}
            alt="Logo da empresa"
            preview={false}
            style={{ width: '100%', maxWidth: '120px', borderRadius: '8px' }}
          />

          <Row style={{ flexDirection: 'column', marginLeft: '12px' }}>
            <Col>
              <Title level={5} style={{ margin: '0' }}>
                {officialStore.description}
              </Title>
            </Col>
            <Col>
              <Text>#{officialStore.officialStoreId}</Text>
            </Col>
          </Row>
        </Col>

        <Col className="d-flex" style={{ alignItems: 'center' }}>
          <Row className="d-flex mr-3" style={{ alignItems: 'center' }}>
            <HeaderButton
              text="Adicionar Coleção"
              onClick={showCollectionsModal}
              Icon={<PlusCircleFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
            />
          </Row>

          <Row className="d-flex" style={{ alignItems: 'center' }}>
            <MenuOutlined onClick={showDrawer} style={{ fontSize: '22px', color: '#510c76', cursor: 'pointer' }} />
          </Row>

          <Drawer
            placement="right"
            onClose={onCloseDrawer}
            visible={drawerIsVisible}
            closable={false}
            bodyStyle={{ padding: '0' }}
          >
            <Navigation officialStore={officialStore} />
          </Drawer>
        </Col>
      </Row>

      <FullScreenModal
        destroyOnClose={true}
        footer={null}
        forceRender={false}
        visible={collectionsModalIsVisible}
        onCancel={onCloseCollectionsModal}
        onOk={onCloseCollectionsModal}
        bodyStyle={{ width: '100%' }}
      >
        <DualForm
          title="Coleções"
          loading={false}
          form={<CollectionsForm onAdd={onAddCollection} />}
          list={currentCollectionItems}
          onDelete={onDeleteCollections}
          onSubmit={onUpdateCollections}
        />
      </FullScreenModal>
    </>
  );
};

export default OfficialStoreCollectionsHeader;
