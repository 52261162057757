import styled from 'styled-components';
import { Row, Col } from 'antd';

export const ProfileContainer = styled(Row)`
  width: 100%;
  min-height: 120px;
  display: flex;
  background-color: white;
  border-radius: 6px;
  align-items: center;
`;

export const InfoContainer = styled(Col)`
  display: flex;
  height: 90px;
  flex-direction: column;
  padding: 0.5rem;
`;

export const LoadingContainer = styled.div`
  width: 200px;
  height: 100px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
`;

export const UploadBody = styled.div`
  width: 200px;
  height: 100px;
  border-radius: 6px;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  > h5 {
    font-size: 16px;
    margin-top: 8px;
  }
`;