import { Row, Col, Typography } from 'antd';

const { Title } = Typography;

const ManufacturerHeader: React.FC = () => (
  <Row className="mt-8" justify="space-between">
    <Col>
      <Title level={2}>Perfil do Fabricante</Title>
    </Col>
  </Row>
);

export default ManufacturerHeader;
