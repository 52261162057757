export const getAddressType = (type: number) => {
  switch (type) {
    case 1:
      return 'Comercial';
    case 2:
      return 'Residencial';
    case 3:
      return 'Cobrança';
    case 4:
      return 'Compras';
    case 5:
      return 'Entrega';
    case 6:
      return 'Correspondência';
    case 7:
      return 'Show room';
    case 8:
      return 'Retirada';
    case 9:
      return '';
    case 10:
      return 'Compras';
    case 11:
      return 'Comercial anterior';
    case 12:
      return 'Residencial anterior';

    default:
      return '';
  }
};
