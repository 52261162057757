import React from 'react';
import { Spin } from 'antd';

const LoadingComponents: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children, ...props }) => (
  <div className="w-100 d-flex" style={{ alignItems: 'center', justifyContent: 'center', height: '60%' }} {...props}>
    <Spin />
  </div>
);

export default LoadingComponents;
