import React from 'react';
import { Col, Row, Pagination } from 'antd';
import { useHistory } from 'react-router';

import Card from '../Card';
import { useRepresentative } from '@hooks/RepresentativeContext';
import LoadingComponents from '@components/LoadingComponents';
import { REPRESENTATIVE_PAGINATION_SIZE } from '@utils/constants';

interface RepresentativesContentProps {
  redirectBaseUrl: string;
}

const RepresentativesContent: React.FC<RepresentativesContentProps> = ({ redirectBaseUrl }) => {
  const history = useHistory();
  const { representatives, currentPage, totalPages, loadRepresentativesPaginated, loading, representativeFilters } =
    useRepresentative();

  const handlePaginationChange = (page: number) => {
    loadRepresentativesPaginated(page - 1, representativeFilters);
  };

  if (loading) return <LoadingComponents />;

  return (
    <>
      <Row gutter={[24, 8]} style={{ marginTop: '16px' }}>
        {representatives.map(({ name, photoUrl, cpfCnpj, code }: any) => (
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={6}
            xxl={4}
            onClick={() => history.push(`${redirectBaseUrl}/perfil/${code}`)}
            key={code}
          >
            <Card name={name} imageUrl={photoUrl} cnpj={cpfCnpj} operationalArea="Região" />
          </Col>
        ))}
      </Row>
      <Row className="mt-4 mb-8 mr-4" justify="end">
        <Pagination
          defaultCurrent={1}
          total={totalPages}
          current={currentPage + 1}
          pageSize={REPRESENTATIVE_PAGINATION_SIZE}
          showSizeChanger={false}
          onChange={handlePaginationChange}
        />
      </Row>
    </>
  );
};

export default RepresentativesContent;
