import styled from 'styled-components';

import { Steps, Input, DatePicker, Col } from 'antd';

const { RangePicker } = DatePicker;

export const StepsContainer = styled(Steps)`
  width: 400px;
  margin-bottom: 40px;

  .ant-steps-item-finish .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: var(--primary-color) !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background-color: var(--primary-color) !important;
  }

  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    border: 2px solid var(--primary-color) !important;
    background-color: #f2f2f2 !important;
  }
`;

export const Container = styled.div`
  width: 800px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .ant-picker-range,
  .ant-picker-range:hover,
  .ant-picker-focused {
    border-color: var(--primary-color) !important;
    box-shadow: 0 0 0 2px rgb(81 12 118 / 20%) !important;
  }
`;

export const CustomInput = styled(Input)`
  width: 320px;
  height: 48px;
  border-radius: 8px;
`;

export const CustomRangePicker = styled(RangePicker)`
  min-width: 240px;
  height: 48px;
  border-radius: 8px;

  .ant-picker-active-bar {
    background: var(--primary-color) !important;
  }

  .ant-picker-input > input {
    color: var(--primary-color) !important;
    font-weight: 600;
  }
`;

export const CustomCard = styled.div`
  width: calc(100% -24px);
  min-height: 80px;
  margin-right: 12px;
  padding: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  margin-bottom: 16px;
  cursor: pointer;

  .card-col {
    display: none;
  }

  &:hover {
    .card-row > :nth-child(-n + 2) {
      display: none;
    }

    .card-col {
      display: flex;
      height: 60px;
      align-items: center;
      justify-content: center;
      color: var(--primary-color);
    }
  }
`;

export const CustomCardContainer = styled(Col)`
  height: 100%;
  padding: 4px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    background: #f5f5f5;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-appearance: none;
    background: var(--primary-color);
    border-radius: 8px;
  }
`;
