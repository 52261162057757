import { CloseOutlined } from '@ant-design/icons';
import React from 'react';

import * as S from './styles';

export type ListFilterType = {
  value: string;
  key: any;
};

interface ListFiltersProps {
  filters: ListFilterType[];
  onRemove: Function;
}
const ListFilters: React.FC<ListFiltersProps> = ({ filters, onRemove }) => {
  return (
    <S.ScrollContainer>
      <S.ListContainer className='d-flex'>
        {filters.map((filter) => (
          <S.FilterContainer>
            <span>{filter.value}</span>
            <CloseOutlined className='ml-2' onClick={() => onRemove(filter)} />
          </S.FilterContainer>
        ))}
      </S.ListContainer>
    </S.ScrollContainer>
  );
};

export default ListFilters;
