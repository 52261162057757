import React from 'react';
import { Col, Row, Typography } from 'antd';

import { Product } from '@models/Product';
import moment from 'moment';

const { Title, Text } = Typography;

interface TabValueProductProps {
  product: Product;
}

const TabValueProduct: React.FC<TabValueProductProps> = ({ product }) => {
  return (
    <Row gutter={[16, 16]} style={{ padding: '12px' }}>
      <Col className="gutter-row">
        <Title level={5}>Data Início Promoção</Title>
        <Text>{moment.utc(product.salesStartDate).format('DD/MM/YYYY')}</Text>
      </Col>
      <Col className="gutter-row">
        <Title level={5}>Data Final Promoção</Title>
        <Text>{moment.utc(product.salesEndDate).format('DD/MM/YYYY')}</Text>
      </Col>
    </Row>
  );
};

export default TabValueProduct;
