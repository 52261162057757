import { AxiosResponse } from 'axios';

import * as api from './api';

const end_point = 'integration-permission';

type IntegrationPermissionType = { integrationId: number; permissionId: string; createdAt: Date };

export function getPermissionsByIntegrationId(
  integrationId: number,
): Promise<AxiosResponse<IntegrationPermissionType[]>> {
  return api.get([end_point, integrationId].join('/'));
}
