import LoadingComponents from '@components/LoadingComponents';
import { useGoal } from '@hooks/GoalContext';
import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import DetailsContent from './components/DetailsContent';
import DetailsHeader from './components/DetailsHeader';

interface MatchParams {
  id: string;
}

const GoalDetailsPage: React.FC = () => {
  const { loadingGetGoalById, loadGoalById } = useGoal();
  const { params } = useRouteMatch<MatchParams>();

  useEffect(() => {
    if (!params.id) return;
    loadGoalById(params.id);
  }, [params.id]);

  if (loadingGetGoalById) return <LoadingComponents />;

  return (
    <>
      <DetailsHeader goalId={parseInt(params.id)} />
      <DetailsContent />
    </>
  );
};

export default GoalDetailsPage;
