import { Collaborator } from '@models/Collaborator';
import { Response } from '@models/Response';
import { ICollaboratorForm } from '@pages/CollaboratorsPage/models/ICollaboratorForm';
import * as api from './api';

export async function get(params: any) {
  return api.get('collaborators', params);
}

export async function getDetails(collaboratorId: number): Promise<any> {
  return api.get(`collaborators/${collaboratorId}`);
}

export async function post(body: any) {
  return api.post('collaborators', body);
}

export function update(collaborator: ICollaboratorForm) {
  return api.put(`collaborators/${collaborator.collaboratorId}`, collaborator);
}

export function removeCollaborator(collaboratorId: number): Promise<any> {
  return api.remove(`collaborators/${collaboratorId}`);
}

export function addOfficialStores(collaboratorId: number, officialStores: number[]) {
  return api.post(`collaborators/${collaboratorId}/official_stores`, { officialStores });
}

export function removeOfficialStore(collaboratorId: number, officialStoreId: number) {
  return api.remove(`collaborators/${collaboratorId}/official_stores/${officialStoreId}`);
}

export function addBrands(collaboratorId: number, brands: number[]) {
  return api.post(`collaborators/${collaboratorId}/brands`, { brands });
}

export function removeBrand(collaboratorId: number, brandId: number) {
  return api.remove(`collaborators/${collaboratorId}/brands/${brandId}`);
}
