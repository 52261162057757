import React, { useCallback, useState } from 'react';
import { Col, Drawer, Row, Typography, Form, Input } from 'antd';
import { FilterFilled } from '@ant-design/icons';

import FiltersForm from '@components/FiltersForm';
import HeaderButton from '@components/HeaderButton';
import { useSeller } from '@hooks/SellerContext';
import FiltersList, { ListFilterType } from '@components/FiltersList';

const { Title } = Typography;

interface SellersHeaderProps {}

const SellersFiltersForm: React.FC = () => (
  <Form.Item label="Filtro" name="searchByNameOrCnpj">
    <Input placeholder="Nome..." />
  </Form.Item>
);

const SellersHeader: React.FC<SellersHeaderProps> = () => {
  const [filtersDrawerIsVisible, setfiltersDrawerIsVisible] = useState<boolean>(false);

  const { loadSellers, sellerFilters } = useSeller();

  const handleCloseFiltersDrawer = useCallback(() => {
    setfiltersDrawerIsVisible(false);
  }, [setfiltersDrawerIsVisible]);

  const handleShowFiltersDrawer = useCallback(() => {
    setfiltersDrawerIsVisible(true);
  }, [setfiltersDrawerIsVisible]);

  const onSubmitFiltersForm = useCallback(
    (values: any) => {
      loadSellers(0, {searchByNameOrCnpj: values.searchByNameOrCnpj});
    },
    [loadSellers],
  );

  const onClearFiltersForm = useCallback(() => {
    loadSellers(0, undefined);
  }, [loadSellers]);

  const onRemoveFilter = ({ key }: ListFilterType) => {
    const newFilters = sellerFilters
    delete newFilters[key]
    loadSellers(0,  newFilters);
  };

  return (
    <>
      <Row className="mt-8" justify="space-between">
        <Col>
          <Title level={2}>Vendedores</Title>
        </Col>
        <Col>
          <Row>
            <Col>
              <FiltersList columns={[{title: 'Nome', key: 'searchByNameOrCnpj'}]} dataSource={sellerFilters} onRemove={onRemoveFilter}/>
            </Col>
            <Col>
              <HeaderButton
                text="Filtros"
                onClick={handleShowFiltersDrawer}
                Icon={<FilterFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Drawer
        title="Filtros"
        placement="left"
        onClose={handleCloseFiltersDrawer}
        visible={filtersDrawerIsVisible}
        width={400}
      >
        <FiltersForm
          onCloseDrawer={handleCloseFiltersDrawer}
          onSubmit={onSubmitFiltersForm}
          onClearFilters={onClearFiltersForm}
          filtersForm={<SellersFiltersForm />}
        />
      </Drawer>
    </>
  );
};

export default SellersHeader;
