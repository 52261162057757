import { formatCnpj } from '@utils/cnpj-mask';
import { formatCpf } from '@utils/cpf-mask';
import { Avatar } from 'antd';
import React from 'react';

import * as S from './styles';

interface CardProps {
  title: string;
  code: number;
  cpfCnpj: string;
}

const Card: React.FC<CardProps> = ({ title, code, cpfCnpj }) => {
  return (
    <S.CardContainer>
      <Avatar
        style={{ backgroundColor: 'var(--primary-color)', verticalAlign: 'middle', minWidth: '40px' }}
        size="large"
      >
        {title[0]}
      </Avatar>

      <div className="ml-4">
        <S.CardTitle>{title}</S.CardTitle>
        <S.CardCode>#{code}</S.CardCode>
        <S.CardCode>{cpfCnpj?.length > 11 ? formatCnpj(cpfCnpj) : formatCpf(cpfCnpj)}</S.CardCode>
      </div>
    </S.CardContainer>
  );
};

export default Card;
