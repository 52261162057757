export const CORREIOS_ERROR_MESSAGES: any = {
  '3': 'Não é permitido agendar processamento de arquivo via webservice', // N
  '10': 'O arquivo já foi processado', // O
  '103': 'Arquivo com erro de estrutura', // A
  '104': 'Dados de validação da coleta incompletos', // D
  '105': 'Cliente não configurado para usar o sistema', // C
  '107': 'Versão inválida do arquivo xml', // V
  '108': 'Valor declarado não pode ser superior a R$ 10.000,00', // V
  '109': 'Dados do contrato inválidos', // D
  '111': 'Coleta domiciliar não disponível para essa localidade', // C
  '1111': 'Coleta domiciliar não disponível para o serviço sedex 10', // C
  '112': 'Serviço não atende o cep de destino', // S
  '113': 'Cep do destinatário inexistente', // C
  '114': 'Cep de destino com formato inválido', // C
  '115': 'Cep de origem com formato inválido', // C
  '117': 'Cep do remetente inexistente', // C
  '120': 'Serviço indisponível para o trecho informado', // S
  '1201': 'Serviço informado não abrange o cep de origem', // S
  '121': 'E-ticket que já foi solicitado', // E
  '122': 'Dados de destinatário incompleto', // D
  '125': 'Dados de remetente incompletos', // D
  '134': 'Data de agendamento inválida. verificar tag -agendamento', // D
  '136': 'Número de entrega inválido', // N
  '1366': 'Serviço de simultânea em agência não atendido na região do cep do remetente', // S
  '138': 'O arquivo não contém pedidos de coleta. verificar tag -coletas_solicitadas', // O
  '140': 'Cartão inválido para o contrato informado', // C
  '142': 'Valor inválido para o tipo de solicitação.verificar tag -ag', // V
  '195': 'Número de e-ticket já utilizado', // N
  '1955': 'Número de objeto já utilizado nesse arquivo', // N
  '198': 'Número de objeto inválido ou dígito verificador incorreto', // N
  '1988': 'Faixa numérica não reservada para esse cliente', // F
  '19888': 'Número de objeto já utilizado', // N
  '198888': 'Tipo de etiqueta inválido', // T
  '199':
    "O serviço adicional 'aviso de recebimento' somente está disponível para os pedidos de autorização de postagem", // O
  '200': 'Código de checklist inválido', // C
  '201': 'Código do produto inválido', // C
  '202': 'Serviço especial sedex 10 não é atendido por logística reversa domiciliária', // S
  '203': 'Valor tag -ar- inválido', // V
  '204': 'Tags -entrega- inconsistentes', // T
  '205': 'Valor declarado inválido', // V
  '206': 'Tag -envio.obj- inválida.', // T
  '207': 'Data de processamento inválida. verificar tag -data_processamento', // D
  '208': 'Arquivo vazio.', // A
  '209': 'Arquivo com mais de 300 coleta', // A
  '210': 'Serviço indisponível no momento', // S
  '211': 'Valor declarado não pode ser inferior a R$ 18,50', // V
  '212': 'Id de postagem inválido', // I
  '213': 'Valor inválido para a tag - qtd_caixa -', // V
  '214': 'Tag - numero - deve ser utilizado somente no serviço de autorização de postagem', // T
  '215': 'Tag - identificacao - cpf ou cnpj inválido.', // T
  '216': 'Uf inválida.', // U
  '217': 'Tag - numero – inválida', // T
  '218': 'Tag - cartao – inválida', // T
  '219': 'Tag - produto.codigo – inválida', // T
  '220': 'Tag - produto.tipo – inválida', // T
  '221': 'Tag - produto.qtd – inválida', // T
  '222': 'O cep informado não pertence a faixa de abrangência do serviço e-sedex grupo i', // O
  '223': 'O cep informado não pertence a faixa de abrangência do serviço e-sedex grupo ii', // O
  '224': 'Tipo da solicitação inválida', // T
  '225': 'Serviço inválido', // S
  '226': 'Quantidade inválida', // Q
  '227': 'O número deve possuir 8 dígitos', // O
  '228': 'Quantidade de objetos superior ao permitido', // Q
  '229': 'Tag – sms - inválida', // T
  '230': 'Tag –ddd_celular – inválida', // T
  '231': 'Tag – celular – inválida', // T
  '232': 'Os documentos para o preenchimento do checklist não foram informados no pedido', // O
  '233': 'Só é permitido o máximo de 8 documentos para a validação no checklist', // S
  '234': 'Tipo de checklis informado não encontrado', // T
  '235': 'O serviço informado não abrange o serviço de checklist documento', // O
  '236': 'A data da autorização de postagem é superior a data de vigência do contrato', // A
  '237': 'Código administrativo inválido', // C
  '238': 'Quantidade de caracteres superior ao permitido. verificar a tag', // Q
  '239': 'A tag documento não é permitida para o tipo de checklist informado', // A
  '240': 'Ao informar a relação de documentos é obrigatório indicar o checklist a ser utilizado', // A
  '241': 'O cep do remetente foi alterado. informar cep atual.', // O
  '242': 'O cep do destinatário foi alterado. informar cep atual.', // O
  '243':
    'Em virtude das restrições de mobilidade urbana durante os jogos olímpicos, no período de 28/07/2016 a 18/09/2016, o serviço de logística reversa com coleta está suspenso temporariamente para esta localidade.', // E
  '244':
    'Em virtude das restrições de mobilidade urbana durante os jogos olímpicos, no período de 28/07/2016 a 18/09/2016, o serviço de sedex 10 está temporariamente suspenso para essa localidade.', // E
  '245':
    'O serviço {0} (cód. {1}) está encerrado a partir de {2}. o prazo de validade do {3} ({4}) é superior a validade do serviço.', // O
  '246': 'A solicitação já foi cadastrada.', // A
  '247': 'Não foi possível solicitar novo range. é necessário consumir no mínimo 80% da última solicitação', // N
  '248': 'Validação do serviço no scpp', // V
};
