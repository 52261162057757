import { useGoal } from '@hooks/GoalContext';
import { GoalItem } from '@models/GoalItem';
import { Empty, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import RepresentativeCard from '../RepresentativeCard';
import SellerCard from '../SellerCard';

import * as S from './styles';

const DetailsContent: React.FC = () => {
  const { currentGoal, filteredGoalItems } = useGoal();
  const [representativesGoalItems, setRepresentativesGoalItems] = useState<GoalItem[]>([]);
  const [sellersGoalItems, setSellersGoalItems] = useState<GoalItem[]>([]);

  useEffect(() => {
    let items = currentGoal?.items;

    if (filteredGoalItems?.length > 0) {
      items = filteredGoalItems;
    }

    setRepresentativesGoalItems([]);
    setSellersGoalItems([]);

    items?.map((item) => {
      if (item.representative) {
        setRepresentativesGoalItems((oldRepresentativesGoalItems) => [...oldRepresentativesGoalItems, item]);
      }

      if (item.seller) {
        setSellersGoalItems((oldSellersGoalItems) => [...oldSellersGoalItems, item]);
      }
    });
  }, [currentGoal, filteredGoalItems, setRepresentativesGoalItems, setSellersGoalItems]);

  return (
    <S.Container>
      <Tabs className="w-100">
        <Tabs.TabPane tab="Representante" key="representative">
          {representativesGoalItems?.length > 0 && (
            <S.Panel>
              <S.ScrollContainer>
                {representativesGoalItems.map((goalItem) => (
                  <RepresentativeCard goalItem={goalItem} key={goalItem.goalItemId} />
                ))}
              </S.ScrollContainer>
            </S.Panel>
          )}
          {!representativesGoalItems?.length && (
            <Empty
              className="empty-component"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Nenhum representante encontrado"
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Vendedor" key="seller">
          {sellersGoalItems?.length > 0 && (
            <S.Panel>
              <S.ScrollContainer>
                {sellersGoalItems.map((goalItem) => (
                  <SellerCard goalItem={goalItem} key={goalItem.goalItemId} />
                ))}
              </S.ScrollContainer>
            </S.Panel>
          )}
          {!sellersGoalItems?.length && (
            <Empty
              className="empty-component"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Nenhum vendedor encontrado"
            />
          )}
        </Tabs.TabPane>
      </Tabs>
    </S.Container>
  );
};

export default DetailsContent;
