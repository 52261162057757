import styled from 'styled-components';

export const Panel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 6px;
  padding: 20px;

  > input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  > input[type='number'] {
    -moz-appearance: textfield;
  }
`;
