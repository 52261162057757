import { FilterFilled } from '@ant-design/icons';
import FiltersForm from '@components/FiltersForm';
import FiltersList, { ListFilterType } from '@components/FiltersList';
import HeaderButton from '@components/HeaderButton';
import { useShoppingBag } from '@hooks/ShoppingBagContext';
import { ShoppingBagFilters } from '@models/ShoppingBagFilters';
import { Col, Drawer, Row, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import ShoppingBagFiltersForm from '../ShoppingBagFiltersForm';

const { Title } = Typography;
const ShoppingBagHeader: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filter, setFilter] = useState<ShoppingBagFilters>({});

  const { loadShoppingBags } = useShoppingBag();

  const integrationId = localStorage.getItem('@modavest/integration_id') || 1;

  useEffect(() => {
    if (integrationId) {
      loadShoppingBags(+integrationId);
    }
  }, [integrationId]);

  const onSubmit = useCallback((values: any) => {
    const newFilters: ShoppingBagFilters = {};

    if (values?.dates?.length) {
      const initialDate = new Date(values?.dates[0]).toISOString();
      const finalDate = new Date(values?.dates[1]).toISOString();
      newFilters.initialDate = initialDate;
      newFilters.finalDate = finalDate;
    }

    if (values?.representativeCode) {
      newFilters.representativeCode = values.representativeCode;
    }

    loadShoppingBags(+integrationId, newFilters);
    setFilter(newFilters);
  }, []);

  const onClearFilters = useCallback(() => {
    loadShoppingBags(+integrationId);
    setIsDrawerOpen(false);
    setFilter({});
  }, []);

  const filterColumns = [
    {
      title: 'Código do representante',
      key: 'representativeCode',
    },
    {
      title: 'Data inicial',
      key: 'initialDate',
      isDate: true,
    },
    {
      title: 'Data final',
      key: 'finalDate',
      isDate: true,
    },
  ];

  const clearFilters = ({ key }: ListFilterType) => {
    loadShoppingBags(+integrationId);
    setFilter({});
  };

  return (
    <Row className="mt-8" justify="space-between">
      <Col>
        <Title level={2}>Sacolas em aberto</Title>
      </Col>

      <Col>
        <Row>
          <Col>
            <FiltersList columns={filterColumns} dataSource={filter} onRemove={clearFilters} />
          </Col>
          <Col>
            <HeaderButton
              text="Filtros"
              onClick={() => setIsDrawerOpen(true)}
              Icon={<FilterFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
            />
          </Col>
          <Drawer
            title="Filtros"
            placement="right"
            onClose={() => setIsDrawerOpen(false)}
            visible={isDrawerOpen}
            width={400}
          >
            <FiltersForm
              filtersForm={<ShoppingBagFiltersForm />}
              onClearFilters={onClearFilters}
              onCloseDrawer={() => setIsDrawerOpen(false)}
              onSubmit={onSubmit}
            ></FiltersForm>
          </Drawer>
        </Row>
      </Col>
    </Row>
  );
};

export default ShoppingBagHeader;
