import { AxiosResponse } from 'axios';
import * as api from './api';

export function getSellers(options: any): Promise<AxiosResponse<any>> {
  return api.get('sellers', options);
}

export function getSellerByCode(sellerCode: number): Promise<any> {
  return api.get(`sellers/${sellerCode}`);
}

export function updateAttribute(representativeId: number, params: any): Promise<any> {
  return api.patch(`sellers/${representativeId}`, params);
}

export function uploadImage(file: any): Promise<any> {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const formData = new FormData();
  formData.append('file', file);
  return api.postWithConfig('upload', formData, config).catch((error) => {
    return Promise.resolve(error.response);
  });
}
