import React from 'react';

import CustomersHeader from './components/CustomersHeader';
import CustomersContent from './components/CustomersContent';

const CustomersPage: React.FC = () => {
  return (
    <>
      <CustomersHeader />
      <CustomersContent />
    </>
  );
};

export default CustomersPage;
