import './styles.css';

import { ExclamationCircleOutlined, FilterFilled, PlusCircleFilled } from '@ant-design/icons';
import FiltersList, { ListFilterType } from '@components/FiltersList';
import GridForm from '@components/GridForm';
import HeaderButton from '@components/HeaderButton';
import { Col, Drawer, Modal, Row, Switch, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import { useGrid } from '@hooks/GridContext';
import { Grid } from '@models/Grid';
import GridFilters from './components/GridFilters';
import { FullScreenModal } from './styles';

const { Title, Text } = Typography;

const GridPage: React.FC = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { gridFilters, filterGrid, gridCurrentPage, gridsTotal, loadGrids, grids, loading, updateSpecificProperty } =
    useGrid();
  const [drawerFiltersVisible, setDrawerFiltersVisible] = useState(false);
  const [modalNewGridVisible, setModalNewGridVisible] = useState(false);  

  const unnactiveGrid = (grid: Grid) => {
    updateSpecificProperty(grid.id, { property: 'isActive', value: !grid.isActive });
  };

  const showFiltersDrawer = () => {
    setDrawerFiltersVisible(true);
  };

  const onCloseFiltersDrawer = () => {
    setDrawerFiltersVisible(false);
  };

  const showNewGridModal = () => {
    setModalNewGridVisible(true);
  };

  const onCloseNewGridModal = () => {
    setModalNewGridVisible(false);
  };

  useEffect(() => {
    loadGrids(0);
  }, []);

  const onRemoveFilter = ({ key }: ListFilterType) => {
    Modal.confirm({
      title: 'Deseja realmente excluir?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Excluir',
      okType: 'danger',
      onOk() {
        const newFilters = gridFilters;

        delete newFilters[key];

        filterGrid(newFilters);
      },
    });
  };

  const onResetFilters = () => {
    const newFilters = gridFilters;

    Object.keys(gridFilters).forEach((key) => {
      delete newFilters[key];
    });

    filterGrid(newFilters);
  };

  const handleTableChanges = (pagination: any, filters: any, sorter: any) => {
    if (sorter.hasOwnProperty('column')) {
      gridFilters.field = `${sorter.field}`;
      gridFilters.order = `${sorter.order}`;
    }

    loadGrids(pagination.current, gridFilters);
  };

  return (
    <>
      <Row className="mt-8" justify="space-between">
        <Col>
          <Title level={2}>Minhas Grades</Title>
        </Col>
        <Col>
          <Row>
            <Col>
              <FiltersList
                columns={[
                  { title: 'Nome das Grades', key: 'gradeName' },
                  { title: 'Status das Grades', key: 'isActive' },
                ]}
                dataSource={gridFilters}
                onRemove={onRemoveFilter}
              />
            </Col>
            <Col>
              <HeaderButton
                text="Filtros"
                onClick={showFiltersDrawer}
                Icon={<FilterFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
              />
            </Col>
            <Col>
              <HeaderButton
                text="Nova Grade"
                onClick={showNewGridModal}
                Icon={<PlusCircleFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Drawer
        title="Filtros"
        placement="left"
        onClose={onCloseFiltersDrawer}
        visible={drawerFiltersVisible}
        width={400}
      >
        <GridFilters onCloseDrawer={onCloseFiltersDrawer} onResetFilters={onResetFilters} />
      </Drawer>

      <FullScreenModal
        destroyOnClose={true}
        visible={modalNewGridVisible}
        onOk={onCloseNewGridModal}
        onCancel={onCloseNewGridModal}
        footer={null}
        forceRender={false}
      >
        <Row align="middle" justify="center" style={{ height: '100%', maxHeight: '100vh', overflow: 'auto' }}>
          <Col
            span={24}
            className="d-flex"
            style={{
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <GridForm onCloseModal={onCloseNewGridModal} />
          </Col>
        </Row>
      </FullScreenModal>

      <Row align="middle" justify="center">
        <Col span={24}>
          <Table
            columns={
              [
                {
                  title: 'Nome da grade',
                  dataIndex: 'gradeName',
                  key: 'gradeName',
                  render: (text: string, grid: Grid): JSX.Element => {
                    return <strong>{text}</strong>;
                  },
                  sorter: true,
                },
                {
                  title: 'Tamanhos',
                  dataIndex: 'sizes',
                  key: 'sizes',
                  render: (text: string, grid: Grid): JSX.Element => {
                    let sizes = '';
                    if (grid?.sizes?.length > 1) {
                      grid.sizes.forEach((size, index) => {
                        if (index === 0) {
                          sizes = size.size;
                        } else {
                          sizes = `${sizes}, ${size.size}`;
                        }
                      });
                    } else {
                      sizes = grid?.sizes[0]?.size || '';
                    }
                    return <p>{sizes}</p>;
                  },
                },
                {
                  title: 'Ações',
                  dataIndex: 'sizes',
                  key: 'sizes',
                  render: (text: string, grid: Grid): JSX.Element => {
                    return <Switch onChange={() => unnactiveGrid(grid)} checked={grid?.isActive}></Switch>;
                  },
                },
              ] as any
            }
            dataSource={grids}
            loading={loading}
            rowKey={(record) => record.collectionId}
            style={{ marginTop: '16px' }}
            scroll={{ x: 800 }}
            pagination={{
              total: gridsTotal,
              current: gridCurrentPage,
              pageSize: 10,
              showSizeChanger: false,
            }}
            onChange={handleTableChanges}
          />
        </Col>
      </Row>
    </>
  );
};

export default GridPage;
