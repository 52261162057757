import { Select, Form, Typography, Spin, Checkbox, message, Modal } from 'antd';

import { StatesArray } from '@models/States';

import * as S from '../../styles';
import './styles.css';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
import { usePriceTable } from '@hooks/PriceTableContext';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ArrowRightOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useEffect } from 'react';

const { Title } = Typography;

interface PriceTablesFormProps {}

const PriceTablesForm: React.FC<PriceTablesFormProps> = () => {
  const { addPriceTableToOfficialStore, actualOfficialStore, loadingAddPriceTable } = useOfficialStore();
  const { loadPriceTables, loading: loadingPriceTable, priceTables } = usePriceTable();
  const [form] = Form.useForm();

  useEffect(() => {
    loadPriceTables(0, { listAll: true });
  }, []);

  const handleFormSubmit = (values: any) => {
    if (!values?.stateCode?.length && !values.applyToAll)
      return message.warning('Informe ao menos um Estado, ou aplique para todos!');

    Modal.confirm({
      title: 'Ao prosseguir as Tabelas de Preços serão sobrescrevidas',
      icon: <ExclamationCircleOutlined />,
      content: 'Deseja mesmo prosseguir?',
      okText: 'Sim',
      cancelText: 'Não',
      onOk: async () => {
        addPriceTableToOfficialStore(
          actualOfficialStore.officialStoreId,
          Number(values.priceTableCode),
          values.stateCode,
          values.applyToAll,
        );
      },
    });
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      form.setFieldsValue({
        stateCode: undefined,
      });
    }
  };

  const handlePricleTableStateChange = (value: any) => {
    if (value) {
      form.setFieldsValue({
        applyToAll: false,
      });
    }
  };

  if (loadingAddPriceTable || loadingPriceTable) return <Spin />;

  return (
    <Form layout="vertical" className="form-price-table" onFinish={handleFormSubmit} form={form}>
      <Title className="mt-2 mb-7" level={5}>
        Selecionar Tabelas de Preço
      </Title>

      <Form.Item
        label="Tabela de Preço"
        name="priceTableCode"
        rules={[{ required: true, message: 'Selecione uma Tabela de Preço' }]}
      >
        <Select size="large">
          {priceTables.map((_priceTable) => (
            <Select.Option value={_priceTable.code}>
              #{_priceTable.code} <ArrowRightOutlined style={{ fontSize: '10px' }} /> {_priceTable.description}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Estado" name="stateCode">
        <Select size="large" mode="multiple" onChange={handlePricleTableStateChange}>
          {StatesArray.map((_state) => (
            <Select.Option value={_state.key}>{_state.value}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item className="d-flex" name="applyToAll" style={{ textAlign: 'center' }} valuePropName="checked">
        <Checkbox onChange={handleCheckboxChange}>Aplicar para todos os Estados?</Checkbox>
      </Form.Item>

      <S.CustomButton
        className="mb-2 mt-4"
        type="primary"
        noMargin
        style={{ width: '100%', height: '48px' }}
        htmlType="submit"
      >
        APLICAR ALTERAÇÕES
      </S.CustomButton>
    </Form>
  );
};

export default PriceTablesForm;
