import { Col, Row, Typography } from 'antd';
import React from 'react';

const { Title } = Typography;

const DevolutionHeader: React.FC = () => {
  return (
    <Row className="mt-8" justify="space-between">
      <Col>
        <Title level={2}>Devoluções</Title>
      </Col>
    </Row>
  );
};

export default DevolutionHeader;
