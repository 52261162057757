import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { LeftOutlined, MenuOutlined } from '@ant-design/icons';

import { Col, Drawer, Image, Row, Typography } from 'antd';

import Navigation from '@components/OfficialStoreNavigation';
import { OfficialStore } from '@models/OfficialStore';

const { Text, Title } = Typography;

interface OfficialStorePaymentHeaderProps {
  officialStore: OfficialStore;
}

const OfficialStorePaymentHeader: React.FC<OfficialStorePaymentHeaderProps> = ({ officialStore }) => {
  const history = useHistory();

  const [drawerIsVisible, setDrawerIsVisible] = useState(false);

  const backUrl = () => {
    history.go(-1);
  };

  const onCloseDrawer = () => {
    setDrawerIsVisible(false);
  };

  const showDrawer = () => {
    setDrawerIsVisible(true);
  };

  return (
    <>
      <Row className="mt-3" justify="space-between">
        <Col className="d-flex" style={{ alignItems: 'center' }}>
          <LeftOutlined onClick={() => backUrl()} style={{ fontSize: '20px', marginRight: '12px' }} />

          <Image
            src={officialStore.logoUrl}
            alt="Logo da empresa"
            preview={false}
            style={{ width: '100%', maxWidth: '120px', borderRadius: '8px' }}
          />

          <Row style={{ flexDirection: 'column', marginLeft: '12px' }}>
            <Col>
              <Title level={5} style={{ margin: '0' }}>
                {officialStore.description}
              </Title>
            </Col>
            <Col>
              <Text>#{officialStore.officialStoreId}</Text>
            </Col>
          </Row>
        </Col>

        <Col className="d-flex" style={{ alignItems: 'center', flexDirection: 'column' }}>
          <Row className="d-flex" style={{ alignItems: 'center' }}>
            <MenuOutlined onClick={showDrawer} style={{ fontSize: '22px', color: '#510c76', cursor: 'pointer' }} />
          </Row>

          <Row className="d-flex" style={{ alignItems: 'center' }}></Row>

          <Drawer
            placement="right"
            onClose={onCloseDrawer}
            visible={drawerIsVisible}
            closable={false}
            bodyStyle={{ padding: '0' }}
          >
            <Navigation officialStore={officialStore} />
          </Drawer>
        </Col>
      </Row>
    </>
  );
};

export default OfficialStorePaymentHeader;
