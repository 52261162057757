import { Form } from 'antd';
import styled from 'styled-components';

export const CustomForm = styled(Form)`
  width: min(800px, 100%);
  background: white;
  padding: 20px;
  border-radius: 8px;
`;

export const ScrollPanel = styled.div`
  height: 60vh;
  overflow-y: scroll;
  padding-right: 12px;

  & .hide {
    pointer-events: none;
    text-decoration-line: line-through;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background: #f5f5f5;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-appearance: none;
    background: var(--primary-color);
    border-radius: 8px;
  }
`;
