import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { usePaymentConditions } from '@hooks/PaymentConditionsContext';
import { OfficialStore } from '@models/OfficialStore';
import { Button, Form, InputNumber, message, Modal, Radio, Space, Table } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useCallback } from 'react';

import * as S from '../../styles';

interface maxCardInstallmentColumnsProps {
  officialStore: OfficialStore;
}

const PanelMaxCardIntallments = ({ officialStore }: maxCardInstallmentColumnsProps) => {
  const { patchCardMaxInstallments, loadingInstallments } = usePaymentConditions();

  const [form] = useForm();

  const showModal = (res: any) => {
    Modal.confirm({
      title: 'Deseja realmente atualizar?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onUpdateCardMaxInstallment(res);
      },
    });
  };
  const onUpdateCardMaxInstallment = useCallback(async (res: any) => {
    try {
      let feesShopkeeper: number;
      let feesManufacture: number;

      if (res.fees === 1) {
        feesShopkeeper = 1;
        feesManufacture = 0;
      } else {
        feesShopkeeper = 0;
        feesManufacture = 1;
      }

      await patchCardMaxInstallments({
        ...res,
        feesShopkeeper,
        feesManufacture,
        officialStoreId: officialStore.officialStoreId,
      });

      message.success('Regras de parcelamento atualizadas com sucesso.');
    } catch (e) {
      message.error('Falha na atualização das regras de parcelamento.');
    }
  }, []);

  const maxCardInstallmentColumns = [
    {
      title: 'Quantidade máxima de parcelas do cartão',
      render: () => (
        <Form
          className="d-flex justify-space-evenly align-center"
          style={{ alignItems: 'center', justifyContent: 'center' }}
          onFinish={showModal}
          form={form}
          initialValues={{
            installment: officialStore?.installment?.installment ?? 0,
            fees: officialStore?.installment?.feesShopkeeper === 1 ? 1 : 2,
          }}
        >
          <Form.Item name={'installment'} className="px-4">
            <InputNumber style={{ width: 'min(100%, 200px)', height: '40px' }} min={0} type="number" />
          </Form.Item>

          <Form.Item name={'fees'}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value={1}>Juros do Lojista</Radio>
                <Radio value={2}>Juros do Fabricante</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ height: '40px' }}>
              <CheckOutlined />
            </Button>
          </Form.Item>
        </Form>
      ),
    },
  ];

  let hasCard;

  if (officialStore?.paymentTypes) {
    hasCard = officialStore.paymentTypes.some((pt) => {
      if (
        pt.paymentType === 'CARTÃO' &&
        (pt.isAccept || pt.isAcceptWithGuide || pt.isAcceptWithGuideOrRepresentative || pt.isAcceptWithRepresentative)
      )
        return true;
      return false;
    });
  }

  if (hasCard)
    return (
      <S.Panel>
        <Table columns={maxCardInstallmentColumns} dataSource={[{}]} pagination={false} loading={loadingInstallments} />
      </S.Panel>
    );
  else
    return (
      <S.Panel hidden={true}>
        <Table columns={maxCardInstallmentColumns} dataSource={[{}]} pagination={false} loading={loadingInstallments} />
      </S.Panel>
    );
};

export default PanelMaxCardIntallments;
