import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

import { Representative } from '@models/Representative';
import * as RepresentativeService from '@services/representative';
import { INITIAL_PAGE, REPRESENTATIVE_PAGINATION_SIZE } from '@utils/constants';
import { message } from 'antd';

interface RepresentativeState {
  loading: boolean;
  loadingCustomers: boolean;
  loadingImageUpload: boolean;
  loadingOrders: boolean;

  customers: any[];
  customersTotal: number;
  customersPage: number;
  loadCustomersByRepresentativeIdPaginated: Function;
  loadCustomersByRepresentativeId: Function;

  orders: any[];
  ordersTotal: number;
  ordersPage: number;
  loadOrdersByRepresentativeIdPaginated: Function;

  actualRepresentative: Representative;
  representatives: any[];
  representativeFilters: any;
  loadRepresentativesPaginated: Function;
  loadRepresentatives: Function;
  loadRepresentativeById: Function;
  currentPage: number;
  totalPages: number;

  updatePermission: Function;
  updateImage: Function;

  createRepresentative: Function;
}

interface RepresentativeProviderProps {
  children: ReactNode;
}

export const RepresentativeContext = createContext<RepresentativeState>({} as RepresentativeState);

const RepresentativeProvider: React.FC<RepresentativeProviderProps> = ({ children }) => {
  const [representatives, setRepresentatives] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [actualRepresentative, setActualRepresentative] = useState<Representative>({} as Representative);
  const [representativeFilters, setRepresentativeFilters] = useState<any>({} as any);

  const [customers, setCustomers] = useState<any[]>([]);
  const [customersTotal, setCustomersTotal] = useState<number>(0);
  const [customersPage, setCustomersPage] = useState<number>(0);

  const [orders, setOrders] = useState<any[]>([]);
  const [ordersTotal, setOrdersTotal] = useState<number>(0);
  const [ordersPage, setOrdersPage] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCustomers, setLoadingCustomers] = useState<boolean>(false);
  const [loadingOrders, setLoadingOrders] = useState<boolean>(false);
  const [loadingImageUpload, setLoadingImageUpload] = useState<boolean>(false);

  const loadRepresentatives = useCallback(async () => {
    setLoading(true);

    const { data } = await RepresentativeService.get({
      listAll: true,
      page: 0,
      pageSize: 0,
      orderBy: 'name',
      direction: 'ASC',
    });

    setRepresentatives(data.data);
    setLoading(false);
  }, [setRepresentatives, setLoading]);

  const loadRepresentativesPaginated = async (page: number, filters?: { searchByNameOrCnpj: string }) => {
    setLoading(true);

    if (filters) setRepresentativeFilters(filters);
    else setRepresentativeFilters({});
    setCurrentPage(page);

    try {
      const options = {
        page,
        pageSize: REPRESENTATIVE_PAGINATION_SIZE,
        ...(filters && { ...filters }),
        orderBy: 'name',
        direction: 'ASC',
      };
      const { data: _representatives } = await RepresentativeService.get(options);
      if (_representatives.total === 0) message.warning('Nenhum registro encontrado!');
      setRepresentatives(_representatives.data);
      setTotalPages(_representatives.total);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const loadRepresentativeById = useCallback(
    async (representativeId: number) => {
      setLoading(true);

      const { data } = await RepresentativeService.getById(representativeId);
      const { data: _orders } = await RepresentativeService.getOrdersByRepresentativeId(representativeId, {
        pageSize: 10,
        page: 0,
      });

      setOrdersTotal(_orders.total);

      if (_orders.data) setOrders(_orders.data);

      setActualRepresentative(data);
      setLoading(false);
    },
    [setRepresentatives, setLoading, setOrdersTotal, setOrders],
  );

  const loadCustomersByRepresentativeIdPaginated = useCallback(
    async (representativeId: number, page?: number) => {
      setLoadingCustomers(true);

      const { data: _customers } = await RepresentativeService.getCustomersByRepresentativeId(representativeId, {
        pageSize: 10,
        page: page ? page - 1 : 0,
      });

      setCustomersPage(page ? page : 0);

      setCustomers(_customers.data);
      setCustomersTotal(_customers.total);
      setLoadingCustomers(false);
    },
    [setLoadingCustomers, REPRESENTATIVE_PAGINATION_SIZE, setCustomers, setCustomersTotal],
  );

  const loadCustomersByRepresentativeId = useCallback(
    async (representativeId: number) => {
      setLoadingCustomers(true);

      const { data: _customers } = await RepresentativeService.getCustomersByRepresentativeId(representativeId, {
        pageSize: 10,
        page: 0,
        listAll: true,
      });

      setCustomers(_customers.data);
      setCustomersTotal(_customers.total);
      setLoadingCustomers(false);
    },
    [setLoadingCustomers, setCustomers, setCustomersTotal],
  );

  const loadOrdersByRepresentativeIdPaginated = useCallback(
    async (representativeId: number, page?: number) => {
      setLoadingOrders(true);

      const { data: _customers } = await RepresentativeService.getOrdersByRepresentativeId(representativeId, {
        pageSize: 10,
        page: page ? page - 1 : 0,
      });

      setOrdersPage(page ? page : 0);

      if (_customers.data) setOrders(_customers.data);

      setOrdersTotal(_customers.total);
      setLoadingOrders(false);
    },
    [setLoadingOrders, REPRESENTATIVE_PAGINATION_SIZE, setOrders, setOrdersTotal],
  );

  const updatePermission = useCallback(
    async (representativeId: number, permission: any) => {
      setLoading(true);

      const { data: _customers } = await RepresentativeService.updateAttribute(representativeId, permission);

      await loadRepresentativeById(representativeId);

      setLoading(false);
    },
    [setLoading, loadRepresentativeById],
  );

  const updateImage = useCallback(
    async (representativeId: number, file: any) => {
      setLoadingImageUpload(true);
      try {
        const { data: _image } = await RepresentativeService.uploadImage(file);

        await RepresentativeService.updateAttribute(representativeId, {
          property: 'photoUrl',
          value: _image.url,
        });

        setActualRepresentative({ ...actualRepresentative, photoUrl: _image.url });
        message.success('Imagem alterada com sucesso!');
      } catch {
        message.error('Erro ao alterar imagem!');
      } finally {
        setLoadingImageUpload(false);
      }
    },
    [actualRepresentative, setLoadingImageUpload, setActualRepresentative],
  );

  const createRepresentative = useCallback(async (body: any) => {
    try {
      const { data: _representative } = await RepresentativeService.createRepresentative(body);

      await loadRepresentatives();

      message.success('Representante cadastrado com sucesso!');
    } catch (e) {
      message.error('Erro ao cadastrar representante!');
      throw e;
    }
  }, []);

  return (
    <RepresentativeContext.Provider
      value={{
        currentPage,
        totalPages,

        loading,
        loadingCustomers,
        loadingImageUpload,
        loadingOrders,

        customers,
        customersTotal,
        customersPage,
        loadCustomersByRepresentativeIdPaginated,
        loadCustomersByRepresentativeId,

        orders,
        ordersTotal,
        ordersPage,
        loadOrdersByRepresentativeIdPaginated,

        actualRepresentative,
        representatives,
        representativeFilters,
        loadRepresentativesPaginated,
        loadRepresentatives,
        loadRepresentativeById,

        updatePermission,
        updateImage,

        createRepresentative,
      }}
    >
      {children}
    </RepresentativeContext.Provider>
  );
};

const useRepresentative = () => {
  return useContext(RepresentativeContext);
};

export { RepresentativeProvider, useRepresentative };
