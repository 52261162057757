import * as api from './api';

export interface ReferenceVideoUnique {
  referenceCode: string;
  integrationId?: number;
}

export interface ReferenceVideoCreate {
  referenceCode: string;
  video: string;
}

export function getByReference({ referenceCode, integrationId }: ReferenceVideoUnique) {
  let url = `reference-videos/reference/${referenceCode}`;
  if (integrationId) {
    url += `?integrationId=${integrationId}`;
  }
  return api.get(url);
}

export function getByUrl(urlVideo: string) {
  return api.get(`reference-videos/video/${urlVideo}`);
}

export function createOrUpdateReferenceVideo(body: ReferenceVideoCreate) {
  return api.post(`reference-videos`, body);
}

export function deleteReferenceVideoByReferenceCode({ referenceCode, integrationId }: ReferenceVideoUnique) {
  let url = `reference-videos/reference/${referenceCode}`;
  if (integrationId) {
    url += `?integrationId=${integrationId}`;
  }
  return api.remove(url);
}
