import { Table, Typography } from 'antd';

const { Text } = Typography;

interface TabAveragePeriodProps {
  averagePeriods: any[];
}

const TabAveragePeriod: React.FC<TabAveragePeriodProps> = ({ averagePeriods }) => {
  const columns = [
    {
      title: 'Prazo Médio',
      dataIndex: 'quantity',
      width: '20%',
      align: 'center' as 'center',
    },
    {
      title: 'Variação (%)',
      dataIndex: 'variationPercentage',
      align: 'right' as 'right',
      render: (value: any) => (value ? <Text>{value}</Text> : <Text>0</Text>),
    },
    {
      title: 'Variação (Valor)',
      dataIndex: 'variationValue',
      align: 'right' as 'right',
      render: (value: any) => (value ? <Text>{value.toFixed(2)}</Text> : <Text>0,00</Text>),
    },
  ];

  return <Table 
  scroll={{ x: 800 }}
  columns={columns} dataSource={averagePeriods} pagination={{ pageSize: 4, hideOnSinglePage: true }} />;
};

export default TabAveragePeriod;
