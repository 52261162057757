import { message } from 'antd';
import React, { useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { useDevolution } from '@hooks/DevolutionsContext';
import { Devolution } from '@models/Devolution';
import DevolutionRequestInvoiceDataForm from './steps/DevolutionInvoiceDataForm';
import { Container } from './styles';

interface DevolutionFormProps {
  devolution: Devolution;
  onCloseModal: Function;
}

const DevolutionForm: React.FC<DevolutionFormProps> = ({ devolution, onCloseModal }) => {
  const { updateDevolutionRequest } = useDevolution();
  const [loading, setLoading] = useState<boolean>(false);

  const [formValues, setFormValues] = React.useState<{ devolution: Devolution; updateObj?: any }>();

  React.useEffect(() => {
    setFormValues({ devolution: devolution });
  }, []);

  const hadleSubmit = async (params: any) => {
    setLoading(true);

    try {
      await updateDevolutionRequest(params);

      message.success('A devolução foi atualizada com sucesso!!');
      onCloseModal();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setFormValues({ devolution: devolution, updateObj: params });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '50px',
        width: '100%',
      }}
    >
      <div className="steps-action d-flex" style={{ width: '100%' }}>
        <Container
          style={{
            background: '#fff',
            padding: '100px',
            borderRadius: '8px',
            width: '100%',
          }}
        >
          {loading && (
            <div
              style={{
                width: '1100px',
                height: '400px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LoadingOutlined
                type="play-circle-o"
                style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
              />
            </div>
          )}

          {!loading && (
            <>
              <DevolutionRequestInvoiceDataForm formValues={formValues} handleSubmit={hadleSubmit} />
            </>
          )}
        </Container>
      </div>
    </div>
  );
};

export default DevolutionForm;
