import * as api from './api';
import * as apiMerp from './api-merp';

export function get(params?: any) {
  return api.get('collections', params);
}

export async function saveCollection(body: any): Promise<any> {
  return api.post('collections', body);
}

export async function updateCollection(id: number, body: any): Promise<any> {
  return api.put(`collections/${id}`, body);
}

export async function saveCollectionMerp(body: any): Promise<any> {
  return apiMerp.post('collectios', body);
}
