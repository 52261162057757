import React from 'react';
import { Typography } from 'antd';

import { Parameter } from '@models/Parameter';
import * as S from './styles';

const { Title, Text } = Typography;

interface DescriptionsProps {
  parameter: Partial<Parameter>;
}
const Descriptions: React.FC<DescriptionsProps> = ({ parameter }) => {
  return (
    <S.Container className="d-flex" style={{ flexDirection: 'column' }}>
      <Title level={4}>
        Informações do Parâmetro
      </Title>

      <div className="d-flex mt-3" style={{ alignItems: 'center' }}>
        <Title level={5} className="mb-0 mr-1">
          ID:
        </Title>
        <Text>{parameter.parameterId}</Text>
      </div>

      <Title level={5} className="mt-5 mb-0">
        Código do Parâmetro:
      </Title>
      <Text>{parameter.code}</Text>

      <Title level={5} className="mt-5 mb-0">
        Descrição do Parâmetro:
      </Title>
      <Text>{parameter.description}</Text>

      <Title level={5} className="mt-5 mb-0">
        Descrição da Utilidade:
      </Title>
      <Text>{parameter.utility}</Text>

      <Title level={5} className="mt-5 mb-0">
        Descrição da Funcionalidade:
      </Title>
      <Text>{parameter.functionality}</Text>

      <Title level={5} className="mt-5 mb-0">
        Descrição da Variante:
      </Title>
      <Text>{parameter.variant}</Text>

      <Title level={5} className="mt-5 mb-0">
        Descrição do Valor:
      </Title>
      <Text>{parameter.value}</Text>
    </S.Container>
  );
};

export default Descriptions;
