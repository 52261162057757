import { Row, Table, Typography } from 'antd';
import React from 'react';

import LoadingComponents from '@components/LoadingComponents';
import { useIntegration } from '@hooks/IntegrationContext';
import { useManufacturer } from '@hooks/ManufacturerContext';
import { Email } from '@models/Email';
import { Phone } from '@models/Phone';
import CardAddress from '../CardAddress';
import CardCnpj from '../CardCnpj';
import CardLogo from '../CardLogo';
import CardTable from '../CardTable';

const { Text } = Typography;

const ManufacturerContent: React.FC = () => {
  const { hasMerpIntegration } = useIntegration();

  const getType = (value: number) => {
    if (value === 0) {
      return value?.toString().replace('0', 'Vendas');
    }

    if (value === 1) {
      return value?.toString().replace('1', 'Compras');
    }

    return value?.toString().replace('2', 'Administrativo');
  };

  const phoneColumns = [
    {
      title: 'Número',
      dataIndex: 'phoneNumber',
      render: (value: any) => {
        return value?.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
      },
    },
    {
      title: 'Tipo',
      dataIndex: 'phoneTypeCode',
      sorter: (phone: Phone, nextPhone: Phone) => phone.phoneId - nextPhone.phoneId,
      render: (value: number) => <Text>{getType(value)}</Text>,
    },
  ];

  const emailColumns = [
    {
      title: 'E-mail',
      dataIndex: 'emailName',
    },
    {
      title: 'Tipo',
      dataIndex: 'emailTypeCode',
      sorter: (email: Email, nextEmail: Email) => email.emailId - nextEmail.emailId,
      render: (value: number) => <Text>{getType(value)}</Text>,
    },
  ];

  const { phones, emails, loading } = useManufacturer();

  if (loading) return <LoadingComponents />;

  return (
    <>
      <Row className="mt-4" justify="space-between" gutter={[16, 16]}>
        <CardLogo xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} />
        <CardCnpj xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} />

        {!hasMerpIntegration &&
          <CardAddress xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} />
        }
      </Row>
      <Row className="mt-4" justify="space-between" gutter={[16, 16]}>
        <CardTable
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
          title="Telefones Cadastrados"
          table={<Table className="w-100" columns={phoneColumns} dataSource={phones}
            scroll={{ x: 800 }}
          />}
        />
        <CardTable
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
          title="Emails Cadastrados"
          table={<Table className="w-100" columns={emailColumns} dataSource={emails}

            scroll={{ x: 800 }} />}
        />
      </Row>
    </>
  );
};

export default ManufacturerContent;
