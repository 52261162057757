import { Form, Modal, Select, Spin, Typography } from 'antd';

import { ArrowRightOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
import { usePriceTable } from '@hooks/PriceTableContext';
import { PriceTable } from '@models/PriceTable';
import { useEffect, useState } from 'react';
import * as S from '../../styles';
import './styles.css';

const { Title } = Typography;

interface RepresentativePriceTablesFormProps {}

const RepresentativePriceTablesForm: React.FC<RepresentativePriceTablesFormProps> = () => {
  const { addRepresentativePriceTableToOfficialStore, actualOfficialStore, loadingAddPriceTable } = useOfficialStore();
  const { loadPriceTables, loading: loadingPriceTable, priceTables } = usePriceTable();
  const [filteredPriceTables, setFilteredPriceTables] = useState<PriceTable[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    loadPriceTables(0, { listAll: true });
  }, []);

  useEffect(() => {
    if (priceTables?.length) {
      if (actualOfficialStore?.representativePriceTables) {
        const relationsId: number[] = actualOfficialStore?.representativePriceTables.map(
          (representative) => representative.priceTableCode,
        );

        const filterResult = priceTables
          .sort((a, b) => {
            if (a.code > b.code) return 1;
            if (a.code < b.code) return -1;
            return 0;
          })
          .filter((priceTable) => !relationsId.includes(priceTable.code));
        setFilteredPriceTables(filterResult);
      } else {
        setFilteredPriceTables(priceTables);
      }
    }
  }, [priceTables]);

  const handleFormSubmit = (values: any) => {
    Modal.confirm({
      title: 'Ao prosseguir a Tabela de Preço selecionada será disponibilizada aos representantes',
      icon: <ExclamationCircleOutlined />,
      content: 'Deseja mesmo prosseguir?',
      okText: 'Sim',
      cancelText: 'Não',
      onOk: async () => {
        addRepresentativePriceTableToOfficialStore(actualOfficialStore.officialStoreId, Number(values.priceTableCode));
      },
    });
  };

  if (loadingAddPriceTable || loadingPriceTable) return <Spin />;

  return (
    <Form layout="vertical" className="form-price-table" onFinish={handleFormSubmit} form={form}>
      <Title className="mt-2 mb-7" level={5}>
        Selecionar Tabelas de Preço
      </Title>

      <Form.Item
        label="Tabela de Preço"
        name="priceTableCode"
        rules={[{ required: true, message: 'Selecione uma Tabela de Preço' }]}
      >
        <Select size="large">
          {filteredPriceTables?.length > 0
            ? filteredPriceTables.map((_priceTable) => (
                <Select.Option value={_priceTable.code}>
                  #{_priceTable.code} <ArrowRightOutlined style={{ fontSize: '10px' }} /> {_priceTable.description}
                </Select.Option>
              ))
            : 'Buscando tabelas de preço...'}
        </Select>
      </Form.Item>

      <S.CustomButton
        className="mb-2 mt-4"
        type="primary"
        noMargin
        style={{ width: '100%', height: '48px' }}
        htmlType="submit"
      >
        APLICAR ALTERAÇÕES
      </S.CustomButton>
    </Form>
  );
};

export default RepresentativePriceTablesForm;
