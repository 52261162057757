import './styles.css';

import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { useBrand } from '@hooks/BrandContext';
import { useCollaborator } from '@hooks/CollaboratorContext';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
import { Collaborator } from '@models/Collaborator';
import { FullScreenModal } from '@styles/globals';
import { Dropdown, Menu, Modal, notification, Table, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import BrandForm from './components/BrandForm';
import BrandTable from './components/BrandTable';
import CollaboratorsForm from './components/ModalForm';
import OfficialStoreForm from './components/OfficialStoreForm';
import OfficialStoreTable from './components/OfficialStoreTable';
import { ICollaboratorForm } from './models/ICollaboratorForm';

const { Title } = Typography;

const CollaboratorsPage: React.FC = () => {
  const [createCollaboratorModalIsVisible, setCreateCollaboratorModalIsVisible] = useState(false);
  const [currentCollaborator, setCurrentCollaborator] = useState<Collaborator>({} as Collaborator);
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
  const [officialStoreModalIsVisible, setOfficialStoreModalIsVisible] = useState(false);
  const [brandModalIsVisible, setBrandModalIsVisible] = useState(false);

  const { loadOfficialStores, loading: loadingOfficialStores } = useOfficialStore();
  const { loadBrands, loading: loadingBrands } = useBrand();
  const {
    collaborators,
    collaboratorFilters,
    collaboratorTotal,
    collaboratorsPage,
    createCollaborator,
    updateCollaborator,
    loadCollaboratorDetails,
    loadRequestCollaborators,
    removeCollaborator,
    loading: loadingCollaborator,
  } = useCollaborator();

  const onCloseOfficialStoreModal = () => setOfficialStoreModalIsVisible(false);
  const onShowOfficialStoreModal = () => setOfficialStoreModalIsVisible(true);

  const onCloseBrandModal = () => setBrandModalIsVisible(false);
  const onShowBrandModal = () => setBrandModalIsVisible(true);

  const menu = (
    <Menu className="collaborators-dropdown-menu">
      <Menu.Item key="0" className="collaborators-dropdown-menu-item">
        <button
          type="button"
          className="collaborators-dropdown-menu-item-button"
          onClick={() => {
            setCreateCollaboratorModalIsVisible(true);
          }}
        >
          Editar Usuário
        </button>
      </Menu.Item>
      <Menu.Item key="1" className="collaborators-dropdown-menu-item">
        <button
          type="button"
          onClick={() => {
            removeCollaborator(currentCollaborator.collaboratorId);
            setCurrentCollaborator({} as Collaborator);
          }}
          className="collaborators-dropdown-menu-item-button"
        >
          Desativar Usuário
        </button>
      </Menu.Item>
      <Menu.Item key="2" className="collaborators-dropdown-menu-item" onClick={onShowOfficialStoreModal}>
        <button type="button" className="collaborators-dropdown-menu-item-button">
          Lojas Oficiais
        </button>
      </Menu.Item>
      <Menu.Item key="3" className="collaborators-dropdown-menu-item" onClick={onShowBrandModal}>
        <button type="button" className="collaborators-dropdown-menu-item-button">
          Marcas
        </button>
      </Menu.Item>
    </Menu>
  );

  const handleSaveCollaborator = useCallback(
    async (collaborator: ICollaboratorForm) => {
      try {
        if (!collaborator.collaboratorId) {
          const colab = await createCollaborator(collaborator);
          if (colab) {
            setCurrentCollaborator({} as Collaborator);
            setCreateCollaboratorModalIsVisible(false);
          }
          return;
        } else {
          const colab = await updateCollaborator(collaborator);
          if (colab) {
            setCurrentCollaborator({} as Collaborator);
            setCreateCollaboratorModalIsVisible(false);
          }
          return;
        }
      } catch (err) {
        console.error('error in handle save collaborator: ', err);
        notification.error({ message: 'Erro', description: 'Falha ao salvar o colaborador' });
      }
    },
    [createCollaborator, updateCollaborator],
  );

  useEffect(() => {
    loadOfficialStores(undefined, undefined, true);
    loadBrands();
    loadRequestCollaborators(0);
  }, []);

  const columnsOne = [
    {
      title: 'ID',
      dataIndex: 'collaboratorId',
      key: 'collaboratorId',
      sorter: true,
      render: (value: number) => {
        return `#${value}`;
      },
    },
    {
      title: 'Nome',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'actions',
      align: 'right' as 'right',
      render: (text: any, collaborator: any): JSX.Element => {
        return (
          <>
            <Dropdown overlay={menu} trigger={['click']}>
              <a
                className="ant-dropdown-link"
                onClick={async () => {
                  await loadCollaboratorDetails(collaborator.collaboratorId);
                  setCurrentCollaborator(collaborator);
                }}
              >
                <EllipsisOutlined style={{ fontSize: '24px', color: '#510c76', cursor: 'pointer' }} />
              </a>
            </Dropdown>
          </>
        );
      },
    },
  ];

  const onTableRowExpand = (expanded: any, record: any) => {
    const keys = [];

    if (expanded) {
      keys.push(record.collaboratorId);
    }

    setExpandedRowKeys(keys);

    if (expanded) return loadCollaboratorDetails(record.collaboratorId);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.hasOwnProperty('column')) {
      collaboratorFilters.field = `${sorter.field}`;
      collaboratorFilters.order = `${sorter.order}`;
    }

    loadRequestCollaborators(pagination.current, collaboratorFilters);
  };

  return (
    <div className="collaborators mt-8">
      <header className="collaborators-header">
        <Title level={2}>Colaboradores</Title>

        <div className="collaborators-right-content-header">
          <div className="collaborators-add-button">
            <button
              type="button"
              className="collaborators-button"
              onClick={() => {
                setCreateCollaboratorModalIsVisible(true);
              }}
            >
              <PlusOutlined width={24} />
              Novo Colaborador
            </button>
          </div>
        </div>
      </header>

      <Table
        className="mt-4"
        columns={columnsOne}
        scroll={{ x: 800 }}
        dataSource={collaborators?.map((collaborator: any) => ({ ...collaborator, key: collaborator.collaboratorId }))}
        rowKey={(record) => record.collaboratorId}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <OfficialStoreTable collaboratorId={record.collaboratorId} officialStores={record.officialStores} />
              <BrandTable collaboratorId={record.collaboratorId} brands={record.brands} />
            </>
          ),
          onExpand: onTableRowExpand,
        }}
        expandedRowKeys={expandedRowKeys}
        pagination={{
          current: collaboratorsPage,
          total: collaboratorTotal,
          pageSize: 10,
          showSizeChanger: false,
        }}
        onChange={handleTableChange}
        loading={loadingOfficialStores || loadingBrands || loadingCollaborator}
      />

      <Modal
        visible={createCollaboratorModalIsVisible}
        footer={null}
        onCancel={() => {
          setCreateCollaboratorModalIsVisible(false);
          setCurrentCollaborator({} as Collaborator);
        }}
        destroyOnClose={true}
        className="collaborators-modal"
      >
        <CollaboratorsForm
          onSubmit={handleSaveCollaborator}
          modal={setCreateCollaboratorModalIsVisible}
          collaboratorExists={currentCollaborator}
          setCurrentCollaborator={currentCollaborator}
        />
      </Modal>

      <FullScreenModal
        destroyOnClose={true}
        visible={officialStoreModalIsVisible}
        onOk={onCloseOfficialStoreModal}
        onCancel={onCloseOfficialStoreModal}
        footer={null}
        forceRender={false}
        bodyStyle={{ width: '100%' }}
      >
        <OfficialStoreForm collaboratorId={currentCollaborator.collaboratorId} />
      </FullScreenModal>

      <FullScreenModal
        destroyOnClose={true}
        visible={brandModalIsVisible}
        onOk={onCloseBrandModal}
        onCancel={onCloseBrandModal}
        footer={null}
        forceRender={false}
        bodyStyle={{ width: '100%' }}
      >
        <BrandForm collaboratorId={currentCollaborator.collaboratorId} />
      </FullScreenModal>
    </div>
  );
};

export default CollaboratorsPage;
