import { Steps, Typography } from 'antd';
import React, { useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { useBrand } from '@hooks/BrandContext';
import { useGrid } from '@hooks/GridContext';
import { useRepresentative } from '@hooks/RepresentativeContext';
import { Representative } from '@models/Representative';
import { RepresentativePhone, SharedData } from './models/representative-form-models';
import RepresentativeFormEmailStep from './steps/RepresentativeFormEmailStep';
import RepresentativeFormPhoneStep from './steps/RepresentativeFormPhoneStep';
import RepresentativeFormStep from './steps/RepresentativeFormStep';
import { Container, StepsContainer } from './styles';

const { Title, Text } = Typography;
const { Step } = Steps;

const steps = [
  {
    title: 'Representante',
    content: () => null,
  },
  {
    title: 'E-mail',
    content: () => null,
  },
  {
    title: 'Telefone',
    content: () => null,
  },
];

interface RepresentativeFormProps {
  onCloseModal: Function;
  isEditing: boolean;
  representativeToEdit?: Representative;
}

const RepresentativeForm: React.FC<RepresentativeFormProps> = ({ onCloseModal, isEditing, representativeToEdit }) => {
  const { loadGrids } = useGrid();
  const { loadBrands } = useBrand();
  const { createRepresentative } = useRepresentative();
  const [currentStep, setCurrentStep] = useState(0);

  const [loading, setLoading] = useState<boolean>(false);

  const [representativeFormValues, setRepresentativeFormValues] = React.useState<Representative | any>();
  const [emailsFormValues, setEmailsFormValues] = React.useState<any>([]);
  const [phonesFormValues, setPhonesFormValues] = React.useState<any>([]);
  const [sharedData, setSharedData] = React.useState<SharedData | any>();

  React.useEffect(() => {
    loadGrids(0);
    loadBrands();

    if (isEditing) {
      setRepresentativeFormValues(representativeToEdit);
      setEmailsFormValues(representativeToEdit?.emails);
      setPhonesFormValues(representativeToEdit?.phones);
    }
  }, []);

  const goToNextStep = () => {
    if (currentStep === 2) {
      hadleSubmit();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const goToBackStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const changeSharedData = (value: any) => {
    setSharedData({ ...sharedData, ...value });
  };

  const hadleSubmit = async () => {
    setLoading(true);

    const representativeBody = {
      integrationId: Number(localStorage.getItem('@modavest/integration_id')),
      ...representativeFormValues,
      cpfCnpj: representativeFormValues?.cpfCnpj?.replace(/\D/g, ''),
      emails: emailsFormValues?.length > 0 ? emailsFormValues : [],
      phones:
        phonesFormValues?.length > 0
          ? phonesFormValues.map((phone: RepresentativePhone) => {
              return {
                ...phone,
                number: phone?.number?.replace(/\D/g, ''),
                branchLine: +phone?.branchLine,
              };
            })
          : [],
      ...(isEditing && { code: representativeToEdit?.code }),
    };

    try {
      await createRepresentative(representativeBody);
      onCloseModal();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '50px',
        width: '100%',
      }}
    >
      <StepsContainer progressDot current={currentStep}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </StepsContainer>

      <div className="steps-content">{steps[currentStep].content}</div>

      <div className="steps-action d-flex" style={{ width: '100%' }}>
        <Container
          style={{
            background: '#fff',
            padding: '100px',
            paddingTop: '110px',
            borderRadius: '8px',
            width: '100%',
          }}
        >
          {loading && (
            <div style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <LoadingOutlined
                type="play-circle-o"
                style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
              />
            </div>
          )}

          {!loading && (
            <>
              {isEditing && (
                <>
                  <RepresentativeFormStep
                    sharedData={sharedData}
                    changeSharedData={changeSharedData}
                    formValues={representativeFormValues}
                    setFormValues={setRepresentativeFormValues}
                    goToNextStep={goToNextStep}
                    isEditing={isEditing}
                    render={currentStep === 0}
                  />

                  <RepresentativeFormEmailStep
                    sharedData={sharedData}
                    changeSharedData={changeSharedData}
                    formValues={emailsFormValues}
                    setFormValues={setEmailsFormValues}
                    goToNextStep={goToNextStep}
                    goToBackStep={goToBackStep}
                    isEditing={isEditing}
                    render={currentStep === 1}
                  />

                  <RepresentativeFormPhoneStep
                    sharedData={sharedData}
                    formValues={phonesFormValues}
                    setFormValues={setPhonesFormValues}
                    goToNextStep={goToNextStep}
                    goToBackStep={goToBackStep}
                    isEditing={isEditing}
                    render={currentStep === 2}
                  />
                </>
              )}

              {!isEditing && (
                <>
                  {currentStep === 0 ? (
                    <RepresentativeFormStep
                      sharedData={sharedData}
                      changeSharedData={changeSharedData}
                      formValues={representativeFormValues}
                      setFormValues={setRepresentativeFormValues}
                      goToNextStep={goToNextStep}
                      isEditing={isEditing}
                      render={true}
                    />
                  ) : null}

                  {currentStep === 1 ? (
                    <RepresentativeFormEmailStep
                      sharedData={sharedData}
                      changeSharedData={changeSharedData}
                      formValues={emailsFormValues}
                      setFormValues={setEmailsFormValues}
                      goToNextStep={goToNextStep}
                      goToBackStep={goToBackStep}
                      isEditing={isEditing}
                      render={true}
                    />
                  ) : null}

                  {currentStep === 2 ? (
                    <RepresentativeFormPhoneStep
                      sharedData={sharedData}
                      formValues={phonesFormValues}
                      setFormValues={setPhonesFormValues}
                      goToNextStep={goToNextStep}
                      goToBackStep={goToBackStep}
                      isEditing={isEditing}
                      render={true}
                    />
                  ) : null}
                </>
              )}
            </>
          )}
        </Container>
      </div>
    </div>
  );
};

export default RepresentativeForm;
