import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { Grid } from '@models/Grid';
import * as GridService from '@services/grid';
import { INITIAL_PAGE } from '@utils/constants';
import { message } from 'antd';

interface GridState {
  grids: Grid[];
  allGrids: Grid[];
  gridFilters: any;
  loading: boolean;
  loadGrids: Function;
  createGrid: Function;
  updateSpecificProperty: Function;
  filterGrid: Function;
  resetFilterGridDetails: Function;
}

interface GridProviderProps {}

export const GridContext = createContext<GridState | any>({});

export type FilterGridTypes = {
  gradeName: string;
};

const GridProvider: React.FC<GridProviderProps> = ({ children }) => {
  const [grids, setGrids] = useState<any[]>([]);
  const [allGrids, setAllGrids] = useState<any[]>([]);
  const [gridFilters, setGridFilters] = useState<any>({} as any);

  const [gridCurrentPage, setGridCurrentPage] = useState<number>(INITIAL_PAGE);
  const [gridsTotal, setGridsTotal] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);

  const loadGrids = useCallback(
    async (page: number, filters?: FilterGridTypes) => {
      setLoading(true);
      setGridCurrentPage(page === 0 ? 1 : page);
      const integrationId: number = Number(localStorage.getItem('@modavest/integration_id')) || 1;

      try {
        const { data: _grids } = await GridService.get(integrationId, {
          page: page ? page - 1 : 0,
          pageSize: 10,
          ...(filters && { ...filters }),
        });
        setGrids(_grids.data);
        setGridsTotal(_grids.total);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [grids],
  );

  const loadAllGrids = useMemo(
    () => async (page: number, filters?: FilterGridTypes) => {
      setGridCurrentPage(page === 0 ? 1 : page);
      const integrationId: number = Number(localStorage.getItem('@modavest/integration_id')) || 1;
      try {
        const { data: _grids } = await GridService.get(integrationId, {
          page: page ? page - 1 : 0,
          pageSize: 10,
          listAll: true,
          ...(filters && { ...filters }),
        });
        setAllGrids(_grids.data);
      } catch (error) {
        console.error(error);
      }
    },
    [allGrids],
  );

  const filterGrid = async (filters: Partial<FilterGridTypes>) => {
    if (!Object.keys(filters).length) return loadGrids(1);
    setLoading(true);
    if (filters) setGridFilters(filters);
    else setGridFilters({});
    const integrationId: number = Number(localStorage.getItem('@modavest/integration_id')) || 1;
    try {
      const { data: _grids } = await GridService.get(integrationId, {
        page: 0,
        pageSize: 10,
        ...(filters && { ...filters }),
      });
      setGrids(_grids.data);
      setGridsTotal(_grids.total);
    } catch {
      message.error('Erro ao filtrar grades');
    } finally {
      setLoading(false);
    }
  };

  const createGrid = useCallback(
    async (newGrid: Partial<Grid>) => {
      setLoading(true);
      try {
        const { data: _newGrid } = await GridService.saveGrid(newGrid);

        setGrids([...grids, _newGrid]);

        await loadGrids(0);
        return _newGrid;
      } catch (error) {
        console.log('error at grid context', error);
        return;
      } finally {
        setLoading(false);
      }
    },
    [grids],
  );

  const updateSpecificProperty = useCallback(
    async (gridId: number, body: { property: string; value: string }) => {
      setLoading(true);

      try {
        const { data: _gridUpdated } = await GridService.updateSpecificProperty(gridId, body);

        const _grids = grids;
        const index = _grids.findIndex((grid) => grid.gridId === gridId);
        _grids[index] = _gridUpdated;

        setGrids(_grids);

        await loadGrids(0);
      } catch (error) {
        console.log('error at grid context', error);
      } finally {
        setLoading(false);
      }
    },
    [grids],
  );

  return (
    <GridContext.Provider
      value={{
        grids,
        gridFilters,
        loading,
        gridCurrentPage,
        gridsTotal,
        allGrids,
        loadGrids,
        createGrid,
        updateSpecificProperty,
        filterGrid,
        loadAllGrids,
      }}
    >
      {children}
    </GridContext.Provider>
  );
};

const useGrid = () => {
  return useContext(GridContext);
};

export { GridProvider, useGrid };
