import { Devolution } from '@models/Devolution';
import moment from 'moment';
import ActionsStatusColumn from './ActionsStatusColumn';
import DevolutionStatusColumn from './DevolutionStatusColumn';

export const devolutionColumns: any = [
  {
    title: 'Solicitação de devolução',
    dataIndex: 'sequence',
    align: 'left' as 'left',
    sorter: true,
  },
  {
    title: 'Nº da nota fiscal',
    dataIndex: 'invoiceCode',
    align: 'left' as 'left',
    sorter: true,
  },
  {
    title: 'Razão social',
    align: 'left' as 'left',
    render: (devolution: Devolution) => <span>{devolution?.invoice?.person?.personName || ''}</span>,
  },
  {
    title: 'CNPJ',
    dataIndex: 'cnpj',
    align: 'left' as 'left',
    render: (cnpj: string) => (
      <p style={{ width: '150px', margin: 0 }}>
        {cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5')}
      </p>
    ),
    sorter: true,
  },
  {
    title: 'Data de solicitação',
    dataIndex: 'createdAt',
    align: 'left' as 'left',
    render: (text: string) => <span>{moment.utc(text).format('DD/MM/YYYY')}</span>,
    sorter: true,
  },
  {
    title: 'Data da última movimentação',
    align: 'left' as 'left',
    render: (devolution: Devolution) => (
      <span>
        {devolution?.observations?.length > 0
          ? moment.utc(devolution?.observations[devolution?.observations?.length - 1]?.createdAt).format('DD/MM/YYYY')
          : moment.utc(devolution?.createdAt).format('DD/MM/YYYY')}
      </span>
    ),
  },
  {
    title: 'Qtde. de devolução',
    dataIndex: 'totalItemDevolutionQuantity',
    align: 'left' as 'left',
    render: (totalItemDevolutionQuantity: number) => <span>{totalItemDevolutionQuantity}</span>,
    sorter: true,
  },
  {
    title: 'Status',
    align: 'center' as 'center',
    render: (devolution: Devolution) => <DevolutionStatusColumn devolution={devolution} />,
  },
  {
    title: 'Ações',
    align: 'center' as 'center',
    render: (devolution: Devolution) => <ActionsStatusColumn devolution={devolution} />,
  },
];
