import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 75vh;
  padding: 20px;

  .empty-component {
    padding: 40px 20px;
    background: white;
    border-radius: 8px;
  }
`;

export const Panel = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 8px;
  padding: 12px;
`;

export const ScrollContainer = styled.div`
  height: 100%;
  max-height: 65vh;
  overflow-y: scroll;
  padding: 8px;

  &::-webkit-scrollbar {
    width: 8px;
    background: #f5f5f5;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-appearance: none;
    background: var(--primary-color);
    border-radius: 8px;
  }
`;
