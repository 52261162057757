import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import * as CategoryService from '@services/category';
import * as SubcategoryService from '@services/subcategory';
import { Category } from '@models/Category';
import { Classification } from '@models/Classification';
import { message } from 'antd';

interface CategoryState {
  loading: boolean;
  loadingSubcategories: boolean;
  categories: Category[];
  loadRequestCategory: Function;
  loadRequestSubcategory: Function;
  addClassificationToSubcategory: Function;
  deleteClassificationFromSubcategory: Function;
}

interface CategoryProviderProps {
  children: ReactNode;
}

export const CategoryContext = createContext<CategoryState>({} as CategoryState);

const CategoryProvider: React.FC<CategoryProviderProps> = ({ children }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSubcategories, setLoadingSubcategories] = useState<boolean>(false);


  const loadRequestCategory = useCallback(async () => {
    if (!categories || categories.length == 0) {
      setLoading(true);
      const { data: _categories } = await CategoryService.get();
      setLoading(false);
      setCategories(_categories);
    }
  }, [categories]);

  async function loadRequestSubcategory(categoryIds: string[]) {
    setLoadingSubcategories(true)
    if (categoryIds && categoryIds.length > 0) {
      for (const categoryId of categoryIds) {
        const categoryIdNumber = parseInt(categoryId);
        const category = categories.filter((_category) => {
          return _category.categoryId == categoryIdNumber;
        })[0];
        if (!category?.subcategories) {
          const { data: subcategories } = await SubcategoryService.get(categoryIdNumber);
          category.subcategories = subcategories;
        }
        setCategories(
          categories.map((c) => {
            return c.categoryId == categoryIdNumber ? category : c;
          }),
        );
      }
    }
    setLoadingSubcategories(false)
  }

  const addClassificationToSubcategory = useCallback(
    async (categoryId: number, subCategoryId: number, classification: Partial<Classification>) => {
      try {
        const categoryIndex = categories.findIndex((_category) => _category.categoryId === categoryId);

        const subCategoryIndex = categories[categoryIndex].subcategories.findIndex(
          (_subCategory) => _subCategory.subCategoryId === subCategoryId,
        );

        const found = categories[categoryIndex].subcategories[subCategoryIndex].classifications.find(
          (localClassification) =>
            localClassification.code === classification.code &&
            localClassification.typeCode === classification.typeCode,
        );

        if (found) return message.warning('Classificação já existe para a SubCategoria!');

        const { data: _classification } = await SubcategoryService.addClassification(subCategoryId, classification);

        if (_classification) {
          categories[categoryIndex].subcategories[subCategoryIndex].classifications = [
            ...categories[categoryIndex].subcategories[subCategoryIndex].classifications,
            _classification,
          ];

          setCategories([...categories]);
          message.success('Classificação adicionada com sucesso!');
        }
      } catch (e) {
        message.warning('Erro ao adicionar classificação!');
      }
    },
    [categories, setCategories],
  );

  const deleteClassificationFromSubcategory = useCallback(
    async (categoryId: number, subCategoryId: number, classification: Partial<Classification>) => {
      try {
        const categoryIndex = categories.findIndex((_category) => _category.categoryId === categoryId);

        const subCategoryIndex = categories[categoryIndex].subcategories.findIndex(
          (_subCategory) => _subCategory.subCategoryId === subCategoryId,
        );

        await SubcategoryService.deleteClassification(subCategoryId, classification);

        const classificationIndex = categories[categoryIndex].subcategories[subCategoryIndex].classifications.findIndex(
          (localClassification) =>
            localClassification.typeCode === classification.typeCode &&
            localClassification.code === classification.code,
        );

        categories[categoryIndex].subcategories[subCategoryIndex].classifications.splice(classificationIndex, 1);

        setCategories([...categories]);
        message.success('Classificação deletada com sucesso!');
      } catch (e) {
        message.warning('Erro ao deletar classificação!');
      }
    },

    [categories, setCategories],
  );

  return (
    <CategoryContext.Provider
      value={{
        loading,
        loadingSubcategories,
        categories,
        loadRequestCategory,
        loadRequestSubcategory,
        addClassificationToSubcategory,
        deleteClassificationFromSubcategory,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

const useCategory = () => {
  return useContext(CategoryContext);
};

export { CategoryProvider, useCategory };
