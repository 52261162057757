import { Table } from 'antd';

import { PriceTablePaymentCondition } from '@models/PriceTablePaymentCondition';

interface TabPaymentConditionProps {
  paymentConditions: PriceTablePaymentCondition[];
}

const TabPaymentCondition: React.FC<TabPaymentConditionProps> = ({ paymentConditions }) => {
  const columns = [
    {
      title: 'Código',
      dataIndex: 'code',
      width: '10%',
      align: 'center' as 'center',
    },
    {
      title: 'Condição de Pagamento',
      dataIndex: 'name',
    },
    {
      title: 'Prazo Médio',
      align: 'right' as 'right',
      render: (value: any) => {
        const avg =
          (value.installmentDays &&
            value.installmentDays.map(Number).reduce(function (a: number, b: number) {
              return a + b;
            }) / value.installmentDays.length) ||
          0;
        return <strong>{avg} dias</strong>;
      },
    },
  ];

  return (
    <Table
    scroll={{ x: 800 }}
    columns={columns} dataSource={paymentConditions} pagination={{ pageSize: 4, hideOnSinglePage: true }} />
  );
};

export default TabPaymentCondition;
