import { CopyOutlined, MailOutlined } from '@ant-design/icons';
import { useManufacturer } from '@hooks/ManufacturerContext';
import { Order } from '@models/Order';
import { IntegrationPermissionEnum } from '@models/enum/IntegrationPermission';
import * as customerService from '@services/customer';
import * as lambdaService from '@services/lambda';
import * as orderService from '@services/order';
import { Button, Modal, notification } from 'antd';
import copy from 'copy-to-clipboard';
import { useState } from 'react';

interface ActionColumnProps {
  order: Order;
}

interface LegalPersonEmail {
  integrationId: number;
  typeCode: number;
  legalPersonCode: number;
  typeName: string;
  email: string;
  isDefault: boolean;
}

const ActionColumn: React.FC<ActionColumnProps> = ({ order: { orderCode, integrationId, customerCode } }) => {
  const { permissions } = useManufacturer();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loadingEmail, setLoadingEmail] = useState<boolean>(false);
  const [loadingUrl, setLoadingUrl] = useState<boolean>(false);
  const [publicPageWithPhotosUrl, setPublicPageWithPhotosUrl] = useState<string>('');
  const [orderCodeEncrypted, setOrderCodeEncrypted] = useState<string>('');

  const copyToClipboard = (textToCopy: string): void => {
    notification.success({ message: 'URL copiada', description: 'A URL foi copiada para área de transferência' });
    copy(textToCopy);
  };

  const generateUrl = (orderCodeEncrypted: string): string =>
    `${process.env.REACT_APP_URL_APP}/pedido/imagens?o=${orderCodeEncrypted}&i=${integrationId}`;

  const getUrl = async (): Promise<void> => {
    try {
      setLoadingUrl(true);
      const {
        data: { orderCode: orderCodeEncrypted },
      } = await orderService.encryptOrderCode(integrationId, orderCode);
      setPublicPageWithPhotosUrl(generateUrl(orderCodeEncrypted));
      setOrderCodeEncrypted(orderCodeEncrypted);
    } catch (e) {
      console.error('Algo deu errado gerar url', e);
      notification.error({ message: 'Algo deu errado' });
    } finally {
      setLoadingUrl(false);
    }
  };

  const verifyIfOrderHasPhotos = (references: orderService.ImageByOrderCodeEncriptedResponseType[]): boolean => {
    return references.some((reference) => reference.urls.length > 0);
  };

  const sendEmail = async () => {
    try {
      setLoadingEmail(true);
      const [
        {
          data: { emails = [] },
        },
        {
          data: { references },
        },
      ] = await Promise.all([
        customerService.getCustomerByCode(customerCode),
        orderService.getImagesByOrderCodeEncripted(orderCodeEncrypted, integrationId),
      ]);

      const legalPersonEmailDefault = emails?.find((email: LegalPersonEmail) => email?.isDefault)?.email;
      if (!legalPersonEmailDefault) {
        notification.warn({ message: 'Pessoa jurídica sem email padrão!', description: 'O email não será enviado' });
        return;
      }

      if (!verifyIfOrderHasPhotos(references)) {
        notification.warn({
          message: 'Pedido não tem nenhuma referência com foto',
          description: 'O email não será enviado',
        });
        return;
      }
      await lambdaService.sendEmailWithZipPhotosOfOrder({
        order_code: orderCode.toString(),
        integration_id: integrationId.toString(),
        email: legalPersonEmailDefault,
      });
      notification.success({ message: 'Email enviado!', description: 'Enviamos um link para baixar as fotos' });
    } catch (e) {
      console.error('Algo deu errado ao enviar link para o email', e);
      notification.error({ message: 'Algo deu errado' });
    } finally {
      setLoadingEmail(false);
      setShowModal(false);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleCopy = () => {
    copyToClipboard(publicPageWithPhotosUrl);
    setShowModal(false);
  };

  return (
    <>
      {permissions?.includes(IntegrationPermissionEnum.EMAIL) && (
        <Button
          title="Enviar fotos por email"
          type="link"
          icon={<MailOutlined />}
          onClick={() => {
            setShowModal(true);
            getUrl();
          }}
        >
          Enviar fotos
        </Button>
      )}
      <Modal
        title="Deseja enviar email com as fotos?"
        visible={showModal}
        centered={true}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={handleCopy} loading={loadingUrl} icon={<CopyOutlined />}>
            Apenas copiar URL
          </Button>,
          <Button key="cancel" onClick={handleCancel} loading={loadingEmail}>
            Cancelar
          </Button>,
          <Button key="sendEmail" type="primary" onClick={sendEmail} loading={loadingEmail}>
            Enviar email
          </Button>,
        ]}
        onOk={() => {
          setShowModal(false);
        }}
        onCancel={handleCancel}
      >
        Será enviado um email com um link para baixar se as referências tiverem fotos no sistema, deseja enviar?
      </Modal>
    </>
  );
};

export default ActionColumn;
