import Loading from '@components/Loading';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import OfficialStoreCollectionsContent from './components/OfficialStoreCollectionsContent';
import OfficialStoreCollectionsHeader from './components/OfficialStoreCollectionsHeader';

interface MatchParams {
  id: string;
}

const OfficialStoreCollectionsPage: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();
  const { actualOfficialStore, findOfficialStoreById, loading } = useOfficialStore();

  useEffect(() => {
    findOfficialStoreById(parseInt(params.id));
  }, []);

  if (loading) return <Loading />;

  return (
    <>
      <OfficialStoreCollectionsHeader officialStore={actualOfficialStore} />
      <OfficialStoreCollectionsContent officialStore={actualOfficialStore} />
    </>
  );
};

export default OfficialStoreCollectionsPage;
