import { LeftOutlined, MenuOutlined } from '@ant-design/icons';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
import { Col, Drawer, Image, Row, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import Navigation from '../../../../components/OfficialStoreNavigation';

const { Text, Title } = Typography;

interface OfficialStoreStockHeaderProps {}

const OfficialStoreStockHeader: React.FC<OfficialStoreStockHeaderProps> = () => {
  const history = useHistory();
  const { actualOfficialStore } = useOfficialStore();
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);

  const backUrl = useCallback(() => {
    history.go(-1);
  }, [history]);

  const onCloseDrawer = useCallback(() => {
    setDrawerIsVisible(false);
  }, [setDrawerIsVisible]);

  const showDrawer = useCallback(() => {
    setDrawerIsVisible(true);
  }, [setDrawerIsVisible]);

  return (
    <>
      <Row className="mt-3" justify="space-between">
        <Col className="d-flex" style={{ alignItems: 'center' }}>
          <LeftOutlined onClick={() => backUrl()} style={{ fontSize: '20px', marginRight: '12px' }} />

          <Image
            src={actualOfficialStore.logoUrl}
            alt="Logo da empresa"
            preview={false}
            style={{ width: '100%', maxWidth: '120px', borderRadius: '8px' }}
          />

          <Row style={{ flexDirection: 'column', marginLeft: '12px' }}>
            <Col>
              <Title level={5} style={{ margin: '0' }}>
                {actualOfficialStore.description}
              </Title>
            </Col>
            <Col>
              <Text>#{actualOfficialStore.officialStoreId}</Text>
            </Col>
          </Row>
        </Col>

        <Col className="d-flex" style={{ alignItems: 'center' }}>
          <Row className="d-flex" style={{ alignItems: 'center' }}>
            <MenuOutlined
              onClick={showDrawer}
              style={{ fontSize: '22px', color: 'var(--primary-color)', cursor: 'pointer' }}
            />
          </Row>

          <Drawer
            placement="right"
            onClose={onCloseDrawer}
            visible={drawerIsVisible}
            closable={false}
            bodyStyle={{ padding: '0' }}
          >
            <Navigation officialStore={actualOfficialStore} />
          </Drawer>
        </Col>
      </Row>
    </>
  );
};

export default OfficialStoreStockHeader;
