import { notification } from 'antd';

export function beforeUploadFileSheet(file: File) {
  const validFileTypes = ['text/csv'];
  const isCsv = validFileTypes.includes(file.type);

  if (!isCsv) {
    notification.error({ message: 'Formato inválido', description: 'Apenas arquivos CSV serão aceitos!' });
  }

  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    notification.error({ message: 'Arquivo muito grande', description: 'O arquivo deve ser menor que 10MB!' });
  }

  const isValid = isCsv && isLt2M;

  return isValid;
}
