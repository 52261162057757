import React from 'react';
import { Col, Row, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const { Title } = Typography;

interface LayoutHeaderProps {
  title: string;
  onReturn?: Function;
  extraComponent?: React.ReactNode;
  titleLevel?: 1 | 2 | 3 | 4 | 5;
}

const LayoutHeader: React.FC<LayoutHeaderProps> = ({ title, onReturn, extraComponent, titleLevel }) => {
  return (
    <Row className="mt-8" justify="space-between">
      <Col className="d-flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
        {onReturn && <LeftOutlined onClick={() => onReturn()} style={{ fontSize: '20px', marginRight: '12px' }} />}
        <Title className="mb-0" level={titleLevel ?? 2}>
          {title}
        </Title>
      </Col>
      <Col>{extraComponent}</Col>
    </Row>
  );
};

export default LayoutHeader;
