import { useOfficialStore } from '@hooks/OfficialStoreContext';
import { useOfficialStoreStock } from '@hooks/OfficialStoreStockContext';
import { LiquidityBufferAppEnum } from '@models/enum/LiquidityBufferApp';
import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import OfficialStoreStockContent from './components/OfficialStoreStockContent';
import OfficialStoreStockHeader from './components/OfficialStoreStockHeader';

interface OfficialStoreStockPageProps {}

interface MatchParams {
  id: string;
}

const OfficialStoreStockPage: React.FC<OfficialStoreStockPageProps> = () => {
  const { params } = useRouteMatch<MatchParams>();
  const { findOfficialStoreById } = useOfficialStore();
  const { loadStocks } = useOfficialStoreStock();

  useEffect(() => {
    findOfficialStoreById(parseInt(params.id));
    loadStocks(parseInt(params.id), LiquidityBufferAppEnum.APP_REPRESENTATIVE);
    loadStocks(parseInt(params.id), LiquidityBufferAppEnum.APP_SELLER);
    loadStocks(parseInt(params.id), LiquidityBufferAppEnum.APP_SHOPKEEPER);
  }, []);

  return (
    <>
      <OfficialStoreStockHeader />
      <OfficialStoreStockContent />
    </>
  );
};

export default OfficialStoreStockPage;
