import React, { useEffect } from 'react';

import { usePriceTable } from '@hooks/PriceTableContext';
import PriceTablesContent from './components/PriceTablesContent';
import PriceTablesHeader from './components/PriceTablesHeader';
import Loading from '@components/Loading';

const PriceTablesPage: React.FC = () => {
  const { loading, loadPriceTables } = usePriceTable();

  useEffect(() => {
    loadPriceTables(0);
  }, []);

  return (
    <>
      <PriceTablesHeader />
      <PriceTablesContent />
    </>
  );
};

export default PriceTablesPage;
