import LoadingComponents from '@components/LoadingComponents';
import ReferencesContentTable from '@components/ReferencesContentTable';
import { useReference } from '@hooks/ReferenceContext';
import { Reference } from '@models/Reference';
import { REFERENCE_PAGINATION_SIZE } from '@utils/constants';
import { getImageOrAvatar } from '@utils/get-image-or-avatar';
import { Table, Typography } from 'antd';
import React, { useCallback, useState } from 'react';

const { Text } = Typography;

interface CollectionDetailsContentProps {
  collectionId: number;
}
const CollectionDetailsContent: React.FC<CollectionDetailsContentProps> = ({ collectionId }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
  const {
    references,
    referencesTotalPages,
    referencesCurrentPage,
    loadReferences,
    loadingReferences,
    loadReferenceProducts,
  } = useReference();

  const columns = [
    {
      title: 'Referência',
      render: (reference: Reference): JSX.Element => (
        <div>
          {getImageOrAvatar(reference.photoUrl, reference.name)}
          <Text className="ml-3">{reference.ReferenceCode}</Text>
        </div>
      ),
      sorter: (product: Reference, nextProduct: Reference) =>
        product.ReferenceCode.localeCompare(nextProduct.ReferenceCode),
      ellipsis: true,
    },
    {
      title: 'Descrição',
      dataIndex: 'name',
      sorter: (product: Reference, nextProduct: Reference) => product.name.localeCompare(nextProduct.name),
      ellipsis: true,
    },
    {
      title: 'Grade/Pack',
      align: 'center' as 'center',
      width: '120px',
      render: (_reference: Reference): JSX.Element => <Text>{_reference.isPack ? 'Pack' : 'Grade'}</Text>,
    },
    {
      title: 'Tamanhos',
      dataIndex: 'grid',
      render: (sizes: string[]): JSX.Element => (
        <Text>{sizes.map((size: string, i: number) => (i === 0 ? size : ' | ' + size))}</Text>
      ),
      ellipsis: true,
    },
    {
      title: 'Qtde. de Itens',
      dataIndex: 'itemQty',
      align: 'right' as 'right',
    },
  ];

  const onTableRowExpand = (expanded: boolean, reference: Reference) => {
    const keys = [];

    if (expanded) {
      keys.push(reference.ReferenceCode);
    }

    setExpandedRowKeys(keys);

    if (expanded) return loadReferenceProducts(reference.ReferenceCode);
  };

  const handlePaginationChange = useCallback(
    (page: number) => {
      loadReferences(page, true, { collectionId });
    },
    [loadReferences, collectionId],
  );

  return (
    <Table
      scroll={{ x: 800 }}
      className="table-products mt-6"
      columns={columns}
      dataSource={references}
      rowKey={(record) => record.ReferenceCode}
      expandable={{
        expandedRowRender: () => <ReferencesContentTable />,
        onExpand: onTableRowExpand,
      }}
      expandedRowKeys={expandedRowKeys}
      pagination={{
        total: referencesTotalPages,
        current: referencesCurrentPage,
        pageSize: REFERENCE_PAGINATION_SIZE,
        showSizeChanger: false,
        onChange: handlePaginationChange,
      }}
      loading={loadingReferences}
    />
  );
};

export default CollectionDetailsContent;
