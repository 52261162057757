import { Order } from '@models/Order';
import { Typography } from 'antd';
import moment from 'moment';
import ActionColumn from './components/ActionColumn';

const { Text } = Typography;

export const columns: any = [
  {
    title: 'Código',
    dataIndex: 'orderCode',
    sorter: true,
  },
  {
    title: 'Lojista',
    dataIndex: 'customerName',
    sorter: true,
  },
  {
    title: 'Valor Total',
    dataIndex: 'totalAmountOrder',
    align: 'right' as 'right',
    render: (text: string) => <Text>R$ {Number(text).toFixed(2).toString().replace('.', ',')}</Text>,
    sorter: true,
  },
  {
    title: 'Data',
    dataIndex: 'orderDate',
    render: (text: string) => <Text>{moment.utc(text).format('DD/MM/YYYY')}</Text>,
    sorter: true,
  },
  {
    title: 'Ações',
    render: (order: Order) => <ActionColumn order={order} />,
  },
];
