import { ExclamationCircleOutlined } from '@ant-design/icons';
import Loading from '@components/Loading';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
import { Form, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import OfficialStoreProfileContent from './components/OfficialStoreProfileContent';
import OfficialStoreProfileHeader from './components/OfficialStoreProfileHeader';

interface MatchParams {
  id: string;
}

interface IUpdateForm {
  description: string;
  isDeliveryImmediately: boolean;
  isMakeToOrder: boolean;
  isVisibleCustomer: boolean;
  isVisibleRepresentative: boolean;
  isVisibleSeller: boolean;
  orderCoverUrl: string;
  orderPreviewCoverUrl: string;
}

const OfficialStoreProfilePage: React.FC = () => {
  const [form] = Form.useForm<IUpdateForm>();
  const { params } = useRouteMatch<MatchParams>();
  const history = useHistory();
  const { actualOfficialStore, updateOfficialStore, findOfficialStoreById, loading } = useOfficialStore();

  const [logoUrl, setLogoUrl] = useState<string>('');

  useEffect(() => {
    findOfficialStoreById(parseInt(params.id));
  }, []);

  useEffect(() => {
    if (!actualOfficialStore) return;

    form.setFieldsValue(actualOfficialStore);
  }, [actualOfficialStore]);

  const onUpdateOfficialStore = async (values: IUpdateForm) => {
    const logo = logoUrl ? logoUrl : actualOfficialStore.logoUrl;

    if (!values.isDeliveryImmediately && !values.isMakeToOrder)
      return message.error('Preencha ao menos um Tipo de Venda!');

    const officialStoreToUpdate = {
      ...form.getFieldsValue([
        'description',
        'isDeliveryImmediately',
        'isMakeToOrder',
        'isVisibleCustomer',
        'isVisibleRepresentative',
        'isVisibleSeller',
        'orderCoverUrl',
        'orderPreviewCoverUrl',
      ]),
      logoUrl: logo,
    };

    const updated = await updateOfficialStore(parseInt(params.id), officialStoreToUpdate);

    if (updated) {
      message.success('Loja atualizada com sucesso!');
      history.go(-1);
    }
  };

  const onSubmit = (values: IUpdateForm) => {
    Modal.confirm({
      title: 'Atualizar Loja Oficial',
      icon: <ExclamationCircleOutlined />,
      content: 'Deseja mesmo prosseguir?',
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => onUpdateOfficialStore(values),
    });
  };

  if (loading) return <Loading />;

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <OfficialStoreProfileHeader officialStore={actualOfficialStore} />
      <OfficialStoreProfileContent
        officialStore={actualOfficialStore}
        logoUrl={logoUrl}
        setLogoUrl={setLogoUrl}
        form={form}
      />
    </Form>
  );
};

export default OfficialStoreProfilePage;
