import React, { useCallback, useState } from 'react';

import { Row, Steps } from 'antd';

import * as S from './styles';
import QuestionTypeStep from '../QuestionTypeStep';
import QuestionDescriptionStep from '../QuestionDescriptionStep';
import QuestionClassificationStep from '../QuestionClassificationStep';

interface QuestionFormProps {
  editingQuestion?: any;
}

const steps = [
  {
    title: 'Tipo',
  },
  {
    title: 'Descrição',
  },
  {
    title: 'Opções',
  },
];

const QuestionForm: React.FC<QuestionFormProps> = ({ editingQuestion }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [question, setQuestion] = useState<any>();

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleAddQuestionField = useCallback(
    (values: any) => {
      setQuestion({ ...question, ...values });
    },
    [question],
  );

  return (
    <>
      <S.StepsContainer progressDot current={currentStep} className="mb-8">
        {steps.map((item) => (
          <Steps.Step key={item.title} title={item.title} />
        ))}
      </S.StepsContainer>

      <Row className='w-100 d-flex' gutter={[16, 16]} style={{alignItems: 'center', justifyContent: 'center'}}>
        {currentStep === 0 && (
          <QuestionTypeStep
            handleAddQuestionField={handleAddQuestionField}
            nextStep={nextStep}
            editingQuestion={editingQuestion}
          />
        )}
        {currentStep === 1 && (
          <QuestionDescriptionStep
            handleAddQuestionField={handleAddQuestionField}
            nextStep={nextStep}
            editingQuestion={editingQuestion}
          />
        )}
        {currentStep === 2 && (
          <QuestionClassificationStep previousStepsValues={question} editingQuestion={editingQuestion} />
        )}
      </Row>
    </>
  );
};

export default QuestionForm;
