import React from 'react';
import { Form, Input } from 'antd';
import { CustomButton } from '@styles/globals';
import { useCustomer } from '@hooks/CustomerContext';

interface FiltersFormProps {
  onCloseDrawer: Function;
}

const FiltersForm: React.FC<FiltersFormProps> = ({ onCloseDrawer }) => {
  const [form] = Form.useForm();

  const { loadCustomersPaginated, customerFilters, customerStatus } = useCustomer();

  const clearFilters = () => {
    form.resetFields();
    loadCustomersPaginated(0, customerStatus, customerFilters);
  };

  const handleSubmit = (values: any) => {
    loadCustomersPaginated(0, customerStatus, { searchByName: values.searchByName });

    onCloseDrawer();
  };

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit}>
      <Form.Item label="Filtro" name="searchByName">
        <Input placeholder="CNPJ, razão social ou nome fantasia..." />
      </Form.Item>

      <CustomButton className="mt-1 w-100" type="primary" htmlType="submit">
        Filtrar
      </CustomButton>

      <CustomButton className="mt-1 w-100" outlined="true" onClick={() => clearFilters()}>
        Reiniciar Filtros
      </CustomButton>
    </Form>
  );
};

export default FiltersForm;
