import React, { useEffect, useState } from 'react';
import { Table, Typography } from 'antd';

import LoadingComponents from '@components/LoadingComponents';
import DualForm from '@components/DualForm';
import { useCategory } from '@hooks/CategoryContext';
import { FullScreenModal } from '@styles/globals';
import { Subcategory } from '@models/Subcategory';
import ClassificationForm from '@components/ClassificationForm';
import './styles.css';

const { Text } = Typography;

interface SubCategoriesTableProps {
  subcategories: any[];
  categoryId: number;
}
const SubCategoriesTable: React.FC<SubCategoriesTableProps> = ({ categoryId, subcategories }) => {
  const {
    categories,
    loading,
    addClassificationToSubcategory,
    deleteClassificationFromSubcategory,
    loadingSubcategories,
  } = useCategory();
  const [modalClassificationIsVisible, setModalClassificationIsVisible] = useState(false);
  const [actualSubCategory, setActualSubCategory] = useState<Subcategory>({} as Subcategory);

  const onCloseClassificationModal = () => {
    setModalClassificationIsVisible(false);
  };

  const onShowClassificationModal = () => {
    setModalClassificationIsVisible(true);
  };

  const handleClickSubCategory = (subcategory: Subcategory) => {
    onShowClassificationModal();
    setActualSubCategory(subcategory);
  };

  const onAddClassification = (values: any) => {
    if (values.typeCode && values.code)
      addClassificationToSubcategory(categoryId, actualSubCategory.subCategoryId, {
        typeCode: Number(values.typeCode),
        code: values.code,
      });
  };

  const onDeleteClassification = (typeCode: number, code: string) => {
    deleteClassificationFromSubcategory(categoryId, actualSubCategory.subCategoryId, { typeCode, code });
  };

  useEffect(() => {
    adaptClassificationsItemsList();
  }, [categories, actualSubCategory]);

  const [currentClassificationItems, setCurrentClassificationItems] = useState<any[]>([]);

  const adaptClassificationsItemsList = () => {
    const list = actualSubCategory?.classifications?.map((item) => ({
      label1: 'Tipo de Classificação',
      item1: item.typeCode,
      label2: 'Classificação',
      item2: item.code,
    }));

    setCurrentClassificationItems(list);
  };

  const columns = [
    {
      render: (subcategory: any) => (
        <strong onClick={() => handleClickSubCategory(subcategory)}>{subcategory.description.toUpperCase()}</strong>
      ),
    },
  ];

  const columnsSubcategory = [
    {
      title: 'Tipo de Classificação',
      dataIndex: 'typeCode',
    },
    {
      title: 'Classificação',
      dataIndex: 'code',
    },
  ];

  if (loading || loadingSubcategories) return <LoadingComponents />;

  return (
    <>
      <Table
        columns={columns}
        dataSource={subcategories}
        pagination={false}
        showHeader={false}
        scroll={{ x: 800 }}
        rowKey={(subcategory) => subcategory.subCategoryId}
        expandable={{
          expandedRowRender: (subcategory) => (
            <Table
            className='mt-2 mb-0'
              columns={columnsSubcategory}
              dataSource={subcategory.classifications}
              pagination={{ style: { marginTop: '24px', marginBottom: 0 } }}
            />
          ),
        }}
        rowClassName="row-purple"
      />

      <FullScreenModal
        destroyOnClose={true}
        footer={null}
        forceRender={false}
        visible={modalClassificationIsVisible}
        onOk={onCloseClassificationModal}
        onCancel={onCloseClassificationModal}
        bodyStyle={{ width: '100%' }}
      >
        <DualForm
          title="Produtos"
          loading={false}
          form={<ClassificationForm onAdd={onAddClassification} />}
          list={currentClassificationItems}
          onDelete={onDeleteClassification}
        />
      </FullScreenModal>
    </>
  );
};

export default SubCategoriesTable;
