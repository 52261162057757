import React, { useCallback, useEffect, useState } from 'react';
import { Button, Input, message, Table, Tooltip, Typography } from 'antd';
import { FileUnknownOutlined } from '@ant-design/icons';

import { useOrder } from '@hooks/OrdersContext';
import OrderItemsTabs from '../OrderItemsTabs';
import { FullScreenModal } from '@styles/globals';
import { FailedOrder } from '@models/FailedOrder';
import * as moment from 'moment';

const { Title, Text } = Typography;

const NotSentTab: React.FC = () => {
  const {
    notSentPage,
    notSentOrders,
    loadNotSentOrders,
    notSentTotal,
    loadingNotSentOrders,
    notSentFilters,
    PAGINATION_SIZE,
  } = useOrder();

  const [errorModalIsVisible, setErrorModalIsVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleCloseErrorModal = useCallback(() => {
    setErrorModalIsVisible(false);
  }, [setErrorModalIsVisible]);

  const handleShowErrorModal = useCallback(
    (error: string) => {
      setErrorMessage(error);
      setErrorModalIsVisible(true);
    },
    [setErrorModalIsVisible, setErrorMessage],
  );

  const notSentColumns = [
    {
      title: 'ID',
      dataIndex: 'sequence',
      sorter: true,
    },
    {
      title: 'Lojista',
      dataIndex: 'customerName',
      sorter: true,
    },
    {
      title: 'Valor Total',
      dataIndex: 'totalAmountOrder',
      align: 'right' as 'right',
      render: (text: string) => <Text>R$ {Number(text).toFixed(2).toString().replace('.', ',')}</Text>,
      sorter: true,
    },
    {
      title: 'Data',
      dataIndex: 'orderDate',
      render: (text: string) => <Text>{moment.utc(text).format('DD/MM/YYYY')}</Text>,
      sorter: true,
    },
    {
      align: 'right' as 'right',
      title: 'Erro',
      render: (record: FailedOrder) => (
        <Tooltip title="Visualizar Erro" color="#510c76" key="tooltip" placement="topRight">
          <FileUnknownOutlined
            style={{ cursor: 'pointer', fontSize: '20px', fontWeight: 'bolder', color: 'var(--primary-color)' }}
            onClick={() => handleShowErrorModal(record.stacktrace)}
          />
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    loadNotSentOrders(0);
  }, []);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.hasOwnProperty('column')) {
      notSentFilters.field = `${sorter.field}`;
      notSentFilters.order = `${sorter.order}`;
    }

    loadNotSentOrders(pagination.current, notSentFilters);
  };

  const orderItemsTabsComponent = (failedOrder: FailedOrder) => <OrderItemsTabs orderFailed={failedOrder} />;

  return (
    <>
      <Table
        columns={notSentColumns}
        dataSource={notSentOrders}
        pagination={{
          pageSize: PAGINATION_SIZE,
          total: notSentTotal,
          current: notSentPage,
          showSizeChanger: false,
          showQuickJumper: false,
        }}
        onChange={handleTableChange}
        rowKey={(failedOrder) =>
          `${failedOrder.branchCode}---${failedOrder.orderCode}---${failedOrder.orderId}---${failedOrder.sequence}`
        }
        expandable={{
          expandedRowRender: orderItemsTabsComponent,
        }}
        scroll={{ x: 800 }}
        loading={loadingNotSentOrders}
      />

      <FullScreenModal
        destroyOnClose={true}
        visible={errorModalIsVisible}
        onOk={handleCloseErrorModal}
        onCancel={handleCloseErrorModal}
        footer={null}
        forceRender={false}
        bodyStyle={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          className="d-flex"
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'white',
            borderRadius: '8px',
            padding: '28px',
          }}
        >
          <Title level={4}>Mensagem de Erro: </Title>

          <Input.TextArea rows={8} style={{ width: 'max(320px, 50vw)', height: 'max-content' }} value={errorMessage} />

          <Button
            className="mt-4 w-100"
            onClick={() => {
              navigator.clipboard.writeText(errorMessage);
              message.success('Texto copiado para a área de transferência.');
            }}
          >
            Copiar Erro
          </Button>
        </div>
      </FullScreenModal>
    </>
  );
};

export default NotSentTab;
