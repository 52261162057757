import CircleIcon from '@components/CircleIcon';
import LoadingComponents from '@components/LoadingComponents';
import { useVisits } from '@hooks/VisitsContext';
import { formatCnpj } from '@utils/cnpj-mask';
import { formatDate } from '@utils/date-format';
import { getAddressType } from '@utils/get-address-type';
import { Card, Col, Row, Typography } from 'antd';

const { Title, Text } = Typography;

const VisitModal = () => {
  const { visitDetails, loadingVisits } = useVisits();

  if (loadingVisits) return <LoadingComponents />;

  return (
    <Row className="w-100" align="middle" justify="center">
      <Col
        className="d-flex py-10"
        xs={23}
        sm={23}
        md={22}
        lg={20}
        xl={18}
        xxl={16}
        style={{
          background: 'white',
          flexDirection: 'column',
          padding: '100px',
          minHeight: '755px',
        }}
      >
        <Row>
          <Title level={3}>Informações da Visita</Title>
        </Row>

        <Col className="mt-9">
          <Title level={4}>Representante:</Title>
          <Text>{visitDetails.visit.representativeCustomer.representative.name}</Text>
          <br />
          <Text> {formatCnpj(visitDetails.visit.representativeCustomer.representative.cpfCnpj)} </Text>
        </Col>

        <Col className="mt-6">
          <Title level={4}>Lojista:</Title>
          <Text>{visitDetails.visit.representativeCustomer.name}</Text>
          <br />
          <Text> {formatCnpj(visitDetails.visit.representativeCustomer.cpfCnpj)} </Text>
        </Col>

        <Col className="mt-6">
          <Title level={4}>Marca:</Title>
          <Text>{visitDetails.visit.brand.brandName}</Text>
        </Col>

        <Col className="mt-6">
          <Title level={4}>Data da Visita:</Title>
          <Text>{formatDate(visitDetails.visit.visitDate)}</Text>
        </Col>

        <Col className="mt-6">
          <Title level={4}>Observação:</Title>
          <Text>{visitDetails.visit.observation}</Text>
        </Col>

        <Col className="mt-6">
          <Title level={4}>Validação</Title>
          {visitDetails.visit.valid ? <CircleIcon color="#6CA11E" /> : <CircleIcon color="#EDC363" />}
        </Col>

        <Row className="d-flex mt-6 justify-center" style={{ gap: '25px' }}>
          <Col className="mt-6" style={{ width: '45%' }}>
            {visitDetails.legalPersonAddresses.map((address) => {
              return (
                <Card bordered={false} bodyStyle={{ boxShadow: '0px 1px 5px 2px #26262650', marginBottom: '25px' }}>
                  <Title level={4}>Endereço do lojista - {getAddressType(address.addressType)}</Title>
                  <Text>{`${address.publicPlace?.length && `${address.publicPlace},`}
                          ${address.address?.length && `${address.address},`}
                          ${address.addressNumber && `${address.addressNumber},`}
                          ${address.neighborhood?.length && `${address.neighborhood}`}
                  `}</Text>
                  <Title level={4}>Cidade do lojista</Title>
                  <Text>{address.cityName}</Text>
                  <Title level={4}>Estado do lojista</Title>
                  <Text>{address.stateAbbreviation}</Text>
                </Card>
              );
            })}
          </Col>

          <Col className="mt-6" style={{ width: '45%' }}>
            <Card bodyStyle={{ boxShadow: '0px 1px 5px 2px #26262650' }}>
              <Title level={4}>Endereço da visita</Title>
              <Text>
                {`${visitDetails.visit.address.publicPlace?.length ? `${visitDetails.visit.address.publicPlace},` : ''} 
              ${visitDetails.visit.address.number ? `${visitDetails.visit.address.number},` : ''}
              ${visitDetails.visit.address.neighborhood?.length ? `${visitDetails.visit.address.neighborhood}` : ''}`}
              </Text>
              <Title level={4}>Cidade da Visita</Title>
              <Text>{visitDetails.visit.address.cityName ? visitDetails.visit.address.cityName : 'Não informado'}</Text>
              <Title level={4}>Estado da Visita</Title>
              <Text>
                {visitDetails.visit.address.stateAbbreviation
                  ? visitDetails.visit.address.stateAbbreviation
                  : 'Não informado'}
              </Text>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default VisitModal;
