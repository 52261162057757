import { AxiosResponse } from 'axios';

import * as api from './api';

const end_point = 'integrations';

export function get(integrationId?: number): Promise<AxiosResponse<any>> {
  if (integrationId) {
    return api.get([end_point, integrationId].join('/'));
  }
  return api.get(end_point);
}
