import { Tabs } from 'antd';
import React, { useCallback } from 'react';

import { useDevolution } from '@hooks/DevolutionsContext';
import { DevolutionStatusEnum } from '@models/enum/DevolutionStatusEnum';
import DevolutionsTab from './components/DevolutionsTab';
import FinishedTab from './components/FinishedTab';
import InDevolution from './components/InDevolution';
import PrimaryAnalysisTab from './components/PrimaryAnalysisTab';
import SecondaryAnalysisTab from './components/SecondaryAnalysisTab';

const DevolutionContent: React.FC = () => {
  const {
    loadPrimaryAnalysisDevolutions,
    loadSecondaryAnalysisDevolutions,
    loadInDevolutionDevolutions,
    loadFinishedDevolutions,
    loadDevolutions,
  } = useDevolution();

  React.useEffect(() => {
    loadDevolutions(0);
  }, []);

  const handleTabChange = useCallback((key) => {
    switch (key) {
      case DevolutionStatusEnum.PRIMARY_ANALYZIS.toString():
        loadPrimaryAnalysisDevolutions(0);
        break;

      case DevolutionStatusEnum.SECONDARY_ANALYSIS.toString():
        loadSecondaryAnalysisDevolutions(0);
        break;

      case DevolutionStatusEnum.IN_DEVOLUTION.toString():
        loadInDevolutionDevolutions(0);
        break;

      case DevolutionStatusEnum.FINISHED.toString():
        loadFinishedDevolutions(0);
        break;

      default:
        loadDevolutions(0);
        break;
    }
  }, []);

  return (
    <Tabs onChange={handleTabChange}>
      <Tabs.TabPane tab="Todos" key="ALL">
        <DevolutionsTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Análise primária" key="PRIMARY_ANALYSIS">
        <PrimaryAnalysisTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Análise secundária" key="SECONDARY_ANALYSIS">
        <SecondaryAnalysisTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Em devolução" key="IN_DEVOLUTION">
        <InDevolution />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Concluído" key="FINISHED">
        <FinishedTab />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default DevolutionContent;
