import Loading from '@components/Loading';
import { useBrand } from '@hooks/BrandContext';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router';

import OfficialStoreSellerContent from './components/OfficialStoreSellerContent';
import OfficialStoreSellerHeader from './components/OfficialStoreSellerHeader';

interface MatchParams {
  id: string;
}

const OfficialStoreSellerPage: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();

  const { actualOfficialStore, findOfficialStoreById, loading } = useOfficialStore();
  const { loadBrands, loading: loadingBrands } = useBrand();

  useEffect(() => {
    findOfficialStoreById(parseInt(params.id));
    loadBrands();
  }, []);

  if (loading || loadingBrands) return <Loading />;

  return (
    <>
      <OfficialStoreSellerHeader officialStore={actualOfficialStore} />
      <OfficialStoreSellerContent officialStore={actualOfficialStore} />
    </>
  );
};

export default OfficialStoreSellerPage;
