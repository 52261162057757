import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Form, Input, message, Tooltip } from 'antd';
import { CloseOutlined, InfoCircleFilled } from '@ant-design/icons';

import { CustomButton } from '@styles/globals';
import { usePriceTable } from '@hooks/PriceTableContext';
import * as S from './styles';
import LoadingComponents from '@components/LoadingComponents';

const { Title, Text } = Typography;

interface CardProps {
  code: number;
  company: number;
  onDelete: Function;
  notExcludable?: boolean;
}

const Card: React.FC<CardProps> = ({ code, company, onDelete, notExcludable }) => (
  <S.CustomCard>
    <Row className="w-100 card-row">
      <Col className="d-flex" span={21}>
        <Row className="w-100 card-row">
          <Col
            className="d-flex"
            span={24}
            style={{ padding: '4px', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Title level={5} style={{ height: '20px' }}>
              Código
            </Title>
            <Text>{code}</Text>
          </Col>
          <Col
            className="d-flex"
            span={24}
            style={{ padding: '4px', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Title level={5} style={{ height: '20px' }}>
              Empresa
            </Title>
            <Text>{company}</Text>
          </Col>
        </Row>
      </Col>
      <Col
        className="d-flex"
        span={3}
        style={{ padding: '4px', justifyContent: 'space-between', alignItems: 'center' }}
      >
        {notExcludable ? (
          <Tooltip title="Tabela de Preços já foi importada!" color="#510c76" key="tooltip" placement="topRight">
            <InfoCircleFilled className="ml-4" style={{ color: 'var(--primary-color)' }} />
          </Tooltip>
        ) : (
          <CloseOutlined className="ml-4" onClick={() => onDelete({ code, company })} />
        )}
      </Col>
    </Row>
  </S.CustomCard>
);

interface AddPriceTableType {
  code: number;
  company: number;
  notExcludable: boolean;
}

const FormAddPriceTable: React.FC = () => {
  const [currentCode, setCurrentCode] = useState<number | undefined>();
  const [currentCompany, setCurrentCompany] = useState<number | undefined>();

  const [priceTablesImportList, setPriceTablesImportList] = useState<AddPriceTableType[]>([]);

  const {
    updatePriceTables,
    loadPriceTableIntegrationFilter,
    priceTableIntegrationFilter,
    loadingPriceTableIntegrationFilter,
  } = usePriceTable();

  useEffect(() => {
    loadPriceTableIntegrationFilter();
  }, []);

  useEffect(() => {
    const newPriceList = priceTableIntegrationFilter?.priceTableCodeList?.map((priceTable) => {
      let priceTableSplit;
      if (priceTable.toString().includes(';')) priceTableSplit = priceTable.toString().split(';');
      else priceTableSplit = [priceTable, ''];
      return { code: Number(priceTableSplit[0]), company: Number(priceTableSplit[1]), notExcludable: true };
    });

    if (newPriceList?.length) setPriceTablesImportList(newPriceList);
  }, [priceTableIntegrationFilter]);

  const onAdd = (values: Partial<AddPriceTableType>) => {
    if (
      priceTablesImportList.find(({ code, company }: AddPriceTableType) => {
        return code == Number(values.code) && company == Number(values.company);
      })
    )
      return message.warning('Tabela de Preço já foi adicionada anteriormente!');

    setPriceTablesImportList([
      ...priceTablesImportList,
      { code: Number(values.code), company: Number(values.company), notExcludable: false },
    ]);
    message.success('Tabela de Preço adicionada com sucesso!');
  };

  const onDelete = (values: Partial<AddPriceTableType>) => {
    const index = priceTablesImportList.findIndex(
      ({ code, company }) => code == Number(values.code) && company == Number(values.company),
    );
    if (index !== -1) {
      if (priceTablesImportList[index].notExcludable)
        return message.warning('Não é possível remover uma Tabela de Preço já importada!');
      priceTablesImportList.splice(index, 1);
      setPriceTablesImportList([...priceTablesImportList]);
    }
  };

  const onSubmit = () => {
    updatePriceTables(priceTablesImportList.map(({ code, company }) => (company ? `${code};${company}` : `${code}`)));
  };

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || !Number(e.target.value)) return setCurrentCode(undefined);

    setCurrentCode(Math.abs(Number(e.target.value)));
  };

  const handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || !Number(e.target.value)) return setCurrentCompany(undefined);

    setCurrentCompany(Math.abs(Number(e.target.value)));
  };

  return (
    <Form layout="vertical" onFinish={(values: any) => onAdd(values)}>
      <Row className="d-flex" align="middle" justify="center" gutter={[16, 24]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="gutter-row">
          <S.CustomCardContainer floating="right">
            <S.ScrollContainer>
              {loadingPriceTableIntegrationFilter ? (
                <LoadingComponents />
              ) : (
                priceTablesImportList.map(({ code, notExcludable, company }) => (
                  <Card code={code} onDelete={onDelete} key={code} notExcludable={notExcludable} company={company} />
                ))
              )}
            </S.ScrollContainer>
          </S.CustomCardContainer>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="gutter-row">
          <S.CustomCardContainer floating="left">
            <S.ScrollContainer>
              <Title level={5} className="mb-4">
                Adicionar Tabela de Preço
              </Title>

              <Form.Item name="code" rules={[{ required: true, message: 'Informe o código!' }]}>
                <Input
                  type="number"
                  className="w-100"
                  min="0"
                  placeholder="Informe o código..."
                  size="large"
                  value={currentCode}
                  onChange={handleCodeChange}
                />
              </Form.Item>

              <Form.Item name="company" rules={[{ required: true, message: 'Informe a empresa!' }]}>
                <Input
                  type="number"
                  className="w-100"
                  min="0"
                  placeholder="Informe a empresa..."
                  size="large"
                  value={currentCompany}
                  onChange={handleCompanyChange}
                />
              </Form.Item>

              <CustomButton className="mt-2 mb-0 w-100" type="primary" htmlType="submit">
                Adicionar
              </CustomButton>

              <CustomButton className="mt-3 w-100" outlined="true" onClick={() => onSubmit()}>
                Salvar
              </CustomButton>

              <S.WarningContainer>
                <span>As Tabelas de Preço não podem ser excluídas, escolha com cuidado!</span>
              </S.WarningContainer>
            </S.ScrollContainer>
          </S.CustomCardContainer>
        </Col>
      </Row>
    </Form>
  );
};

export default FormAddPriceTable;
