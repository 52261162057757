import { FilterFilled } from '@ant-design/icons';
import FiltersForm from '@components/FiltersForm';
import FiltersList from '@components/FiltersList';
import HeaderButton from '@components/HeaderButton';
import ListFilters, { ListFilterType } from '@components/ListFilters';
import { useOrder } from '@hooks/OrdersContext';
import { Row, Col, Typography, Drawer } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import OrderFiltersForm from '../OrderFiltersForm';

const { Title } = Typography;

const filtersNameMap: any = {
  orderCode: 'Cód. Pedido',
  representative: 'Representante',
  customer: 'Lojista',
  initialDate: 'Período inicial',
  finalDate: 'Período final',
};

const OrderHeader: React.FC = () => {
  const [filtersDrawerIsVisible, setfiltersDrawerIsVisible] = useState<boolean>(false);
  const {
    loadAttendedOrders,
    loadCanceledOrders,
    loadInProgressOrders,
    status,
    attendedFilters,
    canceledFilters,
    inProgressFilters,
  } = useOrder();

  const getLoadOrders = () => {
    switch (status) {
      case 'Attended':
        return loadAttendedOrders;

      case 'InProgress':
        return loadInProgressOrders;

      case 'Canceled':
        return loadCanceledOrders;

      default:
        break;
    }
  };

  const getFilters = () => {
    switch (status) {
      case 'Attended':
        return attendedFilters;

      case 'InProgress':
        return inProgressFilters;

      case 'Canceled':
        return canceledFilters;

      default:
        break;
    }
  };

  const handleCloseFiltersDrawer = useCallback(() => {
    setfiltersDrawerIsVisible(false);
  }, [setfiltersDrawerIsVisible]);

  const handleShowFiltersDrawer = useCallback(() => {
    setfiltersDrawerIsVisible(true);
  }, [setfiltersDrawerIsVisible]);

  const onSubmitFiltersForm = useCallback(
    (values: any) => {
      let newFilters = {};
      if (values?.dates?.length) {
        newFilters = {
          ...values,
          dates: undefined,
          initialDate: moment(values.dates[0]).format('YYYY-MM-DD').toString(),
          finalDate: moment(values.dates[1]).format('YYYY-MM-DD').toString(),
        };
      } else {
        newFilters = {
          ...values,
        };
      }
      const loadOrders = getLoadOrders();

      if (loadOrders) loadOrders(0, newFilters);
    },
    [getLoadOrders],
  );

  const onClearFiltersForm = useCallback(() => {
    const loadOrders = getLoadOrders();

    if (loadOrders) loadOrders(0);
  }, []);

  const onRemoveFilter = ({ key }: ListFilterType) => {
    const loadOrders = getLoadOrders();
    const oldFilters = getFilters();

    if (key === 'initialDate' || key === 'finalDate') {
     delete oldFilters.initialDate
     delete oldFilters.finalDate
    } else {
      delete oldFilters[key]
    }

    if (loadOrders) loadOrders(0, oldFilters);
  };

  const filterColumns = [
    { title: 'Cód. Pedido', key: 'orderCode' },
    { title: 'Representante', key: 'representative' },
    { title: 'Lojista', key: 'customer' },
    { title: 'Data Inicial', key: 'initialDate', isDate: true },
    { title: 'Data Final', key: 'finalDate', isDate: true },
  ];

  return (
    <Row className="mt-8" justify="space-between">
      <Col>
        <Title level={2}>Pedidos</Title>
      </Col>

      {status !== 'NotSent' && (
        <Col>
          <Row>
            {status === 'Attended' && (
              <Col>
                <FiltersList columns={filterColumns} dataSource={attendedFilters} onRemove={onRemoveFilter} />
              </Col>
            )}

            {status === 'Canceled' && (
              <Col>
                <FiltersList columns={filterColumns} dataSource={canceledFilters} onRemove={onRemoveFilter} />
              </Col>
            )}

            {status === 'InProgress' && (
              <Col>
                <FiltersList columns={filterColumns} dataSource={inProgressFilters} onRemove={onRemoveFilter} />
              </Col>
            )}

            <Col>
              <HeaderButton
                text="Filtros"
                onClick={handleShowFiltersDrawer}
                Icon={<FilterFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
              />
            </Col>
          </Row>
        </Col>
      )}

      <Drawer
        title="Filtros"
        placement="left"
        onClose={handleCloseFiltersDrawer}
        visible={filtersDrawerIsVisible}
        width={400}
      >
        <FiltersForm
          onCloseDrawer={handleCloseFiltersDrawer}
          onSubmit={onSubmitFiltersForm}
          onClearFilters={onClearFiltersForm}
          filtersForm={<OrderFiltersForm />}
        />
      </Drawer>
    </Row>
  );
};

export default OrderHeader;
