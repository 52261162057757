import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';

import { useOfficialStore } from '@hooks/OfficialStoreContext';

import CardList from './Components/CardList';
import OfficialStoreHeader from './Components/OfficialStoreHeader';
import Loading from '@components/Loading';

const OfficialStorePage: React.FC = () => {
  const { url } = useRouteMatch();

  const { officialStores, loadOfficialStores, loading } = useOfficialStore();

  useEffect(() => {
    loadOfficialStores(0);
  }, []);

  if (loading) return <Loading />;

  return (
    <>
      <OfficialStoreHeader baseUrl={url} />
      <CardList officialStoreList={officialStores} redirectBaseUrl={`${url}/perfil`} />
    </>
  );
};

export default OfficialStorePage;
