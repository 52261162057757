import React from 'react';
import { Row } from 'antd';

import VisibilityPanel from '../VisibilityPanel';
import SalesTypePanel from '../SalesTypePanel';
import OfficialStoreInformationsPanel from '../OfficialStoreInformationsPanel';

interface NewOfficialStoreContentProps {
  logoUrl: string;
  setLogoUrl: React.Dispatch<React.SetStateAction<string>>;
}

const NewOfficialStoreContent: React.FC<NewOfficialStoreContentProps> = ({ logoUrl, setLogoUrl }) => {
  return (
    <Row className="mt-6" gutter={[16, 16]}>
      <OfficialStoreInformationsPanel logoUrl={logoUrl} setLogoUrl={setLogoUrl} />

      <SalesTypePanel />

      <VisibilityPanel />
    </Row>
  );
};

export default NewOfficialStoreContent;
