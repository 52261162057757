import React, { useEffect, useState } from 'react';
import { Table, Typography } from 'antd';

const { Text } = Typography;

interface TabBarCodeProps {
  barCodes: string[];
}

const TabBarCode: React.FC<TabBarCodeProps> = ({ barCodes }) => {
  const [localBarCodes, setLocalBarCodes] = useState<any[]>([]);

  const columns = [
    {
      title: 'Código de Barras',
      dataIndex: 'barcode',
      render: (barcode: number) => <Text>Código de Barras: {barcode}</Text>,
    },
  ];

  useEffect(() => {
    if (barCodes) {
      setLocalBarCodes(
        barCodes.map((barcode) => ({
          barcode,
        })),
      );
    }
  }, [barCodes]);

  return (
    <Table
    scroll={{ x: 800 }}
      columns={columns}
      dataSource={localBarCodes}
      pagination={{ pageSize: 5, showSizeChanger: false, showQuickJumper: false, hideOnSinglePage: true }}
      showHeader={false}
    />
  );
};

export default TabBarCode;
