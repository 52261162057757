import { Form, Input } from 'antd';

interface OfficialStoreFiltersFormProps {}
const OfficialStoreFiltersForm: React.FC<OfficialStoreFiltersFormProps> = () => {
  return (
    <>
      <Form.Item className="mb-3" label="Descrição" name="name">
        <Input placeholder="Descrição da Loja Oficial..." />
      </Form.Item>
    </>
  );
};

export default OfficialStoreFiltersForm;
