import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { message } from 'antd';

import * as OfficialStoreQuestionsService from '@services/officialStoreQuestion';
import { OfficialStoreQuestion } from '@models/OfficialStoreQuestion';
import { OrderDefinedFields } from '@models/OrderDefinedFields';

interface OfficialStoreQuestionsState {
  loadingOfficialStoreQuestions: boolean;
  loadingCreateOfficialStoreQuestion: boolean;
  loadingDeleteOfficialStoreQuestion: boolean;
  loadingUpdateOfficialStoreQuestion: boolean;
  loadingOrderDefinedFields: boolean;
  officialStoreQuestions: OfficialStoreQuestion[];
  orderDefinedFields: OrderDefinedFields[];
  loadOrderDefinedFields: Function;
  loadOfficialStoreQuestions: Function;
  createOfficialStoreQuestion: Function;
  updateOfficialStoreQuestion: Function;
  deleteOfficialStoreQuestion: Function;
}

interface OfficialStoreQuestionsProviderProps {
  children: ReactNode;
}

export const OfficialStoreQuestionsContext = createContext<OfficialStoreQuestionsState>(
  {} as OfficialStoreQuestionsState,
);

const OfficialStoreQuestionsProvider: React.FC<OfficialStoreQuestionsProviderProps> = ({ children }) => {
  const [officialStoreQuestions, setOfficialStoreQuestions] = useState<any[]>([]);
  const [orderDefinedFields, setOrderDefinedFields] = useState<any[]>([]);
  const [loadingOfficialStoreQuestions, setLoadingOfficialStoreQuestions] = useState<boolean>(false);
  const [loadingCreateOfficialStoreQuestion, setLoadingCreateOfficialStoreQuestions] = useState<boolean>(false);
  const [loadingDeleteOfficialStoreQuestion, setLoadingDeleteOfficialStoreQuestions] = useState<boolean>(false);
  const [loadingUpdateOfficialStoreQuestion, setLoadingUpdateOfficialStoreQuestions] = useState<boolean>(false);

  const [loadingOrderDefinedFields, setLoadingOrderDefinedFields] = useState<boolean>(false);

  const loadOrderDefinedFields = useCallback(async () => {
    setLoadingOrderDefinedFields(true);

    const { data: _definedFields } = await OfficialStoreQuestionsService.getOrderDefinedFields();

    if (_definedFields) setOrderDefinedFields(_definedFields);

    setLoadingOrderDefinedFields(false);
  }, [setLoadingOrderDefinedFields, setOrderDefinedFields]);

  const loadOfficialStoreQuestions = useCallback(
    async (officialStoreId: number) => {
      setLoadingOfficialStoreQuestions(true);

      const { data: _officialStoreOfficialStoreQuestions } =
        await OfficialStoreQuestionsService.getQuestionsByOfficialStoreId(officialStoreId);

      if (_officialStoreOfficialStoreQuestions) setOfficialStoreQuestions(_officialStoreOfficialStoreQuestions);

      setLoadingOfficialStoreQuestions(false);
    },
    [setLoadingOfficialStoreQuestions],
  );

  const createOfficialStoreQuestion = useCallback(
    async (officialStoreId: number, question: any) => {
      setLoadingCreateOfficialStoreQuestions(true);

      try {
        const { data: _officialStoreOfficialStoreQuestions } =
          await OfficialStoreQuestionsService.createQuestionByOfficialStoreId(officialStoreId, question);

        setOfficialStoreQuestions([...officialStoreQuestions, _officialStoreOfficialStoreQuestions]);

        message.success('Pergunta cadastrada com sucesso!');
      } catch {
        message.error('Erro ao cadastrar pergunta!');
      } finally {
        setLoadingCreateOfficialStoreQuestions(false);
      }
    },
    [officialStoreQuestions, setLoadingCreateOfficialStoreQuestions, setOfficialStoreQuestions],
  );

  const updateOfficialStoreQuestion = useCallback(
    async (officialStoreId: number, questionId: number, question: any) => {
      setLoadingUpdateOfficialStoreQuestions(true);

      try {
        const { data: _officialStoreOfficialStoreQuestions } =
          await OfficialStoreQuestionsService.updateQuestionByOfficialStoreId(officialStoreId, questionId, question);

        if (_officialStoreOfficialStoreQuestions) {
          const index = officialStoreQuestions.findIndex((_question) => _question.questionId === questionId);

          if (index !== -1) {
            officialStoreQuestions[index] = _officialStoreOfficialStoreQuestions;
            setOfficialStoreQuestions([...officialStoreQuestions]);
            message.success('Pergunta alterada com sucesso!');
          }
        }
      } catch {
        message.error('Erro ao alterar pergunta!');
      } finally {
        setLoadingUpdateOfficialStoreQuestions(false);
      }
    },
    [officialStoreQuestions, setLoadingUpdateOfficialStoreQuestions],
  );

  const deleteOfficialStoreQuestion = useCallback(
    //question como parametros para resolver a questão de closures
    async (officialStoreId: number, questionId: number, actualOfficialStoreQuestions: any[]) => {
      setLoadingDeleteOfficialStoreQuestions(true);

      try {
        const index = actualOfficialStoreQuestions.findIndex((question) => question.questionId === questionId);

        if (index !== -1) {
          await OfficialStoreQuestionsService.deleteQuestionByIds(officialStoreId, questionId);
          actualOfficialStoreQuestions.splice(index, 1);
          setOfficialStoreQuestions([...actualOfficialStoreQuestions]);
          message.success('Pergunta excluída com sucesso!');
        }
      } catch {
        message.error('Erro ao excluir pergunta!');
      } finally {
        setLoadingDeleteOfficialStoreQuestions(false);
      }
    },
    [setLoadingDeleteOfficialStoreQuestions, setOfficialStoreQuestions],
  );

  return (
    <OfficialStoreQuestionsContext.Provider
      value={{
        loadingOfficialStoreQuestions,
        loadingCreateOfficialStoreQuestion,
        loadingUpdateOfficialStoreQuestion,
        loadingDeleteOfficialStoreQuestion,
        loadingOrderDefinedFields,
        orderDefinedFields,
        loadOrderDefinedFields,
        officialStoreQuestions,
        loadOfficialStoreQuestions,
        createOfficialStoreQuestion,
        updateOfficialStoreQuestion,
        deleteOfficialStoreQuestion,
      }}
    >
      {children}
    </OfficialStoreQuestionsContext.Provider>
  );
};

const useOfficialStoreQuestions = () => {
  return useContext(OfficialStoreQuestionsContext);
};

export { OfficialStoreQuestionsProvider, useOfficialStoreQuestions };
