import { ExclamationCircleOutlined } from '@ant-design/icons';
import AddReferenceBindingCollectionFormModal from '@components/CollectionForm/components/AddReferenceBindingCollectionFormModal';
import { PriceTableReferenceData } from '@components/PriceTableForm/models/price-table-form-models';
import { useReference } from '@hooks/ReferenceContext';
import { Reference } from '@models/Reference';
import { Container } from '@pages/MainPage/styles';
import { CustomButton, FullScreenModal } from '@styles/globals';
import { Col, Modal, Row, Table, Tabs, Typography, notification } from 'antd';
import { registerRenderer, textRenderer } from 'handsontable/renderers';
import React from 'react';
const { Title } = Typography;
const { TabPane } = Tabs;

registerRenderer('customStylesRenderer', (hotInstance, TD, ...rest) => {
  textRenderer(hotInstance, TD, ...rest);
  TD.style.fontSize = '18px';
});

interface ReferenceBindingStepProps {
  formValues: {
    references: Reference[];
    referencesTableData: PriceTableReferenceData[];
  };
  setFormValues: Function;
  goToNextStep: Function;
  isEditing: boolean;
  render: boolean;
}

const ReferenceBindingStep: React.FC<ReferenceBindingStepProps> = ({
  formValues,
  setFormValues,
  goToNextStep,
  isEditing = false,
  render = true,
}) => {
  const { searchReferences } = useReference();

  const [addReferenceModalIsVisible, setAddReferenceModalIsVisible] = React.useState(false);

  const [selectedReferences, setSelectedReferences] = React.useState<Reference[]>([]);

  const onCloseAddReferenceModal = () => setAddReferenceModalIsVisible(false);

  const onShowAddReferenceModal = () => {
    searchReferences({
      integrationId: Number(localStorage.getItem('@modavest/integration_id')),
      isActive: true,
      name: null,
      referenceCode: null,
    });
    setAddReferenceModalIsVisible(true);
  };

  React.useEffect(() => {
    if (formValues?.references) {
      setSelectedReferences(formValues?.references);
    }
  }, []);

  React.useEffect(() => {
    if (isEditing && formValues?.references) {
      setSelectedReferences(formValues?.references);
    }
  }, [formValues]);

  const finishStep = async () => {
    const values = getFormValues();

    setFormValues(values);

    if (selectedReferences?.length > 0) {
      showModalConfirmation();
    } else {
      notification.warning({
        message: 'Aviso',
        description: `Adicione pelo menos uma referência na coleção.`,
      });
    }
  };

  const getFormValues = () => {
    const values = {
      references: selectedReferences,
    };
    return values;
  };

  const showModalConfirmation = () => {
    Modal.confirm({
      title: 'Tem certeza que deseja concluir?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Concluir',
      cancelText: 'Cancelar',
      onOk() {
        const values = getFormValues();
        goToNextStep(values);
      },
    });
  };

  const removeReference = (key: string) => {
    const newList = selectedReferences.filter((reference) => reference.ReferenceCode !== key);
    setSelectedReferences(newList);
  };

  const addReferences = (newReferences: Reference[]) => {
    setSelectedReferences((prev) => [...prev, ...newReferences] as any);
  };

  return (
    <>
      {render && (
        <>
          <div className="d-flex" style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}>
            <Row className="d-flex" style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Col span={8}>
                <CustomButton type="primary" style={{ marginBottom: 10 }} onClick={onShowAddReferenceModal}>
                  Adicionar referência
                </CustomButton>
              </Col>
            </Row>

            <Row className="d-flex" style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Col span={24}>
                <h2 style={{ margin: '20px 0 10px 0' }}>Referências adicionadas</h2>
              </Col>
            </Row>

            <Row
              className="d-flex"
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                maxHeight: 350,
                overflow: 'auto',
              }}
            >
              {selectedReferences?.length === 0 ? (
                <Table
                  bordered={true}
                  showHeader={false}
                  pagination={false}
                  style={{ width: '100%' }}
                  className="table-antd"
                  dataSource={[]}
                />
              ) : (
                selectedReferences?.map((reference) => {
                  return (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '20px 30px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '8px',
                        margin: '10px 0',
                      }}
                    >
                      <span>{reference.ReferenceCode + ' - ' + reference.name}</span>
                      <p
                        style={{
                          color: 'red',
                          textDecoration: 'underline',
                          margin: 0,
                          padding: 0,
                          cursor: 'pointer',
                        }}
                        onClick={() => removeReference(reference.ReferenceCode)}
                      >
                        Excluir
                      </p>
                    </div>
                  );
                })
              )}
            </Row>

            <CustomButton type="primary" style={{ marginTop: 40, width: '100%' }} onClick={finishStep}>
              Concluir e ver coleção
            </CustomButton>
          </div>

          <FullScreenModal
            destroyOnClose={true}
            visible={addReferenceModalIsVisible}
            onOk={onCloseAddReferenceModal}
            onCancel={onCloseAddReferenceModal}
            footer={null}
            forceRender={false}
          >
            <Row align="middle" justify="center">
              <Col span={24} className="d-flex" style={{ width: '900px' }}>
                <Container
                  style={{
                    background: '#fff',
                    padding: '60px 100px',
                    borderRadius: '8px',
                    width: '100%',
                    height: 'auto',
                  }}
                >
                  <AddReferenceBindingCollectionFormModal
                    onCloseModal={onCloseAddReferenceModal}
                    setReferences={addReferences}
                    selected={selectedReferences}
                  />
                </Container>
              </Col>
            </Row>
          </FullScreenModal>
        </>
      )}
    </>
  );
};

export default ReferenceBindingStep;
