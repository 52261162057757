import React from 'react';

import UploadImagesContent from './components/UploadImagesContent';
import UploadImagesHeader from './components/UploadImagesHeader';

interface UploadImagesProps {}
const UploadImages: React.FC<UploadImagesProps> = () => {


  return (
    <>
      <UploadImagesHeader />
      <UploadImagesContent />
    </>
  );
};

export default UploadImages;
