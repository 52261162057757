import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 380px;
  background: white;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;
