import { Tabs } from 'antd';

import { PriceTable } from '@models/PriceTable';

import TabAveragePeriod from '../TabAveragePeriod';
import TabClassification from '../TabClassification';
import TabObservation from '../TabObservation';
import TabPaymentCondition from '../TabPaymentCondition';
import TabProduct from '../TabProduct';
import { usePriceTable } from '@hooks/PriceTableContext';
import LoadingComponents from '@components/LoadingComponents';
import TabReference from '../TabReference';
import TabOfficialStore from '../TabOfficialStore';

interface ContentTableProps {
  priceTable: PriceTable;
}

const ContentTable: React.FC<ContentTableProps> = ({ priceTable }) => {
  const { loadingDetails } = usePriceTable();

  if (loadingDetails) return <LoadingComponents />;

  return (
    <Tabs className="mb-6">
      <Tabs.TabPane tab="Lojas Oficiais" key="1">
        <TabOfficialStore priceTableOfficialStores={priceTable.officialStores} />
      </Tabs.TabPane>

      <Tabs.TabPane tab="Cond. Pagto." key="2">
        <TabPaymentCondition paymentConditions={priceTable.paymentConditions} />
      </Tabs.TabPane>

      <Tabs.TabPane tab="Prazo Médio" key="3">
        <TabAveragePeriod averagePeriods={priceTable.averagePeriod} />
      </Tabs.TabPane>

      <Tabs.TabPane tab="Classificação" key="4">
        <TabClassification personClassifications={priceTable.personClassification} />
      </Tabs.TabPane>

      <Tabs.TabPane tab="Observação" key="5">
        <TabObservation priceTable={priceTable} />
      </Tabs.TabPane>

      <Tabs.TabPane tab="Produtos" key="7">
        <TabProduct priceTableProducts={priceTable.products} />
      </Tabs.TabPane>

      <Tabs.TabPane tab="Items Tab. Preço" key="8">
        <TabReference priceTableReferences={priceTable.references} />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default ContentTable;
