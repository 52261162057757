import React from 'react';
import { Typography, Table, Spin } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { OfficialStore } from '@models/OfficialStore';
import { useCollaborator } from '@hooks/CollaboratorContext';
import '../../styles.css';

const { Text } = Typography;

interface OfficialStoreTableProps {
  officialStores: OfficialStore[];
  collaboratorId: number;
}

const OfficialStoreTable: React.FC<OfficialStoreTableProps> = ({ collaboratorId, officialStores }) => {
  const { removeOfficialStoreFromCollaborator, loadingDetails } = useCollaborator();

  const columns = [
    {
      title: 'ID',
      width: '100px',
      dataIndex: 'officialStoreId',
      render: (id: any): JSX.Element => {
        return <Text>#{id}</Text>;
      },
      sorter: (storeA: OfficialStore, storeB: OfficialStore) => storeA.officialStoreId - storeB.officialStoreId,
    },
    {
      title: 'Lojas Oficiais',
      dataIndex: 'description',
      sorter: (storeA: OfficialStore, storeB: OfficialStore) => storeA.description.localeCompare(storeB.description),
    },
    {
      align: 'right' as 'right',
      render: (record: any): JSX.Element => {
        return (
          <CloseOutlined
            style={{ cursor: 'pointer' }}
            onClick={() => removeOfficialStoreFromCollaborator(collaboratorId, record.officialStoreId)}
          />
        );
      },
    },
  ];

  if (loadingDetails)
    return (
      <div className="d-flex mt-8 mb-8" style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Spin />
      </div>
    );

  return (
    <Table
      className="mt-4 mb-8 mr-4 ml-8"
      columns={columns}
      dataSource={officialStores}
      scroll={{ x: 800 }}
      pagination={{ pageSize: 4, hideOnSinglePage: true }}
    />
  );
};

export default OfficialStoreTable;
