import { useOrder } from '@hooks/OrdersContext';
import { Tabs } from 'antd';
import { useCallback } from 'react';

import AttendedTab from '../AttendedTab';
import CanceledTab from '../CanceledTab';
import InProgressTab from '../InProgressTab';
import NotSentTab from '../NotSentTab';

const OrderContent: React.FC = () => {
  const { loadAttendedOrders, loadCanceledOrders, loadInProgressOrders, loadNotSentOrders } = useOrder();
  const handleTabChange = useCallback((key) => {
    switch (key) {
      case 'InProgress':
        loadInProgressOrders(0);
        break;

      case 'Attended':
        loadAttendedOrders(0);
        break;

      case 'Canceled':
        loadCanceledOrders(0);
        break;

      case 'NotSent':
        loadNotSentOrders(0);
        break;
      default:
        break;
    }
  }, []);

  return (
    <Tabs onChange={handleTabChange}>
      <Tabs.TabPane tab="Andamento" key="InProgress">
        <InProgressTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Concluídos" key="Attended">
        <AttendedTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Cancelados" key="Canceled">
        <CanceledTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Pedidos com Erro" key="NotSent">
        <NotSentTab />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default OrderContent;
