export function returnOrderStatus(status: string) {
  switch (status) {   
    case 'InProgress':
      return 'Em andamento';

    case 'BillingReleased':
      return 'Liberado para faturamento';

    case 'PartiallyAnswered':
      return 'Parcialmente atendido';

    case 'Attended':
      return 'Atendido';

    case 'Blocked':
      return 'Bloqueado';

    case 'Canceled':
      return 'Cancelado';

    case 'InComposition':
      return 'Em composição';

    case 'InAnalysis':
      return 'Em análise';

    default:
      return 'Situação não encontrada';
  }
}
