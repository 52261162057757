import React, { useCallback, useEffect, useState } from 'react';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';

import LayoutHeader from '@components/LayoutHeader';
import RoundedButton from '@components/RoundedButton';
import GoalsContent from './components/GoalsContent';
import { FullScreenModal } from '@styles/globals';
import CreateGoal from '@components/CreateGoal';
import { useGoal } from '@hooks/GoalContext';
import './styles.css';
import HeaderButton from '@components/HeaderButton';

const GoalsPage: React.FC = () => {
  const [modalCreateGoalIsVisible, setModalCreateGoalIsVisible] = useState<boolean>(false);
  const [newGoalId, setNewGoalId] = useState<number>();

  const { loadGoals, createGoal, uploadGoalFileSheet, downloadGoalModelFile, loadingDownloadGoalModelFile } = useGoal();

  const onCloseModalCreateGoal = useCallback(() => {
    setModalCreateGoalIsVisible(false);
  }, [setModalCreateGoalIsVisible]);

  const onShowModalCreateGoal = useCallback(() => {
    setModalCreateGoalIsVisible(true);
  }, [setModalCreateGoalIsVisible]);

  const onDownloadGoalModelFile = useCallback(async () => {
    await downloadGoalModelFile();
  }, [downloadGoalModelFile]);

  const getImportButton = useCallback(() => {
    return (
      <div className="header-buttons d-flex">
        <HeaderButton
          text="Baixar modelo de planilha de metas"
          Icon={<DownloadOutlined style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
          onClick={onDownloadGoalModelFile}
          loading={loadingDownloadGoalModelFile}
        />

        <HeaderButton
          text="Criar novas metas"
          Icon={<UploadOutlined style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
          onClick={onShowModalCreateGoal}
        />
      </div>
    );
  }, [loadingDownloadGoalModelFile, onShowModalCreateGoal, onDownloadGoalModelFile]);

  useEffect(() => {
    loadGoals(0);
  }, [loadGoals]);

  const onSubmitGoal = async (values: { description: string }) => {
    const goal = await createGoal(values?.description?.toUpperCase());
    if (goal) {
      setNewGoalId(goal.goalId);
    }
  };

  const onSubmitFileSheet = async (file: File) => {
    await uploadGoalFileSheet(file);
    onCloseModalCreateGoal();
  };

  return (
    <>
      <LayoutHeader title="Metas" extraComponent={getImportButton()} />
      <GoalsContent />
      <FullScreenModal
        destroyOnClose={true}
        footer={null}
        forceRender={false}
        visible={modalCreateGoalIsVisible}
        onOk={onCloseModalCreateGoal}
        onCancel={onCloseModalCreateGoal}
        bodyStyle={{ width: '100%' }}
      >
        <CreateGoal onSubmitGoal={onSubmitGoal} onSubmitFileSheet={onSubmitFileSheet} newGoalId={newGoalId} />
      </FullScreenModal>
    </>
  );
};

export default GoalsPage;
