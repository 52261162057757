import { Address } from '@models/Address';
import { Email } from '@models/Email';
import { EntityWithLabel } from '@models/EntityWithLabel';
import { IntegrationAdditionalFields } from '@models/IntegrationAdditionalFields';
import { Manufacturer } from '@models/Manufacturer';
import { Phone } from '@models/Phone';
import { Response } from '@models/Response';
import { AxiosResponse } from 'axios';
import * as api from './api';

const manufacturers = 'manufacturers';

export async function get(): Promise<Response<Manufacturer>> {
  return api.get(`${manufacturers}/headoffice`);
}

export async function getPhones(manufacturerId: number): Promise<Response<Phone>> {
  return api.get(`${manufacturers}/${manufacturerId}/phonees`);
}

export async function getAddresses(manufacturerId: number): Promise<Response<Address[]>> {
  return api.get(`${manufacturers}/${manufacturerId}/addresses`);
}

export async function getEmails(manufacturerId: number): Promise<Response<Email>> {
  return api.get(`${manufacturers}/${manufacturerId}/emails`);
}

export async function saveAdditionalFields(body: any): Promise<any> {
  return api.post('integrations/upload', body);
}

export async function getPhotoUrl(): Promise<Response<IntegrationAdditionalFields>> {
  return api.get(`integrations/fields`);
}

export async function getEntitiesToForceImport(): Promise<Response<EntityWithLabel>> {
  return api.get(`integrations/entities`);
}

export function forceImport(
  entity: string,
): Promise<AxiosResponse<{ entityName: string; scheduled: number; message: string }>> {
  return api.post(`integrations/force-import`, { entityName: entity });
}

export function updateImage(additionalFieldsPhotoUrl: string) {
  return api.put(`integrations/fields`, { additionalFieldsPhotoUrl });
}

export async function uploadImage(file: any): Promise<any> {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const formData = new FormData();
  formData.append('file', file);
  return await api.postWithConfig('upload', formData, config).catch((error: any) => {
    return new Promise((resolve) => {
      resolve(error.response);
    });
  });
}
