import React, { useState } from 'react';
import { Typography, Table } from 'antd';

import * as S from '../../styles';
import { FullScreenModal } from '@styles/globals';
import PaymentTypeForm from '../PaymentTypeForm';

const { Title, Text } = Typography;

interface PanelPaymentIsDeliveryImmediatelyProps {
  dataSourcePaymentFormIsDeliveryImmediately: any[];
}

const PanelPaymentIsDeliveryImmediately: React.FC<PanelPaymentIsDeliveryImmediatelyProps> = ({
  dataSourcePaymentFormIsDeliveryImmediately,
}) => {
  const [modalIsDeliveryImmediatelyIsVisible, setModalIsDeliveryImmediatelyIsVisible] = useState<boolean>(false);
  const [actualPaymentTypeIsDeliveryImmediately, setActualPaymentTypeIsDeliveryImmediately] = useState<any>();

  const onReturnPaymentTypeDescription = (value: any): string => {
    const {
      isAccept,
      isAcceptWithGuide,
      isAcceptWithRepresentative,
      isAcceptWithGuideOrRepresentative,
      isNotAccept,
      isNotAcceptWithGuide,
      isNotAcceptWithRepresentative,
      isNotAcceptWithGuideOrRepresentative,
    } = value;

    if (isAccept) return 'Aceita';
    if (isAcceptWithGuide) return 'Aceita quando o lojista possui Guia';
    if (isAcceptWithRepresentative) return 'Aceita quando o lojista possui Representante';
    if (isAcceptWithGuideOrRepresentative) return 'Aceita quando o lojista possui Guia ou Representante';

    if (isNotAccept) return 'Não aceita';
    if (isNotAcceptWithGuide) return 'Não aceita quando o lojista possui Guia';
    if (isNotAcceptWithRepresentative) return 'Não aceita quando o lojista possui Representante';
    if (isNotAcceptWithGuideOrRepresentative) return 'Não aceita quando o lojista possui Guia ou Representante';

    return 'Selecione uma opção...';
  };

  const columnsPaymentType = [
    {
      title: 'Pedidos Pronta Entrega',
      dataIndex: 'paymentType',
    },
    {
      render: (value: any) => {
        if (!value) return <Text style={{ color: 'gray' }}>Informação não encontrada</Text>;

        return <Text style={{ color: 'gray' }}>{onReturnPaymentTypeDescription(value)}</Text>;
      },
    },
  ];

  const onCloseModalIsDeliveryImmediately = () => {
    setModalIsDeliveryImmediatelyIsVisible(false);
  };

  return (
    <S.Panel>
      <Title level={5} style={{ color: '#555' }}>
        Forma de Pagamento
      </Title>

      <Table
        className="mt-2"
        columns={columnsPaymentType}
        dataSource={dataSourcePaymentFormIsDeliveryImmediately}
        pagination={false}
        onRow={(record) => {
          return {
            onClick: (event) => {
              setActualPaymentTypeIsDeliveryImmediately(record);
              setModalIsDeliveryImmediatelyIsVisible(true);
            },
          };
        }}
        style={{ cursor: 'pointer' }}
      />

      <FullScreenModal
        destroyOnClose={true}
        visible={modalIsDeliveryImmediatelyIsVisible}
        onOk={onCloseModalIsDeliveryImmediately}
        onCancel={onCloseModalIsDeliveryImmediately}
        footer={null}
        forceRender={false}
      >
        <PaymentTypeForm paymentType={actualPaymentTypeIsDeliveryImmediately} />
      </FullScreenModal>
    </S.Panel>
  );
};

export default PanelPaymentIsDeliveryImmediately;
