import { SearchOutlined } from '@ant-design/icons';
import ModavestBrandLogo from '@assets/svg/modavest-brand.svg';
import { Container, Content, Footer, Header } from '@pages/MainPage/styles';
// import * as lambdaService from '@services/lambda';
import ListFilters from '@components/ListFilters';
import {
  ImageByOrderCodeEncriptedResponseType,
  OrderInfoResponseType,
  getImagesByOrderCodeEncripted,
} from '@services/order';
import { normalizeString } from '@utils/normalize-string';
import { Input, Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import OrderInfoCard from './components/OrderInfoCard';
import ReferenceImageComponent from './components/ReferenceImageComponent';
import './styles.css';
// import { CustomButton } from '@styles/globals';

interface Params {
  i: string;
  o: string;
}

const parseQuery = (queryString: string): Params => {
  const query = queryString.substring(1); // Remove o "?" do início da string
  const params = Object.assign({});

  query.split('&').forEach((pair) => {
    const [key, value] = pair.split('=');
    params[key] = value;
  });

  return params;
};

//TODO: Comentado alguns trechos para quando for implementar um botão de enviar tudo por email através da lambda de zip é só descomentar e ajustar
const OrderImagePage: React.FC = () => {
  const { search } = useLocation();
  const { i: integrationId, o: orderCodeEncrypted } = parseQuery(search);
  const [orderInfo, setOrderInfo] = useState<OrderInfoResponseType>();
  const [loading, setLoading] = useState(false);
  const [filteredReferences, setFilteredReferences] = useState<ImageByOrderCodeEncriptedResponseType[]>([]);
  const [showModalSearch, setShowModalSearch] = useState(false);
  const [referenceToSearch, setReferenceToSearch] = useState('');

  const loadImages = async () => {
    try {
      setLoading(true);
      const { data: response } = await getImagesByOrderCodeEncripted(orderCodeEncrypted, +integrationId);
      setOrderInfo(response);
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Erro ao buscar fotos',
        description: 'Verifique se a url está correta e tente novamente!',
      });
    } finally {
      setLoading(false);
    }
  };

  /*
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [showModalEmail, setShowModalEmail] = useState(false);
  const [emailToSendZip, setEmailToSendZip] = useState<string>('');
  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const doRequestToZipImages = async () => {
    if (!validateEmail(emailToSendZip)) {
      notification.error({ message: 'Email inválido', description: 'Verifique o email informado e tente novamente!' });
      return;
    }
    const body: lambdaService.ZipImagesUrl = {
      integration_id: integrationId,
      email: emailToSendZip,
      order_code: orderInfo?.orderCode || '',
    };
    setLoadingEmail(true);
    await lambdaService
      .sendEmailWithZipPhotosOfOrder(body)
      .then(() =>
        notification.success({ message: 'Confira seu email!', description: 'Enviamos um link para baixar as fotos' }),
      )
      .catch((e) => {
        console.error('Algo deu errado ao enviar link para o email', e);
        notification.error({ message: 'Algo deu errado' });
      })
      .finally(() => {
        setShowModalEmail(false);
        setLoadingEmail(false);
      });
  };
  */

  useEffect(() => {
    if (integrationId && orderCodeEncrypted) {
      loadImages();
      return;
    }
    notification.warn({ message: 'URL inválida', description: 'Verifique e tente novamente!' });
  }, [integrationId, orderCodeEncrypted]);

  useEffect(() => {
    const result =
      orderInfo?.references.reduce((acc, curr) => {
        if (acc[curr.referenceCode]) {
          acc[curr.referenceCode].urls.push(...curr.urls);
        } else {
          acc[curr.referenceCode] = {
            referenceCode: curr.referenceCode,
            urls: curr.urls,
          };
        }
        return acc;
      }, {} as { [x: string]: { urls: string[]; referenceCode: string } }) || {};

    const resultArray = Object.values(result);
    setFilteredReferences(
      (resultArray || [])
        .filter((ref) => normalizeString(ref.referenceCode).includes(normalizeString(referenceToSearch)))
        .map(({ urls, referenceCode }) => Object.assign({ referenceCode, urls })),
    );
  }, [orderInfo, referenceToSearch]);

  return (
    <>
      <Container>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <Header className="d-flex justify-space-between" style={{ backgroundColor: 'var(--primary-color)' }}>
          <img src={ModavestBrandLogo} width={200} style={{ padding: '8px' }} />
          <SearchOutlined className="icon-search" onClick={() => setShowModalSearch(true)} />
          {/* <CustomButton
            type="primary"
            outlined="true"
            style={{ marginBottom: '5px', marginTop: '5px' }}
            onClick={() => {
              // setShowModalEmail(true);
            }}
          >
            Baixar todas as fotos
          </CustomButton> */}
        </Header>
        <Content style={{ backgroundColor: '#f5f5f5' }} className="content-container overflow-auto">
          <OrderInfoCard loading={loading} orderInfo={orderInfo} />
          {referenceToSearch && (
            <div style={{ alignSelf: 'center' }}>
              <ListFilters
                filters={[{ key: 'reference', value: `Filtrado por Ref.: ${referenceToSearch}` }]}
                onRemove={() => setReferenceToSearch('')}
              />
            </div>
          )}
          <ReferenceImageComponent loading={loading} filteredReferences={filteredReferences} />
          <Footer className="text-center">Copyright © {new Date().getFullYear()}</Footer>
        </Content>
      </Container>
      {/* <Modal
        visible={showModalEmail}
        okButtonProps={{ loading: loading || loadingEmail }}
        cancelButtonProps={{ loading: loadingEmail }}
        okText="Enviar"
        onCancel={() => {
          setShowModalEmail(false);
          setEmailToSendZip('');
        }}
        onOk={() => doRequestToZipImages()}
      >
        <Content>
          Coloque aqui o email para receber as fotos em formato <strong>.zip</strong>
          <Input
            autoFocus
            placeholder="Email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailToSendZip(e.target.value)}
            onPressEnter={() => doRequestToZipImages()}
          ></Input>
        </Content>
      </Modal> */}
      <Modal
        visible={showModalSearch}
        okText="Filtrar"
        onCancel={() => {
          setShowModalSearch(false);
          setReferenceToSearch('');
        }}
        onOk={() => setShowModalSearch(false)}
      >
        <Content>
          Filtrar por referência
          <Input
            autoFocus
            placeholder="Código da referência"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReferenceToSearch(e.target.value)}
            value={referenceToSearch}
            onPressEnter={() => setShowModalSearch(false)}
          ></Input>
        </Content>
      </Modal>
    </>
  );
};

export default OrderImagePage;
