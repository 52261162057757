import { Brand } from '@models/Brand';
import { Response } from '@models/Response';
import * as api from './api';

export function get(): Promise<Response<Brand>> {
  return api.get('brands/available');
}

export function saveClassifications(brandId: number, body: any): Promise<any> {
  return api.post(`brands/${brandId}/classifications`, body);
}
