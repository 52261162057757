import { ExclamationCircleOutlined, InfoCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { useDevolution } from '@hooks/DevolutionsContext';
import { Devolution, DevolutionObservation } from '@models/Devolution';
import { InvoiceItem } from '@models/InvoiceItem';
import { DevolutionItemObservationStatusEnum } from '@models/enum/DevolutionStatusEnum';
import { CustomButton } from '@styles/globals';
import { Col, Form, Modal, Row, Switch, Table, Typography, notification } from 'antd';
import moment from 'moment';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CustomInput } from '../../styles';
const { Title } = Typography;

interface DevolutionRequestInvoiceDataFormProps {
  formValues?: { devolution: Devolution; quantityToReturn: any; observationToReturn: any };
  setFormValues: Function;
  handleSubmit: Function;
}

const DevolutionRequestInvoiceDataForm: React.FC<DevolutionRequestInvoiceDataFormProps> = ({
  formValues,
  setFormValues,
  handleSubmit,
}) => {
  const { currentDevolution, setCurrentDevolution, loadingDevolutions, loadByCodeAndCnpj } = useDevolution();

  const [searchDevolutionForm] = Form.useForm();
  const [devolutionForm] = Form.useForm();

  const [products, setProducts] = React.useState<InvoiceItem[]>([]);
  const [history, setHistory] = React.useState<DevolutionObservation[]>([]);

  const [sentJustOneTime, setSentJustOneTime] = React.useState<boolean>(false);
  const [sentJustOneTimeError, setSentJustOneTimeError] = React.useState<boolean>(false);

  const [selectItemError, setSelectItemError] = React.useState<boolean>(false);

  const [totalInvoiceParts, setTotalInvoiceParts] = React.useState<number>(0);
  const [totalQuantityToReturn, setTotalQuantityToReturn] = React.useState<number>(0);
  const [totalQuantityToReturnPercent, setTotalQuantityToReturnPercent] = React.useState<number>(0);

  const [returnValidators, setReturnValidators] = React.useState<any>({});

  React.useEffect(() => {
    searchDevolutionForm.setFields([
      { name: 'cnpj', value: null },
      { name: 'invoiceCode', value: null },
    ]);

    devolutionForm.setFields([
      { name: 'integrationId', value: null },
      { name: 'branchCode', value: null },
      { name: 'email', value: null },
      { name: 'phoneNumber', value: null },
      { name: 'status', value: null },
      { name: 'sequence', value: null },
      { name: 'observations', value: null },
      { name: 'invoiceItemObservations', value: null },
      { name: 'quantityToReturn', value: null },
      { name: 'observationToReturn', value: null },
    ]);

    if (formValues) {
      searchDevolutionForm.setFieldsValue({
        cnpj: formValues?.devolution?.cnpj,
        invoiceCode: formValues?.devolution?.invoiceCode,
      });

      devolutionForm.setFieldsValue({
        integrationId: formValues?.devolution?.integrationId,
        branchCode: formValues?.devolution?.branchCode,
        email: formValues?.devolution?.email,
        phoneNumber: formValues?.devolution?.phoneNumber,
        status: formValues?.devolution?.status,
        sequence: formValues?.devolution?.sequence,
        observations: formValues?.devolution?.observations,
        invoiceItemObservations: formValues?.devolution?.invoiceItemObservations,
        quantityToReturn: formValues?.quantityToReturn,
        observationToReturn: formValues?.observationToReturn,
      });

      setSentJustOneTime(true);
      calculatePercent();
    }
  }, []);

  React.useEffect(() => {
    const loadByParams = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const invoiceCode = urlParams.get('invoiceCode');
      const cnpj = urlParams.get('cnpj');

      if (invoiceCode) {
        searchDevolutionForm.setFieldsValue({
          invoiceCode: invoiceCode,
        });
      }
      if (cnpj) {
        searchDevolutionForm.setFieldsValue({
          cnpj: cnpj,
        });
      }

      if (invoiceCode && cnpj) await loadByCodeAndCnpj(invoiceCode, cnpj);
    };

    loadByParams();
  }, []);

  React.useEffect(() => {
    if (currentDevolution) {
      setProducts(currentDevolution?.invoice?.items || []);
      setHistory(
        currentDevolution?.observations?.map((obser: any) => {
          return {
            ...obser,
            ...obser?.collaborator,
            collaboratorName: (obser?.collaborator?.firstName || '') + ' ' + (obser?.collaborator?.lastName || ''),
          };
        }) || [],
      );

      let total = 0;
      currentDevolution?.invoice?.items?.forEach((item, index) => {
        total += Number.isInteger(item.quantity) ? item?.quantity : Math.trunc(item?.quantity);

        setReturnValidators((prevValidators: any) => ({
          ...prevValidators,
          [index]: false,
        }));
      });
      setTotalInvoiceParts(total);

      if (currentDevolution?.sequence) {
        const part: any = currentDevolution.totalItemDevolutionQuantity;
        setTotalQuantityToReturn(part);

        const percent = +((part / total) * 100).toFixed(2);
        setTotalQuantityToReturnPercent(percent);
      }

      if (currentDevolution?.sequence) {
        devolutionForm.setFieldsValue({
          integrationId: currentDevolution?.integrationId,
          branchCode: currentDevolution?.branchCode,
          email: currentDevolution?.email,
          phoneNumber: currentDevolution?.phoneNumber,
          status: currentDevolution?.status,
          sequence: currentDevolution?.sequence,
          observations: currentDevolution?.observations,
          invoiceItemObservations: currentDevolution?.invoiceItemObservations,
          quantityToReturn: currentDevolution?.invoiceItemObservations.map((item) => {
            return { quantity: +item?.itemDevolutionQuantity !== 0 ? +item?.itemDevolutionQuantity : undefined };
          }),
          observationToReturn: currentDevolution?.invoiceItemObservations.map((item) => {
            return { observation: item?.observation };
          }),
        });
        formatPhone(currentDevolution?.phoneNumber?.replace('+55', ''));
      }
    }
  }, [currentDevolution]);

  const calculatePercent = () => {
    const quantityToReturn = devolutionForm
      .getFieldValue('quantityToReturn')
      ?.map((qtd: any) => +qtd?.quantity)
      .filter((qtd: any) => {
        if (typeof qtd === 'number' && qtd >= 0) {
          return qtd;
        }
      });
    const part: any = Object.values(quantityToReturn)?.reduce((a: any, b: any) => +a + +b, 0);
    setTotalQuantityToReturn(part);

    const total = totalInvoiceParts;
    const percent = +((part / total) * 100).toFixed(2);

    setTotalQuantityToReturnPercent(percent);
  };

  const submit = async () => {
    try {
      if (
        !devolutionForm.getFieldValue('quantityToReturn')?.filter((qtd: any) => qtd?.quantity !== undefined)?.length &&
        !devolutionForm.getFieldValue('observationToReturn')?.filter((obs: any) => obs?.observation !== undefined)
          ?.length
      ) {
        notification.warning({
          message: 'Aviso',
          description: `Adicione pelo menos um item para ser devolvido.`,
        });
        setSelectItemError(true);
        return;
      } else {
        setSelectItemError(false);
      }

      const searchValues = await searchDevolutionForm.validateFields();
      const values = await devolutionForm.validateFields();

      values.cnpj = searchValues?.cnpj;
      values.invoiceCode = searchValues?.invoiceCode;

      if (!sentJustOneTime) {
        setSentJustOneTimeError(true);
        return;
      } else {
        setSentJustOneTimeError(false);
      }

      const formData = {
        devolution: values,
        quantityToReturn: values?.quantityToReturn,
        observationToReturn: values?.observationToReturn,
      };

      setFormValues(formData);

      const integrationId = Number(
        currentDevolution?.integrationId ? currentDevolution?.integrationId : currentDevolution?.invoice?.integrationId,
      );
      const branchCode = Number(
        currentDevolution?.branchCode ? currentDevolution?.branchCode : currentDevolution?.invoice?.branchCode,
      );

      const invoiceItemObservations: any = [];
      products?.forEach((product, index) => {
        invoiceItemObservations.push({
          cnpj: values?.cnpj?.replace(/\D/g, ''),
          invoiceCode: Number(values?.invoiceCode),
          integrationId: integrationId,
          branchCode: branchCode,
          invoiceSequence: currentDevolution?.invoice?.invoiceSequence,
          invoiceDate: currentDevolution?.invoice?.invoiceDate,
          itemSequence: Number(product?.sequence),
          observation: values?.observationToReturn?.[index]?.observation,
          itemDevolutionQuantity: Number(values?.quantityToReturn[index]?.quantity) || 0,
        });
      });

      const devolution = {
        email: values?.email,
        cnpj: values?.cnpj?.replace(/\D/g, ''),
        phoneNumber: '+55' + values?.phoneNumber?.replace(/\D/g, ''),
        invoiceCode: Number(values.invoiceCode),
        integrationId: integrationId,
        branchCode: branchCode,
        invoiceItemObservations: invoiceItemObservations,
        observations:
          currentDevolution?.observations?.map((obs) => {
            return { ...obs, invoiceCode: +obs?.invoiceCode };
          }) || [],
        ...(currentDevolution?.sequence && { sequence: Number(currentDevolution?.sequence) }),
      };

      handleSubmit(devolution);
    } catch (e) {
      console.error(e);
    }
  };

  const clearFilters = async () => {
    searchDevolutionForm.setFieldsValue({
      cnpj: '',
      invoiceCode: '',
    });

    setCurrentDevolution(undefined);
    setProducts([]);
    setHistory([]);
  };

  const searchDevolution = async () => {
    const values = await searchDevolutionForm.validateFields();
    await loadByCodeAndCnpj(values?.invoiceCode, values?.cnpj.replace(/\D/g, ''));
    searchDevolutionForm.setFieldsValue({
      ...values,
    });
  };

  const showModalConfirmation = (event: any) => {
    event.preventDefault();
    Modal.confirm({
      title: 'Solicitar análise',
      icon: <ExclamationCircleOutlined />,
      content:
        'Deseja solicitar a análise de devolução? Esta Nota Fiscal só pode ser devolvida uma vez. Após clicar em confirmar, enviaremos os próximos passos via E-mail e WhatsApp.',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk() {
        submit();
      },
    });
  };

  const showSeeMoreModal = (value: string) => {
    Modal.info({
      content: value,
      icon: null,
      cancelText: 'Fechar',
    });
  };

  const formatCnpj = (value: string) => {
    if (value) {
      const cnpjRegex = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
      const numericValue = value.replace(/\D/g, '');

      if (numericValue.length === 14) {
        searchDevolutionForm.setFieldsValue({
          cnpj: value.replace(cnpjRegex, '$1.$2.$3/$4-$5'),
        });
      } else {
        searchDevolutionForm.setFieldsValue({
          cnpj: numericValue,
        });
      }
    }
  };

  const formatPhone = (value: string | any) => {
    if (value) {
      const numericValue = value.replace(/\D/g, '');

      if (numericValue.length <= 11) {
        const phoneRegex = /^(\d{2})(\d{4})(\d{4})$/;
        const phoneWithDigitRegex = /^(\d{2})(\d{5})(\d{4})$/;
        let formatedPhone = numericValue.replace(phoneRegex, '($1) $2-$3');
        if (numericValue.length > 8) {
          formatedPhone = numericValue.replace(phoneWithDigitRegex, '($1) $2-$3');
        }
        devolutionForm.setFieldsValue({
          phoneNumber: formatedPhone,
        });
      } else {
        devolutionForm.setFieldsValue({
          phoneNumber: numericValue.substring(0, 11),
        });
      }
    }
  };

  const buildUserType = (type?: string) => {
    if (type === 'COMMERCIAL') return <span>Comercial</span>;
    if (type === 'ACCOUNTING') return <span>Contabilidade</span>;
    if (type === 'ADMIN') return <span>Administração</span>;
    if (type === 'USER') return <span>Usuário</span>;
  };

  const buildStatusTag = (status: string) => {
    const style = {
      borderRadius: 5,
      padding: '5px 15px',
      margin: 0,
      width: 120,
      color: '#973D20',
      backgroundColor: '#FFF0EB',
      border: '1px solid #973D20',
    };

    if (status === DevolutionItemObservationStatusEnum.APPROVED) {
      return (
        <p
          style={{
            ...style,
            color: '#2f7324',
            backgroundColor: '#dcfad7',
            border: '1px solid #2f7324',
          }}
        >
          Aprovado
        </p>
      );
    }

    if (status === DevolutionItemObservationStatusEnum.REJECTED) {
      return (
        <p
          style={{
            ...style,
            color: '#973D20',
            backgroundColor: '#FFF0EB',
            border: '1px solid #973D20',
          }}
        >
          Reprovado
        </p>
      );
    }
  };

  if (loadingDevolutions) {
    return (
      <div
        style={{ width: '1200px', height: '1000px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <LoadingOutlined
          type="play-circle-o"
          style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
        />
      </div>
    );
  }

  function gerarRegex(maxNumber: number) {
    if (Number.isInteger(maxNumber)) {
      const regexStr = `^(1?\\d|' + ${maxNumber} + ')$`;
      return new RegExp(regexStr);
    } else {
      const intNumber = Math.trunc(maxNumber);
      const regexStr = `^(1?\\d|' + ${intNumber} + ')$`;
      return new RegExp(regexStr);
    }
  }

  const validateQuantity = (number: number, maxValue: number, index: number) => {
    const values = devolutionForm.getFieldValue('quantityToReturn');

    if (number > maxValue) {
      values[index].quantity = maxValue;
    } else if (number < 0) {
      values[index].quantity = 1;
    } else if (number === 0) {
      values.splice(index, 1);
    }

    devolutionForm.setFieldsValue({
      quantityToReturn: values,
    });

    calculatePercent();
  };

  const addReturnValidator = (index: number) => {
    setReturnValidators((prevValidators: any) => ({
      ...prevValidators,
      [index]: true,
    }));
  };

  const removeReturnValidator = (index: number) => {
    setReturnValidators((prevValidators: any) => ({
      ...prevValidators,
      [index]: false,
    }));
  };

  const historyColumns = [
    {
      title: 'Departamento',
      render: (obs: DevolutionObservation | any) => {
        return <>{buildUserType(obs?.collaboratorId ? obs?.type : obs?.userType)}</>;
      },
    },
    {
      title: 'Responsável',
      align: 'center',
      render: (obs: DevolutionObservation | any) => {
        return <> {obs?.collaboratorId ? obs?.collaboratorName : obs?.userName} </>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render: (status: string) => {
        return (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {buildStatusTag(status)}
          </div>
        );
      },
    },
    {
      title: 'Observação',
      dataIndex: 'observation',
      width: '350px',
      align: 'left',
      render: (value: string) => {
        return (
          <>
            {value?.length > 80 ? (
              <div>
                <span>{value.substring(0, 80)}...</span>
                <span
                  style={{
                    color: '#267AFA',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    marginLeft: '5px',
                  }}
                  onClick={() => showSeeMoreModal(value)}
                >
                  Ver tudo
                </span>
              </div>
            ) : (
              <span>{value}</span>
            )}
          </>
        );
      },
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      align: 'left',
      render: (createdAt: string) => <span>{moment.utc(createdAt).format('DD/MM/YYYY')}</span>,
    },
  ];

  return (
    <div style={{ minWidth: '850px' }}>
      <Form
        form={searchDevolutionForm}
        className="d-flex"
        style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}
      >
        <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Col span={12} style={{ paddingRight: 15 }}>
            <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
              CNPJ <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
            </Title>
            <Form.Item
              name="cnpj"
              rules={[
                {
                  required: true,
                  message: 'Informe o CNPJ',
                },
              ]}
            >
              <CustomInput
                maxLength={18}
                style={{ width: '100%' }}
                placeholder="CNPJ"
                onChange={(e) => {
                  formatCnpj(e.target.value);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={12} style={{ paddingLeft: 15 }}>
            <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
              Número da nota fiscal <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
            </Title>
            <Form.Item
              name="invoiceCode"
              rules={[
                {
                  required: true,
                  message: 'Informe o número da nota fiscal',
                },
              ]}
            >
              <CustomInput style={{ width: '100%' }} placeholder="Número da nota fiscal" />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Form
        form={devolutionForm}
        className="d-flex"
        style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}
      >
        <Row className="d-flex" style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Col span={8}>
            <CustomButton type="primary" style={{ marginBottom: 40 }} onClick={searchDevolution}>
              Buscar NF
            </CustomButton>
          </Col>
        </Row>

        {currentDevolution && products?.length > 0 && (
          <>
            <Row className="d-flex" style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'start' }}>
              <Col span={24}>
                <Row style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h2 style={{ fontWeight: 'bold', paddingBottom: '10px', display: 'flex' }}>
                    Itens <p style={{ color: 'red', paddingLeft: '5px', marginBottom: 0 }}>*</p>
                  </h2>
                  <div className="d-flex">
                    <p style={{ color: '#4F4F4F' }}>{products?.length + ' ' + '(s) encontradas'}</p>
                    <p
                      style={{
                        color: '#512874',
                        textDecoration: 'underline',
                        paddingLeft: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={clearFilters}
                    >
                      Limpar filtros
                    </p>
                  </div>
                </Row>
                <Row
                  style={{
                    width: '100%',
                    height: 90,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    backgroundColor: '#F8F8F8',
                    marginBottom: '30px',
                  }}
                >
                  <div
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
                  >
                    <p style={{ color: '#777777', marginBottom: '5px', padding: '0 10px' }}>
                      Qtd. total de peças da nota fiscal
                    </p>
                    <p style={{ margin: 0 }}>{totalInvoiceParts}</p>
                  </div>
                  <div
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
                  >
                    <p style={{ color: '#777777', marginBottom: '5px', padding: '0 10px' }}>
                      Qtd. total de selecionadas para devolução
                    </p>
                    <p style={{ margin: 0 }}>{totalQuantityToReturn}</p>
                  </div>
                  <div
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
                  >
                    <p style={{ color: '#777777', marginBottom: '5px', padding: '0 10px' }}>
                      Porcentagem de peças selecionadas para devolução
                    </p>
                    <p style={{ margin: 0 }}>{totalQuantityToReturnPercent}%</p>
                  </div>
                </Row>

                <div
                  style={{
                    paddingTop: '75px',
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      height: '75px',
                      backgroundColor: '#666666',
                      color: 'white',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                      fontSize: '15px',
                    }}
                  >
                    <div
                      style={{
                        padding: '10px',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        width: '50%',
                      }}
                    >
                      Descrição
                    </div>
                    <div
                      style={{
                        padding: '10px',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '10%',
                      }}
                    >
                      Qtde.
                    </div>
                    <div
                      style={{
                        padding: '10px',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '15%',
                        textAlign: 'center',
                      }}
                    >
                      Qtde. de devolução
                    </div>
                    <div
                      style={{
                        padding: '10px',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '25%',
                      }}
                    >
                      Observações
                    </div>
                  </div>

                  <div className="interleaved-table-style" style={{ maxHeight: '400px', overflow: 'auto' }}>
                    <table style={{ width: '100%' }}>
                      {products?.length > 0 &&
                        products.map((product: InvoiceItem, index: number) => {
                          return (
                            <tr
                              key={product?.sequence}
                              style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                              <td
                                style={{
                                  padding: '10px',
                                  height: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '50%',
                                }}
                              >
                                <p style={{ textAlign: 'left', width: '100%', margin: 0 }}>{product?.name}</p>
                              </td>
                              <td
                                style={{
                                  padding: '10px',
                                  height: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                  textAlign: 'center',
                                }}
                              >
                                {product?.quantity}
                              </td>
                              <td
                                style={{
                                  padding: '10px',
                                  height: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '15%',
                                  textAlign: 'center',
                                }}
                              >
                                <Row
                                  className="d-flex"
                                  style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}
                                >
                                  <Col span={24}>
                                    <Form.Item
                                      style={{ marginBottom: 0 }}
                                      name={[`quantityToReturn`, index, 'quantity']}
                                      rules={[
                                        {
                                          required: returnValidators[index] ? true : false,
                                          message: 'Informe a quantidade',
                                        },
                                      ]}
                                    >
                                      <CustomInput
                                        disabled={product?.quantity < 1}
                                        onKeyUp={(e: any) => {
                                          const maxValue = Number.isInteger(product?.quantity)
                                            ? product?.quantity
                                            : Math.trunc(product?.quantity);
                                          const currentValue = +e.target.value + '';

                                          if (
                                            !/^\d$/.test(e.key) &&
                                            e.key !== 'Backspace' &&
                                            e.key !== 'ArrowLeft' &&
                                            e.key !== 'ArrowRight'
                                          ) {
                                            e.preventDefault();
                                            return;
                                          }

                                          if (
                                            currentValue.length >= maxValue.toString().length &&
                                            e.key !== 'Backspace'
                                          ) {
                                            e.preventDefault();
                                            return;
                                          }
                                        }}
                                        type="number"
                                        style={{ width: '100%' }}
                                        placeholder="0"
                                        max={
                                          Number.isInteger(product?.quantity)
                                            ? product?.quantity
                                            : Math.trunc(product?.quantity)
                                        }
                                        min={1}
                                        onChange={(e: any) => {
                                          const qtd = Number.isInteger(product?.quantity)
                                            ? product?.quantity
                                            : Math.trunc(product?.quantity);
                                          validateQuantity(+e?.target?.value, qtd, index);

                                          if (+e?.target?.value !== 0) {
                                            addReturnValidator(index);
                                          } else if (
                                            !devolutionForm.getFieldValue('observationToReturn')?.[index]?.observation
                                          ) {
                                            removeReturnValidator(index);
                                          }
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </td>
                              <td
                                style={{
                                  padding: '10px',
                                  height: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '25%',
                                  textAlign: 'center',
                                }}
                              >
                                <Row style={{ width: '100%' }}>
                                  <Col span={24}>
                                    <Form.Item
                                      style={{ marginBottom: 0 }}
                                      name={[`observationToReturn`, index, 'observation']}
                                      rules={[
                                        {
                                          required: returnValidators[index] ? true : false,
                                          message: 'Informe a observação',
                                        },
                                      ]}
                                    >
                                      <CustomInput
                                        disabled={product?.quantity < 1}
                                        placeholder="Observação"
                                        style={{ width: '100%' }}
                                        onChange={(event) => {
                                          if (event?.target?.value) {
                                            addReturnValidator(index);
                                          } else if (
                                            !event?.target?.value &&
                                            (!devolutionForm.getFieldValue('quantityToReturn')?.[index]?.quantity ||
                                              devolutionForm.getFieldValue('quantityToReturn')?.[index]?.quantity === 0)
                                          ) {
                                            removeReturnValidator(index);
                                          }
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          );
                        })}
                    </table>
                  </div>
                </div>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
                  {selectItemError && (
                    <p style={{ color: '#ff4d4f', margin: 0 }}>
                      Adicione a quantidade a ser devolvida e a observação para pelo menos um item.
                    </p>
                  )}
                </div>
              </Col>
            </Row>

            <Row
              className="d-flex"
              style={{ width: '100%', marginTop: '30px', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Col span={12} style={{ paddingRight: 15 }}>
                <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                  E-mail <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                </Title>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: 'Informe o E-mail',
                    },
                  ]}
                >
                  <CustomInput style={{ width: '100%' }} placeholder="E-mail" />
                </Form.Item>
              </Col>

              <Col span={12} style={{ paddingLeft: 15 }}>
                <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                  WhatsApp <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                </Title>
                <Form.Item
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Informe o WhatsApp',
                    },
                  ]}
                >
                  <CustomInput
                    maxLength={15}
                    onChange={(e) => {
                      formatPhone(e.target.value);
                    }}
                    style={{ width: '100%' }}
                    placeholder="WhatsApp"
                  />
                </Form.Item>
              </Col>
            </Row>

            {currentDevolution?.sequence === undefined && (
              <Row style={{ width: '100%' }}>
                <div
                  className="d-flex"
                  style={{ width: '100%', marginTop: '10px', justifyContent: 'start', alignItems: 'center' }}
                >
                  <Switch
                    checked={sentJustOneTime}
                    onChange={(value: boolean) => {
                      setSentJustOneTime(value);
                    }}
                  />
                  <p style={{ margin: 0, marginLeft: 10 }}>
                    Estou ciente que posso devolver esta Nota Fiscal apenas uma vez.
                  </p>
                </div>

                {sentJustOneTimeError && <p style={{ color: '#ff4d4f' }}>Esse campo deve ser preenchido</p>}
              </Row>
            )}

            {history.length > 0 && (
              <Row
                className="d-flex interleaved-table-style"
                style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'start', marginTop: '30px' }}
              >
                <Row style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h2 style={{ fontWeight: 'bold', paddingBottom: '10px' }}>Histórico de observações</h2>
                </Row>
                <Col span={24}>
                  <Table
                    size="middle"
                    showHeader={true}
                    pagination={false}
                    dataSource={history}
                    loading={loadingDevolutions}
                    rowKey={() => uuidv4()}
                    scroll={{ y: 400 }}
                    columns={historyColumns as any}
                  />
                </Col>
              </Row>
            )}

            {currentDevolution?.sequence !== undefined && (
              <p
                style={{
                  width: '100%',
                  height: '52px',
                  borderRadius: '10px',
                  borderColor: '#d9d9d9',
                  background: '#f5f5f5',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '50px',
                  fontSize: 17,
                }}
              >
                <InfoCircleFilled
                  className="ml-4"
                  style={{ color: 'var(--primary-color)', marginRight: '5px', marginBottom: '2px' }}
                />
                Essa nota fiscal já está em processo de devolução, portanto não será possível enviar a solicitação
                novamente.
              </p>
            )}

            <CustomButton
              disabled={currentDevolution?.sequence !== undefined}
              type="primary"
              style={{ marginTop: currentDevolution?.sequence === undefined ? 40 : 25, width: '100%' }}
              onClick={(e) => showModalConfirmation(e)}
            >
              Solicitar análise de devolução
            </CustomButton>
          </>
        )}
      </Form>
    </div>
  );
};

export default DevolutionRequestInvoiceDataForm;
