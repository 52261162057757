import { Col, Form, Row, Select, Steps, Typography } from 'antd';
import React, { useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { useBrand } from '@hooks/BrandContext';
import { useCollection } from '@hooks/CollectionContext';
import { usePriceTable } from '@hooks/PriceTableContext';
import { Color } from '@models/Color';
import { PriceTable, PriceTableItem } from '@models/PriceTable';
import { Product } from '@models/Product';
import { Reference } from '@models/Reference';
import { CustomButton } from '@styles/globals';
import { PriceTableReferenceData, SharedData } from './models/price-table-form-models';
import PriceTableHeaderStep from './steps/PriceTableHeaderStep';
import PriceTableStep from './steps/PriceTableStep';
import { Container, CustomInput, StepsContainer } from './styles';

const { Title, Text } = Typography;
const { Step } = Steps;

const steps = [
  {
    title: 'Cabeçalho',
    content: () => null,
  },
  {
    title: 'Preços',
    content: () => null,
  },
];

interface PriceTableFormProps {
  onCloseModal: Function;
  isEditing: boolean;
  priceTableToEdit?: PriceTable;
}

const PriceTableForm: React.FC<PriceTableFormProps> = ({
  onCloseModal,
  isEditing = false,
  priceTableToEdit = null,
}) => {
  const { loadAllCollections } = useCollection();
  const { loadBrands } = useBrand();

  const {
    allPriceTables,
    createPriceTableHeader,
    updatePriceTableHeader,
    loadPriceTableMerpDetails,
    currentPriceTable,
  } = usePriceTable();
  const [currentStep, setCurrentStep] = useState(0);

  const [loading, setLoading] = useState<boolean>(false);

  const [sharedData, setSharedData] = React.useState<SharedData | any>();
  const [priceTableHeaderFormValues, setPriceTableHeaderFormValues] = React.useState<PriceTable | any>();
  const [priceTableReferencesFormValues, setPriceTableReferencesFormValues] = React.useState<any>();

  const [priceTableItems, setPriceTableItems] = React.useState<any>();

  const [copyPriceTableForm] = Form.useForm();
  const [selectedCopyTableCode, setSelectedCopyTableCode] = React.useState<number>();
  const [selectedCopyVariationPercent, setSelectedCopyVariationPercent] = React.useState<number | undefined>(0);

  React.useEffect(() => {
    loadAllCollections();
    loadBrands();

    if (isEditing) {
      const loadDataToEdit = async () => {
        setLoading(true);
        await loadPriceTableMerpDetails(priceTableToEdit?.code);
        setLoading(false);
      };

      loadDataToEdit();
    }

    if (!isEditing) {
      copyPriceTableForm.setFields([
        { name: 'priceTableCode', value: null },
        { name: 'variationPercent', value: null },
      ]);
    }
  }, []);

  React.useEffect(() => {
    if (
      currentPriceTable &&
      (currentPriceTable?.code === priceTableToEdit?.code || currentPriceTable?.code === selectedCopyTableCode) &&
      (isEditing || selectedCopyTableCode)
    ) {
      setPriceTableHeaderFormValues({
        ...currentPriceTable,
        ...(selectedCopyTableCode && { code: '' }),
        ...(selectedCopyTableCode && { description: currentPriceTable?.description + ' (Cópia)' }),
      });

      const priceTableItems: PriceTableItem[] = currentPriceTable?.priceTable as any;

      if (priceTableItems?.length) {
        setPriceTableItems(priceTableItems);
        const pricesPerReference = groupByReferenceCode(priceTableItems);
        let priceTables: number[][][] = currentPriceTable?.references?.map((reference: Reference) => {
          return createPriceTable(pricesPerReference[reference?.ReferenceCode]);
        });

        if (selectedCopyTableCode && selectedCopyVariationPercent) {
          priceTables = applyVariationPercentageValue(priceTables, selectedCopyVariationPercent);
        }

        const tableData: {
          references: Reference[];
          referencesTableData: PriceTableReferenceData[];
          prices: number[][][];
          refs: any[];
        } = {
          references: currentPriceTable?.references,
          referencesTableData: currentPriceTable?.references?.map((reference: Reference, index: number) => {
            const tableColumn = reference?.colors?.map((color: Color) => color?.name) || [];
            const tableRow = reference?.grid || [];

            return {
              colHeaders: tableColumn,
              rowHeaders: tableRow,
              cellsData: priceTables[index],
            };
          }),
          prices: priceTables,
          refs: [],
        };

        setPriceTableReferencesFormValues(tableData);
      }
    }
  }, [currentPriceTable]);

  function applyVariationPercentageValue(priceTables: number[][][], variacaoPercentual: number): number[][][] {
    for (let i = 0; i < priceTables.length; i++) {
      for (let j = 0; j < priceTables[i].length; j++) {
        for (let k = 0; k < priceTables[i][j].length; k++) {
          priceTables[i][j][k] = +(priceTables[i][j][k] * (1 + variacaoPercentual / 100)).toFixed(2);
        }
      }
    }

    return priceTables;
  }

  const groupByReferenceCode = (prices: PriceTableItem[]) => {
    const groupedData: any = {};
    prices.forEach((item) => {
      const referenceCode = item?.referenceCode;
      if (!(referenceCode in groupedData)) {
        groupedData[referenceCode] = [];
      }
      groupedData[referenceCode].push(item);
    });
    return groupedData;
  };

  const createPriceTable = (prices: PriceTableItem[]) => {
    const colorMap: any = {};
    let colorIndex = 0;
    prices?.forEach((item) => {
      if (!(item?.colorCode in colorMap)) {
        colorMap[item?.colorCode] = colorIndex++;
      }
    });

    const sizeMap: any = {};
    let sizeIndex = 0;
    prices?.forEach((item) => {
      if (!(item?.sizeName in sizeMap)) {
        sizeMap[item?.sizeName] = sizeIndex++;
      }
    });

    const numRows = Object.keys(sizeMap)?.length;
    const numCols = Object.keys(colorMap)?.length;
    const priceTable: number[][] = Array(numRows)
      .fill(0)
      .map(() => Array(numCols).fill(0));

    prices?.forEach((item) => {
      const row = sizeMap[item?.sizeName];
      const col = colorMap[item?.colorCode];

      priceTable[row][col] = item?.prices?.[0]?.price;
    });

    return priceTable;
  };

  const copyPriceTable = async () => {
    const values = { ...(await copyPriceTableForm.validateFields()) };

    setSelectedCopyTableCode(values.priceTableCode);
    setSelectedCopyVariationPercent(+values?.variationPercent?.replace(',', '.'));

    const loadDataToEdit = async () => {
      setLoading(true);
      await loadPriceTableMerpDetails(values?.priceTableCode);
      setLoading(false);
    };

    loadDataToEdit();

    copyPriceTableForm.setFieldsValue({
      priceTableCode: null,
      variationPercent: null,
    });
  };

  const goToNextStep = (values = null) => {
    if (currentStep === 1) {
      hadleSubmit(values);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const goToBackStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const changeSharedData = (value: any) => {
    if (value?.gridCode !== sharedData?.gridCode) {
      setPriceTableReferencesFormValues(null);
    }

    setSharedData({ ...sharedData, ...value });
  };

  const hadleSubmit = async (values = priceTableReferencesFormValues) => {
    setLoading(true);

    const priceTableHeaderBody = removeEmptyProperties({
      integrationId: Number(localStorage.getItem('@modavest/integration_id')),
      code: +priceTableHeaderFormValues?.code,
      description: priceTableHeaderFormValues?.description,
      type: priceTableHeaderFormValues?.type,
      startDate: priceTableHeaderFormValues?.startDate,
      endDate: priceTableHeaderFormValues?.endDate,
      variationPercentage: +priceTableHeaderFormValues?.variationPercentage,
      variationValue: +priceTableHeaderFormValues?.variationValue,
      observation: priceTableHeaderFormValues?.observation,
    });

    const priceTablesBody: any[] = [];
    const referencesFormValues = values ? values : priceTableReferencesFormValues;
    const prices = referencesFormValues.prices;

    referencesFormValues?.references.forEach((reference: Reference, referenceIndex: number) => {
      const tableColumn = reference?.colors || [];

      tableColumn?.forEach((column: Color, columnIndex: number) => {
        const tableRow = reference?.colors[columnIndex]?.products || [];
        tableRow?.forEach((row: Product, rowIndex: number) => {
          const price = prices[referenceIndex]?.[rowIndex]?.[columnIndex];

          let referenceId;
          if (isEditing) {
            priceTableItems.forEach((item: PriceTableItem) => {
              if (
                item?.colorName === column?.name &&
                item?.sizeName === row?.size &&
                item?.referenceCode === reference?.ReferenceCode
              ) {
                referenceId = item?.referenceId;
              }
            });
          }

          priceTablesBody.push({
            integrationId: Number(localStorage.getItem('@modavest/integration_id')),
            productCode: row?.code,
            headerCode: priceTableHeaderFormValues?.code,
            productName: row?.name,
            referenceCode: row?.ReferenceCode,
            colorCode: row?.colorCode,
            colorName: column?.name,
            sizeName: row?.size,
            priceTableName: `${reference?.name} - ${row?.size} - ${column?.name}`,
            priceTableItems: [
              {
                price: price,
                originalPrice: price,
              },
            ],
            ...((isEditing || selectedCopyTableCode) && { referenceId: referenceId }),
          });
        });
      });
    });

    try {
      if (isEditing) {
        await updatePriceTableHeader(priceTableHeaderBody, priceTablesBody);
      } else {
        await createPriceTableHeader(priceTableHeaderBody, priceTablesBody);
      }
      onCloseModal();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  function removeEmptyProperties(obj: any) {
    const newObj: any = {};
    for (let key in obj) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }

  const formatPercent = (text: string) => {
    let formatedNumber = '';
    let dotOrCommaFounded = false;
    let negativeSignAllowed = true;

    for (const character of text) {
      if (/[0-9]/.test(character)) {
        formatedNumber += character;
      } else if ((character === ',' || character === '.') && !dotOrCommaFounded) {
        formatedNumber += character;
        dotOrCommaFounded = true;
      } else if (character === '-' && negativeSignAllowed && formatedNumber === '') {
        formatedNumber += character;
        negativeSignAllowed = false;
      }
    }

    copyPriceTableForm.setFieldsValue({
      variationPercent: formatedNumber,
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '50px',
        width: '100%',
      }}
    >
      <StepsContainer progressDot current={currentStep}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </StepsContainer>

      <div className="steps-content">{steps[currentStep].content}</div>

      <div className="steps-action d-flex" style={{ width: '100%' }}>
        <Container
          style={{
            background: '#fff',
            padding: '100px',
            paddingTop: '110px',
            borderRadius: '8px',
            width: '100%',
          }}
        >
          {loading && (
            <div style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <LoadingOutlined
                type="play-circle-o"
                style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
              />
            </div>
          )}

          {!loading && (
            <>
              {(!isEditing || selectedCopyTableCode) && currentStep === 0 && (
                <Form
                  form={copyPriceTableForm}
                  className="d-flex"
                  style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}
                >
                  <Row
                    className="d-flex"
                    style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <Col span={9} style={{ paddingRight: 10 }}>
                      <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                        Tabelas de preço
                      </Title>
                      <Form.Item
                        name="priceTableCode"
                        rules={[
                          {
                            required: true,
                            message: 'Selecione uma tabela para copiar',
                          },
                        ]}
                      >
                        <Select
                          style={{ height: '48px', borderRadius: '8px' }}
                          placeholder="Tabelas de preço"
                          size="large"
                          showSearch
                          allowClear
                          getPopupContainer={(trigger) => trigger.parentNode}
                        >
                          {allPriceTables?.length &&
                            allPriceTables
                              .sort((a, b) => a?.code - b?.code)
                              .map((priceTable: PriceTable) => (
                                <Select.Option key={priceTable.code} value={priceTable.code}>
                                  {priceTable?.code + ' - ' + priceTable.description}
                                </Select.Option>
                              ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={9} style={{ paddingLeft: 10 }}>
                      <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                        Percentual de variação
                      </Title>
                      <Form.Item name="variationPercent">
                        <CustomInput
                          pattern="[0-9]+([,\.][0-9]+)?"
                          style={{ width: '100%' }}
                          placeholder="Percentual de variação"
                          onChange={(e) => formatPercent(e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={6}
                      style={{ paddingLeft: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <CustomButton
                        type="primary"
                        style={{ width: '100%', marginTop: 5, height: '50px' }}
                        onClick={copyPriceTable}
                      >
                        Copiar tabela de preço
                      </CustomButton>
                    </Col>
                  </Row>
                </Form>
              )}

              <>
                {(isEditing || selectedCopyTableCode) && (
                  <>
                    <PriceTableHeaderStep
                      sharedData={sharedData}
                      changeSharedData={changeSharedData}
                      formValues={priceTableHeaderFormValues}
                      setFormValues={setPriceTableHeaderFormValues}
                      goToNextStep={goToNextStep}
                      isEditing={isEditing}
                      render={currentStep === 0}
                    />

                    <PriceTableStep
                      sharedData={sharedData}
                      changeSharedData={changeSharedData}
                      formValues={priceTableReferencesFormValues}
                      setFormValues={setPriceTableReferencesFormValues}
                      goToNextStep={goToNextStep}
                      goToBackStep={goToBackStep}
                      isEditing={isEditing}
                      render={currentStep === 1}
                    />
                  </>
                )}

                {!isEditing && !selectedCopyTableCode && (
                  <>
                    {currentStep === 0 ? (
                      <>
                        <PriceTableHeaderStep
                          sharedData={sharedData}
                          changeSharedData={changeSharedData}
                          formValues={priceTableHeaderFormValues}
                          setFormValues={setPriceTableHeaderFormValues}
                          goToNextStep={goToNextStep}
                          isEditing={isEditing}
                          render={true}
                        />
                      </>
                    ) : null}

                    {currentStep === 1 ? (
                      <PriceTableStep
                        sharedData={sharedData}
                        changeSharedData={changeSharedData}
                        formValues={priceTableReferencesFormValues}
                        setFormValues={setPriceTableReferencesFormValues}
                        goToNextStep={goToNextStep}
                        goToBackStep={goToBackStep}
                        isEditing={isEditing}
                        render={true}
                      />
                    ) : null}
                  </>
                )}
              </>
            </>
          )}
        </Container>
      </div>
    </div>
  );
};

export default PriceTableForm;
