import React from 'react';

import ProfileInformation from '../ProfileInformation';
import ProfileConnections from '../ProfileConnections';

const ProfileTab: React.FC = () => {
  return (
    <>
      <ProfileInformation />
      <ProfileConnections />
    </>
  );
};

export default ProfileTab;
