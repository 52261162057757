import { CustomButton } from '@styles/globals';
import { Form, Input } from 'antd';
import React from 'react';

interface ClassificationFormProps {
  onAdd: Function;
}
const ClassificationForm: React.FC<ClassificationFormProps> = ({ onAdd }) => {
  return (
    <Form layout="vertical" onFinish={(values: any) => onAdd(values)}>
      <Form.Item
        className="mb-2"
        label="Tipo de Classificação"
        name="typeCode"
        rules={[{ required: true, message: 'Informe o Tipo de Classificação.' }]}
      >
        <Input type="number" placeholder="Tipo de Classificação" min={0} />
      </Form.Item>

      <Form.Item
        className="mb-2"
        label="Classificação"
        name="code"
        rules={[{ required: true, message: 'Informe a Classificação.' }]}
      >
        <Input placeholder="Classificação" />
      </Form.Item>

      <CustomButton htmlType="submit" type="primary" className="w-100">
        Adicionar
      </CustomButton>
    </Form>
  );
};

export default ClassificationForm;
