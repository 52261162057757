import * as api from './api';
import * as apiMerp from './api-merp';

export function get(options?: any) {
  return api.get('price_table', options);
}

export function getPriceTableIntegrationFilter(): Promise<any> {
  return api.get('price_table/filter');
}

export function getDetailsByCode(priceTableCode: number): Promise<any> {
  return api.get(`price_table/${priceTableCode}`);
}

export function updatePriceTables(params: any): Promise<any> {
  return api.put('price_table/filter', params);
}

export function updatePriceTableObservation(priceTableCode: number, value: string): Promise<any> {
  return api.patch(`price_table/${priceTableCode}`, { property: 'observation', value });
}

export function createPriceTable(body: any): Promise<any> {
  return apiMerp.post(`price-tables`, body);
}

export function createPriceTableHeader(body: any): Promise<any> {
  return apiMerp.post(`price-table-headers`, body);
}

export function updatePriceTableHeader(body: any): Promise<any> {
  return apiMerp.put(`price-table-headers/${body?.code}`, body);
}


export function getMerpDetailsByCode(code: any): Promise<any> {
  return apiMerp.get(`price-table-headers/${code}`);
}
