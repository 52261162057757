import React from 'react';
import { Table, Typography } from 'antd';
import { useRepresentative } from '@hooks/RepresentativeContext';

const { Title } = Typography;
interface CustomersProps {}

const Customers: React.FC<CustomersProps> = () => {
  const { customers, customersTotal } = useRepresentative();

  const columns = [
    {
      title: 'Código',
      width: '100px',
      align: 'center' as 'center',
      dataIndex: 'code',
      sorter: (clienteA: any, clienteB: any) => clienteA.code - clienteB.code,
    },
    {
      title: 'Lojista',
      dataIndex: 'name',
      sorter: (clienteA: any, clienteB: any) => clienteA.name.trim().localeCompare(clienteB.name.trim()),
    },
  ];

  return (
    <div className="mt-6 ml-4">
      <Title level={5}>Carteira de Clientes</Title>
      <Table
        columns={columns}
        dataSource={customers}
        rowKey={(record) => record.code}
        scroll={{ x: 800 }}
        pagination={{
          pageSize: 10,
          total: customersTotal,
          showSizeChanger: false,
          showQuickJumper: false,
        }}
      />
    </div>
  );
};

export default Customers;
