import React, { useCallback } from 'react';
import { Table, Typography } from 'antd';

import { useSalesRegion } from '@hooks/SalesRegionContext';
import TableFilters from '@components/TableFilters';
import { SearchOutlined } from '@ant-design/icons';
import '../../styles.css';
const { Text } = Typography;

interface CepsTableProps {
  showModal: Function;
  representativeCode: number;
}
const CepsTable: React.FC<CepsTableProps> = ({ showModal, representativeCode }) => {
  const { operationalArea, loadSalesRegionsCustomers } = useSalesRegion();

  const handleCustomersModal = useCallback(
    (cep: string) => {
      loadSalesRegionsCustomers(representativeCode, { cep });
      showModal();
    },
    [showModal, loadSalesRegionsCustomers, representativeCode],
  );

  const columnsCeps = [
    {
      title: 'CEP',
      dataIndex: 'cep',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pelo CEP'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.cep ? record.cep.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
    },
    {
      render: (record: any) => (
        <Text
          style={{ color: 'var(--primary-color)', fontWeight: 'bold', cursor: 'pointer' }}
          onClick={() => handleCustomersModal(record.cep)}
        >
          Ver clientes
        </Text>
      ),
      align: 'right' as 'right',
    },
  ];

  return (
    <>
      {operationalArea.operationalAreaCeps?.length > 0 && (
        <Table
          className="mt-8 custom-table"
          columns={columnsCeps}
          dataSource={operationalArea.operationalAreaCeps}
          pagination={{ showSizeChanger: false, style: { marginTop: '24px' }, hideOnSinglePage: false }}
          scroll={{ x: 800 }}
        />
      )}
    </>
  );
};

export default CepsTable;
