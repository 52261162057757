import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import * as BrandService from '@services/brand';
import { Brand } from '@models/Brand';
import { message } from 'antd';

interface BrandState {
  brands: Brand[];
  loading: boolean;
  loadBrands: Function;
  saveBrandClassifications: Function;
}

interface BrandProviderProps {
  children: ReactNode;
}

export const BrandContext = createContext<BrandState>({} as BrandState);

const BrandProvider: React.FC<BrandProviderProps> = ({ children }) => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadBrands = useCallback(async () => {
    setLoading(true);

    try {
      const { data: _brands } = await BrandService.get();
      setBrands(_brands);
    } catch (e: any) {
      if (e.response && e.response.status === 404) {
        message.info('Você não possui Marcas!');
      }
    } finally {
      setLoading(false);
    }
  }, [setBrands, setBrands]);

  const loadAllBrands = useCallback(async () => {
    setLoading(true);

    try {
      const { data: _brands } = await BrandService.get();
      setBrands(_brands);
    } catch (e: any) {
      if (e.response && e.response.status === 404) {
        message.info('Você não possui Marcas!');
      }
    } finally {
      setLoading(false);
    }
  }, [setBrands, setBrands]);

  const saveBrandClassifications = useCallback(
    async (brandId: number, newBrands: Brand[]) => {
      setLoading(true);

      try {
        const { data: _brands } = await BrandService.saveClassifications(brandId, newBrands);

        const index = brands.findIndex((brand) => brand.brandId === brandId);

        if (index !== -1) {
          const otherClassificationTypesBrands = brands[index].classifications.filter(
            (classification) => classification.classificationType !== _brands[0].classificationType,
          );

          const classificationsBrands = [...otherClassificationTypesBrands, ..._brands];

          brands[index].classifications = classificationsBrands;
          setBrands(brands);
        }

        message.success('Classificações alteradas com sucesso!');
      } catch {
        message.error('Erro ao alterar classificações!');
      } finally {
        setLoading(false);
      }
    },
    [brands, setBrands, setBrands],
  );
  return (
    <BrandContext.Provider
      value={{
        brands,
        loading,
        loadBrands,
        saveBrandClassifications,
      }}
    >
      {children}
    </BrandContext.Provider>
  );
};

const useBrand = () => {
  return useContext(BrandContext);
};

export { BrandProvider, useBrand };
