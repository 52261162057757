import React, { useCallback, useState } from 'react';
import { Col, Drawer, Row, Typography } from 'antd';
import HeaderButton from '@components/HeaderButton';
import { FilterFilled } from '@ant-design/icons';
import FiltersForm from '../FiltersForm';
import FiltersList, { ListFilterType } from '@components/FiltersList';
import { useCustomer } from '@hooks/CustomerContext';

const { Title } = Typography;

const CustomersHeader: React.FC = () => {
  const [filtersDrawerIsVisible, setfiltersDrawerIsVisible] = useState<boolean>(false);

  const { customerFilters, loadCustomersPaginated, customerStatus } = useCustomer();

  const handleCloseFiltersDrawer = useCallback(() => {
    setfiltersDrawerIsVisible(false);
  }, [setfiltersDrawerIsVisible]);

  const handleShowFiltersDrawer = useCallback(() => {
    setfiltersDrawerIsVisible(true);
  }, [setfiltersDrawerIsVisible]);

  const onRemoveFilter = ({ key }: ListFilterType) => {
    const newFilters = customerFilters;
    delete newFilters[key];
    loadCustomersPaginated(0, customerStatus, newFilters);
  };

  return (
    <>
      <Row className="mt-8" justify="space-between">
        <Col>
          <Title level={2}>Lojistas</Title>
        </Col>
        <Col>
          <Row>
            <Col>
              <FiltersList
                columns={[{ title: 'Nome/Cnpj', key: 'searchByName' }]}
                dataSource={customerFilters}
                onRemove={onRemoveFilter}
              />
            </Col>
            <Col>
              <HeaderButton
                text="Filtros"
                onClick={handleShowFiltersDrawer}
                Icon={<FilterFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Drawer
        title="Filtros"
        placement="left"
        onClose={handleCloseFiltersDrawer}
        visible={filtersDrawerIsVisible}
        width={400}
      >
        <FiltersForm onCloseDrawer={handleCloseFiltersDrawer} />
      </Drawer>
    </>
  );
};

export default CustomersHeader;
