import { VisitDetails } from '@models/VisitDetails';
import { Visits } from '@models/Visits';
import * as VisitsService from '@services/visits';
import { INITIAL_PAGE } from '@utils/constants';
import { message } from 'antd';
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

interface VisitsState {
  currentPage: number;
  totalPages: number;
  visits: Visits[];
  visitDetails: VisitDetails;
  visitsFilters: any;
  loadingVisits: boolean;
  loadVisits: Function;
  loadVisitsDetails: Function;
}

interface VisitsProviderProps {
  children: ReactNode;
}

export const VisitsContext = createContext({} as VisitsState);

const VisitsProvider: React.FC<VisitsProviderProps> = ({ children }) => {
  const [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visits, setVisits] = useState<Visits[]>([]);
  const [visitDetails, setVisitDetails] = useState<VisitDetails>({} as VisitDetails);
  const [visitsFilters, setVisitsFilter] = useState<any>({} as any);
  const [loadingVisits, setLoadingVisits] = useState<boolean>(false);

  const loadVisits = useCallback(
    async (page: number, filters?: any) => {
      setLoadingVisits(true);

      setCurrentPage(page === 0 ? 1 : page);

      if (filters) setVisitsFilter(filters);
      else setVisitsFilter({});

      try {
        const params = {
          page: page ? page - 1 : 0,
          pageSize: 10,
          role: 'representative',
          ...(filters && { ...filters }),
        };

        const { data: _visits } = await VisitsService.get(params);

        setVisits(_visits.data);
        setTotalPages(_visits.total);
      } catch (error) {
        message.error('Falha ao buscar visitas');
        console.log(error);
      } finally {
        setLoadingVisits(false);
      }
    },
    [visits, setVisits, setVisitsFilter, setCurrentPage, setLoadingVisits],
  );

  const loadVisitsDetails = useCallback(
    async (id: number) => {
      setLoadingVisits(true);

      try {
        const { data: _visitDetails } = await VisitsService.getOne(id);

        setVisitDetails(_visitDetails);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingVisits(false);
      }
    },
    [setLoadingVisits, setVisitDetails],
  );

  return (
    <VisitsContext.Provider
      value={{
        currentPage,
        totalPages,
        visits,
        visitsFilters,
        loadingVisits,
        visitDetails,
        loadVisits,
        loadVisitsDetails,
      }}
    >
      {children}
    </VisitsContext.Provider>
  );
};

const useVisits = () => {
  return useContext(VisitsContext);
};

export { VisitsProvider, useVisits };
