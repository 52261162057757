import React from 'react';
import { Table, Typography } from 'antd';
import { useRepresentative } from '@hooks/RepresentativeContext';

const { Title } = Typography;

interface AddressesProps {}

const Addresses: React.FC<AddressesProps> = () => {
  const { actualRepresentative } = useRepresentative();

  const columns = [
    {
      title: 'País',
      dataIndex: 'countryName',
      sorter: (addressA: any, addressB: any) => addressA.countryName.trim().localeCompare(addressB.countryName.trim()),
    },
    {
      title: 'UF',
      dataIndex: 'stateAbbreviation',
      sorter: (addressA: any, addressB: any) =>
        addressA.stateAbbreviation.trim().localeCompare(addressB.stateAbbreviation.trim()),
    },
    {
      title: 'Município',
      dataIndex: 'cityName',
      sorter: (addressA: any, addressB: any) => addressA.cityName.trim().localeCompare(addressB.cityName.trim()),
    },
    {
      title: 'CEP',
      dataIndex: 'cep',
      sorter: (addressA: any, addressB: any) => addressA.cep.trim().localeCompare(addressB.cep.trim()),
    },
    {
      title: 'Bairro',
      dataIndex: 'neighborhood',
      sorter: (addressA: any, addressB: any) =>
        addressA.neighborhood.trim().localeCompare(addressB.neighborhood.trim()),
    },
    {
      title: 'Logradouro',
      dataIndex: 'address',
      sorter: (addressA: any, addressB: any) => addressA.address.trim().localeCompare(addressB.address.trim()),
    },
    {
      title: 'Número',
      dataIndex: 'addressNumber',
      sorter: (addressA: any, addressB: any) => addressA.addressNumber - addressB.addressNumber,
    },
    {
      title: 'Complemento',
      dataIndex: 'complement',
    },
  ];

  return (
    <div className="mt-6 ml-4">
      <Title level={5}>Endereços</Title>

      <Table columns={columns} dataSource={actualRepresentative.addresses} scroll={{ x: 800 }} />
    </div>
  );
};

export default Addresses;
