import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useOfficialStore } from '@hooks/OfficialStoreContext';

import Loading from '@components/Loading';
import OfficialStoreQuestionsHeader from './components/OfficialStoreQuestionsHeader';
import OfficialStoreQuestionsContent from './components/OfficialStoreQuestionsContent';
import { useOfficialStoreQuestions } from '@hooks/OfficialStoreQuestionsContext';
import { useClassification } from '@hooks/ClassificationContext';
import { FullScreenModal } from '@styles/globals';
import QuestionForm from './components/QuestionForm';

interface MatchParams {
  id: string;
}

const OfficialStoreQuestionsPage: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();
  const { actualOfficialStore, findOfficialStoreById, loading } = useOfficialStore();
  const { loadOfficialStoreQuestions, loadingOfficialStoreQuestions } = useOfficialStoreQuestions();
  const { loadClassifications } = useClassification();

  const [modalNewQuestionIsVisible, setModalNewQuestionIsVisible] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState<any>();

  useEffect(() => {
    findOfficialStoreById(parseInt(params.id));
    loadOfficialStoreQuestions(parseInt(params.id));
    loadClassifications();
  }, []);

  const onCloseModalNewQuestion = useCallback(() => {
    setEditingQuestion(undefined);
    setModalNewQuestionIsVisible(false);
  }, [setEditingQuestion, setModalNewQuestionIsVisible]);

  const showModalNewQuestion = useCallback(() => {
    setModalNewQuestionIsVisible(true);
  }, [setModalNewQuestionIsVisible]);

  if (loading || loadingOfficialStoreQuestions) return <Loading />;
  return (
    <>
      <OfficialStoreQuestionsHeader officialStore={actualOfficialStore} showModalNewQuestion={showModalNewQuestion} />
      <OfficialStoreQuestionsContent
        showModalNewQuestion={showModalNewQuestion}
        setEditingQuestion={setEditingQuestion}
      />

      <FullScreenModal
        destroyOnClose={true}
        visible={modalNewQuestionIsVisible}
        onOk={onCloseModalNewQuestion}
        onCancel={onCloseModalNewQuestion}
        footer={null}
        forceRender={false}
        bodyStyle={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
      >
        <QuestionForm editingQuestion={editingQuestion ? editingQuestion : null} />
      </FullScreenModal>
    </>
  );
};

export default OfficialStoreQuestionsPage;
