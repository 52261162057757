import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Select, Form, message } from 'antd';

import { CustomButton } from '@styles/globals';

import * as S from './styles';
import { useCollaborator } from '@hooks/CollaboratorContext';
import { Collaborator } from '@models/Collaborator';
import { useBrand } from '@hooks/BrandContext';

const { Title } = Typography;

interface BrandFormProps {
  collaboratorId: number;
}

const BrandForm: React.FC<BrandFormProps> = ({ collaboratorId }) => {
  const [form] = Form.useForm();

  const [collaborator, setCollaborator] = useState<Collaborator>();

  const { brands } = useBrand();
  const { collaborators, addBrandToCollaborator } = useCollaborator();

  useEffect(() => {
    if (collaboratorId)
      setCollaborator(collaborators.find((_collaborator) => _collaborator.collaboratorId === collaboratorId));
  }, [collaborators, collaboratorId]);

  const handleAddBrand = (values: any) => {
    if (collaborator && values) {
      if (collaborator.brands && collaborator.brands.findIndex((_brand) => _brand.brandId === values.brand) !== -1)
        return message.warning('Marca já foi adicionada anteriormente!');

      addBrandToCollaborator(collaborator.collaboratorId, [values.brand]);
      form.resetFields();
    }
  };

  return (
    <Form className="w-100" layout="vertical" form={form} onFinish={(values: any) => handleAddBrand(values)}>
      <Row className="d-flex" align="middle" justify="center">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="d-flex"
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <S.ContentContainer>
            <Title level={5} className="mb-4">
              Adicionar Marca
            </Title>

            <Form.Item
              className="mb-2"
              label="Marca"
              name="brand"
              rules={[{ required: true, message: 'Informe a Marca' }]}
            >
              <Select>
                {brands &&
                  brands.map((value) => (
                    <Select.Option key={value.brandId} value={value.brandId}>
                      {value.brandName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <CustomButton className="mt-8 w-100" type="primary" htmlType="submit">
              Adicionar
            </CustomButton>
          </S.ContentContainer>
        </Col>
      </Row>
    </Form>
  );
};

export default BrandForm;
