import React, { useEffect } from 'react';
import { Table } from 'antd';

import { columns } from '../../columns';
import { useOrder } from '@hooks/OrdersContext';
import OrderItemsTabs from '../OrderItemsTabs';
import LoadingComponents from '@components/LoadingComponents';

const CanceledTab: React.FC = () => {
  const { canceledPage, canceledOrders, loadCanceledOrders, canceledTotal, loadingCanceledOrders, canceledFilters } =
    useOrder();

  useEffect(() => {
    loadCanceledOrders(0);
  }, []);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.hasOwnProperty('column')) {
      canceledFilters.field = `${sorter.field}`;
      canceledFilters.order = `${sorter.order}`;
    }

    loadCanceledOrders(pagination.current, canceledFilters);
  };

  return (
    <Table
      columns={columns}
      dataSource={canceledOrders}
      pagination={{
        pageSize: 10,
        total: canceledTotal,
        current: canceledPage,
        showSizeChanger: false,
        showQuickJumper: false,
      }}
      onChange={handleTableChange}
      loading={loadingCanceledOrders}
      expandable={{
        expandedRowRender: (record) => <OrderItemsTabs order={record} />,
      }}
      scroll={{ x: 800 }}
      rowKey={(record) => record.orderCode}
    />
  );
};

export default CanceledTab;
