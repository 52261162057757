import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Col, Row, Switch, Typography } from 'antd';

import * as S from '../../styles';

const { Title } = Typography;

interface VisibilityPanelProps {}

const VisibilityPanel: React.FC<VisibilityPanelProps> = () => {
  return (
    <Col xs={24} sm={24} lg={8} xxl={6} className="d-flex">
      <S.Panel>
        <Title level={4} style={{ color: '#555' }}>
          Visibilidade
        </Title>

        <Row>
          <Col>
            <S.CustomFormItem name="isVisibleCustomer" label="Lojistas" valuePropName="checked">
              <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
            </S.CustomFormItem>

            <S.CustomFormItem name="isVisibleRepresentative" label="Representantes" valuePropName="checked">
              <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
            </S.CustomFormItem>

            <S.CustomFormItem name="isVisibleSeller" label="Vendedores" valuePropName="checked">
              <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
            </S.CustomFormItem>
          </Col>
        </Row>
      </S.Panel>
    </Col>
  );
};

export default VisibilityPanel;
