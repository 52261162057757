import { ShoppingBagFilters } from '@models/ShoppingBagFilters';
import { ShoppingBag } from '@models/ShopppingBag';
import * as ShoppingBagService from '@services/shoppingBags';
import { message } from 'antd';
import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

interface ShoppingBagState {
  loadShoppingBags: (integrationId: number, filters?: ShoppingBagFilters) => Promise<void>;
  shoppingBags: ShoppingBag[];
  loadingShoppingBags: boolean;
}

interface ShoppingBagProviderProps {
  children: ReactNode;
}

export const ShoppingBagContext = createContext({} as ShoppingBagState);

const ShoppingBagProvider: React.FC<ShoppingBagProviderProps> = ({ children }) => {
  const [shoppingBags, setShoppingBags] = useState<ShoppingBag[]>([]);
  const [loadingShoppingBags, setLoadingShoppingBags] = useState(false);

  const loadShoppingBags = useCallback(async (integrationId: number, filters?: ShoppingBagFilters) => {
    setLoadingShoppingBags(true);

    try {
      const { data } = await ShoppingBagService.get(integrationId, filters && filters);
      setShoppingBags(data.flat());
    } catch (error) {
      message.error('Falha ao buscar sacolas abertas.');
    } finally {
      setLoadingShoppingBags(false);
    }
  }, []);

  return (
    <ShoppingBagContext.Provider
      value={{
        loadShoppingBags,
        shoppingBags,
        loadingShoppingBags,
      }}
    >
      {children}
    </ShoppingBagContext.Provider>
  );
};

const useShoppingBag = () => {
  return useContext(ShoppingBagContext);
};

export { ShoppingBagProvider, useShoppingBag };
