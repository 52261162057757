import React from 'react';
import { ColProps, Typography } from 'antd';

import Card from '../Card';
import { useManufacturer } from '@hooks/ManufacturerContext';
import LoadingComponents from '@components/LoadingComponents';
import { formatCep } from '@utils/cep-mask';

const { Title, Text } = Typography;

const CardAddress: React.FC<ColProps> = ({ ...rest }) => {
  const { loading, addresses } = useManufacturer();

  if (loading) return <LoadingComponents />;

  return (
    <Card {...rest}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Title level={5}>Endereço Completo</Title>
        <Text>{addresses?.addressCountryName}</Text>
        <Text>
          {addresses?.addressCityName}/{addresses?.addressUf}
        </Text>
        <Text>
          {addresses?.addressName}, {addresses?.addressNumber}
        </Text>
        <Text>{addresses?.addressNeighborhood}</Text>
        <Text>CEP {formatCep(addresses?.addressCep)}</Text>
      </div>
    </Card>
  );
};

export default CardAddress;
