import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import CustomerProfileHeader from './components/CustomerProfileHeader';
import CustomerProfileContent from './components/CustomerProfileContent';
import { useCustomer } from '@hooks/CustomerContext';
import Loading from '@components/Loading';
import { useBrand } from '@hooks/BrandContext';

interface MatchParams {
  id: string;
}

const CustomerProfilePage: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();
  const { loadingCurrentCustomer, loadCustomerByCode } = useCustomer();
  const { loadBrands } = useBrand();

  useEffect(() => {
    loadCustomerByCode(params.id);
    loadBrands();
  }, []);

  if (loadingCurrentCustomer) return <Loading />;

  return (
    <>
      <CustomerProfileHeader />
      <CustomerProfileContent />
    </>
  );
};

export default CustomerProfilePage;
