import { themeColors } from '../../styles/globals';
import { Tag } from 'antd';

interface Data {
  data: {
    label: React.ReactNode;
    closable: boolean;
    onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void;
  };
}

const CustomTagSelect: React.FC<Data> = ({ data: { label, closable, onClose } }) => {
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const style: React.CSSProperties = {
    color: themeColors.primary_color,
    borderColor: themeColors.primary_color,
  };
  return (
    <Tag className="input" onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={style}>
      {label}
    </Tag>
  );
};

export default CustomTagSelect;
