import { useEffect, useState } from 'react';
import { Col, Row, Table, Menu, Dropdown, message, Modal } from 'antd';
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { OfficialStore } from '@models/OfficialStore';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
import OfficialStoreMenuForm from '../OfficialStoreMenuForm';
import OfficialStoreSubMenu from '../OfficialStoreSubMenu';
import { FullScreenModal } from '@styles/globals';
import { CustomDropdownMenu } from '../../styles';
import DualForm from '@components/DualForm';
import ClassificationForm from '@components/ClassificationForm';
import './styles.css';

interface OfficialStoreMenuContentProps {
  officialStore: OfficialStore;
}

const OfficialStoreMenuContent: React.FC<OfficialStoreMenuContentProps> = ({ officialStore }) => {
  const [modalEditMenuIsVisible, setModalEditMenuIsVisible] = useState(false);
  const [modalMenuAddClassificationIsVisible, setModalMenuAddClassificationIsVisible] = useState(false);

  const [actualMenuItem, setActualMenuItem] = useState<any>();

  const {
    menusOfficialStore,
    updateOfficialStoreMenu,
    deleteOfficialStoreMenu,
    addClassificationOfficialStoreMenu,
    deleteOfficialStoreMenuClassification,
  } = useOfficialStore();

  const columns = [
    {
      dataIndex: 'description',
      render: (description: string) => <strong>{description}</strong>,
    },
    {
      align: 'right' as 'right',
      render: () => (
        <Dropdown overlay={menuItems} trigger={['click']}>
          <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px', fontWeight: 'bolder', color: 'white' }} />
        </Dropdown>
      ),
    },
  ];

  const onCloseModalEditMenu = () => {
    setModalEditMenuIsVisible(false);
  };

  const showModalEditMenu = () => {
    setModalEditMenuIsVisible(true);
  };

  const onCloseModalAddClassification = () => {
    setModalMenuAddClassificationIsVisible(false);
  };

  const showModalAddClassification = () => {
    setModalMenuAddClassificationIsVisible(true);
  };

  const onUpdateMenu = async (value: any) => {
    if (officialStore && value && actualMenuItem?.menuId) {
      const updated = await updateOfficialStoreMenu(officialStore.officialStoreId, actualMenuItem?.menuId, value.description);

      if (updated) message.success('Menu atualizado com sucesso!');
      else message.error('Erro ao atualizar menu!');
    }
  };

  const onDeleteMenu = async () => {
    Modal.confirm({
      title: 'Deletar Menu de Loja Oficial',
      icon: <ExclamationCircleOutlined />,
      content: 'Deseja mesmo prosseguir?',
      okText: 'Sim',
      cancelText: 'Não',
      onOk: async () => {
        if (officialStore && actualMenuItem?.menuId) {
          const deleted = await deleteOfficialStoreMenu(officialStore.officialStoreId, actualMenuItem?.menuId);

          if (deleted) message.success('Menu excluído com sucesso!');
          else message.error('Erro ao excluir menu!');
        }
      },
    });
  };

  const onAddNewClassification = async (values: any) => {
    const menu = menusOfficialStore.find((_menu) => _menu.menuId === actualMenuItem?.menuId);
    if (
      menu &&
      menu.classifications.find(
        (classification) => classification.typeCode == values.typeCode && classification.code == values.code,
      )
    )
      return message.warn('Classificação já foi informada!');

    if (officialStore.officialStoreId && actualMenuItem?.menuId && values) {
      await addClassificationOfficialStoreMenu(officialStore.officialStoreId, actualMenuItem?.menuId, values);
    }
  };

  const onDeleteMenuClassification = async (typeCode: number, code: string) => {
    if (officialStore.officialStoreId && actualMenuItem?.menuId) {
      await deleteOfficialStoreMenuClassification(officialStore.officialStoreId, actualMenuItem?.menuId, { typeCode, code });
    }
  };

  const menuItems = (
    <CustomDropdownMenu>
      <Menu.Item key="add" onClick={showModalAddClassification}>
        Classes
      </Menu.Item>
      <Menu.Item onClick={showModalEditMenu} key="edit">
        Editar
      </Menu.Item>
      <Menu.Item key="delete" onClick={onDeleteMenu}>
        Excluir
      </Menu.Item>
    </CustomDropdownMenu>
  );

  useEffect(() => {
    adaptClassificationsItemsList();
  }, [menusOfficialStore, actualMenuItem?.menuId]);

  const [currentClassificationItems, setCurrentClassificationItems] = useState<any[]>([]);

  const adaptClassificationsItemsList = () => {
    const menu = menusOfficialStore.find((_menu) => _menu.menuId === actualMenuItem?.menuId);

    const list = menu?.classifications?.map((item) => ({
      label1: 'Tipo de Classificação',
      item1: item.typeCode,
      label2: 'Classificação',
      item2: item.code,
    }));

    if (list) setCurrentClassificationItems(list);
  };

  return (
    <Row className="mt-2">
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={menusOfficialStore}
          rowKey={(record) => record.menuId}
          showHeader={false}
          expandable={{
            expandedRowRender: (record) => (
              <OfficialStoreSubMenu officialStoreMenu={record} officialStore={officialStore} />
            ),
          }}
          onRow={(record) => {
            return {
              onClick: () => setActualMenuItem(record),
            };
          }}
          className="table-antd"
          rowClassName="row-dark"
          scroll={{ x: 800 }}
        />

        <FullScreenModal
          destroyOnClose={true}
          visible={modalEditMenuIsVisible}
          onOk={onCloseModalEditMenu}
          onCancel={onCloseModalEditMenu}
          footer={null}
          forceRender={false}
        >
          <OfficialStoreMenuForm onSubmit={onUpdateMenu} updating description={actualMenuItem?.description} />
        </FullScreenModal>

        <FullScreenModal
          destroyOnClose={true}
          footer={null}
          forceRender={false}
          visible={modalMenuAddClassificationIsVisible}
          onOk={onCloseModalAddClassification}
          onCancel={onCloseModalAddClassification}
          bodyStyle={{ width: '100%' }}
        >
          <DualForm
            title="Classificações"
            loading={false}
            form={<ClassificationForm onAdd={onAddNewClassification} />}
            list={currentClassificationItems}
            onDelete={onDeleteMenuClassification}
          />
        </FullScreenModal>
      </Col>
    </Row>
  );
};

export default OfficialStoreMenuContent;
