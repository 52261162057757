import React from 'react';
import { useHistory } from 'react-router';

import { LeftOutlined, PlusCircleFilled } from '@ant-design/icons';

import HeaderButton from '@components/HeaderButton';
import RepresentativeForm from '@components/RepresentativeForm';
import { useIntegration } from '@hooks/IntegrationContext';
import { FullScreenModal } from '@styles/globals';
import { Col, Row, Typography } from 'antd';
import { useRepresentative } from '@hooks/RepresentativeContext';

const { Title } = Typography;

interface RepresentativeProfileHeaderProps {}

const RepresentativeProfileHeader: React.FC<RepresentativeProfileHeaderProps> = () => {
  const { hasMerpIntegration } = useIntegration();
  const { actualRepresentative } = useRepresentative();

  const history = useHistory();

  const [modalEditRepresentativeVisible, setModalEditRepresentativeVisible] = React.useState(false);

  const showNewRepresentativeModal = () => {
    setModalEditRepresentativeVisible(true);
  };

  const onCloseEditRepresentativeModal = () => {
    setModalEditRepresentativeVisible(false);
  };

  const backUrl = () => {
    history.go(-1);
  };

  return (
    <>
      <Row className="mt-8" justify="space-between">
        <Col className="d-flex" style={{ alignItems: 'center' }}>
          <LeftOutlined onClick={() => backUrl()} style={{ fontSize: '20px', marginRight: '12px' }} />
          <Row style={{ flexDirection: 'column', marginLeft: '12px' }}>
            <Col>
              <Title level={3} style={{ margin: '0' }}>
                Perfil do Representante
              </Title>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row>
            {hasMerpIntegration && (
              <Col>
                <HeaderButton
                  text="Editar representante"
                  onClick={showNewRepresentativeModal}
                  Icon={<PlusCircleFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <FullScreenModal
        destroyOnClose={true}
        visible={modalEditRepresentativeVisible}
        onOk={onCloseEditRepresentativeModal}
        onCancel={onCloseEditRepresentativeModal}
        footer={null}
        forceRender={false}
      >
        <Row align="middle" justify="center">
          <Col span={24} className="d-flex" style={{ width: '1000px' }}>
            <RepresentativeForm onCloseModal={onCloseEditRepresentativeModal} isEditing={true} representativeToEdit={actualRepresentative}/>
          </Col>
        </Row>
      </FullScreenModal>
    </>
  );
};

export default RepresentativeProfileHeader;
