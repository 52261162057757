import { DownloadOutlined } from '@ant-design/icons';
import { ImageByOrderCodeEncriptedResponseType } from '@services/order';
import { fallbackImage } from '@utils/fallback-image';
import { normalizeString } from '@utils/normalize-string';
import { Button, Empty, Image, Skeleton } from 'antd';
import Title from 'antd/lib/typography/Title';
import { saveAs } from 'file-saver';
import { random } from 'lodash';
import './styles.css';

interface ReferenceImageComponentProps {
  loading: boolean;
  filteredReferences: ImageByOrderCodeEncriptedResponseType[];
}

const ReferenceImageComponent: React.FC<ReferenceImageComponentProps> = ({ loading, filteredReferences }) => {
  const skeletonImage = <Skeleton.Image className="image-size image-skeleton" />;
  const skeletonTitle = <Skeleton active className="skeleton-title" title={false} paragraph={{ rows: 1 }} />;

  const downloadImage = (url: string, referenceCode: string, index: number) => {
    saveAs(url, `Ref_${normalizeString(referenceCode).replace(/\s+/g, '-')}_${index + 1}.jpeg`);
  };

  return (
    <>
      <Image.PreviewGroup>
        {loading &&
          Array.from({ length: 8 }).map(() => (
            <>
              <div className="container-images">
                {skeletonTitle}
                {Array.from({ length: random(3, 10) }).map(() => skeletonImage)}
              </div>
            </>
          ))}

        {!loading && filteredReferences?.length > 0 ? (
          filteredReferences?.map(({ referenceCode, urls }) => (
            <div key={referenceCode} className="reference-row">
              <Title level={4}>Referência {referenceCode}</Title>
              <div className="container-images">
                {[...new Set(urls)].map((url: string, index) => (
                  <>
                    <div className="container-one-image">
                      <Image
                        key={url}
                        src={url}
                        className="image-size image-item"
                        preview={{ src: url }}
                        fallback={fallbackImage}
                      />
                      <Button
                        onClick={() => downloadImage(url, referenceCode, index)}
                        type="primary"
                        size="small"
                        icon={<DownloadOutlined />}
                      ></Button>
                    </div>
                  </>
                ))}
              </div>
            </div>
          ))
        ) : (
          <>
            <Empty style={{ margin: '10px' }} description="Não há referências com o filtro informado!" />
          </>
        )}
      </Image.PreviewGroup>
    </>
  );
};
export default ReferenceImageComponent;
