import React, { useCallback } from 'react';
import { Col, Row, Pagination } from 'antd';
import { useHistory } from 'react-router';

import { useOfficialStore } from '@hooks/OfficialStoreContext';
import { OfficialStore } from '@models/OfficialStore';
import LoadingComponents from '@components/LoadingComponents';
import Card from '../Card';
import { OFFICIAL_STORE_PAGINATION_SIZE } from '@utils/constants';

interface CardListProps {
  officialStoreList: OfficialStore[];
  redirectBaseUrl: string;
}

const CardList: React.FC<CardListProps> = ({ officialStoreList, redirectBaseUrl }) => {
  const history = useHistory();

  const { loadOfficialStores, loading, totalPages, currentPage } = useOfficialStore();

  const handlePaginationChange = useCallback(
    (page: number) => {
      loadOfficialStores(page - 1);
    },
    [loadOfficialStores],
  );

  if (loading) return <LoadingComponents />;

  return (
    <>
      <Row gutter={[24, 8]} style={{ marginTop: '16px' }}>
        {officialStoreList.map(({ description, logoUrl, officialStoreId }: OfficialStore) => (
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={6}
            xxl={4}
            onClick={() => history.push(`${redirectBaseUrl}/${officialStoreId}`)}
            key={officialStoreId}
          >
            <Card title={description} imageUrl={logoUrl} code={officialStoreId} />
          </Col>
        ))}
      </Row>
      <Row className="mt-4 mb-8 mr-4" justify="end">
        <Pagination
          defaultCurrent={1}
          total={totalPages}
          current={currentPage + 1}
          pageSize={OFFICIAL_STORE_PAGINATION_SIZE}
          showSizeChanger={false}
          onChange={handlePaginationChange}
        />
      </Row>
    </>
  );
};

export default CardList;
