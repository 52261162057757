import { useOfficialStore } from '@hooks/OfficialStoreContext';
import { useOfficialStoreStock } from '@hooks/OfficialStoreStockContext';
import { Stock } from '@models/Stock';
import { LiquidityBufferAppEnum } from '@models/enum/LiquidityBufferApp';
import { Row, Steps } from 'antd';
import React, { useState } from 'react';
import * as S from '../../../../styles';
import NewStockStepBranchCode from '../NewStockStepBranchCode';
import NewStockStepConfig from '../NewStockStepConfig';

const steps = [{ title: 'Empresa e Cód. Estoque' }, { title: 'Configurações' }];

interface NewStockFormprops {
  appType: LiquidityBufferAppEnum;
  closeModal: () => void;
}

const NewStockForm: React.FC<NewStockFormprops> = ({ closeModal, appType }) => {
  const { stocks, setStocksByLiquidityBufferApp } = useOfficialStoreStock();
  const { actualOfficialStore } = useOfficialStore();
  const [currentStep, setCurrentStep] = useState(0);
  const [newStock, setNewStock] = useState<Stock>(Object.assign({}));

  const nextStep = () => {
    setCurrentStep(1);
  };

  const previousStep = () => {
    setCurrentStep(0);
  };

  const handleAddStockField = (values: Partial<Stock>) => {
    setNewStock({ ...newStock, ...values });
  };

  const add = () => {
    const index = stocks[appType].findIndex(
      (item) => item?.branchCode == newStock?.branchCode && item?.stockCode == newStock?.stockCode,
    );
    let newArrayStock = [...stocks[appType]];
    const newStockObj = { ...newStock, officialStoreId: actualOfficialStore.officialStoreId };
    if (index > -1) {
      newArrayStock[index] = newStockObj;
    } else {
      newArrayStock.push(newStockObj);
    }
    setStocksByLiquidityBufferApp(appType, newArrayStock);
    closeModal();
  };

  return (
    <>
      <S.StepsContainer progressDot current={currentStep} className="mb-3 mx-auto">
        {steps.map((item) => (
          <Steps.Step key={item.title} title={item.title} />
        ))}
      </S.StepsContainer>
      <Row className="w-100 d-flex justify-center" gutter={[16, 16]}>
        {currentStep === 0 && (
          <NewStockStepBranchCode newStock={newStock} nextStep={nextStep} handleAddStockField={handleAddStockField} />
        )}
        {currentStep === 1 && (
          <NewStockStepConfig
            newStock={newStock}
            previuosStep={previousStep}
            add={add}
            handleAddStockField={handleAddStockField}
          />
        )}
      </Row>
    </>
  );
};

export default NewStockForm;
