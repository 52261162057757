import { Col, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';

import { EditFilled } from '@ant-design/icons';
import SalesRegionForm from '@components/SalesRegionForm';
import { useIntegration } from '@hooks/IntegrationContext';
import { useSalesRegion } from '@hooks/SalesRegionContext';
import { RepresentativeOperationalArea } from '@models/SalesRegionRepresentative';
import { FullScreenModal } from '@styles/globals';
import '../../styles.css';
import OperationalAreasTable from '../OperationalAreasTable';

interface RepresentativeOperationalAreasTableProps {
  operationalAreas: RepresentativeOperationalArea[];
  selectedRepresentatives: number[];
}
const RepresentativeOperationalAreasTable: React.FC<RepresentativeOperationalAreasTableProps> = ({
  operationalAreas,
  selectedRepresentatives,
}) => {
  const { hasMerpIntegration } = useIntegration();
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  const { loadOperationalArea, loadSalesRegionsRepresentatives } = useSalesRegion();

  const [currentSalesRegion, setCurrentSalesRegion] = React.useState<RepresentativeOperationalArea | undefined>(
    undefined,
  );

  const [modalNewSalesRegionVisible, setModalNewSalesRegionVisible] = useState(false);

  useEffect(() => {
    setExpandedRowKeys([]);
  }, [selectedRepresentatives]);

  const showEditSalesRegionModal = (operationalArea: RepresentativeOperationalArea) => {
    setCurrentSalesRegion(operationalArea);
    setModalNewSalesRegionVisible(true);
  };

  const onCloseNewSalesRegionModal = () => {
    loadSalesRegionsRepresentatives(0, currentSalesRegion?.brandId);
    setModalNewSalesRegionVisible(false);
    setCurrentSalesRegion(undefined);
  };

  const columns = [
    {
      dataIndex: 'salesRegionCode',
      width: '100px',
      align: 'center' as 'center',
    },
    {
      dataIndex: 'salesRegionName',
    },
    {
      title: hasMerpIntegration ? 'Ações' : '',
      align: 'center' as 'center',
      width: hasMerpIntegration ? '180px' : '0',
      render: (sales: any, operationalArea: RepresentativeOperationalArea): JSX.Element => {
        return (
          <>
            {hasMerpIntegration && (
              <EditFilled
                onClick={() => showEditSalesRegionModal(operationalArea)}
                style={{ fontSize: '24px', color: '#510c76', paddingLeft: 15 }}
              />
            )}
          </>
        );
      },
    },
  ];

  const onTableRowExpand = (expanded: any, record: any) => {
    const keys = [];

    if (expanded) {
      keys.push(record.salesRegionCode);
      loadOperationalArea(record.salesRegionCode);
    }

    setExpandedRowKeys(keys);
  };

  return (
    <>
      <FullScreenModal
        destroyOnClose={true}
        visible={modalNewSalesRegionVisible}
        onOk={onCloseNewSalesRegionModal}
        onCancel={onCloseNewSalesRegionModal}
        footer={null}
        forceRender={false}
      >
        <Row align="middle" justify="center">
          <Col span={24} className="d-flex" style={{ width: '1000px' }}>
            <SalesRegionForm
              onCloseModal={onCloseNewSalesRegionModal}
              isEditing={true}
              salesOrderToEdit={currentSalesRegion}
            />
          </Col>
        </Row>
      </FullScreenModal>

      <Table
        className="mt-2 custom-table"
        columns={columns as any}
        rowKey={(operationalArea) => operationalArea.salesRegionCode}
        dataSource={operationalAreas}
        expandable={{
          expandedRowRender: (operationalArea) => (
            <OperationalAreasTable representativeCode={operationalArea.representativeCode} />
          ),
          onExpand: onTableRowExpand,
        }}
        expandedRowKeys={expandedRowKeys}
        showHeader={false}
        pagination={false}
        scroll={{ x: 800 }}
      />
    </>
  );
};

export default RepresentativeOperationalAreasTable;
