import * as api from './api';

export async function getSellersByOfficialStoreId(officialStoreId: number) {
  return await api.get(`official-store/${officialStoreId}/seller`);
}

export async function getSellersByBrandId(officialStoreId: number, brandId: number) {
  return await api.get(`official-store/${officialStoreId}/seller/${brandId}`);
}

export async function updateSellersById(officialStoreId: number, body: any): Promise<any> {
  return await api.put(`official-store/${officialStoreId}/seller`, body);
}

export async function removeSellerById(officialStoreId: number, brandId: number, sellerCode: number): Promise<any> {
  return await api.remove(`official-store/${officialStoreId}/seller/${brandId}/${sellerCode}`);
}
