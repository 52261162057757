import * as api from './api';

export function findParameters(params?: any) {
  return api.get(`parameters`, params);
}

export function findParameterItemsByParameterId(parameterId: number) {
  return api.get(`parameters/${parameterId}/items`);
}

export function createParameterItem(parameterId: number, body: any) {
  return api.post(`parameters/${parameterId}/items`, body);
}

export function deleteParameterItem(parameterId: number, sequence: number) {
  return api.remove(`parameters/${parameterId}/items/${sequence}`);
}
