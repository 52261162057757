import { Devolution } from '@models/Devolution';
import { DevolutionStatusEnum } from '@models/enum/DevolutionStatusEnum';
import React from 'react';

interface DevolutionStatusColumnProps {
  devolution: Devolution | any;
}

const DevolutionStatusColumn: React.FC<DevolutionStatusColumnProps> = ({ devolution }) => {
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        {devolution?.status === DevolutionStatusEnum.PRIMARY_ANALYZIS && (
          <p
            style={{
              borderRadius: 5,
              padding: '5px',
              margin: 0,
              width: 150,
              color: '#976F20',
              backgroundColor: '#fffdde',
              border: '1px solid #976F20',
              opacity: 0.6,
            }}
          >
            Análise primária
          </p>
        )}

        {devolution?.status === DevolutionStatusEnum.SECONDARY_ANALYSIS && (
          <p
            style={{
              borderRadius: 5,
              padding: '5px',
              margin: 0,
              width: 150,
              color: '#973D20',
              backgroundColor: '#fae7e3',
              border: '1px solid #973D20',
            }}
          >
            Análise secundária
          </p>
        )}

        {devolution?.status === DevolutionStatusEnum.IN_DEVOLUTION && (
          <p
            style={{
              borderRadius: 5,
              padding: '5px',
              margin: 0,
              width: 150,
              color: '#205E97',
              backgroundColor: 'aliceblue',
              border: '1px solid #205E97',
            }}
          >
            Em devolução
          </p>
        )}

        {devolution?.status === DevolutionStatusEnum.FINISHED && (
          <p
            style={{
              borderRadius: 5,
              padding: '5px',
              margin: 0,
              width: 150,
              color: '#349F2F',
              backgroundColor: '#f9fff5',
              border: '1px solid #349F2F',
            }}
          >
            Concluído
          </p>
        )}
      </div>
    </>
  );
};

export default DevolutionStatusColumn;
