import { Input } from 'antd';
import styled from 'styled-components';

const { TextArea } = Input;

export const Container = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 4px;
`;

export const CustomTextArea = styled(TextArea)`
  min-width: 320px;
  border-radius: 10px;
`;
