import { Modal } from 'antd';
import React from 'react';
import { BigPlayButton, LoadingSpinner, Player } from 'video-react';
import './video-react.css';
import './styles.css';

interface ModalPlayerVideoProps {
  src: string;
  visible: boolean;
  onCancel: () => void;
}

const ModalPlayerVideo: React.FC<ModalPlayerVideoProps> = ({ src, visible, onCancel }) => {
  return (
    <Modal visible={visible} centered={true} destroyOnClose={true} footer={null} onCancel={onCancel}>
      <Player  >
        <source src={src}  type="video/mp4"/>
        <LoadingSpinner />
        <BigPlayButton position="center" />
      </Player>
    </Modal>
  );
};

export default ModalPlayerVideo;
