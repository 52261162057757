import { Col, Form, Modal, Row, Select, Table, Typography } from 'antd';
import React from 'react';

import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useBrand } from '@hooks/BrandContext';
import { useIntegration } from '@hooks/IntegrationContext';
import { useRepresentative } from '@hooks/RepresentativeContext';
import { useSalesRegion } from '@hooks/SalesRegionContext';
import { Brand } from '@models/Brand';
import { Representative } from '@models/Representative';
import { RepresentativeOperationalArea } from '@models/SalesRegionRepresentative';
import { CustomButton } from '@styles/globals';
import { v4 as uuidv4 } from 'uuid';
import { City, states } from './models/sales-order-form-models';
import { Container, CustomInput } from './styles';

const { Title } = Typography;

interface SalesRegionFormProps {
  onCloseModal: Function;
  isEditing: boolean;
  salesOrderToEdit?: RepresentativeOperationalArea;
}

const SalesRegionForm: React.FC<SalesRegionFormProps> = ({
  onCloseModal,
  isEditing = false,
  salesOrderToEdit = undefined,
}) => {
  const { hasMerpIntegration } = useIntegration();
  const { createOperarionalArea } = useSalesRegion();
  const { brands } = useBrand();
  const { representatives } = useRepresentative();

  const [salesRegionForm] = Form.useForm();

  const [loading, setLoading] = React.useState<boolean>(false);

  const { operationalArea, loadOperationalArea, loadingOperationalAreas } = useSalesRegion();

  const [citiesTableRows, setCitiesTableRows] = React.useState<{ value: string; key: string }[]>([]);
  const [cepsTableRows, setCepsTableRows] = React.useState<{ value: string; key: string }[]>([]);

  const [selectedCities, setSelectedCities] = React.useState<any>([]);
  const [selectedCeps, setSelectedCeps] = React.useState<any>([]);

  React.useEffect(() => {
    salesRegionForm.setFields([
      { name: 'operationalAreaCode', value: '' },
      { name: 'representativeCode', value: null },
      { name: 'integrationId', value: '' },
      { name: 'brandId', value: null },
      { name: 'operationalAreaName', value: '' },
      { name: 'operationalAreaDaysNumber', value: '' },
      { name: 'operationalAreaStates', value: [] },
      { name: 'operationalAreaCities', value: [] },
      { name: 'operationalAreaCeps', value: '' },
    ]);

    if (isEditing) {
      const loadOperationalAreaByCode = async () => {
        loadOperationalArea(salesOrderToEdit?.salesRegionCode);
      };

      loadOperationalAreaByCode();
    }
  }, []);

  React.useEffect(() => {
    if (isEditing) {
      if (operationalArea?.operationalAreaCode && operationalArea?.operationalAreaCode !== 0) {
        salesRegionForm.setFieldsValue({
          operationalAreaCode: operationalArea?.operationalAreaCode,
          representativeCode: salesOrderToEdit?.representativeCode,
          integrationId: salesOrderToEdit?.integrationId,
          brandId: salesOrderToEdit?.brandId,
          operationalAreaName: operationalArea?.operationalAreaName,
          operationalAreaDaysNumber: operationalArea?.numberOfDays,
          operationalAreaStates: operationalArea?.operationalAreaStates?.map((state: any) => {
            return state.stateCode;
          }),
          operationalAreaCities: operationalArea?.operationalAreaCities,
          operationalAreaCeps: operationalArea?.operationalAreaCeps,
        });

        setCitiesTableRows(
          operationalArea?.operationalAreaCities?.map((city: any) => {
            return { value: 'Cidade', key: uuidv4() };
          }),
        );
        setCepsTableRows(
          operationalArea?.operationalAreaCeps?.map((cep: any) => {
            return { value: 'CEP', key: uuidv4() };
          }),
        );

        setSelectedCities(operationalArea?.operationalAreaCities);
        setSelectedCeps(operationalArea?.operationalAreaCeps);
      }
    }
  }, [operationalArea]);

  React.useEffect(() => {
    setLoading(loadingOperationalAreas);
  }, [loadingOperationalAreas]);

  const hadleSubmit = async () => {
    setLoading(true);
    const values = await salesRegionForm.validateFields();

    values.integrationId = Number(localStorage.getItem('@modavest/integration_id'));

    if (!values?.operationalAreaCities && citiesTableRows?.length > 0) {
      values.operationalAreaCities = selectedCities.map((city: any) => {
        return {
          cityName: city?.cityName,
          stateCode: city?.stateCode,
          stateName: states?.filter((state) => state?.id === city?.stateCode)[0]?.name,
        };
      });
    }

    if (!values?.operationalAreaCeps && cepsTableRows?.length > 0) {
      values.operationalAreaCeps = selectedCeps.map((cep: any) => {
        return {
          cep: cep?.cep,
        };
      });
    }

    if (values?.operationalAreaStates) {
      values.operationalAreaStates = salesRegionForm.getFieldValue('operationalAreaStates').map((state: number) => {
        return { stateCode: state, stateName: states?.filter((stat) => stat?.id === state)[0]?.name };
      });
    } else {
      values.operationalAreaStates = [];
    }

    if (values?.operationalAreaCities) {
      const cities = [...salesRegionForm?.getFieldValue('operationalAreaCities')];

      let newCities: any[] = [];
      selectedCities.forEach((city: any) => {
        if (cities?.filter((c: any) => c.cityCode === city.cityCode)?.length === 0) {
          newCities.push(city);
        }
      });

      newCities = [...newCities, ...cities];

      values.operationalAreaCities = newCities.map((city: City) => {
        return {
          ...city,
          stateName: states?.filter((state) => state?.id === city?.stateCode)[0]?.name,
        };
      });
    } else {
      values.operationalAreaCities = [];
    }

    if (values?.operationalAreaCeps) {
      const ceps = [...salesRegionForm?.getFieldValue('operationalAreaCeps')];
      let newCeps: any[] = [];
      selectedCeps.forEach((cep: any) => {
        if (ceps?.filter((c: any) => c.cep === cep.cep)?.length === 0) {
          newCeps.push(cep);
        }
      });
      newCeps = [...newCeps, ...ceps];

      values.operationalAreaCeps = newCeps.map((cep: any) => {
        return {
          cep: cep?.cep,
        };
      });
    } else {
      values.operationalAreaCeps = [];
    }

    if (isEditing) {
      values.operationalAreaCode = operationalArea?.operationalAreaCode;
    }

    try {
      await createOperarionalArea(values);
      onCloseModal();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const showModalConfirmation = () => {
    Modal.confirm({
      title: `Tem certeza que deseja ${isEditing ? 'Cadastrar' : 'Editar'} essa região?`,
      icon: <ExclamationCircleOutlined />,
      okText: isEditing ? 'Cadastrar' : 'Editar',
      cancelText: 'Cancelar',
      onOk() {
        hadleSubmit();
      },
    });
  };

  const addCitySalesRegion = () => {
    setCitiesTableRows((prev) => [...prev, { value: 'Cidade', key: uuidv4() }]);
  };

  const removeCitySalesRegion = (key: string, index: number) => {
    const filterCities = [...citiesTableRows].filter((row) => row.key !== key);

    if (isEditing && filterCities?.length === 0) {
      salesRegionForm.setFieldsValue({
        operationalAreaCities: [],
      });
    }

    if (isEditing && filterCities?.length > 0) {
      const cities = [...salesRegionForm.getFieldValue('operationalAreaCities')];
      cities?.splice(index, 1);
      setSelectedCities(cities);
      salesRegionForm.setFieldsValue({
        operationalAreaCities: cities,
      });
    }

    setCitiesTableRows(filterCities);
  };

  const addCepSalesRegion = () => {
    setCepsTableRows((prev) => [...prev, { value: 'CEP', key: uuidv4() }]);
  };

  const removeCepSalesRegion = (key: string, index: number) => {
    const filterCeps = [...cepsTableRows].filter((row) => row.key !== key);

    if (isEditing && filterCeps?.length === 0) {
      salesRegionForm.setFieldsValue({
        operationalAreaCeps: [],
      });
    }

    if (isEditing && filterCeps?.length > 0) {
      const ceps = [...salesRegionForm.getFieldValue('operationalAreaCeps')];
      ceps?.splice(index, 1);
      setSelectedCeps(ceps);
      salesRegionForm.setFieldsValue({
        operationalAreaCeps: ceps,
      });
    }

    setCepsTableRows(filterCeps);
  };

  const onChangeGridSelect = (value: any) => { };

  return (
    <>
      <Row align="middle" justify="center">
        <Col span={24} className="d-flex" style={{ width: '1000px' }}>
          <Container
            style={{
              background: '#fff',
              padding: '100px',
              paddingTop: '110px',
              borderRadius: '8px',
              width: '100%',
            }}
          >
            {loading && (
              <div style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <LoadingOutlined
                  type="play-circle-o"
                  style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
                />
              </div>
            )}

            {!loading && (
              <Form
                form={salesRegionForm}
                className="d-flex"
                style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}
              >
                <Row
                  className="d-flex"
                  style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Col span={12}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      Marca <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                    </Title>
                    <Form.Item
                      name="brandId"
                      rules={[
                        {
                          required: true,
                          message: 'Informe a marca',
                        },
                      ]}
                    >
                      <Select
                        disabled={isEditing}
                        style={{ height: '48px', borderRadius: '8px' }}
                        placeholder="Marca"
                        size="large"
                        showSearch
                        allowClear
                        onChange={(value) => {
                          onChangeGridSelect(value);
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {brands?.length &&
                          brands.map((brand: Brand) => (
                            <Select.Option key={brand.brandId} value={brand.brandId}>
                              {brand.brandName}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12} style={{ paddingLeft: '15px' }}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      Representante <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                    </Title>
                    <Form.Item
                      name="representativeCode"
                      rules={[
                        {
                          required: true,
                          message: 'Informe o representante',
                        },
                      ]}
                    >
                      <Select
                        disabled={isEditing}
                        style={{ height: '48px', borderRadius: '8px' }}
                        placeholder="Representante"
                        size="large"
                        showSearch
                        allowClear
                        onChange={(value) => {
                          onChangeGridSelect(value);
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {representatives?.length &&
                          representatives.map((representative: Representative) => (
                            <Select.Option key={representative.code} value={representative.code}>
                              {representative.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row
                  className="d-flex"
                  style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  {!hasMerpIntegration &&
                    <>
                      <Col span={12}>
                        <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                          Nome da região <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                        </Title>
                        <Form.Item
                          name="operationalAreaName"
                          rules={[
                            {
                              required: true,
                              message: 'Informe o nome da região',
                            },
                          ]}
                        >
                          <CustomInput style={{ width: '100%' }} placeholder="Nome da região" />
                        </Form.Item>
                      </Col>

                      <Col span={12} style={{ paddingLeft: '15px' }}>
                        <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                          Número de dias
                        </Title>
                        <Form.Item name="operationalAreaDaysNumber">
                          <CustomInput type="number" style={{ width: '100%' }} placeholder="Número de dias" />
                        </Form.Item>
                      </Col>
                    </>
                  }

                  {hasMerpIntegration && <Col span={24}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      Nome da região <p style={{ color: 'red' }}>*</p>
                    </Title>
                    <Form.Item
                      name="operationalAreaName"
                      rules={[
                        {
                          required: true,
                          message: 'Informe o nome da região',
                        },
                      ]}
                    >
                      <CustomInput style={{ width: '100%' }} placeholder="Nome da região" />
                    </Form.Item>
                  </Col>}

                </Row>

                <Row
                  className="d-flex"
                  style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Col span={24}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      Estados
                    </Title>
                    <Form.Item
                      name="operationalAreaStates"
                      rules={[
                        {
                          required: true,
                          message: 'Informe o estado',
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        style={{ width: '100%', textAlign: 'left' }}
                        placeholder={'Estados'}
                        options={states.map((state) => ({ value: state.id, label: state.name }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {/* CIDADES DA REGIÃO DE VENDA */}
                <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                  <Col span={24}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      Cidade(s)
                    </Title>
                  </Col>

                  {citiesTableRows.length > 0 && (
                    <Table
                      bordered={true}
                      showHeader={false}
                      pagination={false}
                      style={{ width: '100%', cursor: 'pointer' }}
                      className="table-antd"
                      dataSource={citiesTableRows}
                      columns={[
                        {
                          dataIndex: 'name',
                          render: (name: string, city, index) => (
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <strong>{city.value + ' ' + (index + 1)}</strong>
                              <p
                                style={{
                                  color: 'red',
                                  textDecoration: 'underline',
                                  margin: 0,
                                  padding: 0,
                                  cursor: 'pointer',
                                }}
                                onClick={() => removeCitySalesRegion(city.key, index)}
                              >
                                Excluir
                              </p>
                            </div>
                          ),
                        },
                      ]}
                      expandable={{
                        expandRowByClick: true,
                        expandedRowRender: (city, index) => (
                          <Row
                            style={{ marginRight: 10, width: '100%', display: 'flex', justifyContent: 'space-between' }}
                          >
                            <Col style={{ marginRight: 10, width: '49%' }}>
                              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                                Estado <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                              </Title>
                              <Form.Item
                                name={[`operationalAreaCities`, index, 'stateCode']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Informe o estado',
                                  },
                                ]}
                              >
                                <Select
                                  style={{ height: '48px', borderRadius: '8px' }}
                                  placeholder="Estado"
                                  size="large"
                                  showSearch
                                  allowClear
                                  onChange={(value) => {
                                    onChangeGridSelect(value);
                                  }}
                                  getPopupContainer={(trigger) => trigger.parentNode}
                                >
                                  {states?.length &&
                                    states.map((state: { id: number; name: string }) => (
                                      <Select.Option key={state.id} value={state.id}>
                                        {state.name}
                                      </Select.Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col style={{ width: '49%' }}>
                              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                                Cidade <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                              </Title>
                              <Form.Item
                                name={[`operationalAreaCities`, index, 'cityName']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Informe a cidade',
                                  },
                                ]}
                              >
                                <CustomInput style={{ width: '100%' }} placeholder="Cidade" />
                              </Form.Item>
                            </Col>
                          </Row>
                        ),
                      }}
                    />
                  )}

                  <div
                    className="d-flex"
                    style={{
                      width: '100%',
                      alignItems: 'center',
                      fontSize: '18px',
                      marginTop: citiesTableRows.length > 0 ? '20px' : 0,
                      marginBottom: 30,
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        addCitySalesRegion();
                      }}
                    >
                      <div
                        className="d-flex"
                        style={{
                          backgroundColor: '#512874',
                          width: '30px',
                          height: '30px',
                          borderRadius: '50%',
                          color: 'white',
                          marginRight: '10px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        +
                      </div>
                      <p style={{ color: '#512874', margin: 0 }}>Adicionar cidade</p>
                    </div>
                  </div>
                </Row>

                {/* CEPS DA REGIÃO DE VENDA */}
                <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                  <Col span={24}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      CEP(s)
                    </Title>
                  </Col>

                  {cepsTableRows.length > 0 && (
                    <Table
                      bordered={true}
                      showHeader={false}
                      pagination={false}
                      style={{ width: '100%', cursor: 'pointer' }}
                      className="table-antd"
                      dataSource={cepsTableRows}
                      columns={[
                        {
                          dataIndex: 'value',
                          render: (row: string, cep, index) => (
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <strong>{cep.value + ' ' + (index + 1)}</strong>
                              <p
                                style={{
                                  color: 'red',
                                  textDecoration: 'underline',
                                  margin: 0,
                                  padding: 0,
                                  cursor: 'pointer',
                                }}
                                onClick={() => removeCepSalesRegion(cep.key, index)}
                              >
                                Excluir
                              </p>
                            </div>
                          ),
                        },
                      ]}
                      expandable={{
                        expandRowByClick: true,
                        expandedRowRender: (cep, index) => (
                          <Row style={{ marginRight: 10, width: '100%' }}>
                            <Col span={24} style={{ marginRight: 10, width: '65%' }}>
                              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                                CEP <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                              </Title>
                              <Form.Item
                                name={[`operationalAreaCeps`, index, 'cep']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Informe o cep',
                                  },
                                ]}
                              >
                                <CustomInput style={{ width: '100%' }} placeholder="CEP" />
                              </Form.Item>
                            </Col>
                          </Row>
                        ),
                      }}
                    />
                  )}

                  <div
                    className="d-flex"
                    style={{
                      width: '100%',
                      alignItems: 'center',
                      fontSize: '18px',
                      marginTop: citiesTableRows.length > 0 ? '20px' : 0,
                      marginBottom: 30,
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        addCepSalesRegion();
                      }}
                    >
                      <div
                        className="d-flex"
                        style={{
                          backgroundColor: '#512874',
                          width: '30px',
                          height: '30px',
                          borderRadius: '50%',
                          color: 'white',
                          marginRight: '10px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        +
                      </div>
                      <p style={{ color: '#512874', margin: 0 }}>Adicionar CEP</p>
                    </div>
                  </div>
                </Row>

                <CustomButton type="primary" style={{ marginTop: 40, width: '100%' }} onClick={showModalConfirmation}>
                  {isEditing ? 'Cadastrar' : 'Editar'}
                </CustomButton>
              </Form>
            )}
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default SalesRegionForm;
