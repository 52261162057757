import React, { useEffect } from 'react';

import BrandsHeader from './components/BrandsHeader';
import BrandsContent from './components/BrandsContent';
import { useBrand } from '@hooks/BrandContext';
import Loading from '@components/Loading';
import { useClassification } from '@hooks/ClassificationContext';

const BrandsPage: React.FC = () => {
  const { loadBrands, loading: loadingBrand } = useBrand();
  const { loadClassifications, loading: loadingClassification } = useClassification();

  useEffect(() => {
    loadBrands();
    loadClassifications();
  }, []);

  if (loadingBrand || loadingClassification) return <Loading />;

  return (
    <>
      <BrandsHeader />
      <BrandsContent />
    </>
  );
};

export default BrandsPage;
