import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { message } from 'antd';

import * as ParametersService from '@services/parameter';

interface ParametersState {
  loadingParameters: boolean;
  parameters: any[];
  parametersPage: number;
  parametersTotal: number;
  parametersFilters: any;
  loadParameters: Function;

  loadingCreateParameterItem: boolean;
  createParameterItem: Function;

  parameterItems: any[];
  loadingParameterItems: boolean;
  loadParameterItemsByParameterID: Function;

  loadingDeleteParameterItem: boolean;
  deleteParameterItem: Function;
}

interface ParametersProviderProps {
  children: ReactNode;
}

export const ParametersContext = createContext<ParametersState>({} as ParametersState);

const ParametersProvider: React.FC<ParametersProviderProps> = ({ children }) => {
  const [parameters, setParameters] = useState<any[]>([]);
  const [parameterItems, setParameterItems] = useState<any[]>([]);
  const [parametersPage, setParametersPage] = useState<number>(0);
  const [parametersFilters, setParametersFilters] = useState({});
  const [parametersTotal, setParametersTotal] = useState<number>(0);
  const [loadingParameters, setLoadingParameters] = useState<boolean>(false);
  const [loadingCreateParameterItem, setLoadingCreateParameterItem] = useState<boolean>(false);
  const [loadingDeleteParameterItem, setLoadingDeleteParameterItem] = useState<boolean>(false);
  const [loadingParameterItems, setLoadingParameterItems] = useState<boolean>(false);

  const loadParameters = useCallback(
    async (page: number, filters: any) => {
      setLoadingParameters(true);
      setParametersPage(page === 0 ? 1 : page);

      try {
        const { data: _parameters } = await ParametersService.findParameters({
          parameterIsActive: true,
          page: page ? page - 1 : 0,
          pageSize: 10,
          ...(filters && { ...filters }),
        });

        if (_parameters) setParameters(_parameters.data);
        setParametersTotal(_parameters.total);
      } catch {
        message.error('Erro ao carregar parâmetros');
      } finally {
        setLoadingParameters(false);
      }
    },
    [setLoadingParameters, setParameters],
  );

  const loadParameterItemsByParameterID = useCallback(
    async (parameterId: number) => {
      setLoadingParameterItems(true);

      try {
        const { data: _parameter } = await ParametersService.findParameterItemsByParameterId(parameterId);

        setParameterItems(_parameter.items);
      } catch {
        message.error('Erro ao carregar parâmetros');
      } finally {
        setLoadingParameterItems(false);
      }
    },
    [setLoadingParameterItems, setParameterItems],
  );

  const createParameterItem = useCallback(
    async (
      parameterId: number,
      parameterItem: {
        variant: string;
        value: string;
      },
    ) => {
      setLoadingCreateParameterItem(true);

      try {
        await ParametersService.createParameterItem(parameterId, parameterItem);
        await loadParameterItemsByParameterID(parameterId);
        message.success('Parâmetro criado com sucesso');
      } catch {
        message.error('Erro ao criar parâmetro');
      } finally {
        setLoadingCreateParameterItem(false);
      }
    },
    [setLoadingCreateParameterItem],
  );

  const deleteParameterItem = useCallback(
    async (parameterId: number, sequence: number) => {
      setLoadingDeleteParameterItem(true);

      try {
        await ParametersService.deleteParameterItem(parameterId, sequence);
        await loadParameterItemsByParameterID(parameterId);
        message.success('Parâmetro deletado com sucesso');
      } catch {
        message.error('Erro ao deletar parâmetro');
      } finally {
        setLoadingDeleteParameterItem(false);
      }
    },
    [setLoadingDeleteParameterItem],
  );

  return (
    <ParametersContext.Provider
      value={{
        loadingParameters,
        loadingCreateParameterItem,
        loadingParameterItems,
        loadingDeleteParameterItem,
        parameters,
        parametersFilters,
        parametersPage,
        parametersTotal,
        parameterItems,
        loadParameters,
        createParameterItem,
        loadParameterItemsByParameterID,
        deleteParameterItem,
      }}
    >
      {children}
    </ParametersContext.Provider>
  );
};

const useParameters = () => {
  return useContext(ParametersContext);
};

export { ParametersProvider, useParameters };
