import LoadingComponents from '@components/LoadingComponents';
import { useCollection } from '@hooks/CollectionContext';
import { Collection } from '@models/Collection';
import { CustomButton } from '@styles/globals';
import { Form, Select } from 'antd';
import React, { useCallback, useEffect } from 'react';

interface CollectionsFormProps {
  onAdd: Function;
}
const CollectionsForm: React.FC<CollectionsFormProps> = ({ onAdd }) => {
  const { collections, loadCollections, loading } = useCollection();

  useEffect(() => {
    loadCollections(0, { listAll: true });
  }, []);

  const handleFormSubmit = useCallback((values: string) => {
    const collection = JSON.parse(values);

    onAdd(collection.collectionId, collection.description);
  }, []);

  if (loading) return <LoadingComponents />;

  return (
    <Form layout="vertical" onFinish={(values: any) => handleFormSubmit(values.collection)}>
      <Form.Item className="mb-2" label="Coleção" name="collection">
        <Select style={{ display: 'block', marginBottom: '16px' }} showSearch allowClear placeholder="Coleção">
          {collections?.map((collection: Collection) => (
            <Select.Option key={collection.collectionId} value={JSON.stringify(collection)}>
              {collection.description}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <CustomButton htmlType="submit" type="primary" className="w-100">
        Adicionar
      </CustomButton>
    </Form>
  );
};

export default CollectionsForm;
