import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

import * as OfficialStoreOrderClassificationService from '@services/officialStoreOrderClassification';
import { Classification } from '@models/Classification';
import { message } from 'antd';

interface OfficialStoreOrderClassificationState {
  loading: boolean;
  officialStoreOrderClassifications: Classification[];
  loadOfficialStoreOrderClassifications: Function;
  createOfficialStoreOrderClassification: Function;
  deleteOfficialStoreOrderClassification: Function;
}

interface OfficialStoreOrderClassificationProviderProps {
  children: ReactNode;
}

export const OfficialStoreOrderClassificationContext = createContext<OfficialStoreOrderClassificationState>(
  {} as OfficialStoreOrderClassificationState,
);

const OfficialStoreOrderClassificationProvider: React.FC<OfficialStoreOrderClassificationProviderProps> = ({
  children,
}) => {
  const [officialStoreOrderClassifications, setOfficialStoreOrderClassifications] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadOfficialStoreOrderClassifications = useCallback(
    async (officialStoreId: number) => {
      setLoading(true);

      const { data: _officialStoreOrderClassifications } =
        await OfficialStoreOrderClassificationService.getClassifications(officialStoreId);

      if (_officialStoreOrderClassifications) setOfficialStoreOrderClassifications(_officialStoreOrderClassifications);

      setLoading(false);
    },
    [officialStoreOrderClassifications],
  );

  const createOfficialStoreOrderClassification = async (
    officialStoreId: number,
    classification: Partial<Classification>,
  ) => {
    try {
      await OfficialStoreOrderClassificationService.createOrderClassification(officialStoreId, classification);
      message.success('Classificação incluída com sucesso!');

      setOfficialStoreOrderClassifications((current) => [...current, classification]);
    } catch {
      message.error('Erro ao incluir classificação!');
    }
  };

  const deleteOfficialStoreOrderClassification = async (
    officialStoreId: number,
    classification: Partial<Classification>,
  ) => {
    try {
      const index = officialStoreOrderClassifications.findIndex(
        (_classification) =>
          _classification.typeCode == classification.typeCode && _classification.code == classification.code,
      );

      if (index !== -1) {
        await OfficialStoreOrderClassificationService.deleteOrderClassification(officialStoreId, classification);
        officialStoreOrderClassifications.splice(index, 1);
        setOfficialStoreOrderClassifications([...officialStoreOrderClassifications]);
        message.success('Classificação deletada com sucesso!');
      }
    } catch (error) {
      message.error('Erro ao deletar classificação!');
    }
  };

  return (
    <OfficialStoreOrderClassificationContext.Provider
      value={{
        loading,
        officialStoreOrderClassifications,
        loadOfficialStoreOrderClassifications,
        createOfficialStoreOrderClassification,
        deleteOfficialStoreOrderClassification,
      }}
    >
      {children}
    </OfficialStoreOrderClassificationContext.Provider>
  );
};

const useOfficialStoreOrderClassification = () => {
  return useContext(OfficialStoreOrderClassificationContext);
};

export { OfficialStoreOrderClassificationProvider, useOfficialStoreOrderClassification };
