import React from 'react';

import DevolutionContent from './components/DevolutionContent';
import DevolutionHeader from './components/DevolutionHeader';

const DevolutionPage: React.FC = () => {
  return (
    <>
      <div className='interleaved-table-style'>
        <DevolutionHeader />
        <DevolutionContent />
      </div>
    </>
  );
};

export default DevolutionPage;
