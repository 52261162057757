import React from 'react';
import { Table, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { useSeller } from '@hooks/SellerContext';
import TableFilters from '@components/TableFilters';
import './styles.css';

const { Title } = Typography;

interface CustomersTabProps {}

const CustomersTab: React.FC<CustomersTabProps> = () => {
  const { currentSeller } = useSeller();

  const columns = [
    {
      title: 'Código',
      width: '100px',
      align: 'center' as 'center',
      dataIndex: 'personCode',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pelo Código'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.personCode ? record.personCode.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
      sorter: (clienteA: any, clienteB: any) => clienteA.personCode - clienteB.personCode,
    },
    {
      title: 'Lojista',
      dataIndex: 'name',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pelo Nome'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.personName ? record.personName.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
      sorter: (clienteA: any, clienteB: any) => clienteA.personName.trim().localeCompare(clienteB.personName.trim()),
    },
  ];

  return (
    <div className="mt-6 ml-4">
      <Title level={5}>Lojistas</Title>
      <Table
        scroll={{ x: 800 }}
        columns={columns}
        dataSource={currentSeller.customers}
        className="customers-table"
        rowKey={(record) => record.personCode}
      />
    </div>
  );
};

export default CustomersTab;
