import { CustomButton } from '@styles/globals';
import { Col, Form, Row, Space, Switch, Typography } from 'antd';
import React from 'react';

import { PhoneTypes, RepresentativePhone } from '@components/RepresentativeForm/models/representative-form-models';
import Select from 'antd/lib/select';
import { v4 } from 'uuid';
import { CustomInput } from './styles';

const { Title } = Typography;

interface AddRepresentativePhoneFormModalProps {
  onCloseModal: Function;
  addPhone: Function;
}

const AddRepresentativePhoneFormModal: React.FC<AddRepresentativePhoneFormModalProps> = ({
  onCloseModal,
  addPhone,
}) => {
  const [phonesForm] = Form.useForm();

  const [isDefault, setIsDefault] = React.useState<boolean>(true);

  React.useEffect(() => {
    phonesForm.setFields([
      { name: 'number', value: null },
      { name: 'typeCode', value: null },
      { name: 'branchLine', value: null },
    ]);
  }, []);

  const finishAddEmail = async () => {
    const email: RepresentativePhone = await phonesForm.validateFields();

    email.isDefault = isDefault;
    email.typeName = PhoneTypes.filter((e) => e.typeCode === email.typeCode)[0]?.typeName;
    email.key = v4();

    addPhone(email);
    onCloseModal();
  };

  const formatPhone = (value: string) => {
    if (value) {
      const phoneRegex = /^(\d{2})(\d{4,5})(\d{4})$/;
      const numericValue = value.replace(/\D/g, '');

      if (numericValue.length === 11) {
        phonesForm.setFieldsValue({
          number: value.replace(phoneRegex, '($1) $2-$3'),
        });
      } else {
        phonesForm.setFieldsValue({
          number: numericValue,
        });
      }
    }
  };

  return (
    <>
      <Form
        form={phonesForm}
        className="d-flex"
        style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}
      >
        <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Col span={12} style={{ paddingRight: 15 }}>
            <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
              Telefone <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
            </Title>

            <Form.Item
              name="number"
              rules={[
                {
                  required: true,
                  message: 'Informe o telefone',
                },
              ]}
            >
              <CustomInput
                style={{ width: '100%' }}
                placeholder="Telefone"
                onChange={(event) => formatPhone(event.target.value)}
                maxLength={15}
              />
            </Form.Item>
          </Col>

          <Col span={12} style={{ paddingRight: 15 }}>
            <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
              Tipo <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
            </Title>
            <Form.Item
              name="typeCode"
              rules={[
                {
                  required: true,
                  message: 'Informe o tipo do telefone',
                },
              ]}
            >
              <Select
                style={{ height: '48px', borderRadius: '8px' }}
                placeholder="Tipo"
                size="large"
                showSearch
                allowClear
              >
                {PhoneTypes?.length &&
                  PhoneTypes.map((email: { typeCode: number; typeName: string }) => (
                    <Select.Option key={email.typeCode} value={email.typeCode}>
                      {email.typeName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Col span={12} style={{ paddingRight: '15px' }}>
            <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
              Ramal
            </Title>
            <Form.Item name="branchLine">
              <CustomInput type="number" min="0" style={{ width: '100%' }} placeholder="Ramal" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Col span={24} style={{ marginBottom: '25px' }}>
            <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
              Telefone padrão <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
            </Title>
            <Space direction="horizontal">
              <div>
                <span>Sim: </span>
                <Switch checked={isDefault} onChange={() => setIsDefault(true)} />
              </div>
              <div>
                <span className="ml-4">Não: </span>
                <Switch
                  checked={!isDefault}
                  onChange={() => {
                    setIsDefault(false);
                  }}
                />
              </div>
            </Space>
          </Col>
        </Row>

        <CustomButton type="primary" style={{ marginTop: 5, width: '100%' }} onClick={finishAddEmail}>
          Adicionar telefone
        </CustomButton>
      </Form>
    </>
  );
};

export default AddRepresentativePhoneFormModal;
