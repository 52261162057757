import React from 'react';

import Addresses from '../Addresses';
import Customers from '../Customers';
import Orders from '../Orders';
import ProfileConnectionsHeader from '../ProfileConnectionsHeader';

interface ProfileConnectionsProps {}

const ProfileConnections: React.FC<ProfileConnectionsProps> = () => {
  return (
    <>
      <ProfileConnectionsHeader />
      <Customers />
      <Orders />
      <Addresses />
    </>
  );
};

export default ProfileConnections;
