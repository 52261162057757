import { Subcategory } from '@models/Subcategory';
import { Response } from '@models/Response';
import * as api from './api';

export function get(categoryId: number): Promise<Response<Subcategory>> {
  let url: string = `categories/${categoryId}/subcategories?subcategoryIsActive=true`;
  return api.get(url);
}

export function addClassification(subCategoryId: number, body: any): Promise<any> {
  return api.put(`categories/subcategories/${subCategoryId}/classification`, body);
}

export function deleteClassification(subCategoryId: number, body: any): Promise<any> {
  return api.remove(`categories/subcategories/${subCategoryId}/classification`, { data: body });
}
