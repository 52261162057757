import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { message } from 'antd';

import { Seller } from '@models/Seller';
import * as SellerService from '@services/seller';
import { INITIAL_PAGE, SELLER_PAGINATION_SIZE } from '@utils/constants';

interface SellerState {
  currentPage: number;
  totalPages: number;
  sellers: any[];
  sellerFilters: any;
  loadingSellers: boolean;
  loadSellers: Function;

  currentSeller: Seller;
  loadSellerByCode: Function;
  loadingSellerByCode: boolean;

  loadingImageUpload: boolean;
  updateImage: Function;

  loadingChangePermission: boolean;
  updatePermission: Function;
}

interface SellerProviderProps {
  children: ReactNode;
}

export const SellerContext = createContext<SellerState>({} as SellerState);
const SellerProvider: React.FC<SellerProviderProps> = ({ children }) => {
  const [loadingSellers, setLoadingSellers] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [sellers, setSellers] = useState<any[]>([]);
  const [sellerFilters, setSellerFilters] = useState<any>({} as any);

  const [currentSeller, setCurrentSeller] = useState<Seller>({} as Seller);
  const [loadingSellerByCode, setLoadingSellerByCode] = useState<boolean>(false);

  const [loadingImageUpload, setLoadingImageUpload] = useState<boolean>(false);
  const [loadingChangePermission, setLoadingChangePermission] = useState<boolean>(false);

  const loadSellers = async (page: number, filters?: { searchByNameOrCnpj: string; listAll: boolean }) => {
    setLoadingSellers(true);

    setCurrentPage(page);

    if (filters) setSellerFilters(filters);
    else setSellerFilters({});

    try {
      const options = {
        page,
        pageSize: SELLER_PAGINATION_SIZE,
        orderBy: 'name',
        direction: 'ASC',
        ...(filters && { ...filters }),
      };
      const { data: _sellers } = await SellerService.getSellers(options);
      if (_sellers.total === 0) message.warning('Nenhum registro encontrado!');

      setSellers(_sellers.data);
      setTotalPages(_sellers.total);
    } catch {
      message.error('Erro ao carregar vendedores!');
    } finally {
      setLoadingSellers(false);
    }
  };

  const loadSellerByCode = useCallback(
    async (sellerCode: number) => {
      setLoadingSellerByCode(true);

      const { data } = await SellerService.getSellerByCode(sellerCode);

      setCurrentSeller(data);
      setLoadingSellerByCode(false);
    },
    [setCurrentSeller, setLoadingSellerByCode],
  );

  const updateImage = useCallback(
    async (sellerCode: number, file: any) => {
      setLoadingImageUpload(true);
      try {
        const { data: _image } = await SellerService.uploadImage(file);

        await SellerService.updateAttribute(sellerCode, {
          property: 'photoUrl',
          value: _image.url,
        });

        setCurrentSeller({ ...currentSeller, photoUrl: _image.url });
        message.success('Imagem alterada com sucesso!');
      } catch {
        message.error('Erro ao alterar imagem!');
      } finally {
        setLoadingImageUpload(false);
      }
    },
    [currentSeller, setLoadingImageUpload, setCurrentSeller],
  );

  const updatePermission = useCallback(
    async (sellerCode: number, permission: any) => {
      setLoadingChangePermission(true);

      await SellerService.updateAttribute(sellerCode, permission);

      await loadSellerByCode(sellerCode);

      setLoadingChangePermission(false);
    },
    [setLoadingChangePermission, loadSellerByCode],
  );

  return (
    <SellerContext.Provider
      value={{
        currentPage,
        totalPages,
        loadingSellers,
        loadingSellerByCode,
        loadingImageUpload,
        loadingChangePermission,
        currentSeller,
        sellerFilters,
        sellers,
        loadSellers,
        loadSellerByCode,
        updateImage,
        updatePermission,
      }}
    >
      {children}
    </SellerContext.Provider>
  );
};

const useSeller = () => {
  return useContext(SellerContext);
};

export { SellerProvider, useSeller };
