import { CheckOutlined, CloseOutlined, EditFilled, FilterFilled, PlusCircleFilled } from '@ant-design/icons';
import { Col, Drawer, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import CollectionForm from '@components/CollectionForm';
import FiltersForm from '@components/FiltersForm';
import FiltersList, { ListFilterType } from '@components/FiltersList';
import HeaderButton from '@components/HeaderButton';
import LayoutHeader from '@components/LayoutHeader';
import Loading from '@components/Loading';
import ProductsFiltersForm from '@components/ProductsFiltersForm';
import { useCategory } from '@hooks/CategoryContext';
import { useCollection } from '@hooks/CollectionContext';
import { useColor } from '@hooks/ColorContext';
import { useIntegration } from '@hooks/IntegrationContext';
import { useReference } from '@hooks/ReferenceContext';
import { Collection } from '@models/Collection';
import { FullScreenModal } from '@styles/globals';
import { useHistory } from 'react-router-dom';
import { CustomSwitch } from './styles';

const filtersNameMap: any = {
  name: 'Descrição',
  reference: 'Referência',
  subCategoryId: 'Cód. SubCategoria',
  colorCode: 'Cód. Cor',
  size: 'Tamanho',
};

interface CollectionDetailsHeaderProps {
  collectionId: number;
}
const CollectionDetailsHeader: React.FC<CollectionDetailsHeaderProps> = ({ collectionId }) => {
  const { hasMerpIntegration } = useIntegration();
  const { loadColorsGroupedByName, loading: loadingColors, colors } = useColor();
  const { loadRequestCategory, loading: loadingCategories, categories } = useCategory();
  const { loadReferences, referencesFilters } = useReference();
  const { collections, allCollections, loadAllCollections } = useCollection();
  const history = useHistory();

  const [modalNewCollectionVisible, setModalNewCollectionVisible] = useState<boolean>(false);
  const [initialPageModalNewCollection, setInitialPageModalNewCollection] = useState<number>(0);
  const [filtersDrawerIsVisible, setfiltersDrawerIsVisible] = useState<boolean>(false);
  const [collection, setCollection] = useState<Collection>();

  useEffect(() => {
    loadColorsGroupedByName();
    loadRequestCategory();
    loadAllCollections();
  }, []);

  useEffect(() => {
    if (collections && collections.length) {
      const actualCollection = collections.find((col: Collection) => col.collectionId === collectionId);
      setCollection(actualCollection);
    } else {
      loadAllCollections();
    }
  }, [collections, collectionId]);

  useEffect(() => {
    if (allCollections && allCollections?.length && !collections?.length) {
      const actualCollection = allCollections.find((col: Collection) => col.collectionId === collectionId);
      setCollection(actualCollection);
    }
  }, [allCollections]);

  const handleCloseFiltersDrawer = useCallback(() => {
    setfiltersDrawerIsVisible(false);
  }, [setfiltersDrawerIsVisible]);

  const handleShowFiltersDrawer = useCallback(() => {
    setfiltersDrawerIsVisible(true);
  }, [setfiltersDrawerIsVisible]);

  const handleCloseNewCollectionModal = useCallback(() => {
    loadReferences(0, true, { ...referencesFilters, collectionId });
    setModalNewCollectionVisible(false);
  }, [referencesFilters, loadReferences, setModalNewCollectionVisible]);

  const handleShowNewCollectionModalPage1 = useCallback(() => {
    setInitialPageModalNewCollection(0);
    setModalNewCollectionVisible(true);
  }, [setModalNewCollectionVisible, setInitialPageModalNewCollection]);

  const handleShowNewCollectionModalPage3 = useCallback(() => {
    setInitialPageModalNewCollection(2);
    setModalNewCollectionVisible(true);
  }, [setModalNewCollectionVisible, setInitialPageModalNewCollection]);

  const onSubmitFiltersForm = useCallback(
    (values: any) => {
      loadReferences(0, true, { ...values, collectionId });
    },
    [collectionId, loadReferences],
  );

  const onClearFiltersForm = useCallback(() => {
    loadReferences(0, true, { collectionId });
  }, [collectionId, loadReferences]);

  const onRemoveFilter = ({ key }: ListFilterType) => {
    const newFilters = { ...referencesFilters, [key]: undefined };
    loadReferences(0, true, newFilters);
  };

  const getHeaderButtons = () => {
    return (
      <Row>
        <Col>
          <FiltersList
            columns={[
              { title: 'Referência', key: 'reference' },
              { title: 'Nome', key: 'name' },
              { title: 'Cód. Cor', key: 'colorCode' },
              { title: 'Tamanho', key: 'size' },
              { title: 'Cód. Coleção', key: 'collectionId?' },
              { title: 'Cód. SubCategoria', key: 'subCategoryId' },
            ]}
            dataSource={referencesFilters}
            onRemove={onRemoveFilter}
          />
        </Col>
        <Col>
          <HeaderButton
            text="Filtros"
            onClick={handleShowFiltersDrawer}
            Icon={<FilterFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
          />
        </Col>

        <Col>
          <HeaderButton
            text="Notificar Lojistas"
            onClick={() => null}
            Icon={
              <CustomSwitch
                size="small"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked
                disabled
                onChange={() => null}
              />
            }
          />
        </Col>
        {hasMerpIntegration && (
          <Col>
            <HeaderButton
              text="Editar coleção"
              onClick={handleShowNewCollectionModalPage1}
              Icon={<EditFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
            />
          </Col>
        )}
        <Col>
          <HeaderButton
            text="Adicionar novo filtro"
            onClick={handleShowNewCollectionModalPage3}
            Icon={<PlusCircleFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
          />
        </Col>
      </Row>
    );
  };

  if (loadingColors || loadingCategories) return <Loading />;

  return (
    <>
      <LayoutHeader
        title={collection?.description ?? ''}
        extraComponent={getHeaderButtons()}
        onReturn={() => history.go(-1)}
        titleLevel={3}
      />

      <Drawer
        title="Filtros"
        placement="left"
        onClose={handleCloseFiltersDrawer}
        visible={filtersDrawerIsVisible}
        width={400}
      >
        <FiltersForm
          onCloseDrawer={handleCloseFiltersDrawer}
          onSubmit={onSubmitFiltersForm}
          onClearFilters={onClearFiltersForm}
          filtersForm={<ProductsFiltersForm colors={colors} categories={categories} />}
        />
      </Drawer>

      <FullScreenModal
        destroyOnClose={true}
        visible={modalNewCollectionVisible}
        onOk={handleCloseNewCollectionModal}
        onCancel={handleCloseNewCollectionModal}
        footer={null}
        forceRender={false}
      >
        <Row align="middle" justify="center" style={{ height: '100%' }}>
          <Col
            span={24}
            className="d-flex"
            style={{ height: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
          >
            <CollectionForm
              onCloseModal={handleCloseNewCollectionModal}
              page={initialPageModalNewCollection}
              isEditing={false}
              collectionToEdit={collection}
            />
          </Col>
        </Row>
      </FullScreenModal>
    </>
  );
};

export default CollectionDetailsHeader;
