import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import {
  AppstoreOutlined,
  LinkOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProfileOutlined,
  RollbackOutlined,
  ScheduleOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  SkinOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Skeleton } from 'antd';

import { ReactComponent as LogoutIcon } from '@assets/icons/logout.svg';
import { ReactComponent as ModaVestIcon } from '@assets/icons/modavest.svg';
import ModavestBrandLogo from '@assets/png/brand-modavest.png';
import ModaVestBrand from '@assets/svg/modavest-brand.svg';

import { useKeycloak } from '@react-keycloak/web';
import { KeycloakProfile } from 'keycloak-js';

import { useIntegration } from '@hooks/IntegrationContext';
import { useManufacturer } from '@hooks/ManufacturerContext';
import { IntegrationPermissionEnum } from '@models/enum/IntegrationPermission';
import BrandsPage from '@pages/BrandsPage';
import CategoryPage from '@pages/CategoryPage';
import CollaboratorsPage from '@pages/CollaboratorsPage';
import CollectionDetailsPage from '@pages/CollectionDetailsPage';
import CollectionPage from '@pages/CollectionPage';
import CustomerProfilePage from '@pages/CustomerProfilePage';
import CustomersPage from '@pages/CustomersPage';
import DevolutionPage from '@pages/DevolutionPage';
import GoalDetailsPage from '@pages/GoalDetailsPage';
import GoalsPage from '@pages/GoalsPage';
import GridPage from '@pages/GridPage';
import IntegrationPage from '@pages/IntegrationPage';
import LinksPage from '@pages/LinksPage';
import ManufacturerPage from '@pages/ManufacturerPage';
import OfficialStoreCollectionsPage from '@pages/OfficialStoreCollectionsPage';
import OfficialStoreMenuPage from '@pages/OfficialStoreMenuPage';
import OfficialStoreNewPage from '@pages/OfficialStoreNewPage';
import OfficialStoreOrderClassificationsPage from '@pages/OfficialStoreOrderClassificationsPage';
import OfficialStorePage from '@pages/OfficialStorePage';
import OfficialStorePaymentPage from '@pages/OfficialStorePaymentPage';
import OfficialStoreProfilePage from '@pages/OfficialStoreProfilePage';
import OfficialStoreQuestionsPage from '@pages/OfficialStoreQuestionsPage';
import OfficialStoreRepresentativePage from '@pages/OfficialStoreRepresentativePage';
import OfficialStoreSellerPage from '@pages/OfficialStoreSellerPage';
import OfficialStoreStockPage from '@pages/OfficialStoreStockPage';
import OrderPage from '@pages/OrderPage';
import ParametersPage from '@pages/ParametersPage';
import PaymentPage from '@pages/PaymentPage';
import PriceTablesPage from '@pages/PriceTablesPage';
import ProductsPage from '@pages/ProductsPage';
import RepresentativesProfilePage from '@pages/RepresentativeProfilePage';
import RepresentativesPage from '@pages/RepresentativesPage';
import SalesRegionPage from '@pages/SalesRegionPage';
import SellersPage from '@pages/SellersPage';
import SellersProfilePage from '@pages/SellersProfilePage';
import ShoppingBagPage from '@pages/ShoppingBagPage';
import UploadImagesPage from '@pages/UploadImagesPage';
import UploadVideosPage from '@pages/UploadVideosPage';
import VisitsPage from '@pages/VisitsPage';
import { Container, Content, Footer, Header } from './styles';
import './styles.css';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface KeycloakJwtTokenParsed extends Keycloak.KeycloakTokenParsed {
  integration_id: number;
}

interface IntegrationAdditionalFieldsProps {
  additionalFieldsPhotoUrl: string;
}

const MainPage: React.FC = () => {
  const { hasMerpIntegration, setCurrentIntegrationById } = useIntegration();
  const [user, setUser] = useState<KeycloakProfile>();
  const { path, url } = useRouteMatch();
  const { keycloak } = useKeycloak();
  const history = useHistory();
  const { fields, loadRequestFields, permissions, loadPermissions, loadingPermissions } = useManufacturer();
  const [collapsed, setCollapsed] = useState(false);
  const [isCollaborator, setIsCollaborator] = useState<boolean>(false);

  let { additionalFieldsPhotoUrl }: IntegrationAdditionalFieldsProps = fields;

  const handleSignout = useCallback(() => {
    keycloak?.logout();
  }, []);

  const openUrl = useCallback(
    (_path: string) => {
      history.push(_path);
    },
    [history],
  );

  useEffect(() => {
    if (keycloak) {
      const allowedRoles = ['ans', 'collaborator', 'manufacturer'];
      let hasPermission = false;

      allowedRoles.forEach((role) => {
        if (keycloak.hasRealmRole(role)) hasPermission = true;
      });

      if (!hasPermission) {
        handleSignout();
      }

      setIsCollaborator(keycloak.hasRealmRole('collaborator'));
      if (keycloak?.authenticated) {
        keycloak.loadUserProfile().then((profile: KeycloakProfile) => {
          setUser(profile);
        });
        const { integration_id } = keycloak.tokenParsed as KeycloakJwtTokenParsed;
        if (integration_id) {
          localStorage.setItem('@modavest/integration_id', integration_id.toString());
          setCurrentIntegrationById(integration_id);
        }
      }
    }
  }, [keycloak]);

  useEffect(() => {
    loadRequestFields();
  }, []);

  useEffect(() => {
    if (fields?.integrationId?.integrationId) {
      loadPermissions(fields?.integrationId?.integrationId);
    }
  }, [fields]);

  const verifyIsCollaboratorBlockedRoute = (_path: string, Component: React.FC) => {
    if (!isCollaborator) {
      return (
        <Route exact path={`${_path}`}>
          <Component />
        </Route>
      );
    }
  };

  const verifyPermission = (path: string, permissionNeeded: IntegrationPermissionEnum, Component: React.FC) => {
    if (permissions?.includes(permissionNeeded)) {
      return (
        <Route exact path={`${path}`}>
          <Component />
        </Route>
      );
    }
  };

  return (
    <Container>
      <Sider style={{ overflowY: 'auto' }} collapsed={collapsed} theme="dark">
        {collapsed ? (
          <ModaVestIcon className="ml-3 my-4" width="40" height="40" />
        ) : (
          <>
            <img className="ml-6 my-4" src={ModaVestBrand} title="ModaVest" alt="logotipo-modavest" />
            <div className="text-center">
              <h3 className="menu-color">{user?.firstName}</h3>
            </div>
          </>
        )}
        <Skeleton
          title={false}
          loading={loadingPermissions}
          active
          paragraph={{ rows: 25, style: { margin: '2rem 0.5rem' } }}
        >
          <Menu theme="dark" mode="inline">
            {!isCollaborator && !hasMerpIntegration && (
              <Menu.Item onClick={() => openUrl(`${url}/categorias`)} key="categorias" icon={<AppstoreOutlined />}>
                Categorias
              </Menu.Item>
            )}

            <Menu.Item onClick={() => openUrl(`${url}/coleções`)} key="coleções" icon={<SkinOutlined />}>
              Coleções
            </Menu.Item>

            <Menu.Item onClick={() => openUrl(`${url}/lojistas`)} key="lojistas" icon={<UserOutlined />}>
              Lojistas
            </Menu.Item>

            {!hasMerpIntegration && (
              <Menu.Item onClick={() => openUrl(`${url}/metas`)} key="metas" icon={<ScheduleOutlined />}>
                Metas
              </Menu.Item>
            )}

            <Menu.Item
              onClick={() => openUrl(`${url}/lojas-oficiais`)}
              key="lojas-oficiais"
              icon={<ShoppingOutlined />}
            >
              Lojas Oficiais
            </Menu.Item>

            {!isCollaborator && (
              <Menu.Item onClick={() => openUrl(`${url}/minhas-marcas`)} key="minhas-marcas" icon={<ProfileOutlined />}>
                Minhas Marcas
              </Menu.Item>
            )}

            {!hasMerpIntegration && (
              <Menu.Item onClick={() => openUrl(`${url}/devolucoes`)} key="5" icon={<RollbackOutlined />}>
                Devoluções
              </Menu.Item>
            )}

            <Menu.Item onClick={() => openUrl(`${url}/pedidos`)} key="pedidos" icon={<ShoppingCartOutlined />}>
              Pedidos
            </Menu.Item>

            {permissions?.includes(IntegrationPermissionEnum.LINK) && (
              <Menu.Item onClick={() => openUrl(`${url}/links`)} key="links" icon={<LinkOutlined />}>
                Links
              </Menu.Item>
            )}

            {!hasMerpIntegration && (
              <Menu.Item onClick={() => openUrl(`${url}/vendedores`)} key="vendedores" icon={<TeamOutlined />}>
                Vendedores
              </Menu.Item>
            )}

            <SubMenu title="Produtos" icon={<ShoppingOutlined />} key="produtos">
              <Menu.Item onClick={() => openUrl(`${url}/tabela-preco`)}>Tabela de Preço</Menu.Item>
              <Menu.Item onClick={() => openUrl(`${url}/meus-produtos`)}>Meus Produtos</Menu.Item>
              <Menu.Item onClick={() => openUrl(`${url}/carregar-imagens`)}>Carregar Imagens</Menu.Item>
              {permissions?.includes(IntegrationPermissionEnum.VIDEO) && (
                <Menu.Item onClick={() => openUrl(`${url}/carregar-videos`)}>Carregar Vídeos</Menu.Item>
              )}
            </SubMenu>

            <SubMenu title="Representantes" key="representantes" icon={<TeamOutlined />}>
              <Menu.Item onClick={() => openUrl(`${url}/meus-representantes`)}>Meus Representantes</Menu.Item>
              <Menu.Item onClick={() => openUrl(`${url}/região-de-venda`)}>Região de venda</Menu.Item>
              {!hasMerpIntegration && <Menu.Item onClick={() => openUrl(`${url}/visitas`)}>Visitas</Menu.Item>}
              <Menu.Item onClick={() => openUrl(`${url}/sacolas`)}>Sacolas</Menu.Item>
            </SubMenu>

            {!isCollaborator && (
              <SubMenu title="Configurações" key="configurações" icon={<SettingOutlined />}>
                <Menu.Item onClick={() => openUrl(`${url}/pagamento`)}>Pagamento</Menu.Item>

                <Menu.Item onClick={() => openUrl(`${url}/colaboradores`)}>Meus Colaboradores</Menu.Item>

                <Menu.Item onClick={() => openUrl(`${url}/fabricantes`)}>Perfil de Fabricante</Menu.Item>

                {!hasMerpIntegration && !isCollaborator && (
                  <Menu.Item onClick={() => openUrl(`${url}/parametros`)}>Parâmetros</Menu.Item>
                )}

                {!hasMerpIntegration && <Menu.Item onClick={() => openUrl(`${url}/integracao`)}>Integração</Menu.Item>}

                {hasMerpIntegration && <Menu.Item onClick={() => openUrl(`${url}/grades`)}>Grades</Menu.Item>}
              </SubMenu>
            )}

            <Menu.Item
              key="14"
              className="mt-2 w-100 d-flex align-center pl-6 pr-0"
              onClick={handleSignout}
              icon={<LogoutIcon width="22" height="22" fill="white" />}
            >
              Sair
            </Menu.Item>
          </Menu>
        </Skeleton>
      </Sider>

      <Layout>
        <Header style={{ height: '90px' }} className="d-flex justify-space-between align-center">
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => {
              setCollapsed(!collapsed);
            },
          })}
          <img src={additionalFieldsPhotoUrl} width={80} alt="" />
          <img src={ModavestBrandLogo} width={200} />
        </Header>

        <Content style={{ backgroundColor: '#f5f5f5' }} className="py-4 px-8 overflow-auto">
          <Switch>
            {!hasMerpIntegration && verifyIsCollaboratorBlockedRoute(`${path}/categorias`, CategoryPage)}

            {verifyIsCollaboratorBlockedRoute(`${path}/pagamento`, PaymentPage)}

            {verifyIsCollaboratorBlockedRoute(`${path}/colaboradores`, CollaboratorsPage)}

            {verifyIsCollaboratorBlockedRoute(`${path}/fabricantes`, ManufacturerPage)}

            {!hasMerpIntegration && !isCollaborator && (
              <Route exact path={`${path}/parametros`}>
                <ParametersPage />
              </Route>
            )}

            {verifyIsCollaboratorBlockedRoute(`${path}/minhas-marcas`, BrandsPage)}

            {verifyPermission(`${path}/links`, IntegrationPermissionEnum.LINK, LinksPage)}
            {verifyPermission(`${path}/carregar-videos`, IntegrationPermissionEnum.VIDEO, UploadVideosPage)}

            {!hasMerpIntegration && (
              <Route exact path={`${path}/devolucoes`}>
                <DevolutionPage />
              </Route>
            )}

            {!hasMerpIntegration && (
              <Route exact path={`${path}/integracao`}>
                <IntegrationPage />
              </Route>
            )}
            {!hasMerpIntegration && (
              <Route exact path={`${path}/vendedores`}>
                <SellersPage />
              </Route>
            )}
            {!hasMerpIntegration && (
              <Route exact path={`${path}/vendedores/perfil/:id`}>
                <SellersProfilePage />
              </Route>
            )}
            <Route exact path={`${path}/tabela-preco`}>
              <PriceTablesPage />
            </Route>
            <Route exact path={`${path}/meus-produtos`}>
              <ProductsPage />
            </Route>
            <Route exact path={`${path}/carregar-imagens`}>
              <UploadImagesPage />
            </Route>
            <Route exact path={`${path}/meus-representantes`}>
              <RepresentativesPage />
            </Route>
            <Route exact path={`${path}/meus-representantes/perfil/:id`}>
              <RepresentativesProfilePage />
            </Route>
            <Route exact path={`${path}/região-de-venda`}>
              <SalesRegionPage />
            </Route>
            {!hasMerpIntegration && (
              <Route exact path={`${path}/visitas`}>
                <VisitsPage />
              </Route>
            )}
            <Route exact path={`${path}/sacolas`}>
              <ShoppingBagPage />
            </Route>
            <Route exact path={`${path}/coleções`}>
              <CollectionPage />
            </Route>
            <Route exact path={`${path}/coleções/detalhes/:id`}>
              <CollectionDetailsPage />
            </Route>
            <Route exact path={`${path}/lojistas`}>
              <CustomersPage />
            </Route>
            <Route exact path={`${path}/lojistas/perfil/:id`}>
              <CustomerProfilePage />
            </Route>
            <Route exact path={`${path}/lojas-oficiais`}>
              <OfficialStorePage />
            </Route>
            <Route exact path={`${path}/lojas-oficiais/nova-loja`}>
              <OfficialStoreNewPage />
            </Route>
            <Route exact path={`${path}/lojas-oficiais/perfil/:id`}>
              <OfficialStoreProfilePage />
            </Route>
            <Route exact path={`${path}/lojas-oficiais/perfil/:id/colecoes`}>
              <OfficialStoreCollectionsPage />
            </Route>
            <Route exact path={`${path}/lojas-oficiais/perfil/:id/menu`}>
              <OfficialStoreMenuPage />
            </Route>
            <Route exact path={`${path}/lojas-oficiais/perfil/:id/pedidos/classificacoes`}>
              <OfficialStoreOrderClassificationsPage />
            </Route>
            <Route exact path={`${path}/lojas-oficiais/perfil/:id/pagamento`}>
              <OfficialStorePaymentPage />
            </Route>
            <Route exact path={`${path}/lojas-oficiais/perfil/:id/representantes`}>
              <OfficialStoreRepresentativePage />
            </Route>
            {!hasMerpIntegration && (
              <Route exact path={`${path}/lojas-oficiais/perfil/:id/vendedores`}>
                <OfficialStoreSellerPage />
              </Route>
            )}
            <Route exact path={`${path}/lojas-oficiais/perfil/:id/perguntas`}>
              <OfficialStoreQuestionsPage />
            </Route>
            {!hasMerpIntegration && (
              <Route exact path={`${path}/lojas-oficiais/perfil/:id/estoque`}>
                <OfficialStoreStockPage />
              </Route>
            )}
            <Route exact path={`${path}/pedidos`}>
              <OrderPage />
            </Route>
            {!hasMerpIntegration && (
              <Route exact path={`${path}/metas`}>
                <GoalsPage />
              </Route>
            )}
            {!hasMerpIntegration && (
              <Route exact path={`${path}/metas/:id`}>
                <GoalDetailsPage />
              </Route>
            )}
            <Route exact path={`${path}/grades`}>
              <GridPage />
            </Route>

            {isCollaborator ? (
              <Redirect from="*" exact to={`${path}/coleções`} />
            ) : !hasMerpIntegration ? (
              <Redirect from="*" exact to={`${path}/categorias`} />
            ) : (
              <Redirect from="*" exact to={`${path}/coleções`} />
            )}
          </Switch>
        </Content>
        <Footer className="text-center">Copyright © {new Date().getFullYear()}</Footer>
      </Layout>
    </Container>
  );
};

export default MainPage;
