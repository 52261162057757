import React, { useCallback, useEffect } from 'react';
import { Form } from 'antd';
import { CustomButton } from '@styles/globals';

interface FiltersFormProps {
  onCloseDrawer: Function;
  onSubmit: Function;
  onClearFilters: Function;
  filtersForm: React.ReactNode;
  initialValues?: any;
}

const FiltersForm: React.FC<FiltersFormProps> = ({
  onCloseDrawer,
  onSubmit,
  onClearFilters,
  filtersForm,
  initialValues,
}) => {
  const [form] = Form.useForm();

  const clearFilters = useCallback(() => {
    form.resetFields();
    onClearFilters();
  }, [form, onClearFilters]);

  const handleSubmit = useCallback(
    (values: any) => {
      onSubmit(values);
      form.resetFields();
      onCloseDrawer();
    },

    [onSubmit, onCloseDrawer],
  );

  useEffect(() => {
    if (initialValues) form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit}>
      {filtersForm}

      <CustomButton className="mt-1 w-100" type="primary" htmlType="submit">
        Filtrar
      </CustomButton>

      <CustomButton className="mt-1 w-100" outlined="true" onClick={() => clearFilters()}>
        Reiniciar Filtros
      </CustomButton>
    </Form>
  );
};

export default FiltersForm;
