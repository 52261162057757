import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useSeller } from '@hooks/SellerContext';
import Loading from '@components/Loading';
import SellersContent from './components/SellersContent';
import SellersHeader from './components/SellersHeader';

const SellersPage: React.FC = () => {
  const { url } = useRouteMatch();
  const { loadSellers, loadingSellers } = useSeller();

  useEffect(() => {
    loadSellers(0);
  }, []);

  if (loadingSellers) return <Loading />;

  return (
    <>
      <SellersHeader />
      <SellersContent redirectBaseUrl={url} />
    </>
  );
};

export default SellersPage;
