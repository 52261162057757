import React, { useEffect } from 'react';

import ProductsHeader from './components/ProductsHeader';
import ProductsContent from './components/ProductsContent';
import { useReference } from '@hooks/ReferenceContext';

const ProductsPage: React.FC = () => {
  const { loadReferences } = useReference();
  const isActive = true
  
  useEffect(() => {
    loadReferences(0, isActive);
  }, []);

  return (
    <>
      <ProductsHeader />
      <ProductsContent />
    </>
  );
};

export default ProductsPage;
