import LayoutHeader from '@components/LayoutHeader';
import Loading from '@components/Loading';
import { useParameters } from '@hooks/ParametersContext';
import React, { useEffect } from 'react';
import ParametersContent from './components/ParametersContent';

const ParametersPage: React.FC = () => {
  const { loadParameters, loadingParameters } = useParameters();

  useEffect(() => {
    loadParameters(0);
  }, []);

  return (
    <>
      <LayoutHeader title="Parâmetros" />
      <ParametersContent />
    </>
  );
};

export default ParametersPage;
