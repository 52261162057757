import { EllipsisOutlined, ExclamationCircleFilled, PlusCircleFilled, SearchOutlined } from '@ant-design/icons';
import TableFilters from '@components/TableFilters';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
import { useOfficialStoreStock } from '@hooks/OfficialStoreStockContext';
import { Stock } from '@models/Stock';
import { LiquidityBufferAppEnum } from '@models/enum/LiquidityBufferApp';
import { CustomButton, FullScreenModal } from '@styles/globals';
import { normalizeString } from '@utils/normalize-string';
import { returnOrder } from '@utils/sort-boolean-array';
import {
  Col,
  Dropdown,
  Form,
  InputNumber,
  Menu,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Table,
  TableColumnProps,
  Typography,
} from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import * as S from '../../styles';
import NewStockForm from './components/NewStockForm';
const { Text } = Typography;

interface OfficialStoreStockRepresentativeProps {
  appType: LiquidityBufferAppEnum;
}

const BooleanRender = (value: boolean) => (value ? <Text>S</Text> : <Text>N</Text>);

const OfficialStoreStock: React.FC<OfficialStoreStockRepresentativeProps> = ({ appType }) => {
  const [form] = Form.useForm();
  const {
    loading: loadingStock,
    stocks,
    setStocksByLiquidityBufferApp,
    value,
    percentage,
    saveStocks,
  } = useOfficialStoreStock();
  const { loading: loadingOfficialStore, actualOfficialStore } = useOfficialStore();
  const [currentStock, setCurrentStock] = useState<Stock>();
  const [showModalNewStock, setShowModalNewStock] = useState<boolean>(false);
  const [liquidityBufferType, setLiquidityBufferType] = useState<'value' | 'percentage'>('value');

  useEffect(() => {
    form.setFieldsValue({
      officialStoreId: actualOfficialStore.officialStoreId,
      value: value[appType],
      percentage: percentage[appType],
      appType: appType,
      stocks: stocks[appType],
    });
    if (!value[appType]) {
      setLiquidityBufferType('percentage');
    } else {
      setLiquidityBufferType('value');
    }
  }, [stocks[appType], value[appType], percentage[appType], actualOfficialStore, appType]);

  const handleRemove = () => {
    const index = stocks[appType].findIndex(
      (item) => currentStock?.branchCode == item?.branchCode && currentStock?.stockCode == item?.stockCode,
    );
    if (index > -1) {
      stocks[appType].splice(index, 1);
      setStocksByLiquidityBufferApp(appType, [...stocks[appType]]);
    }
  };

  const showModalNoticeChangeTypeStock = (newValue: 'value' | 'percentage') => {
    Modal.confirm({
      icon: <ExclamationCircleFilled />,
      title: 'Cuidado',
      content: 'Ao mudar o tipo do colchão de estoque o valor no campo atual será perdido! Deseja continuar?',
      cancelText: 'Cancelar',
      okText: 'Continuar',
      okType: 'danger',
      onOk: () => {
        if (newValue === 'value') {
          form.setFieldsValue({ percentage: null });
        } else {
          form.setFieldsValue({ value: null });
        }
        setLiquidityBufferType(newValue);
      },
      onCancel: () => {},
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key="delete" onClick={() => handleRemove()}>
        Deletar
      </Menu.Item>
    </Menu>
  );

  const tableFilter = (
    { setSelectedKeys, selectedKeys, confirm, clearFilters = () => {} }: FilterDropdownProps,
    inputPlaceholder: string,
  ) => (
    <TableFilters
      inputPlaceholder={inputPlaceholder}
      inputValue={selectedKeys[0]}
      onChangeInputValue={setSelectedKeys}
      onFilter={confirm}
      onClearFilters={clearFilters}
    />
  );
  const columns: TableColumnProps<Stock>[] = [
    {
      title: 'Empresa',
      dataIndex: 'branchCode',
      align: 'left',
      filterDropdown: (filterDropdownProps) => tableFilter(filterDropdownProps, 'Filtrar pela Empresa'),
      filterIcon: <SearchOutlined />,
      onFilter: (value, record) => {
        return record.branchCode ? normalizeString(record.branchCode) == normalizeString(value) : false;
      },
      render: (value) => <Text>{value}</Text>,
      sorter: (stockA, stockB) => stockA.branchCode - stockB.branchCode,
    },
    {
      title: 'Cod Estoque',
      dataIndex: 'stockCode',
      align: 'center',
      filterDropdown: (filterDropdownProps) => tableFilter(filterDropdownProps, 'Filtrar pelo Cod Estoque'),
      filterIcon: <SearchOutlined />,
      onFilter: (value, record) => {
        return record.stockCode ? normalizeString(record.stockCode) == normalizeString(value) : false;
      },
      render: (value) => <Text>{value}</Text>,
      sorter: (stockA, stockB) => stockA.stockCode - stockB.stockCode,
    },
    {
      title: 'Estoque',
      dataIndex: 'estoque',
      align: 'center',
      render: BooleanRender,
      sorter: (stockA, stockB) => returnOrder(stockA.estoque, stockB.estoque),
    },
    {
      title: 'Ped. Venda',
      dataIndex: 'pedVenda',
      align: 'center',
      render: BooleanRender,
      sorter: (stockA, stockB) => returnOrder(stockA.pedVenda, stockB.pedVenda),
    },
    {
      title: 'Trans. Ent.',
      dataIndex: 'transEnt',
      align: 'center',
      render: BooleanRender,
      sorter: (stockA, stockB) => returnOrder(stockA.transEnt, stockB.transEnt),
    },
    {
      title: 'Trans. Saída.',
      dataIndex: 'transSaida',
      align: 'center',
      render: BooleanRender,
      sorter: (stockA, stockB) => returnOrder(stockA.transSaida, stockB.transSaida),
    },
    {
      title: 'Ord. Prod. Plan.',
      dataIndex: 'ordProdPlan',
      align: 'center',
      render: BooleanRender,
      sorter: (stockA, stockB) => returnOrder(stockA.ordProdPlan, stockB.ordProdPlan),
    },
    {
      title: 'Ord. Prod. em And.',
      dataIndex: 'ordProdEmAnd',
      align: 'center',
      render: BooleanRender,
      sorter: (stockA, stockB) => returnOrder(stockA.ordProdEmAnd, stockB.ordProdEmAnd),
    },
    {
      title: 'Ord. Prod. Ag. Lib.',
      dataIndex: 'ordProdAgLib',
      align: 'center',
      render: BooleanRender,
      sorter: (stockA, stockB) => returnOrder(stockA.ordProdAgLib, stockB.ordProdAgLib),
    },
    {
      title: 'Ord. de Compra',
      dataIndex: 'ordCompra',
      align: 'center',
      render: BooleanRender,
      sorter: (stockA, stockB) => returnOrder(stockA.ordCompra, stockB.ordCompra),
    },
    {
      align: 'right',
      render: (value) => (
        <Dropdown overlay={menu} trigger={['click']}>
          <EllipsisOutlined
            style={{ cursor: 'pointer', fontSize: '24px', fontWeight: 'bolder' }}
            onClick={() => setCurrentStock(value)}
          />
        </Dropdown>
      ),
    },
  ];

  const closeModalNewStock = () => {
    setShowModalNewStock(false);
  };

  const handleSave = () => {
    saveStocks(actualOfficialStore.officialStoreId, form.getFieldsValue());
  };

  const handleChangeLiquidityBufferType = (e: RadioChangeEvent) => {
    const newValue = e.target.value;
    showModalNoticeChangeTypeStock(newValue);
  };

  return (
    <>
      <Row className="mt-4">
        <Col span={24}>
          <Table
            loading={loadingStock || loadingOfficialStore}
            className="mt-2"
            columns={columns}
            dataSource={stocks[appType]}
            rowKey={(record) =>
              `${record.integrationId}${record.officialStoreId}${record.branchCode}${record.stockCode}`
            }
            scroll={{ x: 800 }}
            pagination={false}
          />
        </Col>
        <Col span={24} className="d-flex justify-center">
          <S.ButtonContainer onClick={() => setShowModalNewStock(true)}>
            <PlusCircleFilled twoToneColor="var(--primary-color)" style={{ fontSize: '20px' }} />
            <span style={{ marginLeft: '4px' }}>Adicionar estoque</span>
          </S.ButtonContainer>
        </Col>
        <Col span={24}>Colchão de estoque</Col>
        <Col span={12} className="d-flex justify-space-around align-center">
          <Radio.Group onChange={handleChangeLiquidityBufferType} value={liquidityBufferType}>
            <Radio value={'value'}>Quantidade</Radio>
            <Radio value={'percentage'}>Percentual</Radio>
          </Radio.Group>
          <Form
            key={`stockForm${actualOfficialStore.officialStoreId}`}
            layout="horizontal"
            className="d-flex align-center flex-row justify-space-around w-100"
            form={form}
          >
            <Form.Item name="officialStoreId" hidden></Form.Item>
            <Form.Item name="appType" hidden></Form.Item>
            <Form.Item name="stocks" hidden></Form.Item>
            <Form.Item name="value" label="Quantidade" className="mb-0 h-100 align-center">
              <InputNumber
                step={10}
                min={0}
                max={9999999999}
                autoComplete="off"
                placeholder="Quantidade"
                inputMode="numeric"
                disabled={liquidityBufferType === 'percentage'}
              />
            </Form.Item>
            <Text>ou</Text>
            <Form.Item name="percentage" label="Percentual" className="mb-0 h-10 align-center0">
              <InputNumber
                step={1}
                min={0}
                max={100}
                autoComplete="off"
                placeholder="Percentual"
                inputMode="decimal"
                formatter={(value) => `${value}%`}
                parser={(value) => value?.replace('%', '') as any}
                disabled={liquidityBufferType === 'value'}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col span={12} className="d-flex justify-end align-center">
          <CustomButton
            style={{ backgroundColor: 'var(--primary-color)', color: 'var(--support-color)' }}
            onClick={() => handleSave()}
          >
            Salvar
          </CustomButton>
        </Col>
      </Row>
      <FullScreenModal
        visible={showModalNewStock}
        onOk={closeModalNewStock}
        onCancel={closeModalNewStock}
        closable
        destroyOnClose={true}
        footer={null}
        bodyStyle={{ width: '50%' }}
      >
        <NewStockForm closeModal={closeModalNewStock} appType={appType} />
      </FullScreenModal>
    </>
  );
};

export default OfficialStoreStock;
