import { DatePicker, Form, Input } from 'antd';

interface OrderFiltersFormProps {}

const OrderFiltersForm: React.FC<OrderFiltersFormProps> = () => {
  return (
    <>
      <Form.Item className="mb-3" label="Pedido" name="orderCode">
        <Input placeholder="Código do Pedido..." />
      </Form.Item>

      <Form.Item className="mb-3" label="Representante" name="representative">
        <Input placeholder="Descrição ou CNPJ do Representante..." />
      </Form.Item>

      <Form.Item className="mb-3" label="Lojista" name="customer">
        <Input placeholder="Descrição ou CNPJ do Lojista..." />
      </Form.Item>

      <Form.Item className="mb-8" name="dates" label="Período da Data do Pedido">
        <DatePicker.RangePicker className="w-100" format="DD/MM/YYYY" />
      </Form.Item>
    </>
  );
};

export default OrderFiltersForm;
