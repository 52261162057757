import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 100%;
  height: 124px;

  padding: 16px;
  display: flex;
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  cursor: pointer;
  align-items: center;
`;

export const CardTitle = styled.h3`
  font-size: max(0.7vw, 0.9rem);
  color: #555;
  font-weight: bold;
  margin: 4px 0 0 0;
`;

export const CardCode = styled.span`
  font-size: max(0.6vw, 0.8rem);

  color: #555;
  font-weight: 400;
`;
