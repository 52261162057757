import { Response } from '@models/Response';
import { Stock } from '@models/Stock';
import { LiquidityBufferAppEnum } from '@models/enum/LiquidityBufferApp';
import { AxiosResponse } from 'axios';
import * as api from './api';

const stockBaseUrl = 'stock';

export interface SaveStocksBody {
  appType: LiquidityBufferAppEnum;
  integrationId?: number;
  officialStoreId: number;
  value: number | null;
  percentage: number | null;
  stocks: Stock[];
}

export interface GetStocksResponse {
  appType: LiquidityBufferAppEnum;
  integrationId: number;
  liquidityBufferId: number;
  officialStoreId: number;
  percentage: number;
  stocks: Stock[];
  value: number;
  createdAt: Date;
  updatedAt: Date;
}

export function getStocks(
  officialStoreId: number,
  liquidityBufferApp: LiquidityBufferAppEnum,
): Promise<AxiosResponse<GetStocksResponse>> {
  return api.get(`${stockBaseUrl}/${officialStoreId}?appType=${liquidityBufferApp}`);
}

export function saveStocks(officialStoreId: number, body: SaveStocksBody): Promise<Response<Stock>> {
  return api.put(`${stockBaseUrl}/${officialStoreId}`, body);
}
