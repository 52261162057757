import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';

import Loading from '@components/Loading';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
import OfficialStoreOrderClassificationsHeader from './components/OfficialStoreOrderClassificationsHeader';
import OfficialStoreOrderClassificationsContent from './components/OfficialStoreOrderClassificationsContent';
import { useOfficialStoreOrderClassification } from '@hooks/OfficialStoreOrderClassificationContext';

interface MatchParams {
  id: string;
}

const OfficialStoreOrderClassificationsPage: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();
  const { actualOfficialStore, findOfficialStoreById, loading } = useOfficialStore();
  const { loadOfficialStoreOrderClassifications, loading: loadingOfficialStoreClassifications } =
    useOfficialStoreOrderClassification();

  useEffect(() => {
    findOfficialStoreById(parseInt(params.id));
    loadOfficialStoreOrderClassifications(parseInt(params.id));
  }, []);

  if (loading || loadingOfficialStoreClassifications) return <Loading />;

  return (
    <>
      <OfficialStoreOrderClassificationsHeader officialStore={actualOfficialStore} />
      <OfficialStoreOrderClassificationsContent officialStore={actualOfficialStore} />
    </>
  );
};

export default OfficialStoreOrderClassificationsPage;
