import { CopyOutlined, SearchOutlined } from '@ant-design/icons';
import TableFilters from '@components/TableFilters';
import { useBrand } from '@hooks/BrandContext';
import { useLink } from '@hooks/LinkContext';
import { useManufacturer } from '@hooks/ManufacturerContext';
import { Link } from '@models/Link';
import { LinkFilter } from '@services/link';
import { Button, notification } from 'antd';
import Table, { ColumnType, TablePaginationConfig } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import { SorterResult } from 'antd/lib/table/interface';
import { Brand } from '@models/Brand';

const LinksPage: React.FC = () => {
  const { loading, links, total, currentPage, loadLinks } = useLink();
  const {
    loading: loadingManufacturer,
    manufacturers: manufacturersHeadOffice,
    loadRequestManufacturer,
  } = useManufacturer();
  const { loading: loadingBrand, brands, loadBrands } = useBrand();

  const [filterLinks, setFilterLinks] = useState<{
    filters: LinkFilter;
    pagination: TablePaginationConfig;
    orderBy: string;
    direction: 'ASC' | 'DESC';
  }>(Object.assign({}));

  //Constantes
  const divisor = '_&_';

  useEffect(() => {
    loadRequestManufacturer(true);
    loadBrands();
  }, []);

  useEffect(() => {
    const { filters, pagination, orderBy = 'linkTitle', direction = 'ASC' } = filterLinks;
    loadLinks(filters, pagination?.current, pagination?.pageSize, orderBy, direction);
  }, [filterLinks]);

  useEffect(() => {
    setFilterLinks({
      ...filterLinks,
      filters: {
        ...filterLinks.filters,
        linkManufacturerActive: true,
        brandIds: brands.map(({ brandId }) => +brandId),
        manufacturerIds: manufacturersHeadOffice.map(({ integrationId: { integrationId } }: any) => +integrationId),
      },
    });
  }, [brands, manufacturersHeadOffice]);

  const copyToClipboard = (textToCopy: string) => {
    notification.success({ message: 'URL copiada', description: 'A URL foi copiada para área de transferência' });
    copy(textToCopy);
  };

  const openUrlInNewTab = (url: string) => {
    window.open(url, '_blank');
  };

  const tablefilter = (
    { setSelectedKeys, selectedKeys, confirm, clearFilters }: any,
    placeholder: string,
    type?: 'text' | 'datePickerRange' | 'multipleSelect',
    options?: { loading: boolean; maxTagCount: number | 'responsive'; options: { label: string; value: any }[] },
  ) => {
    return (
      <TableFilters
        inputPlaceholder={placeholder}
        inputValue={selectedKeys}
        onChangeInputValue={setSelectedKeys}
        onFilter={confirm}
        onClearFilters={clearFilters}
        type={type}
        optionsToSelect={options}
      />
    );
  };

  const formatDate = (dateToFormat: string | number | Date) =>
    new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(new Date(dateToFormat));

  const handleFilter = (filters: any): LinkFilter => {
    let processedFilters: LinkFilter = { ...filters };

    if (filters?.brandIds) {
      processedFilters = {
        ...processedFilters,
        brandIds: filters.brandIds.map((element: string) => element.split(divisor)[0]),
      };
    } else {
      processedFilters = {
        ...processedFilters,
        brandIds: brands.map(({ brandId }) => +brandId),
      };
    }
    if (filters?.linkCreatedAt) {
      processedFilters = {
        ...processedFilters,
        linkCreatedAtInitial: moment(filters.linkCreatedAt[0], 'DD/MM/YYYY').toDate(),
        linkCreatedAtFinal: moment(filters.linkCreatedAt[1], 'DD/MM/YYYY').toDate(),
      };
    } else {
      processedFilters = {
        ...processedFilters,
        linkCreatedAtInitial: undefined,
        linkCreatedAtFinal: undefined,
      };
    }
    if (filters?.linkExpirationInitialDate) {
      processedFilters = {
        ...processedFilters,
        linkExpirationInitialDateInitial: moment(filters.linkExpirationInitialDate[0], 'DD/MM/YYYY').toDate(),
        linkExpirationInitialDateFinal: moment(filters.linkExpirationInitialDate[1], 'DD/MM/YYYY').toDate(),
      };
    } else {
      processedFilters = {
        ...processedFilters,
        linkExpirationInitialDateInitial: undefined,
        linkExpirationInitialDateFinal: undefined,
      };
    }
    if (filters?.linkExpirationFinalDate) {
      processedFilters = {
        ...processedFilters,
        linkExpirationFinalDateInitial: moment(filters.linkExpirationFinalDate[0], 'DD/MM/YYYY').toDate(),
        linkExpirationFinalDateFinal: moment(filters.linkExpirationFinalDate[1], 'DD/MM/YYYY').toDate(),
      };
    } else {
      processedFilters = {
        ...processedFilters,
        linkExpirationFinalDateInitial: undefined,
        linkExpirationFinalDateFinal: undefined,
      };
    }
    return processedFilters;
  };

  const formatValueOfBrand = ({ brandId, brandName }: Brand): string => {
    return `${brandId}${divisor}${brandName}`;
  };

  const searchOutlinedIcon = <SearchOutlined />;

  const columns: ColumnType<Link>[] = [
    {
      title: 'Título',
      dataIndex: 'linkTitle',
      sorter: true,
      filterDropdown: (props) => tablefilter(props, 'Filtrar por título'),
      filterIcon: searchOutlinedIcon,
    },
    {
      title: 'Marca',
      dataIndex: 'brandIds',
      filterDropdown: (props) =>
        tablefilter(props, 'Filtrar por marca', 'multipleSelect', {
          loading: loadingBrand,
          maxTagCount: 'responsive',
          options: brands.map((brand) => ({
            label: brand.brandName,
            value: formatValueOfBrand(brand),
          })),
        }),
      filterIcon: searchOutlinedIcon,
      render: (_, { linkManufacturerBrands = [] }: Link) => {
        if (linkManufacturerBrands?.length > 0) {
          const brandNames: string[] = linkManufacturerBrands.map(
            ({ brandId }) => brands.find((brand) => brand.brandId === brandId)?.brandName || '',
          );
          return (
            <>
              {brandNames
                .filter((x) => !!x)
                .filter((element: string, i) => brandNames.indexOf(element) === i)
                .join(', ')}
            </>
          );
        }
        return <span title="Sem marcas">-</span>;
      },
      ellipsis: true,
    },
    {
      title: 'Descrição',
      dataIndex: 'linkDescription',
      sorter: true,
      filterDropdown: (props) => tablefilter(props, 'Filtrar por descrição'),
      filterIcon: searchOutlinedIcon,
      ellipsis: true,
    },
    {
      title: 'Data da inclusão',
      dataIndex: 'linkCreatedAt',
      sorter: true,
      render: (_: string, { linkCreatedAt }: Link): JSX.Element => {
        return <>{formatDate(linkCreatedAt)}</>;
      },
      filterDropdown: (props) => tablefilter(props, 'Filtrar por data da inclusão', 'datePickerRange'),
      filterIcon: searchOutlinedIcon,
    },
    {
      title: 'Validade inicial',
      dataIndex: 'linkExpirationInitialDate',
      sorter: true,
      render: (_: string, { linkExpirationInitialDate }: Link): JSX.Element => {
        return <>{formatDate(linkExpirationInitialDate)}</>;
      },
      filterDropdown: (props) => tablefilter(props, 'Filtrar por data da inclusão', 'datePickerRange'),
      filterIcon: searchOutlinedIcon,
    },
    {
      title: 'Validade final',
      dataIndex: 'linkExpirationFinalDate',
      sorter: true,
      render: (_: string, { linkExpirationFinalDate }: Link): JSX.Element => {
        return <>{formatDate(linkExpirationFinalDate)}</>;
      },
      filterDropdown: (props: any) => tablefilter(props, 'Filtrar por data da inclusão', 'datePickerRange'),
      filterIcon: searchOutlinedIcon,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      width: '12em',
      render: (_: any, link: Link): JSX.Element => {
        return (
          <>
            <Button
              type="link"
              onClick={() => openUrlInNewTab(link.linkUrl)}
              className="pa-0"
              title="Abre link em uma nova aba"
            >
              Abrir Link
            </Button>
            <Button
              type="link"
              icon={<CopyOutlined title="Copiar para área de transferência" />}
              onClick={() => copyToClipboard(link.linkUrl)}
            ></Button>
          </>
        );
      },
    },
  ];

  const handleSorter = (sorter: SorterResult<Link>): { orderBy: string; direction: 'ASC' | 'DESC' } => ({
    orderBy: sorter.field?.toString() || 'linkTitle',
    direction: sorter.order === 'descend' ? 'DESC' : 'ASC',
  });

  const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    const { orderBy, direction } = handleSorter(sorter);
    setFilterLinks({
      filters: { ...filterLinks.filters, ...handleFilter(filters) },
      pagination,
      orderBy,
      direction,
    });
  };

  return (
    <Table
      scroll={{ x: 800 }}
      pagination={{
        total,
        current: currentPage,
        pageSize: 10,
        showSizeChanger: false,
      }}
      loading={loading || loadingManufacturer || loadingBrand}
      columns={columns}
      dataSource={links?.map((link: Link) => ({ ...link, key: link.linkId }))}
      onChange={handleTableChange}
    ></Table>
  );
};

export default LinksPage;
