import React from 'react';
import { Modal, Table, Typography as Text } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import Loading from '@components/Loading';
import { useOfficialStoreOrderClassification } from '@hooks/OfficialStoreOrderClassificationContext';
import { OfficialStore } from '@models/OfficialStore';
import { Classification } from '@models/Classification';
interface OfficialStoreOrderClassificationsContentProps {
  officialStore: OfficialStore;
}

const OfficialStoreOrderClassificationsContent: React.FC<OfficialStoreOrderClassificationsContentProps> = ({
  officialStore,
}) => {
  const { officialStoreOrderClassifications, loading, deleteOfficialStoreOrderClassification } =
    useOfficialStoreOrderClassification();

  const onDeleteClassification = async (typeCode: number, code: string) => {
    await deleteOfficialStoreOrderClassification(officialStore.officialStoreId, {
      code,
      typeCode,
    });
  };

  const showModalConfirmation = ({ code, typeCode }: Classification) => {
    Modal.confirm({
      title: 'Deseja realmente excluir?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <br />
          <Text>Tipo de Classificação: {typeCode}</Text>
          <Text>Classificação: {code}</Text>
        </>
      ),
      okText: 'Excluir',
      okType: 'danger',
      onOk() {
        onDeleteClassification(typeCode, code);
      },
    });
  };

  const columns = [
    {
      title: 'Classificações',
    },
    {
      dataIndex: 'typeCode',
      key: 'typeCode',
      align: 'right' as 'right',
      render: (value: any) => <strong style={{ color: 'gray' }}>Tipo de Classificação: {value}</strong>,
    },
    {
      width: '160px',
      dataIndex: 'code',
      key: 'code',
      align: 'right' as 'right',
      render: (value: any) => <strong style={{ color: 'gray' }}>Classificação: {value}</strong>,
    },
    {
      align: 'right' as 'right',
      render: ({ code, typeCode }: Classification): JSX.Element => (
        <CloseOutlined
          style={{ cursor: 'pointer' }}
          onClick={() => {
            showModalConfirmation({ code, typeCode });
          }}
        />
      ),
    },
  ];

  if (loading) return <Loading />;

  return (
    <Table className="mt-4" columns={columns} dataSource={officialStoreOrderClassifications} scroll={{ x: 800 }} />
  );
};

export default OfficialStoreOrderClassificationsContent;
