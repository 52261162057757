import { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';
import * as api from './api';

export function getGoals(params?: any) {
  return api.get('goals', params);
}

export function getGoalById(goalId: number): Promise<AxiosResponse<any>> {
  return api.get(`goals/${goalId}`);
}

export function createGoal(params: { description: string }): Promise<AxiosResponse<any>> {
  return api.post('goals', params);
}

export function deleteGoalItems(goalId: number): Promise<AxiosResponse<any>> {
  return api.remove(`goal-items/${goalId}`);
}

export function updateGoalStatus(goalId: number, goalIsActive: boolean): Promise<AxiosResponse<any>> {
  return api.patch(`goals/${goalId}`, { property: 'isActive', value: goalIsActive });
}

export async function getGoalResultsFileSheet(goalId: number): Promise<void> {
  const { data } = await api.downloadFile(`goal-items/download/${goalId}`);
  fileDownload(data, `metas-resultados-id-${goalId}.csv`);
}

export async function getGoalModelFileSheet(): Promise<void> {
  const file = await require('@assets/csv/metas-modelo.csv');
  const fileUrl = file.default;
  const downloadLink = document.createElement('a');
  downloadLink.href = fileUrl;
  downloadLink.download = 'metas-modelo.csv';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export function uploadGoalFileSheet(file: File): Promise<AxiosResponse<any>> {
  const formData = new FormData();
  formData.append('metas-input.csv', file);
  return api.post('goal-items/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
