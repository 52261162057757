import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { LeftOutlined, MenuOutlined, PlusCircleFilled } from '@ant-design/icons';

import { Col, Drawer, Image, Row, Typography, Form, message } from 'antd';

import Navigation from '../../../../components/OfficialStoreNavigation';
import { OfficialStore } from '@models/OfficialStore';
import { FullScreenModal } from '@styles/globals';

import * as S from './styles';
import { useOfficialStoreOrderClassification } from '@hooks/OfficialStoreOrderClassificationContext';
import DualForm from '@components/DualForm';
import ClassificationForm from '@components/ClassificationForm';

const { Text, Title } = Typography;

interface OfficialStoreOrderClassificationsHeaderProps {
  officialStore: OfficialStore;
}

const OfficialStoreOrderClassificationsHeader: React.FC<OfficialStoreOrderClassificationsHeaderProps> = ({
  officialStore,
}) => {
  const history = useHistory();
  const {
    createOfficialStoreOrderClassification,
    officialStoreOrderClassifications,
    deleteOfficialStoreOrderClassification,
  } = useOfficialStoreOrderClassification();

  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [modalNewClassificationIsVisible, setModalNewClassificationIsVisible] = useState(false);

  const backUrl = useCallback(() => {
    history.go(-1);
  }, [history]);

  const onCloseDrawer = useCallback(() => {
    setDrawerIsVisible(false);
  }, [setDrawerIsVisible]);

  const showDrawer = useCallback(() => {
    setDrawerIsVisible(true);
  }, [setDrawerIsVisible]);

  const onCloseModalNewClassification = useCallback(() => {
    setModalNewClassificationIsVisible(false);
  }, [setModalNewClassificationIsVisible]);

  const showModalNewClassification = useCallback(() => {
    setModalNewClassificationIsVisible(true);
  }, [setModalNewClassificationIsVisible]);

  const onCreateNewClassification = async (values: any) => {
    if (officialStore.officialStoreId && values) {
      if (
        officialStoreOrderClassifications.find(
          (_classification) => _classification.typeCode == values.typeCode && _classification.code == values.code,
        )
      )
        return message.warning('Classificação já foi vinculada anteriormente!');

      await createOfficialStoreOrderClassification(officialStore.officialStoreId, values);
    }
  };

  const onDeleteClassification = async (typeCode: string, code: string) => {
    await deleteOfficialStoreOrderClassification(officialStore.officialStoreId, {
      code,
      typeCode,
    });
  };

  useEffect(() => {
    adaptClassificationsItemsList();
  }, [officialStore, officialStoreOrderClassifications]);

  const [currentClassificationItems, setCurrentClassificationItems] = useState<any[]>([]);

  const adaptClassificationsItemsList = () => {
    const list = officialStoreOrderClassifications.map((item: any) => ({
      label1: 'Tipo de Classificação',
      item1: item.typeCode,
      label2: 'Classificação',
      item2: item.code,
    }));

    if (list) setCurrentClassificationItems(list);
  };

  return (
    <>
      <Row className="mt-3" justify="space-between">
        <Col className="d-flex" style={{ alignItems: 'center' }}>
          <LeftOutlined onClick={() => backUrl()} style={{ fontSize: '20px', marginRight: '12px' }} />

          <Image
            src={officialStore.logoUrl}
            alt="Logo da empresa"
            preview={false}
            style={{ width: '100%', maxWidth: '120px', borderRadius: '8px' }}
          />

          <Row style={{ flexDirection: 'column', marginLeft: '12px' }}>
            <Col>
              <Title level={5} style={{ margin: '0' }}>
                {officialStore.description}
              </Title>
            </Col>
            <Col>
              <Text>#{officialStore.officialStoreId}</Text>
            </Col>
          </Row>
        </Col>

        <Col className="d-flex" style={{ alignItems: 'center' }}>
          <Form.Item style={{ margin: '0 16px 0 0' }}>
            <S.ButtonContainer onClick={showModalNewClassification}>
              <PlusCircleFilled twoToneColor="#510c76" style={{ fontSize: '20px' }} />
              <span style={{ marginLeft: '4px' }}>Nova Classificação</span>
            </S.ButtonContainer>
          </Form.Item>

          <MenuOutlined onClick={showDrawer} style={{ fontSize: '22px', color: '#510c76', cursor: 'pointer' }} />
          <Drawer
            placement="right"
            onClose={onCloseDrawer}
            visible={drawerIsVisible}
            closable={false}
            bodyStyle={{ padding: '0' }}
          >
            <Navigation officialStore={officialStore} />
          </Drawer>
        </Col>
      </Row>

      <FullScreenModal
        destroyOnClose={true}
        footer={null}
        forceRender={false}
        visible={modalNewClassificationIsVisible}
        onOk={onCloseModalNewClassification}
        onCancel={onCloseModalNewClassification}
        bodyStyle={{ width: '100%' }}
      >
        <DualForm
          title="Classificações"
          loading={false}
          form={<ClassificationForm onAdd={onCreateNewClassification} />}
          list={currentClassificationItems}
          onDelete={onDeleteClassification}
        />
      </FullScreenModal>
    </>
  );
};

export default OfficialStoreOrderClassificationsHeader;
