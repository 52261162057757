import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Select, Form, message } from 'antd';

import { CustomButton } from '@styles/globals';

import * as S from './styles';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
import { useCollaborator } from '@hooks/CollaboratorContext';
import { Collaborator } from '@models/Collaborator';

const { Title } = Typography;

interface OfficialStoreFormProps {
  collaboratorId: number;
}

const OfficialStoreForm: React.FC<OfficialStoreFormProps> = ({ collaboratorId }) => {
  const [form] = Form.useForm();

  const [collaborator, setCollaborator] = useState<Collaborator>();

  const { officialStores } = useOfficialStore();
  const { addOfficialStoreToCollaborator, collaborators } = useCollaborator();

  useEffect(() => {
    if (collaboratorId)
      setCollaborator(collaborators.find((_collaborator) => _collaborator.collaboratorId === collaboratorId));
  }, [collaborators, collaboratorId]);

  const handleAddOfficialStore = (values: any) => {
    if (collaborator && values) {
      if (
        collaborator.officialStores &&
        collaborator.officialStores.findIndex(
          (_officialStore) => _officialStore.officialStoreId === values.officialStore,
        ) !== -1
      )
        return message.warning('Loja Oficial já foi adicionada anteriormente!');

      addOfficialStoreToCollaborator(collaborator.collaboratorId, [values.officialStore]);
      form.resetFields();
    }
  };

  return (
    <Form className="w-100" layout="vertical" form={form} onFinish={(values: any) => handleAddOfficialStore(values)}>
      <Row className="d-flex" align="middle" justify="center">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="d-flex"
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <S.ContentContainer>
            <Title level={5} className="mb-4">
              Adicionar Loja Oficial
            </Title>

            <Form.Item
              className="mb-2"
              label="Loja Oficial"
              name="officialStore"
              rules={[{ required: true, message: 'Informe a Loja Oficial' }]}
            >
              <Select>
                {officialStores &&
                  officialStores.map((value) => (
                    <Select.Option key={value.officialStoreId} value={value.officialStoreId}>
                      {value.description}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <CustomButton className="mt-8 w-100" type="primary" htmlType="submit">
              Adicionar
            </CustomButton>
          </S.ContentContainer>
        </Col>
      </Row>
    </Form>
  );
};

export default OfficialStoreForm;
