import * as api from './api';

export function getRepresentativesById(officialStoreId: number): Promise<any> {
  return api.get(`official-store/${officialStoreId}/representative`).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function getRepresentativesByBrandId(officialStoreId: number, brandId: number): Promise<any> {
  return api.get(`official-store/${officialStoreId}/representative/${brandId}`).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function updateRepresentativesById(officialStoreId: number, body: any): Promise<any> {
  return api.put(`official-store/${officialStoreId}/representative`, body).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function removeRepresentativesById(
  officialStoreId: number,
  brandId: number,
  representativeCode: number,
): Promise<any> {
  return api
    .remove(`official-store/${officialStoreId}/representative/${brandId}/${representativeCode}`)
    .catch((error) => {
      return Promise.resolve(error.response);
    });
}
