import React from 'react';

import UploadVideosHeader from './components/UploadVideosHeader';
import UploadVideosContent from './components/UploadVideosContent';

interface UploadVideoProps {}
const UploadVideosPage: React.FC<UploadVideoProps> = () => {
  return (
    <>
      <UploadVideosHeader />
      <UploadVideosContent />
    </>
  );
};

export default UploadVideosPage;
