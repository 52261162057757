import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { message, notification } from 'antd';

import * as CollaboratorService from '@services/collaborator';
import { Collaborator } from '@models/Collaborator';
import { ICollaboratorForm } from '@pages/CollaboratorsPage/models/ICollaboratorForm';

interface CollaboratorState {
  loading: boolean;
  loadingDetails: boolean;
  collaborators: Collaborator[];
  collaboratorsPage: number;
  collaboratorTotal: number;
  collaboratorFilters: any;
  loadRequestCollaborators: Function;
  loadCollaboratorDetails: Function;
  createCollaborator: Function;
  updateCollaborator: Function;
  removeCollaborator: Function;
  addOfficialStoreToCollaborator: Function;
  removeOfficialStoreFromCollaborator: Function;
  addBrandToCollaborator: Function;
  removeBrandFromCollaborator: Function;
}

interface CollaboratorProviderProps {
  children: ReactNode;
}

export const CollaboratorContext = createContext<CollaboratorState>({} as CollaboratorState);

const CollaboratorProvider: React.FC<CollaboratorProviderProps> = ({ children }) => {
  const [collaborators, setCollaborators] = useState<Collaborator[]>([]);
  const [collaboratorsPage, setCollaboratorsPage] = useState<number>(0);
  const [collaboratorTotal, setCollaboratorTotal] = useState<number>(0);
  const [collaboratorFilters, setCollaboratorFilters] = useState({});
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false);

  const loadRequestCollaborators = async (page: number, filters: any) => {
    setLoading(true);
    setCollaboratorsPage(page === 0 ? 1 : page);
    try {
      let { data: collaborator } = await CollaboratorService.get({
        page: page ? page - 1 : 0,
        pageSize: 10,
        ...(filters && { ...filters }),
      });

      setCollaborators(collaborator.data);
      setCollaboratorTotal(collaborator.total);
    } catch (error) {
      message.error('Erro ao buscar colaboradores');
    } finally {
      setLoading(false);
    }
  };

  const createCollaborator = useCallback(
    async (collaborator: Collaborator) => {
      setLoading(true);
      try {
        const { data: newCollaborator } = await CollaboratorService.post(collaborator);
        setCollaborators([...collaborators, newCollaborator]);
        notification.success({ message: 'Sucesso', description: 'Colaborador salvo com sucesso' });
        setLoading(false);

        return newCollaborator;
      } catch (error: any) {
        if (error.response.data.message) {
          error.response.data.message.map((e: any) => {
            notification.warning({ message: 'Falha ao salvar colaborador', description: e });
          });
        } else {
          console.error('error no context', error);
          message.warning('Campo único já existente!');
        }
        setLoading(false);
        return;
      }
    },
    [collaborators],
  );

  const updateCollaborator = useCallback(
    async (collaborator: ICollaboratorForm) => {
      setLoading(true);
      const { data: updatedCollaborator } = await CollaboratorService.update(collaborator);

      if (collaborators.length != 0) {
        setCollaborators(
          collaborators.map((element) => {
            if (element.collaboratorId === updatedCollaborator.collaboratorId) {
              notification.success({ message: 'Sucesso', description: 'Colaborador salvo com sucesso' });
              return updatedCollaborator;
            }
            notification.success({ message: 'Sucesso', description: 'Colaborador salvo com sucesso' });
            return element;
          }),
        );
      } else {
        setCollaborators([updatedCollaborator]);
      }
      setLoading(false);
    },
    [collaborators],
  );

  const removeCollaborator = useCallback(
    async (collaboratorId: number) => {
      try {
        const { data: _collaborator } = await CollaboratorService.removeCollaborator(collaboratorId);

        const index = collaborators.findIndex((collaborator) => collaborator.collaboratorId === collaboratorId);

        if (index !== -1) {
          collaborators.splice(index, 1);
          setCollaborators([...collaborators]);
        }

        message.success('Collaborador removido com sucesso!');
      } catch (e) {
        message.error('Erro ao remover colaborador!');
      }
    },
    [collaborators, setCollaborators],
  );

  const loadCollaboratorDetails = useCallback(
    async (collaboratorId: number) => {
      setLoadingDetails(true);
      try {
        const { data: _collaborator } = await CollaboratorService.getDetails(collaboratorId);

        const index = collaborators.findIndex(
          (collaborator) => collaborator.collaboratorId === _collaborator.collaboratorId,
        );

        if (index !== -1) {
          collaborators[index] = _collaborator;
          setCollaborators([...collaborators]);
        }
      } catch (e) {
        message.error('Erro ao listar lojas oficiais!');
      } finally {
        setLoadingDetails(false);
      }
    },
    [collaborators, setCollaborators, setLoadingDetails],
  );

  const addOfficialStoreToCollaborator = useCallback(
    async (collaboratorId: number, officialStores: number[]) => {
      try {
        const { data: _collaborator } = await CollaboratorService.addOfficialStores(collaboratorId, officialStores);

        const index = collaborators.findIndex(
          (collaborator) => collaborator.collaboratorId === _collaborator.collaboratorId,
        );

        if (index !== -1) {
          collaborators[index] = _collaborator;
          setCollaborators([...collaborators]);
          message.success('Loja Oficial adicionada com sucesso!');
        }
      } catch (e) {
        message.error('Erro ao adicionar loja oficial!');
      }
    },
    [collaborators, setCollaborators],
  );

  const removeOfficialStoreFromCollaborator = useCallback(
    async (collaboratorId: number, officialStoreId: number) => {
      try {
        const { data: _collaborator } = await CollaboratorService.removeOfficialStore(collaboratorId, officialStoreId);

        const index = collaborators.findIndex(
          (collaborator) => collaborator.collaboratorId === _collaborator.collaboratorId,
        );

        if (index !== -1) {
          collaborators[index] = { ..._collaborator };
          setCollaborators([...collaborators]);

          message.success('Loja Oficial removida com sucesso!');
        }
      } catch (e) {
        message.error('Erro ao remover loja oficial!');
      }
    },
    [collaborators, setCollaborators],
  );

  const addBrandToCollaborator = useCallback(
    async (collaboratorId: number, brands: number[]) => {
      try {
        const { data: _collaborator } = await CollaboratorService.addBrands(collaboratorId, brands);

        const index = collaborators.findIndex(
          (collaborator) => collaborator.collaboratorId === _collaborator.collaboratorId,
        );

        if (index !== -1) {
          collaborators[index] = _collaborator;
          setCollaborators([...collaborators]);
          message.success('Marca adicionada com sucesso!');
        }
      } catch (e) {
        message.error('Erro ao adicionar marca!');
      }
    },
    [collaborators, setCollaborators],
  );

  const removeBrandFromCollaborator = useCallback(
    async (collaboratorId: number, brandId: number) => {
      try {
        const { data: _collaborator } = await CollaboratorService.removeBrand(collaboratorId, brandId);

        const index = collaborators.findIndex(
          (collaborator) => collaborator.collaboratorId === _collaborator.collaboratorId,
        );

        if (index !== -1) {
          collaborators[index] = { ..._collaborator };
          setCollaborators([...collaborators]);

          message.success('Marca removida com sucesso!');
        }
      } catch (e) {
        message.error('Erro ao remover marca!');
      }
    },
    [collaborators, setCollaborators],
  );

  return (
    <CollaboratorContext.Provider
      value={{
        collaborators,
        collaboratorFilters,
        collaboratorsPage,
        collaboratorTotal,
        loading,
        loadingDetails,
        loadRequestCollaborators,
        loadCollaboratorDetails,
        createCollaborator,
        removeCollaborator,
        updateCollaborator,
        addOfficialStoreToCollaborator,
        removeOfficialStoreFromCollaborator,
        addBrandToCollaborator,
        removeBrandFromCollaborator,
      }}
    >
      {children}
    </CollaboratorContext.Provider>
  );
};

const useCollaborator = () => {
  return useContext(CollaboratorContext);
};

export { CollaboratorProvider, useCollaborator };
