import { CloseOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useCollection } from '@hooks/CollectionContext';
import { Collection, CollectionDto } from '@models/Collection';
import { CustomButton } from '@styles/globals';
import { Col, Form, Modal, Row, Steps, Typography, message } from 'antd';
import { reject } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { useBrand } from '@hooks/BrandContext';
import { useIntegration } from '@hooks/IntegrationContext';
import { Reference } from '@models/Reference';
import ReferenceBindingStep from './steps/ReferenceBindingStep';
import { Container, CustomCard, CustomCardContainer, CustomInput, CustomRangePicker, StepsContainer } from './styles';

const { Title, Text } = Typography;
const { Step } = Steps;

const steps = [
  {
    title: 'Descrição',
    content: () => null,
  },
  {
    title: 'Disponibilidade',
    content: () => null,
  },
  {
    title: 'Produtos',
    content: () => null,
  },
];

interface CollectionFormProps {
  onCloseModal: Function;
  page?: number;
  isEditing: boolean;
  collectionToEdit?: Collection;
}

const initialCollectionData: CollectionDto = {
  description: null,
  dateCostumerStart: null,
  dateCostumerEnd: null,
  dateCostumerPremiumStart: null,
  dateCostumerPremiumEnd: null,
  dateRepresentativeStart: null,
  dateRepresentativeEnd: null,
  dateRepresentativePremiumStart: null,
  dateRepresentativePremiumEnd: null,
  dateSellerStart: null,
  dateSellerEnd: null,
  dateSellerPremiumStart: null,
  dateSellerPremiumEnd: null,
  classifications: [],
};

const CollectionForm: React.FC<CollectionFormProps> = ({ onCloseModal, page, isEditing = false, collectionToEdit }) => {
  const { hasMerpIntegration } = useIntegration();
  const { loadBrands } = useBrand();
  const { loadAllCollections, createCollection, updateCollection, createCollectionMerp } = useCollection();
  const [form] = Form.useForm();

  const [currentFormPage, setCurrentFormPage] = useState(0);
  const [collectionData, setCollectionData] = useState<CollectionDto>(initialCollectionData);
  const [collectionToEditId, setcollectionToEditId] = useState<number | null>(null);

  const [classificationType, setClassificationType] = useState<string>();
  const [classification, setClassification] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const [collectionsReferencesFormValues, setCollectionsReferencesFormValues] = React.useState<any>();

  React.useEffect(() => {
    loadAllCollections();
    loadBrands();
  }, []);

  useEffect(() => {
    page ? setCurrentFormPage(page) : setCurrentFormPage(0);

    if (collectionToEdit) {
      setcollectionToEditId(collectionToEdit.collectionId);
      setCollectionData({ ...collectionData, ...collectionToEdit });
      setCollectionsReferencesFormValues({ references: collectionToEdit?.referencesExpanded || [] });

      const {
        dateCostumerStart,
        dateCostumerEnd,
        dateCostumerPremiumStart,
        dateCostumerPremiumEnd,
        dateRepresentativeStart,
        dateRepresentativeEnd,
        dateRepresentativePremiumStart,
        dateRepresentativePremiumEnd,
        dateSellerStart,
        dateSellerEnd,
        dateSellerPremiumStart,
        dateSellerPremiumEnd,
      } = collectionToEdit;

      form.setFieldsValue({
        dateCostumer: handleDate(dateCostumerStart, dateCostumerEnd),
        dateCostumerPremium: handleDate(dateCostumerPremiumStart, dateCostumerPremiumEnd),
        dateRepresentative: handleDate(dateRepresentativeStart, dateRepresentativeEnd),
        dateRepresentativePremium: handleDate(dateRepresentativePremiumStart, dateRepresentativePremiumEnd),
        dateSeller: handleDate(dateSellerStart, dateSellerEnd),
        dateSellerPremium: handleDate(dateSellerPremiumStart, dateSellerPremiumEnd),
      });
    }
  }, [page]);

  const goToNextStep = (values = null) => {
    if (currentFormPage === 2) {
      handleSubmitMerp(values);
    } else {
      setCurrentFormPage(currentFormPage + 1);
    }
  };

  const handleDate = (dateStart: string, dateEnd: string): moment.Moment[] => {
    return dateStart && dateEnd ? [moment(dateStart), moment(dateEnd)] : [];
  };

  const onChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCollectionData({ ...collectionData, description: event.target.value });
  };

  const onChangeDateCostumer = (value: any) => {
    setCollectionData({
      ...collectionData,
      dateCostumerStart: moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
      dateCostumerEnd: moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const onChangeDateCostumerPremium = (value: any) => {
    setCollectionData({
      ...collectionData,
      dateCostumerPremiumStart: moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
      dateCostumerPremiumEnd: moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const onChangeDateRepresentative = (value: any) => {
    setCollectionData({
      ...collectionData,
      dateRepresentativeStart: moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
      dateRepresentativeEnd: moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const onChangeDateRepresentativePremium = (value: any) => {
    setCollectionData({
      ...collectionData,
      dateRepresentativePremiumStart: moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
      dateRepresentativePremiumEnd: moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const onChangeDateSeller = (value: any) => {
    setCollectionData({
      ...collectionData,
      dateSellerStart: moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
      dateSellerEnd: moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const onChangeDateSellerPremium = (value: any) => {
    setCollectionData({
      ...collectionData,
      dateSellerPremiumStart: moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
      dateSellerPremiumEnd: moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const addClassification = () => {
    if (!classification) return message.error('Preencha a classificação!');
    if (!classificationType) return message.error('Preencha o tipo de classificação!');

    if (
      collectionData.classifications?.find(
        (findClassification) =>
          findClassification.typeCode === Number(classificationType) && findClassification.code === classification,
      )
    )
      return message.warning('Filtro já existe!');

    setCollectionData({
      ...collectionData,
      classifications: [
        ...collectionData.classifications,
        { code: classification, typeCode: Number(classificationType) },
      ],
    });
    setClassification(undefined);
    setClassificationType(undefined);
  };

  const removeClassification = (typeCode: number, code: string) => {
    const filteredClassifications = reject(
      collectionData.classifications,
      (_classification) => _classification.typeCode === typeCode && _classification.code === code,
    );

    setCollectionData({
      ...collectionData,
      classifications: filteredClassifications,
    });
  };

  const showModalConfirmation = (typeCode: number, code: string) => {
    Modal.confirm({
      title: 'Deseja realmente excluir?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <Text>{typeCode}</Text>
          <br />
          <Text>{code}</Text>
        </>
      ),
      okText: 'Excluir',
      okType: 'danger',
      onOk() {
        removeClassification(typeCode, code);
      },
    });
  };

  const onClickSubmitButton = async () => {
    let messageText;
    setLoading(true);

    if (collectionToEditId) {
      messageText = 'atualizada';
      await updateCollection(collectionToEditId, collectionData);
      setLoading(false);
    } else {
      messageText = 'cadastrada';
      await createCollection(collectionData);
      setLoading(false);
    }

    setCollectionData(initialCollectionData);
    onCloseModal();
    message.success(`Coleção ${messageText} com sucesso!`);
  };

  const handleSubmitMerp = async (values: any = collectionsReferencesFormValues) => {
    setLoading(true);

    const body = {
      ...collectionData,
      ...(isEditing && { collectionId: collectionToEditId }),
      integrationId: Number(localStorage.getItem('@modavest/integration_id')),
      references: values?.references?.map((reference: Reference) => reference?.ReferenceCode),
    };

    try {
      await createCollectionMerp(body);
      message.success(`Coleção ${isEditing ? 'atualizada' : 'cadastrada'} com sucesso!`);
      onCloseModal();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const next = () => {
    if (currentFormPage === 0 && (!collectionData.description || collectionData.description.length === 0))
      return message.warning('Preencha o nome da coleção para prosseguir!');

    setCurrentFormPage(currentFormPage + 1);
  };

  const dateElements = !hasMerpIntegration ? [
    { title: 'Lojista', name: 'dateCostumer', onChangeDate: onChangeDateCostumer },
    { title: 'Representante', name: 'dateRepresentative', onChangeDate: onChangeDateRepresentative },
    { title: 'Vendedor', name: 'dateSeller', onChangeDate: onChangeDateSeller },
    {
      title: 'Lojista Premium',
      name: 'dateCostumerPremium',
      onChangeDate: onChangeDateCostumerPremium,
    },
    {
      title: 'Representante Premium',
      name: 'dateRepresentativePremium',
      onChangeDate: onChangeDateRepresentativePremium,
    },
    { title: 'Vendedor Premium', name: 'dateSellerPremium', onChangeDate: onChangeDateSellerPremium },
  ] : [
    { title: 'Representante', name: 'dateRepresentative', onChangeDate: onChangeDateRepresentative, },
    {
      title: 'Representante Premium',
      name: 'dateRepresentativePremium',
      onChangeDate: onChangeDateRepresentativePremium,
    },
  ];;

  return (
    <Form form={form} className="d-flex" style={{ flexDirection: 'column', alignItems: 'center' }}>
      {loading && (
        <div
          className="steps-action d-flex"
          style={{ alignItems: 'center', justifyContent: 'center', width: '700px', height: '380px', borderRadius: 8 }}
        >
          <Container
            style={{
              background: '#fff',
              padding: '20px',
              borderRadius: '8px',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <LoadingOutlined
                type="play-circle-o"
                style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
              />
            </div>
          </Container>
        </div>
      )}

      {!loading && (
        <>
          <StepsContainer progressDot current={currentFormPage}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </StepsContainer>

          <div className="steps-content">{steps[currentFormPage].content}</div>
          <div className="steps-action d-flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Container
              style={{
                background: `${currentFormPage === 2 ? 'transparent' : '#fff'}`,
                padding: '20px',
                borderRadius: '8px',
              }}
            >
              {currentFormPage === 0 ? (
                <Row className="d-flex" style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <Col span={24}>
                    <Title level={5}>Nome da Coleção</Title>
                    <CustomInput
                      placeholder="Nome da Coleção"
                      onChange={onChangeDescription}
                      value={collectionData?.description || ''}
                    />
                  </Col>
                </Row>
              ) : null}

              {currentFormPage === 1 ? (
                <Row
                  className="d-flex"
                  style={{ justifyContent: 'center', alignItems: 'center', padding: '0 8px' }}
                  gutter={[24, 0]}
                >
                  {!hasMerpIntegration ? <>{dateElements.map(({ title, name, onChangeDate }) => (
                    <Col span={8} key={name}>
                      <Title level={5}>{title}</Title>
                      <Form.Item name={name}>
                        <CustomRangePicker format="DD/MM/YYYY" name={name} onChange={onChangeDate} />
                      </Form.Item>
                    </Col>
                  ))}</> : <>{dateElements.map(({ title, name, onChangeDate }) => (
                    <Col span={12} key={name} style={{ padding: '0 10px' }}>
                      <Title level={5}>{title}</Title>
                      <Form.Item name={name}>
                        <CustomRangePicker format="DD/MM/YYYY" name={name} onChange={onChangeDate} />
                      </Form.Item>
                    </Col>
                  ))}</>}
                </Row>
              ) : null}

              {hasMerpIntegration && isEditing && (
                <Row
                  style={{
                    width: '100%',
                    background: '#fff',
                    padding: currentFormPage === 2 ? '60px' : '0',
                    borderRadius: '8px',
                  }}
                  gutter={16}
                >
                  <ReferenceBindingStep
                    formValues={collectionsReferencesFormValues}
                    setFormValues={setCollectionsReferencesFormValues}
                    goToNextStep={goToNextStep}
                    isEditing={isEditing}
                    render={currentFormPage === 2}
                  />
                </Row>
              )}

              {currentFormPage === 2 ? (
                hasMerpIntegration ? (
                  <Row
                    style={{
                      width: '100%',
                      background: '#fff',
                      padding: '60px',
                      borderRadius: '8px',
                      display: !isEditing ? 'flex' : 'none',
                    }}
                    gutter={16}
                  >
                    <ReferenceBindingStep
                      formValues={collectionsReferencesFormValues}
                      setFormValues={setCollectionsReferencesFormValues}
                      goToNextStep={goToNextStep}
                      isEditing={isEditing}
                      render={true}
                    />
                  </Row>
                ) : (
                  <Row className="d-flex" gutter={16}>
                    <Col span={12} className="gutter-row">
                      <Row style={{ height: '480px', background: '#fff', padding: '20px', borderRadius: '8px' }}>
                        <CustomCardContainer span={24}>
                          {collectionData.classifications.map(({ typeCode, code }) => (
                            <CustomCard key={`${typeCode}-${code}`}>
                              <Row className="card-row">
                                <Col span={14} style={{ paddingLeft: '8px', paddingTop: '8px' }}>
                                  <Title level={5} style={{ height: '20px' }}>
                                    Tipo de Classificação
                                  </Title>
                                  <Text>{typeCode}</Text>
                                </Col>
                                <Col span={10} style={{ paddingLeft: '8px', paddingTop: '8px' }}>
                                  <Title level={5} style={{ height: '20px' }}>
                                    Classificação
                                  </Title>
                                  <Text>{code}</Text>
                                </Col>
                                <Col
                                  span={24}
                                  className="card-col"
                                  onClick={() => showModalConfirmation(typeCode, code)}
                                >
                                  <CloseOutlined style={{ fontSize: '16px', color: 'var(--primary-color)' }} />
                                  <Text style={{ fontSize: '14px', color: 'var(--primary-color)' }}>
                                    Excluir Filtro
                                  </Text>
                                </Col>
                              </Row>
                            </CustomCard>
                          ))}
                        </CustomCardContainer>
                      </Row>
                    </Col>
                    <Col span={12} className="gutter-row">
                      <Row style={{ height: '480px', background: '#fff', padding: '40px 20px', borderRadius: '8px' }}>
                        <Col span={24}>
                          <Title level={5}>Tipo de Classificação</Title>
                          <CustomInput
                            placeholder="Tipo de Classificação"
                            type="number"
                            value={classificationType}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setClassificationType(e.target.value)}
                          />
                        </Col>
                        <Col span={24}>
                          <Title level={5}>Classificação</Title>
                          <CustomInput
                            placeholder="Classificação"
                            value={classification}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setClassification(e.target.value)}
                          />
                        </Col>
                        <CustomButton type="primary" onClick={addClassification}>
                          ADICIONAR FILTRO DE PRODUTO
                        </CustomButton>
                        <CustomButton
                          type="primary"
                          outlined="true"
                          style={{ marginTop: '80px' }}
                          onClick={onClickSubmitButton}
                          disabled={loading}
                        >
                          {loading ? (
                            <LoadingOutlined
                              type="play-circle-o"
                              style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
                            />
                          ) : (
                            'CONCLUIR E VER COLEÇÃO'
                          )}
                        </CustomButton>
                      </Row>
                    </Col>
                  </Row>
                )
              ) : null}

              {currentFormPage !== 2 ? (
                <CustomButton type="primary" onClick={next}>
                  Continuar
                </CustomButton>
              ) : null}
            </Container>
          </div>
        </>
      )}
    </Form>
  );
};

export default CollectionForm;
