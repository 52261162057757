import { ExclamationCircleOutlined } from '@ant-design/icons';
import { SharedData } from '@components/ProductForm/models/product-form-models';
import { useBrand } from '@hooks/BrandContext';
import { useGrid } from '@hooks/GridContext';
import { Brand } from '@models/Brand';
import { Grid } from '@models/Grid';
import { Reference } from '@models/Reference';
import { CustomTextArea } from '@pages/CollectionDetailsPage/components/CustomerNotification/styles';
import { CustomButton } from '@styles/globals';
import { Col, Form, Modal, Row, Select, Space, Switch, Table, Typography } from 'antd';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CustomInput } from '../../styles';
const { Title, Text } = Typography;

interface ProductFormReferenceStepProps {
  sharedData?: SharedData;
  changeSharedData?: Function;
  formValues: Reference | undefined;
  setFormValues: Function;
  goToNextStep: Function;
  isEditing?: boolean;
  render: boolean;
}

const ProductFormReferenceStep: React.FC<ProductFormReferenceStepProps> = ({
  sharedData,
  changeSharedData,
  formValues,
  setFormValues,
  goToNextStep,
  isEditing = false,
  render,
}) => {
  const { allGrids } = useGrid();
  const { brands } = useBrand();

  const [referenceForm] = Form.useForm();

  const [isPack, setIsPack] = React.useState<boolean>(false);
  const [hasDetails, setHasDetails] = React.useState<boolean>(false);
  const [isActive, setIsActive] = React.useState<boolean>(true);
  const [selectedGrid, setSelectedGrid] = React.useState<string>('');

  const [detailsTableRows, setDetailsTableRows] = React.useState<{ row: string; key: string }[]>([
    { row: 'Detalhe', key: uuidv4() },
  ]);
  const [compositionsTableRows, setCompositionsTableRows] = React.useState<{ row: string; key: string }[]>([
    { row: 'Composição', key: uuidv4() },
  ]);

  React.useEffect(() => {
    referenceForm.setFields([
      { name: 'ReferenceCode', value: '' },
      { name: 'name', value: '' },
      { name: 'description', value: '' },
      { name: 'isPack', value: false },
      { name: 'isGrid', value: true },
      { name: 'details', value: [] },
      { name: 'composition', value: [] },
      { name: 'gridCode', value: '' },
      { name: 'brandId', value: '' },
      { name: 'isActive', value: true },
      { name: 'weight', value: '' },
      { name: 'height', value: '' },
      { name: 'width', value: '' },
      { name: 'length', value: '' },
      { name: 'packWeight', value: '' },
      { name: 'packHeight', value: '' },
      { name: 'packWidth', value: '' },
      { name: 'packLength', value: '' },
    ]);
  }, []);

  React.useEffect(() => {
    if (formValues?.ReferenceCode) {
      referenceForm.setFieldsValue({
        ReferenceCode: formValues.ReferenceCode,
        name: formValues.name,
        description: formValues.description,
        isPack: formValues.isPack,
        isGrid: formValues.isGrid,
        details: formValues.details,
        composition: formValues.composition,
        gridCode: formValues.gridCode,
        brandId: formValues.brandId,
        isActive: formValues.isActive,
        weight: formValues.weight,
        height: formValues.height,
        width: formValues.width,
        length: formValues.length,
        packWeight: formValues.packWeight,
        packHeight: formValues.packHeight,
        packWidth: formValues.packWidth,
        packLength: formValues.packLength,
      });

      setIsPack(formValues.isPack);
      setIsActive(formValues.isActive);
      setSelectedGrid(formValues?.gridCode?.toString());
      if (formValues?.details?.length > 0) {
        setHasDetails(true);
      }
    }
  }, [formValues]);

  const finishStep = async () => {
    const values = await referenceForm.validateFields();

    values.isGrid = !isPack;
    values.isPack = isPack;
    values.isActive = isActive;

    if (changeSharedData) changeSharedData({ gridCode: values?.gridCode });

    setFormValues(values);
    goToNextStep();
  };

  const addReferenceDetail = () => {
    setDetailsTableRows((prev) => [...prev, { row: 'Detalhe', key: uuidv4() }]);
  };

  const removeReferenceDetail = (key: string) => {
    setDetailsTableRows(detailsTableRows.filter((row) => row.key != key));
  };

  const addReferenceComposition = () => {
    setCompositionsTableRows((prev) => [...prev, { row: 'Composição', key: uuidv4() }]);
  };

  const removeReferenceComposition = (key: string) => {
    setCompositionsTableRows(compositionsTableRows.filter((row) => row.key != key));
  };

  const onChangeGridSelect = (value: any) => {
    if (sharedData?.gridCode && sharedData?.gridCode !== value) {
      Modal.confirm({
        title: 'Tem certeza que deseja alterar a grade?',
        content: 'Ao trocar a grade, todos os dados de cores, tamanhos e imagens terão que ser preenchidos novamente.',
        icon: <ExclamationCircleOutlined />,
        okText: 'Alterar',
        cancelText: 'Cancelar',
        okType: 'danger',
        onOk() {
          if (changeSharedData) {
            changeSharedData({ gridCode: value });
          }
        },
        onCancel() {
          referenceForm.setFieldsValue({
            gridCode: sharedData?.gridCode,
          });
        },
      });
    }
  };

  return (
    <>
      {render && (
        <Form
          form={referenceForm}
          className="d-flex"
          style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}
        >
          <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Col span={24}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Código da referência <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item
                name="ReferenceCode"
                rules={[
                  {
                    required: true,
                    message: 'Informe o código da referência',
                  },
                ]}
              >
                <CustomInput disabled={isEditing} style={{ width: '100%' }} placeholder="Código da referência" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Col span={24}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Nome da referência <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Informe o nome da referência',
                  },
                ]}
              >
                <CustomInput style={{ width: '100%' }} placeholder="Nome da referência" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Col span={24}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Descrição da referência <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Informe o descrição da referência',
                  },
                ]}
              >
                <CustomTextArea
                  style={{
                    width: '100%',
                    resize: 'none',
                    height: '100px',
                    padding: '10px',
                  }}
                  placeholder="Descrição da referência"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Col span={24} style={{ marginBottom: '25px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Referência vendida por <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Space direction="horizontal">
                <div>
                  <span>Grade: </span>
                  <Switch checked={!isPack} onChange={() => setIsPack(false)} />
                </div>
                <div>
                  <span className="ml-4">Pack: </span>
                  <Switch checked={isPack} onChange={() => setIsPack(true)} />
                </div>
              </Space>
            </Col>
          </Row>

          {/* DETALHES DA REFERÊNCIA */}
          <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Col span={24} style={{ marginBottom: '25px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Detalhes da referência
              </Title>
              <Space direction="horizontal">
                <div>
                  <span>Sim: </span>
                  <Switch checked={hasDetails} onChange={() => setHasDetails(true)} />
                </div>
                <div>
                  <span className="ml-4">Não: </span>
                  <Switch
                    checked={!hasDetails}
                    onChange={() => {
                      setHasDetails(false);
                      setDetailsTableRows([]);
                    }}
                  />
                </div>
              </Space>
            </Col>

            {hasDetails && detailsTableRows.length > 0 && (
              <Table
                bordered={true}
                showHeader={false}
                pagination={false}
                style={{ width: '100%', cursor: 'pointer' }}
                className="table-antd"
                dataSource={detailsTableRows}
                columns={[
                  {
                    dataIndex: 'row',
                    render: (row: string, detail, index) => (
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong>{detail.row + ' ' + (index + 1)}</strong>
                        {index !== 0 && (
                          <p
                            style={{
                              color: 'red',
                              textDecoration: 'underline',
                              margin: 0,
                              padding: 0,
                              cursor: 'pointer',
                            }}
                            onClick={() => removeReferenceDetail(detail.key)}
                          >
                            Excluir
                          </p>
                        )}
                      </div>
                    ),
                  },
                ]}
                expandable={{
                  expandRowByClick: true,
                  expandedRowRender: (detail, index) => (
                    <div>
                      <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                        Título <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                      </Title>
                      <Form.Item
                        name={[`details`, index, 'title']}
                        rules={[
                          {
                            required: true,
                            message: 'Informe o título',
                          },
                        ]}
                      >
                        <CustomInput style={{ width: '100%' }} placeholder="Título" />
                      </Form.Item>

                      <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                        Descrição <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                      </Title>
                      <Form.Item
                        name={[`details`, index, 'description']}
                        rules={[
                          {
                            required: true,
                            message: 'Informe a descrição',
                          },
                        ]}
                      >
                        <CustomInput style={{ width: '100%' }} placeholder="Descrição" />
                      </Form.Item>
                    </div>
                  ),
                }}
              />
            )}

            {hasDetails && (
              <div
                className="d-flex"
                style={{
                  width: '100%',
                  alignItems: 'center',
                  fontSize: '18px',
                  marginTop: detailsTableRows.length > 0 ? '20px' : 0,
                  marginBottom: 30,
                }}
              >
                <div
                  className="d-flex"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    addReferenceDetail();
                  }}
                >
                  <div
                    className="d-flex"
                    style={{
                      backgroundColor: '#512874',
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      color: 'white',
                      marginRight: '10px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    +
                  </div>
                  <p style={{ color: '#512874', margin: 0 }}>Adicionar detalhe</p>
                </div>
              </div>
            )}
          </Row>

          {/* COMPOSIÇÃO DA REFERÊNCIA */}
          <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Col span={24}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Composição da referência <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
            </Col>

            {compositionsTableRows.length > 0 && (
              <Table
                bordered={true}
                showHeader={false}
                pagination={false}
                style={{ width: '100%', cursor: 'pointer' }}
                className="table-antd"
                dataSource={compositionsTableRows}
                columns={[
                  {
                    dataIndex: 'row',
                    render: (row: string, composition, index) => (
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong>{composition.row + ' ' + (index + 1)}</strong>
                        {index !== 0 && (
                          <p
                            style={{
                              color: 'red',
                              textDecoration: 'underline',
                              margin: 0,
                              padding: 0,
                              cursor: 'pointer',
                            }}
                            onClick={() => removeReferenceComposition(composition.key)}
                          >
                            Excluir
                          </p>
                        )}
                      </div>
                    ),
                  },
                ]}
                expandable={{
                  expandRowByClick: true,
                  expandedRowRender: (composition, index) => (
                    <Row style={{ marginRight: 10, width: '100%' }}>
                      <Col style={{ marginRight: 10, width: '65%' }}>
                        <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                          Material <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                        </Title>
                        <Form.Item
                          name={[`composition`, index, 'material']}
                          rules={[
                            {
                              required: true,
                              message: 'Informe o material',
                            },
                          ]}
                        >
                          <CustomInput style={{ width: '100%' }} placeholder="Material" />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: '30%' }}>
                        <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                          Percentual <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                        </Title>
                        <Form.Item
                          name={[`composition`, index, 'percentage']}
                          rules={[
                            {
                              required: true,
                              message: 'Informe a porcentagem',
                            },
                          ]}
                        >
                          <CustomInput type="number" style={{ width: '100%' }} placeholder="%" />
                        </Form.Item>
                      </Col>
                    </Row>
                  ),
                }}
              />
            )}

            <div
              className="d-flex"
              style={{
                width: '100%',
                alignItems: 'center',
                fontSize: '18px',
                marginTop: compositionsTableRows.length > 0 ? '20px' : 0,
                marginBottom: 30,
              }}
            >
              <div
                className="d-flex"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  addReferenceComposition();
                }}
              >
                <div
                  className="d-flex"
                  style={{
                    backgroundColor: '#512874',
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    color: 'white',
                    marginRight: '10px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  +
                </div>
                <p style={{ color: '#512874', margin: 0 }}>Adicionar composição</p>
              </div>
            </div>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Col span={24}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Grade da referência <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item
                name="gridCode"
                rules={[
                  {
                    required: true,
                    message: 'Selecione a grade',
                  },
                ]}
                style={{ borderRadius: '8px' }}
              >
                <Select
                  disabled={isEditing}
                  style={{ height: '48px', borderRadius: '8px' }}
                  placeholder="Grade da referência"
                  size="large"
                  showSearch
                  allowClear
                  onChange={(value) => {
                    onChangeGridSelect(value);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {allGrids?.length &&
                    allGrids.map((grid: Grid) => (
                      <Select.Option key={grid.id} value={grid.id}>
                        {grid.gradeName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Col span={24}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Marca da referência <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item
                name="brandId"
                rules={[
                  {
                    required: true,
                    message: 'Selecione a marca',
                  },
                ]}
                style={{ borderRadius: '8px' }}
              >
                <Select
                  style={{ height: '48px', borderRadius: '8px' }}
                  placeholder="Marca da referência"
                  size="large"
                  showSearch
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {brands?.length &&
                    brands.map((brand: Brand) => (
                      <Select.Option key={brand.brandId} value={brand.brandId}>
                        {brand.brandName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Col span={24} style={{ marginBottom: '25px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Referência <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Space direction="horizontal">
                <div>
                  <span>Ativar: </span>
                  <Switch checked={isActive} onChange={() => setIsActive(true)} />
                </div>
                <div>
                  <span className="ml-4">Inativar: </span>
                  <Switch
                    checked={!isActive}
                    onChange={() => {
                      setIsActive(false);
                      setDetailsTableRows([]);
                    }}
                  />
                </div>
              </Space>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col span={12} style={{ paddingRight: '15px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Peso (g)
              </Title>
              <Form.Item name="weight">
                <CustomInput type="number" min="0" style={{ width: '100%' }} placeholder="Peso" />
              </Form.Item>
            </Col>

            <Col span={12} style={{ paddingLeft: '15px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Altura (cm)
              </Title>
              <Form.Item name="height">
                <CustomInput type="number" min="0" style={{ width: '100%' }} placeholder="Altura" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col span={12} style={{ paddingRight: '15px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Largura (cm)
              </Title>
              <Form.Item name="width">
                <CustomInput type="number" min="0" style={{ width: '100%' }} placeholder="Largura" />
              </Form.Item>
            </Col>

            <Col span={12} style={{ paddingLeft: '15px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Profundidade (cm)
              </Title>
              <Form.Item name="length">
                <CustomInput type="number" min="0" style={{ width: '100%' }} placeholder="Profundidade" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col span={12} style={{ paddingRight: '15px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Peso da embalagem (g)
              </Title>
              <Form.Item name="packWeight">
                <CustomInput type="number" min="0" style={{ width: '100%' }} placeholder="Peso da embalagem" />
              </Form.Item>
            </Col>

            <Col span={12} style={{ paddingLeft: '15px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Altura da embalagem (cm)
              </Title>
              <Form.Item name="packHeight">
                <CustomInput type="number" min="0" style={{ width: '100%' }} placeholder="Altura da embalagem" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col span={12} style={{ paddingRight: '15px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Largura da embalagem (cm)
              </Title>
              <Form.Item name="packWidth">
                <CustomInput type="number" min="0" style={{ width: '100%' }} placeholder="Largura da embalagem" />
              </Form.Item>
            </Col>

            <Col span={12} style={{ paddingLeft: '15px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Profundidade da embalagem (cm)
              </Title>
              <Form.Item name="packLength">
                <CustomInput type="number" min="0" style={{ width: '100%' }} placeholder="Profundidade da embalagem" />
              </Form.Item>
            </Col>
          </Row>

          <CustomButton type="primary" style={{ marginTop: 40, width: '100%' }} onClick={finishStep}>
            Continuar
          </CustomButton>
        </Form>
      )}
    </>
  );
};

export default ProductFormReferenceStep;
