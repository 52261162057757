import { CustomButton } from '@styles/globals';
import { Col, Form, Modal, Row, Table, Typography } from 'antd';
import React from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useBrand } from '@hooks/BrandContext';
import { useReference } from '@hooks/ReferenceContext';
import { Brand } from '@models/Brand';
import { Reference } from '@models/Reference';
import Select from 'antd/lib/select';
import { CustomInput } from './styles';

const { Title } = Typography;

interface AddReferenceBindingCollectionFormModalProps {
  onCloseModal: Function;
  setReferences: Function;
  selected: Reference[];
}

const AddReferenceBindingCollectionFormModal: React.FC<AddReferenceBindingCollectionFormModalProps> = ({
  onCloseModal,
  setReferences,
  selected,
}) => {
  const { brands } = useBrand();
  const { searchReferenceResult, searchReferences, loadingReferences } = useReference();

  const [filterReferencesForm] = Form.useForm();

  const [selectedRows, setSelectedRows] = React.useState<React.Key[]>([]);
  const [selectedReferences, setSelectedReferences] = React.useState<Reference[]>([]);
  const [referenceList, setReferenceList] = React.useState<{ key: string; value: string }[]>([]);

  React.useEffect(() => {
    filterReferencesForm.setFields([
      { name: 'referenceCode', value: null },
      { name: 'name', value: null },
      { name: 'brandId', value: null },
    ]);
  }, []);

  React.useEffect(() => {
    const list = searchReferenceResult
      ?.filter((reference) => {
        return !selected.some((item) => item.ReferenceCode === reference.ReferenceCode);
      })
      ?.map((reference: Reference) => {
        return {
          value: reference?.name,
          key: reference?.ReferenceCode,
        };
      });
    setReferenceList(list);
  }, [searchReferenceResult]);

  const finishAddReference = async () => {
    setReferences(selectedReferences);
    onCloseModal();
  };

  const filterReferences = async () => {
    if (selectedRows?.length === 0) {
      const filters = filterReferencesForm.getFieldsValue() || {};

      filters.isActive = true;
      filters.integrationId = Number(localStorage.getItem('@modavest/integration_id'));

      await searchReferences(filters);
    } else if (selectedRows.length > 0) {
      showModalConfirmation();
    }
  };

  const clearFilters = async () => {
    filterReferencesForm.resetFields();
  };

  const onToggleSizeCheckbox = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRows(newSelectedRowKeys);

    const selected = searchReferenceResult.filter((reference) => {
      return newSelectedRowKeys.includes(reference.ReferenceCode);
    });

    setSelectedReferences(selected);
  };

  const showModalConfirmation = () => {
    Modal.confirm({
      title: 'Descartar referências selecionadas?',
      icon: <ExclamationCircleOutlined />,
      content: 'Ao aplicar o filtro, todos as referências que você incluiu serão removidas. Deseja continuar?',
      okText: 'Continuar',
      cancelText: 'Cancelar',
      onOk() {
        setSelectedRows([]);
        setSelectedReferences([]);
        const filters = filterReferencesForm.getFieldsValue() || {};

        filters.isActive = true;
        filters.integrationId = Number(localStorage.getItem('@modavest/integration_id'));

        searchReferences(filters);
      },
    });
  };

  return (
    <>
      <Form
        form={filterReferencesForm}
        className="d-flex"
        style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}
      >
        <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Col span={8} style={{ paddingRight: 20 }}>
            <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
              Código da referência
            </Title>
            <Form.Item name="referenceCode">
              <CustomInput style={{ width: '100%' }} placeholder="Código da referência" />
            </Form.Item>
          </Col>

          <Col span={8} style={{ paddingRight: 20 }}>
            <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
              Nome da referência
            </Title>
            <Form.Item name="name">
              <CustomInput style={{ width: '100%' }} placeholder="Nome da referência" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
              Marca
            </Title>
            <Form.Item name="brandId">
              <Select
                style={{ height: '48px', borderRadius: '8px' }}
                placeholder="Marca"
                size="large"
                showSearch
                allowClear
              >
                {brands?.length &&
                  brands.map((brand: Brand) => (
                    <Select.Option key={brand.brandId} value={brand.brandId}>
                      {brand.brandName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row className="d-flex" style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Col span={4}>
            <CustomButton type="primary" style={{ marginBottom: 40 }} onClick={filterReferences}>
              Filtrar
            </CustomButton>
          </Col>
        </Row>

        <Row className="d-flex" style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'start' }}>
          <Col span={24}>
            <Row style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2 style={{ fontWeight: 'bold', paddingBottom: '10px' }}>Referências para inclusão</h2>
              <div className="d-flex">
                <p style={{ color: '#4F4F4F' }}>
                  {searchReferenceResult?.length - (selected?.length || 0)} referência(s) encontradas{' '}
                </p>
                <p
                  style={{
                    color: '#512874',
                    textDecoration: 'underline',
                    paddingLeft: '20px',
                    cursor: 'pointer',
                  }}
                  onClick={clearFilters}
                >
                  Limpar filtros
                </p>
              </div>
            </Row>
            <Table
              size="middle"
              showHeader={true}
              pagination={false}
              dataSource={referenceList}
              rowKey={(reference) => reference?.key}
              scroll={{ y: 300 }}
              loading={loadingReferences}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedRows,
                onChange: onToggleSizeCheckbox,
                fixed: true,
                hideSelectAll: false,
                columnWidth: 80,
              }}
              columns={[
                {
                  title: 'Código',
                  dataIndex: 'code',
                  render: (typeCode: number, reference: { key: string; value: string }) => <>{reference.key}</>,
                },
                {
                  title: 'Name',
                  dataIndex: 'name',
                  render: (typeCode: number, reference: { key: string; value: string }) => <>{reference.value}</>,
                },
              ]}
            />
          </Col>
        </Row>

        <CustomButton type="primary" style={{ marginTop: 40, width: '100%' }} onClick={finishAddReference}>
          Adicionar referência(s)
        </CustomButton>
      </Form>
    </>
  );
};

export default AddReferenceBindingCollectionFormModal;
