import { notification } from "antd";

export function beforeUpload(file: File) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

  if (!isJpgOrPng) {
    notification.error({ message: 'Formato inválido', description: 'Apenas imagens JPG/PNG são aceitas!' });
  }

  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    notification.error({ message: 'Arquivo muito grande', description: 'A imagem deve ser menor que 10MB!' });
  }
  return isJpgOrPng && isLt2M;
}

