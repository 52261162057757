import { CustomInput } from '@components/CollectionForm/styles';
import { useGrid } from '@hooks/GridContext';
import { CustomButton } from '@styles/globals';
import { Form, Select } from 'antd';
import React, { useEffect } from 'react';

const { Option } = Select;

interface GridFiltersProps {
  onCloseDrawer: Function;
  onResetFilters: Function;
}

const GridFilters: React.FC<GridFiltersProps> = ({ onCloseDrawer, onResetFilters }) => {
  const [form] = Form.useForm();
  const { filterGrid, loadGrids } = useGrid();

  useEffect(() => {
    form.setFields([
      { name: 'gradeName', value: null },
      { name: 'isActive', value: null },
    ]);
  }, []);

  const onSearchFiltersDrawer = () => {
    let filters = form.getFieldsValue();

    if (filters?.isActive || filters?.gradeName)
      filters = {
        ...filters,
      };

    filterGrid(filters);
    onCloseDrawer();
  };

  const resetFilters = () => {
    onResetFilters();
    form.resetFields();
    loadGrids(0);
    onCloseDrawer();
  };

  return (
    <Form form={form} layout="vertical">
      <Form.Item className="mb-0" name="gradeName" label="Nome da grade">
        <CustomInput style={{ width: '100%' }} placeholder="Digite o nome da grade" />
      </Form.Item>

      <Form.Item style={{ marginTop: '20px' }} className="mb-0" name="isActive" label="Status das grades">
        <Select
          style={{ display: 'block', marginBottom: '16px' }}
          size="large"
          showSearch
          allowClear
          placeholder="Selecione o status da grade"
        >
          <Option key="ativas" value="true">
            Ativo
          </Option>
          <Option key="ativas" value="false">
            Inativo
          </Option>
        </Select>
      </Form.Item>

      <CustomButton
        className="mt-8 w-100"
        type="primary"
        size="large"
        block
        htmlType="submit"
        onClick={() => onSearchFiltersDrawer()}
      >
        Buscar
      </CustomButton>

      <CustomButton outlined="true" className="mt-2 w-100" onClick={resetFilters} type="ghost" size="large" block>
        Reiniciar Filtros
      </CustomButton>
    </Form>
  );
};

export default GridFilters;
