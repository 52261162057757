import { Col, Row, Tabs, Typography } from 'antd';
import React from 'react';

import { formatCep } from '@utils/cep-mask';
import { useCustomer } from '@hooks/CustomerContext';
import TablePane from '../TablePane';

const { Title, Text } = Typography;

const Address: React.FC = () => {
  const { currentCustomer } = useCustomer();

  const addressColumns = [
    {
      dataIndex: 'stateAbbreviation',
      title: 'Estado',
    },
    {
      dataIndex: 'cityName',
      title: 'Município',
    },
    {
      dataIndex: 'neighborhood',
      title: 'Bairro',
    },
    {
      dataIndex: 'cep',
      title: 'CEP',
      render: (value: string) => {
        return <Text>{formatCep(value)}</Text>;
      },
    },
    {
      dataIndex: 'address',
      title: 'Logradouro',
    },
    {
      dataIndex: 'addressNumber',
      title: 'Número',
    },
    {
      dataIndex: 'complement',
      title: 'Complemento',
    },
    {
      dataIndex: 'reference',
      title: 'Referência',
    },
  ];

  return (
    <Col span={24}>
      <Title className="mt-4" level={4} style={{ color: 'gray' }}>
        Endereços
      </Title>

      <Row>
        <Tabs className="w-100">
          <Tabs.TabPane tab="Comercial" key="principal">
            <TablePane columns={addressColumns} data={currentCustomer.addresses} span={24} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Cobrança" key="cobranca" disabled></Tabs.TabPane>
          <Tabs.TabPane tab="Compras" key="compras" disabled></Tabs.TabPane>
          <Tabs.TabPane tab="Entrega" key="entrega" disabled></Tabs.TabPane>
          <Tabs.TabPane tab="Correspondência" key="correspondencia" disabled></Tabs.TabPane>
          <Tabs.TabPane tab="Showroom" key="showroom" disabled></Tabs.TabPane>
        </Tabs>
      </Row>
    </Col>
  );
};

export default Address;
