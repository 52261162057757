import { OrderInfoResponseType } from '@services/order';
import { formatDateAndHour } from '@utils/date-format-intl';
import { Card, Skeleton } from 'antd';
import './styles.css';

interface OrderInfoCardProps {
  loading: boolean;
  orderInfo: OrderInfoResponseType | undefined;
}

const OrderInfoCard: React.FC<OrderInfoCardProps> = ({ loading, orderInfo }) => {
  return (
    <div className="order-info">
      <Skeleton active loading={loading} title={true} paragraph={{ rows: 4 }}>
        <Card title="Informações do pedido" headStyle={{ textAlign: 'center' }} className="card-info-order">
          <p>
            Fabricante: <strong> {orderInfo?.manufacturerName}</strong>
          </p>
          <p>
            Cliente: <strong> {orderInfo?.customerName}</strong>
          </p>
          <p>
            Código do pedido: <strong> {orderInfo?.orderCode}</strong>
          </p>
          <p>Data do pedido: {orderInfo?.orderDate && <strong> {formatDateAndHour(orderInfo?.orderDate)}</strong>}</p>
        </Card>
      </Skeleton>
    </div>
  );
};

export default OrderInfoCard;
