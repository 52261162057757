import ShoppingBagContent from './components/ShoppingBagContent';
import ShoppingBagHeader from './components/ShoppingBagHeader';

const ShoppingBagPage: React.FC = () => {
  return (
    <>
      <ShoppingBagHeader />
      <ShoppingBagContent />
    </>
  );
};

export default ShoppingBagPage;
