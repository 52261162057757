import { Tabs } from 'antd';
import { useEffect, useState } from 'react';

import { useIntegration } from '@hooks/IntegrationContext';
import { useOrder } from '@hooks/OrdersContext';
import { FailedOrder } from '@models/FailedOrder';
import { Order } from '@models/Order';
import Detail from '../Detail';
import Invoices from '../Invoices';
import ItemDetails from '../ItemDetails';
import OrderActionsTab from '../OrderActionsTab';
import Payment from '../Payment';

interface OrderItemsTabsProps {
  order?: Order;
  orderFailed?: FailedOrder;
}

const OrderItemsTabs: React.FC<OrderItemsTabsProps> = ({ order, orderFailed }) => {
  const { hasMerpIntegration } = useIntegration();
  const [actualOrder, setActualOrder] = useState<Order>({} as Order);

  const { loadOrderDetailByCode, loadFailedOrderDetailByCode } = useOrder();

  useEffect(() => {
    if (order) {
      const load = async () => {
        const orderResponse = await loadOrderDetailByCode(order.orderCode);

        setActualOrder(orderResponse);
      };

      load();
    }
  }, [order]);

  useEffect(() => {
    if (orderFailed) {
      const load = async () => {
        const orderResponse = await loadFailedOrderDetailByCode(orderFailed.sequence);

        setActualOrder(orderResponse);
      };

      load();
    }
  }, [orderFailed]);

  return (
    <>
      <Tabs className="mb-8">
        <Tabs.TabPane tab="Itens do Pedido" key="1">
          <ItemDetails order={actualOrder} isFailedOrder={!!orderFailed} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Pagamento" key="2">
          <Payment order={actualOrder} isFailedOrder={!!orderFailed} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Detalhamento" key="3">
          <Detail order={actualOrder} />
        </Tabs.TabPane>

        {!orderFailed && (
          <Tabs.TabPane tab="Notas Fiscais e Boletos" key="4">
            <Invoices order={actualOrder} />
          </Tabs.TabPane>
        )}

        {hasMerpIntegration && (
          <Tabs.TabPane tab="Ações" key="5">
            <OrderActionsTab order={actualOrder} />
          </Tabs.TabPane>
        )}
      </Tabs>
    </>
  );
};

export default OrderItemsTabs;
