import React from 'react';
import { Form, Typography, Checkbox } from 'antd';
import { CustomButton } from '@styles/globals';

const { Title } = Typography;
interface ConnectionCustomersFiltersProps {}

const ConnectionCustomersFilters: React.FC<ConnectionCustomersFiltersProps> = () => {
  return (
    <Form>
      <Title level={5}>Lojista</Title>

      <Form.Item className="mt-4 mb-0">
        <Checkbox>Nome da Loja</Checkbox>
      </Form.Item>

      <Form.Item className="mb-0">
        <Checkbox>Endereço do Lojista</Checkbox>
      </Form.Item>

      <Form.Item className="mb-0">
        <Checkbox>Representante</Checkbox>
      </Form.Item>

      <Form.Item className="mb-0">
        <Checkbox>Guia</Checkbox>
      </Form.Item>

      <Form.Item className="mb-0">
        <Checkbox>Transportadora</Checkbox>
      </Form.Item>

      <Form.Item className="mb-0">
        <Checkbox>Vendedor</Checkbox>
      </Form.Item>

      <CustomButton className="mt-4" type="primary" htmlType="submit">
        Salvar
      </CustomButton>
    </Form>
  );
};

export default ConnectionCustomersFilters;
