import { Table } from 'antd';
import React, { useEffect } from 'react';

import { useDevolution } from '@hooks/DevolutionsContext';
import { devolutionColumns } from '@pages/DevolutionPage/components/DevolutionColumns/DevolutionColumns';

const FinishedTab: React.FC = () => {
  const {
    finishedDevolutions,
    loadFinishedDevolutions,
    loadingDevolutions,
    finishedFilters,
    finishedPage,
    finishedTotal,
  } = useDevolution();

  React.useEffect(() => {
    loadFinishedDevolutions(0);
  }, []);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.hasOwnProperty('column')) {
      finishedFilters.field = `${sorter.field}`;
      finishedFilters.order = `${sorter.order}`;
    }

    loadFinishedDevolutions(pagination.current, finishedFilters);
  };

  return (
    <Table
      columns={devolutionColumns}
      dataSource={finishedDevolutions}
      loading={loadingDevolutions}
      pagination={{
        pageSize: 10,
        total: finishedTotal,
        current: finishedPage,
        showSizeChanger: false,
        showQuickJumper: false,
      }}
      scroll={{ x: 800 }}
      rowKey={(devolution) => devolution?.invoiceCode}
      onChange={handleTableChange}
    />
  );
};

export default FinishedTab;
