import React, { useState } from 'react';
import { Typography, Table } from 'antd';

import * as S from '../../styles';
import { FullScreenModal } from '@styles/globals';
import PaymentTypeForm from '../PaymentTypeForm';

const { Text } = Typography;

interface PanelPaymentIsMakeToOrderProps {
  dataSourcePaymentFormIsMakeToOrder: any[];
}

const PanelPaymentIsMakeToOrder: React.FC<PanelPaymentIsMakeToOrderProps> = ({
  dataSourcePaymentFormIsMakeToOrder,
}) => {
  const [modalIsMakeToOrderIsVisible, setModalIsMakeToOrderIsVisible] = useState<boolean>(false);
  const [actualPaymentTypeIsMakeToOrder, setActualPaymentTypeIsMakeToOrder] = useState<any>();

  const onReturnPaymentTypeDescription = (value: any): string => {
    const {
      isAccept,
      isAcceptWithGuide,
      isAcceptWithRepresentative,
      isAcceptWithGuideOrRepresentative,
      isNotAccept,
      isNotAcceptWithGuide,
      isNotAcceptWithRepresentative,
      isNotAcceptWithGuideOrRepresentative,
    } = value;

    if (isAccept) return 'Aceita';
    if (isAcceptWithGuide) return 'Aceita quando o lojista possui Guia';
    if (isAcceptWithRepresentative) return 'Aceita quando o lojista possui Representante';
    if (isAcceptWithGuideOrRepresentative) return 'Aceita quando o lojista possui Guia ou Representante';

    if (isNotAccept) return 'Não aceita';
    if (isNotAcceptWithGuide) return 'Não aceita quando o lojista possui Guia';
    if (isNotAcceptWithRepresentative) return 'Não aceita quando o lojista possui Representante';
    if (isNotAcceptWithGuideOrRepresentative) return 'Não aceita quando o lojista possui Guia ou Representante';

    return 'Selecione uma opção...';
  };

  const columnsPaymentOrders = [
    { title: 'Pedidos Sob Encomenda', dataIndex: 'paymentType' },
    {
      render: (value: any) => {
        if (!value) return <Text style={{ color: 'gray' }}>Informação não encontrada</Text>;

        return <Text style={{ color: 'gray' }}>{onReturnPaymentTypeDescription(value)}</Text>;
      },
    },
  ];

  const onCloseModalIsMakeToOrder = () => {
    setModalIsMakeToOrderIsVisible(false);
  };

  return (
    <>
      <S.Panel>
        <Table
          columns={columnsPaymentOrders}
          dataSource={dataSourcePaymentFormIsMakeToOrder}
          pagination={false}
          onRow={(record) => {
            return {
              onClick: (event) => {
                setActualPaymentTypeIsMakeToOrder(record);
                setModalIsMakeToOrderIsVisible(true);
              },
            };
          }}
          style={{ cursor: 'pointer' }}
        />
      </S.Panel>

      <FullScreenModal
        destroyOnClose={true}
        visible={modalIsMakeToOrderIsVisible}
        onOk={onCloseModalIsMakeToOrder}
        onCancel={onCloseModalIsMakeToOrder}
        footer={null}
        forceRender={false}
      >
        <PaymentTypeForm paymentType={actualPaymentTypeIsMakeToOrder} />
      </FullScreenModal>
    </>
  );
};

export default PanelPaymentIsMakeToOrder;
