import { Devolution } from '@models/Devolution';
import { DevolutionsFilters } from '@models/DevolutionsFilters';
import { DevolutionStatusEnum } from '@models/enum/DevolutionStatusEnum';
import * as DevolutionService from '@services/devolution';
import { CORREIOS_ERROR_MESSAGES } from '@utils/correios-errors';
import { message, notification } from 'antd';
import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react';

type DevolutionStatusType = 'ALL' | 'PRIMARY_ANALYSIS' | 'SECONDARY_ANALYSIS' | 'IN_DEVOLUTION' | 'FINISHED';
interface DevolutionState {
  currentDevolution: Devolution | undefined;
  setCurrentDevolution: Function;

  filters: any;
  page: number;
  devolutions: Devolution[];
  total: number;
  loadingDevolutions: boolean;
  loadDevolutions: Function;

  primaryAnalysisFilters: any;
  primaryAnalysisPage: number;
  primaryAnalysisDevolutions: Devolution[];
  primaryAnalysisTotal: number;
  loadPrimaryAnalysisDevolutions: Function;

  secondaryAnalysisFilters: any;
  secondaryAnalysisPage: number;
  secondaryAnalysisDevolutions: Devolution[];
  secondaryAnalysisTotal: number;
  loadSecondaryAnalysisDevolutions: Function;

  inDevolutionFilters: any;
  inDevolutionPage: number;
  inDevolutionDevolutions: Devolution[];
  inDevolutionTotal: number;
  loadInDevolutionDevolutions: Function;

  finishedPage: number;
  finishedDevolutions: any[];
  finishedTotal: number;
  finishedFilters: any;
  loadFinishedDevolutions: Function;

  loadByCodeAndCnpj: Function;
  createDevolutionRequest: Function;
  updateDevolutionRequest: Function;
  finishDevolutionRequest: Function;
  sendDevolutionInvoice: Function;

  status: DevolutionStatusType;
}

interface DevolutionProviderProps {
  children: ReactNode;
}

const PAGINATION_SIZE = 10;

export const DevolutionsContext = createContext<DevolutionState>({} as DevolutionState);

const DevolutionProvider: React.FC<DevolutionProviderProps> = ({ children }) => {
  const [loadingDevolutions, setLoadingDevolutions] = useState<boolean>(false);

  const [filters, setFilters] = useState<any>({} as any);
  const [devolutions, setDevolutions] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);

  const [currentDevolution, setCurrentDevolution] = useState<Devolution | undefined>(undefined);

  const [primaryAnalysisFilters, setPrimaryAnalysisFilters] = useState<any>({} as any);
  const [primaryAnalysisDevolutions, setPrimaryAnalysisDevolutions] = useState<any[]>([]);
  const [primaryAnalysisTotal, setPrimaryAnalysisTotal] = useState<number>(0);
  const [primaryAnalysisPage, setPrimaryAnalysisPage] = useState<number>(0);

  const [secondaryAnalysisFilters, setSecondaryAnalysisFilters] = useState<any>({} as any);
  const [secondaryAnalysisDevolutions, setSecondaryAnalysisDevolutions] = useState<any[]>([]);
  const [secondaryAnalysisTotal, setSecondaryAnalysisTotal] = useState<number>(0);
  const [secondaryAnalysisPage, setSecondaryAnalysisPage] = useState<number>(0);

  const [inDevolutionFilters, setInDevolutionFilters] = useState<any>({} as any);
  const [inDevolutionDevolutions, setInDevolutionDevolutions] = useState<any[]>([]);
  const [inDevolutionTotal, setInDevolutionTotal] = useState<number>(0);
  const [inDevolutionPage, setInDevolutionPage] = useState<number>(0);

  const [finishedDevolutions, setFinishedDevolutions] = useState<any[]>([]);
  const [finishedTotal, setFinishedTotal] = useState<number>(0);
  const [finishedPage, setFinishedPage] = useState<number>(0);
  const [finishedFilters, setFinishedFilters] = useState<any>({} as any);

  let status: 'ALL' | 'PRIMARY_ANALYSIS' | 'SECONDARY_ANALYSIS' | 'IN_DEVOLUTION' | 'FINISHED' = 'ALL';

  const updateDevolutionsArray = () => {
    if (status === DevolutionStatusEnum.PRIMARY_ANALYZIS) {
      loadPrimaryAnalysisDevolutions(0);
    }

    if (status === DevolutionStatusEnum.SECONDARY_ANALYSIS) {
      loadSecondaryAnalysisDevolutions(0);
    }

    if (status === DevolutionStatusEnum.IN_DEVOLUTION) {
      loadInDevolutionDevolutions(0);
    }

    if (status === DevolutionStatusEnum.FINISHED) {
      loadFinishedDevolutions(0);
    }

    if (status === 'ALL') {
      loadDevolutions(0);
    }
  };

  const searchDevolutions = async (page: number, filters?: DevolutionsFilters) => {
    var devolutions: any[] = [];
    var total = 0;

    const { data: _devolutions } = await DevolutionService.get({
      pageSize: PAGINATION_SIZE,
      page: page ? page - 1 : 0,
      ...(filters && { ...filters }),
    });

    total = total + _devolutions.total;
    devolutions = [...devolutions, ..._devolutions.data];

    return {
      devolutions,
      total,
    };
  };

  const loadDevolutions = async (page: number, filters?: DevolutionsFilters) => {
    setLoadingDevolutions(true);
    status = 'ALL';
    if (filters) setPrimaryAnalysisFilters(filters);
    else setPrimaryAnalysisFilters({});

    try {
      const { devolutions, total } = await searchDevolutions(page, filters);

      setDevolutions(devolutions);
      setTotal(total);
      setPage(page === 0 ? 1 : page);
    } catch (error) {
      message.error('Erro ao buscar devoluções');
    } finally {
      setLoadingDevolutions(false);
    }
  };

  const loadPrimaryAnalysisDevolutions = async (page: number, filters?: DevolutionsFilters) => {
    setLoadingDevolutions(true);
    status = 'PRIMARY_ANALYSIS';
    if (filters) setPrimaryAnalysisFilters(filters);
    else setPrimaryAnalysisFilters({});

    const statusOrder = [DevolutionStatusEnum.PRIMARY_ANALYZIS];

    try {
      const { devolutions, total } = await searchDevolutions(page, {
        ...filters,
        statusOrder: statusOrder || [],
      });

      setPrimaryAnalysisDevolutions(devolutions);
      setPrimaryAnalysisTotal(total);
      setPrimaryAnalysisPage(page === 0 ? 1 : page);
    } catch (error) {
      message.error('Erro ao listar devoluções');
    } finally {
      setLoadingDevolutions(false);
    }
  };

  const loadSecondaryAnalysisDevolutions = async (page: number, filters?: DevolutionsFilters) => {
    setLoadingDevolutions(true);
    status = 'SECONDARY_ANALYSIS';
    if (filters) setSecondaryAnalysisFilters(filters);
    else setSecondaryAnalysisFilters({});

    const statusOrder = [DevolutionStatusEnum.SECONDARY_ANALYSIS];

    try {
      const { devolutions, total } = await searchDevolutions(page, {
        ...filters,
        statusOrder: statusOrder || [],
      });

      setSecondaryAnalysisDevolutions(devolutions);
      setSecondaryAnalysisTotal(total);
      setSecondaryAnalysisPage(page === 0 ? 1 : page);
    } catch (error) {
      message.error('Erro ao listar devoluções');
    } finally {
      setLoadingDevolutions(false);
    }
  };

  const loadInDevolutionDevolutions = useCallback(
    async (page: number, filters?: DevolutionsFilters) => {
      setLoadingDevolutions(true);
      status = 'IN_DEVOLUTION';
      if (filters) setInDevolutionFilters(filters);
      else setInDevolutionFilters({});

      const statusOrder = [DevolutionStatusEnum.IN_DEVOLUTION];

      try {
        const { devolutions, total } = await searchDevolutions(page, {
          ...filters,
          statusOrder: statusOrder || [],
        });

        setInDevolutionDevolutions(devolutions);
        setInDevolutionTotal(total);
        setInDevolutionPage(page === 0 ? 1 : page);
      } catch (error) {
        message.error('Erro ao listar devoluções');
      } finally {
        setLoadingDevolutions(false);
      }
    },
    [PAGINATION_SIZE, setLoadingDevolutions, setInDevolutionTotal, setInDevolutionPage],
  );

  const loadFinishedDevolutions = useCallback(
    async (page: number, filters?: DevolutionsFilters) => {
      setLoadingDevolutions(true);
      status = 'FINISHED';
      if (filters) setFinishedFilters(filters);
      else setFinishedFilters({});

      const statusOrder = [DevolutionStatusEnum.FINISHED];

      try {
        const { devolutions, total } = await searchDevolutions(page, {
          ...filters,
          statusOrder: statusOrder || [],
        });

        setFinishedDevolutions(devolutions);
        setFinishedTotal(total);
        setFinishedPage(page === 0 ? 1 : page);
      } catch (error) {
        message.error('Erro ao listar devoluções');
      } finally {
        setLoadingDevolutions(false);
      }
    },
    [PAGINATION_SIZE, setLoadingDevolutions, setFinishedTotal, setFinishedPage],
  );

  const loadByCodeAndCnpj = useCallback(
    async (code: string, cnpj: string) => {
      setLoadingDevolutions(true);
      try {
        const { data } = await DevolutionService.getByCodeAndCnpj(code, cnpj);

        setCurrentDevolution(data);
      } catch (error) {
        message.error('Erro ao buscar devolução');
      } finally {
        setLoadingDevolutions(false);
      }
    },
    [setLoadingDevolutions, setCurrentDevolution],
  );

  const createDevolutionRequest = useCallback(
    async (devolution: Devolution) => {
      setLoadingDevolutions(true);
      try {
        const { data } = await DevolutionService.createDevolutionRequest(devolution);

        setCurrentDevolution(data);
      } catch (error: any) {
        if (error.response.status === 406) {
          message.error('Essa devolução já foi solicitada');
        } else {
          message.error('Erro ao criar devolução');
        }
        throw error;
      } finally {
        setLoadingDevolutions(false);
      }
    },
    [setLoadingDevolutions, setCurrentDevolution],
  );

  const updateDevolutionRequest = useCallback(
    async (params: any) => {
      setLoadingDevolutions(true);

      try {
        const { data } = await DevolutionService.updateDevolutionRequest(params);
        updateDevolutionsArray();
      } catch (error: any) {
        if (error?.response?.data?.code) {
          notification.error({
            message: 'Erro ao gerar PAC',
            description: CORREIOS_ERROR_MESSAGES[error?.response?.data?.code.toString()],
          });
        } else if (error?.response?.data?.statusCode === 412) {
          notification.error({
            message: 'Erro ao gerar PAC',
            description: 'Falha na geração da logistica reversa',
          });
        } else if (params?.statusObservation) {
          notification.error({
            message: 'Erro',
            description: `Não foi possível ${
              params?.statusObservation === 'APPROVED' ? 'aprovar' : 'reprovar'
            } a devolução`,
          });
        } else {
          notification.error({
            message: 'Erro',
            description: `Não foi possível atualizar a devolução`,
          });
        }

        throw error;
      } finally {
        setLoadingDevolutions(false);
      }
    },
    [setLoadingDevolutions],
  );

  const finishDevolutionRequest = useCallback(
    async (sequence: number) => {
      setLoadingDevolutions(true);
      try {
        const { data } = await DevolutionService.finishDevolutionRequest(sequence);
        updateDevolutionsArray();
      } catch (error) {
        message.error('Erro ao atualizar devolução');
        throw error;
      } finally {
        setLoadingDevolutions(false);
      }
    },
    [setLoadingDevolutions],
  );

  const sendDevolutionInvoice = useCallback(
    async (params: any) => {
      setLoadingDevolutions(true);
      try {
        await DevolutionService.sendDevolutionInvoice(params);
      } catch (error) {
        message.error('Erro ao enviar solicitação de devolução');
        throw error;
      } finally {
        setLoadingDevolutions(false);
      }
    },
    [setLoadingDevolutions],
  );

  return (
    <DevolutionsContext.Provider
      value={{
        currentDevolution,
        setCurrentDevolution,

        filters,
        page,
        devolutions,
        total,
        loadingDevolutions,
        loadDevolutions,

        primaryAnalysisFilters,
        primaryAnalysisPage,
        primaryAnalysisDevolutions,
        primaryAnalysisTotal,
        loadPrimaryAnalysisDevolutions,

        secondaryAnalysisFilters,
        secondaryAnalysisPage,
        secondaryAnalysisDevolutions,
        secondaryAnalysisTotal,
        loadSecondaryAnalysisDevolutions,

        inDevolutionPage,
        inDevolutionFilters,
        inDevolutionDevolutions,
        inDevolutionTotal,
        loadInDevolutionDevolutions,

        finishedPage,
        finishedDevolutions,
        finishedTotal,
        finishedFilters,
        loadFinishedDevolutions,

        loadByCodeAndCnpj,
        createDevolutionRequest,
        updateDevolutionRequest,
        finishDevolutionRequest,
        sendDevolutionInvoice,

        status,
      }}
    >
      {children}
    </DevolutionsContext.Provider>
  );
};

const useDevolution = () => {
  return useContext(DevolutionsContext);
};

export { DevolutionProvider, useDevolution };
