import { Stock } from '@models/Stock';
import { CustomButton } from '@styles/globals';
import { Col, Form, InputNumber, Row, message } from 'antd';
import React, { useCallback, useEffect } from 'react';

interface NewStockStep1Props {
  newStock?: Stock;
  nextStep: () => void;
  handleAddStockField: (values: Partial<Stock>) => void;
}

const NewStockStepBranchCode: React.FC<NewStockStep1Props> = ({ nextStep, handleAddStockField, newStock }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ branchCode: newStock?.branchCode, stockCode: newStock?.stockCode });
  }, [newStock]);

  const handleSubmit = useCallback((values: Partial<Stock>) => {
    if (!values.branchCode || !values.stockCode)
      return message.warning('Preencha os campos necessários para prosseguir!');

    handleAddStockField({ branchCode: Math.trunc(+values.branchCode), stockCode: Math.trunc(+values.stockCode) });
    nextStep();
  }, []);

  return (
    <>
      <Form
        key={'formStock'}
        layout="vertical"
        className="d-flex justify-center align-center flex-column"
        form={form}
        onFinish={(values: Partial<Stock>) => handleSubmit(values)}
      >
        <Row>
          <Col
            className="d-flex gutter-row justify-center align-center flex-grow-1"
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
          >
            <div className="question-panel-wrapper">
              <Form.Item
                name="branchCode"
                label="Empresa"
                required
                rules={[{ required: true, message: 'O código da empresa é obrigatório!' }]}
              >
                <InputNumber
                  className="w-100"
                  autoFocus
                  step={1}
                  min={1}
                  max={9999999999}
                  placeholder="Insira o código da empresa"
                />
              </Form.Item>
              <Form.Item
                name="stockCode"
                label="Código do estoque"
                required
                rules={[{ required: true, message: 'O código do estoque é obrigatório!' }]}
              >
                <InputNumber
                  className="w-100"
                  step={1}
                  min={1}
                  max={9999999999}
                  autoComplete="off"
                  placeholder="Insira o código do estoque"
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <CustomButton
          style={{ backgroundColor: 'var(--primary-color)', color: 'var(--support-color)' }}
          htmlType="submit"
        >
          Próximo passo
        </CustomButton>
      </Form>
    </>
  );
};

export default NewStockStepBranchCode;
