import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import SellerProfileHeader from './components/SellerProfileHeader';
import SellerProfileContent from './components/SellerProfileContent';
import { useSeller } from '@hooks/SellerContext';
import LoadingComponents from '@components/LoadingComponents';

interface MatchParams {
  id: string;
}
const SellersProfilePage: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();
  const { loadingSellerByCode, loadSellerByCode } = useSeller();

  useEffect(() => {
    loadSellerByCode(parseInt(params.id));
  }, []);

  if (loadingSellerByCode) return <LoadingComponents />

  return (
    <>
      <SellerProfileHeader />
      <SellerProfileContent />
    </>
  );
};

export default SellersProfilePage;
