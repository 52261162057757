import React from 'react';

import DevolutionRequestForm from '@components/DevolutionRequestForm';

const DevolutionRequestPage: React.FC = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: '#f5f5f5',
          display: 'flex',
          justifyContent: 'center',
          overflow: 'auto',
          height: '100vh',
        }}
      >
        <div style={{ maxWidth: '1100px', height: 'fit-content', paddingTop: '10px', paddingBottom: '110px' }}>
          <DevolutionRequestForm />
        </div>
      </div>
    </>
  );
};

export default DevolutionRequestPage;
