import React from 'react';
import { Table, Typography } from 'antd';
import { useRepresentative } from '@hooks/RepresentativeContext';
import { SearchOutlined } from '@ant-design/icons';
import TableFilters from '@components/TableFilters';

import './styles.css';
import Loading from '@components/Loading';

const { Title } = Typography;

interface CustomersTabProps {}

const CustomersTab: React.FC<CustomersTabProps> = () => {
  const { customers, customersTotal, loadingCustomers } = useRepresentative();

  const columns = [
    {
      title: 'Código',
      width: '100px',
      align: 'center' as 'center',
      dataIndex: 'code',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pelo Código'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.code ? record.code.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
      sorter: (clienteA: any, clienteB: any) => clienteA.code - clienteB.code,
    },
    {
      title: 'Lojista',
      dataIndex: 'name',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pelo Nome'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.name ? record.name.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
      sorter: (clienteA: any, clienteB: any) => clienteA.name.trim().localeCompare(clienteB.name.trim()),
    },
    {
      title: 'CNPJ',
      dataIndex: 'cpfCnpj',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pelo CNPJ'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.cpfCnpj ? record.cpfCnpj.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
      sorter: (clienteA: any, clienteB: any) => clienteA.cpfCnpj - clienteB.cpfCnpj,
    },
  ];

  if (loadingCustomers) return <Loading />;

  return (
    <div className="mt-6 ml-4">
      <Title level={5}>Lojistas</Title>
      <Table
        columns={columns}
        dataSource={customers}
        scroll={{ x: 800 }}
        className="customers-table"
        rowKey={(record) => record.code}
        pagination={{
          pageSize: 8,
          total: customersTotal,
          showSizeChanger: false,
          showQuickJumper: false,
        }}
      />
    </div>
  );
};

export default CustomersTab;
