import React, { createContext, useState, ReactNode, useContext, useCallback } from 'react';

import { Product } from '@models/Product';
import * as ProductService from '@services/product';

import { CollectionDetailsFiltersType } from '@models/CollectionDetailsFilters';
import { Reference } from '@models/Reference';
import { message } from 'antd';

interface ProductState {
  products: Product[];
  productsOfCollection: Product[];
  productsSizes: any[];

  productReferenceDetails: Reference;

  tabData: any;
  tabValue: any;
  tabBarCode: any;
  tabClassification: any;

  loading: boolean;
  loadingDetails: boolean;

  loadProducts: Function;
  loadProductsByCollectionId: Function;
  loadSizes: Function;
  loadReferenceInfo: Function;

  loadProductDetails: Function;
  updateProductStatus: Function;
  updateReferenceObservation: Function;

  loadingSizes: boolean;
}

interface ProductProviderProps {
  children: ReactNode;
}

export const ProductContext = createContext<ProductState>({} as ProductState);

const ProductProvider: React.FC<ProductProviderProps> = ({ children }) => {
  const [products, setProducts] = useState<any[]>([]);
  const [productsOfCollection, setProductsOfCollection] = useState<any[]>([]);
  const [productsSizes, setProductsSizes] = useState<any[]>([]);

  const [productReferenceDetails, setProductReferenceDetails] = useState<Reference>({} as Reference);

  const [tabData, setTabData] = useState<any[]>([]);
  const [tabValue, setTabValue] = useState<any[]>([]);
  const [tabBarCode, setTabBarCode] = useState<any[]>([]);
  const [tabClassification, setTabClassification] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false);
  const [loadingSizes, setLoadingSizes] = useState<boolean>(false);

  const loadProducts = useCallback(
    async (filters?: any) => {
      setLoading(true);

      const { data: _products } = await ProductService.get(filters);

      setProducts(_products);
      setLoading(false);
    },
    [products, setLoading],
  );

  const loadProductDetails = useCallback(
    async (referenceCode: string, productCode: number) => {
      setLoadingDetails(true);

      const { data: _product } = await ProductService.getDetails(referenceCode, productCode);

      if (_product) {
        const index = products.findIndex(
          (product) => product.ReferenceCode === referenceCode && product.code === productCode,
        );

        if (index !== -1) {
          products[index] = { ..._product };
          setProducts([...products]);
        }
      }

      setLoadingDetails(false);
    },
    [products, setProducts, setLoadingDetails],
  );

  const loadProductsByCollectionId = useCallback(
    async (collectionId: number, filters?: CollectionDetailsFiltersType) => {
      setLoading(true);
      const { data } = await ProductService.findProductsByCollectionId(collectionId, filters);
      setProductsOfCollection(data);
      setLoading(false);
    },
    [products],
  );

  const loadSizes = useCallback(async () => {
    setLoadingSizes(true);
    const { data } = await ProductService.getSizes();
    setProductsSizes(data);
    setLoadingSizes(false);
  }, [setLoadingSizes, setProductsSizes]);

  const loadReferenceInfo = useCallback(
    async (referenceCode: string) => {
      const { data: _reference } = await ProductService.getReferenceInfo(referenceCode);
      setProductReferenceDetails(_reference);
    },
    [setProductReferenceDetails],
  );

  const updateProductStatus = useCallback(
    async (referenceCode: string, colorCode: string, productCode: number) => {
      const index = products.findIndex(
        (_product: Product) =>
          _product.ReferenceCode === referenceCode && _product.code === productCode && _product.colorCode === colorCode,
      );

      const response = await ProductService.updateStatus(
        referenceCode,
        colorCode,
        productCode,
        !products[index].isActive,
      );

      if (response) {
        products[index].isActive = !products[index].isActive;
        setProducts([...products]);
      }
    },
    [products, setProducts],
  );

  const updateReferenceObservation = useCallback(
    async (referenceCode: string, observation: any) => {
      try {
        await ProductService.updateReferenceObservation(referenceCode, observation);
        message.success('Observação alterada com sucesso!');
      } catch {
        message.error('Erro ao gravar observação!');
      }
    },
    [products, setProducts],
  );

  return (
    <ProductContext.Provider
      value={{
        products,
        productReferenceDetails,
        tabData,
        tabValue,
        tabBarCode,
        tabClassification,
        loading,
        loadingDetails,
        productsOfCollection,
        productsSizes,
        loadingSizes,
        loadProducts,
        loadReferenceInfo,
        loadProductsByCollectionId,
        loadSizes,
        loadProductDetails,
        updateProductStatus,
        updateReferenceObservation,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

const useProduct = () => {
  const context = useContext(ProductContext);
  return context;
};

export { ProductProvider, useProduct };
