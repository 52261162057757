import { InboxOutlined } from '@ant-design/icons';
import { CustomButton } from '@styles/globals';
import { beforeUploadFileSheet } from '@utils/file-sheet-validation';
import { Form, Input, message, Steps, Upload } from 'antd';
import React, { useCallback, useState } from 'react';
import * as S from './styles';
import LoadingComponents from '@components/LoadingComponents';
import { UploadFile } from 'antd/lib/upload/interface';
import { useGoal } from '@hooks/GoalContext';

const { Dragger } = Upload;

interface CreateGoalProps {
  onSubmitGoal: Function;
  onSubmitFileSheet: Function;
  newGoalId: number | undefined;
  initialPage?: 0 | 1;
}

const steps = [
  {
    title: 'Criar meta',
  },
  {
    title: 'Enviar arquivo',
  },
];

const CreateGoal: React.FC<CreateGoalProps> = ({ onSubmitGoal, onSubmitFileSheet, newGoalId, initialPage }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { loadingCreateGoal, loadingUploadGoalFileSheet } = useGoal();
  const [formPage, setFormPage] = useState<0 | 1>(initialPage ?? 0);

  const handleUpload = async ({ file }: any) => {
    if (!file) return;

    setFileList((list) => [...list, file]);
  };

  const handleSubmitFormPageDescription = useCallback(
    async (values: { description: string }) => {
      await onSubmitGoal(values);
      setFormPage(1);
    },
    [onSubmitGoal, setFormPage],
  );

  const handleSubmitFormPageFile = useCallback(async () => {
    {
      if (fileList?.length == 0) return message.info('Escolha algum arquivo .CSV para prosseguir');
      onSubmitFileSheet(fileList[0]);
    }
  }, [fileList, onSubmitFileSheet]);

  if (loadingCreateGoal || loadingUploadGoalFileSheet) return <LoadingComponents />;

  return (
    <S.Container>
      <div className="mt-2 mb-8 d-flex flex-column align-center justify-center">
        <S.Title className="mb-0 text-primary">{formPage == 0 ? 'CRIAR META' : 'ENVIAR ARQUIVO'}</S.Title>
        {formPage == 0 && <S.Subtitle>Informe uma descrição</S.Subtitle>}
        {formPage == 1 && (
          <>
            <S.Subtitle className="mb-0 mt-2">
              Faça upload de um arquivo de metas <br />
            </S.Subtitle>
            <S.Subtitle className="mb-0">Garanta que o ID da meta esteja presente no arquivo</S.Subtitle>
            <S.Subtitle>
              ID da nova meta: <strong className="text-primary">#{newGoalId}</strong>
            </S.Subtitle>
          </>
        )}
      </div>

      <S.StepsContainer progressDot current={formPage} className="mb-8 w-100">
        {steps.map((item) => (
          <Steps.Step key={item.title} title={item.title} />
        ))}
      </S.StepsContainer>

      {formPage == 0 && (
        <Form layout="vertical" onFinish={handleSubmitFormPageDescription}>
          <Form.Item
            className="mb-2"
            label="Descrição"
            name="description"
            rules={[{ required: true, message: 'A meta necessita de uma descrição.' }]}
          >
            <Input className="uppercase" placeholder="Digite uma descrição" autoFocus />
          </Form.Item>
          <CustomButton htmlType="submit" type="primary" className="w-100">
            CRIAR META
          </CustomButton>
        </Form>
      )}

      {formPage == 1 && (
        <>
          <Dragger
            beforeUpload={beforeUploadFileSheet}
            customRequest={handleUpload}
            disabled={!!fileList.length}
            accept="text/csv"
            fileList={fileList}
          >
            <>
              <p className="ant-upload-drag-icon">
                <InboxOutlined style={{ color: 'var(--primary-color)' }} />
              </p>
              <p className="ant-upload-text">Clique ou arraste algum arquivo nesta área para realizar o upload</p>
              <p className="ant-upload-hint">Apenas arquivos com a extensão .CSV serão aceitos.</p>
            </>
          </Dragger>
          <CustomButton htmlType="button" type="primary" className="w-100" onClick={handleSubmitFormPageFile}>
            FINALIZAR
          </CustomButton>
        </>
      )}
    </S.Container>
  );
};

export default CreateGoal;
