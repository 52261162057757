import { Spin } from 'antd';
import * as S from './styles';

interface HeaderButtonProps {
  onClick: Function;
  text: string;
  Icon: React.ReactNode;
  loading?: boolean;
}

const HeaderButton: React.FC<HeaderButtonProps> = ({ onClick, text, Icon, loading }) => {
  return (
    <S.ActionButtonContainer onClick={() => onClick()}>
      {!loading && (<>{ Icon }</>)}
      {loading && <Spin size="small" />}
      <S.ActionButtonLabel>{text}</S.ActionButtonLabel>
    </S.ActionButtonContainer>
  );
};

export default HeaderButton;
