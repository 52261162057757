import { DeleteOutlined, PictureOutlined } from '@ant-design/icons';
import ModalPlayerVideo from '@components/ModalPlayerVideo';
import { useVideos } from '@hooks/VideosContext';
import { VideoProps } from '@models/VideoProps';
import { Image, Progress, Table } from 'antd';
import { useState } from 'react';
import './styles.css';

const UploadVideosContent: React.FC = () => {
  const { fileList, removeVideo, loading } = useVideos();

  const [urlVideo, setUrlVideo] = useState<string>('');

  const handleClickThumbnail = (url: string) => {
    setUrlVideo(url);
  };

  const columns = [
    {
      width: '80px',
      align: 'center' as 'center',
      render: (value: VideoProps) =>
        !value.error || !value.thumbnailUrl ? (
          <div className="div-icon-picture" onClick={() => handleClickThumbnail(value.url)}>
            <PictureOutlined />
          </div>
        ) : (
          <Image
            preview={false}
            onClick={() => handleClickThumbnail(value.url)}
            style={{ maxHeight: '80px' }}
            src={value.thumbnailUrl}
          />
        ),
    },
    { dataIndex: 'name', title: 'Nome do arquivo' },
    { dataIndex: 'reference', title: 'Referência' },
    {
      title: 'Erro?',
      align: 'center' as 'center',
      render: (video: VideoProps) => {
        if (video.uploaded) {
          return video.error ? <span>Sim</span> : <span>Não</span>;
        }
      },
    },
    {
      title: 'Enviado',
      render: (video: VideoProps) => {
        if (video.uploaded) {
          return video.error ? <Progress percent={100} status="exception" /> : <Progress percent={100} />;
        }
      },
    },
    {
      render: (video: VideoProps) =>
        !video.uploaded && (
          <DeleteOutlined style={{ cursor: 'pointer', fontSize: '18px' }} onClick={() => removeVideo(video)} />
        ),
      align: 'right' as 'right',
    },
  ];

  return (
    <>
      <ModalPlayerVideo
        src={urlVideo}
        visible={!!urlVideo}
        onCancel={() => {
          setUrlVideo('');
        }}
      />
      <Table className="mt-4" dataSource={fileList} columns={columns} loading={loading} />
    </>
  );
};

export default UploadVideosContent;
