import { Col, Drawer, Row, Typography } from 'antd';
import React, { useCallback, useState } from 'react';

import { FilterFilled, PlusCircleFilled } from '@ant-design/icons';
import FiltersList, { ListFilterType } from '@components/FiltersList';
import HeaderButton from '@components/HeaderButton';
import RepresentativeForm from '@components/RepresentativeForm';
import { useIntegration } from '@hooks/IntegrationContext';
import { useRepresentative } from '@hooks/RepresentativeContext';
import { FullScreenModal } from '@styles/globals';
import FiltersForm from '../FiltersForm';

const { Title } = Typography;

interface RepresentativesHeaderProps {}

const RepresentativesHeader: React.FC<RepresentativesHeaderProps> = () => {
  const { hasMerpIntegration } = useIntegration();

  const [modalNewRepresentativeVisible, setModalNewRepresentativeVisible] = useState(false);

  const [filtersDrawerIsVisible, setfiltersDrawerIsVisible] = useState<boolean>(false);
  const { representativeFilters, loadRepresentativesPaginated } = useRepresentative();

  const handleCloseFiltersDrawer = useCallback(() => {
    setfiltersDrawerIsVisible(false);
  }, [setfiltersDrawerIsVisible]);

  const handleShowFiltersDrawer = useCallback(() => {
    setfiltersDrawerIsVisible(true);
  }, [setfiltersDrawerIsVisible]);

  const onRemoveFilter = ({ key }: ListFilterType) => {
    const newFilters = representativeFilters;
    delete newFilters[key];
    loadRepresentativesPaginated(0, newFilters);
  };

  const showNewRepresentativeModal = () => {
    setModalNewRepresentativeVisible(true);
  };

  const onCloseNewRepresentativeModal = () => {
    setModalNewRepresentativeVisible(false);
  };

  return (
    <>
      <Row className="mt-8" justify="space-between">
        <Col>
          <Title level={2}>Meus Representantes</Title>
        </Col>
        <Col>
          <Row>
            <Col>
              <FiltersList
                columns={[{ title: 'Nome/Cnpj', key: 'searchByNameOrCnpj' }]}
                dataSource={representativeFilters}
                onRemove={onRemoveFilter}
              />
            </Col>
            <Col>
              <HeaderButton
                text="Filtros"
                onClick={handleShowFiltersDrawer}
                Icon={<FilterFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
              />
            </Col>
            {hasMerpIntegration && (
              <Col>
                <HeaderButton
                  text="Novo representante"
                  onClick={showNewRepresentativeModal}
                  Icon={<PlusCircleFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <Drawer
        title="Filtros"
        placement="left"
        onClose={handleCloseFiltersDrawer}
        visible={filtersDrawerIsVisible}
        width={400}
      >
        <FiltersForm onCloseDrawer={handleCloseFiltersDrawer} />
      </Drawer>

      <FullScreenModal
        destroyOnClose={true}
        visible={modalNewRepresentativeVisible}
        onOk={onCloseNewRepresentativeModal}
        onCancel={onCloseNewRepresentativeModal}
        footer={null}
        forceRender={false}
      >
        <Row align="middle" justify="center">
          <Col span={24} className="d-flex" style={{ width: '1000px' }}>
            <RepresentativeForm onCloseModal={onCloseNewRepresentativeModal} isEditing={true}/>
          </Col>
        </Row>
      </FullScreenModal>
    </>
  );
};

export default RepresentativesHeader;
