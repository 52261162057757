import React, { useCallback, useEffect } from 'react';
import { Col, Typography, Radio, Form, Checkbox, Row, message } from 'antd';

import './styles.css';
import { CustomButton } from '@styles/globals';
const { Title } = Typography;

interface QuestionTypeStepProps {
  handleAddQuestionField: Function;
  nextStep: Function;
  editingQuestion?: any;
}

const QuestionTypeStep: React.FC<QuestionTypeStepProps> = ({ handleAddQuestionField, nextStep, editingQuestion }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editingQuestion) {
      form.setFieldsValue({
        questionType: editingQuestion.questionType,
        isDeliverImmediately: editingQuestion.isDeliverImmediately,
        isMakeToOrder: editingQuestion.isMakeToOrder,
        isRequired: editingQuestion.isRequired,
      });
      return;
    }

    form.setFieldsValue({
      questionType: 'TEXTO',
      isDeliverImmediately: false,
      isMakeToOrder: false,
      isRequired: false,
    });
    
  }, []);

  const handleSubmit = useCallback((values: any) => {
    if (!values.questionType || (!values.isMakeToOrder && !values.isDeliverImmediately))
      return message.warning('Preencha os campos necessários para prosseguir!');

    handleAddQuestionField(values);
    nextStep();
  }, []);

  return (
    <Form
      layout="vertical"
      className="d-flex"
      style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
      form={form}
      onFinish={(values: any) => handleSubmit(values)}
    >
      <Row gutter={[16, 16]}>
        <Col
          className="d-flex gutter-row"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <div className="question-panel-wrapper">
            <Title level={4}>Tipo de Pergunta</Title>

            <Form.Item name="questionType">
              <Radio.Group className="d-flex" style={{ flexDirection: 'column' }}>
                <Radio className="radio-option" value="TEXTO">
                  Texto Aberto
                </Radio>
                <Radio className="radio-option" value="CAIXA DE SELEÇÃO">
                  Caixa de Seleção
                </Radio>
                <Radio className="radio-option" value="LISTA DE SELEÇÃO">
                  Lista de Seleção
                </Radio>
                <Radio className="radio-option" value="TEXTO REPRESENTANTE">
                  Texto Representante
                </Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </Col>

        <Col
          className="d-flex gutter-row"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <div className="question-panel-wrapper">
            <Title level={4}>Tipo de Venda</Title>

            <Form.Item name="isDeliverImmediately" valuePropName="checked">
              <Checkbox>Pronta Entrega</Checkbox>
            </Form.Item>
            <Form.Item name="isMakeToOrder" valuePropName="checked">
              <Checkbox>Sob Encomenda</Checkbox>
            </Form.Item>
          </div>
        </Col>

        <Col
          className="d-flex gutter-row mt-4"
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <Form.Item name="isRequired" valuePropName="checked">
            <Checkbox>Resposta Obrigatória</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <CustomButton type="primary" htmlType="submit">
        Continuar
      </CustomButton>
    </Form>
  );
};

export default QuestionTypeStep;
