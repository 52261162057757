import { ExclamationCircleOutlined } from '@ant-design/icons';
import { SharedData } from '@components/ProductForm/models/product-form-models';
import AddRepresentativePhoneFormModal from '@components/RepresentativeForm/components/AddRepresentativePhoneFormModal';
import { RepresentativePhone } from '@components/RepresentativeForm/models/representative-form-models';
import { Container } from '@pages/MainPage/styles';
import { CustomButton, FullScreenModal } from '@styles/globals';
import { Col, Modal, Row, Table, notification } from 'antd';
import { registerRenderer, textRenderer } from 'handsontable/renderers';
import React from 'react';

registerRenderer('customStylesRenderer', (hotInstance, TD, ...rest) => {
  textRenderer(hotInstance, TD, ...rest);
  TD.style.fontSize = '18px';
});

interface RepresentativeFormPhoneStepProps {
  sharedData?: SharedData;
  changeSharedData?: Function;
  formValues: RepresentativePhone[];
  setFormValues: Function;
  goToNextStep: Function;
  goToBackStep: Function;
  isEditing: boolean;
  render: boolean;
}

const RepresentativeFormPhoneStep: React.FC<RepresentativeFormPhoneStepProps> = ({
  formValues,
  setFormValues,
  goToNextStep,
  goToBackStep,
  isEditing = false,
  render = true,
}) => {
  const [addRepresentativePhoneModalIsVisible, setAddRepresentativePhoneModalIsVisible] = React.useState(false);

  const [selectedPhones, setSelectedPhones] = React.useState<RepresentativePhone[]>([]);

  const onCloseAddRepresentativePhoneModal = () => setAddRepresentativePhoneModalIsVisible(false);

  const onShowAddRepresentativePhoneModal = () => {
    setAddRepresentativePhoneModalIsVisible(true);
  };

  React.useEffect(() => {
    if (!isEditing && formValues?.length) {
      setSelectedPhones(formValues);
    }
  }, []);

  React.useEffect(() => {
    if (isEditing && formValues?.length) {
      setSelectedPhones(formValues);
    }
  }, [formValues]);

  React.useEffect(() => {
    const values = selectedPhones;
    setFormValues(values);
  }, [selectedPhones]);

  const finishStep = async () => {
    if (selectedPhones?.length > 0) {
      const values = selectedPhones;
      setFormValues(values);
      showModalConfirmation();
    } else {
      notification.warning({
        message: 'Aviso',
        description: `Adicione pelo menos um telefone para o representante.`,
      });
    }
  };

  const returnStep = async () => {
    const values = selectedPhones;
    setFormValues(values);
    goToBackStep();
  };

  const showModalConfirmation = () => {
    Modal.confirm({
      title: `Tem certeza que deseja ${isEditing ? 'Editar' : 'Cadastrar'}?`,
      icon: <ExclamationCircleOutlined />,
      okText: isEditing ? 'Editar' : 'Cadastrar',
      cancelText: 'Cancelar',
      onOk() {
        goToNextStep();
      },
    });
  };

  const removeEmail = (key: any) => {
    const newList = selectedPhones.filter((phone: RepresentativePhone) => phone?.key !== key);
    setSelectedPhones(newList);
  };

  const addPhone = (newEmail: RepresentativePhone) => {
    if (newEmail.isDefault) {
      let phonesCopy = [...selectedPhones, newEmail];
      phonesCopy = phonesCopy.map((e) => {
        if (e.key === newEmail.key) {
          return {
            ...e,
          };
        } else {
          return {
            ...e,
            isDefault: false,
          };
        }
      });
      setSelectedPhones(phonesCopy);
    } else {
      setSelectedPhones((prev) => [...prev, newEmail]);
    }
  };

  const handleSetIsDefault = (phone: RepresentativePhone) => {
    let phonesCopy = [...selectedPhones];
    phonesCopy = phonesCopy.map((e) => {
      if (e.key === phone.key) {
        return {
          ...e,
          isDefault: !e.isDefault,
        };
      } else {
        return {
          ...e,
          isDefault: false,
        };
      }
    });

    setSelectedPhones(phonesCopy);
  };

  return (
    <>
      {render && (
        <>
          <div className="d-flex" style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}>
            <Row className="d-flex" style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Col span={5}>
                <CustomButton type="primary" style={{ marginBottom: 10 }} onClick={onShowAddRepresentativePhoneModal}>
                  Adicionar telefone
                </CustomButton>
              </Col>
            </Row>

            <Row className="d-flex" style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Col span={24}>
                <h2 style={{ margin: '20px 0 30px 0' }}>Telefones adicionados</h2>
              </Col>
            </Row>

            <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <Col span={24}>
                {selectedPhones?.length === 0 && (
                  <Row style={{ width: '100%' }}>
                    <Table
                      bordered={true}
                      showHeader={false}
                      pagination={false}
                      style={{ width: '100%' }}
                      className="table-antd"
                      dataSource={[]}
                    />
                  </Row>
                )}

                {selectedPhones?.length > 0 &&
                  selectedPhones?.map((phone: RepresentativePhone) => {
                    return (
                      <Row
                        key={phone.key}
                        style={{
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          margin: '10px 0',
                        }}
                      >
                        <div
                          style={{
                            width: '17%',
                            display: 'flex',
                          }}
                        >
                          <span style={{ marginRight: 5 }}>Telefone padrão:</span>

                          <div className="checkbox">
                            <input
                              checked={phone?.isDefault}
                              onChange={() => handleSetIsDefault(phone)}
                              type="checkbox"
                              className="checkbox"
                              id={`checkbox-${phone.key}`}
                              name={`checkbox-${phone.key}`}
                            />
                            <label htmlFor={`checkbox-${phone.key}`} style={{ cursor: 'pointer' }}></label>
                          </div>
                        </div>

                        <Row
                          style={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: '1px solid #E5E5E5',
                            padding: '10px',
                            borderRadius: '8px',
                            width: '83%',
                          }}
                        >
                          <span style={{ width: '33%', textTransform: 'capitalize' }}>
                            {phone.typeName.toLowerCase()}
                          </span>
                          <span style={{ width: '34%' }}>{phone?.number}</span>
                          <p
                            style={{
                              color: 'red',
                              textDecoration: 'underline',
                              margin: 0,
                              padding: 0,
                              cursor: 'pointer',
                              maxWidth: 'fit-content',
                              width: '33%',
                            }}
                            onClick={() => removeEmail(phone.key)}
                          >
                            Excluir
                          </p>
                        </Row>
                      </Row>
                    );
                  })}
              </Col>
            </Row>

            <CustomButton type="primary" style={{ marginTop: 40, width: '100%' }} onClick={finishStep}>
              {isEditing ? 'Editar' : 'Cadastrar'}
            </CustomButton>

            <CustomButton type="default" style={{ marginTop: 20, width: '100%' }} onClick={returnStep}>
              Voltar
            </CustomButton>
          </div>

          <FullScreenModal
            destroyOnClose={true}
            visible={addRepresentativePhoneModalIsVisible}
            onOk={onCloseAddRepresentativePhoneModal}
            onCancel={onCloseAddRepresentativePhoneModal}
            footer={null}
            forceRender={false}
          >
            <Row align="middle" justify="center">
              <Col span={24} className="d-flex" style={{ width: '900px' }}>
                <div className="steps-action d-flex" style={{ width: '100%' }}>
                  <Container
                    style={{
                      background: '#fff',
                      padding: '60px',
                      borderRadius: '8px',
                      width: '100%',
                      height: 'auto',
                    }}
                  >
                    <AddRepresentativePhoneFormModal
                      onCloseModal={onCloseAddRepresentativePhoneModal}
                      addPhone={addPhone}
                    />
                  </Container>
                </div>
              </Col>
            </Row>
          </FullScreenModal>
        </>
      )}
    </>
  );
};

export default RepresentativeFormPhoneStep;
