import React, { createContext, useState, ReactNode, useContext, useCallback } from 'react';

import { Manufacturer } from '@models/Manufacturer';
import * as ManufacturerService from '@services/manufacturer';
import * as IntegrationPermissionService from '@services/integration-permission';
import { Phone } from '@models/Phone';
import { Address } from '@models/Address';
import { Email } from '@models/Email';
import { IntegrationAdditionalFields } from '@models/IntegrationAdditionalFields';
import { message } from 'antd';
import { EntityWithLabel } from '@models/EntityWithLabel';

interface ManufacturerState {
  manufacturers: Manufacturer[];
  phones: Phone[];
  addresses: Address[];
  emails: Email[];
  url: IntegrationAdditionalFields[];
  manufacturersByIntegration: Manufacturer[];
  errors: string[];
  loadRequestManufacturer: () => Promise<void>;
  loadRequestByIntegration: Function;
  clearErrors: Function;
  createBrand: Function;

  loadingImageUpload: boolean;
  updateImage: Function;

  permissions: string[];
  loadingPermissions: boolean;
  loadPermissions: (integrationId: number) => Promise<void>;

  entities: EntityWithLabel[];
  loadEntities: () => Promise<void>;
  loadingForceImport: boolean;
  forceImport: () => Promise<void>;
}

interface ManufacturerProviderProps {
  children: ReactNode;
}

export const ManufacturerContext = createContext<ManufacturerState | any>({});

const ManufacturerProvider: React.FC<ManufacturerProviderProps> = ({ children }) => {
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);

  const [phones, setPhones] = useState<Phone[]>([]);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [emails, setEmails] = useState<Email[]>([]);
  const [fields, setFields] = useState<IntegrationAdditionalFields[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPermissions, setLoadingPermissions] = useState<boolean>(false);
  const [loadingImageUpload, setLoadingImageUpload] = useState<boolean>(false);
  const [loadingForceImport, setLoadingForceImport] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const [permissions, setPermissions] = useState<string[]>([]);

  const [entities, setEntities] = useState<EntityWithLabel[]>([]);

  const loadEntities = useCallback(async () => {
    setLoading(true);
    try {
      const { data: returnedEntities } = await ManufacturerService.getEntitiesToForceImport();
      setEntities(returnedEntities);
    } catch (error) {
      console.error('error in context', error);
      message.error('Erro ao buscar entidades!');
    } finally {
      setLoading(false);
    }
  }, [setLoading]);

  const loadRequestManufacturerAddresses = useCallback(
    async (manufacturerId: number) => {
      setLoading(true);
      try {
        const { data: _addresses } = await ManufacturerService.getAddresses(manufacturerId);
        setAddresses(_addresses[0]);
      } catch (error) {
        setErrors(['Erro ao carregar endereços!']);
        console.error('error in context', error);
      } finally {
        setLoading(false);
      }
    },
    [setAddresses, setLoading, setErrors],
  );

  const loadPermissions = useCallback(
    async (integrationId: number) => {
      try {
        setLoadingPermissions(true);
        const { data: permissions } = await IntegrationPermissionService.getPermissionsByIntegrationId(integrationId);
        setPermissions(permissions.map(({ permissionId }) => permissionId));
      } catch (error) {
        setErrors(['Erro ao carregar permissões!']);
        console.error('error in context', error);
      } finally {
        setLoadingPermissions(false);
      }
    },
    [fields],
  );

  const loadRequestManufacturerPhones = useCallback(
    async (manufacturerId: number) => {
      setLoading(true);
      try {
        const { data: _phones } = await ManufacturerService.getPhones(manufacturerId);
        setPhones(_phones);
      } catch (error) {
        setErrors(['Erro ao carregar telefones!']);
        console.error('error in context', error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setPhones, setErrors],
  );

  const loadRequestManufacturerEmails = useCallback(
    async (manufacturerId: number) => {
      setLoading(true);
      try {
        const { data: _emails } = await ManufacturerService.getEmails(manufacturerId);
        setEmails(_emails);
      } catch (error) {
        setErrors(['Erro ao carregar emails!']);
        console.error('error in context', error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setEmails, setErrors],
  );

  const loadRequestManufacturer = useCallback(async () => {
    setLoading(true);
    try {
      let { data: _manufacturers } = await ManufacturerService.get();

      if (_manufacturers.length) {
        setManufacturers(_manufacturers);
        loadRequestManufacturerAddresses(_manufacturers[0].manufacturerId);
        loadRequestManufacturerPhones(_manufacturers[0].manufacturerId);
        loadRequestManufacturerEmails(_manufacturers[0].manufacturerId);
      }
    } catch (error) {
      setErrors(['Erro ao carregar fabricantes!']);
      console.error('error in context', error);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setManufacturers, setErrors, loadRequestManufacturerAddresses]);

  const loadRequestFields = async () => {
    setLoading(true);
    try {
      const { data: url } = await ManufacturerService.getPhotoUrl();
      setFields(url);
    } catch (error) {
      setErrors(['Erro ao carregar campos adicionais!']);
      console.error('error in context', error);
    } finally {
      setLoading(false);
    }
  };

  const updateImage = useCallback(
    async (file: any) => {
      setLoadingImageUpload(true);
      try {
        const { data: _image } = await ManufacturerService.uploadImage(file);
        await ManufacturerService.updateImage(_image.url);
        await loadRequestFields();
        message.success('Imagem alterada com sucesso!');
      } catch {
        message.error('Erro ao alterar imagem!');
      } finally {
        setLoadingImageUpload(false);
      }
    },
    [loadRequestFields, setLoadingImageUpload],
  );

  const forceImport = useCallback(
    async (entity: string) => {
      try {
        setLoadingForceImport(true);
        const { data: response } = await ManufacturerService.forceImport(entity);
        if (response) {
          message.success(response?.message);
          return;
        }
        message.warn(`Erro ao tentar importar!`);
      } catch (error: any) {
        message.error(error?.response?.data?.message ?? 'Erro ao importar!');
      } finally {
        setLoadingForceImport(false);
      }
    },
    [setLoadingForceImport],
  );

  return (
    <ManufacturerContext.Provider
      value={{
        manufacturers,
        phones,
        addresses,
        emails,
        fields,
        loadingImageUpload,
        loadRequestManufacturer,
        loadRequestManufacturerPhones,
        loadRequestManufacturerAddresses,
        loadRequestManufacturerEmails,
        loadRequestFields,
        updateImage,
        loading,
        errors,
        permissions,
        loadingPermissions,
        loadPermissions,
        entities,
        loadEntities,
        loadingForceImport,
        forceImport,
      }}
    >
      {children}
    </ManufacturerContext.Provider>
  );
};

const useManufacturer = () => {
  const context = useContext(ManufacturerContext);
  return context;
};

export { ManufacturerProvider, useManufacturer };
