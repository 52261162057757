import { ProductPerTab, SharedData } from '@components/ProductForm/models/product-form-models';
import { useGrid } from '@hooks/GridContext';
import { Grid, Size } from '@models/Grid';
import { CustomButton } from '@styles/globals';
import { Col, Form, Row, Select, Table, Tabs, Typography, notification } from 'antd';
import { SelectValue } from 'antd/lib/select';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CustomInput } from '../../styles';
const { Title } = Typography;
const { TabPane } = Tabs;

interface ProductFormGridAndColorStepProps {
  sharedData?: SharedData;
  changeSharedData?: Function;
  formValues: {
    colors: { key: string; name: string; code: string }[];
    sizes: { key: string; value: string; isActive: boolean }[];
    productsPerTab: ProductPerTab[];
  };
  setFormValues: Function;
  goToNextStep: Function;
  goToBackStep: Function;
  isEditing?: boolean;
  render: boolean;
}

const ProductFormGridAndColorStep: React.FC<ProductFormGridAndColorStepProps> = ({
  sharedData,
  changeSharedData,
  formValues,
  setFormValues,
  goToNextStep,
  goToBackStep,
  isEditing = false,
  render,
}) => {
  const { allGrids } = useGrid();
  const [gridAndColorForm] = Form.useForm();

  const [sizes, setSizes] = React.useState<{ key: string; value: string; isActive: boolean }[]>([]);

  const [selectedColorTab, setSelectedColorTab] = React.useState<{ key: string; name: string }>();
  const [colors, setColors] = React.useState<{ key: string; name: string; code: string }[]>([]);
  const [productsPerTab, setProductsPerTab] = React.useState<ProductPerTab[]>([]);

  const [changedColorName, setChangedColorName] = React.useState<string>('');

  const codeInput = React.useRef(null);

  React.useEffect(() => {
    gridAndColorForm.setFields([
      { name: 'color', value: '' },
      { name: 'code', value: '' },
    ]);

    if (formValues?.colors?.length > 0) {
      setProductsPerTab(formValues.productsPerTab);
      setSizes(formValues.sizes);
      setColors(formValues.colors);
      setSelectedColorTab(formValues.colors[0]);
    } else {
      const grid = allGrids.filter((gd: Grid) => gd.id === Number(sharedData?.gridCode))[0];
      setSizes(
        grid?.sizes?.map((size: Size) => {
          return {
            value: size?.size,
            key: uuidv4(),
            isActive: true,
          };
        }),
      );
    }
  }, []);

  React.useEffect(() => {
    if (formValues && isEditing) {
      setProductsPerTab(formValues.productsPerTab);
      setSizes(formValues.sizes);
      setColors(formValues.colors);
      setSelectedColorTab(formValues.colors[0]);
    }
  }, [formValues]);

  const finishStep = async () => {
    await gridAndColorForm.validateFields();

    const values = {
      colors: colors,
      sizes: sizes,
      productsPerTab: productsPerTab,
    };

    if (changeSharedData) {
      changeSharedData({ colors });
    }
    setFormValues(values);
    goToNextStep();
  };

  const returnStep = async () => {
    const values = {
      colors: colors,
      sizes: sizes,
      productsPerTab: productsPerTab,
    };

    if (changeSharedData) {
      changeSharedData({ colors });
    }
    setFormValues(values);
    goToBackStep();
  };

  const addColor = async () => {
    await gridAndColorForm.validateFields();
    const colorName = gridAndColorForm.getFieldValue('color');
    const colorCode = gridAndColorForm.getFieldValue('code') || '';
    let validColor = true;

    if (colors.some((color) => color?.name === colorName && color?.code === colorCode)) {
      notification.error({
        message: 'Erro',
        description: `Não podem existir duas ou mais cores com nomes e códigos iguais.`,
      });
      validColor = false;
    }

    if (colorName && validColor) {
      const newColor = { key: uuidv4(), name: colorName, code: colorCode };
      if (colors.length === 0) setSelectedColorTab(newColor);
      setColors((prev) => [...prev, newColor]);
      gridAndColorForm.setFieldsValue({
        color: '',
        code: '',
      });
      const product = {
        color: newColor,
        sizes: sizes?.map((size) => {
          return { key: size?.key, value: size?.value, isActive: true, barCodes: [] };
        }),
        selectedRowKeys: [...sizes?.map((size) => size?.key)],
      };
      setProductsPerTab((prev) => [...prev, product]);
    }
  };

  const removeColor = (value: string) => {
    setColors(colors.filter((color) => color.key !== value));
    setProductsPerTab(productsPerTab.filter((product) => product.color.key !== value));
  };

  const onChangeTab = (value: string) => {
    setSelectedColorTab(colors.filter((c) => c.key === value)[0]);
  };

  const onToggleSizeCheckbox = (newSelectedRowKeys: React.Key[]) => {
    const product: any = {
      color: selectedColorTab,
      sizes: sizes?.map((size) => {
        if (!newSelectedRowKeys.includes(size?.key)) {
          return {
            ...size,
            isActive: false,
          };
        } else {
          return {
            ...size,
            isActive: true,
          };
        }
      }),
      selectedRowKeys: newSelectedRowKeys,
    };

    if (productsPerTab.length === 0) {
      setProductsPerTab([product as ProductPerTab]);
    } else {
      const productsPerTabCopy = [...productsPerTab];
      const colorTabIndex = productsPerTabCopy.findIndex((p) => p.color.key === selectedColorTab?.key);
      productsPerTabCopy[colorTabIndex] = product as ProductPerTab;
      setProductsPerTab(productsPerTabCopy);
    }
  };

  const onBarCodeInputChange = (value: SelectValue, sizeKey: string) => {
    const productsPerTabCopy = [...productsPerTab];
    const colorTabIndex = productsPerTabCopy.findIndex((p) => p.color.key === selectedColorTab?.key);

    const rowSizes = [...productsPerTabCopy[colorTabIndex]?.sizes];

    if (rowSizes?.length === 0) {
      rowSizes?.push({
        ...sizes?.filter((s) => s.key === sizeKey)[0],
        barCodes: value?.toString()?.trim().split(',') || [],
      });
      productsPerTabCopy[colorTabIndex].sizes = rowSizes;
    } else {
      const sizeIndex = rowSizes?.findIndex((s) => s?.key === sizeKey);
      rowSizes[sizeIndex] = { ...rowSizes[sizeIndex], barCodes: value?.toString()?.trim().split(',') || [] };
      productsPerTabCopy[colorTabIndex].sizes = rowSizes;
    }

    if (!productsPerTabCopy[colorTabIndex]?.selectedRowKeys?.includes(sizeKey)) {
      productsPerTabCopy[colorTabIndex].selectedRowKeys = [
        ...productsPerTabCopy[colorTabIndex].selectedRowKeys,
        sizeKey,
      ];
    }

    setProductsPerTab(productsPerTabCopy);
  };

  const editColorName = (colorIndex: number, colorName: string) => {
    const colorUpdated = [...colors];
    const products = [...productsPerTab];

    colorUpdated[colorIndex] = { ...colorUpdated[colorIndex], name: colorName };

    products.forEach((product) => {
      if (product?.color?.key === colorUpdated[colorIndex]?.key) {
        product.color = colorUpdated[colorIndex];
      }
    });

    setColors(colorUpdated);
    setProductsPerTab(products);
    setChangedColorName('');
  };

  return (
    <>
      {render && (
        <Form
          form={gridAndColorForm}
          className="d-flex"
          style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}
        >
          <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col span={12} style={{ paddingRight: 15 }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Cor <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item
                name="color"
                rules={[
                  {
                    required: colors.length > 0 ? false : true,
                    message: 'Informe ao menos uma cor',
                  },
                ]}
              >
                <CustomInput
                  style={{ width: '100%' }}
                  placeholder="Cor"
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      addColor();
                    }
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={12} style={{ paddingLeft: 15 }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Código
              </Title>
              <Form.Item name="code">
                <CustomInput style={{ width: '100%' }} placeholder="Código" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Col span={8}>
              <CustomButton type="primary" style={{ marginBottom: 40 }} onClick={addColor}>
                Cadastrar
              </CustomButton>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'start' }}>
            <h2 style={{ fontWeight: 'bold', width: '100%', paddingBottom: '10px' }}>Cores</h2>
            <Tabs className="colors-tab" style={{ width: '100%' }} onChange={(value) => onChangeTab(value)}>
              {colors?.length > 0 &&
                colors.map((color, colorIndex) => {
                  return (
                    <TabPane tab={color.name} key={color.key} closable={true}>
                      <Table
                        size="middle"
                        showHeader={true}
                        pagination={false}
                        dataSource={sizes}
                        rowKey={(size) => size?.key}
                        rowSelection={{
                          type: 'checkbox',
                          selectedRowKeys: productsPerTab?.filter((product) => product?.color?.key === color?.key)[0]
                            ?.selectedRowKeys,
                          onChange: onToggleSizeCheckbox,
                        }}
                        columns={[
                          {
                            title: 'Ativo/inativo',
                            dataIndex: 'isActive',
                            width: 40,
                            render: (typeCode: number, size: { key: string; value: string }) => (
                              <h3 style={{ margin: 0 }}>{size?.value}</h3>
                            ),
                          },
                          {
                            title: 'Código de Barras',
                            dataIndex: 'code',
                            align: 'center',
                            render: (typeCode: number, size: { key: string; value: string }) => (
                              <>
                                <Row
                                  className="d-flex"
                                  style={{
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '10px 0',
                                  }}
                                >
                                  <Col span={17}>
                                    <Select
                                      ref={codeInput}
                                      mode="tags"
                                      style={{ width: '100%', textAlign: 'left' }}
                                      placeholder={'Escreva o código de barras'}
                                      onChange={(value) => {
                                        onBarCodeInputChange(value, size?.key);
                                      }}
                                      value={productsPerTab
                                        ?.filter((product) => product?.color?.key === color?.key)[0]
                                        ?.sizes?.filter((s) => s?.key === size?.key)[0]
                                        ?.barCodes?.map((code: string) => code)}
                                      options={productsPerTab
                                        ?.filter((product) => product?.color?.key === color?.key)[0]
                                        ?.sizes?.filter((s) => s?.key === size?.key)[0]
                                        ?.barCodes?.map((code: string) => {
                                          return {
                                            value: code,
                                            label: code,
                                          };
                                        })}
                                      open={false}
                                    />
                                  </Col>
                                  <Col span={6}>
                                    <CustomButton type="primary" style={{ margin: 0 }}>
                                      Adicionar código
                                    </CustomButton>
                                  </Col>
                                </Row>
                              </>
                            ),
                          },
                        ]}
                      />
                      {isEditing ? (
                        <Row style={{ width: '100%', textAlign: 'left', paddingTop: 20 }}>
                          <Col span={7} style={{ paddingRight: 10 }}>
                            <CustomInput
                              style={{ width: '100%' }}
                              placeholder="Digite a cor"
                              value={changedColorName}
                              onChange={(e) => setChangedColorName(e.target.value)}
                            />
                          </Col>
                          <Col span={5}>
                            <CustomButton
                              type="primary"
                              style={{ margin: 0 }}
                              onClick={() => editColorName(colorIndex, changedColorName)}
                            >
                              Alterar nome da cor
                            </CustomButton>
                          </Col>
                        </Row>
                      ) : (
                        <div style={{ width: '100%', textAlign: 'left' }}>
                          <p
                            style={{
                              color: 'red',
                              textDecoration: 'underline',
                              margin: 0,
                              padding: 0,
                              cursor: 'pointer',
                              marginTop: 20,
                              maxWidth: 'fit-content',
                            }}
                            onClick={() => removeColor(color.key)}
                          >
                            Excluir cor {color.name}
                          </p>
                        </div>
                      )}
                    </TabPane>
                  );
                })}
            </Tabs>

            {colors?.length === 0 && (
              <Table
                style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'start' }}
                showHeader={false}
                dataSource={[]}
                bordered={true}
              ></Table>
            )}
          </Row>

          <CustomButton type="primary" style={{ marginTop: 40, width: '100%' }} onClick={finishStep}>
            Continuar
          </CustomButton>

          <CustomButton type="default" style={{ marginTop: 20, width: '100%' }} onClick={returnStep}>
            Voltar
          </CustomButton>
        </Form>
      )}
    </>
  );
};

export default ProductFormGridAndColorStep;
