import React, { useCallback, useEffect, useState } from 'react';

import { FilterFilled, PlusCircleFilled } from '@ant-design/icons';
import FiltersForm from '@components/FiltersForm';
import HeaderButton from '@components/HeaderButton';
import ListFilters, { ListFilterType } from '@components/ListFilters';
import ProductForm from '@components/ProductForm';
import ProductsFiltersForm from '@components/ProductsFiltersForm';
import { useCategory } from '@hooks/CategoryContext';
import { useCollection } from '@hooks/CollectionContext';
import { useColor } from '@hooks/ColorContext';
import { useIntegration } from '@hooks/IntegrationContext';
import { useReference } from '@hooks/ReferenceContext';
import { FullScreenModal } from '@styles/globals';
import { Col, Drawer, Row, Typography } from 'antd';

const { Title } = Typography;

const filtersNameMap: any = {
  name: 'Descrição',
  reference: 'Referência',
  collectionId: 'Cód. Coleção',
  subCategoryId: 'Cód. SubCategoria',
  colorCode: 'Cód. Cor',
  size: 'Tamanho',
};

const ProductsHeader: React.FC = () => {
  const { hasMerpIntegration } = useIntegration();
  const [filtersDrawerIsVisible, setfiltersDrawerIsVisible] = useState<boolean>(false);
  const [modalNewProductVisible, setModalNewProductVisible] = useState(false);

  const { loadColorsGroupedByName, loading: loadingColors, colors } = useColor();
  const { loadReferences, loadingReferences, referenceIsActive, referencesFilters } = useReference();
  const { loadCollections, loading: loadingCollections, collections } = useCollection();
  const { loadRequestCategory, loading: loadingCategories, categories } = useCategory();

  const [filters, setFilters] = useState<ListFilterType[]>([]);

  useEffect(() => {
    loadColorsGroupedByName();
    loadCollections(0, { listAll: true });
    loadRequestCategory();
  }, []);

  useEffect(() => {
    const _filters: ListFilterType[] = [];
    if (referencesFilters && Object.keys(referencesFilters).length) {
      for (let key of Object.keys(referencesFilters)) {
        if (referencesFilters[key]) {
          _filters.push({
            value: `${filtersNameMap[key]}: ${referencesFilters[key]}`,
            key,
          });
        }
      }
    }
    setFilters(_filters);
  }, [referencesFilters]);

  const handleCloseFiltersDrawer = useCallback(() => {
    setfiltersDrawerIsVisible(false);
  }, [setfiltersDrawerIsVisible]);

  const handleShowFiltersDrawer = useCallback(() => {
    setfiltersDrawerIsVisible(true);
  }, [setfiltersDrawerIsVisible]);

  const onSubmitFiltersForm = useCallback(
    (values: any) => {
      loadReferences(0, referenceIsActive, values);
    },
    [referenceIsActive],
  );

  const onClearFiltersForm = useCallback(() => {
    loadReferences(0, referenceIsActive);
  }, []);

  const onRemoveFilter = ({ key }: ListFilterType) => {
    const newFilters = { ...referencesFilters, [key]: undefined };

    loadReferences(0, referenceIsActive, newFilters);
  };

  const showNewProductModal = () => {
    setModalNewProductVisible(true);
  };

  const onCloseNewProductModal = () => {
    setModalNewProductVisible(false);
  };

  return (
    <>
      <Row className="mt-8" justify="space-between">
        <Col>
          <Title level={2}>Produtos</Title>
        </Col>

        <Col>
          <Row>
            <Col>
              <ListFilters filters={filters} onRemove={onRemoveFilter} />
            </Col>

            <Col>
              <HeaderButton
                text="Filtros"
                onClick={handleShowFiltersDrawer}
                Icon={<FilterFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
              />
            </Col>

            {hasMerpIntegration && (
              <Col>
                <HeaderButton
                  text="Novo produto"
                  onClick={showNewProductModal}
                  Icon={<PlusCircleFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Drawer
          title="Filtros"
          placement="left"
          onClose={handleCloseFiltersDrawer}
          visible={filtersDrawerIsVisible}
          width={400}
        >
          <FiltersForm
            onCloseDrawer={handleCloseFiltersDrawer}
            onSubmit={onSubmitFiltersForm}
            onClearFilters={onClearFiltersForm}
            filtersForm={<ProductsFiltersForm colors={colors} collections={collections} categories={categories} />}
          />
        </Drawer>

        <FullScreenModal
          destroyOnClose={true}
          visible={modalNewProductVisible}
          onOk={onCloseNewProductModal}
          onCancel={onCloseNewProductModal}
          footer={null}
          forceRender={false}
        >
          <Row align="middle" justify="center">
            <Col span={24} className="d-flex" style={{ width: '1000px' }}>
              <ProductForm onCloseModal={onCloseNewProductModal} isEditing={false} />
            </Col>
          </Row>
        </FullScreenModal>
      </Row>
    </>
  );
};

export default ProductsHeader;
