import { Col, Row, Table } from 'antd';
import React, { useState } from 'react';

import { EditFilled, SearchOutlined } from '@ant-design/icons';
import SalesRegionForm from '@components/SalesRegionForm';
import TableFilters from '@components/TableFilters';
import { useBrand } from '@hooks/BrandContext';
import { useIntegration } from '@hooks/IntegrationContext';
import { useSalesRegion } from '@hooks/SalesRegionContext';
import { RepresentativeOperationalArea } from '@models/SalesRegionRepresentative';
import { FullScreenModal } from '@styles/globals';
import { getImageOrAvatar } from '@utils/get-image-or-avatar';
import '../../styles.css';
import RepresentativesTable from '../RepresentativesTable';

const SalesRegionContent: React.FC = () => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
  const { brands } = useBrand();

  const brandColumns = [
    {
      title: 'Cód.',
      dataIndex: 'brandId',
      width: '100px',
      align: 'center' as 'center',
      sorter: (marcaA: any, marcaB: any) => marcaA.brandId - marcaB.brandId,
    },
    {
      title: 'Marca',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pela Marca'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.brandName ? record.brandName.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
      render: (brand: any) => (
        <Row align="middle">
          {getImageOrAvatar(brand.brandPhotoUrl, brand.brandName)}
          <strong className="ml-3">{brand.brandName}</strong>
        </Row>
      ),
      sorter: (marcaA: any, marcaB: any) => marcaA.brandName.localeCompare(marcaB.brandName),
    },
  ];

  const onTableRowExpand = (expanded: any, record: any) => {
    const keys = [];

    if (expanded) {
      keys.push(record.brandId);
    }

    setExpandedRowKeys(keys);
  };

  return (
    <>
      <Table
        className="mt-4 custom-table"
        columns={brandColumns as any}
        dataSource={brands}
        rowKey={(brand) => brand.brandId}
        expandable={{
          expandedRowRender: (record) => <RepresentativesTable brandId={record.brandId} />,
          onExpand: onTableRowExpand,
        }}
        expandedRowKeys={expandedRowKeys}
        scroll={{ x: 800 }}
      />
    </>
  );
};

export default SalesRegionContent;
