import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import * as GoalService from '@services/goal';
import { Goal } from '@models/Goal';
import { message } from 'antd';
import { INITIAL_PAGE } from '@utils/constants';
import { GoalItem } from '@models/GoalItem';
import { calculateEquivalentPercentageNumber } from '@utils/currency-format';
import { normalizeString } from '@utils/normalize-string';

interface GoalState {
  goals: Goal[];
  loadingGoals: boolean;
  loadGoals: Function;
  goalsCurrentPage: number;
  goalsTotal: number;

  createGoal: Function;
  loadingCreateGoal: boolean;

  updateGoalStatus: Function;
  loadingUpdateGoalStatus: boolean;

  uploadGoalFileSheet: Function;
  loadingUploadGoalFileSheet: boolean;

  currentGoal: Goal;
  loadGoalById: Function;
  loadingGetGoalById: boolean;

  filteredGoalItems: GoalItem[];
  filtersObjectGoalItems: GoalFilters;
  filterGoalItems: Function;
  clearFilterGoalItems: Function;

  downloadGoalResultsFile: Function;
  loadingDownloadGoalResultsFile: boolean;

  downloadGoalModelFile: Function;
  loadingDownloadGoalModelFile: boolean;

  deleteGoalItems: Function;
  loadingDeleteGoalItems: boolean;
}

interface GoalProviderProps {
  children: ReactNode;
}

export const GoalContext = createContext<GoalState>({} as GoalState);

export interface GoalFilters {
  collection?: string;
  customer?: string;
  officialStore?: string;
  reference?: string;
  uf?: string;
  productClassificationTypeCode?: string;
  productClassificationCode?: string;
  orderClassificationTypeCode?: string;
  orderClassificationCode?: string;
  initialPercentInterval?: number;
  finalPercentInterval?: number;
  field?: string;
  order?: string;
  representativeCode?: string;
  sellerCode?: string;
}

const GoalProvider: React.FC<GoalProviderProps> = ({ children }) => {
  const [goals, setGoals] = useState<Goal[]>([]);
  const [goalsCurrentPage, setGoalsCurrentPage] = useState<number>(INITIAL_PAGE);
  const [goalsTotal, setGoalsTotal] = useState<number>(0);
  const [filtersObjectGoalItems, setFiltersObjectGoalItems] = useState<GoalFilters>({} as GoalFilters);
  const [filteredGoalItems, setFilteredGoalItems] = useState<GoalItem[]>([]);
  const [currentGoal, setCurrentGoal] = useState<Goal>({} as Goal);
  //loading
  const [loadingGoals, setLoadingGoals] = useState<boolean>(false);
  const [loadingCreateGoal, setLoadingCreateGoal] = useState<boolean>(false);
  const [loadingUpdateGoalStatus, setLoadingUpdateGoalStatus] = useState<boolean>(false);
  const [loadingUploadGoalFileSheet, setLoadingUploadGoalFileSheet] = useState<boolean>(false);
  const [loadingGetGoalById, setLoadingGetGoalById] = useState<boolean>(false);
  const [loadingDownloadGoalResultsFile, setLoadingDownloadGoalResultsFile] = useState<boolean>(false);
  const [loadingDeleteGoalItems, setLoadingDeleteGoalItems] = useState<boolean>(false);
  const [loadingDownloadGoalModelFile, setLoadingDownloadGoalModelFile] = useState<boolean>(false);

  const loadGoals = useCallback(
    async (page: number, filters?: any) => {
      setLoadingGoals(true);
      setGoalsCurrentPage(page === 0 ? 1 : page);

      try {
        const { data: _goals } = await GoalService.getGoals({
          page: page ? page - 1 : 0,
          pageSize: 10,
          ...(filters && { ...filters }),
        });
        setGoals(_goals.data);
        setGoalsTotal(_goals.total);
      } catch (e: any) {
        if (e.response && e.response.status === 404) {
          message.info('Você não possui metas cadastradas!');
        }
      } finally {
        setLoadingGoals(false);
      }
    },
    [setGoals, setLoadingGoals],
  );

  const loadGoalById = async (goalId: number) => {
    setLoadingGetGoalById(true);
    try {
      const goalIndex = goals.findIndex((goal) => goal.goalId == goalId);

      if (goalIndex != -1) {
        const { data: _goal } = await GoalService.getGoalById(goalId);
        goals[goalIndex] = _goal;
        setGoals([...goals]);
        setCurrentGoal(_goal);
        setFilteredGoalItems([]);
        setFiltersObjectGoalItems({} as GoalFilters);
      } else {
        message.info('Meta não encontrada!');
      }
    } catch (e: any) {
      message.warn('Não foi possível buscar os detalhes da meta!');
    } finally {
      setLoadingGetGoalById(false);
    }
  };

  const createGoal = async (description: string) => {
    setLoadingCreateGoal(true);
    try {
      const { data: newGoal } = await GoalService.createGoal({ description });
      setGoals((oldGoals) => [newGoal, ...oldGoals]);
      message.success('Meta cadastrada com sucesso');
      return newGoal;
    } catch (error: any) {
      message.error('Ocorreu um erro ao criar a meta');
    } finally {
      setLoadingCreateGoal(false);
    }
  };

  const updateGoalStatus = async (goalId: number, goalIsActive: boolean) => {
    setLoadingUpdateGoalStatus(true);
    try {
      const { data: goal } = await GoalService.updateGoalStatus(goalId, goalIsActive);

      if (goal) {
        const goalToUpdateIndex = goals.findIndex((_goal) => _goal.goalId == goalId);
        if (goalToUpdateIndex != -1) {
          goals[goalToUpdateIndex] = goal;
          setGoals(goals);
          message.success('Meta atualizada com sucesso');
        }
      }
    } catch (error) {
      message.error('Ocorreu um erro ao atualizar a meta');
    } finally {
      setLoadingUpdateGoalStatus(false);
    }
  };

  const uploadGoalFileSheet = async (file: File) => {
    setLoadingUploadGoalFileSheet(true);
    try {
      await GoalService.uploadGoalFileSheet(file);
      message.success('Arquivo enviado com sucesso');
    } catch (error: any) {
      message.error('Ocorreu um erro ao enviar o arquivo');
      if (error.response.data.message) message.error(error.response.data.message);
    } finally {
      setLoadingUploadGoalFileSheet(false);
    }
  };

  const filterGoalItems = (filters: GoalFilters) => {
    if (!currentGoal?.items) return;

    let filtered: GoalItem[] = currentGoal.items;

    if (filters?.uf) {
      filtered = filtered.filter((item) => item.ufRule.toUpperCase().includes(filters.uf?.toUpperCase() ?? ''));
    }

    if (filters?.reference) {
      filtered = filtered.filter((item) => !!item.references.find((ref) => ref.includes(filters.reference ?? '')));
    }

    if (filters?.collection) {
      filtered = filtered.filter(
        (item) =>
          !!item.collections.find((collection) =>
            collection.description.toUpperCase().includes(filters.collection?.toUpperCase() ?? ''),
          ),
      );
    }

    if (filters?.customer) {
      filtered = filtered.filter(
        (item) =>
          !!item.legalPersons.find((legalPerson) =>
            legalPerson.fantasyName.toUpperCase().includes(filters.customer?.toUpperCase() ?? ''),
          ),
      );
    }

    if (filters?.officialStore) {
      filtered = filtered.filter(
        (item) =>
          !!item.officialStores.find((officialStore) =>
            officialStore.description.toUpperCase().includes(filters.officialStore?.toUpperCase() ?? ''),
          ),
      );
    }

    if (filters?.orderClassificationTypeCode) {
      filtered = filtered.filter(
        (item) =>
          !!item.orderClassifications.find(
            (classification) => classification.typeCode == parseInt(filters.orderClassificationTypeCode as string),
          ),
      );
    }

    if (filters?.productClassificationTypeCode) {
      filtered = filtered.filter(
        (item) =>
          !!item.productClassifications.find(
            (classification) => classification.typeCode == parseInt(filters.productClassificationTypeCode as string),
          ),
      );
    }

    if (filters?.orderClassificationCode) {
      filtered = filtered.filter(
        (item) =>
          !!item.orderClassifications.find((classification) => classification.code == filters.orderClassificationCode),
      );
    }

    if (filters?.productClassificationCode) {
      filtered = filtered.filter(
        (item) =>
          !!item.productClassifications.find(
            (classification) => classification.code == filters.productClassificationCode,
          ),
      );
    }

    if (filters?.initialPercentInterval) {
      filtered = filtered.filter(
        (item) =>
          calculateEquivalentPercentageNumber(item?.goalValue, item?.reachedValue) >=
          Number(filters?.initialPercentInterval),
      );
    }

    if (filters?.finalPercentInterval) {
      filtered = filtered.filter((item) => {
        return (
          calculateEquivalentPercentageNumber(item?.goalValue, item?.reachedValue) <=
          Number(filters?.finalPercentInterval)
        );
      });
    }

    if (filters?.representativeCode) {
      const representativeCode = normalizeString(filters.representativeCode ?? '');
      filtered = filtered.filter(
        (item) =>
          normalizeString(item.representativeCode.toString()).includes(representativeCode) ||
          normalizeString(item.representative.name).includes(representativeCode),
      );
    }

    if (filters?.sellerCode) {
      const sellerCode = normalizeString(filters.sellerCode ?? '');
      filtered = filtered.filter(
        (item) =>
          normalizeString(item.sellerCode.toString()).includes(sellerCode) ||
          normalizeString(item.seller.sellerName).includes(sellerCode) ||
          normalizeString(item.seller.personName).includes(sellerCode),
      );
    }

    setFilteredGoalItems(filtered);
    setFiltersObjectGoalItems(filters);
  };

  const clearFilterGoalItems = () => {
    setFilteredGoalItems([]);
  };

  const downloadGoalResultsFile = async (goalId: number) => {
    try {
      setLoadingDownloadGoalResultsFile(true);
      await GoalService.getGoalResultsFileSheet(goalId);
      message.success('Resultados baixados com sucesso');
    } catch (e: any) {
      if (e.response && e.response.status === 404) {
        message.error('Detalhes da meta ainda não foram importados');
      } else {
        message.error('Erro ao baixar resultados');
      }
    } finally {
      setLoadingDownloadGoalResultsFile(false);
    }
  };

  const downloadGoalModelFile = async () => {
    try {
      setLoadingDownloadGoalModelFile(true);
      await GoalService.getGoalModelFileSheet();
      message.success('Modelo baixado com sucesso');
    } catch {
      message.error('Erro ao baixar modelo');
    } finally {
      setLoadingDownloadGoalModelFile(false);
    }
  };

  const deleteGoalItems = async (goalId: number) => {
    setLoadingDeleteGoalItems(true);
    try {
      const goalIndex = goals.findIndex((goal) => goal.goalId == goalId);

      if (goalIndex != -1) {
        const { data: _goal } = await GoalService.deleteGoalItems(goalId);
        goals[goalIndex] = { ...goals[goalIndex], items: [] };
        setGoals([...goals]);
        setCurrentGoal((oldGoal) => ({ ...oldGoal, items: [] }));
        message.success('Meta deletada com sucesso');
      } else {
        message.info('Meta não encontrada');
      }
    } catch (e: any) {
      message.warn('Não foi possível deletar a meta');
    } finally {
      setLoadingDeleteGoalItems(false);
    }
  };

  return (
    <GoalContext.Provider
      value={{
        goals,
        loadingGoals,
        goalsCurrentPage,
        goalsTotal,
        loadGoals,
        createGoal,
        loadingCreateGoal,
        updateGoalStatus,
        loadingUpdateGoalStatus,
        uploadGoalFileSheet,
        loadingUploadGoalFileSheet,
        currentGoal,
        loadGoalById,
        loadingGetGoalById,
        filterGoalItems,
        filtersObjectGoalItems,
        filteredGoalItems,
        clearFilterGoalItems,
        loadingDownloadGoalResultsFile,
        downloadGoalResultsFile,
        loadingDeleteGoalItems,
        deleteGoalItems,
        downloadGoalModelFile,
        loadingDownloadGoalModelFile,
      }}
    >
      {children}
    </GoalContext.Provider>
  );
};

const useGoal = () => {
  return useContext(GoalContext);
};

export { GoalProvider, useGoal };
