import { DollarOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useOrder } from '@hooks/OrdersContext';
import { ShoppingBag } from '@models/ShopppingBag';
import { Button, Modal } from 'antd';

interface IShoppingBagAction {
  shoppingBag: ShoppingBag;
}

const ShoppingBagAction: React.FC<IShoppingBagAction> = ({ shoppingBag }: IShoppingBagAction) => {
  const { generateFaileOrderByShoppingBag } = useOrder();

  const handleCLick = () => {
    Modal.confirm({
      title: 'Deseja realmente retornar esse pedido?',
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: 'Sim',
      cancelText: 'Não',

      onOk: () => {
        shoppingBag?.bagJson && generateFaileOrderByShoppingBag(JSON.parse(shoppingBag.bagJson));
      },
    });
  };

  return (
    <>
      <Button
        type="text"
        style={{ color: 'var(--primary-color)', display: 'flex', alignItems: 'baseline' }}
        onClick={handleCLick}
      >
        Retornar pedido <DollarOutlined />
      </Button>
    </>
  );
};

export default ShoppingBagAction;
