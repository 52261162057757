import { Button } from 'antd';
import styled from 'styled-components';

export const WrapperNewOfficialStoreHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;

  > * {
    font-size: 16px;
    margin: 0 8px 0 0 !important;
  }
`;

export const CustomButton = styled(Button)`
  width: min(80px, 100%);
  height: 36px;
  border-radius: 8px;

  > span {
    color: var(--support_color);
    font-size: 12px;
    font-weight: 500;
  }
`;
