import React from 'react';

import DevolutionRequestUploadForm from '@components/DevolutionRequestUploadForm';

const DevolutionUploadPage: React.FC = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: '#f5f5f5',
          display: 'flex',
          justifyContent: 'center',
          overflow: 'auto',
          height: '100vh',
        }}
      >
        <div style={{ width: '1000px', height: 'fit-content', paddingTop: '10px', paddingBottom: '110px' }}>
          <DevolutionRequestUploadForm />
        </div>
      </div>
    </>
  );
};

export default DevolutionUploadPage;
