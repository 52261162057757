import React from 'react';
import { useHistory } from 'react-router';

import { LeftOutlined } from '@ant-design/icons';

import { Col, Row, Typography } from 'antd';

const { Title } = Typography;

interface SellerProfileHeaderProps {}

const SellerProfileHeader: React.FC<SellerProfileHeaderProps> = () => {
  const history = useHistory();

  const backUrl = () => {
    history.go(-1);
  };

  return (
    <>
      <Row className="mt-8" justify="space-between">
        <Col className="d-flex" style={{ alignItems: 'center' }}>
          <LeftOutlined onClick={() => backUrl()} style={{ fontSize: '20px', marginRight: '12px' }} />
          <Row style={{ flexDirection: 'column', marginLeft: '12px' }}>
            <Col>
              <Title level={3} style={{ margin: '0' }}>
                Perfil do Vendedor
              </Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SellerProfileHeader;
