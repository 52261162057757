import React, { useEffect } from 'react';

import { useCategory } from '@hooks/CategoryContext';
import CategoryHeader from './components/CategoryHeader';
import CategoryContent from './components/CategoryContent';
import Loading from '@components/Loading'
import './style.css';

const CategoryPage: React.FC = () => {
  const { loadRequestCategory, loading } = useCategory();

  useEffect(() => {
    loadRequestCategory();
  }, []);

  if (loading) return <Loading />;

  return (
    <>
      <CategoryHeader />
      <CategoryContent />
    </>
  );
};

export default CategoryPage;
