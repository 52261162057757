import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Typography, Select, Form, Input, message, DatePicker, InputNumber, Modal } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import {
  FIELD_NAME_BILLING_FORECAST_DATE,
  FIELD_NAME_PRIORITY_CODE,
  FIELD_NAME_CUSTOMER_ORDER_CODE,
} from '@models/OrderDefinedFieldValues';
import { CustomButton } from '@styles/globals';

import { useOfficialStoreQuestions } from '@hooks/OfficialStoreQuestionsContext';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
import * as S from './styles';
import Loading from '@components/Loading';

const { Title, Text } = Typography;

interface CardInfoProps {
  title: string;
  text: string;
}

const CardInfo: React.FC<CardInfoProps> = ({ title, text }) => (
  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} style={{ paddingLeft: '8px', paddingTop: '8px' }}>
    <Title level={5} style={{ height: '20px' }}>
      {title}
    </Title>
    <Text>{text}</Text>
  </Col>
);

interface CardProps {
  typeCode: number;
  code: string;
  option: string;
  definedField: string;
  definedFieldValue: string;
  onDelete: Function;
}

const Card: React.FC<CardProps> = ({ typeCode, code, onDelete, option, definedField, definedFieldValue }) => (
  <S.CustomCard>
    <Row className="w-100 card-row">
      <Col span={24} style={{ paddingLeft: '8px', paddingTop: '8px' }}>
        {option && (
          <Title level={5} style={{ height: '20px' }}>
            Opção: <span style={{ fontWeight: 400 }}>{option}</span>
          </Title>
        )}
      </Col>
      {typeCode && <CardInfo title={'Tipo de Classificação'} text={typeCode.toString()} />}
      {code && <CardInfo title={'Classificação'} text={code} />}
      {definedField && <CardInfo title={'Campo Pré-Definido'} text={definedField} />}
      {definedFieldValue && (
        <CardInfo
          title={'Valor Campo Pré-Definido'}
          text={
            definedField === FIELD_NAME_BILLING_FORECAST_DATE
              ? moment(definedFieldValue).format('DD/MM/YYYY')
              : definedFieldValue
          }
        />
      )}
      <Col span={24} className="card-col" onClick={() => onDelete(typeCode, code)}>
        <CloseOutlined style={{ fontSize: '16px', color: 'var(--primary-color)' }} />
        <Text style={{ fontSize: '14px', color: 'var(--primary-color)' }}>Excluir Opção</Text>
      </Col>
    </Row>
  </S.CustomCard>
);

interface QuestionClassificationStepProps {
  previousStepsValues: any;
  editingQuestion?: any;
}

const QuestionClassificationStep: React.FC<QuestionClassificationStepProps> = ({
  previousStepsValues,
  editingQuestion,
}) => {
  const { createOfficialStoreQuestion, loadingCreateOfficialStoreQuestion, updateOfficialStoreQuestion, loadOrderDefinedFields } =
    useOfficialStoreQuestions();
  const { actualOfficialStore } = useOfficialStore();
  const { orderDefinedFields } = useOfficialStoreQuestions();
  const [form] = Form.useForm();

  const [optionsClassifications, setOptionsClassifications] = useState<any[]>([]);
  const [definedFieldName, setDefinedFieldName] = useState<string | null>(null);

  useEffect(() => {
    if (editingQuestion) {
      setOptionsClassifications(editingQuestion.options);
    }

    if (!orderDefinedFields || !orderDefinedFields.length) loadOrderDefinedFields();
  }, []);

  const handleAdd = (values: any) => {
    if (
      optionsClassifications.find(
        (option) => Number(option.typeCode) === Number(values.typeCode) && option.code === values.code,
      )
    )
      return message.warning('Opção já existe!');

    if (optionsClassifications.filter((option) => option.definedField !== values.definedField).length > 0)
      return message.warning('Opção não corresponde com as anteriores!');

    let definedFieldType;
    const field = orderDefinedFields.find((_field) => _field.fieldName === values.definedField);
    if (field && Object.keys(field).length > 0) definedFieldType = field.fieldType;

    setOptionsClassifications([
      ...optionsClassifications,
      {
        option: values.option,
        typeCode: values.typeCode,
        code: values.code,
        definedField: values.definedField,
        definedFieldValue: values.definedFieldValue,
        observation: values.observation,
        definedFieldType,
      },
    ]);

    form.setFieldsValue({
      option: undefined,
      typeCode: undefined,
      code: undefined,
      observation: undefined,
      definedField: undefined,
      definedFieldValue: undefined,
    });

    setDefinedFieldName(null);
  };

  const handleRemove = (typeCode: number, code: string) => {
    Modal.confirm({
      title: 'Deseja realmente excluir?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Excluir',
      okType: 'danger',
      onOk() {
        const index = optionsClassifications.findIndex(
          (option) => Number(option.typeCode) == typeCode && option.code == code,
        );
        if (index !== -1) {
          optionsClassifications.splice(index, 1);
          setOptionsClassifications([...optionsClassifications]);
        }
      },
    });
  };

  const handleSubmit = useCallback(async () => {
    /*if (
      !previousStepsValues ||
      (!['TEXTO', 'TEXTO REPRESENTANTE'].includes(previousStepsValues.questiontype) && !optionsClassifications) ||
      (!['TEXTO', 'TEXTO REPRESENTANTE'].includes(previousStepsValues.questiontype) && !optionsClassifications.length)
    )
      return message.warning('Preencha os campos necessários para prosseguir!');
*/
    const options = optionsClassifications?.map((_option) => ({
      ..._option,
      definedFieldValue:
        _option.definedField === FIELD_NAME_BILLING_FORECAST_DATE
          ? moment(_option.definedFieldValue).toISOString()
          : _option.definedFieldValue,
    }));

    if (editingQuestion)
      return updateOfficialStoreQuestion(actualOfficialStore.officialStoreId, editingQuestion.questionId, {
        ...previousStepsValues,
        options,
      });

    createOfficialStoreQuestion(actualOfficialStore.officialStoreId, {
      ...previousStepsValues,
      options,
    });
  }, [optionsClassifications, previousStepsValues]);

  const handleDefinedFieldChange = useCallback(
    (value: string) => {
      form.setFieldsValue({
        definedFieldValue: undefined,
      });
      setDefinedFieldName(value);
    },
    [optionsClassifications],
  );

  if (loadingCreateOfficialStoreQuestion) return <Loading />;

  return (
    <>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="gutter-row d-flex">
        <S.CustomCardContainer floating="right">
          <S.ScrollContainer>
            {optionsClassifications.map(({ typeCode, code, option, definedField, definedFieldValue }: any) => (
              <Card
                typeCode={typeCode}
                code={code}
                onDelete={handleRemove}
                key={`${typeCode}-${code}`}
                option={option}
                definedField={definedField}
                definedFieldValue={definedFieldValue?.toString()}
              />
            ))}
          </S.ScrollContainer>
        </S.CustomCardContainer>
      </Col>

      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="gutter-row d-flex">
        <S.CustomCardContainer floating="left">
          <S.ScrollContainer className="pb-8">
            <Form layout="vertical" onFinish={(values: any) => handleAdd(values)} form={form}>
              <Title level={5} className="mb-4">
                Adicionar Opções
              </Title>

              <Form.Item className="mb-2" label="Opção" name="option">
                <Input placeholder="Opção" />
              </Form.Item>

              <Form.Item
                className="mb-2"
                label="Tipo de Classificação"
                name="typeCode"
                // rules={[{ required: true, message: 'Informe o Tipo de Classificação.' }]}
              >
                <Input type="number" placeholder="Tipo de Classificação" min={0} />
              </Form.Item>

              <Form.Item
                className="mb-2"
                label="Classificação"
                name="code"
                // rules={[{ required: true, message: 'Informe a Classificação.' }]}
              >
                <Input placeholder="Classificação" />
              </Form.Item>

              <Form.Item className="mb-2" label="Observação" name="observation">
                <Input.TextArea rows={4} placeholder="Digite uma observação..." />
              </Form.Item>

              <Form.Item
                className="mb-0 mb-1"
                label="Campo Pré-Definido"
                name="definedField"
                // rules={[{ required: true, message: 'Informe o Nome do Campo' }]}
              >
                <Select placeholder="Nome do Campo" onChange={(value: string) => handleDefinedFieldChange(value)}>
                  {orderDefinedFields.length &&
                    orderDefinedFields.map((definedField) => (
                      <Select.Option key={definedField.fieldName} value={definedField.fieldName}>
                        {definedField.fieldName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              {definedFieldName === FIELD_NAME_BILLING_FORECAST_DATE && (
                <Form.Item
                  className="mb-2 mt-0"
                  name="definedFieldValue"
                  // rules={[{ required: true, message: 'Informe o Valor do Campo' }]}
                >
                  <DatePicker className="w-100" format="DD/MM/YYYY" />
                </Form.Item>
              )}
              {definedFieldName === FIELD_NAME_CUSTOMER_ORDER_CODE && (
                <Form.Item
                  className="mb-2 mt-0"
                  name="definedFieldValue"
                  rules={[{ required: true, message: 'Informe o Valor do Campo' }]}
                >
                  <Input
                    placeholder="Valor do Campo"
                    maxLength={
                      orderDefinedFields.find((field) => field.fieldName === FIELD_NAME_CUSTOMER_ORDER_CODE)
                        ?.fieldMaxLength ?? undefined
                    }
                  />
                </Form.Item>
              )}
              {definedFieldName === FIELD_NAME_PRIORITY_CODE && (
                <Form.Item
                  className="mb-2 mt-0"
                  name="definedFieldValue"
                  // rules={[{ required: true, message: 'Informe o Valor do Campo' }]}
                >
                  <InputNumber
                    className="w-100"
                    placeholder="Valor do Campo"
                    min={0}
                    maxLength={
                      orderDefinedFields.find((field) => field.fieldName === FIELD_NAME_PRIORITY_CODE)
                        ?.fieldMaxLength ?? undefined
                    }
                  />
                </Form.Item>
              )}

              <CustomButton className="mt-8 w-100" type="primary" htmlType="submit">
                ADICIONAR
              </CustomButton>

              <CustomButton className="mt-1 w-100" outlined="true" onClick={handleSubmit}>
                SALVAR
              </CustomButton>
            </Form>
          </S.ScrollContainer>
        </S.CustomCardContainer>
      </Col>
    </>
  );
};

export default QuestionClassificationStep;
