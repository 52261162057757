import { Col, ColProps, Table, Typography } from 'antd';
import React from 'react';

const { Title } = Typography;

interface TablePaneProps extends ColProps {
  title?: string;
  data: any[];
  columns: any[];
  rowSelection?: any;
}
const TablePane: React.FC<TablePaneProps> = ({ title, data, columns, rowSelection, ...rest }) => {
  return (
    <Col className="mt-4" {...rest}>
      {title && (
        <Title level={4} style={{ color: 'gray' }}>
          {title}
        </Title>
      )}

      <Table
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 4 }}
        scroll={{ x: 800 }}
        rowSelection={rowSelection}
      />
    </Col>
  );
};

export default TablePane;
