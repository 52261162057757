import React from 'react';
import { Form, Typography, Checkbox } from 'antd';
import { CustomButton } from '@styles/globals';

const { Title } = Typography;
interface ConnectionOrdersFiltersProps {}

const ConnectionOrdersFilters: React.FC<ConnectionOrdersFiltersProps> = () => {
  return (
    <Form>
      <Title level={5}>Pedido</Title>

      <Form.Item className="mt-4 mb-0">
        <Checkbox>Cor</Checkbox>
      </Form.Item>

      <Form.Item className="mb-0">
        <Checkbox>Tamanho</Checkbox>
      </Form.Item>

      <Form.Item className="mb-0">
        <Checkbox>Estoque</Checkbox>
      </Form.Item>

      <Form.Item className="mb-0">
        <Checkbox>Preço</Checkbox>
      </Form.Item>

      <Form.Item className="mb-0">
        <Checkbox>Classificação do Pedido</Checkbox>
      </Form.Item>

      <Form.Item className="mb-0">
        <Checkbox>Observações do Pedido</Checkbox>
      </Form.Item>

      <Form.Item className="mb-0">
        <Checkbox>Dados Fiscais</Checkbox>
      </Form.Item>

      <CustomButton className="mt-4" type="primary" htmlType="submit">
        Salvar
      </CustomButton>
    </Form>
  );
};

export default ConnectionOrdersFilters;
