import { notification } from 'antd';
import React, { createContext, ReactNode, useContext, useState } from 'react';

import { ImageProps } from '@models/ImageProps';
import * as ImageService from '@services/images';

interface ImagesState {
  loading: boolean;
  fileList: ImageProps[];
  updateFileList: ({
    file,
    url,
    reference,
    color,
    sequence,
  }: {
    file: File;
    url: string;
    reference: string;
    color: string;
    sequence: string;
  }) => void;
  removeImage: (img: ImageProps) => void;
  uploadImages: () => Promise<void>;
  uploadImagesWithInput: Function;
  objectToFileList: Function;
  uploadImage: Function;
  uploadFile: Function;
  clearImages: () => void;
  fileToBase64: Function;
}

interface ImagesProviderProps {
  children: ReactNode;
}

export const ImagesContext = createContext<ImagesState>({} as ImagesState);
const ImagesProvider: React.FC<ImagesProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<ImageProps[]>([]);

  const updateFileList = ({
    file,
    url,
    reference,
    color,
    sequence,
  }: {
    file: File;
    url: string;
    reference: string;
    color: string;
    sequence: string;
  }) => {
    setFileList((current) => [...current, { name: file.name, url, reference, color, sequence, file }]);
  };

  const removeImage = (img: ImageProps) => {
    const index = fileList.findIndex(
      (file) => file.reference === img.reference && file.color === img.color && file.sequence === img.sequence,
    );
    if (index !== -1) {
      fileList.splice(index, 1);
      setFileList([...fileList]);
    }
  };

  const uploadImages = async () => {
    setLoading(true);

    for (const [i, file] of fileList.entries()) {
      let fileWithError: boolean = false;
      try {
        const { data } = await ImageService.uploadImage(file.file);

        await ImageService.saveImageColorReferences({
          image: data.large,
          image_medium: data.medium,
          image_small: data.small,
          ReferenceCode: file.reference,
          code: file.color,
          sequence: file.sequence,
        });
      } catch (e) {
        fileWithError = true;
      }
      fileList[i] = { ...fileList[i], uploaded: true, error: fileWithError };
      setFileList([...fileList]);
    }

    setLoading(false);

    if (fileList.filter((file) => file.error).length) {
      notification.warning({
        message: 'Aviso!',
        description: 'Algumas imagens não foram enviadas corretamente.',
      });
    } else {
      notification.success({
        message: 'Sucesso!',
        description: 'As imagens foram salvas com sucesso.',
      });

      await new Promise((resolve) => setTimeout(() => resolve(0), 1000));
      setFileList([]);
    }
  };

  const uploadImagesWithInput = async (inputFiles: any) => {
    setLoading(true);
    let files: any = [];

    for (const file of inputFiles) {
      try {
        const { data } = await ImageService.uploadImage(file);
        files.push(data);
      } catch (e) {
        console.log(e);
        notification.error({
          message: 'Erro!',
          description: 'Não foi possível fazer upload desta imagem.',
        });
      }
    }

    setLoading(false);
    return files;
  };

  const uploadImage = async (file: any) => {
    setLoading(true);

    try {
      const { data } = await ImageService.uploadImage(file);
      setLoading(false);

      return data;
    } catch (e) {
      notification.error({
        message: 'Erro!',
        description: 'Não foi possível fazer upload desta imagem.',
      });
      return e;
    } finally {
      setLoading(false);
    }
  };

  const uploadFile = async (file: any) => {
    setLoading(true);

    try {
      const { data } = await ImageService.uploadFile(file);
      setLoading(false);

      return data;
    } catch (e) {
      notification.error({
        message: 'Erro!',
        description: 'Não foi possível fazer upload deste arquivo.',
      });
      return e;
    } finally {
      setLoading(false);
    }
  };

  const objectToFileList = (object: any) => {
    const files: any = [];

    Object.keys(object).forEach((key) => {
      const fileData = object[key];
      const file = new File([], fileData.name, { lastModified: fileData.lastModified, type: fileData.type });
      files.push(file);
    });
    return files;
  };

  function fileToBase64(file: any) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result as string;
        resolve(result.split(',')[1]);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  const clearImages = () => {
    setFileList([]);
  };

  return (
    <ImagesContext.Provider
      value={{
        loading,
        fileList,
        updateFileList,
        removeImage,
        uploadImages,
        uploadImage,
        uploadFile,
        uploadImagesWithInput,
        objectToFileList,
        clearImages,
        fileToBase64,
      }}
    >
      {children}
    </ImagesContext.Provider>
  );
};

const useImages = () => {
  return useContext(ImagesContext);
};

export { ImagesProvider, useImages };
