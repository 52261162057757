import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react';

import { Integration } from '@models/Integration';
import { responseIsSuccess } from '@services/api';
import * as IntegrationService from '@services/integration';

const MERP_SYSTEM = 2;

interface IntegrationState {
  errors: string[];
  loading: boolean;
  clearErrors: Function;
  setCurrentIntegrationById: Function;
  clearCurrentIntegration: Function;
  currentIntegration: Integration | null;
  hasMerpIntegration: boolean;
}

interface IntegrationProviderProps {
  children: ReactNode;
}

const IntegrationContext = createContext<IntegrationState>(Object.assign({}));

const IntegrationProvider: React.FC<IntegrationProviderProps> = ({ children }) => {
  const [currentIntegration, setCurrentIntegration] = useState<Integration | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMerpIntegration, setHasMerpIntegration] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const clearErrors = () => {
    setErrors([]);
  };

  const clearCurrentIntegration = () => {
    setCurrentIntegration(null);
  };

  const setCurrentIntegrationById = useCallback(
    async (integrationId: number): Promise<boolean> => {
      try {
        setLoading(true);
        const { status: status, data: integration } = await IntegrationService.get(integrationId);

        if (!responseIsSuccess(status)) {
          setErrors([integration.message]);
          return false;
        }
        setCurrentIntegration(integration);

        if (integration?.integrationSystem === MERP_SYSTEM) {
          setHasMerpIntegration(true);
        }

        return true;
      } catch (error) {
        console.error(error);
        setErrors([`Erro ao buscar integração ${integrationId}`]);
        return false;
      } finally {
        setLoading(false);
      }
    },
    [setLoading],
  );

  return (
    <IntegrationContext.Provider
      value={{
        loading,
        errors,
        currentIntegration,
        clearCurrentIntegration,
        clearErrors,
        setCurrentIntegrationById,
        hasMerpIntegration,
      }}
    >
      {children}
    </IntegrationContext.Provider>
  );
};

const useIntegration = () => {
  return useContext(IntegrationContext);
};

export { IntegrationProvider, useIntegration };
