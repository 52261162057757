import React from 'react';

import { Product } from '@models/Product';

import '../styles.css';

interface TabDataProductProps {
  product: Product;
}

const TabDataProduct: React.FC<TabDataProductProps> = ({ product }) => {
  return (
    <div style={{ display: 'flex', padding: '8px' }}>
      <div className="data-title">
        <p>Produção Própria</p>
        <p>Produto Acabado</p>
        <p>Matéria Prima</p>
        <p>Matéria Consulto</p>
      </div>
      <div className="data-value">
        <p>{product.isOwnProduction ? 'Sim' : 'Não'}</p>
        <p>{product.isFinishedProduct ? 'Sim' : 'Não'}</p>
        <p>{product.isBulkMaterial ? 'Sim' : 'Não'}</p>
        <p>{product.isRawMaterial ? 'Sim' : 'Não'}</p>
      </div>
    </div>
  );
};

export default TabDataProduct;
