import { CustomRangePicker } from '@components/CollectionForm/styles';
import { SharedData } from '@components/ProductForm/models/product-form-models';
import { PriceTable } from '@models/PriceTable';
import { CustomTextArea } from '@pages/CollectionDetailsPage/components/CustomerNotification/styles';
import { CustomButton } from '@styles/globals';
import { Col, Form, Row, Select, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { CustomInput } from '../../styles';
const { Title, Text } = Typography;

interface PriceTableHeaderStepProps {
  sharedData?: SharedData;
  changeSharedData?: Function;
  formValues: PriceTable | undefined;
  setFormValues: Function;
  goToNextStep: Function;
  isEditing: boolean;
  render: boolean;
}

const PriceTableHeaderStep: React.FC<PriceTableHeaderStepProps> = ({
  sharedData,
  changeSharedData,
  formValues,
  setFormValues,
  goToNextStep,
  isEditing = false,
  render = true,
}) => {
  const [priceTableHeaderForm] = Form.useForm();

  const [validityDate, setValidityDate] = React.useState<{ startDate: string; endDate: string } | null>();

  React.useEffect(() => {
    priceTableHeaderForm.setFields([
      { name: 'code', value: null },
      { name: 'integrationId', value: null },
      { name: 'description', value: null },
      { name: 'type', value: null },
      { name: 'codeName', value: null },
      { name: 'maxChangeFilterDate', value: null },
      { name: 'validityDate', value: null },
      { name: 'startDate', value: null },
      { name: 'endDate', value: null },
      { name: 'variationPercentage', value: null },
      { name: 'variationValue', value: null },
      { name: 'observation', value: null },
    ]);

    if (formValues) {
      priceTableHeaderForm.setFieldsValue({
        code: formValues.code,
        integrationId: formValues.integrationId,
        description: formValues.description,
        type: formValues.type,
        codeName: formValues.codeName,
        maxChangeFilterDate: formValues.maxChangeFilterDate,
        validityDate: [moment(formValues.startDate), moment(formValues.endDate)],
        startDate: formValues.startDate,
        endDate: formValues.endDate,
        variationPercentage: formValues.variationPercentage,
        variationValue: formValues.variationValue,
        observation: formValues.observation,
      });

      setValidityDate({
        startDate: moment(formValues.startDate).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(formValues.endDate).format('YYYY-MM-DD HH:mm:ss'),
      });
    }
  }, []);

  const finishStep = async () => {
    const values = await priceTableHeaderForm.validateFields();

    values.startDate = validityDate?.startDate;
    values.endDate = validityDate?.endDate;

    setFormValues(values);
    goToNextStep();
  };

  const onChangeValidityDate = (value: any) => {
    if (value) {
      setValidityDate({
        startDate: moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
      });
    } else {
      setValidityDate(null);
    }
  };

  return (
    <>
      {render && (
        <Form
          form={priceTableHeaderForm}
          className="d-flex"
          style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}
        >
          <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Col span={24}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Código da tabela de preço <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Informe o código da tabela de preço',
                  },
                ]}
              >
                <CustomInput
                  disabled={isEditing}
                  type="number"
                  style={{ width: '100%' }}
                  placeholder="Código da tabela de preço"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Col span={24}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Descrição da tabela de preço <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Informe o descrição da tabela de preço',
                  },
                ]}
              >
                <CustomTextArea
                  style={{
                    width: '100%',
                    resize: 'none',
                    height: '100px',
                    padding: '10px',
                  }}
                  placeholder="Descrição da tabela de preço"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col span={12} style={{ paddingRight: '15px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Tipo <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Selecione o tipo',
                  },
                ]}
                style={{ borderRadius: '8px' }}
              >
                <Select
                  style={{ height: '48px', borderRadius: '8px' }}
                  placeholder="Tipo"
                  size="large"
                  showSearch
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  <Select.Option key={'Group'} value={'Group'}>
                    Grupo
                  </Select.Option>
                  <Select.Option key={'AveragePeriod'} value={'AveragePeriod'}>
                    Prazo médio
                  </Select.Option>
                  <Select.Option key={'GroupAveragePeriod'} value={'GroupAveragePeriod'}>
                    Grupo e prazo médio
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12} style={{ paddingLeft: '15px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Data de vigência <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item
                name="validityDate"
                rules={[
                  {
                    required: true,
                    message: 'Selecione a data de vigência',
                  },
                ]}
              >
                <CustomRangePicker
                  format="DD/MM/YYYY"
                  placeholder={['Data inicial', 'Data final']}
                  style={{ width: '100%' }}
                  onChange={(value) => onChangeValidityDate(value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col span={12} style={{ paddingRight: '15px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Percentual de variação
              </Title>
              <Form.Item name="variationPercentage">
                <CustomInput type="number" min="0" style={{ width: '100%' }} placeholder="Percentual de variação" />
              </Form.Item>
            </Col>

            <Col span={12} style={{ paddingLeft: '15px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Valor de variação
              </Title>
              <Form.Item name="variationValue">
                <CustomInput type="number" min="0" style={{ width: '100%' }} placeholder="Valor de variação" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Col span={24}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Observação
              </Title>
              <Form.Item name="observation">
                <CustomTextArea
                  style={{
                    width: '100%',
                    resize: 'none',
                    height: '100px',
                    padding: '10px',
                  }}
                  placeholder="Descrição da referência"
                />
              </Form.Item>
            </Col>
          </Row>

          <CustomButton type="primary" style={{ marginTop: 40, width: '100%' }} onClick={finishStep}>
            Continuar
          </CustomButton>
        </Form>
      )}
    </>
  );
};

export default PriceTableHeaderStep;
