import { PictureOutlined } from '@ant-design/icons';
import ModalPlayerVideo from '@components/ModalPlayerVideo';
import { Button, Empty, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { ReferenceVideoUnique } from '@services/referenceVideo';
import { useVideos } from '@hooks/VideosContext';

interface TabVideoProps {
  referenceCode: string;
}

const TabVideo: React.FC<TabVideoProps> = ({ referenceCode }: ReferenceVideoUnique) => {
  const { loadReferenceVideo, deleteReferenceVideo, loading, referenceVideo } = useVideos();
  const [videoModal, setVideoModal] = useState<string>('');

  useEffect(() => {
    loadReferenceVideo({ referenceCode });
  }, [referenceCode]);

  const handleDelete = (referenceCode: string) => {
    deleteReferenceVideo({ referenceCode });
  };

  const formatDateAndHour = (dateToFormat: string | number | Date) =>
    new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(new Date(dateToFormat));

  return (
    <>
      <ModalPlayerVideo
        src={videoModal}
        visible={!!videoModal}
        onCancel={() => {
          setVideoModal('');
        }}
      />
      <div
        style={{
          width: '180px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: '16px',
        }}
      >
        {loading ? (
          <>
            <Skeleton.Button size="large" active={true} />
            <br />
            <Skeleton.Image />
          </>
        ) : (
          <>
            {referenceVideo?.video ? (
              <>
                <Button size="large" loading={loading} onClick={() => setVideoModal(referenceVideo.video)}>
                  Ver vídeo
                  <PictureOutlined />
                </Button>
                <Title className="mt-2" style={{ fontSize: '12px' }}>
                  Data de upload do arquivo
                </Title>
                <Text style={{ fontSize: '12px', textAlign: 'center' }}>
                  {formatDateAndHour(referenceVideo.referenceVideoUpdatedAt)}
                </Text>
                <br />
                <Text
                  style={{
                    fontSize: '12px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: 'var(--primary-color)',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleDelete(referenceCode)}
                >
                  Excluir
                </Text>
              </>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sem vídeo disponível" />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TabVideo;
