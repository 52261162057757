import React, { useCallback } from 'react';
import { Col, Row, Pagination } from 'antd';
import Card from '../Card';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useCustomer } from '@hooks/CustomerContext';
import LoadingComponents from '@components/LoadingComponents';
import { CUSTOMER_PAGINATION_SIZE } from '@utils/constants';

interface CustomersListProps {
  status?: boolean;
}

const CustomersList: React.FC<CustomersListProps> = ({ status }) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { customers, loadingCustomers, customersTotal, loadCustomersPaginated, customersPage, customerFilters } =
    useCustomer();

  const handlePaginationChange = (page: number) => {
    loadCustomersPaginated(page - 1, status ? 'APPROVED' : 'PENDING', customerFilters);
  };

  if (loadingCustomers) return <LoadingComponents />;

  return (
    <>
      <Row gutter={[16, 16]} style={{ padding: '12px' }}>
        {customers.map(({ code, name, cnpj }: any) => (
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={6}
            xxl={4}
            onClick={() => history.push(`${url}/perfil/${code}`)}
            key={code}
            className="gutter-row d-flex"
          >
            <Card title={name} code={code} cpfCnpj={cnpj} />
          </Col>
        ))}
      </Row>
      <Row className="mt-4 mb-8 mr-4" justify="end">
        <Pagination
          defaultCurrent={1}
          total={customersTotal}
          current={customersPage + 1}
          pageSize={CUSTOMER_PAGINATION_SIZE}
          showSizeChanger={false}
          onChange={handlePaginationChange}
        />
      </Row>
    </>
  );
};

export default CustomersList;
