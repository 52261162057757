import { Input, Form, Button } from 'antd';
import styled from 'styled-components';

export const CustomForm = styled(Form)`
  width: 280px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
`;

export const CustomInput = styled(Input)`
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgb(81 12 118 / 20%);
  text-transform: uppercase;
  
  &:focus,
  &:hover {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgb(81 12 118 / 20%);
  }
`;

export const CustomButton = styled(Button)`
  width: 100%;
  background-color: var(--primary-color);
  border-radius: 8px;
  margin-top: 8px;

  > * {
    color: white;
    font-size: 12px !important;
    font-weight: 500;
  }
`;
