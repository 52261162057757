import { Table } from 'antd';
import React from 'react';

import { useDevolution } from '@hooks/DevolutionsContext';
import { devolutionColumns } from '@pages/DevolutionPage/components/DevolutionColumns/DevolutionColumns';

const DevolutionsTab: React.FC = () => {
  const { devolutions, loadDevolutions, loadingDevolutions, filters, page, total } = useDevolution();

  React.useEffect(() => {
    loadDevolutions(0);
  }, []);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.hasOwnProperty('column')) {
      filters.field = `${sorter.field}`;
      filters.order = `${sorter.order}`;
    }

    loadDevolutions(pagination.current, filters);
  };

  return (
    <Table
      columns={devolutionColumns}
      dataSource={devolutions}
      loading={loadingDevolutions}
      pagination={{
        pageSize: 10,
        total: total,
        current: page,
        showSizeChanger: false,
        showQuickJumper: false,
      }}
      scroll={{ x: 800 }}
      rowKey={(devolution) => devolution?.invoiceCode}
      onChange={handleTableChange}
    />
  );
};

export default DevolutionsTab;
