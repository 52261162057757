import { useOrder } from '@hooks/OrdersContext';
import { useShoppingBag } from '@hooks/ShoppingBagContext';
import { ShoppingBag } from '@models/ShopppingBag';
import { formatCnpj } from '@utils/cnpj-mask';
import { formatCurrency } from '@utils/currency-format';
import { formatDate } from '@utils/date-format';
import { Table, TableColumnProps } from 'antd';

import ShoppingBagAction from '../ShoppingBagAction';

const ShoppingBagContent: React.FC = () => {
  const { shoppingBags, loadingShoppingBags } = useShoppingBag();
  const { loadingGenerateFailedOrderByShoppingBag } = useOrder();

  const columns: TableColumnProps<ShoppingBag>[] = [
    {
      title: 'Código',
      dataIndex: 'representativeCode',
      sorter: (a, b) => a.representativeCode - b.representativeCode,
    },
    {
      title: 'Nome do representante',
      dataIndex: ['representative', 'name'],
      sorter: (a, b) => a.representative?.name.localeCompare(b.representative.name),
    },
    {
      title: 'CNPJ do cliente',
      dataIndex: 'customerCnpj',
      render: (cnpj: string) => {
        return formatCnpj(cnpj);
      },
    },
    {
      title: 'Nome do cliente',
      dataIndex: 'customerName',
      sorter: (a, b) => a.customerName.localeCompare(b.customerName),
    },
    {
      title: 'Valor total',
      dataIndex: 'totalAmountOrder',
      render: (total: number) => {
        return formatCurrency(total);
      },
      sorter: (a, b) => a.totalAmountOrder - b.totalAmountOrder,
    },
    {
      title: 'Data',
      dataIndex: 'orderDate',
      render: (date: string) => {
        return formatDate(date);
      },
      sorter: (a, b) => new Date(a.orderDate).getTime() - new Date(b.orderDate).getTime(),
    },
    {
      title: 'Ações',
      render: (shoppingBag: ShoppingBag) => <ShoppingBagAction shoppingBag={shoppingBag} />,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={shoppingBags}
        rowKey={(row) => row.shoppingBagId}
        loading={loadingShoppingBags || loadingGenerateFailedOrderByShoppingBag}
      ></Table>
    </>
  );
};

export default ShoppingBagContent;
