import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { LeftOutlined, MenuOutlined, PlusCircleFilled } from '@ant-design/icons';

import { Col, Drawer, Image, Row, Typography, Form, message } from 'antd';

import Navigation from '../../../../components/OfficialStoreNavigation';
import { OfficialStore } from '@models/OfficialStore';
import * as S from '../../styles';
import { FullScreenModal } from '@styles/globals';
import OfficialStoreMenuForm from '../OfficialStoreMenuForm';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
const { Text, Title } = Typography;

interface OfficialStoreMenuHeaderProps {
  officialStore: OfficialStore;
}

const OfficialStoreMenuHeader: React.FC<OfficialStoreMenuHeaderProps> = ({ officialStore }) => {
  const history = useHistory();
  const { createOfficialStoreMenu } = useOfficialStore();

  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [modalNewMenuIsVisible, setModalNewMenuIsVisible] = useState(false);

  const backUrl = () => {
    history.go(-1);
  };

  const onCloseDrawer = () => {
    setDrawerIsVisible(false);
  };

  const showDrawer = () => {
    setDrawerIsVisible(true);
  };

  const onCloseModalNewMenu = () => {
    setModalNewMenuIsVisible(false);
  };

  const showModalNewMenu = () => {
    setModalNewMenuIsVisible(true);
  };

  const onCreateNewMenu = async (value: any) => {
    if (officialStore && value) {
      const created = await createOfficialStoreMenu(officialStore.officialStoreId, value.description);

      if (created) message.success('Menu cadastrado com sucesso!');
      else message.error('Erro ao cadastrar menu!');
    }
  };

  return (
    <>
      <Row className="mt-3" justify="space-between">
        <Col className="d-flex" style={{ alignItems: 'center' }}>
          <LeftOutlined onClick={() => backUrl()} style={{ fontSize: '20px', marginRight: '12px' }} />

          <Image
            src={officialStore.logoUrl}
            alt="Logo da empresa"
            preview={false}
            style={{ width: '100%', maxWidth: '120px', borderRadius: '8px' }}
          />

          <Row style={{ flexDirection: 'column', marginLeft: '12px' }}>
            <Col>
              <Title level={5} style={{ margin: '0' }}>
                {officialStore.description}
              </Title>
            </Col>
            <Col>
              <Text>#{officialStore.officialStoreId}</Text>
            </Col>
          </Row>
        </Col>

        <Col className="d-flex" style={{ alignItems: 'center' }}>
          <Form.Item style={{ margin: '0 16px 0 0' }}>
            <S.ButtonContainer onClick={showModalNewMenu}>
              <PlusCircleFilled twoToneColor="#510c76" style={{ fontSize: '20px' }} />
              <span style={{ marginLeft: '4px' }}>Novo Menu</span>
            </S.ButtonContainer>
          </Form.Item>

          <MenuOutlined onClick={showDrawer} style={{ fontSize: '22px', color: '#510c76', cursor: 'pointer' }} />
          <Drawer
            placement="right"
            onClose={onCloseDrawer}
            visible={drawerIsVisible}
            closable={false}
            bodyStyle={{ padding: '0' }}
          >
            <Navigation officialStore={officialStore} />
          </Drawer>
        </Col>
      </Row>

      <FullScreenModal
        destroyOnClose={true}
        visible={modalNewMenuIsVisible}
        onOk={onCloseModalNewMenu}
        onCancel={onCloseModalNewMenu}
        footer={null}
        forceRender={false}
      >
        <OfficialStoreMenuForm onSubmit={onCreateNewMenu} />
      </FullScreenModal>
    </>
  );
};

export default OfficialStoreMenuHeader;
