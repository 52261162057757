import React, { useCallback, useEffect } from 'react';
import { Tabs } from 'antd';
import CustomersList from '../CustomersList';
import { useCustomer } from '@hooks/CustomerContext';

const CustomersContent: React.FC = () => {
  const { loadCustomersPaginated } = useCustomer();

  const handleTabChange = useCallback((key) => {
    loadCustomersPaginated(0, key);
  }, []);

  useEffect(() => {
    loadCustomersPaginated(0, 'APPROVED');
  }, []);

  return (
    <Tabs onChange={handleTabChange}>
      <Tabs.TabPane tab="Aprovados" key="APPROVED">
        <CustomersList status />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Pendentes" key="PENDING">
        <CustomersList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default CustomersContent;
