import React from 'react';
import { Table, Typography } from 'antd';
import { useSeller } from '@hooks/SellerContext';

const { Title } = Typography;
interface CustomersProps {}

const Customers: React.FC<CustomersProps> = () => {
  const { currentSeller } = useSeller();

  const columns = [
    {
      title: 'Código',
      width: '100px',
      align: 'center' as 'center',
      dataIndex: 'personCode',
      sorter: (clienteA: any, clienteB: any) => clienteA.code - clienteB.code,
    },
    {
      title: 'Lojista',
      dataIndex: 'name',
      sorter: (clienteA: any, clienteB: any) => clienteA.code - clienteB.code,
    },
  ];

  return (
    <div className="mt-6 ml-4">
      <Title level={5}>Carteira de Clientes</Title>
      <Table
        scroll={{ x: 800 }}
        columns={columns}
        dataSource={currentSeller.customers}
        rowKey={(record) => record.personCode}
      />
    </div>
  );
};

export default Customers;
