import { Row } from 'antd';
import styled from 'styled-components';

export const CardContainer = styled(Row)`
  overflow-y: scroll;
  height: 60vh;
  align-items: center;
  justify-content: end;
  padding: 12px;
  
  &::-webkit-scrollbar {
    width: 8px;
    background: #f5f5f5;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-appearance: none;
    background: var(--primary-color);
    border-radius: 8px;
  }
`;
