import React from 'react';
import { Typography, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { useRepresentative } from '@hooks/RepresentativeContext';

const { Title } = Typography;
interface PermissionFiltersProps {}

const PermissionFilters: React.FC<PermissionFiltersProps> = () => {
  const { actualRepresentative, updatePermission } = useRepresentative();

  const onChangeCheckbox = async (e: CheckboxChangeEvent) => {
    const permission = {
      property: e.target.name,
      value: e.target.checked,
    };

    await updatePermission(actualRepresentative.code, permission);
  };

  return (
    <>
      <Title level={5}>Permissões</Title>

      <div>
        <Checkbox
          className="mt-4 mb-0 ml-2"
          name="canMakeToOrder"
          onChange={onChangeCheckbox}
          checked={actualRepresentative.canMakeToOrder}
        >
          Pedido Sob-encomenda
        </Checkbox>

        <Checkbox
          className="mb-0"
          name="canDeliveryImmediately"
          onChange={onChangeCheckbox}
          checked={actualRepresentative.canDeliveryImmediately}
        >
          Pedido Pronta-entrega
        </Checkbox>

        <Checkbox
          className="mb-0"
          name="canCheckFinance"
          onChange={onChangeCheckbox}
          checked={actualRepresentative.canCheckFinance}
        >
          Ver financeiro dos lojistas
        </Checkbox>

        <Checkbox
          className="mb-0"
          name="canManageOrderSynchronization"
          onChange={onChangeCheckbox}
          checked={actualRepresentative.canManageOrderSynchronization}
        >
          Permitir gerência da sincronização de pedidos
        </Checkbox>
      </div>
    </>
  );
};

export default PermissionFilters;
