import React from 'react';
import { Empty, Table, Typography } from 'antd';

import { Classification } from '@models/Classification';

interface TabClassificationProps {
  classifications: Classification[];
}

const { Text } = Typography;

const TabClassification: React.FC<TabClassificationProps> = ({ classifications }) => {
  const columns = [
    {
      title: 'Tipo de Classificação',
      dataIndex: 'typeCode',
      render: (typeCode: number) => <Text>Tipo de Classificação: {typeCode}</Text>,
    },
    {
      title: 'Classificação',
      dataIndex: 'code',
      render: (code: string) => <Text>Classificação: {code}</Text>,
    },
    {
      dataIndex: 'typeName',
      render: (typeName: string) => <Text>{typeName}</Text>,
    },
    {
      dataIndex: 'name',
      render: (name: string) => <Text>{name}</Text>,
    },
  ];

  if (!classifications || !classifications.length)
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sem classificações disponíveis" />;

  return (
    <div className="classification">
      <Table
       scroll={{ x: 800 }}
        columns={columns}
        dataSource={classifications}
        pagination={{ pageSize: 5, showSizeChanger: false, showQuickJumper: false, hideOnSinglePage: true }}
        showHeader={false}
      />
    </div>
  );
};

export default TabClassification;
