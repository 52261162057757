import React from 'react';
import { Typography } from 'antd';

import { getImageOrAvatar } from '@utils/get-image-or-avatar';

import * as S from './styles';
const { Text, Title } = Typography;

interface CardProps {
  photoUrl: string;
  name: string;
  code: string;
  onClick: Function;
}
const Card: React.FC<CardProps> = ({ photoUrl, name, code, onClick }) => {
  return (
    <S.Container className="d-flex" onClick={() => onClick()}>
      {getImageOrAvatar(photoUrl, name)}
      <div className="ml-2">
        <Title level={5} className="mb-0">
          {name}
        </Title>
        <span>#{code}</span>
      </div>
    </S.Container>
  );
};

export default Card;
