import React, { createContext, useState, useContext, useCallback } from 'react';

import * as ClassificationService from '@services/classification';
import { Classification } from '@models/Classification';

interface ClassificationState {
  classifications: Classification[];
  loadClassifications: Function;
}

interface ClassificationProviderProps {}

export const ClassificationContext = createContext<ClassificationState | any>({});

const ClassificationProvider: React.FC<ClassificationProviderProps> = ({ children }) => {
  const [classifications, setClassifications] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);


  const loadClassifications = useCallback(async () => {
    setLoading(true);

    const { data: _classifications } = await ClassificationService.get();

    setLoading(false);
    setClassifications(_classifications);
  }, [classifications]);

  return (
    <ClassificationContext.Provider
      value={{
        classifications,
        loading,
        loadClassifications,
      }}
    >
      {children}
    </ClassificationContext.Provider>
  );
};

const useClassification = () => {
  return useContext(ClassificationContext);
};

export { ClassificationProvider, useClassification };
