import React from 'react';
import { Form, Typography, Checkbox } from 'antd';
import { CustomButton } from '@styles/globals';

const { Title } = Typography;
interface ConnectionPaymentsFiltersProps {}

const ConnectionPaymentsFilters: React.FC<ConnectionPaymentsFiltersProps> = () => {
  return (
    <Form>
      <Title level={5}>Pagamento</Title>

      <Form.Item className="mt-4 mb-0">
        <Checkbox>Preço</Checkbox>
      </Form.Item>

      <Form.Item className="mb-0">
        <Checkbox>Condição de Pagamento</Checkbox>
      </Form.Item>

      <Form.Item className="mb-0">
        <Checkbox>Forma de Pagamento</Checkbox>
      </Form.Item>

      <Form.Item className="mb-0">
        <Checkbox>Tabela de Preço</Checkbox>
      </Form.Item>

      <Form.Item className="mb-0">
        <Checkbox>Tipos de Desconto</Checkbox>
      </Form.Item>

      <CustomButton className="mt-4" type="primary" htmlType="submit">
        Salvar
      </CustomButton>
    </Form>
  );
};

export default ConnectionPaymentsFilters;
