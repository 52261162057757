import React from 'react';
import { Typography, Table, Spin } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { Brand } from '@models/Brand';
import { useCollaborator } from '@hooks/CollaboratorContext';
import '../../styles.css';

const { Text } = Typography;

interface BrandTableProps {
  brands: Brand[];
  collaboratorId: number;
}

const BrandTable: React.FC<BrandTableProps> = ({ collaboratorId, brands }) => {
  const { loadingDetails, removeBrandFromCollaborator } = useCollaborator();

  const columns = [
    {
      title: 'ID',
      dataIndex: 'brandId',
      width: '100px',
      render: (id: any): JSX.Element => {
        return <Text>#{id}</Text>;
      },
    },
    { title: 'Marca', dataIndex: 'brandName' },
    {
      align: 'right' as 'right',
      render: (record: any): JSX.Element => {
        return (
          <CloseOutlined
            style={{ cursor: 'pointer' }}
            onClick={() => removeBrandFromCollaborator(collaboratorId, record.brandId)}
          />
        );
      },
    },
  ];

  if (loadingDetails)
    return (
      <div className="d-flex mt-8 mb-4" style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Spin />
      </div>
    );

  return (
    <Table
      className="mt-4 mb-8 mr-4 ml-8"
      scroll={{ x: 800 }}
      columns={columns}
      dataSource={brands}
      pagination={{ pageSize: 4, hideOnSinglePage: true }}
    />
  );
};

export default BrandTable;
