import * as api from './api-merp';

export function get(integrationId: number, params?: any) {
  return api.get(`grades/${integrationId}`, params);
}

export async function saveGrid(body: any): Promise<any> {
  return api.post('grades', body);
}

export function updateSpecificProperty(gradeId: number, body: { property: string; value: string }): Promise<any> {
  return api.patch(`grades/${gradeId}`, body).catch((error) => {
    return Promise.resolve(error.response);
  });
}
