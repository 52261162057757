import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';

import Loading from '@components/Loading';
import { useRepresentative } from '@hooks/RepresentativeContext';
import RepresentativeProfileHeader from './components/RepresentativeProfileHeader';
import RepresentativeProfileContent from './components/RepresentativeProfileContent';

interface MatchParams {
  id: string;
}

const RepresentativesProfilePage: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();

  const { loading, loadRepresentativeById, loadCustomersByRepresentativeId } = useRepresentative();

  useEffect(() => {
    loadRepresentativeById(parseInt(params.id));
    loadCustomersByRepresentativeId(parseInt(params.id));
  }, []);

  if (loading) return <Loading />;

  return (
    <>
      <RepresentativeProfileHeader />

      <RepresentativeProfileContent />
    </>
  );
};

export default RepresentativesProfilePage;
