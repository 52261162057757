import { notification } from 'antd';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import * as ImageService from '@services/images';
import * as ReferenceVideoService from '@services/referenceVideo';
import * as ReferenceService from '@services/reference';
import { VideoProps } from '@models/VideoProps';
import { ReferenceVideo } from '@models/ReferenceVideo';

interface VideosState {
  loading: boolean;
  fileList: VideoProps[];
  referenceVideo: ReferenceVideo;
  loadReferenceVideo: ({
    referenceCode,
    integrationId,
  }: {
    referenceCode: string;
    integrationId?: number;
  }) => Promise<void>;
  deleteReferenceVideo: ({
    referenceCode,
    integrationId,
  }: {
    referenceCode: string;
    integrationId?: number;
  }) => Promise<void>;
  updateFileList: ({
    file,
    url,
    thumbnailUrl,
    reference,
  }: {
    file: File;
    url: string;
    thumbnailUrl?: string;
    reference: string;
  }) => void;
  removeVideo: (img: VideoProps) => void;
  uploadVideos: () => Promise<void>;
  clearVideos: () => void;
}

interface VideosProviderProps {
  children: ReactNode;
}

export const VideosContext = createContext<VideosState>({} as VideosState);
const VideosProvider: React.FC<VideosProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<VideoProps[]>([]);
  const [referenceVideo, setReferenceVideo] = useState<ReferenceVideo>(Object.assign({}));

  const deleteReferenceVideo = async ({
    referenceCode,
    integrationId,
  }: {
    referenceCode: string;
    integrationId?: number;
  }): Promise<void> => {
    try {
      setLoading(true);
      await ReferenceVideoService.deleteReferenceVideoByReferenceCode({ referenceCode, integrationId });
      notification.success({ message: 'Sucesso', description: `Vídeo excluído com sucesso` });
      setReferenceVideo(Object.assign({}));
    } catch (error) {
      console.warn(error);
      notification.error({ message: 'Erro', description: `Erro ao excluir vídeo` });
    } finally {
      setLoading(false);
    }
  };

  const loadReferenceVideo = async ({
    referenceCode,
    integrationId,
  }: {
    referenceCode: string;
    integrationId?: number;
  }) => {
    try {
      setLoading(true);
      const { data: video } = await ReferenceVideoService.getByReference({ referenceCode, integrationId });
      setReferenceVideo(video);
    } catch (error) {
      console.warn(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateFileList = ({
    file,
    url,
    thumbnailUrl,
    reference,
  }: {
    file: File;
    url: string;
    thumbnailUrl?: string;
    reference: string;
  }) => {
    if (thumbnailUrl) {
      setFileList((current) => [...current, { name: file.name, url, thumbnailUrl, reference, file }]);
      return;
    }
    setFileList((current) => [...current, { name: file.name, url, thumbnailUrl: '', reference, file }]);
  };

  const removeVideo = ({ reference }: VideoProps) => {
    const index = fileList.findIndex((file) => file.reference === reference);
    if (index !== -1) {
      fileList.splice(index, 1);
      setFileList([...fileList]);
    }
  };

  const chunkArray = (arr: VideoProps[], chunkSize: number) => {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  };

  const uploadVideos = async () => {
    setLoading(true);
    try {
      // Numero máximo de uploads que ele fará simultanetamente
      const maxConcurrenteUploadVideos = 5;
      const batchs = chunkArray(fileList, maxConcurrenteUploadVideos);
      for (const fileListChunk of batchs) {
        const chunkError = Array(fileListChunk.length).fill(false);
        await Promise.all(
          fileListChunk.map(async ({ file, reference }) => {
            const i = fileList.findIndex((x) => x.reference === reference);
            try {
              const referenceAlreadyExists = await ReferenceService.getByReferenceCode(reference);
              if (referenceAlreadyExists) {
                const {
                  data: { url },
                } = await ImageService.uploadVideo(file);

                const referenceVideo = { video: url, referenceCode: referenceAlreadyExists.data.ReferenceCode };
                await ReferenceVideoService.createOrUpdateReferenceVideo(referenceVideo);
              }
            } catch (e) {
              chunkError[i] = true;
            }

            fileList[i] = { ...fileList[i], uploaded: true, error: chunkError[i] };
            setFileList([...fileList]);
          }),
        );
      }

      if (fileList.some((file) => file.error)) {
        notification.warning({
          message: 'Aviso!',
          description: 'Alguns vídeos não foram enviados corretamente.',
        });
      } else {
        notification.success({
          message: 'Sucesso!',
          description: 'Os vídeos foram salvos com sucesso.',
        });

        await new Promise((resolve) => setTimeout(() => resolve(0), 1000));
        setFileList([]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const clearVideos = () => {
    setFileList([]);
  };

  return (
    <VideosContext.Provider
      value={{
        loading,
        fileList,
        referenceVideo,
        loadReferenceVideo,
        deleteReferenceVideo,
        updateFileList,
        removeVideo,
        uploadVideos,
        clearVideos,
      }}
    >
      {children}
    </VideosContext.Provider>
  );
};

const useVideos = () => {
  return useContext(VideosContext);
};

export { VideosProvider, useVideos };
