import { PlusCircleFilled } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';

import PriceTableForm from '@components/PriceTableForm';
import { useIntegration } from '@hooks/IntegrationContext';
import { usePriceTable } from '@hooks/PriceTableContext';
import { FullScreenModal } from '@styles/globals';
import { useState } from 'react';
import FormAddPriceTable from '../FormAddPriceTable';
import * as S from './styles';

const { Title } = Typography;

const PriceTablesHeader: React.FC = () => {
  const { hasMerpIntegration } = useIntegration();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [newPriceTableModalIsVisible, setNewPriceTableModalIsVisible] = useState(false);
  const { loadAllPriceTables } = usePriceTable();

  const onCloseModal = () => setModalIsVisible(false);
  const onShowModal = () => setModalIsVisible(true);

  const onCloseNewPriceTableModal = () => setNewPriceTableModalIsVisible(false);
  const onShowNewPriceTableModal = () => {
    loadAllPriceTables();
    setNewPriceTableModalIsVisible(true);
  };

  return (
    <>
      <Row className="mt-8" justify="space-between">
        <Col>
          <Title level={2}>Tabelas de Preço</Title>
        </Col>
        <Row>
          {!hasMerpIntegration && (
            <S.ActionButtonContainer onClick={onShowModal}>
              <PlusCircleFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />
              <S.ActionButtonLabel>Adicionar Tabela de Preço</S.ActionButtonLabel>
            </S.ActionButtonContainer>
          )}

          {hasMerpIntegration && (
            <S.ActionButtonContainer onClick={onShowNewPriceTableModal}>
              <PlusCircleFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />
              <S.ActionButtonLabel>Nova Tabela de Preço</S.ActionButtonLabel>
            </S.ActionButtonContainer>
          )}
        </Row>
      </Row>

      <FullScreenModal
        destroyOnClose={true}
        visible={modalIsVisible}
        onOk={onCloseModal}
        onCancel={onCloseModal}
        footer={null}
        forceRender={false}
      >
        <FormAddPriceTable />
      </FullScreenModal>

      <FullScreenModal
        destroyOnClose={true}
        visible={newPriceTableModalIsVisible}
        onOk={onCloseNewPriceTableModal}
        onCancel={onCloseNewPriceTableModal}
        footer={null}
        forceRender={false}
      >
        <Row align="middle" justify="center">
          <Col span={24} className="d-flex" style={{ width: '1000px' }}>
            <PriceTableForm onCloseModal={onCloseNewPriceTableModal} isEditing={false} />
          </Col>
        </Row>
      </FullScreenModal>
    </>
  );
};

export default PriceTablesHeader;
