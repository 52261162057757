import { Col, Modal, Row, Typography } from 'antd';
import React from 'react';

import { CloseOutlined, ExclamationCircleOutlined, InfoCircleFilled } from '@ant-design/icons';
import Loading from '@components/Loading';
import { CustomButton } from '@styles/globals';
import * as S from './styles';

const { Title, Text } = Typography;

interface CardProps {
  label1: string;
  label2: string;
  value1: string;
  value2: string;
  onDelete: Function;
  option?: string;
}

const Card: React.FC<CardProps> = ({ label1, label2, value1, value2, onDelete, option }) => (
  <S.CustomCard>
    <Row className="w-100 card-row">
      {option && (
        <Col span={24} style={{ paddingLeft: '8px', paddingTop: '8px' }}>
          <Title level={5} style={{ height: '20px' }}>
            Opção: <span style={{ fontWeight: 400 }}>{option}</span>
          </Title>
        </Col>
      )}
      <Col span={16} style={{ paddingLeft: '8px', paddingTop: '8px' }}>
        <Title level={5} style={{ height: '20px' }}>
          {label1}
        </Title>
        <Text>{value1}</Text>
      </Col>
      <Col span={8} style={{ paddingLeft: '8px', paddingTop: '8px' }}>
        <Title level={5} style={{ height: '20px' }}>
          {label2}
        </Title>
        <Text>{value2}</Text>
      </Col>
      <Col span={24} className="card-col" onClick={() => onDelete(value1, value2)}>
        <CloseOutlined style={{ fontSize: '16px', color: 'var(--primary-color)' }} />
        <Text style={{ fontSize: '14px', color: 'var(--primary-color)' }}>Excluir Opção</Text>
      </Col>
    </Row>
  </S.CustomCard>
);

type ListItemType = {
  label1: string;
  label2: string;
  item1: string;
  item2: string;
};

interface DualFormProps {
  title: string;
  form: React.ReactNode;
  list: ListItemType[];
  loading: boolean;
  onDelete: Function;
  onSubmit?: Function;
}
const DualForm: React.FC<DualFormProps> = ({ title, form, list, loading, onDelete, onSubmit }) => {
  const showModalConfirmation = (listItemType: ListItemType) => {
    Modal.confirm({
      title: 'Deseja realmente excluir?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <Text>
            {listItemType.label1}: {listItemType.item1}
          </Text>
          <br />
          <Text>
            {listItemType.label2}: {listItemType.item2}
          </Text>
        </>
      ),
      okText: 'Excluir',
      okType: 'danger',
      onOk() {
        onDelete(listItemType.item1, listItemType.item2);
      },
    });
  };

  if (loading) return <Loading />;

  return (
    <Row gutter={[16, 16]} style={{ alignItems: 'center', justifyContent: 'center' }}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className="gutter-row">
        <S.CustomCardContainer floating="left">
          <S.ScrollContainer>
            {list.map(({ label1, label2, item1, item2 }) => (
              <Card
                label1={label1}
                label2={label2}
                value1={item1}
                value2={item2}
                onDelete={() => showModalConfirmation({ label1, label2, item1, item2 })}
              />
            ))}
          </S.ScrollContainer>
        </S.CustomCardContainer>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className="gutter-row">
        <S.CustomCardContainer floating="right">
          <S.ScrollContainer>
            <Title level={5} className="mb-4">
              {title}
            </Title>
            {form}
            {onSubmit &&
              (list?.length === 0 ? (
                <CustomButton disabled={true} style={{ width: '100%' }} outlined="true" onClick={() => onSubmit()}>
                  SALVAR
                </CustomButton>
              ) : (
                <CustomButton className="w-100" outlined="true" onClick={() => onSubmit()}>
                  SALVAR
                </CustomButton>
              ))}

            {list?.length === 0 && (
              <p
                style={{
                  width: '100%',
                  height: '42px',
                  borderRadius: '10px',
                  borderColor: '#d9d9d9',
                  background: '#f5f5f5',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '15px',
                  fontSize: 14,
                }}
              >
                <InfoCircleFilled
                  className="ml-4"
                  style={{ color: 'var(--primary-color)', marginRight: '5px', marginBottom: '2px' }}
                />
                Ao menos uma coleção deve ser adicionada antes de salvar
              </p>
            )}
          </S.ScrollContainer>
        </S.CustomCardContainer>
      </Col>
    </Row>
  );
};

export default DualForm;
