import React from 'react';
import { Col, ColProps, Typography } from 'antd';

import * as S from './styles';

const {Title} = Typography

interface CardTableProps extends ColProps {
  table: React.ReactNode;
  title: string;
}
const CardTable: React.FC<CardTableProps> = ({ table, title, ...rest }) => {
  return (
    <Col {...rest} className="d-flex gutter-row">
      <S.Container>
        <Title className="w-100 mt-2 mb-4" level={4}>{title}</Title>
        {table}
      </S.Container>
    </Col>
  );
};

export default CardTable;
