import { useDevolution } from '@hooks/DevolutionsContext';
import { devolutionColumns } from '@pages/DevolutionPage/components/DevolutionColumns/DevolutionColumns';
import { Table } from 'antd';
import React, { useEffect } from 'react';

const PrimaryAnalysisTab: React.FC = () => {
  const {
    primaryAnalysisDevolutions,
    loadPrimaryAnalysisDevolutions,
    loadingDevolutions,
    primaryAnalysisFilters,
    primaryAnalysisPage,
    primaryAnalysisTotal,
  } = useDevolution();

  React.useEffect(() => {
    loadPrimaryAnalysisDevolutions(0);
  }, []);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.hasOwnProperty('column')) {
      primaryAnalysisFilters.field = `${sorter.field}`;
      primaryAnalysisFilters.order = `${sorter.order}`;
    }

    loadPrimaryAnalysisDevolutions(pagination.current, primaryAnalysisFilters);
  };

  return (
    <Table
      columns={devolutionColumns}
      dataSource={primaryAnalysisDevolutions}
      loading={loadingDevolutions}
      pagination={{
        pageSize: 10,
        total: primaryAnalysisTotal,
        current: primaryAnalysisPage,
        showSizeChanger: false,
        showQuickJumper: false,
      }}
      scroll={{ x: 800 }}
      rowKey={(devolution) => devolution?.invoiceCode}
      onChange={handleTableChange}
    />
  );
};

export default PrimaryAnalysisTab;
