import * as api from './api';
import { Response } from '@models/Response';

export function getClassifications(officialStoreId: number): Promise<Response<any>> {
  return api.get(`official-store/${officialStoreId}/order_classification`).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function createOrderClassification(officialStoreId: number, body: any): Promise<any> {
  return api.post(`official-store/${officialStoreId}/order_classification`, body).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function deleteOrderClassification(officialStoreId: number, params: any): Promise<any> {
  return api.remove(`official-store/${officialStoreId}/order_classification`, { params }).catch((error) => {
    return Promise.resolve(error.response);
  });
}
