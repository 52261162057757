import { Image, message } from 'antd';
import React, { useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { useDevolution } from '@hooks/DevolutionsContext';
import { Devolution } from '@models/Devolution';
import devolutionSent from '../../assets/svg/devolution-sent.svg';
import DevolutionRequestInvoiceDataForm from './steps/DevolutionRequestInvoiceDataForm';
import { Container } from './styles';

const DevolutionRequestForm: React.FC<any> = ({}) => {
  const { createDevolutionRequest } = useDevolution();
  const [loading, setLoading] = useState<boolean>(false);
  const [wasSent, setWasSent] = useState<boolean>(false);

  const [formValues, setFormValues] =
    React.useState<{ devolution: Devolution; quantityToReturn: any; observationToReturn: any }>();

  const hadleSubmit = async (devolution: Devolution) => {
    setLoading(true);

    try {
      await createDevolutionRequest(devolution);

      message.success('A devolução foi enviada com sucesso!!');
      setWasSent(true);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '50px',
        width: '100%',
      }}
    >
      <div className="steps-action d-flex" style={{ width: '100%' }}>
        <Container
          style={{
            background: '#fff',
            padding: '100px',
            borderRadius: '8px',
            width: '100%',
          }}
        >
          {loading && (
            <div
              style={{
                width: '1100px',
                height: '550px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LoadingOutlined
                type="play-circle-o"
                style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
              />
            </div>
          )}

          {!loading && wasSent && (
            <div
              style={{
                width: '1100px',
                height: '550px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '150px',
                textAlign: 'center',
              }}
            >
              <Image src={devolutionSent} style={{ width: '100%', maxWidth: '250px' }} preview={false} />
              <h2 style={{ fontWeight: 'bold' }}>Sua solicitação de devolução foi enviada com sucesso!</h2>
              <p>
                Após a validação dos dados enviados para a devolução, você receberá um link no e-mail/WhatsApp e, ao
                clicar, será redirecionado para a próxima etapa de devolução.
              </p>
            </div>
          )}

          {!loading && !wasSent && (
            <>
              <DevolutionRequestInvoiceDataForm
                formValues={formValues}
                setFormValues={setFormValues}
                handleSubmit={hadleSubmit}
              />
            </>
          )}
        </Container>
      </div>
    </div>
  );
};

export default DevolutionRequestForm;
