import { StatusGroupTypeEnum } from '@models/enum/StatusOrder';
import { FailedOrder } from '@models/FailedOrder';
import { Order } from '@models/Order';
import { OrderFilters } from '@models/OrderFilters';
import { ShoppingBagOrderJson } from '@models/ShoppingBagOrderJson';
import * as OrderService from '@services/order';
import { message } from 'antd';
import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

type StatusType = 'Attended' | 'Canceled' | 'InProgress' | 'NotSent';
interface OrderState {
  attendedFilters: any;
  attendedPage: number;
  attendedOrders: Order[];
  attendedTotal: number;
  loadingAttendedOrders: boolean;
  loadAttendedOrders: Function;

  canceledFilters: any;
  canceledPage: number;
  canceledOrders: Order[];
  canceledTotal: number;
  loadingCanceledOrders: boolean;
  loadCanceledOrders: Function;

  inProgressFilters: any;
  inProgressPage: number;
  inProgressOrders: Order[];
  inProgressTotal: number;
  loadingInProgressOrders: boolean;
  loadInProgressOrders: Function;

  notSentPage: number;
  notSentOrders: FailedOrder[];
  notSentTotal: number;
  notSentFilters: any;
  loadingNotSentOrders: boolean;
  loadNotSentOrders: Function;

  loadingOrderDetail: boolean;
  loadOrderDetailByCode: Function;
  loadFailedOrderDetailByCode: Function;

  status: StatusType;

  PAGINATION_SIZE: number;
  generateFaileOrderByShoppingBag: (orderBagJson: ShoppingBagOrderJson) => Promise<void>;
  loadingGenerateFailedOrderByShoppingBag: boolean;

  loadingPDF: boolean;
  updateOrderUsingMerp: Function;
  generateAndDownlodPDF: Function;
}

interface OrderProviderProps {
  children: ReactNode;
}

const PAGINATION_SIZE = 10;

export const OrderContext = createContext<OrderState>({} as OrderState);

const OrderProvider: React.FC<OrderProviderProps> = ({ children }) => {
  const [attendedFilters, setAttendedFilters] = useState<any>({} as any);
  const [loadingAttendedOrders, setLoadingAttendedOrders] = useState<boolean>(false);
  const [attendedOrders, setAttendedOrders] = useState<any[]>([]);
  const [attendedTotal, setAttendedTotal] = useState<number>(0);
  const [attendedPage, setAttendedPage] = useState<number>(0);

  const [canceledFilters, setCanceledFilters] = useState<any>({} as any);
  const [loadingCanceledOrders, setLoadingCanceledOrders] = useState<boolean>(false);
  const [canceledOrders, setCanceledOrders] = useState<any[]>([]);
  const [canceledTotal, setCanceledTotal] = useState<number>(0);
  const [canceledPage, setCanceledPage] = useState<number>(0);

  const [inProgressFilters, setInProgressFilters] = useState<any>({} as any);
  const [loadingInProgressOrders, setLoadingInProgressOrders] = useState<boolean>(false);
  const [inProgressOrders, setInProgressOrders] = useState<any[]>([]);
  const [inProgressTotal, setInProgressTotal] = useState<number>(0);
  const [inProgressPage, setInProgressPage] = useState<number>(0);

  const [loadingNotSentOrders, setLoadingNotSentOrders] = useState<boolean>(false);
  const [notSentOrders, setNotSentOrders] = useState<any[]>([]);
  const [notSentTotal, setNotSentTotal] = useState<number>(0);
  const [notSentPage, setNotSentPage] = useState<number>(0);
  const [notSentFilters, setNotSentFilters] = useState<any>({} as any);

  const [loadingOrderDetail, setLoadingOrderDetail] = useState<boolean>(false);

  const [status, setStatus] = useState<StatusType>('InProgress');
  const [loadingGenerateFailedOrderByShoppingBag, setLoadingGenerateFailedOrderByShoppingBag] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);

  const searchOrders = async (statusGroup: string, page: number, filters?: OrderFilters) => {
    var orders: any[] = [];
    var total = 0;

    const { data: _orders } = await OrderService.get({
      pageSize: PAGINATION_SIZE,
      page: page ? page - 1 : 0,
      statusGroup,
      ...(filters && { ...filters }),
    });

    total = total + _orders.total;
    orders = [...orders, ..._orders.data];

    return {
      orders,
      total,
    };
  };

  const loadAttendedOrders = async (page: number, filters?: OrderFilters) => {
    setLoadingAttendedOrders(true);
    setStatus('Attended');
    if (filters) setAttendedFilters(filters);
    else setAttendedFilters({});

    const statusGroup = StatusGroupTypeEnum.Attended;

    try {
      const { orders, total } = await searchOrders(statusGroup, page, filters);

      setAttendedOrders(orders);
      setAttendedTotal(total);
      setAttendedPage(page === 0 ? 1 : page);
    } catch (error) {
      message.error('Erro ao listar pedidos');
    } finally {
      setLoadingAttendedOrders(false);
    }
  };

  const loadCanceledOrders = async (page: number, filters?: OrderFilters) => {
    setLoadingCanceledOrders(true);
    setStatus('Canceled');
    if (filters) setCanceledFilters(filters);
    else setCanceledFilters({});

    const statusGroup = StatusGroupTypeEnum.Canceled;

    try {
      const { orders, total } = await searchOrders(statusGroup, page, filters);

      setCanceledOrders(orders);
      setCanceledTotal(total);
      setCanceledPage(page === 0 ? 1 : page);
    } catch (error) {
      message.error('Erro ao listar pedidos');
    } finally {
      setLoadingCanceledOrders(false);
    }
  };

  const loadInProgressOrders = useCallback(
    async (page: number, filters?: OrderFilters) => {
      setLoadingInProgressOrders(true);
      setStatus('InProgress');
      if (filters) setInProgressFilters(filters);
      else setInProgressFilters({});

      const statusGroup = StatusGroupTypeEnum.InProgress;

      try {
        const { orders, total } = await searchOrders(statusGroup, page, filters);

        setInProgressOrders(orders);
        setInProgressTotal(total);
        setInProgressPage(page === 0 ? 1 : page);
      } catch (error) {
        message.error('Erro ao listar pedidos');
      } finally {
        setLoadingInProgressOrders(false);
      }
    },
    [PAGINATION_SIZE, setLoadingInProgressOrders, setInProgressTotal, setInProgressPage],
  );

  const loadNotSentOrders = useCallback(
    async (page: number, filters?: any) => {
      setLoadingNotSentOrders(true);
      setStatus('NotSent');
      try {
        const { data: _orders } = await OrderService.getFailed({
          pageSize: PAGINATION_SIZE,
          page: page ? page - 1 : 0,
          ...(filters && { ...filters }),
        });

        setNotSentOrders(_orders.data);
        setNotSentTotal(_orders.total);
        setNotSentPage(page === 0 ? 1 : page);
      } catch (error) {
        message.error('Erro ao listar pedidos');
      } finally {
        setLoadingNotSentOrders(false);
      }
    },
    [PAGINATION_SIZE, setLoadingNotSentOrders, setNotSentTotal, setNotSentPage],
  );

  const loadOrderDetailByCode = useCallback(
    async (code: number, option: 'attended' | 'canceled' | 'in-progress') => {
      setLoadingOrderDetail(true);

      const { data: _order } = await OrderService.getByCode(code);

      if (option === 'attended') {
        const index = attendedOrders.findIndex((order) => order.orderCode === code);
        attendedOrders[index] = { ..._order, hasDetails: true };
        setAttendedOrders(attendedOrders);
      }

      if (option === 'canceled') {
        const index = canceledOrders.findIndex((order) => order.orderCode === code);
        canceledOrders[index] = { ..._order, hasDetails: true };
        setAttendedOrders(canceledOrders);
      }

      if (option === 'in-progress') {
        const index = inProgressOrders.findIndex((order) => order.orderCode === code);
        inProgressOrders[index] = { ..._order, hasDetails: true };
        setAttendedOrders(inProgressOrders);
      }

      setLoadingOrderDetail(false);
      return _order;
    },
    [
      attendedOrders,
      canceledOrders,
      inProgressOrders,
      setLoadingOrderDetail,
      setAttendedOrders,
      setCanceledOrders,
      setInProgressOrders,
    ],
  );

  const loadFailedOrderDetailByCode = async (sequence: number) => {
    setLoadingOrderDetail(true);

    try {
      const { data: _order } = await OrderService.getFailedDetail(sequence);

      const index = notSentOrders.findIndex((order) => order.sequence === sequence);
      notSentOrders[index] = { ..._order, hasDetails: true };
      setNotSentOrders(notSentOrders);
      return _order;
    } catch {
      message.error('Erro ao carregar detalhes!');
    } finally {
      setLoadingOrderDetail(false);
    }
  };

  const generateFaileOrderByShoppingBag = useCallback(async (orderBagJson: ShoppingBagOrderJson) => {
    setLoadingGenerateFailedOrderByShoppingBag(true);
    try {
      await OrderService.generateFailedOrderByShoppingBag(orderBagJson);

      message.success('Pedido retornado com sucesso!');
    } catch (error) {
      message.error('Erro ao retornar pedido!');
    } finally {
      setLoadingGenerateFailedOrderByShoppingBag(false);
    }
  }, []);

  const updateOrderUsingMerp = useCallback(async (order: Order) => {
    try {
      await OrderService.updateOrderUsingMerp(order);

      message.success('Pedido atualizado com sucesso!');
    } catch (error) {
      message.error('Erro ao atualizar pedido!');
    } finally {
    }
  }, []);

  const generateAndDownlodPDF = useCallback(async (order: Order) => {
    try {
      setLoadingPDF(true);
      const response = await OrderService.generateAndDownlodPDF(order);

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

      const url = window.URL.createObjectURL(pdfBlob);

      const tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', `pedido - ${order.orderCode}.pdf`);

      document.body.appendChild(tempLink);
      tempLink.click();

      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
      message.success('PDF gerado  com sucesso!');
      return response;
    } catch (error) {
      message.error('Erro ao gerar PDF do pedido!');
    } finally {
      setLoadingPDF(false);
    }
  }, []);

  return (
    <OrderContext.Provider
      value={{
        attendedFilters,
        attendedPage,
        attendedOrders,
        attendedTotal,
        loadingAttendedOrders,
        loadAttendedOrders,

        canceledFilters,
        canceledPage,
        canceledOrders,
        canceledTotal,
        loadingCanceledOrders,
        loadCanceledOrders,

        inProgressPage,
        inProgressFilters,
        inProgressOrders,
        inProgressTotal,
        loadingInProgressOrders,
        loadInProgressOrders,

        notSentPage,
        notSentOrders,
        notSentTotal,
        notSentFilters,
        loadingNotSentOrders,
        loadNotSentOrders,

        loadingOrderDetail,
        loadOrderDetailByCode,
        loadFailedOrderDetailByCode,

        status,

        PAGINATION_SIZE,
        generateFaileOrderByShoppingBag,
        loadingGenerateFailedOrderByShoppingBag,

        updateOrderUsingMerp,
        generateAndDownlodPDF,
        loadingPDF,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

const useOrder = () => {
  return useContext(OrderContext);
};

export { OrderProvider, useOrder };
