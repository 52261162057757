import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';

import Loading from '@components/Loading';
import { useRepresentative } from '@hooks/RepresentativeContext';
import RepresentativesContent from './components/RepresentativesContent';
import RepresentativesHeader from './components/RepresentativesHeader';

const RepresentativesPage: React.FC = () => {
  const { url } = useRouteMatch();
  const { loading, loadRepresentativesPaginated } = useRepresentative();

  useEffect(() => {
    loadRepresentativesPaginated(0);
  }, []);

  if (loading) return <Loading />;

  return (
    <>
      <RepresentativesHeader />
      <RepresentativesContent redirectBaseUrl={url} />
    </>
  );
};

export default RepresentativesPage;
