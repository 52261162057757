import { Form, Input, DatePicker } from 'antd';

const { RangePicker } = DatePicker;
const ShoppingBagFiltersForm: React.FC = () => {
  return (
    <>
      <Form.Item className="mb-3" label="Código do representante" name="representativeCode">
        <Input placeholder="Informe o código do representante" />
      </Form.Item>

      <Form.Item className="mb-3" label="Código do representante" name="dates">
        <RangePicker size="large" className="w-100" format="DD/MM/YYYY" />
      </Form.Item>
    </>
  );
};

export default ShoppingBagFiltersForm;
