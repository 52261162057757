import { Stock } from '@models/Stock';
import { LiquidityBufferAppEnum } from '@models/enum/LiquidityBufferApp';
import * as OfficialStoreStockService from '@services/officialStoreStock';
import { message } from 'antd';
import { ReactNode, createContext, useContext, useMemo, useState } from 'react';

interface OfficialStoreStockState {
  loading: boolean;
  stocks: { [key in LiquidityBufferAppEnum]: Stock[] };
  loadStocks: (officialStoreId: number, liquidityBufferApp: LiquidityBufferAppEnum) => Promise<void>;
  setStocksByLiquidityBufferApp: (liquidityBufferApp: LiquidityBufferAppEnum, stocks: Stock[]) => void;
  saveStocks: (officialStoreId: number, body: OfficialStoreStockService.SaveStocksBody) => Promise<void>;
  value: { [key in LiquidityBufferAppEnum]: number | null };
  percentage: { [key in LiquidityBufferAppEnum]: number | null };
}

export const OfficialStoreStockContext = createContext<OfficialStoreStockState>(Object.assign({}));

const OfficialStoreStockProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [stocks, setStocks] = useState<{ [key in LiquidityBufferAppEnum]: Stock[] }>(Object.assign({}));
  const [value, setValue] = useState<{ [key in LiquidityBufferAppEnum]: number }>(Object.assign({}));
  const [percentage, setPercentage] = useState<{ [key in LiquidityBufferAppEnum]: number | null }>(Object.assign({}));

  const handleError = (error: any, defaultMessage = 'Algo deu errado!') => {
    console.error('error on context', error);
    message.error(error?.response?.data?.message ?? defaultMessage, 5);
  };

  const saveStocks = async (officialStoreId: number, body: OfficialStoreStockService.SaveStocksBody): Promise<void> => {
    try {
      setLoading(true);
      const { data: response } = await OfficialStoreStockService.saveStocks(officialStoreId, body);
      message.success('Salvo com sucesso!');
      await loadStocks(officialStoreId, body.appType);
      setStocksByLiquidityBufferApp(body.appType, response ?? []);
    } catch (error) {
      handleError(error, 'Erro ao salvar estoques!');
      setStocksByLiquidityBufferApp(body.appType, []);
    } finally {
      setLoading(false);
    }
  };

  const loadStocks = async (officialStoreId: number, liquidityBufferApp: LiquidityBufferAppEnum): Promise<void> => {
    try {
      setLoading(true);
      const { data: response } = await OfficialStoreStockService.getStocks(officialStoreId, liquidityBufferApp);
      setStocksByLiquidityBufferApp(liquidityBufferApp, response?.stocks ?? []);
      setValueByLiquidityBufferApp(liquidityBufferApp, response?.value ?? null);
      setPercentageByLiquidityBufferApp(liquidityBufferApp, response?.percentage ?? null);
    } catch (error: any) {
      if (error?.response?.status == 404) {
        setStocksByLiquidityBufferApp(liquidityBufferApp, []);
        setValueByLiquidityBufferApp(liquidityBufferApp, null);
        setPercentageByLiquidityBufferApp(liquidityBufferApp, null);
        return;
      }
      handleError(error, 'Erro ao carregar estoques!');
      setStocksByLiquidityBufferApp(liquidityBufferApp, []);
    } finally {
      setLoading(false);
    }
  };

  const setStocksByLiquidityBufferApp = (liquidityBufferApp: LiquidityBufferAppEnum, newStocks: Stock[]) => {
    setStocks((prev) => ({ ...prev, [liquidityBufferApp]: newStocks }));
  };

  const setValueByLiquidityBufferApp = (liquidityBufferApp: LiquidityBufferAppEnum, value: number | null) => {
    setValue((prev) => ({ ...prev, [liquidityBufferApp]: value }));
  };

  const setPercentageByLiquidityBufferApp = (liquidityBufferApp: LiquidityBufferAppEnum, value: number | null) => {
    setPercentage((prev) => ({ ...prev, [liquidityBufferApp]: value }));
  };

  const valueProvider = useMemo(
    () => ({
      loading,
      loadStocks,
      stocks,
      setStocksByLiquidityBufferApp,
      saveStocks,
      value,
      percentage,
    }),
    [loading, loadStocks, stocks, setStocksByLiquidityBufferApp, saveStocks, value, percentage],
  );
  return <OfficialStoreStockContext.Provider value={valueProvider}>{children}</OfficialStoreStockContext.Provider>;
};

const useOfficialStoreStock = () => {
  return useContext(OfficialStoreStockContext);
};

export { OfficialStoreStockProvider, useOfficialStoreStock };
