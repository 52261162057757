import { useEffect } from 'react';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Row, Table, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useOfficialStore } from '@hooks/OfficialStoreContext';
import { OfficialStoreCollection } from '@models/OfficialStoreCollection';
import { useCollection } from '@hooks/CollectionContext';
import * as S from '../../styles';

const { Title } = Typography;

interface CollectionsPanelProps {
  officialStoreId: number;
  officialStoreCollections: OfficialStoreCollection[];
}

const CollectionsPanel: React.FC<CollectionsPanelProps> = ({ officialStoreCollections, officialStoreId }) => {
  const { loadCollections } = useCollection();
  const { url } = useRouteMatch();
  const { updateOfficialStoreCollections } = useOfficialStore();
  const [form] = useForm();
  const history = useHistory();

  useEffect(() => {
    loadCollections();
  }, []);

  useEffect(() => {
    if (!officialStoreCollections) return;

    const collectionsIds = officialStoreCollections.map((collection) => collection.collectionId);

    form.setFieldsValue({ collections: collectionsIds });
  }, [officialStoreCollections]);

  const onDeleteOfficialStoreCollection = async (collectionId: number) => {
    const filtered = officialStoreCollections.filter(({ collectionId: id }: any) => id !== collectionId);

    updateOfficialStoreCollections(officialStoreId, filtered);
  };

  const columns = [
    {
      title: 'Cód.',
      dataIndex: 'collectionId',
      sorter: (collectionA: any, collectionB: any) => collectionA.collectionId - collectionB.collectionId,
    },
    {
      title: 'Nome da Coleção',
      dataIndex: 'collection',
      render: (value: any) => <span>{value.description}</span>,
      sorter: (collectionA: any, collectionB: any) =>
        collectionA.collection.description.trim().localeCompare(collectionB.collection.description.trim()),
    },
    {
      title: '',
      dataIndex: 'collectionId',
      align: 'right' as 'right',
      render: (value: any) => (
        <Popconfirm
          title={`Tem certeza que deseja remover essa coleção?`}
          onConfirm={() => onDeleteOfficialStoreCollection(value)}
          okText="Sim"
          cancelText="Não"
        >
          <CloseOutlined style={{ cursor: 'pointer' }} onClick={() => null} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <Col xs={24} sm={24} lg={16} xxl={12} className="d-flex">
      <S.Panel>
        <Row justify="space-between">
          <Col>
            <Title level={4} style={{ color: '#555' }}>
              Coleções ativas
            </Title>
          </Col>
          <Col>
            <S.CustomButton
              type="primary"
              onClick={() => {
                history.push(`${url}/colecoes`);
              }}
              noMargin
            >
              Pesquisar coleções <SearchOutlined />
            </S.CustomButton>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={officialStoreCollections}
              pagination={{ pageSize: 4 }}
              rowKey={(record) => record.collectionId}
              scroll={{ x: 800 }}
            />
          </Col>
        </Row>
      </S.Panel>
    </Col>
  );
};

export default CollectionsPanel;
