import { Table } from 'antd';

import { Classification } from '@models/Classification';

interface OfficialStoreMenuClassificationsTableProps {
  submenuClassifications: Classification[];
}

const OfficialStoreSubMenuClassificationsTable: React.FC<OfficialStoreMenuClassificationsTableProps> = ({
  submenuClassifications,
}) => {
  const columns = [
    {
      title: 'Tipo Classificação',
      dataIndex: 'typeCode',
      key: 'typeCode',
      align: 'right' as 'right',
      render: (value: any) => <strong style={{ color: 'gray' }}>Tipo de Classificação: {value}</strong>,
    },
    {
      title: 'Classificação',
      dataIndex: 'code',
      key: 'code',
      align: 'right' as 'right',
      width: '160px',
      render: (value: any) => <strong style={{ color: 'gray' }}>Classificação: {value}</strong>,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={submenuClassifications}
      showHeader={false}
      pagination={false}
      className="mb-4 mt-1"
      scroll={{ x: 800 }}
    />
  );
};

export default OfficialStoreSubMenuClassificationsTable;
