import { useEffect, useState } from 'react';
import { Table } from 'antd';
import { groupBy } from 'lodash';

import { Classification } from '@models/Classification';

interface TabClassificationProps {
  personClassifications: Classification[];
}

const TabClassification: React.FC<TabClassificationProps> = ({ personClassifications }) => {
  const [classifications, setClassifications] = useState<any[]>([]);

  useEffect(() => {
    if (personClassifications)
      setClassifications(Object.values(groupBy(personClassifications, (classification) => classification.typeCode)));
  }, [personClassifications]);

  const columns = [
    {
      title: 'Tipo Classificação',
      dataIndex: 'typeCode',
      key: 'typeCode',
      width: '20%',
      align: 'center' as 'center',
      render: (text: string, classificationsList: any): JSX.Element => {
        return <strong style={{ color: 'gray' }}>{classificationsList[0].typeCode}</strong>;
      },
    },
    {
      title: 'Classificação',
      dataIndex: 'code',
      key: 'code',
      render: (text: string, classificationsList: any): JSX.Element => {
        return (
          <strong style={{ color: 'gray' }}>
            {classificationsList.map((classification: any, i: number) =>
              i === 0 ? classification.code : ' | ' + classification.code,
            )}
          </strong>
        );
      },
    },
  ];

  return <Table 
  scroll={{ x: 800 }}
  columns={columns} dataSource={classifications} pagination={{ pageSize: 4, hideOnSinglePage: true }} />;
};

export default TabClassification;
