import { PriceTableReference } from '@models/PriceTableReference';
import { Table, Typography } from 'antd';

const { Text } = Typography;

interface TabReferenceProps {
  priceTableReferences: PriceTableReference[];
}

const TabReference: React.FC<TabReferenceProps> = ({ priceTableReferences }) => {
  const columns = [
    {
      title: 'ID Ref.',
      dataIndex: 'referenceId',
      width: '60px',
      align: 'center' as 'center',
      sorter: (referenceA: any, referenceB: any) => referenceA.referenceId - referenceB.referenceId,
    },
    {
      title: 'Código Ref.',
      align: 'center' as 'center',
      width: '200px',
      render: (reference: any) => <Text>{reference?.priceTable?.referenceCode}</Text>,
      sorter: (referenceA: any, referenceB: any) =>
        referenceA.priceTable.referenceCode.trim().localeCompare(referenceB.priceTable.referenceCode.trim()),
    },
    {
      title: 'Escala',
      width: '80px',
      dataIndex: 'scaleCode',
      align: 'right' as 'right',
      sorter: (referenceA: any, referenceB: any) => referenceA.scaleCode - referenceB.scaleCode,
    },
    {
      title: 'Valor',
      dataIndex: 'originalPrice',
      align: 'right' as 'right',
      render: (value: any) => (value ? <Text>{value.toFixed(2)}</Text> : <Text>0,00</Text>),
      sorter: (referenceA: any, referenceB: any) => referenceA.originalPrice - referenceB.originalPrice,
    },
    {
      title: 'Variação (%)',
      dataIndex: 'variationPercentage',
      align: 'right' as 'right',
      render: (value: any) => (value ? <Text>{value}</Text> : <Text>0</Text>),
      sorter: (referenceA: any, referenceB: any) => referenceA.variationPercentage - referenceB.variationPercentage,
    },
    {
      title: 'Variação (Valor)',
      dataIndex: 'variationValue',
      align: 'right' as 'right',
      render: (value: any) => (value ? <Text>{value.toFixed(2)}</Text> : <Text>0,00</Text>),
      sorter: (referenceA: any, referenceB: any) => referenceA.variationValue - referenceB.variationValue,
    },
  ];

  return (
    <Table
      scroll={{ x: 800 }}
      columns={columns}
      dataSource={priceTableReferences}
      pagination={{ pageSize: 4, hideOnSinglePage: true }}
    />
  );
};

export default TabReference;
