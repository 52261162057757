export enum DevolutionStatusEnum {
  PRIMARY_ANALYZIS = 'PRIMARY_ANALYSIS',
  SECONDARY_ANALYSIS = 'SECONDARY_ANALYSIS',
  IN_DEVOLUTION = 'IN_DEVOLUTION',
  FINISHED = 'FINISHED',
}

export enum DevolutionItemObservationStatusEnum {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
