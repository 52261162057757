import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';

import Loading from '@components/Loading';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
import OfficialStoreRepresentativeHeader from './components/OfficialStoreRepresentativeHeader';
import OfficialStoreRepresentativeContent from './components/OfficialStoreRepresentativeContent';
import { useRepresentative } from '@hooks/RepresentativeContext';
import { useBrand } from '@hooks/BrandContext';

interface MatchParams {
  id: string;
}

const OfficialStoreRepresentativePage: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();

  const { actualOfficialStore, findOfficialStoreById, loading } = useOfficialStore();
  const { loadRepresentatives, loading: loadingRepresentatives } = useRepresentative();
  const { loadBrands, loading: loadingBrands } = useBrand();

  useEffect(() => {
    findOfficialStoreById(parseInt(params.id));
    loadBrands();
    loadRepresentatives();
  }, []);

  if (loading || loadingBrands || loadingRepresentatives) return <Loading />;

  return (
    <>
      <OfficialStoreRepresentativeHeader officialStore={actualOfficialStore} />
      <OfficialStoreRepresentativeContent officialStore={actualOfficialStore} />
    </>
  );
};

export default OfficialStoreRepresentativePage;
