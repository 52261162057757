import { Row, Col, Typography } from 'antd';

import { Order } from '@models/Order';
import { formatCurrency, calculateEquivalentPercentage } from '@utils/index';
import { useOrder } from '@hooks/OrdersContext';
import LoadingComponents from '@components/LoadingComponents';
import './styles.css';

const { Text } = Typography;

interface PaymentProps {
  order: Order;
  isFailedOrder?: boolean;
}

const Payment: React.FC<PaymentProps> = ({ order, isFailedOrder }) => {
  const { loadingOrderDetail } = useOrder();

  if (loadingOrderDetail) return <LoadingComponents />;

  const getItemsTotalValue = (): number => {
    if (order.items)
      return order.items.reduce(function (a, b: any) {
        return isFailedOrder ? a + b.quantity * b.price : a + b.Quantity * b.price;
      }, 0);

    return 0;
  };

  return (
    <Row justify="space-around" gutter={[32, 24]}>
      <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8} className="gutter-row">
        <div className="payment-container">
          <Text style={{ fontWeight: 'bold' }}>Meio de Pagamento</Text>
          <Text>{order.paymentConditionName ?? 'Sem informação'}</Text>
        </div>

        <div className="payment-container">
          <Text style={{ fontWeight: 'bold' }}>Desconto</Text>
          <Text>
            {order.discountValue && order.grossValue
              ? `${formatCurrency(order.discountValue)} (${calculateEquivalentPercentage(
                  order.grossValue,
                  order.discountValue,
                )})`
              : 'Sem informação'}
          </Text>
        </div>

        <div className="payment-container">
          <Text style={{ fontWeight: 'bold' }}>Frete</Text>
          <Text>{order.freightValue ? formatCurrency(order.freightValue) : 'Sem informação'}</Text>
        </div>
        {/* TODO */}
        {/* <div className="payment-container">
          <Text style={{ fontWeight: 'bold' }}>Nome do Titular</Text>
          <Text>Nome do Titular</Text>
        </div>
        <div className="payment-container">
          <Text style={{ fontWeight: 'bold' }}>CPF do Titular</Text>
          <Text>CPF do Titular</Text>
        </div> */}
      </Col>

      <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8} className="gutter-row">
        <div className="payment-container">
          <Text style={{ fontWeight: 'bold' }}>Total dos Itens</Text>
          <Text>{formatCurrency(getItemsTotalValue())}</Text>
        </div>

        <div className="payment-container">
          <Text style={{ fontWeight: 'bold' }}>Total</Text>
          <Text>{formatCurrency(order.totalAmountOrder)}</Text>
        </div>
      </Col>

      <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8} className="gutter-row">
        {/* TODO */}
        {/* <div className="payment-container">
          <Text style={{ fontWeight: 'bold' }}>Parcelamento</Text>
          <Text>Parcelamento</Text>
        </div> */}
      </Col>
    </Row>
  );
};

export default Payment;
