import { Form, Input } from 'antd';
import styled from 'styled-components';

export const Panel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 6px;
  padding: 20px;
`;

export const UploadBody = styled.div`
  width: 200px;
  height: 160px;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  > h5 {
    font-size: 16px;
    margin-top: 8px;
  }
`;

export const ContainerNameInput = styled(Input)`
  width: 320px;
`;

export const CustomFormItem = styled(Form.Item)`
  margin: 8px 0 0 0;

  > .ant-form-item-label {
    padding: 0;
  }
`;

export const LoadingContainer = styled.div`
  width: 200px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
`;
