import React from 'react';
import { Row, Typography, Form } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';

import * as S from './styles';

const { Title } = Typography;

const NewOfficialStoreHeader: React.FC = () => {
  const history = useHistory();

  return (
    <Row className="mt-8" justify="space-between">
      <S.WrapperNewOfficialStoreHeaderTitle>
        <LeftOutlined onClick={() => history.goBack()} />
        <Title level={3}>Nova Loja Oficial</Title>
      </S.WrapperNewOfficialStoreHeaderTitle>

      <Form.Item style={{ margin: 0 }}>
        <S.CustomButton type="primary" htmlType="submit">
          SALVAR
        </S.CustomButton>
      </Form.Item>
    </Row>
  );
};

export default NewOfficialStoreHeader;
