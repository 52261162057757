import React from 'react';
import { Typography, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { useSeller } from '@hooks/SellerContext';

const { Title } = Typography;
interface PermissionFiltersProps {}

const PermissionFilters: React.FC<PermissionFiltersProps> = () => {
  const { currentSeller, updatePermission } = useSeller();

  const onChangeCheckbox = async (e: CheckboxChangeEvent) => {
    const permission = {
      property: e.target.name,
      value: e.target.checked,
    };

    await updatePermission(currentSeller.sellerCode, permission);
  };

  return (
    <>
      <Title level={5}>Permissões</Title>

      <div>
        <Checkbox
          className="mt-4 mb-0 ml-2"
          name="canMakeToOrder"
          onChange={onChangeCheckbox}
          checked={currentSeller.canMakeToOrder}
        >
          Pedido Sob-encomenda
        </Checkbox>

        <Checkbox
          className="mb-0"
          name="canDeliveryImmediately"
          onChange={onChangeCheckbox}
          checked={currentSeller.canDeliveryImmediately}
        >
          Pedido Pronta-entrega
        </Checkbox>

        <Checkbox
          className="mb-0"
          name="canCheckFinance"
          onChange={onChangeCheckbox}
          checked={currentSeller.canCheckFinance}
        >
          Ver financeiro dos lojistas
        </Checkbox>
      </div>
    </>
  );
};

export default PermissionFilters;
