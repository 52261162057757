import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ExclamationCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Typography } from 'antd';
import { useCustomer } from '@hooks/CustomerContext';
import { isEmpty } from '@utils/is-empty';

const { Title } = Typography;

interface CustomerProfileHeaderProps {}

const CustomerProfileHeader: React.FC<CustomerProfileHeaderProps> = () => {
  const history = useHistory();
  const { currentCustomer, changeCustomerStatus } = useCustomer();
  const [status, setStatus] = useState<string>('');

  useEffect(() => {
    if (!isEmpty(currentCustomer) && currentCustomer.manufacturersShopkeepers.length) {
      setStatus(currentCustomer.manufacturersShopkeepers[0].status);
    }
  }, []);

  const backUrl = () => {
    history.go(-1);
  };

  const rejectCustomer = () => {
    changeCustomerStatus(currentCustomer.code, 'REJECTED');
    backUrl();
  };

  const handleRemove = () => {
    Modal.confirm({
      title: 'Tem certeza que deseja rejeitar esse lojista? Essa ação não pode ser desfeita.',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        rejectCustomer();
      },
      onCancel: () => {},
    });
  };

  return (
    <>
      <Row className="mt-8" justify="space-between">
        <Col className="d-flex" style={{ alignItems: 'center' }}>
          <LeftOutlined onClick={() => backUrl()} style={{ fontSize: '20px', marginRight: '12px' }} />
          <Row style={{ flexDirection: 'column', marginLeft: '12px' }}>
            <Col>
              <Title level={3} style={{ margin: '0' }}>
                Perfil do Lojista
              </Title>
            </Col>
          </Row>
        </Col>
        <Col className="d-flex" style={{ alignItems: 'center' }}>
          {status === 'PENDING' ? (
            <div className="d-flex flex-row" style={{ alignItems: 'baseline' }}>
              <Title level={4} className="mr-5" style={{ color: '#FF0000' }}>
                Pendente
              </Title>
              <Button
                className="w-100"
                style={{ borderRadius: '8px' }}
                type="primary"
                size="large"
                onClick={handleRemove}
              >
                <span className="align-center" style={{ color: 'white' }}>
                  Rejeitar
                </span>
              </Button>
            </div>
          ) : (
            <Title level={4} style={{ color: 'limegreen' }}>
              Aprovado
            </Title>
          )}
        </Col>
      </Row>
    </>
  );
};

export default CustomerProfileHeader;
