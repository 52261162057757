import * as api from './api';

export function getQuestionsByOfficialStoreId(officialStoreId: number): Promise<any> {
  return api.get(`official-store/${officialStoreId}/questions`).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function getOrderDefinedFields(): Promise<any> {
  return api.get(`order_defined_fields`).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function createQuestionByOfficialStoreId(officialStoreId: number, body: any): Promise<any> {
  return api.post(`official-store/${officialStoreId}/questions`, body).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function updateQuestionByOfficialStoreId(officialStoreId: number, questionId: number, body: any): Promise<any> {
  return api.put(`official-store/${officialStoreId}/questions/${questionId}`, body).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function deleteQuestionByIds(officialStoreId: number, questionId: number): Promise<any> {
  return api.remove(`official-store/${officialStoreId}/questions/${questionId}`).catch((error) => {
    return Promise.resolve(error.response);
  });
}
