import React, { useCallback } from 'react';
import { Form, Input } from 'antd';
import { CustomButton } from '@styles/globals';
import { useRepresentative } from '@hooks/RepresentativeContext';

interface FiltersFormProps {
  onCloseDrawer: Function;
}

const FiltersForm: React.FC<FiltersFormProps> = ({ onCloseDrawer }) => {
  const [form] = Form.useForm();

  const { loadRepresentativesPaginated } = useRepresentative();

  const clearFilters = useCallback(() => {
    form.resetFields();
    loadRepresentativesPaginated(0, undefined, true);
  }, [form, loadRepresentativesPaginated]);

  const handleSubmit = useCallback(
    (values: any) => {
      loadRepresentativesPaginated(0, {searchByNameOrCnpj: values.searchByNameOrCnpj});

      onCloseDrawer();
    },
    [loadRepresentativesPaginated, onCloseDrawer],
  );

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit}>
      <Form.Item label="Filtro" name="searchByNameOrCnpj">
        <Input placeholder="CNPJ ou nome..." />
      </Form.Item>

      <CustomButton className="mt-1 w-100" type="primary" htmlType="submit">
        Filtrar
      </CustomButton>

      <CustomButton className="mt-1 w-100" outlined="true" onClick={() => clearFilters()}>
        Reiniciar Filtros
      </CustomButton>
    </Form>
  );
};

export default FiltersForm;
