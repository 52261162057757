export function sortBooleanArray(array: any[], propertyName: string): any[] {
  if (!array.every((obj) => propertyName in obj)) {
    throw new Error(`A propriedade '${propertyName}' não existe em todos os objetos do array.`);
  }
  return array.sort((a, b) => {
    return returnOrder(a[propertyName], b[propertyName]);
  });
}

export function returnOrder(a: boolean, b: boolean) {
  if (a === b) {
    return 0;
  } else if (a) {
    return -1;
  } else {
    return 1;
  }
}
