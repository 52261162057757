import React, { useState } from 'react';

import { Table } from 'antd';
import { useCategory } from '@hooks/CategoryContext';
import SubCategoriesTable from '../SubcategoriesTable';
import './styles.css'

const CategoryContent: React.FC = () => {
  const { categories, loadRequestSubcategory } = useCategory();
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);

  const columns = [
    {
      dataIndex: 'description',
      render: (description: string) => <strong>{description.toUpperCase()}</strong>,
    },
  ];

  const onTableRowExpand = (expanded: any, record: any) => {
    const keys = [];

    if (expanded) {
      keys.push(record.categoryId);
    }

    setExpandedRowKeys(keys);
    loadRequestSubcategory(keys.map(key => key.toString()));
  };

  return (
    <Table
      className="mt-4 table-antd"
      columns={columns}
      showHeader={false}
      dataSource={categories}
      rowClassName="row-dark"
      scroll={{ x: 800 }}
      expandable={{
        expandedRowRender: (record) => <SubCategoriesTable categoryId={record.categoryId} subcategories={record.subcategories} />,
        onExpand: onTableRowExpand,
      }}
      rowKey={(record) => record.categoryId}
      expandedRowKeys={expandedRowKeys}
    />
  );
};

export default CategoryContent;
