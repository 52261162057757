import { useDevolution } from '@hooks/DevolutionsContext';
import { useOrder } from '@hooks/OrdersContext';
import { devolutionColumns } from '@pages/DevolutionPage/components/DevolutionColumns/DevolutionColumns';
import { Table } from 'antd';
import React, { useEffect } from 'react';

const SecondaryAnalysisTab: React.FC = () => {
  const {
    secondaryAnalysisDevolutions,
    loadSecondaryAnalysisDevolutions,
    loadingDevolutions,
    secondaryAnalysisFilters,
    secondaryAnalysisPage,
    secondaryAnalysisTotal,
  } = useDevolution();

  React.useEffect(() => {
    loadSecondaryAnalysisDevolutions(0);
  }, []);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.hasOwnProperty('column')) {
      secondaryAnalysisFilters.field = `${sorter.field}`;
      secondaryAnalysisFilters.order = `${sorter.order}`;
    }

    loadSecondaryAnalysisDevolutions(pagination.current, secondaryAnalysisFilters);
  };

  return (
    <Table
      columns={devolutionColumns}
      dataSource={secondaryAnalysisDevolutions}
      loading={loadingDevolutions}
      pagination={{
        pageSize: 10,
        total: secondaryAnalysisTotal,
        current: secondaryAnalysisPage,
        showSizeChanger: false,
        showQuickJumper: false,
      }}
      scroll={{ x: 800 }}
      rowKey={(devolution) => devolution?.invoiceCode}
      onChange={handleTableChange}
    />
  );
};

export default SecondaryAnalysisTab;
