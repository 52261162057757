import React, { useCallback, useEffect, useState } from 'react';
import { message, Table, Typography } from 'antd';

import { useParameters } from '@hooks/ParametersContext';
import { FullScreenModal } from '@styles/globals';
import { EyeFilled, PlusCircleFilled, SearchOutlined } from '@ant-design/icons';
import DualForm from '@components/DualForm';
import ParametersForm from '../ParametersForm';
import TableFilters from '@components/TableFilters';
import Descriptions from '../Descriptions';
import ParametersPage from '@pages/ParametersPage';

const { Title } = Typography;

const ParametersContent: React.FC = () => {
  const [parametersModalIsVisible, setParametersModalIsVisible] = useState<boolean>(false);
  const [descriptionsModalIsVisible, setDescriptionsModalIsVisible] = useState<boolean>(false);
  const [currentParameter, setCurrentParameter] = useState<any>();
  const [currentParameterItems, setCurrentParameterItems] = useState<any[]>([]);

  const {
    loadParameters,
    parameters,
    createParameterItem,
    parameterItems,
    parametersFilters,
    parametersPage,
    parametersTotal,
    loadParameterItemsByParameterID,
    loadingParameterItems,
    loadingParameters,
    deleteParameterItem,
    loadingDeleteParameterItem,
    loadingCreateParameterItem,
  } = useParameters();

  const closeParametersModal = useCallback(() => {
    setParametersModalIsVisible(false);
  }, [setParametersModalIsVisible]);

  const closeDescriptionsModal = useCallback(() => {
    setDescriptionsModalIsVisible(false);
  }, [setDescriptionsModalIsVisible]);

  const showParametersModal = useCallback(
    async (parameter: any) => {
      setCurrentParameter(parameter);
      loadParameterItemsByParameterID(parameter.parameterId);
      setParametersModalIsVisible(true);
    },
    [setParametersModalIsVisible, setCurrentParameter],
  );

  const showDescriptionsModal = useCallback(
    async (parameter: any) => {
      setCurrentParameter(parameter);
      setDescriptionsModalIsVisible(true);
    },
    [setDescriptionsModalIsVisible, setCurrentParameter],
  );

  useEffect(() => {
    adaptParameterItemsList();
  }, [parameterItems]);

  const adaptParameterItemsList = () => {
    const list = parameterItems.map((item) => ({
      label1: 'Variante',
      item1: item.variant,
      label2: 'Valor',
      item2: item.value,
    }));

    setCurrentParameterItems(list);
  };

  const columns = [
    {
      title: 'Código',
      dataIndex: 'code',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pelo Código'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.code ? record.code.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
      sorter: true,
    },
    {
      title: 'Desc. Parâmetro',
      dataIndex: 'description',
      ellipsis: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pela Desc. Parâmetro'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.description ? record.description.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
      sorter: true,
    },
    {
      title: 'Desc. Utilidade',
      dataIndex: 'utility',
      ellipsis: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pela Desc. Utilidade'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.utility ? record.utility.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
      sorter: true,
    },
    {
      title: 'Desc. Funcionalidade',
      dataIndex: 'functionality',
      ellipsis: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pela Desc. Funcionalidade'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.functionality ? record.functionality.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
      sorter: true,
    },
    {
      title: 'Desc. Variante',
      dataIndex: 'variant',
      ellipsis: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pela Desc. Variante'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.variant ? record.variant.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
      sorter: true,
    },
    {
      title: 'Desc. Valor',
      dataIndex: 'value',
      ellipsis: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pela Desc. Valor'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.value ? record.value.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
      sorter: true,
    },
    {
      title: 'Ações',
      width: '140px',
      align: 'center' as 'center',
      render: (record: any) => (
        <>
          <EyeFilled
            className="mr-3"
            style={{ fontSize: '18px', color: 'var(--primary-color)' }}
            onClick={() => showDescriptionsModal(record)}
          />
          <PlusCircleFilled
            style={{ fontSize: '18px', color: 'var(--primary-color)' }}
            onClick={() => showParametersModal(record)}
          />
        </>
      ),
    },
  ];

  const onAddParameter = useCallback(
    (values: any) => {
      if (values.value) {
        const variant = values.variant ? values.variant : '0';

        if (parameterItems.find((param) => param.variant === variant && param.value === values.value))
          return message.warning('Opção de Parâmetro já foi informada anteriormente!');

        createParameterItem(currentParameter.parameterId, { ...values, variant });
      }
    },
    [currentParameter, parameterItems],
  );

  const onDeleteParameter = useCallback(
    (variant: string, value: string) => {
      const index = parameterItems.findIndex((param) => param.variant === variant && param.value === value);

      if (index !== -1) {
        deleteParameterItem(parameterItems[index].parameterId, parameterItems[index].sequence);
      }
    },
    [parameterItems],
  );

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.hasOwnProperty('column')) {
      parametersFilters.field = `${sorter.field}`;
      parametersFilters.order = `${sorter.order}`;
    }

    loadParameters(pagination.current, parametersFilters);
  };

  return (
    <>
      <Table
        className="mt-4"
        columns={columns}
        dataSource={parameters}
        scroll={{ x: 1200 }}
        pagination={{
          current: parametersPage,
          total: parametersTotal,
          pageSize: 10,
          showSizeChanger: false,
        }}
        onChange={handleTableChange}
        loading={loadingParameters}
      />

      <FullScreenModal
        destroyOnClose={true}
        footer={null}
        forceRender={false}
        visible={parametersModalIsVisible}
        onCancel={closeParametersModal}
        onOk={closeParametersModal}
        bodyStyle={{ width: '100%' }}
      >
        <DualForm
          title="Parâmetros"
          loading={loadingParameterItems || loadingDeleteParameterItem || loadingCreateParameterItem}
          form={<ParametersForm onAddParameter={onAddParameter} parameter={currentParameter} />}
          list={currentParameterItems}
          onDelete={onDeleteParameter}
        />
      </FullScreenModal>

      <FullScreenModal
        destroyOnClose={true}
        footer={null}
        forceRender={false}
        visible={descriptionsModalIsVisible}
        onCancel={closeDescriptionsModal}
        onOk={closeDescriptionsModal}
        bodyStyle={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Descriptions parameter={currentParameter} />
      </FullScreenModal>
    </>
  );
};

export default ParametersContent;
