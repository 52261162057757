import { Button, message, Table, Typography } from 'antd';

import { Order } from '@models/Order';
import { useOrder } from '@hooks/OrdersContext';
import LoadingComponents from '@components/LoadingComponents';

const { Text } = Typography;

interface InvoicesProps {
  order: Order;
}

const Invoices: React.FC<InvoicesProps> = ({ order }) => {
  const { loadingOrderDetail } = useOrder();

  if (loadingOrderDetail) return <LoadingComponents />;

  const columns = [
    {
      title: 'Transportadora',
      dataIndex: 'shippingCompanyName',
    },
    {
      title: 'Peso Bruto',
      dataIndex: 'grossWeight',
      align: 'right' as 'right',
      render: (value: any) => <Text>{value} KG</Text>,
    },
    {
      title: 'Peso Líquido',
      dataIndex: 'netWeight',
      align: 'right' as 'right',
      render: (value: any) => <Text>{value} KG</Text>,
    },
    {
      title: 'Cód. Rastreio',
      dataIndex: 'trackingCode',
      render: (value: any) => {
        if (value)
          return (
            <Button
              onClick={() => {
                navigator.clipboard.writeText(value);
                message.success('Texto copiado para a área de transferência.');
              }}
            >
              {value}
            </Button>
          );
      },
    },
    {
      title: 'Chave Acesso',
      dataIndex: 'accessKey',
      render: (value: any) => {
        return (
          <Button
            onClick={() => {
              navigator.clipboard.writeText(value);
              message.success('Texto copiado para a área de transferência.');
            }}
          >
            {value}
          </Button>
        );
      },
    },
  ];

  return <Table columns={columns} dataSource={order.invoices} pagination={false} 
  scroll={{ x: 800 }}
  />;
};

export default Invoices;
