import { DeleteOutlined } from '@ant-design/icons';
import { useImages } from '@hooks/ImagesContext';
import { ImageProps } from '@models/ImageProps';
import { Image, Progress, Table } from 'antd';

const UploadImagesContent: React.FC = () => {
  const { fileList, removeImage } = useImages();

  const columns = [
    {
      width: '80px',
      align: 'center' as 'center',
      render: (value: any) => !value.error && <Image style={{ maxHeight: '80px' }} src={value.url} />,
    },
    { dataIndex: 'name', title: 'Nome do arquivo' },
    { dataIndex: 'reference', title: 'Referência' },
    { dataIndex: 'color', title: 'Cor' },
    { dataIndex: 'sequence', title: 'Sequência' },
    {
      title: 'Erro?',
      align: 'center' as 'center',
      render: (img: ImageProps) => {
        if (img.uploaded) {
          return img.error ? <span>Sim</span> : <span>Não</span>;
        }
      },
    },
    {
      title: 'Enviado',
      render: (img: ImageProps) => {
        if (img.uploaded) {
          return img.error ? <Progress percent={100} status="exception" /> : <Progress percent={100} />;
        }
      },
    },
    {
      render: (img: ImageProps) =>
        !img.uploaded && (
          <DeleteOutlined style={{ cursor: 'pointer', fontSize: '18px' }} onClick={() => removeImage(img)} />
        ),
      align: 'right' as 'right',
    },
  ];
  return <Table className="mt-4" dataSource={fileList} columns={columns} />;
};

export default UploadImagesContent;
