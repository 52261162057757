import { Product } from '@models/Product';
import { Reference } from '@models/Reference';
import { ReferenceFilters } from '@models/ReferenceFilters';
import { ReferencePack } from '@models/ReferencePack';
import * as ReferenceService from '@services/reference';
import { INITIAL_PAGE, REFERENCE_PAGINATION_SIZE } from '@utils/constants';
import { message } from 'antd';
import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react';

interface ReferenceState {
  references: Reference[];
  searchReferenceResult: Reference[];
  setSearchReferenceResult: Function;
  referencesFilters: any;
  referencesCurrentPage: number;
  referencesTotalPages: number;
  loadingReferences: boolean;
  loadReferences: Function;
  loadReferencesByCollectionId: Function;
  referenceIsActive: boolean;
  createReferenceWithIntegration: Function;

  referencePack: ReferencePack;
  loadingReferencePack: boolean;
  loadReferencePack: Function;

  updateReferencePack: Function;

  updateReferenceStatus: Function;

  loadingUpdate: boolean;

  referenceProducts: Product[];
  loadingReferenceProducts: boolean;
  loadReferenceProducts: Function;

  searchReferences: Function;

  currentReference: Reference | undefined;
  loadReferenceDetails: Function;
}

interface ReferenceProviderProps {
  children: ReactNode;
}

export const ReferenceContext = createContext<ReferenceState>({} as ReferenceState);

const ReferenceProvider: React.FC<ReferenceProviderProps> = ({ children }) => {
  const [references, setReferences] = useState<Reference[]>([]);
  const [currentReference, setCurrentReferences] = useState<Reference>();
  const [searchReferenceResult, setSearchReferenceResult] = useState<Reference[]>([]);
  const [referencesFilters, setReferenceFilters] = useState<any>({} as any);
  const [referencesCurrentPage, setReferencesCurrentPage] = useState<number>(INITIAL_PAGE);
  const [referencesTotalPages, setReferencesTotalPages] = useState<number>(0);
  const [loadingReferences, setLoadingReferences] = useState<boolean>(false);
  const [referenceIsActive, setReferenceIsActive] = useState<boolean>(true);

  const [referenceProducts, setReferenceProducts] = useState<Product[]>([]);
  const [loadingReferenceProducts, setLoadingReferenceProducts] = useState<boolean>(false);

  const [referencePack, setReferencePack] = useState<ReferencePack>({} as ReferencePack);
  const [loadingReferencePack, setLoadingReferencePack] = useState<boolean>(false);

  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

  const loadReferences = async (page: number, isActive: boolean, filters?: ReferenceFilters) => {
    setLoadingReferences(true);
    setReferencesCurrentPage(page === 0 ? 1 : page);
    setReferenceIsActive(isActive);
    if (filters) setReferenceFilters(filters);
    else setReferenceFilters({});

    try {
      const { data: _references } = await ReferenceService.get({
        page: page ? page - 1 : 0,
        pageSize: REFERENCE_PAGINATION_SIZE,
        isActive,
        ...(filters && { ...filters }),
      });
      setReferences(_references.data);
      setReferencesTotalPages(_references.total);
    } catch {
      message.error('Erro ao carregar Referências!');
    } finally {
      setLoadingReferences(false);
    }
  };

  const loadReferencesByCollectionId = async (page: number, collectionId: number) => {
    setLoadingReferences(true);
    setReferencesCurrentPage(page);

    try {
      const { data: _references } = await ReferenceService.getByCollectionId(collectionId, {
        page,
        pageSize: REFERENCE_PAGINATION_SIZE,
      });
      setReferences(_references.data);
      setReferencesTotalPages(_references.total);
    } catch {
      message.error('Erro ao carregar Referências!');
    } finally {
      setLoadingReferences(false);
    }
  };

  const loadReferencePack = useCallback(
    async (referenceCode: string) => {
      setLoadingReferencePack(true);

      try {
        const { data: _pack } = await ReferenceService.getPack(referenceCode);
        setReferencePack(_pack);
      } catch {
        message.error('Erro ao carregar informações do Pack do Produto!');
      } finally {
        setLoadingReferencePack(false);
      }
    },
    [setReferencePack, setLoadingReferencePack],
  );

  const loadReferenceProducts = useCallback(
    async (referenceCode: string) => {
      setLoadingReferenceProducts(true);

      try {
        const { data: _products } = await ReferenceService.getReferenceProducts(referenceCode);
        setReferenceProducts(_products);
      } catch {
        message.error('Erro ao carregar produtos da Referência!');
      } finally {
        setLoadingReferenceProducts(false);
      }
    },
    [setReferenceProducts, setLoadingReferenceProducts],
  );

  const updateReferencePack = useCallback(
    async (referenceCode: string, body: any) => {
      setLoadingReferencePack(true);

      try {
        const { data: _reference } = await ReferenceService.updatePack(referenceCode, body);

        if (_reference) {
          const index = references.findIndex((ref) => ref.ReferenceCode === referenceCode);

          if (index !== -1) {
            references[index] = { ...references[index], ..._reference };
            setReferences([...references]);
          }
        }

        message.success('Pack de produtos atualizado com sucesso!');
      } catch {
        message.error('Erro ao atualizar informações de Pack dos produtos!');
      } finally {
        setLoadingReferencePack(false);
      }
    },
    [references, setReferences, setReferencePack, setLoadingReferencePack],
  );

  const createReferenceWithIntegration = useCallback(
    async (body: Reference) => {
      setLoadingReferencePack(true);

      try {
        const { data: _reference } = await ReferenceService.createReferenceWithIntegration(body);

        if (_reference) {
          await loadReferences(0, true);
        }

        message.success('Produtos cadastrados com sucesso!');
      } catch (e) {
        message.error('Erro ao cadastrar produtos!');
        throw e;
      } finally {
        setLoadingReferencePack(false);
      }
    },
    [references, setReferences],
  );

  const updateReferenceStatus = async (referenceCode: string, isActive: boolean) => {
    setLoadingUpdate(true);

    try {
      const { data: _reference } = await ReferenceService.updateReferenceStatus(referenceCode, isActive);

      if (_reference) {
        const index = references.findIndex((ref) => ref.ReferenceCode === referenceCode);

        if (index !== -1) {
          references.splice(index, 1);
          setReferences([...references]);
        }
      }

      message.success('Situação alterada com sucesso!');
    } catch {
      message.error('Erro ao alterar situação da Referência!');
    } finally {
      setLoadingUpdate(false);
    }
  };

  const searchReferences = async (body: any) => {
    setLoadingReferences(true);

    try {
      const { data: _references } = await ReferenceService.searchReference(body);

      if (_references) {
        setSearchReferenceResult([..._references]);
      }
    } catch {
      message.error('Erro ao buscar referências!');
    } finally {
      setLoadingReferences(false);
    }
  };

  const loadReferenceDetails = async (referenceCode: string) => {
    setLoadingReferences(true);

    try {
      const { data: _reference } = await ReferenceService.getReferenceDetails(referenceCode);

      if (_reference) {
        setCurrentReferences(_reference);
      }
    } catch {
      message.error('Erro ao buscar referência!');
    } finally {
      setLoadingReferences(false);
    }
  };

  return (
    <ReferenceContext.Provider
      value={{
        references,
        searchReferenceResult,
        setSearchReferenceResult,
        referencesFilters,
        referencesCurrentPage,
        referencesTotalPages,
        loadingReferences,
        loadReferences,
        loadReferencesByCollectionId,
        referenceIsActive,

        createReferenceWithIntegration,

        referencePack,
        loadingReferencePack,
        loadReferencePack,

        updateReferencePack,

        updateReferenceStatus,

        loadingUpdate,

        referenceProducts,
        loadingReferenceProducts,
        loadReferenceProducts,

        searchReferences,

        currentReference,
        loadReferenceDetails,
      }}
    >
      {children}
    </ReferenceContext.Provider>
  );
};

const useReference = () => {
  return useContext(ReferenceContext);
};

export { ReferenceProvider, useReference };
