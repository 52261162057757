import { Link } from '@models/Link';
import { message } from 'antd';
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import * as LinkService from '@services/link';

interface LinkState {
  links: Link[];
  total: number;
  currentPage: number;
  loadLinks: (
    linkFilter: LinkService.LinkFilter,
    page?: any,
    pageSize?: any,
    orderBy?: string,
    direction?: 'ASC' | 'DESC',
  ) => Promise<void>;
  loading: boolean;
}

interface LinkProviderProps {
  children: ReactNode;
}

export const LinkContext = createContext<LinkState>({} as LinkState);

const LinkProvider: React.FC<LinkProviderProps> = ({ children }) => {
  const [links, setLinks] = useState<Link[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);

  const loadLinks = useCallback(
    async (
      linkFilter: LinkService.LinkFilter,
      page = 1,
      pageSize = 10,
      orderBy: string = 'linkTitle',
      direction: 'ASC' | 'DESC' = 'ASC',
    ) => {
      setLoading(true);
      setCurrentPage(page === 0 ? 1 : page);

      try {
        const {
          data: { data: fetchedLinks, total },
        } = await LinkService.get(linkFilter, page ? page - 1 : 0, pageSize, orderBy, direction);
        setLinks(fetchedLinks);
        setTotal(total);
      } catch (e: any) {
        console.warn('erro ao buscar links [loadLinks]', e);
        if (e.response && e.response.status === 404) {
          message.info('Você não possui links!');
        }
      } finally {
        setLoading(false);
      }
    },
    [links],
  );

  const linkContextValue = {
    loading,
    links,
    total,
    currentPage,
    loadLinks,
  };

  return <LinkContext.Provider value={linkContextValue}>{children}</LinkContext.Provider>;
};

const useLink = () => {
  return useContext(LinkContext);
};

export { LinkProvider, useLink };
