import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';

import CollectionDetailsContent from './components/CollectionDetailsContent';
import CollectionDetailsHeader from './components/CollectionDetailsHeader';
import { useReference } from '@hooks/ReferenceContext';


interface MatchParams {
  id: string;
}

const CollectionDetailsPage: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();
  const { loadReferences } = useReference();

  useEffect(() => {
    if (params.id) loadReferences(0, true, { collectionId: +params.id });
  }, [params.id]);

  return (
    <>
      <CollectionDetailsHeader collectionId={+params.id} />
      <CollectionDetailsContent collectionId={+params.id} />
    </>
  );
};

export default CollectionDetailsPage;
