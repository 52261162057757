import { Visits } from '@models/Visits';
import { DatePicker, Form, Input, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';

interface VisitsFilterFormProps {
  visits: Visits[];
}

const { RangePicker } = DatePicker;

const VisitsFilterForm: React.FC<VisitsFilterFormProps> = ({ visits }) => {
  const brandsArr = visits.map((visit) => {
    return visit.brand.brandName;
  });

  const brandies = brandsArr.filter((elem, index, arr) => index === arr.indexOf(elem));

  return (
    <>
      <Form.Item className="mb-3" label="Representante" name="representative">
        <Input placeholder="Informe o CNPJ ou o nome do representante" />
      </Form.Item>

      <Form.Item className="mb-3" label="Lojista" name="legalPerson">
        <Input placeholder="Informe o lojista ou o CNPJ do lojista" />
      </Form.Item>

      <Form.Item className="mb-3" label="Marca" name="brand">
        <Select placeholder="Selecione a Marca">
          {brandies.map((brand) => (
            <Select.Option key={brand} value={brand}>
              {brand}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item className="mb-3" label="Data da Visita" name="dates">
        <RangePicker size="large" className="w-100" format="DD/MM/YYYY" />
      </Form.Item>

      <Form.Item className="mb-3" label="CEP da Visita" name="addressCep">
        <MaskedInput mask={'*****-***'} />
      </Form.Item>

      <Form.Item className="mb-3" label="Estado da Visita" name="addressState">
        <Input placeholder="Selecione o estado" />
      </Form.Item>

      <Form.Item className="mb-3" label="Cidade da Visita" name="addressCity">
        <Input placeholder="Informe o nome da cidade" />
      </Form.Item>
    </>
  );
};

export default VisitsFilterForm;
