import { CustomButton } from '@styles/globals';
import { Col, Form, Row, Space, Switch, Typography } from 'antd';
import React from 'react';

import { EmailTypes, RepresentativeEmail } from '@components/RepresentativeForm/models/representative-form-models';
import Select from 'antd/lib/select';
import { v4 } from 'uuid';
import { CustomInput } from './styles';

const { Title } = Typography;

interface AddRepresentativeEmailFormModalProps {
  onCloseModal: Function;
  addEmail: Function;
}

const AddRepresentativeEmailFormModal: React.FC<AddRepresentativeEmailFormModalProps> = ({
  onCloseModal,
  addEmail,
}) => {
  const [emailsForm] = Form.useForm();

  const [isDefault, setIsDefault] = React.useState<boolean>(true);

  React.useEffect(() => {
    emailsForm.setFields([
      { name: 'email', value: null },
      { name: 'typeCode', value: null },
    ]);
  }, []);

  const finishAddEmail = async () => {
    const email: RepresentativeEmail = await emailsForm.validateFields();

    email.isDefault = isDefault;
    email.typeName = EmailTypes.filter((e) => e.typeCode === email.typeCode)[0]?.typeName;
    email.key = v4();

    addEmail(email);
    onCloseModal();
  };

  return (
    <>
      <Form
        form={emailsForm}
        className="d-flex"
        style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}
      >
        <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Col span={12} style={{ paddingRight: 15 }}>
            <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
              E-mail <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
            </Title>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Informe o e-mail',
                },
              ]}
            >
              <CustomInput style={{ width: '100%' }} placeholder="E-mail" />
            </Form.Item>
          </Col>

          <Col span={12} style={{ paddingRight: 15 }}>
            <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
              Tipo <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
            </Title>
            <Form.Item
              name="typeCode"
              rules={[
                {
                  required: true,
                  message: 'Informe o tipo do e-mail',
                },
              ]}
            >
              <Select
                style={{ height: '48px', borderRadius: '8px' }}
                placeholder="Tipo"
                size="large"
                showSearch
                allowClear
              >
                {EmailTypes?.length &&
                  EmailTypes.map((email: { typeCode: number; typeName: string }) => (
                    <Select.Option key={email.typeCode} value={email.typeCode}>
                      {email.typeName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Col span={24} style={{ marginBottom: '25px' }}>
            <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
              E-mail padrão <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
            </Title>
            <Space direction="horizontal">
              <div>
                <span>Sim: </span>
                <Switch checked={isDefault} onChange={() => setIsDefault(true)} />
              </div>
              <div>
                <span className="ml-4">Não: </span>
                <Switch
                  checked={!isDefault}
                  onChange={() => {
                    setIsDefault(false);
                  }}
                />
              </div>
            </Space>
          </Col>
        </Row>

        <CustomButton type="primary" style={{ marginTop: 5, width: '100%' }} onClick={finishAddEmail}>
          Adicionar e-mail
        </CustomButton>
      </Form>
    </>
  );
};

export default AddRepresentativeEmailFormModal;
