export interface SharedData {
  gridCode?: string;
}

export interface RepresentativeEmail {
  key?: string;
  typeCode: number;
  typeName: string;
  email: string;
  isDefault: boolean;
}

export interface RepresentativePhone {
  key?: string;
  typeCode: number;
  typeName: string;
  number: string;
  isDefault: boolean;
  branchLine: number;
}

export const EmailTypes = [
  {
    typeCode: 1,
    typeName: 'ECOMERCIAL',
  },
  {
    typeCode: 2,
    typeName: 'PARTICULAR',
  },
  {
    typeCode: 3,
    typeName: 'COMERCIAL',
  },
  {
    typeCode: 4,
    typeName: 'NFE',
  },
];

export const PhoneTypes = [
  {
    typeCode: 7,
    typeName: 'RESIDENCIAL',
  },
  {
    typeCode: 3,
    typeName: 'FAX',
  },
  {
    typeCode: 9,
    typeName: 'ESCRITORIO',
  },
  {
    typeCode: 6,
    typeName: 'SHOW ROOM - REPRES',
  },
  {
    typeCode: 10,
    typeName: 'WHATSAPP',
  },
  {
    typeCode: 2,
    typeName: 'TELEFONE SECUNDARIO',
  },
  {
    typeCode: 1,
    typeName: 'TELEFONE COMERCIAL',
  },
  {
    typeCode: 4,
    typeName: 'CELULAR',
  },
  {
    typeCode: 8,
    typeName: 'CELULAR EMPRESA',
  },
  {
    typeCode: 5,
    typeName: 'OUTROS',
  },
];
