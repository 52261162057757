import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Row, Typography, Table, Collapse } from 'antd';

import { Collection, CollectionClassification } from '@models/Collection';

import './styles.css';
import { groupBy } from 'lodash';
import { ColumnsType } from 'antd/lib/table';

const { Title, Text } = Typography;
const { Panel } = Collapse;

const columns: ColumnsType<CollectionClassification> = [
  {
    title: 'Tipo Classificação',
    dataIndex: 'typeCode',
    key: 'typeCode',
    render: (_text: string, classificationsList: any): JSX.Element => {
      return <strong style={{ color: 'gray' }}>{classificationsList[0].typeCode}</strong>;
    },
    sorter: (typeCodeA: CollectionClassification, typeCodeB: CollectionClassification) => {
      return typeCodeA.typeCode - typeCodeB.typeCode;
    },
  },
  {
    title: 'Classificação',
    dataIndex: 'code',
    key: 'code',
    render: (_text: string, classificationsList: any): JSX.Element => {
      return (
        <strong style={{ color: 'gray' }}>
          {classificationsList.map((classification: CollectionClassification, i: number) =>
            i === 0 ? classification.code : ' | ' + classification.code,
          )}
        </strong>
      );
    },
    sorter: (classificationA: CollectionClassification, classificationB: CollectionClassification) => {
      return classificationA.code.localeCompare(classificationB.code);
    },
  },
];

const cards = [
  { title: 'Lojista', start: 'dateCostumerStart', end: 'dateCostumerEnd' },
  { title: 'Lojista Premium', start: 'dateCostumerPremiumStart', end: 'dateCostumerPremiumEnd' },
  { title: 'Vendedor', start: 'dateSellerStart', end: 'dateSellerEnd' },
  { title: 'Vendedor Premium', start: 'dateSellerPremiumStart', end: 'dateSellerPremiumEnd' },
  { title: 'Representante', start: 'dateRepresentativeStart', end: 'dateRepresentativeEnd' },
  { title: 'Representante Premium', start: 'dateRepresentativePremiumStart', end: 'dateRepresentativePremiumEnd' },
];

interface TableExtraInfosProps {
  collection: Collection;
}

const TableExtraInfos: React.FC<TableExtraInfosProps> = ({ collection }) => {
  const [classifications, setClassifications] = useState<Array<any>>([]);

  useEffect(() => {
    setClassifications(Object.values(groupBy(collection.classifications, (classification) => classification.typeCode)));
  }, []);

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          {cards.map((card, _i) => (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={4}
              xxl={4}
              className={`d-flex align-center justify-center gutter-row ${
                card.title === 'Vendedor' ? 'has-divider-before' : ''
              } ${card.title === 'Vendedor Premium' ? 'has-divider-after' : ''}`}
              style={{ flexDirection: 'column' }}
              key={card.title}
            >
              <Title level={5} style={{ textAlign: 'center' }}>
                {card.title}
              </Title>
              {collection && Object(collection)[card.start] && Object(collection)[card.end] ? (
                <Text style={{ textAlign: 'center' }}>
                  {`${moment(Object(collection)[card.start]).format('DD/MM/YYYY')} até ${moment(
                    Object(collection)[card.end],
                  ).format('DD/MM/YYYY')}`}
                </Text>
              ) : (
                <Text>Não informado</Text>
              )}
            </Col>
          ))}
        </Row>

        {collection.classifications && collection.classifications.length ? (
          <Row align="middle" justify="center">
            <Col span={24}>
              <Collapse className="mt-4" ghost>
                <Panel header="Exibir classificações" key="1" style={{ fontWeight: 'bold' }}>
                  <Table
                    columns={columns}
                    dataSource={classifications}
                    pagination={{
                      hideOnSinglePage: true,
                    }}
                    className="table-header"
                    rowKey={(record) => record.typeCode}
                    style={{ marginTop: '0', fontWeight: 'normal' }}
                    scroll={{ x: 800 }}
                  />
                </Panel>
              </Collapse>
            </Col>
          </Row>
        ) : null}
      </Col>
    </Row>
  );
};

export default TableExtraInfos;
