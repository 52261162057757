import styled from 'styled-components';

export const ScrollContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding: 8px;

  &::-webkit-scrollbar {
    width: 8px;
    background: #f5f5f5;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-appearance: none;
    background: var(--primary-color);
    border-radius: 8px;
  }
`;

interface CustomCardContainerProps {
  floating: 'right' | 'left';
}

export const CustomCardContainer = styled.div<CustomCardContainerProps>`
  width: 100%;
  height: 420px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  float: ${(props) => (props.floating === 'right' ? 'right' : 'left')};
`;

export const CustomCard = styled.div`
  width: 100%;
  display: flex;
  min-height: 180px;
  padding: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  cursor: pointer;
  margin-bottom: 16px;

  .card-col {
    display: none;
  }

  &:hover {
    .card-row > :nth-child(-n + 5) {
      display: none;
    }

    .card-col {
      display: flex !important;
      /* min-height: 180px; */
      align-items: center;
      justify-content: center;
      color: var(--primary-color);
    }
  }
`;
