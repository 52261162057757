import React, { useEffect } from 'react';
import { Button, Col, Form, message, Radio, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { PaymentType } from '@models/PaymentType';
import { useOfficialStore } from '@hooks/OfficialStoreContext';

interface PaymentTypeFormProps {
  paymentType: PaymentType;
}

const PaymentTypeForm: React.FC<PaymentTypeFormProps> = ({ paymentType }) => {
  const [form] = useForm();
  const { updateOfficialStorePaymentType } = useOfficialStore();

  useEffect(() => {
    form.setFieldsValue({
      paymentTypeOption: Object.keys(paymentType).find((i) => Object(paymentType)[i] === true), //encontra o primeiro valor true e assume
    });
  }, [paymentType]);

  const makeFalsyPaymentTypeObject = () => ({
    isAccept: false,
    isAcceptWithGuide: false,
    isAcceptWithRepresentative: false,
    isAcceptWithGuideOrRepresentative: false,
    isNotAccept: false,
    isNotAcceptWithGuide: false,
    isNotAcceptWithRepresentative: false,
    isNotAcceptWithGuideOrRepresentative: false,
  });

  const onSubmit = async (value: any) => {
    const falsyPaymentType = makeFalsyPaymentTypeObject();

    Object(falsyPaymentType)[value.paymentTypeOption] = true;

    try {
      await updateOfficialStorePaymentType({ ...paymentType, ...falsyPaymentType });
      message.success('Forma de Pagamento atualizada com sucesso!');
    } catch {
      message.success('Erro ao atualizar Forma de Pagamento!');
    }
  };

  return (
    <Form
      className="d-flex"
      form={form}
      onFinish={onSubmit}
      style={{ backgroundColor: 'white', padding: '20px', borderRadius: '6px', flexDirection: 'column' }}
    >
      <Form.Item name="paymentTypeOption">
        <Radio.Group>
          <Row className="d-flex">
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              className="d-flex mt-4"
              style={{ flexDirection: 'column' }}
            >
              <Radio value="isNotAccept">Não aceita</Radio>
              <Radio value="isNotAcceptWithGuide">Não aceita quando o lojista possui Guia</Radio>
              <Radio value="isNotAcceptWithRepresentative">Não aceita quando o lojista possui Representante</Radio>
              <Radio value="isNotAcceptWithGuideOrRepresentative">
                Não aceita quando o lojista possui Guia ou Representante
              </Radio>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              className="d-flex mt-4"
              style={{ flexDirection: 'column' }}
            >
              <Radio value="isAccept">Aceita</Radio>
              <Radio value="isAcceptWithGuide">Aceita quando o lojista possui Guia</Radio>
              <Radio value="isAcceptWithRepresentative">Aceita quando o lojista possui Representante</Radio>
              <Radio value="isAcceptWithGuideOrRepresentative">
                Aceita quando o lojista possui Guia ou Representante
              </Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Form.Item>

      <Button type="primary" htmlType="submit">
        Atualizar
      </Button>
    </Form>
  );
};

export default PaymentTypeForm;
