import { Stock } from '@models/Stock';
import { CustomButton } from '@styles/globals';
import { splitArrayIntoChunks } from '@utils/split-array-into-chunks';
import { Checkbox, Col, Form, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useEffect } from 'react';

interface NewStockStepConfigProps {
  newStock?: Stock;
  previuosStep: () => void;
  add: () => void;
  handleAddStockField: (values: Partial<Stock>) => void;
}

const formItens = [
  {
    label: 'Estoque',
    propertyName: 'estoque',
  },
  {
    label: 'Ped. Venda',
    propertyName: 'pedVenda',
  },
  {
    label: 'Trans. Ent.',
    propertyName: 'transEnt',
  },
  {
    label: 'Trans. Saída.',
    propertyName: 'transSaida',
  },
  {
    label: 'Ord. Prod. Plan.',
    propertyName: 'ordProdPlan',
  },
  {
    label: 'Ord. Prod. em And.',
    propertyName: 'ordProdEmAnd',
  },
  {
    label: 'Ord. Prod. Ag. Lib.',
    propertyName: 'ordProdAgLib',
  },
  {
    label: 'Ord. de Compra',
    propertyName: 'ordCompra',
  },
];
type TypeFormItem = typeof formItens[number];
const formatedPairItemsToForm = splitArrayIntoChunks<TypeFormItem>(formItens, 3);

const NewStockStepConfig: React.FC<NewStockStepConfigProps> = ({
  previuosStep,
  add,
  handleAddStockField,
  newStock,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      estoque: newStock?.estoque,
      pedVenda: newStock?.pedVenda,
      transEnt: newStock?.transEnt,
      transSaida: newStock?.transSaida,
      ordProdPlan: newStock?.ordProdPlan,
      ordProdEmAnd: newStock?.ordProdEmAnd,
      ordProdAgLib: newStock?.ordProdAgLib,
      ordCompra: newStock?.ordCompra,
    });
  }, [newStock]);

  const handleChange = (propertyName: string, event: CheckboxChangeEvent) => {
    handleAddStockField({ [propertyName]: event.target.checked });
  };

  return (
    <>
      <Form
        layout="vertical"
        className="d-flex justify-center align-center flex-column w-100"
        form={form}
        onFinish={add}
      >
        <div className="question-panel-wrapper">
          {formatedPairItemsToForm.map((pair) => {
            return (
              <Row key={JSON.stringify(pair)} gutter={[16, 16]}>
                {pair.map(({ label, propertyName }) => {
                  return (
                    <Col key={label} xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                      <Form.Item name={propertyName} valuePropName="checked">
                        <Checkbox onChange={(e) => handleChange(propertyName, e)}>{label}</Checkbox>
                      </Form.Item>
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </div>
        <Row className="w-100">
          <Col span={12} className="pr-1">
            <CustomButton
              style={{ backgroundColor: 'var(--primary-color)', color: 'var(--support-color)' }}
              onClick={() => previuosStep()}
            >
              Voltar
            </CustomButton>
          </Col>
          <Col span={12} className="pl-1">
            <CustomButton
              style={{ backgroundColor: 'var(--primary-color)', color: 'var(--support-color)' }}
              htmlType="submit"
            >
              Adicionar
            </CustomButton>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default NewStockStepConfig;
