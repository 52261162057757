import { PriceTableProduct } from '@models/PriceTableProduct';
import { Table, Typography } from 'antd';

const { Text } = Typography;

interface TabProductProps {
  priceTableProducts: PriceTableProduct[];
}

const TabProduct: React.FC<TabProductProps> = ({ priceTableProducts }) => {
  const columns = [
    {
      title: 'Código',
      dataIndex: 'productCode',
      width: '20%',
      align: 'center' as 'center',
      sorter: (productA: any, productB: any) => productA.productCode - productB.productCode,
    },
    {
      title: 'Descrição',
      dataIndex: 'productName',
      sorter: (productA: any, productB: any) => productA.productName.trim().localeCompare(productB.productName.trim()),
    },
    {
      title: 'Referência',
      dataIndex: 'referenceCode',
      sorter: (productA: any, productB: any) =>
        productA.referenceCode.trim().localeCompare(productB.referenceCode.trim()),
    },
    {
      title: 'Cor',
      dataIndex: 'colorName',
      sorter: (productA: any, productB: any) => productA.colorName.trim().localeCompare(productB.colorName.trim()),
    },
    {
      title: 'Tamanho',
      dataIndex: 'sizeName',
      align: 'center' as 'center',
      sorter: (productA: any, productB: any) => productA.sizeName.trim().localeCompare(productB.sizeName.trim()),
    },
    {
      title: 'Variação (%)',
      dataIndex: 'variationPercentage',
      align: 'right' as 'right',
      render: (value: any) => (value ? <Text>{value}</Text> : <Text>0</Text>),
      sorter: (productA: any, productB: any) => productA.variationPercentage - productB.variationPercentage,
    },
    {
      title: 'Variação (Valor)',
      dataIndex: 'variationValue',
      align: 'right' as 'right',
      render: (value: any) => (value ? <Text>{value.toFixed(2)}</Text> : <Text>0,00</Text>),
      sorter: (productA: any, productB: any) => productA.variationValue - productB.variationValue,
    },
  ];

  return (
    <Table
      scroll={{ x: 800 }}
      columns={columns}
      dataSource={priceTableProducts}
      pagination={{ pageSize: 4, hideOnSinglePage: true }}
    />
  );
};

export default TabProduct;
