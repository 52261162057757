export function splitArrayIntoChunks<T>(array: T[], chunkSize = 2): T[][] {
  const chunks = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }

  return chunks;
}
