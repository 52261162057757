import { Order } from '@models/Order';
import { Row, Col, Typography } from 'antd';

import { useOrder } from '@hooks/OrdersContext';
import LoadingComponents from '@components/LoadingComponents';
import { formatCnpj, formatCpf } from '@utils/index';
import './styles.css';

const { Text } = Typography;

interface DetailProps {
  order: Order;
}

const Detail: React.FC<DetailProps> = ({ order }) => {
  const { loadingOrderDetail } = useOrder();

  if (loadingOrderDetail) return <LoadingComponents />;

  return (
    <Row justify="space-around" gutter={[32, 24]}>
      <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8} className="gutter-row">
        {/* TODO */}
        {/* <div className="payment-container">
          <Text style={{ fontWeight: 'bold' }}>Tipo de Pedido</Text>
          <Text>Tipo de Pedido</Text>
        </div>
        <div className="payment-container">
          <Text style={{ fontWeight: 'bold' }}>Transportadora</Text>
          <Text>Transportadora Ltda.</Text>
        </div> */}

        <div className="payment-container">
          <Text style={{ fontWeight: 'bold' }}>Lojista</Text>
          <div className="d-flex" style={{ flexDirection: 'column' }}>
            <Text style={{ textAlign: 'end' }}>{order.customerName}</Text>
            <Text style={{ textAlign: 'end' }}>
              {order.customerCpfCnpj?.length > 11
                ? formatCnpj(order.customerCpfCnpj)
                : formatCpf(order.customerCpfCnpj)}
            </Text>
          </div>
        </div>

        <div className="payment-container">
          <Text style={{ fontWeight: 'bold' }}>Representante</Text>
          <div className="d-flex" style={{ flexDirection: 'column' }}>
            <Text style={{ textAlign: 'end' }}>{order.representativeName}</Text>
            <Text style={{ textAlign: 'end' }}>
              {order.representativeCpfCnpj?.length > 11
                ? formatCnpj(order.representativeCpfCnpj)
                : formatCpf(order.representativeCpfCnpj)}
            </Text>
          </div>
        </div>

        <div className="payment-container">
          <Text style={{ fontWeight: 'bold' }}>Guia</Text>
          <Text style={{ textAlign: 'end' }}>
            {order.guideCpfCnpj?.length > 11 ? formatCnpj(order.guideCpfCnpj) : formatCpf(order.guideCpfCnpj)}
          </Text>
        </div>
      </Col>

      <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8} className="gutter-row">
        {/* TODO */}
        {/* <div className="payment-container">
          <Text style={{ fontWeight: 'bold' }}>Fabricante</Text>
          <Text>Fabricante Ltda.</Text>
        </div> */}
      </Col>

      <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8} className="gutter-row">
        {/* TODO */}
        {/* <div className="payment-container">
          <Text style={{ fontWeight: 'bold' }}>Guia</Text>
          <Text>{order.guideCpfCnpj}</Text>
        </div> */}
      </Col>
    </Row>
  );
};

export default Detail;
