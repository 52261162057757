import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Form, message } from 'antd';

import { useOfficialStore } from '@hooks/OfficialStoreContext';

import Loading from '@components/Loading';
import NewOfficialStoreContent from './components/NewOfficialStoreContent';
import NewOfficialStoreHeader from './components/NewOfficialStoreHeader';

const OfficialStoreNewPage: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { createOfficialStore, loading } = useOfficialStore();
  const [logoUrl, setLogoUrl] = useState<string>('');

  const initialFormValues = {
    description: '',
    isDeliveryImmediately: false,
    isMakeToOrder: false,
    isVisibleCustomer: false,
    isVisibleRepresentative: false,
    isVisibleSeller: false,
  };

  const onSubmit = async (values: any) => {
    const { isDeliveryImmediately, isMakeToOrder } = values;

    if (!isDeliveryImmediately && !isMakeToOrder) return message.warning('Escolha pelo menos 01(um) Tipo de Venda!');

    const newOfficialStore = { ...values, logoUrl };

    const response = await createOfficialStore(newOfficialStore);

    if (!response.error) {
      message.success('Loja cadastrada com sucesso!');
      history.go(-1);
    } else {
      response.message.map((errorMsg: any) => {
        message.error(errorMsg);
      });
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      <Form layout="vertical" form={form} onFinish={onSubmit} initialValues={initialFormValues}>
        <NewOfficialStoreHeader />
        <NewOfficialStoreContent logoUrl={logoUrl} setLogoUrl={setLogoUrl} />
      </Form>
    </>
  );
};

export default OfficialStoreNewPage;
