import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

import { PriceTable, PriceTableItem } from '@models/PriceTable';
import { PriceTableIntegrationFilter } from '@models/PriceTableIntegrationFilter';
import * as PriceTableService from '@services/priceTable';
import { message } from 'antd';

interface PriceTableState {
  loading: boolean;
  loadingUpdate: boolean;
  loadingDetails: boolean;
  loadingPriceTableIntegrationFilter: boolean;

  priceTables: PriceTable[];
  allPriceTables: PriceTable[];
  priceTableIntegrationFilter: PriceTableIntegrationFilter;
  priceTableCurrentPage: number;
  priceTableTotal: number;
  priceTableFilters: any;

  loadPriceTables: Function;
  loadAllPriceTables: Function;
  loadPriceTableIntegrationFilter: Function;
  loadPriceTableDetails: Function;

  currentPriceTable: PriceTable | undefined;
  loadPriceTableMerpDetails: Function;

  updatePriceTables: Function;
  updatePriceTableObservation: Function;

  createPriceTableHeader: Function;
  updatePriceTableHeader: Function;
}

interface PriceTableProviderProps {
  children: ReactNode;
}

export const PriceTableContext = createContext<PriceTableState>({} as PriceTableState);

const PriceTableProvider: React.FC<PriceTableProviderProps> = ({ children }) => {
  const [priceTables, setPriceTables] = useState<PriceTable[]>([]);
  const [allPriceTables, setAllPriceTables] = useState<PriceTable[]>([]);
  const [currentPriceTable, setCurrentPriceTable] = useState<PriceTable>({} as PriceTable);
  const [priceTableIntegrationFilter, setPriceTableIntegrationFilter] = useState<PriceTableIntegrationFilter>(
    {} as PriceTableIntegrationFilter,
  );

  const [priceTableFilters, setPriceTableFilters] = useState({} as any);
  const [priceTableCurrentPage, setPriceTableCurrentPage] = useState<number>(0);
  const [priceTableTotal, setPriceTableTotal] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPriceTableIntegrationFilter, setLoadingPriceTableIntegrationFilter] = useState<boolean>(false);

  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false);

  const loadPriceTables = useCallback(
    async (page: number, filters?: any) => {
      setLoading(true);
      setPriceTableCurrentPage(page === 0 ? 1 : page);

      try {
        const { data: _priceTables } = await PriceTableService.get({
          page: page ? page - 1 : 0,
          pageSize: 10,
          ...(filters && { ...filters }),
        });
        setPriceTables(_priceTables.data);
        setPriceTableTotal(_priceTables.total);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [setPriceTables, setLoading],
  );

  const loadAllPriceTables = useCallback(
    async (page: number, filters?: any) => {
      setLoading(true);

      try {
        const { data: _priceTables } = await PriceTableService.get({
          page: page ? page - 1 : 0,
          pageSize: 10,
          listAll: true,
          ...(filters && { ...filters }),
        });
        setAllPriceTables(_priceTables.data);        
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [setAllPriceTables, setLoading],
  );

  const loadPriceTableIntegrationFilter = useCallback(async () => {
    setLoadingPriceTableIntegrationFilter(true);

    const { data: _integrationFilter } = await PriceTableService.getPriceTableIntegrationFilter();

    setPriceTableIntegrationFilter(_integrationFilter);
    setLoadingPriceTableIntegrationFilter(false);
  }, [setPriceTableIntegrationFilter, setLoadingPriceTableIntegrationFilter]);

  const loadPriceTableDetails = useCallback(
    async (priceTableCode: number) => {
      setLoadingDetails(true);
      try {
        const { data: _priceTable } = await PriceTableService.getDetailsByCode(priceTableCode);

        const index = priceTables.findIndex((priceTable) => priceTable.code === priceTableCode);

        if (index !== -1 && _priceTable) {
          priceTables[index] = _priceTable;
          setPriceTables([...priceTables]);
        }
      } catch (e) {
        console.error(e);
        message.error('Erro ao buscar detalhes da tabela de preço!');
      } finally {
        setLoadingDetails(false);
      }
    },
    [priceTables, setPriceTables, setLoadingDetails],
  );

  const loadPriceTableMerpDetails = useCallback(
    async (priceTableCode: number) => {
      setLoadingDetails(true);
      try {
        const { data: _priceTable } = await PriceTableService.getMerpDetailsByCode(priceTableCode);

        setCurrentPriceTable(_priceTable);
      } catch (e) {
        console.error(e);
        message.error('Erro ao buscar detalhes da tabela de preço!');
      } finally {
        setLoadingDetails(false);
      }
    },
    [priceTables, setPriceTables, setLoadingDetails],
  );

  const updatePriceTables = useCallback(
    async (priceTableCodeList: string[]) => {
      setLoadingUpdate(true);

      try {
        const { data: _priceTables } = await PriceTableService.updatePriceTables({ priceTableCodeList });
        message.success('Tabelas de Preço vinculadas com sucesso!');
      } catch (e) {
        message.error('Erro ao gravar Tabelas de Preço!');
      } finally {
        setLoadingUpdate(false);
      }
    },
    [setPriceTables, setLoadingUpdate],
  );

  const updatePriceTableObservation = useCallback(
    async (priceTableCode: number, observation: string) => {
      setLoadingUpdate(true);

      try {
        const { data: _priceTable } = await PriceTableService.updatePriceTableObservation(priceTableCode, observation);

        const index = priceTables.findIndex((priceTable) => priceTable.code === priceTableCode);

        if (index !== -1 && _priceTable) {
          priceTables[index].observation = _priceTable.observation;
          setPriceTables([...priceTables]);
          message.success('Observação alterada com sucesso!');
        }
      } catch (e) {
        message.error('Erro ao alterar observação!');
      } finally {
        setLoadingUpdate(false);
      }
    },
    [priceTables, setPriceTables, setLoadingUpdate],
  );

  const createPriceTableHeader = useCallback(
    async (priceTableHeader, priceTable) => {
      setLoadingUpdate(true);

      try {
        const { data: _priceTableHeader } = await PriceTableService.createPriceTableHeader(priceTableHeader);
        const { data: _priceTable } = await PriceTableService.createPriceTable(priceTable);

        setPriceTables((prev) => [...prev, _priceTableHeader]);

        message.success('Tabela de preço criado com sucesso!');
      } catch (e: any) {
        if (e?.response?.data?.message) {
          message.error(e?.response?.data?.message);
        } else {
          message.error('Erro ao criar tabela de preço!');
        }
        console.error('ERROR: ', e.response);
        throw e;
      } finally {
        setLoadingUpdate(false);
      }
    },
    [priceTables, setPriceTables, setLoadingUpdate],
  );

  const updatePriceTableHeader = useCallback(
    async (priceTableHeader, priceTable) => {
      setLoadingUpdate(true);

      try {
        const { data: _priceTableHeader } = await PriceTableService.updatePriceTableHeader(priceTableHeader);
        const { data: _priceTable } = await PriceTableService.createPriceTable(priceTable);

        const priceTableList = [...priceTables].map((priceTable: PriceTable) => {
          if (priceTable.code === _priceTableHeader?.code) {
            return _priceTableHeader;
          } else {
            return priceTable;
          }
        });

        setPriceTables([...priceTableList]);

        message.success('Tabela de preço atualizada com sucesso!');
      } catch (e: any) {
        if (e?.response?.data?.message) {
          message.error(e?.response?.data?.message);
        } else {
          message.error('Erro ao atualizar tabela de preço!');
        }
        console.error('ERROR: ', e.response);
        throw e;
      } finally {
        setLoadingUpdate(false);
      }
    },
    [priceTables, setPriceTables, setLoadingUpdate],
  );

  return (
    <PriceTableContext.Provider
      value={{
        loading,
        loadingUpdate,
        loadingDetails,
        loadingPriceTableIntegrationFilter,
        priceTableIntegrationFilter,
        priceTables,
        allPriceTables,
        priceTableCurrentPage,
        priceTableTotal,
        priceTableFilters,
        loadPriceTables,
        loadAllPriceTables,
        loadPriceTableIntegrationFilter,
        loadPriceTableDetails,
        currentPriceTable,
        loadPriceTableMerpDetails,
        updatePriceTables,
        updatePriceTableObservation,
        createPriceTableHeader,
        updatePriceTableHeader,
      }}
    >
      {children}
    </PriceTableContext.Provider>
  );
};

const usePriceTable = () => {
  return useContext(PriceTableContext);
};

export { PriceTableProvider, usePriceTable };
