import React, { useEffect } from 'react';

import Loading from '@components/Loading';
import { useBrand } from '@hooks/BrandContext';
import SalesRegionContent from './components/SalesRegionContent';
import SalesRegionHeader from './components/SalesRegionHeader';

const SalesRegionPage: React.FC = () => {
  const { loadBrands, loading } = useBrand();

  useEffect(() => {
    loadBrands();
  }, []);

  if (loading) return <Loading />;

  return (
    <>
      <SalesRegionHeader />
      <SalesRegionContent />
    </>
  );
};

export default SalesRegionPage;
