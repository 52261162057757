import React from 'react';

import { Row, Col, Typography } from 'antd';

const { Title } = Typography;

const BrandsHeader: React.FC = () => {
  return (
    <Row className="mt-8" justify="space-between">
      <Col>
        <Title level={2}>Minhas Marcas</Title>
      </Col>
    </Row>
  );
};

export default BrandsHeader;
