import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import { OfficialStore } from '@models/OfficialStore';
import { PaymentConditions } from '@models/PaymentConditions';

import PanelPaymentTerm from '../PanelPaymentTerm';
import PanelPaymentMinimumValue from '../PanelPaymentMinimumValue';
import PanelPaymentIsDeliveryImmediately from '../PanelPaymentIsDeliveryImmediately';
import PanelPaymentIsMakeToOrder from '../PanelPaymentIsMakeToOrder';
import PanelMaxCardIntallments from '../PanelMaxCardInstallments';

interface OfficialStorePaymentContentProps {
  officialStore: OfficialStore;
  paymentConditions: PaymentConditions[];
}

const OfficialStorePaymentContent: React.FC<OfficialStorePaymentContentProps> = ({
  officialStore,
  paymentConditions,
}) => {
  const [dataSourcePaymentFormIsDeliveryImmediately, setDataSourcePaymentFormIsDeliveryImmediately] = useState<any[]>(
    [],
  );
  const [dataSourcePaymentFormIsMakeToOrder, setDataSourcePaymentFormIsMakeToOrder] = useState<any[]>([]);

  useEffect(() => {
    if (!officialStore) return;

    const _dataSourcePaymentFormIsDeliveryImmediately = officialStore.paymentTypes?.filter(
      (payment) => payment.saleType === 'PRONTA ENTREGA',
    );

    const _dataSourcePaymentFormIsMakeToOrder = officialStore.paymentTypes?.filter(
      (payment) => payment.saleType === 'SOB ENCOMENDA',
    );

    setDataSourcePaymentFormIsDeliveryImmediately(_dataSourcePaymentFormIsDeliveryImmediately);
    setDataSourcePaymentFormIsMakeToOrder(_dataSourcePaymentFormIsMakeToOrder);
  }, [officialStore]);

  return (
    <>
      <Row className="mt-4" justify="space-between" gutter={[16, 16]}>
        <Col className="d-flex" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <PanelPaymentTerm
            paymentConditions={paymentConditions}
            officialStorePaymentConditions={officialStore.paymentConditions}
            officialStoreId={officialStore.officialStoreId}
          />
        </Col>

        <Col className="d-flex" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <PanelPaymentIsDeliveryImmediately
            dataSourcePaymentFormIsDeliveryImmediately={dataSourcePaymentFormIsDeliveryImmediately}
          />
        </Col>

        <Col className="d-flex" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <PanelPaymentMinimumValue officialStore={officialStore} />
        </Col>

        <Col className="d-flex" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <PanelPaymentIsMakeToOrder dataSourcePaymentFormIsMakeToOrder={dataSourcePaymentFormIsMakeToOrder} />
        </Col>

        <Col className="d-flex" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <PanelMaxCardIntallments officialStore={officialStore} />
        </Col>
      </Row>
    </>
  );
};

export default OfficialStorePaymentContent;
