import './styles.css';

import { DeleteOutlined, PictureOutlined } from '@ant-design/icons';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
import * as OfficialStoreService from '@services/officialStore';
import { Button, Col, FormInstance, Row, Upload, UploadProps, message } from 'antd';
import { useEffect, useState } from 'react';

import * as S from '../../styles';

const { Dragger } = Upload;

interface IUploadOrderCoverModal {
  form: FormInstance<IUpdateForm>;
  closeModal: () => void;
  officialStoreName: string;
}

interface IUpdateForm {
  description: string;
  isDeliveryImmediately: boolean;
  isMakeToOrder: boolean;
  isVisibleCustomer: boolean;
  isVisibleRepresentative: boolean;
  isVisibleSeller: boolean;
  orderCoverUrl?: string | null;
  orderPreviewCoverUrl: string;
}

export const UploadOrderCoverModal = ({ form, closeModal, officialStoreName }: IUploadOrderCoverModal) => {
  const [files, setFiles] = useState<any[]>([]);
  const [orderCoverUrl, setOrderCoverUrl] = useState('');

  const { actualOfficialStore, updateSpecificProperty } = useOfficialStore();

  useEffect(() => {
    if (actualOfficialStore) {
      if (actualOfficialStore?.orderCoverUrl) setOrderCoverUrl(actualOfficialStore?.orderCoverUrl);
      form.setFieldsValue({
        ...form.getFieldsValue([
          'description',
          'isDeliveryImmediately',
          'isMakeToOrder',
          'isVisibleCustomer',
          'isVisibleRepresentative',
          'isVisibleSeller',
          'orderPreviewCoverUrl',
        ]),
        orderCoverUrl: actualOfficialStore.orderCoverUrl,
      });
    }
  }, []);

  const updateOrderCoverUrl = async () => {
    updateSpecificProperty(actualOfficialStore.officialStoreId, {
      property: 'orderCoverUrl',
      value: orderCoverUrl,
    });

    closeModal();
  };

  const handleUpload = async ({ file }: any) => {
    if (!file) return;

    try {
      const { data } = await OfficialStoreService.uploadImagesToSizes(file);

      if (data) {
        setOrderCoverUrl(data.large);

        form.setFieldsValue({ orderCoverUrl: data.large });
      }

      message.success('Imagem alterada com sucesso!');
    } catch {
      message.error('Erro ao alterar imagem!');
    }
  };

  const props: UploadProps = {
    name: 'file',
    showUploadList: false,
    multiple: false,
    fileList: files,
    accept: '.png, .jpg, .jpeg',
    beforeUpload(file, FileList) {
      if (file.size > 2097152) {
        message.error('Tamanho máximo do arquivo: 2MB');
        return false;
      }
    },

    onChange(info) {
      setFiles([info?.file]);
    },
  };

  const handleDeleteFile = () => {
    setOrderCoverUrl('');
    form.setFieldsValue({ orderCoverUrl: null });
    setFiles([]);
  };

  return (
    <S.Panel>
      <Row className="row">
        <Col style={{ width: '100%' }}>
          <S.CustomFormItem name="orderCoverUrl">
            <Dragger {...props} customRequest={handleUpload} style={{ display: orderCoverUrl ? 'none' : 'block' }}>
              <div>
                <p className="ant-upload-text">Arraste a imagem aqui</p>
                <p className="ant-upload-text">ou</p>
                <p className="ant-upload-text">
                  <Button type="primary">Carregar imagem</Button>
                </p>
                <p className="ant-upload-text">Formatos aceitos: png, jpeg</p>
                <p className="ant-upload-text" style={{ fontSize: '.85rem' }}>
                  Tamanho máximo do arquivo: 2MB
                </p>
              </div>
            </Dragger>
            <div
              style={{
                display: orderCoverUrl ? 'flex' : 'none',
                alignItems: 'baseline',
                gap: '0.5rem',
                justifyContent: 'center',
              }}
            >
              <p className="ant-upload-text">{files[0]?.name || `Capa para pedidos - ${officialStoreName}`}</p>

              <span onClick={handleDeleteFile}>
                <DeleteOutlined />
              </span>
            </div>
          </S.CustomFormItem>
        </Col>

        <Col className="col-preview">
          <p>Prévia</p>
          <div className="preview" style={{ display: orderCoverUrl === '' ? 'flex' : 'none' }}>
            <PictureOutlined />
          </div>
          <div className="preview" style={{ display: orderCoverUrl === '' ? 'none' : 'flex' }}>
            <img style={{ width: '100%', maxWidth: '100%', textAlign: 'center' }} src={orderCoverUrl} />
          </div>
        </Col>

        <Col className="button-col">
          <Button type="primary" onClick={() => updateOrderCoverUrl()}>
            Salvar capa
          </Button>
        </Col>
      </Row>
    </S.Panel>
  );
};
