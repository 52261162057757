import styled from 'styled-components';
import { Form, Steps } from 'antd';

export const CustomForm = styled(Form)`
  width: 100%;
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin-top: 16px;
`;

export const StepsContainer = styled(Steps)`
  width: min(400px, 100%);
  margin-bottom: 40px;

  .ant-steps-item-finish .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: var(--primary-color) !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background-color: var(--primary-color) !important;
  }

  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    border: 2px solid var(--primary-color) !important;
    background-color: #f2f2f2 !important;
  }
`;
