import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, Row, Switch, Typography, Image, InputNumber } from 'antd';

import { Color } from '@models/Color';
import * as S from './styles';
import { CustomButton } from '@styles/globals';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import GradeImage from '@assets/png/grade-image.png';
import LoadingComponents from '@components/LoadingComponents';
import { Pack } from '@models/Pack';
const { Title } = Typography;

interface ProductPackProps {
  colors: Partial<Color>[];
  packs: Pack[];
  sizes: string[];
  loading: boolean;
  isPack: boolean;
  onSubmit: Function;
}

const ProductPack: React.FC<ProductPackProps> = ({ colors, sizes, loading, isPack, onSubmit, packs }) => {
  const [form] = Form.useForm();
  const [packChecked, setPackChecked] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      isGrid: !isPack,
      isPack: !!isPack,
    });

    if (isPack && packs?.length) {
      packs.forEach((pack) => {
        form.setFieldsValue({ [`${pack.color.code}-${pack.size}`]: pack.packAmount });
      });
    }

    setPackChecked(isPack);
  }, [isPack, packs]);

  const handleGridSwitchChange = useCallback((checked: boolean) => {
    form.setFieldsValue({
      isGrid: checked,
      isPack: !checked,
    });
    setPackChecked(!checked);
  }, []);

  const handlePackSwitchChange = useCallback((checked: boolean) => {
    form.setFieldsValue({
      isGrid: !checked,
      isPack: checked,
    });
    setPackChecked(checked);
  }, []);

  const handleSubmit = useCallback(async (values: any) => {
    const _packs: Pack[] = [];

    if (values.isPack)
      for (let key of Object.keys(values)) {
        if (key !== 'isGrid' && key !== 'isPack') {
          //key formada por colorCode-sizeName
          const packColorAndSize = key.split('-');
          _packs.push({
            color: { code: packColorAndSize[0], name: '' },
            size: packColorAndSize[1],
            packAmount: values[key],
          });
        }
      }

    onSubmit({ isGrid: values.isGrid, isPack: values.isPack, packs: _packs });
  }, []);

  if (loading) return <LoadingComponents />;

  return (
    <S.Container>
      <Form onFinish={handleSubmit} form={form}>
        <Row className="w-100" justify="center" gutter={[16, 16]}>
          <Col className="gutter-row">
            <Form.Item label="Grade" name="isGrid" valuePropName="checked">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={handleGridSwitchChange}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row">
            <Form.Item label="Pack" name="isPack" valuePropName="checked">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={handlePackSwitchChange}
              />
            </Form.Item>
          </Col>
        </Row>

        {!packChecked && (
          <S.GridContainer className="pt-8 pb-8" style={{ gridTemplateColumns: `repeat(1, 1fr)` }}>
            <Row className="w-100" justify="center">
              <Image src={GradeImage} width={94} preview={false} />
            </Row>
          </S.GridContainer>
        )}

        {packChecked && (
          <>
            <Title className="mb-0" level={5}>
              Cor/Tamanho
            </Title>

            <S.ScrollContainer className="mt-6 mb-6">
              <S.GridContainer style={{ gridTemplateColumns: `repeat(${sizes.length + 1}, 1fr)` }}>
                {colors.map((color, i) => (
                  <>
                    <S.ColorNameContainer>
                      <span id={color.code}>{color.name}</span>
                    </S.ColorNameContainer>

                    {sizes.map((size) => (
                      <S.InputContainer>
                        <span>{i === 0 && size}</span>
                        <Form.Item className="mb-0" name={`${color.code}-${size}`} initialValue={0}>
                          <InputNumber min={0} style={{ minWidth: '68px', width: '100%' }} />
                        </Form.Item>
                      </S.InputContainer>
                    ))}
                  </>
                ))}
              </S.GridContainer>
            </S.ScrollContainer>
          </>
        )}

        <CustomButton className="w-100" type="primary" htmlType="submit">
          ATUALIZAR
        </CustomButton>
      </Form>
    </S.Container>
  );
};

export default ProductPack;
