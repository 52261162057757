import React from 'react';
import { Col, ColProps } from 'antd';

import * as S from './styles';

interface CardProps extends ColProps {}
const Card: React.FC<CardProps> = ({ children, ...rest }) => {
  return (
    <Col {...rest} className="d-flex gutter-row">
      <S.Container>{children}</S.Container>
    </Col>
  );
};

export default Card;
