import { UF_LIST } from '@utils/uf-list';
import { Col, Form, Input, Row, Select } from 'antd';
import React from 'react';
import { ScrollContainer } from '../DetailsContent/styles';

const GoalFiltersForm: React.FC = () => {
  return (
    <ScrollContainer>
      <Form.Item className="mb-3" label="Representante" name="representativeCode">
        <Input placeholder="Código ou nome do representante..." />
      </Form.Item>

      <Form.Item className="mb-3" label="Vendedor" name="sellerCode">
        <Input placeholder="Código ou nome do vendedor..." />
      </Form.Item>

      <Form.Item className="mb-3" label="Lojista" name="customer">
        <Input placeholder="Lojista..." />
      </Form.Item>

      <Form.Item className="mb-3" label="Coleção" name="collection">
        <Input placeholder="Coleção..." />
      </Form.Item>

      <Form.Item className="mb-3" label="Loja oficial" name="officialStore">
        <Input placeholder="Loja oficial..." />
      </Form.Item>

      <Form.Item className="mb-3" label="Referência" name="reference">
        <Input placeholder="Referência..." />
      </Form.Item>

      <Form.Item className="mb-3" label="UF" name="uf">
        <Select placeholder="UF...">
          {UF_LIST.map((uf) => (
            <Select.Option key={uf.label} label={uf.label} value={uf.value}>
              {uf.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Row gutter={[8, 8]}>
        <Col span={12} className="gutter-row">
          <Form.Item className="mb-3" label="Intervalo Inicial (%)" name="initialPercentInterval">
            <Input type="number" placeholder="Intervalo Inicial (%)..." />
          </Form.Item>
        </Col>
        <Col span={12} className="gutter-row">
          <Form.Item className="mb-3" label="Intervalo Final (%)" name="finalPercentInterval">
            <Input type="number" placeholder="Intervalo Final (%)..." />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item className="mb-3" label="Tipo class. do produto" name="productClassificationTypeCode">
        <Input placeholder="Tipo de classificação do produto..." />
      </Form.Item>

      <Form.Item className="mb-3" label="Classificação do produto" name="productClassificationCode">
        <Input placeholder="Classificação do produto..." />
      </Form.Item>

      <Form.Item className="mb-3" label="Tipo class. do pedido" name="orderClassificationTypeCode">
        <Input placeholder="Tipo de classificação do pedido..." />
      </Form.Item>

      <Form.Item className="mb-8" label="Classificação do pedido" name="orderClassificationCode">
        <Input placeholder="Classificação do pedido..." />
      </Form.Item>
    </ScrollContainer>
  );
};

export default GoalFiltersForm;
