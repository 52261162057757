import { LoadingOutlined } from '@ant-design/icons';
import { SharedData } from '@components/ProductForm/models/product-form-models';
import { useImages } from '@hooks/ImagesContext';
import { useIntegration } from '@hooks/IntegrationContext';
import { Representative } from '@models/Representative';
import { CustomButton } from '@styles/globals';
import { Col, Form, Row, Space, Switch, Typography } from 'antd';
import React from 'react';
import { CustomInput } from '../../styles';
const { Title, Text } = Typography;

interface RepresentativeFormStepProps {
  sharedData?: SharedData;
  changeSharedData?: Function;
  formValues: Representative | undefined;
  setFormValues: Function;
  goToNextStep: Function;
  isEditing: boolean;
  render: boolean;
}

const RepresentativeFormStep: React.FC<RepresentativeFormStepProps> = ({
  sharedData,
  changeSharedData,
  formValues,
  setFormValues,
  goToNextStep,
  isEditing = false,
  render = true,
}) => {
  const { hasMerpIntegration } = useIntegration();
  const [representativeForm] = Form.useForm();

  const { uploadImage } = useImages();
  const [uploadLoading, setUploadLoading] = React.useState<boolean>(false);
  const [uploadImageUrl, setUploadImageUrl] = React.useState<string>('');

  const [isInactive, setIsInactive] = React.useState<boolean>(false);
  const [canMakeToOrder, setCanMakeToOrder] = React.useState<boolean>(true);
  const [canDeliveryImmediately, setCanDeliveryImmediately] = React.useState<boolean>(true);
  const [canCheckFinance, setCanCheckFinance] = React.useState<boolean>(true);
  const [canManageOrderSynchronization, setCanManageOrderSynchronization] = React.useState<boolean>(true);

  React.useEffect(() => {
    representativeForm.setFields([
      { name: 'cpfCnpj', value: null },
      { name: 'name', value: null },
      { name: 'supervisorCode', value: null },
      { name: 'supervisorName', value: null },
      { name: 'branchInsertCode', value: null },
    ]);

    if (!isEditing && formValues) {
      representativeForm.setFieldsValue({
        cpfCnpj: formValues?.cpfCnpj,
        name: formValues?.name,
        supervisorCode: formValues?.supervisorCode,
        supervisorName: formValues?.supervisorName,
        branchInsertCode: formValues?.branchInsertCode,
      });

      setCanMakeToOrder(formValues?.canMakeToOrder);
      setIsInactive(formValues?.isInactive);
      setCanDeliveryImmediately(formValues?.canDeliveryImmediately);
      setCanCheckFinance(formValues?.canCheckFinance);
      setCanManageOrderSynchronization(formValues?.canManageOrderSynchronization);
      setUploadImageUrl(formValues?.photoUrl || '');
    }
  }, []);

  React.useEffect(() => {
    if (isEditing && formValues) {

      representativeForm.setFieldsValue({
        cpfCnpj: formValues?.cpfCnpj,
        name: formValues?.name,
        supervisorCode: formValues?.supervisorCode,
        supervisorName: formValues?.supervisorName,
        branchInsertCode: formValues?.branchInsertCode,
      });

      formatCnpj(formValues?.cpfCnpj);

      setCanMakeToOrder(formValues?.canMakeToOrder);
      setIsInactive(formValues?.isInactive);
      setCanDeliveryImmediately(formValues?.canDeliveryImmediately);
      setCanCheckFinance(formValues?.canCheckFinance);
      setCanManageOrderSynchronization(formValues?.canManageOrderSynchronization);
      setUploadImageUrl(formValues?.photoUrl || '');
    }
  }, [formValues]);

  const finishStep = async () => {
    const values: Representative = await representativeForm.validateFields();

    values.canMakeToOrder = canMakeToOrder;
    values.isInactive = isInactive;
    values.canDeliveryImmediately = canDeliveryImmediately;
    values.canCheckFinance = canCheckFinance;
    values.canManageOrderSynchronization = canManageOrderSynchronization;
    values.photoUrl = uploadImageUrl;

    setFormValues(values);
    goToNextStep();
  };

  const formatCnpj = (value: string) => {
    if (value) {
      const cnpjRegex = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
      const numericValue = value.replace(/\D/g, '');

      if (numericValue.length === 14) {
        representativeForm.setFieldsValue({
          cpfCnpj: value.replace(cnpjRegex, '$1.$2.$3/$4-$5'),
        });
      } else if (numericValue.length === 11) {
        const cpfRegex = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
        representativeForm.setFieldsValue({
          cpfCnpj: value.replace(cpfRegex, '$1.$2.$3-$4'),
        });
      } else {
        representativeForm.setFieldsValue({
          cpfCnpj: numericValue,
        });
      }
    }
  };

  const handleUpload = async (files: any) => {
    setUploadLoading(true);

    const image = await uploadImage(files[0]);

    if (image) {
      setUploadImageUrl(image?.large);
    }

    setUploadLoading(false);
  };

  return (
    <>
      {render && (
        <Form
          form={representativeForm}
          className="d-flex"
          style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}
        >
          <Row
            className="d-flex"
            style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px' }}
          >
            <Col span={12} style={{ paddingRight: '15px' }}>
              <Title style={{ display: 'flex', marginBottom: '10px', height: '30px' }} level={5}>
                Foto de perfil do representante
              </Title>

              {!uploadImageUrl && (
                <label
                  htmlFor="upload-profile-photo"
                  className="d-flex"
                  style={{
                    cursor: uploadLoading ? 'default' : 'pointer',
                    width: '170px',
                    minWidth: '170px',
                    height: '170px',
                    marginBottom: '20px',
                    borderRadius: '50%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    backgroundColor: '#E5E5E5',
                    flexDirection: 'column',
                  }}
                >
                  {uploadLoading ? (
                    <LoadingOutlined
                      type="play-circle-o"
                      style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
                    />
                  ) : (
                    <>
                      <div className="add-image-icon">
                        <p style={{ margin: '0', color: 'white' }}>+</p>
                      </div>
                      <p style={{ margin: 0, fontSize: 18, textAlign: 'center' }}>Adicionar foto</p>
                      <input
                        id="upload-profile-photo"
                        type="file"
                        multiple={true}
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={(e) => handleUpload(e?.target?.files)}
                      />
                    </>
                  )}
                </label>
              )}

              {uploadImageUrl && (
                <label
                  htmlFor="upload-profile-photo"
                  className="d-flex"
                  style={{
                    cursor: uploadLoading ? 'default' : 'pointer',
                    width: '170px',
                    minWidth: '170px',
                    height: '170px',
                    marginBottom: '20px',
                    borderRadius: '50%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    flexDirection: 'column',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundImage: uploadLoading ? 'none' : `url(${uploadImageUrl})`,
                    backgroundColor: !uploadLoading ? 'none' : '#E5E5E5',
                  }}
                >
                  {uploadLoading ? (
                    <LoadingOutlined
                      type="play-circle-o"
                      style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
                    />
                  ) : (
                    <>
                      <div
                        style={{
                          width: '50px',
                          height: '50px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: 'white',
                          backgroundColor: '#512874',
                          borderRadius: '50%',
                          fontSize: '30px',
                          position: 'absolute',
                          right: 0,
                          bottom: 0,
                        }}
                      >
                        <p style={{ margin: '0', color: 'white' }}>+</p>
                      </div>

                      <input
                        id="upload-profile-photo"
                        type="file"
                        multiple={true}
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={(e) => handleUpload(e?.target?.files)}
                      />
                    </>
                  )}
                </label>
              )}
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col span={12} style={{ paddingRight: '15px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                CPF/CNPJ do representante <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>

              <Form.Item
                name="cpfCnpj"
                rules={[
                  {
                    pattern: /(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/,
                    required: true,
                    message: 'Informe o CPF/CNPJ do representante corretamente',
                  },
                ]}
              >
                <CustomInput
                  style={{ width: '100%' }}
                  placeholder="CPF/CNPJ do representante"
                  onChange={(event) => formatCnpj(event.target.value)}
                  maxLength={18}
                />
              </Form.Item>
            </Col>

            <Col span={12} style={{ paddingLeft: '15px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Nome do representante <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Informe o nome do representante',
                  },
                ]}
              >
                <CustomInput style={{ width: '100%' }} placeholder="Nome do representante" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            {!hasMerpIntegration &&
              <>
                <Col span={12} style={{ paddingRight: '15px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Código do supervisor
                  </Title>
                  <Form.Item name="supervisorCode">
                    <CustomInput type="number" min="0" style={{ width: '100%' }} placeholder="Código do supervisor" />
                  </Form.Item>
                </Col>

                <Col span={12} style={{ paddingLeft: '15px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Nome do supervisor
                  </Title>
                  <Form.Item name="supervisorName">
                    <CustomInput style={{ width: '100%' }} placeholder="Nome do supervisor" />
                  </Form.Item>
                </Col>
              </>
            }

            {hasMerpIntegration && <Col span={24}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Nome do supervisor
              </Title>
              <Form.Item name="supervisorName">
                <CustomInput style={{ width: '100%' }} placeholder="Nome do supervisor" />
              </Form.Item>
            </Col>}

          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col span={12} style={{ paddingRight: '15px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Código de inserção da filial
              </Title>
              <Form.Item name="branchInsertCode">
                <CustomInput
                  type="number"
                  min="0"
                  style={{ width: '100%' }}
                  placeholder="Código de inserção da filial"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Col span={24} style={{ marginBottom: '25px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Situação do representante <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Space direction="horizontal">
                <div>
                  <span>Ativar: </span>
                  <Switch checked={!isInactive} onChange={() => setIsInactive(false)} />
                </div>
                <div>
                  <span className="ml-4">Inativar: </span>
                  <Switch
                    checked={isInactive}
                    onChange={() => {
                      setIsInactive(true);
                    }}
                  />
                </div>
              </Space>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Col span={24} style={{ marginBottom: '25px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Pode realizar pedidos <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Space direction="horizontal">
                <div>
                  <span>Sim: </span>
                  <Switch checked={canMakeToOrder} onChange={() => setCanMakeToOrder(true)} />
                </div>
                <div>
                  <span className="ml-4">Não: </span>
                  <Switch
                    checked={!canMakeToOrder}
                    onChange={() => {
                      setCanMakeToOrder(false);
                    }}
                  />
                </div>
              </Space>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Col span={24} style={{ marginBottom: '25px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Pode entregar imediatamente <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Space direction="horizontal">
                <div>
                  <span>Sim: </span>
                  <Switch checked={canDeliveryImmediately} onChange={() => setCanDeliveryImmediately(true)} />
                </div>
                <div>
                  <span className="ml-4">Não: </span>
                  <Switch
                    checked={!canDeliveryImmediately}
                    onChange={() => {
                      setCanDeliveryImmediately(false);
                    }}
                  />
                </div>
              </Space>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Col span={24} style={{ marginBottom: '25px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Pode verificar finanças <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Space direction="horizontal">
                <div>
                  <span>Sim: </span>
                  <Switch checked={canCheckFinance} onChange={() => setCanCheckFinance(true)} />
                </div>
                <div>
                  <span className="ml-4">Não: </span>
                  <Switch
                    checked={!canCheckFinance}
                    onChange={() => {
                      setCanCheckFinance(false);
                    }}
                  />
                </div>
              </Space>
            </Col>
          </Row>

          <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Col span={24} style={{ marginBottom: '25px' }}>
              <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                Pode gerenciar sincronização de pedidos <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
              </Title>
              <Space direction="horizontal">
                <div>
                  <span>Sim: </span>
                  <Switch
                    checked={canManageOrderSynchronization}
                    onChange={() => setCanManageOrderSynchronization(true)}
                  />
                </div>
                <div>
                  <span className="ml-4">Não: </span>
                  <Switch
                    checked={!canManageOrderSynchronization}
                    onChange={() => {
                      setCanManageOrderSynchronization(false);
                    }}
                  />
                </div>
              </Space>
            </Col>
          </Row>

          <CustomButton type="primary" style={{ marginTop: 40, width: '100%' }} onClick={finishStep}>
            Continuar
          </CustomButton>
        </Form>
      )}
    </>
  );
};

export default RepresentativeFormStep;
