import { Col, Form, Modal, Row, Select, Space, Switch, Typography } from 'antd';
import React from 'react';

import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { usePaymentConditions } from '@hooks/PaymentConditionsContext';
import { CustomButton } from '@styles/globals';
import { SelectValue } from 'antd/lib/select';
import { PaymentCondition } from './models/payment-condition-form-models';
import { Container, CustomInput } from './styles';

const { Title } = Typography;

interface PaymentConditionsFormProps {
  onCloseModal: Function;
}

const PaymentConditionsForm: React.FC<PaymentConditionsFormProps> = ({ onCloseModal }) => {
  const { createPaymentCondition } = usePaymentConditions();
  const [paymentConditionForm] = Form.useForm();
  const [isActive, setIsActive] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [subdivisionIntervals, setSubdivisionIntervals] = React.useState<string[]>([]);

  React.useEffect(() => {
    paymentConditionForm.setFields([
      { name: 'name', value: null },
      { name: 'installment', value: null },
      { name: 'discountPercentage', value: null },
      { name: 'discountValue', value: null },
      { name: 'installmentDays', value: null },
    ]);
  }, []);

  const handleSubmit = async (values: PaymentCondition) => {
    setLoading(true);
    try {
      await createPaymentCondition(values);
      onCloseModal();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const showModalConfirmation = async () => {
    const values: PaymentCondition = await paymentConditionForm.validateFields();

    Modal.confirm({
      title: 'Tem certeza que deseja cadastrar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Cadastrar',
      cancelText: 'Cancelar',
      onOk() {
        values.isActive = isActive;
        values.integrationId = Number(localStorage.getItem('@modavest/integration_id'));
        values.installmentDays = subdivisionIntervals;
        handleSubmit(values);
      },
    });
  };

  const onBarCodeInputChange = (values: SelectValue) => {
    const intervals: any = values?.toString().trim().split(',');
    setSubdivisionIntervals(intervals);
  };

  return (
    <>
      <div className="steps-action d-flex" style={{ width: '100%' }}>
        <Container
          style={{
            background: '#fff',
            padding: '100px',
            paddingTop: '110px',
            borderRadius: '8px',
            width: '100%',
          }}
        >
          {loading ? (
            <div style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <LoadingOutlined
                type="play-circle-o"
                style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
              />
            </div>
          ) : (
            <Form
              form={paymentConditionForm}
              className="d-flex"
              style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}
            >
              <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <Col span={12} style={{ paddingRight: '15px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Nome da condição de pagamento <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                  </Title>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Informe o nome da condição de pagamento',
                      },
                    ]}
                  >
                    <CustomInput style={{ width: '100%' }} placeholder="Nome da condição de pagamento" />
                  </Form.Item>
                </Col>

                <Col span={12} style={{ paddingLeft: '15px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Quantidade de parcelas <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                  </Title>
                  <Form.Item
                    name="installment"
                    rules={[
                      {
                        required: true,
                        message: 'Informe o dias de parcelamento',
                      },
                    ]}
                  >
                    <CustomInput type="number" style={{ width: 'Q00%' }} placeholder="" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <Col span={12} style={{ paddingRight: '15px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Percentual de desconto
                  </Title>
                  <Form.Item name="discountPercentage">
                    <CustomInput type="number" min="0" style={{ width: '100%' }} placeholder="Percentual de desconto" />
                  </Form.Item>
                </Col>

                <Col span={12} style={{ paddingLeft: '15px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Valor de desconto
                  </Title>
                  <Form.Item name="discountValue">
                    <CustomInput type="number" min="0" style={{ width: '100%' }} placeholder="Valor de desconto" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <Col span={24}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Intervalo de parcelamento <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                  </Title>
                  <Form.Item
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: 'Informe o intervalo de parcelamento',
                      },
                    ]}
                  >
                    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Col span={17}>
                        <Select
                          mode="tags"
                          style={{ width: '100%', textAlign: 'left' }}
                          placeholder={'Intervalo de parcelamento'}
                          onChange={(value) => {
                            onBarCodeInputChange(value);
                          }}
                          options={subdivisionIntervals?.map((interval: string) => {
                            return {
                              value: interval,
                              label: interval,
                            };
                          })}
                          open={false}
                        />
                      </Col>
                      <Col span={6}>
                        <CustomButton type="primary" style={{ margin: 0 }}>
                          Adicionar código
                        </CustomButton>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>

              <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <Col span={24} style={{ marginBottom: '25px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Condição de pagamento <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                  </Title>
                  <Space direction="horizontal">
                    <div>
                      <span>Ativa: </span>
                      <Switch checked={isActive} onChange={() => setIsActive(true)} />
                    </div>
                    <div>
                      <span className="ml-4">Inativa: </span>
                      <Switch
                        checked={!isActive}
                        onChange={() => {
                          setIsActive(false);
                        }}
                      />
                    </div>
                  </Space>
                </Col>
              </Row>

              <CustomButton type="primary" style={{ marginTop: 40, width: '100%' }} onClick={showModalConfirmation}>
                Cadastrar
              </CustomButton>
            </Form>
          )}
        </Container>
      </div>
    </>
  );
};

export default PaymentConditionsForm;
