export enum StatusGroupTypeEnum {
  InProgress = 'progress',
  Attended = 'finished',
  Canceled = 'canceled',
}

export enum StatusOrderTypeEnum {
  InProgress = 'InProgress',
  BillingReleased = 'BillingReleased',
  PartiallyAnswered = 'PartiallyAnswered',
  Attended = 'Attended',
  Blocked = 'Blocked',
  Canceled = 'Canceled',
  InComposition = 'InComposition',
  InAnalysis = 'InAnalysis',
  Failed = 'Failed',
}

export const StatusOrderTypeEnumArray = [
  { statusDescription: 'Em progresso', statusValue: StatusOrderTypeEnum.InProgress },
  { statusDescription: 'Faturamento lançado', statusValue: StatusOrderTypeEnum.BillingReleased },
  { statusDescription: 'Parcialmente respondido', statusValue: StatusOrderTypeEnum.PartiallyAnswered },
  { statusDescription: 'Atendido', statusValue: StatusOrderTypeEnum.Attended },
  { statusDescription: 'Bloqueado', statusValue: StatusOrderTypeEnum.Blocked },
  { statusDescription: 'Cancelado', statusValue: StatusOrderTypeEnum.Canceled },
  { statusDescription: 'Em composição', statusValue: StatusOrderTypeEnum.InComposition },
  { statusDescription: 'Em análise', statusValue: StatusOrderTypeEnum.InAnalysis },
];
