import { Col, Form, Image, Modal, Row, Select, message } from 'antd';
import React, { useState } from 'react';
import './styles';

import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
  LoadingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { states } from '@components/SalesRegionForm/models/sales-order-form-models';
import { useDevolution } from '@hooks/DevolutionsContext';
import { CustomButton } from '@styles/globals';
import { formatCep } from '@utils/cep-mask';
import Title from 'antd/lib/typography/Title';
import devolutionSent from '../../assets/svg/pana.svg';
import { Container, CustomInput } from './styles';

const DevolutionRequestUploadForm: React.FC<any> = ({}) => {
  const { sendDevolutionInvoice } = useDevolution();

  const [uploadForm] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [wasSent, setWasSent] = useState<boolean>(false);

  const [file, setFile] = useState<any>();
  const [fileError, setFileError] = useState<boolean>();
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    uploadForm.setFields([
      { name: 'invoiceCode', value: null },
      { name: 'responsibleName', value: null },
      { name: 'addressPublicPlace', value: null },
      { name: 'addressName', value: null },
      { name: 'addressNumber', value: null },
      { name: 'addressComplement', value: null },
      { name: 'addressNeighborhood', value: null },
      { name: 'addressCityName', value: null },
      { name: 'addressUf', value: null },
      { name: 'addressCep', value: null },
      { name: 'addressBcbCountryCode', value: null },
      { name: 'addressCountryName', value: null },
      { name: 'addressPostOfficeBox', value: null },
      { name: 'addressReference', value: null },
    ]);
  }, []);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const invoiceCode = urlParams.get('invoiceCode');
    if (invoiceCode) {
      uploadForm.setFieldsValue({
        invoiceCode: invoiceCode,
      });
    }
  }, []);

  const hadleSubmit = async (values: any) => {
    setLoading(true);

    try {
      await sendDevolutionInvoice(values);

      message.success('A devolução foi enviada com sucesso!!');
      setWasSent(true);
    } catch (e) {
      console.log(e);
      setWasSent(false);
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async (files: any) => {
    setFile(files[0]);
  };

  const handleRemoveFile = () => {
    setFile(undefined);
    if (fileInputRef?.current) fileInputRef.current.value = '';
  };

  const showModalConfirmation = async () => {
    const values = await uploadForm.validateFields();

    if (file) {
      setFileError(false);
    } else {
      setFileError(true);
      return;
    }

    const uploadDevolution = {
      invoiceCode: values?.invoiceCode,
      devolutionInvoice: file,
      addressOriginDevolution: {
        responsibleName: values?.responsibleName,
        addressPublicPlace: values?.addressPublicPlace,
        addressName: values?.addressName,
        addressNumber: values?.addressNumber,
        addressComplement: values?.addressComplement,
        addressNeighborhood: values?.addressNeighborhood,
        addressCityName: values?.addressCityName,
        addressUf: values?.addressUf,
        addressCep: values?.addressCep?.replace(/\D/g, ''),
        addressBcbCountryCode: values?.addressBcbCountryCode,
        addressCountryName: values?.addressCountryName,
        addressPostOfficeBox: values?.addressPostOfficeBox,
        addressReference: values?.addressReference,
      },
    };

    Modal.confirm({
      title: 'Solicitar análise',
      icon: <ExclamationCircleOutlined />,
      content: 'Deseja solicitar a análise de devolução? Esta Nota Fiscal só pode ser devolvida uma vez.',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: () => {
        hadleSubmit(uploadDevolution);
      },
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '50px',
        width: '100%',
      }}
    >
      <div className="steps-action d-flex" style={{ width: '100%' }}>
        <Container
          style={{
            background: '#fff',
            padding: '50px',
            borderRadius: '8px',
            width: '100%',
          }}
        >
          {loading && (
            <div
              style={{
                width: '1000px',
                height: '550px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LoadingOutlined
                type="play-circle-o"
                style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
              />
            </div>
          )}

          {!loading && wasSent && (
            <div
              style={{
                width: '1000px',
                height: '550px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '150px',
                textAlign: 'center',
              }}
            >
              <Image src={devolutionSent} style={{ width: '100%', maxWidth: '250px' }} preview={false} />
              <h2 style={{ fontWeight: 'bold' }}>
                A última etapa de solicitação de devolução foi enviada com sucesso!
              </h2>
              <p>
                Após a validação e aprovação dos dados enviados para a devolução, você receberá o código para envio.
              </p>
            </div>
          )}

          {!loading && !wasSent && (
            <>
              <Form
                form={uploadForm}
                className="d-flex"
                style={{ width: '100%', height: '100%', flexDirection: 'column' }}
              >
                <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Col span={24}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      Número da nota fiscal de origem <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                    </Title>
                    <Form.Item
                      name="invoiceCode"
                      rules={[
                        {
                          required: true,
                          message: 'Informe o número da nota fiscal de origem',
                        },
                      ]}
                    >
                      <CustomInput
                        style={{ width: '100%' }}
                        type="number"
                        placeholder="Número da nota fiscal de origem"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', marginBottom: 30 }}>
                  <label htmlFor="file-upload" className="d-flex">
                    <div
                      style={{
                        border: `1px solid ${fileError ? '#ff4d4f' : '#B8B8B8'}`,
                        padding: 15,
                        display: 'flex',
                        borderRadius: '10px',
                        cursor: file === undefined ? 'pointer' : 'not-allowed',
                        backgroundColor: file === undefined ? '' : '#f5f5f5',
                      }}
                    >
                      <UploadOutlined style={{ fontSize: 17, marginRight: '0.5rem' }} />

                      <p style={{ display: 'flex', margin: 0 }}>
                        Anexar nota fiscal de devolução <p style={{ color: 'red', paddingLeft: '5px', margin: 0 }}>*</p>
                      </p>

                      <input
                        disabled={file !== undefined}
                        id="file-upload"
                        type="file"
                        accept="image/*, .pdf, text/xml"
                        style={{ display: 'none' }}
                        onChange={(e) => handleUpload(e?.target?.files)}
                        ref={fileInputRef}
                      />
                    </div>
                  </label>

                  {fileError && <p style={{ color: '#ff4d4f', margin: 0 }}>Anexe o arquivo da nota fiscal</p>}

                  {file !== undefined && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #B8B8B8',
                        padding: 10,
                        paddingTop: 15,
                        minWidth: '265px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <FileOutlined style={{ marginRight: 5, marginBottom: 4, fontSize: '1rem' }} />
                        <p style={{ margin: 0 }}>{file?.name}</p>
                      </div>
                      <DeleteOutlined
                        style={{
                          marginLeft: 35,
                          marginBottom: 4,
                          cursor: 'pointer',
                          color: 'red',
                          fontSize: '1.1rem',
                        }}
                        onClick={handleRemoveFile}
                      />
                    </div>
                  )}
                </div>

                <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Col span={24}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      Nome do Responsável
                      <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                    </Title>
                    <Form.Item name="responsibleName" rules={[{ required: true, message: 'Informe o responsável' }]}>
                      <CustomInput style={{ width: '100%' }} placeholder="Informe o responsável" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Col span={12} style={{ paddingRight: '12px' }}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      Nome do País
                      <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                    </Title>
                    <Form.Item
                      name="addressCountryName"
                      rules={[{ required: true, message: 'Informe o nome do país' }]}
                    >
                      <CustomInput style={{ width: '100%' }} placeholder="Informe o nome do país" />
                    </Form.Item>
                  </Col>

                  <Col span={12} style={{ paddingLeft: '12px' }}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      Código do País (BCB)
                    </Title>
                    <Form.Item name="addressBcbCountryCode">
                      <CustomInput style={{ width: '100%' }} placeholder="Informe o código do país (BCB)" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Col span={12} style={{ paddingRight: '12px' }}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      UF
                      <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                    </Title>
                    <Form.Item name="addressUf" rules={[{ required: true, message: 'Informe a UF' }]}>
                      <Select
                        style={{ height: '48px', borderRadius: '8px' }}
                        placeholder="Selecione a UF"
                        size="large"
                        showSearch
                        allowClear
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {states?.length &&
                          states.map((state: { id: number; name: string; UF: string }) => (
                            <Select.Option key={state.UF} value={state.UF}>
                              {state.UF}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12} style={{ paddingLeft: '12px' }}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      CEP
                      <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                    </Title>
                    <Form.Item name="addressCep" rules={[{ required: true, message: 'Informe o CEP' }]}>
                      <CustomInput
                        onChange={(e) => {
                          if (e?.target?.value?.length === 8) {
                            uploadForm.setFieldsValue({
                              addressCep: formatCep(e.target.value),
                            });
                          } else {
                            uploadForm.setFieldsValue({
                              addressCep: e.target.value,
                            });
                          }
                        }}
                        maxLength={9}
                        style={{ width: '100%' }}
                        placeholder="Informe o CEP"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Col span={12} style={{ paddingRight: '12px' }}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      Cidade
                      <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                    </Title>
                    <Form.Item name="addressCityName" rules={[{ required: true, message: 'Informe a cidade' }]}>
                      <CustomInput style={{ width: '100%' }} placeholder="Informe a cidade" />
                    </Form.Item>
                  </Col>

                  <Col span={12} style={{ paddingLeft: '12px' }}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      Bairro
                      <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                    </Title>
                    <Form.Item name="addressNeighborhood" rules={[{ required: true, message: 'Informe o bairro' }]}>
                      <CustomInput style={{ width: '100%' }} placeholder="Informe o bairro" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Col span={12} style={{ paddingRight: '12px' }}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      Logradouro
                      <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                    </Title>
                    <Form.Item name="addressPublicPlace" rules={[{ required: true, message: 'Informe o logradouro' }]}>
                      <CustomInput style={{ width: '100%' }} placeholder="Informe o logradouro" />
                    </Form.Item>
                  </Col>

                  <Col span={12} style={{ paddingLeft: '12px' }}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      Nome do Endereço
                      <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                    </Title>
                    <Form.Item name="addressName" rules={[{ required: true, message: 'Informe o nome do endereço' }]}>
                      <CustomInput style={{ width: '100%' }} placeholder="Informe o nome do endereço" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Col span={12} style={{ paddingRight: '12px' }}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      Número
                      <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                    </Title>
                    <Form.Item name="addressNumber" rules={[{ required: true, message: 'Informe o número' }]}>
                      <CustomInput style={{ width: '100%' }} type="number" placeholder="Informe o número" />
                    </Form.Item>
                  </Col>

                  <Col span={12} style={{ paddingLeft: '12px' }}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      Complemento
                    </Title>
                    <Form.Item name="addressComplement">
                      <CustomInput style={{ width: '100%' }} placeholder="Informe o complemento" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Col span={12} style={{ paddingRight: '12px' }}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      Caixa Postal
                    </Title>
                    <Form.Item name="addressPostOfficeBox">
                      <CustomInput type="number" style={{ width: '100%' }} placeholder="Informe a caixa postal" />
                    </Form.Item>
                  </Col>

                  <Col span={12} style={{ paddingLeft: '12px' }}>
                    <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                      Referência
                    </Title>
                    <Form.Item name="addressReference">
                      <CustomInput style={{ width: '100%' }} placeholder="Informe a referência" />
                    </Form.Item>
                  </Col>
                </Row>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 15,
                    backgroundColor: '#f4f4f4',
                    fontSize: 16,
                  }}
                >
                  <div style={{ height: '80%', display: 'flex', alignItems: 'start' }}>
                    <ExclamationCircleOutlined style={{ marginRight: 10 }} />
                  </div>
                  <p style={{ margin: 0 }}>
                    Ao clicar em <strong style={{ marginLeft: 3 }}> “Solicitar devolução” </strong>, entrará no processo
                    final de análise e se aprovado receberá o código para envio.
                  </p>
                </div>
                <CustomButton
                  type="primary"
                  style={{ marginTop: 40, width: '100%' }}
                  onClick={(e) => showModalConfirmation()}
                >
                  Solicitar devolução
                </CustomButton>
              </Form>
            </>
          )}
        </Container>
      </div>
    </div>
  );
};

export default DevolutionRequestUploadForm;
