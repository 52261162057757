import { Col, Row } from 'antd';
import React, { useState } from 'react';

import { PlusCircleFilled } from '@ant-design/icons';
import HeaderButton from '@components/HeaderButton';
import SalesRegionForm from '@components/SalesRegionForm';
import { useCategory } from '@hooks/CategoryContext';
import { useCollection } from '@hooks/CollectionContext';
import { useColor } from '@hooks/ColorContext';
import { useIntegration } from '@hooks/IntegrationContext';
import { useRepresentative } from '@hooks/RepresentativeContext';
import { FullScreenModal } from '@styles/globals';
import Title from 'antd/lib/typography/Title';
import '../../styles.css';

const SalesRegionHeader: React.FC = () => {
  const { hasMerpIntegration } = useIntegration();

  const [modalNewSalesRegionVisible, setModalNewSalesRegionVisible] = useState(false);

  const { loadColorsGroupedByName } = useColor();
  const { loadCollections } = useCollection();
  const { loadRequestCategory } = useCategory();
  const { loadRepresentatives } = useRepresentative();

  React.useEffect(() => {
    loadColorsGroupedByName();
    loadCollections(0, { listAll: true });
    loadRequestCategory();
    loadRepresentatives();
  }, []);

  const showNewSalesRegionModal = () => {
    setModalNewSalesRegionVisible(true);
  };

  const onCloseNewSalesRegionModal = () => {
    setModalNewSalesRegionVisible(false);
  };

  return (
    <>
      <Row className="mt-8" justify="space-between">
        <Col>
          <Title level={2}>Região de Venda</Title>
        </Col>

        <Col>
          <Row>
            {hasMerpIntegration && (
              <Col>
                <HeaderButton
                  text="Nova região de venda"
                  onClick={showNewSalesRegionModal}
                  Icon={<PlusCircleFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
                />
              </Col>
            )}
          </Row>
        </Col>

        <FullScreenModal
          destroyOnClose={true}
          visible={modalNewSalesRegionVisible}
          onOk={onCloseNewSalesRegionModal}
          onCancel={onCloseNewSalesRegionModal}
          footer={null}
          forceRender={false}
        >
          <Row align="middle" justify="center">
            <Col span={24} className="d-flex" style={{ width: '1000px' }}>
              <SalesRegionForm onCloseModal={onCloseNewSalesRegionModal} isEditing={false} />
            </Col>
          </Row>
        </FullScreenModal>
      </Row>
    </>
  );
};

export default SalesRegionHeader;
